import { EventEmitter } from '@angular/core';
import { ZoomService } from '../zoom.service';
import * as i0 from "@angular/core";
import * as i1 from "../zoom.service";
export class VirtualBackgroundSelectorComponent {
    constructor(zoomService) {
        this.zoomService = zoomService;
        this.selectedVirtualBackground = 'NONE';
        this.virtualBackgroundSelected = new EventEmitter();
    }
    ngOnInit() {
        this.selectedVirtualBackgroundSubscription = this.zoomService.virtualBackground$().subscribe(value => {
            this.selectedVirtualBackground = value;
        });
    }
    selectVirtualBackground(vb) {
        this.selectedVirtualBackground = vb;
        this.virtualBackgroundSelected.emit(vb);
    }
    ngOnDestroy() {
        var _a;
        (_a = this.selectedVirtualBackgroundSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
}
VirtualBackgroundSelectorComponent.ɵfac = function VirtualBackgroundSelectorComponent_Factory(t) { return new (t || VirtualBackgroundSelectorComponent)(i0.ɵɵdirectiveInject(i1.ZoomService)); };
VirtualBackgroundSelectorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: VirtualBackgroundSelectorComponent, selectors: [["virtual-background-selector"]], outputs: { virtualBackgroundSelected: "virtualBackgroundSelected" }, decls: 10, vars: 8, consts: [[1, "d-flex", 2, "gap", "10px"], [1, "virtual-background-preview", "pointer", 3, "click"], ["src", "../../../../../content/images/video-call/none-virtual-bg-preview.png"], ["src", "../../../../../content/images/video-call/blur-virtual-bg-preview.png"], [1, "d-flex", "mt-2", 2, "gap", "10px"], ["src", "../../../../../content/images/video-call/light-virtual-bg-preview.png"], ["src", "../../../../../content/images/video-call/green-virtual-bg-preview.png"]], template: function VirtualBackgroundSelectorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵlistener("click", function VirtualBackgroundSelectorComponent_Template_div_click_1_listener() { return ctx.selectVirtualBackground("NONE"); });
        i0.ɵɵelement(2, "img", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "div", 1);
        i0.ɵɵlistener("click", function VirtualBackgroundSelectorComponent_Template_div_click_3_listener() { return ctx.selectVirtualBackground("BLUR"); });
        i0.ɵɵelement(4, "img", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 4);
        i0.ɵɵelementStart(6, "div", 1);
        i0.ɵɵlistener("click", function VirtualBackgroundSelectorComponent_Template_div_click_6_listener() { return ctx.selectVirtualBackground("LIGHT"); });
        i0.ɵɵelement(7, "img", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "div", 1);
        i0.ɵɵlistener("click", function VirtualBackgroundSelectorComponent_Template_div_click_8_listener() { return ctx.selectVirtualBackground("GREEN"); });
        i0.ɵɵelement(9, "img", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵclassProp("selected-virtual-background", ctx.selectedVirtualBackground === "NONE");
        i0.ɵɵadvance(2);
        i0.ɵɵclassProp("selected-virtual-background", ctx.selectedVirtualBackground === "BLUR");
        i0.ɵɵadvance(3);
        i0.ɵɵclassProp("selected-virtual-background", ctx.selectedVirtualBackground === "LIGHT");
        i0.ɵɵadvance(2);
        i0.ɵɵclassProp("selected-virtual-background", ctx.selectedVirtualBackground === "GREEN");
    } }, styles: [".virtual-background-preview[_ngcontent-%COMP%]{width:144px;height:auto;box-sizing:border-box;border:2px solid #fff}.selected-virtual-background[_ngcontent-%COMP%]{border:2px solid #44a38b;border-radius:10px;box-sizing:border-box}"] });
