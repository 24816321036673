import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JhiPaginationUtil } from './pagination-util.service';
import * as i0 from "@angular/core";
import * as i1 from "./pagination-util.service";
export class JhiResolvePagingParams {
    constructor(paginationUtil) {
        this.paginationUtil = paginationUtil;
    }
    resolve(route, state) {
        const page = route.queryParams['page'] ? route.queryParams['page'] : '1';
        const defaultSort = route.data['defaultSort'] ? route.data['defaultSort'] : 'id,asc';
        const sort = route.queryParams['sort'] ? route.queryParams['sort'] : defaultSort;
        return {
            page: this.paginationUtil.parsePage(page),
            predicate: this.paginationUtil.parsePredicate(sort),
            ascending: this.paginationUtil.parseAscending(sort)
        };
    }
}
JhiResolvePagingParams.ɵfac = function JhiResolvePagingParams_Factory(t) { return new (t || JhiResolvePagingParams)(i0.ɵɵinject(i1.JhiPaginationUtil)); };
JhiResolvePagingParams.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: JhiResolvePagingParams, factory: JhiResolvePagingParams.ɵfac, providedIn: 'root' });
