import anchorme from "anchorme";
import * as i0 from "@angular/core";
export class Linkify {
    static convertUrlAndEmailsToLink(text) {
        return anchorme({
            input: text,
            // use some options
            options: {
                attributes: {
                    target: "_blank",
                    class: "detected",
                },
            }
        });
    }
}
Linkify.ɵfac = function Linkify_Factory(t) { return new (t || Linkify)(); };
Linkify.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: Linkify, factory: Linkify.ɵfac, providedIn: 'root' });
