import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "../../ng-jhipster/language/jhi-translate.directive";
export class RefreshRequestComponent {
    constructor(activeModal) {
        this.activeModal = activeModal;
    }
    refresh() {
        window.location.reload();
    }
    close() {
        this.activeModal.dismiss();
    }
}
RefreshRequestComponent.ɵfac = function RefreshRequestComponent_Factory(t) { return new (t || RefreshRequestComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal)); };
RefreshRequestComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: RefreshRequestComponent, selectors: [["refresh-request"]], decls: 9, vars: 0, consts: [[1, "olha-theme"], [1, "modal-body"], [1, "d-flex", "justify-content-end"], ["icon", "times-circle", 1, "close-icon", 3, "click"], [1, "modal-div", "text-center"], ["jhiTranslate", "refreshRequest.title", 1, "pb-2"], ["jhiTranslate", "refreshRequest.text"], [1, "text-center", "mt-3", "mb-3"], ["type", "button", "jhiTranslate", "refreshRequest.refresh", 1, "btn", "btn-primary", 3, "click"]], template: function RefreshRequestComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function RefreshRequestComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelement(5, "h3", 5);
        i0.ɵɵelement(6, "span", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 7);
        i0.ɵɵelementStart(8, "button", 8);
        i0.ɵɵlistener("click", function RefreshRequestComponent_Template_button_click_8_listener() { return ctx.refresh(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, directives: [i2.FaIconComponent, i3.JhiTranslateDirective], styles: [".modal-body[_ngcontent-%COMP%]{padding:10px}.modal-div[_ngcontent-%COMP%]{padding:0 15px 15px 15px}"] });
