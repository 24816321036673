import { LANGUAGES } from '../../app.constants';
import * as i0 from "@angular/core";
export class FindFlagCodeFromKeyPipe {
    constructor() {
        this.languages = LANGUAGES;
    }
    transform(lang) {
        if (lang === 'gb')
            lang = 'en';
        let language = this.languages.find(l => l.code === lang);
        return language.flagCode;
    }
}
FindFlagCodeFromKeyPipe.ɵfac = function FindFlagCodeFromKeyPipe_Factory(t) { return new (t || FindFlagCodeFromKeyPipe)(); };
FindFlagCodeFromKeyPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "findFlagCodeFromKey", type: FindFlagCodeFromKeyPipe, pure: true });
FindFlagCodeFromKeyPipe.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FindFlagCodeFromKeyPipe, factory: FindFlagCodeFromKeyPipe.ɵfac, providedIn: 'root' });
