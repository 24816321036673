import { SessionService } from '../../session/session.service';
import { SessionStatusManagerService } from '../../../core/session/session-status-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "../../session/session.service";
import * as i2 from "../../../core/session/session-status-manager.service";
import * as i3 from "@angular/common";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "@fortawesome/angular-fontawesome";
const _c0 = function (a0, a1) { return { coachName: a0, minute: a1 }; };
function NavbarNotificationComponent_ng_container_0_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "span", 3);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction2(1, _c0, ctx_r1.session == null ? null : ctx_r1.session.coachLogin, ctx_r1.minutesLeftToSessionStart));
} }
const _c1 = function (a0) { return { coachName: a0 }; };
function NavbarNotificationComponent_ng_container_0_ng_container_2_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "span", 4);
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵelementStart(3, "button", 6);
    i0.ɵɵlistener("click", function NavbarNotificationComponent_ng_container_0_ng_container_2_div_1_Template_button_click_3_listener() { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(3); return ctx_r5.navigateToSession(); });
    i0.ɵɵelement(4, "span", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(2, _c1, ctx_r3.session == null ? null : ctx_r3.session.coachLogin));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("jhiTranslate", "inukaNgApp.indexUser.sessionInfoCard.join " + ctx_r3.session.type);
} }
function NavbarNotificationComponent_ng_container_0_ng_container_2_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "span", 8);
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵelementStart(3, "button", 6);
    i0.ɵɵlistener("click", function NavbarNotificationComponent_ng_container_0_ng_container_2_div_2_Template_button_click_3_listener() { i0.ɵɵrestoreView(_r8); const ctx_r7 = i0.ɵɵnextContext(3); return ctx_r7.navigateToSession(); });
    i0.ɵɵelement(4, "fa-icon", 9);
    i0.ɵɵelement(5, "span", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(2, _c1, ctx_r4.session == null ? null : ctx_r4.session.coachLogin));
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("jhiTranslate", "inukaNgApp.indexUser.sessionInfoCard.join " + ctx_r4.session.type);
} }
function NavbarNotificationComponent_ng_container_0_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NavbarNotificationComponent_ng_container_0_ng_container_2_div_1_Template, 5, 4, "div", 1);
    i0.ɵɵtemplate(2, NavbarNotificationComponent_ng_container_0_ng_container_2_div_2_Template, 6, 4, "div", 1);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.session.type === "text");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.session.type === "video");
} }
function NavbarNotificationComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NavbarNotificationComponent_ng_container_0_div_1_Template, 2, 4, "div", 1);
    i0.ɵɵtemplate(2, NavbarNotificationComponent_ng_container_0_ng_container_2_Template, 3, 2, "ng-container", 0);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.session.active);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.session.started);
} }
export class NavbarNotificationComponent {
    constructor(sessionService, sessionStatusManagerService) {
        this.sessionService = sessionService;
        this.sessionStatusManagerService = sessionStatusManagerService;
        this.minutesLeftToSessionStart = -1;
    }
    set session(value) {
        if (!this._session || this._session !== value || this._session.id !== value.id) {
            this._session = value;
            this.setMinutesLeftTimer();
        }
    }
    get session() {
        return this._session;
    }
    ngOnInit() {
    }
    setMinutesLeftTimer() {
        if (this.session != null && (this.session.active || this.session.started)) {
            this.minutesLeftToSessionStart = this.sessionService.calculateMinutesLeftToSessionStart(this.session);
            if (this.minutesLeftToSessionStart > 0) {
                if (this.minutesLeftToSessionStart < (24 * 60)) { // less than one day is left until session start
                    if (this.timeOutVar) {
                        clearTimeout(this.timeOutVar);
                    }
                    this.timeOutVar = window.setTimeout(() => {
                        this.setMinutesLeftTimer();
                    }, 1000 * 60); // check every one minute
                }
            }
        }
    }
    ngOnDestroy() {
        clearTimeout(this.timeOutVar);
    }
    navigateToSession() {
        this.sessionStatusManagerService.navigateToSession(this.session);
    }
}
NavbarNotificationComponent.ɵfac = function NavbarNotificationComponent_Factory(t) { return new (t || NavbarNotificationComponent)(i0.ɵɵdirectiveInject(i1.SessionService), i0.ɵɵdirectiveInject(i2.SessionStatusManagerService)); };
NavbarNotificationComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: NavbarNotificationComponent, selectors: [["navbar-notification"]], inputs: { session: "session" }, decls: 1, vars: 1, consts: [[4, "ngIf"], ["class", "col text-center p-3 p-xl-0", 4, "ngIf"], [1, "col", "text-center", "p-3", "p-xl-0"], ["jhiTranslate", "inukaNgApp.indexUser.navbarNotification.sessionIsAboutToStart", 1, "text-success", "font-weight-bold", 3, "translateValues"], ["jhiTranslate", "inukaNgApp.indexUser.navbarNotification.chatSessionIsStarted", 1, "text-success", "font-weight-bold", "mr-2", 3, "translateValues"], [1, "mt-2", "d-lg-inline-block"], [1, "btn", "btn-primary", 3, "click"], [3, "jhiTranslate"], ["jhiTranslate", "inukaNgApp.indexUser.navbarNotification.videoSessionIsStarted", 1, "text-success", "font-weight-bold", "mr-2", 3, "translateValues"], ["icon", "video", 1, "mr-2"]], template: function NavbarNotificationComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, NavbarNotificationComponent_ng_container_0_Template, 3, 2, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.session);
    } }, directives: [i3.NgIf, i4.JhiTranslateDirective, i5.FaIconComponent], styles: [""] });
