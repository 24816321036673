import { EventEmitter } from "@angular/core";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i3 from "../session-durations-list/session-durations-list.component";
function SessionTypeCardComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 6);
    i0.ɵɵelement(2, "use");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("background-color", ctx_r0.isMatchWithACoachComponent ? "var(--bg-2)" : "#FFFFFF");
    i0.ɵɵadvance(2);
    i0.ɵɵattribute("href", ctx_r0.svgLink, null, "xlink");
} }
export class SessionTypeCardComponent {
    constructor() {
        this.onSessionTypeSelected = new EventEmitter();
    }
    ngOnInit() {
        if (this.sessionType != null) {
            if (this.selectedSessionType != null && this.selectedSessionType === this.sessionType)
                this.highlightSessionType(this.selectedSessionType);
            this.getSessionType();
            if (this.selectedSessionType === this.sessionType) {
                setTimeout(() => {
                    this.highlightSessionType(this.selectedSessionType);
                }, 50);
            }
        }
        else {
            console.log('SESSION DURATIONS CAN NOT BE RETRIEVED');
        }
    }
    getSessionType() {
        this.sessionTypeHeader = 'inukaNgApp.session.type.' + this.sessionType;
        this.sessionTypeDescription = 'inukaNgApp.session.type.' + this.sessionType + 'Description';
        if (this.sessionType === 'text') {
            this.svgLink = '../../../../content/images/sprite.svg?v=3#text-42-44A38B';
        }
        else if (this.sessionType === 'video') {
            this.svgLink = '../../../../content/images/sprite.svg?v=3#video-39-44A38B';
        }
        else if (this.sessionType === 'intro_call') {
            this.svgLink = '../../../../content/images/sprite.svg?v=3#intro-40-44A38B';
        }
        else {
            console.log('SESSION TYPE NOT RECOGNIZED');
        }
    }
    selectSessionType() {
        this.onSessionTypeSelected.emit(this.sessionType);
        this.highlightSessionType(this.sessionType);
    }
    highlightSessionType(sessionType) {
        this.selected = !this.selected;
        document.querySelectorAll(".header").forEach(d => d.setAttribute("style", "color: #44A38B"));
        document.querySelectorAll(".duration").forEach(d => d.setAttribute("style", "color: #44A38B"));
        document.querySelectorAll(".description").forEach(d => d.setAttribute("style", "color: #0A4A3A"));
        // document.getElementById("session-type-card-" + sessionType).style.background = "#44A38B";
        // document.getElementById("session-type-card-" + sessionType).style.boxShadow = "0 10px 20px 0 rgba(0, 0, 0, 0.13)";
        // document.getElementById("header-" + sessionType).style.color = "#FFF";
        // document.getElementById("duration-" + sessionType).style.color = "#FCFBF7";
        // document.getElementById("description-" + sessionType).style.color = "#FFF";
    }
}
SessionTypeCardComponent.ɵfac = function SessionTypeCardComponent_Factory(t) { return new (t || SessionTypeCardComponent)(); };
SessionTypeCardComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionTypeCardComponent, selectors: [["session-type-card"]], inputs: { selectedCoach: "selectedCoach", sessionType: "sessionType", selectedSessionType: "selectedSessionType", isMatchWithACoachComponent: "isMatchWithACoachComponent", sessionDurations: "sessionDurations", selected: "selected" }, outputs: { onSessionTypeSelected: "onSessionTypeSelected" }, decls: 5, vars: 8, consts: [[1, "olha-theme", "session-type-card", "d-flex", "align-items-center", 3, "click"], ["class", "icon-parent d-flex justify-content-center align-items-center", 3, "background-color", 4, "ngIf"], [1, "header", 3, "jhiTranslate"], [3, "durations"], [1, "description", 3, "jhiTranslate"], [1, "icon-parent", "d-flex", "justify-content-center", "align-items-center"], [2, "height", "42px!important", "width", "42px!important"]], template: function SessionTypeCardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function SessionTypeCardComponent_Template_div_click_0_listener() { return ctx.selectSessionType(); });
        i0.ɵɵtemplate(1, SessionTypeCardComponent_div_1_Template, 3, 3, "div", 1);
        i0.ɵɵelement(2, "h4", 2);
        i0.ɵɵelement(3, "session-durations-list", 3);
        i0.ɵɵelement(4, "span", 4);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵstyleProp("background-color", ctx.isMatchWithACoachComponent ? "#FFFFFF" : "var(--bg-2)");
        i0.ɵɵclassProp("selected-session-type", ctx.selected);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.svgLink != null);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("jhiTranslate", ctx.sessionTypeHeader);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("durations", ctx.sessionDurations);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("jhiTranslate", ctx.sessionTypeDescription);
    } }, directives: [i1.NgIf, i2.JhiTranslateDirective, i3.SessionDurationsListComponent], styles: [".icon-parent[_ngcontent-%COMP%]{height:100%;max-height:100px;aspect-ratio:1/1;border-radius:50%;margin:10px;z-index:100}.session-type-card[_ngcontent-%COMP%]{border-radius:20px;padding:30px;margin:20px;height:300px;width:300px;flex-direction:column;cursor:pointer}.header[_ngcontent-%COMP%]{color:#44a38b;margin:0 !important}.duration[_ngcontent-%COMP%]{color:#7cbfae;font-weight:lighter;margin-bottom:10px}.description[_ngcontent-%COMP%]{color:#0a4a3a;text-align:center;margin-top:10px}.selected-session-type.session-type-card[_ngcontent-%COMP%]{background-color:#44a38b !important;box-shadow:0 10px 20px 0 rgba(0,0,0,.13)}.selected-session-type[_ngcontent-%COMP%], .selected-session-type[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%], .selected-session-type[_ngcontent-%COMP%]   .description[_ngcontent-%COMP%]{color:#fff !important}"] });
