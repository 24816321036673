import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ExtraQuestionAnswerService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/extra-question-answers';
    }
    save(srqCompletedId, extraQuestionAnswer) {
        return this.http.post(`${this.resourceUrl}/srq-completed/${srqCompletedId}`, extraQuestionAnswer, { observe: 'response' });
    }
    getSrqCompletedExtraQuestionAnswers(srqCompletedId) {
        return this.http.get(`${this.resourceUrl}/srq-completed/${srqCompletedId}`, { observe: 'response' });
    }
}
ExtraQuestionAnswerService.ɵfac = function ExtraQuestionAnswerService_Factory(t) { return new (t || ExtraQuestionAnswerService)(i0.ɵɵinject(i1.HttpClient)); };
ExtraQuestionAnswerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ExtraQuestionAnswerService, factory: ExtraQuestionAnswerService.ɵfac, providedIn: 'root' });
