import * as i0 from "@angular/core";
export class JhiKeysPipe {
    transform(value) {
        const keys = [];
        const valueKeys = Object.keys(value);
        for (const key of valueKeys) {
            keys.push({ key, value: value[key] });
        }
        return keys;
    }
}
JhiKeysPipe.ɵfac = function JhiKeysPipe_Factory(t) { return new (t || JhiKeysPipe)(); };
JhiKeysPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "keys", type: JhiKeysPipe, pure: true });
