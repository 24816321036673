import { ActivatedRouteSnapshot, Router, Routes } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Authority } from '../../shared/constants/authority.constants';
import { UserRouteAccessService } from '../../core/auth/user-route-access-service';
import { Session } from '../../shared/model/session.model';
import { SessionService } from './session.service';
import { SessionDetailComponent } from './session-detail.component';
import { SessionChatUserComponent } from './session-chat-user/session-chat-user.component';
import { ScheduleSessionComponent } from './schedule-session/schedule-session.component';
import { SessionChatCoachComponent } from './session-chat-coach/session-chat-coach.component';
import { InboxComponent } from './offline-message/inbox.component';
import { PreviousSessionTranscriptPopupComponent } from './session-chat-coach/previous-session-transcript-popup/previous-session-transcript-popup.component';
import { CoachUserNoteComponent } from './session-chat-coach/coach-user-note/coach-user-note.component';
import { VideoSessionCoachComponent } from './session-chat-coach/video-session-coach/video-session-coach.component';
import { VideoSessionUserComponent } from './session-chat-user/video-session-user/video-session-user.component';
import { SessionTypeSelectionComponent } from './session-type-selection/session-type-selection.component';
import * as i0 from "@angular/core";
import * as i1 from "./session.service";
import * as i2 from "@angular/router";
export class SessionResolve {
    constructor(service, router) {
        this.service = service;
        this.router = router;
    }
    resolve(route) {
        const id = route.params['id'];
        if (id) {
            return this.service.find(id).pipe(flatMap((session) => {
                if (session.body) {
                    return of(session.body);
                }
                else {
                    this.router.navigate(['404']);
                    return EMPTY;
                }
            }));
        }
        return of(new Session());
    }
}
SessionResolve.ɵfac = function SessionResolve_Factory(t) { return new (t || SessionResolve)(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.Router)); };
SessionResolve.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SessionResolve, factory: SessionResolve.ɵfac, providedIn: 'root' });
export const sessionRoute = [
    {
        path: 'chat',
        component: SessionChatUserComponent,
        data: { authorities: [Authority.USER], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'chat/:sessionId',
        component: SessionChatUserComponent,
        data: { authorities: [Authority.USER], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'video',
        component: VideoSessionUserComponent,
        data: { authorities: [Authority.USER], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'video/:sessionId',
        component: VideoSessionUserComponent,
        data: { authorities: [Authority.USER], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'chat/:sessionId/:past',
        component: SessionChatUserComponent,
        data: { authorities: [Authority.USER], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'inbox/:conversation',
        component: InboxComponent,
        data: {
            authorities: [Authority.USER, Authority.COACH],
            pageTitle: 'inukaNgApp.offlineMessage.home.title',
            navbarTitle: 'navbarTitleInbox'
        },
    },
    {
        path: 'inbox/:conversation/:sessionId',
        component: InboxComponent,
        data: {
            authorities: [Authority.USER, Authority.COACH],
            pageTitle: 'inukaNgApp.offlineMessage.home.title',
            navbarTitle: 'navbarTitleInbox'
        },
    },
    {
        path: 'chat-coach',
        component: SessionChatCoachComponent,
        data: { authorities: [Authority.COACH], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'chat-coach/:sessionId',
        component: SessionChatCoachComponent,
        data: { authorities: [Authority.COACH], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'video-coach',
        component: VideoSessionCoachComponent,
        data: { authorities: [Authority.COACH], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'video-coach/:sessionId',
        component: VideoSessionCoachComponent,
        data: { authorities: [Authority.COACH], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'chat-coach/:sessionId/:display',
        component: SessionChatCoachComponent,
        data: { authorities: [Authority.COACH], pageTitle: 'inukaNgApp.session.home.title' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'past/:sessionId',
        component: PreviousSessionTranscriptPopupComponent,
        data: { authorities: [Authority.COACH, Authority.USER], pageTitle: 'sessionChat.previousSessionTranscript' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'notes/:userId/:coachId',
        component: CoachUserNoteComponent,
        data: { authorities: [Authority.COACH], pageTitle: 'sessionChat.coachUserNotes' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'schedule-session/:isScheduled',
        component: ScheduleSessionComponent,
        data: {
            pageTitle: 'sessionSchedule.title'
        }
    },
    {
        path: 'schedule-session/:isScheduled/:sessionId',
        component: ScheduleSessionComponent,
        data: {
            pageTitle: 'sessionSchedule.title'
        }
    },
    {
        path: 'schedule-session/:isScheduled/:sessionId/:user/:userId',
        component: ScheduleSessionComponent,
        data: {
            pageTitle: 'sessionSchedule.title'
        }
    },
    {
        path: 'book-follow-up/:previousSessionId',
        component: ScheduleSessionComponent,
        data: {
            pageTitle: 'sessionSchedule.title'
        }
    },
    {
        path: 'schedule-session',
        component: ScheduleSessionComponent,
        data: {
            pageTitle: 'sessionSchedule.title'
        }
    },
    {
        path: ':id/view',
        component: SessionDetailComponent,
        resolve: {
            session: SessionResolve
        },
        data: {
            authorities: [Authority.MASTERMIND],
            pageTitle: 'inukaNgApp.session.home.title'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'session-type-selection/:coachId',
        component: SessionTypeSelectionComponent,
        data: {
            authorities: [Authority.USER],
            pageTitle: 'sessionTypeSelection.home.title',
        },
    },
];
