import * as Bowser from "bowser";
import * as i0 from "@angular/core";
export class BrowserDetector {
    static isSafari() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const name = browser.getBrowserName(true);
        console.log(`The current browser name is "${name}"`);
        return name === 'safari';
    }
    static isChrome() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const name = browser.getBrowserName(true);
        console.log(`The current browser name is "${name}"`);
        return name === 'chrome';
    }
    static isDesktop() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const name = browser.getPlatformType(true);
        console.log(`The current platform is "${name}"`);
        return name === 'desktop';
    }
    static isLocalHost() {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            return true;
        }
        return false;
    }
}
BrowserDetector.ɵfac = function BrowserDetector_Factory(t) { return new (t || BrowserDetector)(); };
BrowserDetector.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: BrowserDetector, factory: BrowserDetector.ɵfac, providedIn: 'root' });
