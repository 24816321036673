import { BrowserDetector } from '../../core/util/browser-detector';
import { SessionStorageService } from "ngx-webstorage";
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
export class PwaInstallationService {
    constructor(sessionStorage) {
        this.sessionStorage = sessionStorage;
        this.MAX_PWA_INSTALLATION_SHOW = 2;
    }
    shouldShowPwaInstallation() {
        if (!BrowserDetector.isDesktop() && BrowserDetector.isSafari()) {
            if (this.getNumberOfShown() < this.MAX_PWA_INSTALLATION_SHOW) {
                return true;
            }
        }
        return false;
    }
    getNumberOfShown() {
        const numberOfShownStr = this.sessionStorage.retrieve('pwa_installation_shown');
        if (numberOfShownStr) {
            return parseInt(numberOfShownStr, 10);
        }
        else {
            return 0;
        }
    }
    increaseNumberOfShown() {
        let numberOfShown = this.getNumberOfShown();
        numberOfShown = numberOfShown + 1;
        this.sessionStorage.store('pwa_installation_shown', numberOfShown);
        return numberOfShown;
    }
}
PwaInstallationService.ɵfac = function PwaInstallationService_Factory(t) { return new (t || PwaInstallationService)(i0.ɵɵinject(i1.SessionStorageService)); };
PwaInstallationService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PwaInstallationService, factory: PwaInstallationService.ɵfac, providedIn: 'root' });
