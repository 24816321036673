import { Observable } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';
import * as i0 from "@angular/core";
/**
 * An utility class to manage RX events
 */
export class JhiEventManager {
    constructor() {
        this.observable = Observable.create((observer) => {
            this.observer = observer;
        }).pipe(share());
    }
    /**
     * Method to broadcast the event to observer
     */
    broadcast(event) {
        if (this.observer) {
            this.observer.next(event);
        }
    }
    /**
     * Method to subscribe to an event with callback
     */
    subscribe(eventName, callback) {
        const subscriber = this.observable
            .pipe(filter((event) => {
            if (typeof event === 'string') {
                return event === eventName;
            }
            return event.name === eventName;
        }), map((event) => {
            if (typeof event !== 'string') {
                // when releasing generator-jhipster v7 then current return will be changed to
                // (to avoid redundant code response.content in JhiEventManager.subscribe callbacks):
                // return event.content;
                return event;
            }
            return undefined;
        }))
            .subscribe(callback);
        return subscriber;
    }
    /**
     * Method to unsubscribe the subscription
     */
    destroy(subscriber) {
        subscriber.unsubscribe();
    }
}
JhiEventManager.ɵfac = function JhiEventManager_Factory(t) { return new (t || JhiEventManager)(); };
JhiEventManager.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: JhiEventManager, factory: JhiEventManager.ɵfac, providedIn: 'root' });
