import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CannedMessagesService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/canned-messages';
    }
    create(cannedMessages) {
        return this.http.post(this.resourceUrl, cannedMessages, { observe: 'response' });
    }
    update(cannedMessages) {
        console.log(cannedMessages);
        return this.http.put(this.resourceUrl, cannedMessages, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        console.log(options);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    queryAsList() {
        return this.http.get(`${this.resourceUrl}/list`, { observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
CannedMessagesService.ɵfac = function CannedMessagesService_Factory(t) { return new (t || CannedMessagesService)(i0.ɵɵinject(i1.HttpClient)); };
CannedMessagesService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CannedMessagesService, factory: CannedMessagesService.ɵfac, providedIn: 'root' });
