import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "./customer-care-info.component";
export class CustomerCareInfoModalComponent {
    constructor(activeModal) {
        this.activeModal = activeModal;
    }
}
CustomerCareInfoModalComponent.ɵfac = function CustomerCareInfoModalComponent_Factory(t) { return new (t || CustomerCareInfoModalComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal)); };
CustomerCareInfoModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: CustomerCareInfoModalComponent, selectors: [["customer-care-info-modal"]], decls: 1, vars: 1, consts: [[3, "selectedConversation"]], template: function CustomerCareInfoModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "customer-care-info", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("selectedConversation", ctx.selectedConversation);
    } }, directives: [i2.CustomerCareInfoComponent], styles: [".text-default-div[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]{vertical-align:middle}.text-default-div[_ngcontent-%COMP%]{padding-left:10px}select.btn-mini[_ngcontent-%COMP%]{height:auto;line-height:10px}select.btn[_ngcontent-%COMP%]{-webkit-appearance:button;-moz-appearance:button;padding:2px !important;margin-left:5px !important;font-size:14px !important;border:none !important;box-shadow:0 0 0 0 #fff !important}select.btn-mini[_ngcontent-%COMP%] + .caret[_ngcontent-%COMP%]{margin-left:-20px;margin-top:9px}"] });
