import { LANGUAGES } from '../../app.constants';
import * as i0 from "@angular/core";
export class FindLanguageFromKeyPipe {
    constructor() {
        this.languagesKeyToName = LANGUAGES;
    }
    transform(lang) {
        let langKeyList = lang.replace(/\s/g, '').split(",");
        let languageList = [];
        langKeyList.forEach(l1 => languageList.push(this.languagesKeyToName.find(l2 => l2.code === l1).nativeName));
        return languageList.join(', ');
    }
}
FindLanguageFromKeyPipe.ɵfac = function FindLanguageFromKeyPipe_Factory(t) { return new (t || FindLanguageFromKeyPipe)(); };
FindLanguageFromKeyPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "findLanguageFromKey", type: FindLanguageFromKeyPipe, pure: true });
FindLanguageFromKeyPipe.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FindLanguageFromKeyPipe, factory: FindLanguageFromKeyPipe.ɵfac, providedIn: 'root' });
