import { ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../core/auth/account.service';
import { NavbarInukaService } from '../../entities/navbar-inuka/navbar-inuka.service';
import { VERSION } from '../../app.constants';
import { ApplicationConfigService } from '../../core/config/application-config.service';
import { SessionStorageService } from "ngx-webstorage";
import { NgxUiLoaderService } from "ngx-ui-loader";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ConsoleLogViewer } from '../../core/log/console-log-viewer.js';
import { BrowserDetector } from '../../core/util/browser-detector';
import { LeaveNumberModalComponent } from '../../entities/login/leave-number-modal.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JhiLanguageService } from '../../ng-jhipster/language';
import { JhiEventManager } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth/account.service";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../../entities/navbar-inuka/navbar-inuka.service";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../ng-jhipster/language";
import * as i7 from "../../ng-jhipster/service";
import * as i8 from "../../core/config/application-config.service";
import * as i9 from "ngx-ui-loader";
import * as i10 from "ngx-webstorage";
import * as i11 from "@ng-bootstrap/ng-bootstrap";
import * as i12 from "@angular/common";
import * as i13 from "../../shared/page-warning/page-warning.component";
import * as i14 from "../../shared/white-label.directive";
function MainComponent_div_0_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "router-outlet", 6);
    i0.ɵɵelementEnd();
} }
function MainComponent_div_0_router_outlet_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "router-outlet", 7);
} }
function MainComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, MainComponent_div_0_div_1_Template, 2, 0, "div", 3);
    i0.ɵɵelementStart(2, "div", 4);
    i0.ɵɵtemplate(3, MainComponent_div_0_router_outlet_3_Template, 1, 0, "router-outlet", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("collapsed-mode", ctx_r0.collapsedMode);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.displayNavbar() && ctx_r0.isAuthenticated());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.isAuthenticated());
} }
function MainComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵelement(2, "router-outlet");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("collapsed-mode", ctx_r1.collapsedMode);
} }
export class MainComponent {
    constructor(accountService, titleService, navbarTitleService, activatedRoute, router, translateService, languageService, rootRenderer, eventManager, applicationConfigService, elementRef, ngxLoader, sessionStorageService, modalService, sessionStorage) {
        this.accountService = accountService;
        this.titleService = titleService;
        this.navbarTitleService = navbarTitleService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.translateService = translateService;
        this.languageService = languageService;
        this.rootRenderer = rootRenderer;
        this.eventManager = eventManager;
        this.applicationConfigService = applicationConfigService;
        this.elementRef = elementRef;
        this.ngxLoader = ngxLoader;
        this.sessionStorageService = sessionStorageService;
        this.modalService = modalService;
        this.sessionStorage = sessionStorage;
        this.loading = false;
        this.modalOptions = { windowClass: 'modal-window', size: 'md', centered: true };
        this.langKey = "";
        this.noNavbarComponents = ['#/wellbeing-scan', '#/wellbeing-start', '#/post-scan-info', '#/onboarding'];
        this.noFooterComponents = ['#/session/chat-coach', '#/session/chat', '#/session/inbox'];
        this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);
    }
    ngOnInit() {
        console.log("MainComponent.ngOnInit: " + VERSION);
        if (!BrowserDetector.isChrome() && !BrowserDetector.isSafari() && !this.sessionStorage.retrieve('bestBrowserPopupDisplayed')) {
            this.displayErrorModal();
            this.sessionStorage.store('bestBrowserPopupDisplayed', true);
        }
        // try to log in automatically
        this.accountService.identity().subscribe(() => {
            console.log("able to login");
        }, () => {
            console.log("unable to login");
        });
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateTitle();
                this.changeLanguageIfRequestedViaUrlParameter();
            }
            if (event instanceof NavigationError && event.error.status === 404) {
                this.router.navigate(['/404']);
            }
        });
        this.translateService.onLangChange.subscribe((langChangeEvent) => {
            console.log("Language changed");
            this.updateTitle();
            this.renderer.setAttribute(document.querySelector('html'), 'lang', langChangeEvent.lang);
        });
        this.eventManager.subscribe('httpRequestStarted', () => {
            // console.log("Showing loading spinner");
            this.ngxLoader.start();
        });
        this.eventManager.subscribe('httpRequestFinished', () => {
            // console.log("Hiding loading spinner");
            this.ngxLoader.stop();
        });
        this.eventManager.subscribe('collapsedModeEnabled', () => {
            this.collapsedMode = true;
        });
        this.eventManager.subscribe('collapsedModeDisabled', () => {
            this.collapsedMode = false;
        });
        this.eventManager.subscribe('toggleConsoleLogViewer', () => {
            if (!this.consoleLogViewer) {
                this.consoleLogViewer = new ConsoleLogViewer();
            }
            if (this.consoleLogViewer.isEnabled()) {
                // this.consoleLogViewer.disable();
            }
            else {
                this.consoleLogViewer.enable();
            }
        });
    }
    displayErrorModal() {
        const modalRef = this.modalService.open(LeaveNumberModalComponent, this.modalOptions);
        modalRef.componentInstance.leaveNumberModalRef = modalRef;
        modalRef.componentInstance.displayNLHelpHeader = false;
        modalRef.componentInstance.errorMessage = 'login.messages.error.bestBrowsers';
    }
    changeLanguageIfRequestedViaUrlParameter() {
        let langKey = this.activatedRoute.snapshot.queryParams["langKey"];
        console.log(": debug langKey: " + langKey);
        if (langKey && this.langKey !== langKey) {
            this.sessionStorageService.store('locale', langKey);
            this.languageService.changeLanguage(langKey);
        }
    }
    getPageTitle(routeSnapshot) {
        let title = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : '';
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }
    getNavbarTitle(routeSnapshot) {
        let title = routeSnapshot.data && routeSnapshot.data['navbarTitle'] ? routeSnapshot.data['navbarTitle'] : '';
        if (routeSnapshot.firstChild) {
            title = this.getNavbarTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }
    updateTitle() {
        let pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
        if (!pageTitle) {
            pageTitle = 'global.title';
        }
        this.translateService.get(pageTitle).subscribe(title => this.titleService.setTitle(title));
        let navbarTitle = this.getNavbarTitle(this.router.routerState.snapshot.root);
        if (!navbarTitle) {
            navbarTitle = 'inuka';
        }
        this.navbarTitleService.navbarTitle = navbarTitle;
    }
    isAuthenticated() {
        return this.accountService.isAuthenticated();
    }
    displayNavbar() {
        return !this.noNavbarComponents.includes(window.location.hash.split('?')[0]);
    }
    displayFooter() {
        let path = window.location.hash.split('?')[0];
        let isFooterAllowed = !this.noFooterComponents.some(c => path.includes(c));
        return this.displayNavbar() && isFooterAllowed;
    }
}
MainComponent.ɵfac = function MainComponent_Factory(t) { return new (t || MainComponent)(i0.ɵɵdirectiveInject(i1.AccountService), i0.ɵɵdirectiveInject(i2.Title), i0.ɵɵdirectiveInject(i3.NavbarInukaService), i0.ɵɵdirectiveInject(i4.ActivatedRoute), i0.ɵɵdirectiveInject(i4.Router), i0.ɵɵdirectiveInject(i5.TranslateService), i0.ɵɵdirectiveInject(i6.JhiLanguageService), i0.ɵɵdirectiveInject(i0.RendererFactory2), i0.ɵɵdirectiveInject(i7.JhiEventManager), i0.ɵɵdirectiveInject(i8.ApplicationConfigService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i9.NgxUiLoaderService), i0.ɵɵdirectiveInject(i10.SessionStorageService), i0.ɵɵdirectiveInject(i11.NgbModal), i0.ɵɵdirectiveInject(i10.SessionStorageService)); };
MainComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: MainComponent, selectors: [["jhi-main"]], decls: 4, vars: 2, consts: [["id", "app", "class", "h-100 d-flex flex-column", 3, "collapsed-mode", "whiteLabel", 4, "ngIf"], ["class", "h-100 d-flex flex-column", 3, "collapsed-mode", "whiteLabel", 4, "ngIf"], ["id", "app", 1, "h-100", "d-flex", "flex-column", 3, "whiteLabel"], [4, "ngIf"], [2, "flex-grow", "1"], ["style", "overflow: scroll;", 4, "ngIf"], ["name", "navbar"], [2, "overflow", "scroll"], [1, "h-100", "d-flex", "flex-column", 3, "whiteLabel"]], template: function MainComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, MainComponent_div_0_Template, 4, 4, "div", 0);
        i0.ɵɵtemplate(1, MainComponent_div_1_Template, 3, 2, "div", 1);
        i0.ɵɵelement(2, "page-warning");
        i0.ɵɵelement(3, "ngx-ui-loader");
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.isAuthenticated());
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isAuthenticated());
    } }, directives: [i12.NgIf, i13.PageWarningComponent, i9.ɵb, i14.WhiteLabelDirective, i4.RouterOutlet], encapsulation: 2 });
