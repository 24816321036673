import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i4 from "../../shared/alert/alert-error.component";
import * as i5 from "@fortawesome/angular-fontawesome";
const _c0 = function (a1) { return ["/session", a1, "edit"]; };
function SessionDetailComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "h2");
    i0.ɵɵelementStart(2, "span", 3);
    i0.ɵɵtext(3, "Session");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "hr");
    i0.ɵɵelement(6, "jhi-alert-error");
    i0.ɵɵelementStart(7, "dl", 4);
    i0.ɵɵelementStart(8, "dt");
    i0.ɵɵelementStart(9, "span", 5);
    i0.ɵɵtext(10, "Coach First Join");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "dd");
    i0.ɵɵelementStart(12, "span");
    i0.ɵɵtext(13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "dt");
    i0.ɵɵelementStart(15, "span", 6);
    i0.ɵɵtext(16, "Duration");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(17, "dd");
    i0.ɵɵelementStart(18, "span");
    i0.ɵɵtext(19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "dt");
    i0.ɵɵelementStart(21, "span", 7);
    i0.ɵɵtext(22, "Session Count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(23, "dd");
    i0.ɵɵelementStart(24, "span");
    i0.ɵɵtext(25);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(26, "dt");
    i0.ɵɵelementStart(27, "span", 8);
    i0.ɵɵtext(28, "Session Start Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(29, "dd");
    i0.ɵɵelementStart(30, "span");
    i0.ɵɵtext(31);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(32, "dt");
    i0.ɵɵelementStart(33, "span", 9);
    i0.ɵɵtext(34, "Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(35, "dd");
    i0.ɵɵelementStart(36, "span");
    i0.ɵɵtext(37);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(38, "dt");
    i0.ɵɵelementStart(39, "span", 10);
    i0.ɵɵtext(40, "User First Join");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(41, "dd");
    i0.ɵɵelementStart(42, "span");
    i0.ɵɵtext(43);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(44, "dt");
    i0.ɵɵelementStart(45, "span", 11);
    i0.ɵɵtext(46, "User Notified");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(47, "dd");
    i0.ɵɵelementStart(48, "span");
    i0.ɵɵtext(49);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(50, "dt");
    i0.ɵɵelementStart(51, "span", 12);
    i0.ɵɵtext(52, "Active Voucher Code");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(53, "dd");
    i0.ɵɵelementStart(54, "span");
    i0.ɵɵtext(55);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(56, "dt");
    i0.ɵɵelementStart(57, "span", 13);
    i0.ɵɵtext(58, "Organization Code Used");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(59, "dd");
    i0.ɵɵelementStart(60, "span");
    i0.ɵɵtext(61);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(62, "dt");
    i0.ɵɵelementStart(63, "span", 14);
    i0.ɵɵtext(64, "Cancel Or Reschedule Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(65, "dd");
    i0.ɵɵelementStart(66, "span");
    i0.ɵɵtext(67);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(68, "dt");
    i0.ɵɵelementStart(69, "span", 15);
    i0.ɵɵtext(70, "First Message Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(71, "dd");
    i0.ɵɵelementStart(72, "span");
    i0.ɵɵtext(73);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(74, "dt");
    i0.ɵɵelementStart(75, "span", 16);
    i0.ɵɵtext(76, "Last Message Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(77, "dd");
    i0.ɵɵelementStart(78, "span");
    i0.ɵɵtext(79);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(80, "dt");
    i0.ɵɵelementStart(81, "span", 17);
    i0.ɵɵtext(82, "Coach Id");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(83, "dd");
    i0.ɵɵelementStart(84, "span");
    i0.ɵɵtext(85);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(86, "dt");
    i0.ɵɵelementStart(87, "span", 18);
    i0.ɵɵtext(88, "Action Plan Id");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(89, "dd");
    i0.ɵɵelementStart(90, "span");
    i0.ɵɵtext(91);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(92, "dt");
    i0.ɵɵelementStart(93, "span", 19);
    i0.ɵɵtext(94, "User");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(95, "dd");
    i0.ɵɵtext(96);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(97, "button", 20);
    i0.ɵɵlistener("click", function SessionDetailComponent_div_2_Template_button_click_97_listener() { i0.ɵɵrestoreView(_r2); const ctx_r1 = i0.ɵɵnextContext(); return ctx_r1.previousState(); });
    i0.ɵɵelement(98, "fa-icon", 21);
    i0.ɵɵtext(99, "\u00A0");
    i0.ɵɵelementStart(100, "span", 22);
    i0.ɵɵtext(101, "Back");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(102, "button", 23);
    i0.ɵɵelement(103, "fa-icon", 24);
    i0.ɵɵtext(104, "\u00A0");
    i0.ɵɵelementStart(105, "span", 25);
    i0.ɵɵtext(106, "Edit");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.session.id, "");
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(ctx_r0.session.coachFirstJoin);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.duration);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.sessionCount);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.sessionStartTime);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.time);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.userFirstJoin);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.userNotified);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.activeVoucherCode);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.organizationCodeUsed);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.cancelOrRescheduleTime);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.firstMessageTime);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.lastMessageTime);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.coachId);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.session.actionPlanId);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.session.user == null ? null : ctx_r0.session.user.login, " ");
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(17, _c0, ctx_r0.session.id));
} }
export class SessionDetailComponent {
    constructor(activatedRoute) {
        this.activatedRoute = activatedRoute;
        this.session = null;
    }
    ngOnInit() {
        this.activatedRoute.data.subscribe(({ session }) => (this.session = session));
    }
    previousState() {
        window.history.back();
    }
}
SessionDetailComponent.ɵfac = function SessionDetailComponent_Factory(t) { return new (t || SessionDetailComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute)); };
SessionDetailComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionDetailComponent, selectors: [["jhi-session-detail"]], decls: 3, vars: 1, consts: [[1, "row", "justify-content-center"], [1, "col-8"], [4, "ngIf"], ["jhiTranslate", "inukaNgApp.session.detail.title"], [1, "row-md", "jh-entity-details"], ["jhiTranslate", "inukaNgApp.session.coachFirstJoin"], ["jhiTranslate", "inukaNgApp.session.duration"], ["jhiTranslate", "inukaNgApp.session.sessionCount"], ["jhiTranslate", "inukaNgApp.session.sessionStartTime"], ["jhiTranslate", "inukaNgApp.session.time"], ["jhiTranslate", "inukaNgApp.session.userFirstJoin"], ["jhiTranslate", "inukaNgApp.session.userNotified"], ["jhiTranslate", "inukaNgApp.session.activeVoucherCode"], ["jhiTranslate", "inukaNgApp.session.organizationCodeUsed"], ["jhiTranslate", "inukaNgApp.session.cancelOrRescheduleTime"], ["jhiTranslate", "inukaNgApp.session.firstMessageTime"], ["jhiTranslate", "inukaNgApp.session.lastMessageTime"], ["jhiTranslate", "inukaNgApp.session.coachId"], ["jhiTranslate", "inukaNgApp.session.actionPlanId"], ["jhiTranslate", "inukaNgApp.session.user"], ["type", "submit", 1, "btn", "btn-info", 3, "click"], ["icon", "arrow-left"], ["jhiTranslate", "entity.action.back"], ["type", "button", 1, "btn", "btn-primary", 3, "routerLink"], ["icon", "pencil-alt"], ["jhiTranslate", "entity.action.edit"]], template: function SessionDetailComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵtemplate(2, SessionDetailComponent_div_2_Template, 107, 19, "div", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.session);
    } }, directives: [i2.NgIf, i3.JhiTranslateDirective, i4.AlertErrorComponent, i5.FaIconComponent, i1.RouterLink], encapsulation: 2 });
