import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatRadioModule } from "@angular/material/radio";
import { EmojiModule } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgJhipsterModule } from '../../ng-jhipster/public_api';
import * as i0 from "@angular/core";
export class InukaNgSharedLibsModule {
}
InukaNgSharedLibsModule.ɵfac = function InukaNgSharedLibsModule_Factory(t) { return new (t || InukaNgSharedLibsModule)(); };
InukaNgSharedLibsModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgSharedLibsModule });
InukaNgSharedLibsModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [FormsModule,
        CommonModule,
        NgbModule,
        NgJhipsterModule,
        InfiniteScrollModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        TranslateModule,
        ScrollingModule,
        EmojiModule,
        MatSlideToggleModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatRadioModule,
        MatDatepickerModule, MatInputModule, MatNativeDateModule, MatIconModule, NgxMatSelectSearchModule,
        NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule, AngularMultiSelectModule] });
