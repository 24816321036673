import { ElementRef } from '@angular/core';
import { JhiEventManager } from '../../ng-jhipster/service';
import { fromEvent, merge, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from '../../core/user/user.service';
import { CUSTOMER_CARE_EMAIL_FOR_COACH } from '../../app.constants';
import { AccountService } from '../../core/auth/account.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/service";
import * as i2 from "../../core/user/user.service";
import * as i3 from "../../core/auth/account.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common";
import * as i6 from "@fortawesome/angular-fontawesome";
const _c0 = ["browserWarning"];
const _c1 = ["requestTimeoutWarning"];
function PageWarningComponent_div_0_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Server is down. ");
    i0.ɵɵelement(2, "br");
    i0.ɵɵtext(3, " Consider refreshing your browser by clicking ");
    i0.ɵɵelementStart(4, "a", 6);
    i0.ɵɵlistener("click", function PageWarningComponent_div_0_div_2_Template_a_click_4_listener() { i0.ɵɵrestoreView(_r9); const ctx_r8 = i0.ɵɵnextContext(2); return ctx_r8.refresh(); });
    i0.ɵɵtext(5, "here");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function PageWarningComponent_div_0_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Low Internet Connection");
    i0.ɵɵelement(2, "br");
    i0.ɵɵtext(3, " Feel free to try again or get in touch with us through: ");
    i0.ɵɵelementStart(4, "a", 7);
    i0.ɵɵtext(5, "whatsapp");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(6, " or ");
    i0.ɵɵelementStart(7, "a", 8);
    i0.ɵɵtext(8, "e-mail");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(7);
    i0.ɵɵpropertyInterpolate1("href", "mailto:", ctx_r5.customerCareEmail, "", i0.ɵɵsanitizeUrl);
} }
function PageWarningComponent_div_0_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " You are not connected to Internet");
    i0.ɵɵelement(2, "br");
    i0.ɵɵelementEnd();
} }
function PageWarningComponent_div_0_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Establishing connection with Inuka servers . . .");
    i0.ɵɵelement(2, "br");
    i0.ɵɵelementEnd();
} }
function PageWarningComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2, 3);
    i0.ɵɵtemplate(2, PageWarningComponent_div_0_div_2_Template, 6, 0, "div", 4);
    i0.ɵɵtemplate(3, PageWarningComponent_div_0_div_3_Template, 9, 1, "div", 4);
    i0.ɵɵtemplate(4, PageWarningComponent_div_0_div_4_Template, 3, 0, "div", 4);
    i0.ɵɵtemplate(5, PageWarningComponent_div_0_div_5_Template, 3, 0, "div", 4);
    i0.ɵɵelementStart(6, "fa-icon", 5);
    i0.ɵɵlistener("click", function PageWarningComponent_div_0_Template_fa_icon_click_6_listener() { i0.ɵɵrestoreView(_r11); const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.closeWarning(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.warning === ctx_r0.serverIsDown);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.warning === ctx_r0.slowInternetSpeed);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.warning === ctx_r0.internetIsDown);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.warning === ctx_r0.reconnectingToWebsocket);
} }
function PageWarningComponent_ng_template_1_Template(rf, ctx) { if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵelementStart(1, "div", 10);
    i0.ɵɵelementStart(2, "fa-icon", 11);
    i0.ɵɵlistener("click", function PageWarningComponent_ng_template_1_Template_fa_icon_click_2_listener() { const restoredCtx = i0.ɵɵrestoreView(_r14); const modal_r12 = restoredCtx.$implicit; return modal_r12.dismiss("Cross click"); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 12);
    i0.ɵɵtext(4, " Request took longer than expected, Please try again ");
    i0.ɵɵelementEnd();
} }
export class PageWarningComponent {
    constructor(eventManager, userService, accountService, modalService) {
        this.eventManager = eventManager;
        this.userService = userService;
        this.accountService = accountService;
        this.modalService = modalService;
        this.serverIsDown = 'serverIsDown';
        this.slowInternetSpeed = 'slowInternetSpeed';
        this.internetIsDown = 'internetIsDown';
        this.reconnectingToWebsocket = 'reconnectingToWebsocket';
        this.connectedToWebsocket = 'connectedToWebsocket';
        this.requestTimeout = 'requestTimeout';
        this.showCloseButton = true;
    }
    ngOnInit() {
        if (this.accountService.isAuthenticated()) {
            this.userService.current().subscribe(user => {
                if (user.authorities.includes("ROLE_USER")) {
                    this.userService.customerCare().subscribe(response => {
                        this.customerCareEmail = response;
                    });
                }
                else {
                    this.customerCareEmail = CUSTOMER_CARE_EMAIL_FOR_COACH;
                }
            });
        }
        else {
            this.customerCareEmail = CUSTOMER_CARE_EMAIL_FOR_COACH;
        }
        this.createOnline$().subscribe(isOnline => {
            if (isOnline) {
                this.warning = undefined;
            }
            else {
                this.warning = this.internetIsDown;
            }
        });
        // let subscriptionDisabled = this.eventManager.subscribe('browserWarning', (e) => this.text = e.content + this.closeButton);
        let subscriptionServerIsDown = this.eventManager.subscribe(this.serverIsDown, (e) => {
            console.log("PageWarningComponent: " + JSON.stringify(e));
            this.warning = this.serverIsDown;
        });
        let subscriptionSlowInternetSpeed = this.eventManager.subscribe(this.slowInternetSpeed, (e) => {
            console.log("PageWarningComponent: " + JSON.stringify(e));
            this.warning = this.slowInternetSpeed;
        });
        let subscriptionInternetIsDown = this.eventManager.subscribe(this.internetIsDown, (e) => {
            console.log("PageWarningComponent: " + JSON.stringify(e));
            this.warning = this.internetIsDown;
        });
        let subscriptionReconnectingToWebsocket = this.eventManager.subscribe(this.reconnectingToWebsocket, (e) => {
            console.log("PageWarningComponent: " + JSON.stringify(e));
            if (!this.warning) {
                this.warning = this.reconnectingToWebsocket;
                this.showCloseButton = e.content;
            }
        });
        let subscriptionConnectedToWebsocket = this.eventManager.subscribe(this.connectedToWebsocket, (e) => {
            // console.log("PageWarningComponent: " + JSON.stringify(e));
            if (this.warning === this.reconnectingToWebsocket) {
                this.warning = undefined;
            }
        });
        let subscriptionRequestTimeout = this.eventManager.subscribe(this.requestTimeout, (e) => {
            if (!this.modalService.hasOpenModals()) {
                this.requestTimeoutWarningModal = this.modalService.open(this.requestTimeoutWarning, { centered: true });
            }
        });
    }
    closeWarning() {
        this.warning = undefined;
    }
    refresh() {
        location.reload();
    }
    createOnline$() {
        return merge(fromEvent(window, 'offline').pipe(map(() => false)), fromEvent(window, 'online').pipe(map(() => true)), new Observable((sub) => {
            sub.next(navigator.onLine);
            sub.complete();
        }));
    }
}
PageWarningComponent.ɵfac = function PageWarningComponent_Factory(t) { return new (t || PageWarningComponent)(i0.ɵɵdirectiveInject(i1.JhiEventManager), i0.ɵɵdirectiveInject(i2.UserService), i0.ɵɵdirectiveInject(i3.AccountService), i0.ɵɵdirectiveInject(i4.NgbModal)); };
PageWarningComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: PageWarningComponent, selectors: [["page-warning"]], viewQuery: function PageWarningComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c1, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.browserWarning = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.requestTimeoutWarning = _t.first);
    } }, decls: 3, vars: 1, consts: [["class", "p-2 text-center display-on-top", 4, "ngIf"], ["requestTimeoutWarning", ""], [1, "p-2", "text-center", "display-on-top"], ["browserWarning", ""], [4, "ngIf"], ["icon", "times-circle", 1, "close-button", "pr-2", 3, "click"], ["href", "#", 3, "click"], ["href", "https://chat.whatsapp.com/ITYTGjuZQSMKUUnKgVWtyy", 2, "color", "#44A38D", "font-weight", "bold"], [2, "color", "#44A38D", "font-weight", "bold", 3, "href"], [1, "modal-header", "d-block"], [1, "text-right"], ["icon", "times", 1, "pr-2", 2, "cursor", "pointer", 3, "click"], [1, "modal-body"]], template: function PageWarningComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, PageWarningComponent_div_0_Template, 7, 4, "div", 0);
        i0.ɵɵtemplate(1, PageWarningComponent_ng_template_1_Template, 5, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.warning);
    } }, directives: [i5.NgIf, i6.FaIconComponent], styles: [".display-on-top[_ngcontent-%COMP%]{background-color:#000;opacity:.9;color:#fff;position:fixed;top:0;left:0;right:0;z-index:51000}.close-button[_ngcontent-%COMP%]{position:fixed;right:.4rem;top:.4rem;cursor:pointer;color:#fff}"] });
