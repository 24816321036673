import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import { BaseComponent } from '../../../../shared/base.component';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { JhiLanguageService } from '../../../../ng-jhipster/language';
import { JhiEventManager } from '../../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "ngx-webstorage";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../../ng-jhipster/language";
import * as i5 from "../../../../ng-jhipster/service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "@angular/common";
import * as i9 from "ngx-echarts";
const _c0 = function () { return { renderer: "svg" }; };
function OrganizationNumberOfSessionsComponent_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 3);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r0.chartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
export class OrganizationNumberOfSessionsComponent extends BaseComponent {
    constructor(organizationDashboardService, sessionStorage, modalService, languageService, eventManager, translate) {
        super(sessionStorage, modalService);
        this.organizationDashboardService = organizationDashboardService;
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.languageService = languageService;
        this.eventManager = eventManager;
        this.translate = translate;
        this.isLoadingData = false;
        this.currentLanguage = 'en';
        this.data = [];
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
        this.currentLanguage = this.languageService.getCurrentLanguage();
        this.eventManager.subscribe('langKeyChanged', () => {
            this.currentLanguage = this.languageService.getCurrentLanguage();
            this.refreshChartData();
        });
    }
    updateLanguage() {
        this.currentLanguage = this.languageService.getCurrentLanguage();
        this.eventManager.subscribe('langKeyChanged', () => {
            this.currentLanguage = this.languageService.getCurrentLanguage();
            this.refreshChartData();
        });
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationNumberOfSessionsReport(this.parameters).subscribe(response => {
            this.report = response.body;
            console.log(this.report);
            this.refreshChartData();
            this.isLoadingData = false;
        }, error => {
            console.log(error);
            this.isLoadingData = false;
        });
    }
    refreshChartData() {
        var _a;
        this.data = [];
        if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.organizationNumberOfSessionsCount) {
            this.data.push({
                value: this.report.organizationNumberOfSessionsCount.endedSessionsCount,
                name: this.translate.instant('inukaNgApp.organizationAdmin.organizationNumberOfSessions.endedSessions')
            });
            this.data.push({
                value: this.report.organizationNumberOfSessionsCount.userNoShowSessionsCount,
                name: this.translate.instant('inukaNgApp.organizationAdmin.organizationNumberOfSessions.userNoShowSessions')
            });
            this.data.push({
                value: this.report.organizationNumberOfSessionsCount.lastMinuteCanceledOrRescheduledSessionsCount,
                name: this.translate.instant('inukaNgApp.organizationAdmin.organizationNumberOfSessions.lastMinuteCanceledOrRescheduledSessions')
            });
            this.totalSessionsCount = this.report.organizationNumberOfSessionsCount.totalSessionsCount;
            this.totalSessionsCountText = this.translate.instant('inukaNgApp.organizationAdmin.organizationNumberOfSessions.totalSessions') + '\n' + this.totalSessionsCount.toString();
        }
        this.chartOption = {
            title: {
                text: this.totalSessionsCountText,
                textStyle: {
                    fontSize: 20,
                },
                left: 'center',
                top: 'center',
            },
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            legend: {
                bottom: 0,
                left: 'center',
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
            },
            series: [
                {
                    type: 'pie',
                    radius: ['55%', '75%'],
                    avoidLabelOverlap: false,
                    animationDuration: 3000,
                    color: ['#0A4A3A', '#BA3F1D', '#823038'],
                    itemStyle: {
                        borderRadius: 5,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                    },
                    data: this.data,
                    center: ['center', 'center']
                }
            ]
        };
    }
}
OrganizationNumberOfSessionsComponent.ɵfac = function OrganizationNumberOfSessionsComponent_Factory(t) { return new (t || OrganizationNumberOfSessionsComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService), i0.ɵɵdirectiveInject(i2.SessionStorageService), i0.ɵɵdirectiveInject(i3.NgbModal), i0.ɵɵdirectiveInject(i4.JhiLanguageService), i0.ɵɵdirectiveInject(i5.JhiEventManager), i0.ɵɵdirectiveInject(i6.TranslateService)); };
OrganizationNumberOfSessionsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationNumberOfSessionsComponent, selectors: [["organization-number-of-sessions"]], inputs: { parameters: "parameters" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 3, vars: 1, consts: [[1, "card", "dashboard-card", "p-2"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationNumberOfSessions.header", 1, "mt-3", "ml-3", "mb-0", "light-weight"], [4, "ngIf"], ["echarts", "", 1, "e-charts", 3, "options", "initOpts"]], template: function OrganizationNumberOfSessionsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "h4", 1);
        i0.ɵɵtemplate(2, OrganizationNumberOfSessionsComponent_ng_container_2_Template, 2, 3, "ng-container", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.isLoadingData && (ctx.chartOption == null ? null : ctx.chartOption.series.length));
    } }, directives: [i7.JhiTranslateDirective, i8.NgIf, i9.NgxEchartsDirective], styles: [".total-sessions[_ngcontent-%COMP%]{color:#9db7b0;font-family:\"Open Sans\",serif;font-size:14px;font-style:normal;font-weight:400;line-height:120%}.total-sessions-div[_ngcontent-%COMP%]{position:absolute;top:40%;left:calc(20% - 20px);z-index:1000;width:200px;text-align:center}.total-sessions-count[_ngcontent-%COMP%]{margin-bottom:0 !important;line-height:30px}.e-charts[_ngcontent-%COMP%]{height:283px;width:100%;position:relative}@media(max-width: 500px){.e-charts[_ngcontent-%COMP%]{height:400px}}@media(min-width: 1400px){.total-sessions-div[_ngcontent-%COMP%]{left:75px}}"] });
