import * as i0 from "@angular/core";
export class JhiTruncateCharactersPipe {
    transform(input, chars, breakOnWord) {
        if (isNaN(chars)) {
            return input;
        }
        if (chars <= 0) {
            return '';
        }
        if (input && input.length > chars) {
            input = input.substring(0, chars);
            if (!breakOnWord) {
                const lastspace = input.lastIndexOf(' ');
                // Get last space
                if (lastspace !== -1) {
                    input = input.substr(0, lastspace);
                }
            }
            else {
                while (input.endsWith(' ')) {
                    input = input.substr(0, input.length - 1);
                }
            }
            return input + '...';
        }
        return input;
    }
}
JhiTruncateCharactersPipe.ɵfac = function JhiTruncateCharactersPipe_Factory(t) { return new (t || JhiTruncateCharactersPipe)(); };
JhiTruncateCharactersPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "truncateCharacters", type: JhiTruncateCharactersPipe, pure: true });
