import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../language/jhi-translate.directive";
function JhiMetricsGarbageCollectorComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "number");
    i0.ɵɵpipe(4, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "ngb-progressbar", 5);
    i0.ɵɵelementStart(6, "span");
    i0.ɵɵtext(7);
    i0.ɵɵpipe(8, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2(" GC Live Data Size/GC Max Data Size (", i0.ɵɵpipeBind2(3, 7, ctx_r0.garbageCollectorMetrics["jvm.gc.live.data.size"] / 1048576, "1.0-0"), "M / ", i0.ɵɵpipeBind2(4, 10, ctx_r0.garbageCollectorMetrics["jvm.gc.max.data.size"] / 1048576, "1.0-0"), "M)");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("max", ctx_r0.garbageCollectorMetrics["jvm.gc.max.data.size"])("value", ctx_r0.garbageCollectorMetrics["jvm.gc.live.data.size"])("striped", true)("animated", false);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(8, 13, 100 * ctx_r0.garbageCollectorMetrics["jvm.gc.live.data.size"] / ctx_r0.garbageCollectorMetrics["jvm.gc.max.data.size"], "1.0-2"), "%");
} }
function JhiMetricsGarbageCollectorComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "number");
    i0.ɵɵpipe(4, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "ngb-progressbar", 5);
    i0.ɵɵelementStart(6, "span");
    i0.ɵɵtext(7);
    i0.ɵɵpipe(8, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2(" GC Memory Promoted/GC Memory Allocated (", i0.ɵɵpipeBind2(3, 7, ctx_r1.garbageCollectorMetrics["jvm.gc.memory.promoted"] / 1048576, "1.0-0"), "M / ", i0.ɵɵpipeBind2(4, 10, ctx_r1.garbageCollectorMetrics["jvm.gc.memory.allocated"] / 1048576, "1.0-0"), "M)");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("max", ctx_r1.garbageCollectorMetrics["jvm.gc.memory.allocated"])("value", ctx_r1.garbageCollectorMetrics["jvm.gc.memory.promoted"])("striped", true)("animated", false);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(8, 13, 100 * ctx_r1.garbageCollectorMetrics["jvm.gc.memory.promoted"] / ctx_r1.garbageCollectorMetrics["jvm.gc.memory.allocated"], "1.0-2"), "%");
} }
function JhiMetricsGarbageCollectorComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 0);
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵtext(2, "Classes loaded");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 7);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r2.garbageCollectorMetrics.classesLoaded);
} }
function JhiMetricsGarbageCollectorComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 0);
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵtext(2, "Classes unloaded");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 7);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r3.garbageCollectorMetrics.classesUnloaded);
} }
function JhiMetricsGarbageCollectorComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelementStart(1, "table", 9);
    i0.ɵɵelementStart(2, "thead");
    i0.ɵɵelementStart(3, "tr");
    i0.ɵɵelement(4, "th");
    i0.ɵɵelementStart(5, "th", 10);
    i0.ɵɵtext(6, "Count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "th", 11);
    i0.ɵɵtext(8, "Mean");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "th", 12);
    i0.ɵɵtext(10, "Min");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "th", 13);
    i0.ɵɵtext(12, "p50");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "th", 14);
    i0.ɵɵtext(14, "p75");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "th", 15);
    i0.ɵɵtext(16, "p95");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(17, "th", 16);
    i0.ɵɵtext(18, "p99");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(19, "th", 17);
    i0.ɵɵtext(20, "Max");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(21, "tbody");
    i0.ɵɵelementStart(22, "tr");
    i0.ɵɵelementStart(23, "td");
    i0.ɵɵtext(24, "jvm.gc.pause");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(25, "td", 18);
    i0.ɵɵtext(26);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(27, "td", 18);
    i0.ɵɵtext(28);
    i0.ɵɵpipe(29, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(30, "td", 18);
    i0.ɵɵtext(31);
    i0.ɵɵpipe(32, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(33, "td", 18);
    i0.ɵɵtext(34);
    i0.ɵɵpipe(35, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(36, "td", 18);
    i0.ɵɵtext(37);
    i0.ɵɵpipe(38, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(39, "td", 18);
    i0.ɵɵtext(40);
    i0.ɵɵpipe(41, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(42, "td", 18);
    i0.ɵɵtext(43);
    i0.ɵɵpipe(44, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(45, "td", 18);
    i0.ɵɵtext(46);
    i0.ɵɵpipe(47, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance(26);
    i0.ɵɵtextInterpolate(ctx_r4.garbageCollectorMetrics["jvm.gc.pause"].count);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(29, 8, ctx_r4.garbageCollectorMetrics["jvm.gc.pause"].mean, "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(32, 11, ctx_r4.garbageCollectorMetrics["jvm.gc.pause"]["0.0"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(35, 14, ctx_r4.garbageCollectorMetrics["jvm.gc.pause"]["0.5"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(38, 17, ctx_r4.garbageCollectorMetrics["jvm.gc.pause"]["0.75"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(41, 20, ctx_r4.garbageCollectorMetrics["jvm.gc.pause"]["0.95"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(44, 23, ctx_r4.garbageCollectorMetrics["jvm.gc.pause"]["0.99"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(47, 26, ctx_r4.garbageCollectorMetrics["jvm.gc.pause"].max, "1.0-3"));
} }
export class JhiMetricsGarbageCollectorComponent {
}
JhiMetricsGarbageCollectorComponent.ɵfac = function JhiMetricsGarbageCollectorComponent_Factory(t) { return new (t || JhiMetricsGarbageCollectorComponent)(); };
JhiMetricsGarbageCollectorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiMetricsGarbageCollectorComponent, selectors: [["jhi-metrics-garbagecollector"]], inputs: { garbageCollectorMetrics: "garbageCollectorMetrics", updating: "updating" }, decls: 9, vars: 5, consts: [[1, "row"], [1, "col-md-4"], [4, "ngIf"], ["class", "row", 4, "ngIf"], ["class", "table-responsive", 4, "ngIf"], ["type", "success", 3, "max", "value", "striped", "animated"], [1, "col-md-9"], [1, "col-md-3", "text-right"], [1, "table-responsive"], [1, "table", "table-striped"], ["jhiTranslate", "metrics.servicesstats.table.count", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.mean", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.min", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.p50", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.p75", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.p95", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.p99", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.max", 1, "text-right"], [1, "text-right"]], template: function JhiMetricsGarbageCollectorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵtemplate(2, JhiMetricsGarbageCollectorComponent_div_2_Template, 9, 16, "div", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "div", 1);
        i0.ɵɵtemplate(4, JhiMetricsGarbageCollectorComponent_div_4_Template, 9, 16, "div", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 1);
        i0.ɵɵtemplate(6, JhiMetricsGarbageCollectorComponent_div_6_Template, 5, 1, "div", 3);
        i0.ɵɵtemplate(7, JhiMetricsGarbageCollectorComponent_div_7_Template, 5, 1, "div", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(8, JhiMetricsGarbageCollectorComponent_div_8_Template, 48, 29, "div", 4);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.garbageCollectorMetrics);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.garbageCollectorMetrics);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.garbageCollectorMetrics);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.garbageCollectorMetrics);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating && ctx.garbageCollectorMetrics);
    } }, directives: [i1.NgIf, i2.NgbProgressbar, i3.JhiTranslateDirective], pipes: [i1.DecimalPipe], encapsulation: 2 });
