import { EventEmitter } from '@angular/core';
import { AvailableCoachSlotsCalendar } from '../../../../session/schedule-session/available-coach-slots/available-coach-slots-calendar.model';
import { DayAvailableCoachSlots } from '../../../../session/schedule-session/available-coach-slots/day-available-coach-slots.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../ng-jhipster/language/jhi-translate.directive";
const _c0 = function (a0) { return { year: a0 }; };
function AvailableCoachSlotsCalendarComponent_ng_container_0_h3_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h3", 12);
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵpropertyInterpolate1("jhiTranslate", "sessionSchedule.header.months.", (ctx_r1.selectedDate == null ? null : ctx_r1.selectedDate.day.month()) + 1, "");
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(2, _c0, ctx_r1.selectedDate == null ? null : ctx_r1.selectedDate.day.year()));
} }
function AvailableCoachSlotsCalendarComponent_ng_container_0_div_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelement(1, "span", 14);
    i0.ɵɵelement(2, "span", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const weekDay_r4 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate1("jhiTranslate", "sessionSchedule.header.days3.", weekDay_r4.dayNumber, "");
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate1("jhiTranslate", "sessionSchedule.header.days2.", weekDay_r4.dayNumber, "");
} }
function AvailableCoachSlotsCalendarComponent_ng_container_0_div_12_div_1_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 18);
    i0.ɵɵlistener("click", function AvailableCoachSlotsCalendarComponent_ng_container_0_div_12_div_1_ng_container_1_Template_div_click_1_listener() { i0.ɵɵrestoreView(_r11); const day_r7 = i0.ɵɵnextContext().$implicit; const ctx_r9 = i0.ɵɵnextContext(3); return ctx_r9.selectDay(day_r7); });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const day_r7 = i0.ɵɵnextContext().$implicit;
    const ctx_r8 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("day-disabled", !(day_r7.coachesSlots == null ? null : day_r7.coachesSlots.length))("selected-day", (day_r7.day == null ? null : day_r7.day.date()) === (ctx_r8.selectedDate == null ? null : ctx_r8.selectedDate.day.date()))("text-light", !(day_r7.coachesSlots == null ? null : day_r7.coachesSlots.length));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(day_r7.day.date());
} }
function AvailableCoachSlotsCalendarComponent_ng_container_0_div_12_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17);
    i0.ɵɵtemplate(1, AvailableCoachSlotsCalendarComponent_ng_container_0_div_12_div_1_ng_container_1_Template, 3, 7, "ng-container", 0);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const day_r7 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", day_r7.day);
} }
function AvailableCoachSlotsCalendarComponent_ng_container_0_div_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtemplate(1, AvailableCoachSlotsCalendarComponent_ng_container_0_div_12_div_1_Template, 2, 1, "div", 16);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const week_r5 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", week_r5);
} }
function AvailableCoachSlotsCalendarComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵlistener("click", function AvailableCoachSlotsCalendarComponent_ng_container_0_Template_div_click_2_listener() { i0.ɵɵrestoreView(_r14); const ctx_r13 = i0.ɵɵnextContext(); return ctx_r13.showPrevious(); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(3, "svg", 3);
    i0.ɵɵelement(4, "use", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(5, "div", 5);
    i0.ɵɵtemplate(6, AvailableCoachSlotsCalendarComponent_ng_container_0_h3_6_Template, 1, 4, "h3", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 7);
    i0.ɵɵlistener("click", function AvailableCoachSlotsCalendarComponent_ng_container_0_Template_div_click_7_listener() { i0.ɵɵrestoreView(_r14); const ctx_r15 = i0.ɵɵnextContext(); return ctx_r15.showNext(); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(8, "svg", 8);
    i0.ɵɵelement(9, "use", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(10, "div", 9);
    i0.ɵɵtemplate(11, AvailableCoachSlotsCalendarComponent_ng_container_0_div_11_Template, 3, 2, "div", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(12, AvailableCoachSlotsCalendarComponent_ng_container_0_div_12_Template, 2, 1, "div", 11);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("px-3", !ctx_r0.isSmall);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedDate);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngForOf", ctx_r0.availableCoachSlotsCalendar.weekDays);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.availableCoachSlotsCalendar.weeks);
} }
export class AvailableCoachSlotsCalendarComponent {
    constructor() {
        this.showNextPeriod = new EventEmitter();
        this.showPreviousPeriod = new EventEmitter();
        this.selectedDateChange = new EventEmitter();
    }
    set availableCoachSlotsCalendar(value) {
        this._availableCoachSlotsCalendar = value;
        this.selectFirstDayWithAvailableCoachSlots();
    }
    get availableCoachSlotsCalendar() {
        return this._availableCoachSlotsCalendar;
    }
    ngOnInit() {
    }
    selectDay(day) {
        var _a;
        if ((_a = day.coachesSlots) === null || _a === void 0 ? void 0 : _a.length) {
            this.selectedDate = day;
            // to prevent ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
                this.selectedDateChange.emit(day);
            }, 1);
        }
    }
    selectFirstDayWithAvailableCoachSlots() {
        var _a;
        if (this.availableCoachSlotsCalendar.hasAnyAvailableSlot) {
            if (this.selectedDate) {
                // find the new DayAvailableCoachSlot of the same date
                this.selectedDate = this.availableCoachSlotsCalendar.findByDate(this.selectedDate.day);
                if (this.selectedDate) {
                    this.selectDay(this.selectedDate);
                }
            }
            if (!this.selectedDate) {
                for (const week of this.availableCoachSlotsCalendar.weeks) {
                    for (const day of week) {
                        if (day.day && ((_a = day.coachesSlots) === null || _a === void 0 ? void 0 : _a.length)) {
                            this.selectDay(day);
                            return;
                        }
                    }
                }
            }
        }
    }
    showNext() {
        this.selectedDate = null;
        this.showNextPeriod.emit();
    }
    showPrevious() {
        this.selectedDate = null;
        this.showPreviousPeriod.emit();
    }
}
AvailableCoachSlotsCalendarComponent.ɵfac = function AvailableCoachSlotsCalendarComponent_Factory(t) { return new (t || AvailableCoachSlotsCalendarComponent)(); };
AvailableCoachSlotsCalendarComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AvailableCoachSlotsCalendarComponent, selectors: [["available-coach-slots-calendar"]], inputs: { availableCoachSlotsCalendar: "availableCoachSlotsCalendar", viewMode: "viewMode", selectedDate: "selectedDate", isSmall: "isSmall" }, outputs: { showNextPeriod: "showNextPeriod", showPreviousPeriod: "showPreviousPeriod", selectedDateChange: "selectedDateChange" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [1, "row", "mb-4"], ["id", "calendar-previous-btn", 1, "col-2", "pointer", "text-left", 3, "click"], [1, "angle-icon", 2, "height", "22px", "width", "22px", "transform", "rotate(90deg)"], [0, "xlink", "href", "../../../../../../../../content/images/sprite.svg?v=3#angle-down"], [1, "col-8", "text-center"], [3, "jhiTranslate", "translateValues", 4, "ngIf"], ["id", "calendar-next-btn", 1, "col-2", "pointer", "text-right", 3, "click"], [1, "angle-icon", 2, "height", "22px", "width", "22px", "transform", "rotate(-90deg)"], [1, "row"], ["class", "col text-center", "style", "width: 14.2%", 4, "ngFor", "ngForOf"], ["class", "row", 4, "ngFor", "ngForOf"], [3, "jhiTranslate", "translateValues"], [1, "col", "text-center", 2, "width", "14.2%"], [1, "d-none", "d-lg-inline", 3, "jhiTranslate"], [1, "d-inline", "d-lg-none", 3, "jhiTranslate"], ["class", "col py-2 text-center", "style", "width: 14.2%", 4, "ngFor", "ngForOf"], [1, "col", "py-2", "text-center", 2, "width", "14.2%"], [1, "day", 3, "click"]], template: function AvailableCoachSlotsCalendarComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, AvailableCoachSlotsCalendarComponent_ng_container_0_Template, 13, 5, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.availableCoachSlotsCalendar == null ? null : ctx.availableCoachSlotsCalendar.hasAnyAvailableSlot);
    } }, directives: [i1.NgIf, i1.NgForOf, i2.JhiTranslateDirective], styles: [".day[_ngcontent-%COMP%]{cursor:pointer;display:inline-block;width:2rem;height:2rem;text-align:center;padding:.4rem 0}.day-disabled[_ngcontent-%COMP%]{cursor:default !important}.selected-day[_ngcontent-%COMP%]{background-color:#44a38d;border-radius:50%;color:#fff}"] });
