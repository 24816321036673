import { RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { LoginComponent } from './login/login.component';
import { InukaNgSharedLibsModule } from '../shared/libs/shared-libs.module';
import { InukaNgSharedModule } from '../shared/shared.module';
import { WellbeingScanComponent } from './wellbeing-scan/wellbeing-scan.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HowToStartComponent } from './how-to-start/how-to-start.component';
import { InukaServicesComponent } from './inuka-services/inuka-services.component';
import { RegisterZimbabweComponent } from './register-zimbabwe/register-zimbabwe.component';
import { WhatPeopleSayComponent } from './what-people-say/what-people-say.component';
import { WellbeingStartComponent } from './wellbeing-scan/wellbeing-start/wellbeing-start.component';
import { RedirectComponent } from '../shared/redirect-component/redirect.component';
import { UserRouteAccessService } from '../core/auth/user-route-access-service';
import { Authority } from '../shared/constants/authority.constants';
import { PostScanInfoComponent } from './wellbeing-scan/post-scan-info/post-scan-info.component';
import { RegisterDemoComponent } from './register-demo/register-demo.component';
import { RegisterCoronaComponent } from './register-corona/register-corona.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { WellbeingResultComponent } from './wellbeing-result/wellbeing-result.component';
import { IndexUserModule } from './index-user/index-user.module';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { IndexCoachComponent } from './index-coach/index-coach.component';
import { UserPaymentComponent } from './user-payment/user-payment.component';
import { PaymentSuccessComponent } from './payment/payment-success/payment-success.component';
import { CoachSettingsComponent } from './coach-settings/coach-settings.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ActivateComponent } from './activate/activate.component';
import { UnsubscribeComponent } from '../account/unsubscribe/unsubscribe.component';
import { MyStatisticsComponent } from './index-coach/my-statistics/my-statistics.component';
import { WellbeingResultDela2Component } from './wellbeing-result/wellbeing-result-dela-2.component';
import { NgIdleModule } from '@ng-idle/core';
import { WellbeingResult2Component } from './wellbeing-result/wellbeing-result2.component';
import { LookingForHelpTopicsComponent } from './wellbeing-scan/looking-for-help-topics/looking-for-help-topics.component';
import { WellbeingScanFinishComponent } from './wellbeing-scan/wellbeing-scan-finish/wellbeing-scan-finish.component';
import { LandingComponent } from './landing/landing.component';
import { InukaNgActionPlanModule } from './action-plan/action-plan.module';
import { MatButtonModule } from '@angular/material/button';
import { ChartsModule } from 'ng2-charts';
import { MyAvailabilityComponent } from './my-availability/my-availability.component';
import { WellbeingStartStandaloneComponent } from './wellbeing-scan/wellbeing-start-standalone/wellbeing-start-standalone.component';
import { WellbeingScanStandaloneCallbackComponent } from './wellbeing-scan/wellbeing-scan-standalone-callback/wellbeing-scan-standalone-callback.component';
import { WellbeingScanExtraQuestionsComponent } from './wellbeing-scan/wellbeing-scan-extra-questions/wellbeing-scan-extra-questions.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InukaNgSessionModule } from './session/session.module';
import { OrganizationResourcesPageComponent } from './organization-resources/organization-resources-page.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { UnsubscribeEmailComponent } from './notification-preference/unsubscribe-email/unsubscribe-email.component';
import { EmailFeedbackComponent } from './email-feedback/email-feedback.component';
import { InukaNgVideoCallModule } from './video-call/video-call.module';
import { ResourcesForHelpPageComponent } from '../shared/resources-for-help/resources-for-help-page.component';
import { MatchWithACoachStartComponent } from './wellbeing-scan/match-with-a-coach/match-with-a-coach-start.component';
import { MatchWithACoachComponent } from './wellbeing-scan/match-with-a-coach/match-with-a-coach.component';
import { OrganizationDashboardModule } from './organization-dashboard/organization-dashboard.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ng-idle/core";
export class InukaNgEntityModule {
}
InukaNgEntityModule.ɵfac = function InukaNgEntityModule_Factory(t) { return new (t || InukaNgEntityModule)(); };
InukaNgEntityModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgEntityModule });
InukaNgEntityModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[
            PickerModule,
            RouterModule.forChild([
                { path: 'redirect', component: RedirectComponent },
                {
                    path: 'forgot-password',
                    component: ForgotPasswordComponent,
                    data: { pageTitle: 'reset.request.title' },
                },
                {
                    path: 'password-reset',
                    component: PasswordResetComponent,
                    data: { pageTitle: 'reset.request.title' },
                },
                {
                    path: 'how-to-start',
                    component: HowToStartComponent,
                    data: { pageTitle: 'howToStart.title', navbarTitle: 'navbarTitleHowToStart' },
                },
                {
                    path: 'inuka-services',
                    component: InukaServicesComponent,
                    data: { pageTitle: 'inukaServices.title' },
                },
                {
                    path: 'login',
                    component: LoginComponent,
                    data: { pageTitle: 'login.title' },
                },
                {
                    path: 'register-zimbabwe',
                    component: RegisterZimbabweComponent,
                    data: { pageTitle: 'register.title' },
                },
                {
                    path: 'register-demo',
                    component: RegisterDemoComponent,
                    data: { pageTitle: 'register.title' },
                },
                {
                    path: 'register-corona',
                    component: RegisterCoronaComponent,
                    data: { pageTitle: 'register.title' },
                },
                {
                    path: 'account/unsubscribe/:activationKey',
                    component: UnsubscribeComponent,
                },
                {
                    path: '',
                    component: IndexComponent,
                    data: { authorities: [], pageTitle: 'register.welcome.title' },
                },
                {
                    path: 'wellbeing-scan',
                    redirectTo: 'wellbeing-scan/1',
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'wellbeing-scan/:question',
                    component: WellbeingScanComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'wellbeing-extra-questions/:question',
                    component: WellbeingScanExtraQuestionsComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'wellbeing-start',
                    component: WellbeingStartComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'wellbeing-finish/:isExtraQuestion',
                    component: WellbeingScanFinishComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'match-with-a-coach-start',
                    component: MatchWithACoachStartComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'match-with-a-coach/:step',
                    component: MatchWithACoachComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'looking-for-help-topics',
                    component: LookingForHelpTopicsComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'wellbeing-start-standalone',
                    component: WellbeingStartStandaloneComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'wellbeing-scan-standalone-callback',
                    component: WellbeingScanStandaloneCallbackComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'wellbeing-result',
                    component: WellbeingResultComponent,
                    data: {
                        pageTitle: 'wellBeingResult.title',
                        navbarTitle: 'wellbeingResult',
                    },
                },
                {
                    path: 'wellbeing-result2/:totalScore',
                    component: WellbeingResult2Component,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.USER],
                        pageTitle: 'wellBeingResult.title',
                        navbarTitle: 'wellbeingResult',
                    },
                },
                // {
                //   path: 'wellbeing-result-chart',
                //   component: WellbeingResultChartComponent,
                //   canActivate: [UserRouteAccessService],
                //   data: { authorities: [Authority.USER] }
                // },
                // {
                //   path: 'wellbeing-trend',
                //   component: WellbeingTrendComponent,
                //   canActivate: [UserRouteAccessService],
                //   data: { authorities: [Authority.USER], pageTitle: 'wellBeingResult.title' }
                // },
                {
                    path: 'wellbeing-result-dela-2',
                    component: WellbeingResultDela2Component,
                    canActivate: [UserRouteAccessService],
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'post-scan-info',
                    component: PostScanInfoComponent,
                    canActivate: [UserRouteAccessService],
                    data: { authorities: [Authority.USER], pageTitle: 'home.postScanInfo' },
                },
                {
                    path: 'what-people-say',
                    component: WhatPeopleSayComponent,
                    data: { pageTitle: 'whatPeopleSay.title' },
                },
                {
                    path: 'user-settings',
                    component: UserSettingsComponent,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.USER],
                        pageTitle: 'settings.userTitle',
                        navbarTitle: 'navbarTitleUserSettings',
                    },
                },
                {
                    path: 'coach-settings',
                    component: CoachSettingsComponent,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.COACH],
                        pageTitle: 'settings.coachTitle',
                        navbarTitle: 'navbarTitleUserSettings',
                    },
                },
                {
                    path: 'my-availability',
                    component: MyAvailabilityComponent,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.COACH],
                        pageTitle: 'settings.myAvailabilityTitle',
                        navbarTitle: 'navbarTitleMyAvailability',
                    },
                },
                {
                    path: 'my-statistics',
                    component: MyStatisticsComponent,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.COACH],
                        pageTitle: 'settings.myStatisticsTitle',
                        navbarTitle: 'navbarTitleMyStatistics',
                    },
                },
                {
                    path: 'organization-resources',
                    component: OrganizationResourcesPageComponent,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.USER],
                        pageTitle: 'settings.supportResourcesTitle',
                        navbarTitle: 'navbarTitleSupportResources',
                    },
                },
                {
                    path: 'resources-for-help',
                    component: ResourcesForHelpPageComponent,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.USER],
                        pageTitle: 'settings.supportResourcesTitle',
                        navbarTitle: 'navbarTitleSupportResources',
                    },
                },
                {
                    path: 'onboarding',
                    component: OnboardingComponent,
                    canActivate: [UserRouteAccessService],
                    data: { authorities: [Authority.USER], pageTitle: 'inukaNgApp.onboarding.title' },
                },
                {
                    path: 'index-coach',
                    component: IndexCoachComponent,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.COACH, Authority.CUSTOMER_CARE],
                        pageTitle: 'inukaNgApp.navbar.title.inuka',
                    },
                },
                {
                    path: 'user-payment',
                    component: UserPaymentComponent,
                    canActivate: [UserRouteAccessService],
                    data: {
                        authorities: [Authority.USER, Authority.COACH, Authority.CUSTOMER_CARE],
                        pageTitle: 'payment.title',
                        navbarTitle: 'navbarTitleUserPayment',
                    },
                },
                {
                    path: 'payment-success',
                    component: PaymentSuccessComponent,
                    data: {
                        authorities: [Authority.USER],
                        pageTitle: 'payment.title',
                        navbarTitle: 'navbarTitleUserPayment',
                    },
                },
                {
                    path: 'activate/:activationKey',
                    component: ActivateComponent,
                    data: { pageTitle: 'activate.title' },
                },
                {
                    path: 'landing',
                    component: LandingComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'account/unsubscribe',
                    component: UnsubscribeEmailComponent,
                    data: { pageTitle: 'home.title' },
                },
                {
                    path: 'email-feedback',
                    component: EmailFeedbackComponent,
                    data: { pageTitle: 'emailFeedback.title' },
                },
                {
                    path: '',
                    loadChildren: () => import('./index-user/index-user.module').then(m => m.IndexUserModule),
                },
                {
                    path: 'pay',
                    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
                },
                {
                    path: 'access-log-inuka',
                    loadChildren: () => import('./access-log-inuka/access-log-inuka.module').then(m => m.InukaNgAccessLogInukaModule),
                },
                {
                    path: 'geoip-blocks',
                    loadChildren: () => import('./geoip-blocks/geoip-blocks.module').then(m => m.InukaNgGeoipBlocksModule),
                },
                {
                    path: 'geoip-country',
                    loadChildren: () => import('./geoip-country/geoip-country.module').then(m => m.InukaNgGeoipCountryModule),
                },
                {
                    path: 'action-plan',
                    loadChildren: () => import('./action-plan/action-plan.module').then(m => m.InukaNgActionPlanModule),
                },
                {
                    path: 'action-plan-item',
                    loadChildren: () => import('./action-plan-item/action-plan-item.module').then(m => m.InukaNgActionPlanItemModule),
                },
                {
                    path: 'coach-availability',
                    loadChildren: () => import('./coach-availability/coach-availability.module').then(m => m.InukaNgCoachAvailabilityModule),
                },
                {
                    path: 'message',
                    loadChildren: () => import('./message/message.module').then(m => m.InukaNgMessageModule),
                },
                {
                    path: 'notification',
                    loadChildren: () => import('./notification/notification.module').then(m => m.InukaNgNotificationModule),
                },
                {
                    path: 'conversationn',
                    loadChildren: () => import('./conversationn/conversationn.module').then(m => m.InukaNgConversationnModule),
                },
                {
                    path: 'offline-message',
                    loadChildren: () => import('./offline-message/offline-message.module').then(m => m.InukaNgOfflineMessageModule),
                },
                {
                    path: 'user-organization-code',
                    loadChildren: () => import('./user-organization-code/user-organization-code.module').then(m => m.InukaNgUserOrganizationCodeModule),
                },
                {
                    path: 'script',
                    loadChildren: () => import('../admin/script/script.module').then(m => m.InukaNgScriptModule),
                },
                {
                    path: 'session',
                    loadChildren: () => import('./session/session.module').then(m => m.InukaNgSessionModule),
                },
                {
                    path: 'srq-completed',
                    loadChildren: () => import('./srq-completed/srq-completed.module').then(m => m.InukaNgSRQCompletedModule),
                },
                {
                    path: 'token',
                    loadChildren: () => import('./token/token.module').then(m => m.InukaNgTokenModule),
                },
                {
                    path: 'user-voucher',
                    loadChildren: () => import('./user-voucher/user-voucher.module').then(m => m.InukaNgUserVoucherModule),
                },
                {
                    path: 'job',
                    loadChildren: () => import('./job/job.module').then(m => m.InukaNgJobModule),
                },
                {
                    path: 'pricing',
                    loadChildren: () => import('./pricing/pricing.module').then(m => m.InukaNgPricingModule),
                },
                {
                    path: 'questionarre-answer',
                    loadChildren: () => import('./questionarre-answer/questionarre-answer.module').then(m => m.InukaNgQuestionarreAnswerModule),
                },
                // {
                //   path: 'resource-for-help',
                //   loadChildren: () => import('./resource-for-help/resource-for-help.module').then(m => m.InukaNgResourceForHelpModule)
                // },
                {
                    path: 'slot',
                    loadChildren: () => import('./slot/slot.module').then(m => m.InukaNgSlotModule),
                },
                {
                    path: 'user-details',
                    loadChildren: () => import('./user-details/user-details.module').then(m => m.InukaNgUserDetailsModule),
                },
                {
                    path: 'canned-messages',
                    loadChildren: () => import('../admin/canned-messages/canned-messages.module').then(m => m.InukaNgCannedMessagesModule),
                },
                {
                    path: 'notification-preference-admin',
                    loadChildren: () => import('../admin/notification-preferences/notification-preference-admin.module').then(m => m.InukaNgNotificationPreferenceAdminModule),
                },
                {
                    path: 'feedback',
                    loadChildren: () => import('../admin/feedback/feedback.module').then(m => m.InukaNgFeedbackModule),
                },
                {
                    path: 'coach-availability-preference',
                    loadChildren: () => import('./admin/coach-availability-preference/coach-availability-preference.module').then(m => m.InukaNgCoachAvailabilityPreferenceModule),
                },
                {
                    path: 'registration',
                    loadChildren: () => import('./registration/registration.module').then(m => m.InukaNgRegistrationModule),
                },
                /* jhipster-needle-add-entity-route - JHipster will add entity modules routes here */
            ]),
            InukaNgSharedLibsModule,
            InukaNgSharedModule,
            ChartsModule,
            IndexUserModule,
            AngularMultiSelectModule,
            FullCalendarModule,
            NgIdleModule.forRoot(),
            InukaNgActionPlanModule,
            MatButtonModule,
            MatCheckboxModule,
            MatTooltipModule,
            InukaNgSessionModule,
            PasswordStrengthMeterModule,
            InukaNgVideoCallModule,
            OrganizationDashboardModule,
        ]] });
