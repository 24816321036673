import * as i0 from "@angular/core";
export class JhiOrderByPipe {
    transform(values, predicate = '', reverse = false) {
        if (predicate === '') {
            return reverse ? values.sort().reverse() : values.sort();
        }
        return values.sort((a, b) => {
            if (a[predicate] < b[predicate]) {
                return reverse ? 1 : -1;
            }
            else if (b[predicate] < a[predicate]) {
                return reverse ? -1 : 1;
            }
            return 0;
        });
    }
}
JhiOrderByPipe.ɵfac = function JhiOrderByPipe_Factory(t) { return new (t || JhiOrderByPipe)(); };
JhiOrderByPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "orderBy", type: JhiOrderByPipe, pure: true });
