import { Location } from '@angular/common';
import { BaseComponent } from '../../../shared/base.component';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TrackerService } from '../../../core/tracker/tracker.service';
import { ActivatedRoute, Router } from "@angular/router";
import { QuestionarreAnswerService } from '../../questionarre-answer/questionarre-answer.service';
import { AccountService } from '../../../core/auth/account.service';
import { UserDetailsService } from '../../user-details/user-details.service';
import { WellbeingScanService } from '../../wellbeing-scan/wellbeing-scan.service';
import { LookingForHelpTopicsService } from '../../looking-for-help-topics/looking-for-help-topics.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ngx-webstorage";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../core/tracker/tracker.service";
import * as i5 from "@angular/router";
import * as i6 from "../../questionarre-answer/questionarre-answer.service";
import * as i7 from "../../../core/auth/account.service";
import * as i8 from "../../user-details/user-details.service";
import * as i9 from "../../wellbeing-scan/wellbeing-scan.service";
import * as i10 from "../../looking-for-help-topics/looking-for-help-topics.service";
import * as i11 from "../../../shared/wellbeing-header/wellbeing-header.component";
import * as i12 from "../../../ng-jhipster/language/jhi-translate.directive";
export class WellbeingScanFinishComponent extends BaseComponent {
    constructor(location, sessionStorage, modalService, trackerService, router, questionarreAnswerService, accountService, userDetailsService, wellbeingScanService, activatedRoute, lookingForHelpTopicsService) {
        super(sessionStorage, modalService);
        this.location = location;
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.trackerService = trackerService;
        this.router = router;
        this.questionarreAnswerService = questionarreAnswerService;
        this.accountService = accountService;
        this.userDetailsService = userDetailsService;
        this.wellbeingScanService = wellbeingScanService;
        this.activatedRoute = activatedRoute;
        this.lookingForHelpTopicsService = lookingForHelpTopicsService;
        this.answers = [];
    }
    ngOnInit() {
        this.activatedRoute.params.subscribe((params) => {
            this.isExtraQuestion = params["isExtraQuestion"];
            console.log(this.isExtraQuestion);
        });
    }
    srqSubmit() {
        this.isRequestInProgress = true;
        this.trackerService.sendActivity("self_scan_completed");
        if (this.accountService.isAuthenticated()) {
            if (this.lookingForHelpTopicsService.hasAnySavedHelpTopics()) {
                this.lookingForHelpTopicsService.submitUserLookingForHelpTopics().subscribe();
            }
            this.wellbeingScanService.submitAnswers().subscribe((result) => {
                if (result) {
                    console.log('Save success');
                    this.router.navigate(['/wellbeing-result']).then();
                }
            }, () => {
                console.log('Save error');
                this.isRequestInProgress = false;
            }, () => {
                this.isRequestInProgress = false;
            });
        }
        else {
            this.isRequestInProgress = false;
            this.router.navigate(["/register-corporate"]).then();
        }
    }
    goBack() {
        this.isExtraQuestion === "noExtraQuestion" ? this.router.navigate(["/wellbeing-scan", 20]).then() : this.location.back();
    }
}
WellbeingScanFinishComponent.ɵfac = function WellbeingScanFinishComponent_Factory(t) { return new (t || WellbeingScanFinishComponent)(i0.ɵɵdirectiveInject(i1.Location), i0.ɵɵdirectiveInject(i2.SessionStorageService), i0.ɵɵdirectiveInject(i3.NgbModal), i0.ɵɵdirectiveInject(i4.TrackerService), i0.ɵɵdirectiveInject(i5.Router), i0.ɵɵdirectiveInject(i6.QuestionarreAnswerService), i0.ɵɵdirectiveInject(i7.AccountService), i0.ɵɵdirectiveInject(i8.UserDetailsService), i0.ɵɵdirectiveInject(i9.WellbeingScanService), i0.ɵɵdirectiveInject(i5.ActivatedRoute), i0.ɵɵdirectiveInject(i10.LookingForHelpTopicsService)); };
WellbeingScanFinishComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WellbeingScanFinishComponent, selectors: [["wellbeing-scan-finish"]], features: [i0.ɵɵInheritDefinitionFeature], decls: 14, vars: 2, consts: [[1, "olha-theme"], [1, "container", "bg-2"], ["jhiTranslate", "inukaNgApp.wellbeingScan.thatsIt", 2, "margin-top", "90px"], ["src", "../../../../../content/images/wellbeing/wellbeing-scan-finished.png", "alt", "well done", 2, "width", "336px"], [1, "d-none", "d-md-block", "d-lg-block", "d-xl-block", "mt-3"], [1, "d-flex", "justify-content-center"], ["id", "ss-back-btn", "jhiTranslate", "inukaNgApp.wellbeingScan.back", 1, "btn", "btn-secondary", "btn-large", "m-2", 3, "click"], ["id", "ss-show-results-btn", "jhiTranslate", "inukaNgApp.wellbeingScan.toResults", 1, "btn", "btn-primary", "btn-large", "m-2", 3, "disabled", "click"], [1, "d-md-none", "mt-3"], ["jhiTranslate", "inukaNgApp.wellbeingScan.toResults", 1, "btn", "btn-primary", "btn-large", "m-2", 3, "disabled", "click"], ["jhiTranslate", "inukaNgApp.wellbeingScan.back", 1, "btn", "btn-secondary", "btn-large", "m-2", 3, "click"]], template: function WellbeingScanFinishComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "wellbeing-header");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelement(3, "h2", 2);
        i0.ɵɵelement(4, "img", 3);
        i0.ɵɵelementStart(5, "div", 4);
        i0.ɵɵelementStart(6, "div", 5);
        i0.ɵɵelementStart(7, "button", 6);
        i0.ɵɵlistener("click", function WellbeingScanFinishComponent_Template_button_click_7_listener() { return ctx.goBack(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "button", 7);
        i0.ɵɵlistener("click", function WellbeingScanFinishComponent_Template_button_click_8_listener() { return ctx.srqSubmit(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 8);
        i0.ɵɵelementStart(10, "div", 5);
        i0.ɵɵelementStart(11, "button", 9);
        i0.ɵɵlistener("click", function WellbeingScanFinishComponent_Template_button_click_11_listener() { return ctx.srqSubmit(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(12, "div", 5);
        i0.ɵɵelementStart(13, "button", 10);
        i0.ɵɵlistener("click", function WellbeingScanFinishComponent_Template_button_click_13_listener() { return ctx.goBack(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(8);
        i0.ɵɵproperty("disabled", ctx.isRequestInProgress);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("disabled", ctx.isRequestInProgress);
    } }, directives: [i11.WellbeingHeaderComponent, i12.JhiTranslateDirective], styles: [".container[_ngcontent-%COMP%]{margin:90px auto 295px;width:auto;max-width:1169px !important;padding-top:30px;text-align:center}@media(min-width: 1200px){.container[_ngcontent-%COMP%]{margin:90px auto 30px !important;padding-top:60px;height:720px}}"] });
