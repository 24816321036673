import { SessionService } from '../../session/session.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionInfoPopupComponent } from '../session-info-popup/session-info-popup.component';
import { SessionStatusManagerService } from '../../../core/session/session-status-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "../../session/session.service";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../core/session/session-status-manager.service";
import * as i4 from "@angular/common";
import * as i5 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i6 from "@fortawesome/angular-fontawesome";
const _c0 = function (a0, a1) { return { userName: a0, minute: a1 }; };
function CoachNavbarNotificationComponent_ng_container_0_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵelement(2, "span", 4);
    i0.ɵɵelementStart(3, "div", 5);
    i0.ɵɵelementStart(4, "button", 6);
    i0.ɵɵlistener("click", function CoachNavbarNotificationComponent_ng_container_0_div_1_Template_button_click_4_listener() { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(2); return ctx_r3.navigateToSession(); });
    i0.ɵɵelement(5, "span", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "button", 8);
    i0.ɵɵlistener("click", function CoachNavbarNotificationComponent_ng_container_0_div_1_Template_button_click_6_listener() { i0.ɵɵrestoreView(_r4); const ctx_r5 = i0.ɵɵnextContext(2); return ctx_r5.openUserDetailsPopup(); });
    i0.ɵɵelement(7, "span", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction2(1, _c0, ctx_r1.session == null ? null : ctx_r1.session.user == null ? null : ctx_r1.session.user.login, ctx_r1.minutesLeftToSessionStart));
} }
const _c1 = function (a0) { return { userName: a0 }; };
function CoachNavbarNotificationComponent_ng_container_0_ng_container_2_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "span", 10);
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵelementStart(3, "button", 6);
    i0.ɵɵlistener("click", function CoachNavbarNotificationComponent_ng_container_0_ng_container_2_div_1_Template_button_click_3_listener() { i0.ɵɵrestoreView(_r9); const ctx_r8 = i0.ɵɵnextContext(3); return ctx_r8.navigateToSession(); });
    i0.ɵɵelement(4, "span", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "button", 8);
    i0.ɵɵlistener("click", function CoachNavbarNotificationComponent_ng_container_0_ng_container_2_div_1_Template_button_click_5_listener() { i0.ɵɵrestoreView(_r9); const ctx_r10 = i0.ɵɵnextContext(3); return ctx_r10.openUserDetailsPopup(); });
    i0.ɵɵelement(6, "span", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(1, _c1, ctx_r6.session == null ? null : ctx_r6.session.user == null ? null : ctx_r6.session.user.login));
} }
function CoachNavbarNotificationComponent_ng_container_0_ng_container_2_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "span", 12);
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵelementStart(3, "button", 6);
    i0.ɵɵlistener("click", function CoachNavbarNotificationComponent_ng_container_0_ng_container_2_div_2_Template_button_click_3_listener() { i0.ɵɵrestoreView(_r12); const ctx_r11 = i0.ɵɵnextContext(3); return ctx_r11.navigateToSession(); });
    i0.ɵɵelement(4, "fa-icon", 13);
    i0.ɵɵelement(5, "span", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "button", 8);
    i0.ɵɵlistener("click", function CoachNavbarNotificationComponent_ng_container_0_ng_container_2_div_2_Template_button_click_6_listener() { i0.ɵɵrestoreView(_r12); const ctx_r13 = i0.ɵɵnextContext(3); return ctx_r13.openUserDetailsPopup(); });
    i0.ɵɵelement(7, "span", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(1, _c1, ctx_r7.session == null ? null : ctx_r7.session.user == null ? null : ctx_r7.session.user.login));
} }
function CoachNavbarNotificationComponent_ng_container_0_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CoachNavbarNotificationComponent_ng_container_0_ng_container_2_div_1_Template, 7, 3, "div", 1);
    i0.ɵɵtemplate(2, CoachNavbarNotificationComponent_ng_container_0_ng_container_2_div_2_Template, 8, 3, "div", 1);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.session.type === "text");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.session.type === "video");
} }
function CoachNavbarNotificationComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CoachNavbarNotificationComponent_ng_container_0_div_1_Template, 8, 4, "div", 1);
    i0.ɵɵtemplate(2, CoachNavbarNotificationComponent_ng_container_0_ng_container_2_Template, 3, 2, "ng-container", 0);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.session.active && ctx_r0.minutesLeftToSessionStart !== 0);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.session.started || ctx_r0.minutesLeftToSessionStart === 0);
} }
export class CoachNavbarNotificationComponent {
    constructor(sessionService, modelService, sessionStatusManagerService) {
        this.sessionService = sessionService;
        this.modelService = modelService;
        this.sessionStatusManagerService = sessionStatusManagerService;
        this.minutesLeftToSessionStart = -1;
    }
    set session(value) {
        if (!this._session || this._session !== value || this._session.id !== value.id) {
            this._session = value;
            this.setMinutesLeftTimer();
        }
    }
    get session() {
        return this._session;
    }
    ngOnInit() {
    }
    setMinutesLeftTimer() {
        if (this.session != null && (this.session.active || this.session.started)) {
            this.minutesLeftToSessionStart = this.sessionService.calculateMinutesLeftToSessionStart(this.session);
            if (this.minutesLeftToSessionStart > 0) {
                if (this.minutesLeftToSessionStart < (24 * 60)) { // less than one day is left until session start
                    if (this.timeOutVar) {
                        clearTimeout(this.timeOutVar);
                    }
                    this.timeOutVar = window.setTimeout(() => {
                        this.setMinutesLeftTimer();
                    }, 1000 * 60); // check every one minute
                }
            }
        }
    }
    ngOnDestroy() {
        clearTimeout(this.timeOutVar);
    }
    openUserDetailsPopup() {
        let modalRef = this.modelService.open(SessionInfoPopupComponent);
        modalRef.componentInstance.session = this.session;
    }
    navigateToSession() {
        this.sessionStatusManagerService.navigateToSession(this.session);
    }
}
CoachNavbarNotificationComponent.ɵfac = function CoachNavbarNotificationComponent_Factory(t) { return new (t || CoachNavbarNotificationComponent)(i0.ɵɵdirectiveInject(i1.SessionService), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i3.SessionStatusManagerService)); };
CoachNavbarNotificationComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: CoachNavbarNotificationComponent, selectors: [["coach-navbar-notification"]], inputs: { session: "session" }, decls: 1, vars: 1, consts: [[4, "ngIf"], ["class", "col text-center p-3 p-xl-0", 4, "ngIf"], [1, "col", "text-center", "p-3", "p-xl-0"], [1, "col", "text-center"], ["jhiTranslate", "inukaNgApp.coachNavbarNotification.sessionIsAboutToStart", 1, "text-success", "font-weight-bold", "mr-2", 3, "translateValues"], [1, "mt-2", "d-lg-inline-block"], [1, "btn", "btn-primary", "mr-2", "navbar-notification-join-session", 3, "click"], ["jhiTranslate", "inukaNgApp.coachNavbarNotification.chat"], [1, "btn", "btn-link", 3, "click"], ["jhiTranslate", "inukaNgApp.coachNavbarNotification.details"], ["jhiTranslate", "inukaNgApp.coachNavbarNotification.chatSessionIsStarted", 1, "text-success", "font-weight-bold", "mr-2", 3, "translateValues"], ["jhiTranslate", "inukaNgApp.coachNavbarNotification.joinSession"], ["jhiTranslate", "inukaNgApp.coachNavbarNotification.videoSessionIsStarted", 1, "text-success", "font-weight-bold", "mr-2", 3, "translateValues"], ["icon", "video", 1, "mr-2"]], template: function CoachNavbarNotificationComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, CoachNavbarNotificationComponent_ng_container_0_Template, 3, 2, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.session && (ctx.session.active || ctx.session.started));
    } }, directives: [i4.NgIf, i5.JhiTranslateDirective, i6.FaIconComponent], styles: [""] });
