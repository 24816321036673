import { LANGUAGES } from '../../../core/language/language.constants';
import { SessionStorageService } from 'ngx-webstorage';
import { AccountService } from '../../../core/auth/account.service';
import { JhiLanguageService } from '../../../ng-jhipster/language';
import { JhiEventManager } from '../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../../core/auth/account.service";
import * as i2 from "../../../ng-jhipster/language";
import * as i3 from "ngx-webstorage";
import * as i4 from "../../../ng-jhipster/service";
import * as i5 from "@angular/common";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../../layouts/navbar/active-menu.directive";
import * as i8 from "../find-language-from-key.pipe";
function LanguageSelectorComponent_span_1_li_5_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelementStart(1, "a", 7);
    i0.ɵɵlistener("click", function LanguageSelectorComponent_span_1_li_5_Template_a_click_1_listener() { const restoredCtx = i0.ɵɵrestoreView(_r4); const language_r2 = restoredCtx.$implicit; const ctx_r3 = i0.ɵɵnextContext(2); return ctx_r3.changeLanguage(language_r2); });
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "findLanguageFromKey");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const language_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("jhiActiveMenu", language_r2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 2, language_r2));
} }
function LanguageSelectorComponent_span_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2);
    i0.ɵɵelementStart(1, "a", 3);
    i0.ɵɵelementStart(2, "span", 4);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "ul", 5);
    i0.ɵɵtemplate(5, LanguageSelectorComponent_span_1_li_5_Template, 4, 4, "li", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r0.getCurrentLanguage().toUpperCase());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r0.languages);
} }
export class LanguageSelectorComponent {
    constructor(accountService, languageService, sessionStorage, eventManager) {
        this.accountService = accountService;
        this.languageService = languageService;
        this.sessionStorage = sessionStorage;
        this.eventManager = eventManager;
        this.languages = LANGUAGES;
        this.account = null;
    }
    ngOnInit() {
        this.accountService.getAuthenticationState().subscribe(account => {
            this.account = account;
        });
    }
    changeLanguage(langKey) {
        this.sessionStorage.store('locale', langKey);
        this.languageService.changeLanguage(langKey);
        if (this.account) {
            this.account.langKey = langKey;
            this.accountService.saveLanguage(this.account).subscribe(response => {
                this.eventManager.broadcast('langKeyChanged');
            });
        }
        else {
            this.eventManager.broadcast('langKeyChanged');
        }
    }
    getCurrentLanguage() {
        return this.languageService.getCurrentLanguage();
    }
}
LanguageSelectorComponent.ɵfac = function LanguageSelectorComponent_Factory(t) { return new (t || LanguageSelectorComponent)(i0.ɵɵdirectiveInject(i1.AccountService), i0.ɵɵdirectiveInject(i2.JhiLanguageService), i0.ɵɵdirectiveInject(i3.SessionStorageService), i0.ɵɵdirectiveInject(i4.JhiEventManager)); };
LanguageSelectorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: LanguageSelectorComponent, selectors: [["language-selector"]], decls: 2, vars: 1, consts: [[1, "olha-theme"], ["ngbDropdown", "", "class", "d-inline-block text-dark align-middle", 4, "ngIf"], ["ngbDropdown", "", 1, "d-inline-block", "text-dark", "align-middle"], ["ngbDropdownToggle", "", "href", "javascript:void(0);", "id", "languagesnavBarDropdown", 1, "language-anchor"], [1, "language-label"], ["ngbDropdownMenu", "", "aria-labelledby", "languagesnavBarDropdown", 1, "dropdown-menu", "dropdown-menu-right", "dropdown-menu-location"], [4, "ngFor", "ngForOf"], ["ngbDropdownItem", "", 1, "language-dropdown-item", 3, "jhiActiveMenu", "click"]], template: function LanguageSelectorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, LanguageSelectorComponent_span_1_Template, 6, 2, "span", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.languages && ctx.languages.length > 1);
    } }, directives: [i5.NgIf, i6.NgbDropdown, i6.NgbDropdownToggle, i6.NgbDropdownMenu, i5.NgForOf, i6.NgbDropdownItem, i7.ActiveMenuDirective], pipes: [i8.FindLanguageFromKeyPipe], styles: [".language-anchor[_ngcontent-%COMP%]{text-decoration:none}.language-label[_ngcontent-%COMP%]{line-height:130%;color:#4ab197}.dropdown-menu-location[_ngcontent-%COMP%]{top:27px !important;right:27px !important}a.language-dropdown-item[_ngcontent-%COMP%]{background-color:#fff;color:#44a38d !important}a.language-dropdown-item[_ngcontent-%COMP%]:hover{background-color:#44a38d;color:#fff !important}"] });
