import { SRQCompletedService } from '../../srq-completed/srq-completed.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as momenttz from "moment-timezone";
import { SessionStatusManagerService } from '../../../core/session/session-status-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "../../srq-completed/srq-completed.service";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../core/session/session-status-manager.service";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@angular/common";
import * as i6 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i7 from "../../../shared/wellbeing-result/wellbeing-result-badge/wellbeing-result-badge.component";
function SessionInfoPopupComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17);
    i0.ɵɵelementStart(1, "div", 18);
    i0.ɵɵelement(2, "span", 19);
    i0.ɵɵelementStart(3, "b");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "span", 20);
    i0.ɵɵelementStart(6, "b");
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 21);
    i0.ɵɵelement(9, "wellbeing-result-badge", 22);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r0.user.endedSessionCount);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r0.user.credits);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("wellbeingResult", ctx_r0.srqHistory[ctx_r0.srqHistory.length - 1]);
} }
function SessionInfoPopupComponent_ng_container_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 23);
    i0.ɵɵelement(2, "use", 24);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(3, "span", 25);
    i0.ɵɵtext(4, "Anonymous chat");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} }
function SessionInfoPopupComponent_ng_container_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 26);
    i0.ɵɵelement(2, "use", 27);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(3, "span", 28);
    i0.ɵɵtext(4, "Video call");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} }
export class SessionInfoPopupComponent {
    constructor(srqCompletedService, modelService, sessionStatusManagerService) {
        this.srqCompletedService = srqCompletedService;
        this.modelService = modelService;
        this.sessionStatusManagerService = sessionStatusManagerService;
        this.timezone = momenttz.tz.guess();
    }
    set session(value) {
        this._session = value;
        this.user = value.user;
        this.loadSrqHistory(this.user);
    }
    get session() {
        return this._session;
    }
    ngOnInit() {
    }
    loadSrqHistory(user) {
        this.srqCompletedService.srqHistoryByUser(user.id).subscribe((res) => {
            this.srqHistory = res.body;
        });
    }
    close() {
        this.modelService.dismissAll();
    }
    navigateToSession() {
        this.sessionStatusManagerService.navigateToSession(this.session);
    }
}
SessionInfoPopupComponent.ɵfac = function SessionInfoPopupComponent_Factory(t) { return new (t || SessionInfoPopupComponent)(i0.ɵɵdirectiveInject(i1.SRQCompletedService), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i3.SessionStatusManagerService)); };
SessionInfoPopupComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionInfoPopupComponent, selectors: [["session-info-popup"]], inputs: { session: "session", minimized: "minimized" }, decls: 21, vars: 7, consts: [[1, "olha-theme", "p-3"], [1, "row", "justify-content-around"], [1, "col", "text-right"], ["icon", "times-circle", 2, "cursor", "pointer", 3, "click"], [1, "row", "justify-content-around", "mx-2"], [1, "col"], [1, "d-inline-block"], ["class", "row justify-content-start m-2", 4, "ngIf"], [1, "row", "m-2", "mt-4", "justify-content-start"], [1, "col", "text-dark", 2, "font-size", "1rem"], ["icon", "calendar", 1, "mr-3"], [1, "row", "m-2", "justify-content-start"], [4, "ngIf"], [1, "row", "m-2", "mt-4", "justify-content-center"], [1, "col", "text-center"], [1, "btn", "btn-primary", "mr-2", 3, "click"], ["jhiTranslate", "inukaNgApp.coachNavbarNotification.joinSession"], [1, "row", "justify-content-start", "m-2"], [1, "col-7"], ["jhiTranslate", "inukaNgApp.inukaJourney.sessions", 1, "text-light", "mr-1"], ["jhiTranslate", "sessionChat.paragraph.credits", 1, "text-light", "ml-3", "mr-1"], [1, "col-5", 2, "margin-top", "-5px"], [3, "wellbeingResult"], [1, "text-dark", 2, "height", "14px", "width", "14px"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#text"], ["jhiTranslate", "inukaNgApp.inukaJourney.anonymousChat", 1, "ml-3"], [1, "text-dark", 2, "height", "10px", "width", "14px"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#video-call-dark"], ["jhiTranslate", "inukaNgApp.inukaJourney.videoCall", 1, "ml-3"]], template: function SessionInfoPopupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function SessionInfoPopupComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelementStart(6, "h3", 6);
        i0.ɵɵtext(7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(8, SessionInfoPopupComponent_div_8_Template, 10, 3, "div", 7);
        i0.ɵɵelementStart(9, "div", 8);
        i0.ɵɵelementStart(10, "div", 9);
        i0.ɵɵelement(11, "fa-icon", 10);
        i0.ɵɵtext(12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 11);
        i0.ɵɵelementStart(14, "div", 9);
        i0.ɵɵtemplate(15, SessionInfoPopupComponent_ng_container_15_Template, 5, 0, "ng-container", 12);
        i0.ɵɵtemplate(16, SessionInfoPopupComponent_ng_container_16_Template, 5, 0, "ng-container", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(17, "div", 13);
        i0.ɵɵelementStart(18, "div", 14);
        i0.ɵɵelementStart(19, "button", 15);
        i0.ɵɵlistener("click", function SessionInfoPopupComponent_Template_button_click_19_listener() { return ctx.navigateToSession(); });
        i0.ɵɵelement(20, "span", 16);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵclassProp("text-danger", ctx.user.flagged);
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate(ctx.user.login);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.minimized);
        i0.ɵɵadvance(4);
        i0.ɵɵtextInterpolate1(" ", ctx.session.sessionStartTime.tz(ctx.timezone).format("ddd, D MMM, hh:mm A (zz)") + ", " + ctx.session.duration + " min", " ");
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.session.type === "text");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.session.type === "video");
    } }, directives: [i4.FaIconComponent, i5.NgIf, i6.JhiTranslateDirective, i7.WellbeingResultBadgeComponent], styles: [""] });
