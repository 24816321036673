import * as i0 from "@angular/core";
import * as i1 from "../../language/jhi-translate.directive";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../pipe/keys.pipe";
function JhiMetricsHttpRequestComponent_table_2_tr_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "tr");
    i0.ɵɵelementStart(1, "td");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "td");
    i0.ɵɵelementStart(4, "ngb-progressbar", 8);
    i0.ɵɵelementStart(5, "span");
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "td", 9);
    i0.ɵɵtext(8);
    i0.ɵɵpipe(9, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "td", 9);
    i0.ɵɵtext(11);
    i0.ɵɵpipe(12, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const entry_r2 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.key);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("max", ctx_r1.requestMetrics["all"].count)("value", entry_r2.value.count)("striped", true)("animated", false);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.value.count);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(9, 8, ctx_r1.filterNaN(entry_r2.value.mean), "1.0-2"), " ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(12, 11, entry_r2.value.max, "1.0-2"));
} }
function JhiMetricsHttpRequestComponent_table_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "table", 2);
    i0.ɵɵelementStart(1, "thead");
    i0.ɵɵelementStart(2, "tr");
    i0.ɵɵelementStart(3, "th", 3);
    i0.ɵɵtext(4, "Code");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "th", 4);
    i0.ɵɵtext(6, "Count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "th", 5);
    i0.ɵɵtext(8, "Mean");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "th", 6);
    i0.ɵɵtext(10, "Max");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "tbody");
    i0.ɵɵtemplate(12, JhiMetricsHttpRequestComponent_table_2_tr_12_Template, 13, 14, "tr", 7);
    i0.ɵɵpipe(13, "keys");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(12);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(13, 1, ctx_r0.requestMetrics["percode"]));
} }
export class JhiMetricsHttpRequestComponent {
    filterNaN(input) {
        if (isNaN(input)) {
            return 0;
        }
        return input;
    }
}
JhiMetricsHttpRequestComponent.ɵfac = function JhiMetricsHttpRequestComponent_Factory(t) { return new (t || JhiMetricsHttpRequestComponent)(); };
JhiMetricsHttpRequestComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiMetricsHttpRequestComponent, selectors: [["jhi-metrics-request"]], inputs: { requestMetrics: "requestMetrics", updating: "updating" }, decls: 3, vars: 1, consts: [["jhiTranslate", "metrics.jvm.http.title"], ["class", "table table-striped", 4, "ngIf"], [1, "table", "table-striped"], ["jhiTranslate", "metrics.jvm.http.table.code"], ["jhiTranslate", "metrics.jvm.http.table.count"], ["jhiTranslate", "metrics.jvm.http.table.mean", 1, "text-right"], ["jhiTranslate", "metrics.jvm.http.table.max", 1, "text-right"], [4, "ngFor", "ngForOf"], ["type", "success", 3, "max", "value", "striped", "animated"], [1, "text-right"]], template: function JhiMetricsHttpRequestComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "h3", 0);
        i0.ɵɵtext(1, "HTTP requests (time in millisecond)");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, JhiMetricsHttpRequestComponent_table_2_Template, 14, 3, "table", 1);
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.updating);
    } }, directives: [i1.JhiTranslateDirective, i2.NgIf, i2.NgForOf, i3.NgbProgressbar], pipes: [i4.JhiKeysPipe, i2.DecimalPipe], encapsulation: 2 });
