import { SimpleChanges } from '@angular/core';
import { LANGUAGES } from '../../../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i2 from "@angular/common";
import * as i3 from "@ctrl/ngx-emoji-mart/ngx-emoji";
import * as i4 from "@angular/material/tooltip";
function SupportedLanguagesComponent_ngx_emoji_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ngx-emoji", 3);
} if (rf & 2) {
    const lang_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵpropertyInterpolate1("emoji", "flag-", ctx_r0.getLang(lang_r1), "");
    i0.ɵɵpropertyInterpolate("matTooltip", ctx_r0.displayToolTip(lang_r1));
    i0.ɵɵproperty("matTooltipPosition", "above");
} }
export class SupportedLanguagesComponent {
    constructor() { }
    ngOnInit() {
        this.list.sort((a, b) => a > b ? 1 : -1);
    }
    ngOnChanges(changes) { }
    displayToolTip(lang) {
        let language = LANGUAGES.find(l => l.code === lang);
        return language ? language.nativeName : "";
    }
    getLang(lang) {
        let language = LANGUAGES.find(l => l.code === lang);
        return language ? language.flagCode : "";
    }
}
SupportedLanguagesComponent.ɵfac = function SupportedLanguagesComponent_Factory(t) { return new (t || SupportedLanguagesComponent)(); };
SupportedLanguagesComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SupportedLanguagesComponent, selectors: [["supported-languages"]], inputs: { list: "list" }, features: [i0.ɵɵNgOnChangesFeature], decls: 3, vars: 1, consts: [[1, "align-items-top"], ["jhiTranslate", "sessionSchedule.paragraph.languages", 1, "pr-1", 2, "font-size", "0.975rem"], [3, "emoji", "matTooltip", "matTooltipPosition", 4, "ngFor", "ngForOf"], [3, "emoji", "matTooltip", "matTooltipPosition"]], template: function SupportedLanguagesComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "span", 1);
        i0.ɵɵtemplate(2, SupportedLanguagesComponent_ngx_emoji_2_Template, 1, 3, "ngx-emoji", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.list);
    } }, directives: [i1.JhiTranslateDirective, i2.NgForOf, i3.EmojiComponent, i4.MatTooltip], styles: [".align-items-top[_ngcontent-%COMP%]{display:flex;align-items:flex-start}"] });
