import { NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import ZoomVideo, { LiveTranscriptionLanguage } from '@zoom/videosdk';
import { BehaviorSubject, from, of, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, filter, finalize } from "rxjs/operators";
import { VideoCallJoinResult } from '../../shared/model/video-call-join-result.model';
import { VideoCallService } from './video-call.service';
import { AccountService } from '../../core/auth/account.service';
import { BrowserDetector } from '../../core/util/browser-detector';
import { VideoCallTranscriptionMessage } from '../../shared/model/video-call-transcription-message.model';
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CameraIsInUsePopupComponent } from './camera-is-in-use-popup/camera-is-in-use-popup.component';
import { DomainService } from '../../core/util/domain.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./video-call.service";
import * as i3 from "../../core/auth/account.service";
import * as i4 from "@angular/router";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
export class ZoomService {
    constructor(http, videoCallService, accountService, zone, router, modalService) {
        this.http = http;
        this.videoCallService = videoCallService;
        this.accountService = accountService;
        this.zone = zone;
        this.router = router;
        this.modalService = modalService;
        this.initDefaults();
        if (navigator.mediaDevices) {
            navigator.mediaDevices.ondevicechange = ((event) => {
                if (this.isOnVideoCallPage()) {
                    this.updateAvailableDevices();
                }
            });
        }
    }
    init() {
        console.log('zoom init started');
        if (!this.client) {
            if (this.clientInitiatorSubject) {
                console.log('zoom init finished, returned existing clientInit observable');
                return this.clientInitiatorSubject.asObservable();
            }
            else {
                console.log('zoom init started a Zoom.client.init');
                this.clientInitiatorSubject = new ReplaySubject(1);
                const client = ZoomVideo.createClient();
                this.zone.runOutsideAngular(() => {
                    console.log('zoom init started a Zoom.client.init (now outside Zone)');
                    client.init('en-US', 'CDN', {
                        stayAwake: true,
                        leaveOnPageUnload: true,
                        enforceVirtualBackground: BrowserDetector.isLocalHost()
                    }).then(value => {
                        console.log('zoom init finished Zoom.client.init');
                        this.zone.run(() => {
                            console.log('zoom init finished Zoom.client.init (now inside Zone)');
                            this.client = client;
                            this.clientInitiatorSubject.next();
                            this.clientInitiatorSubject = null;
                            this.client.getLoggerClient({
                                debugMode: false,
                            });
                        });
                    }).catch(reason => {
                        console.error('failed to init zoom');
                        console.error(reason);
                        this.clientInitiatorSubject = null;
                    });
                });
            }
            return this.clientInitiatorSubject;
        }
        else {
            console.log('zoom init finished, returned existing client');
            return of(true);
        }
    }
    destroy() {
        ZoomVideo.destroyClient();
        this.initDefaults();
    }
    initDefaults() {
        this.client = null;
        this.stream = null;
        this.audioEncode = null;
        this.audioDecode = null;
        this.selfVideoContainer = null;
        this.participantVideoContainer = null;
        this.selectedCamera = new BehaviorSubject(null);
        this.selectedMic = new BehaviorSubject(null);
        this.selectedSpeaker = new BehaviorSubject(null);
        this.virtualBackground = new BehaviorSubject('NONE');
        this.availableCameras = new BehaviorSubject([]);
        this.availableSpeakers = new BehaviorSubject([]);
        this.availableMics = new BehaviorSubject([]);
        this.videoStatus = new BehaviorSubject('STOPPED');
        this.audioStatus = new BehaviorSubject('STOPPED');
        this.transcriptionStatus = new BehaviorSubject('STOPPED');
        this.connectionStatus = new BehaviorSubject('CONNECTING');
        this.selectedLanguage = new BehaviorSubject(null);
        this.incomingAudioStatus = new BehaviorSubject('STOPPED');
        this.participantJoinStatus = new BehaviorSubject('LEFT');
        this.virtualBackgroundSupportStatus = new BehaviorSubject('PENDING');
        // these subjects emit `UserId` to be used for rendering their video
        this.participantVideoStarted = new BehaviorSubject(null);
        this.participantVideoStopped = new BehaviorSubject(null);
        this.participantAudioStarted = new BehaviorSubject(null);
        this.participantAudioStopped = new BehaviorSubject(null);
        this.participantJoinedTheCall = new ReplaySubject(1);
        this.participantLeftTheCall = new ReplaySubject(1);
        this.onVideoCallEnded = new Subject();
        this.onDeviceAccessDenied = new ReplaySubject(1);
    }
    isOnVideoCallPage() {
        const url = this.router.url;
        if (url.includes('session/video-coach') || url.includes('session/video')) {
            return true;
        }
        else {
            return false;
        }
    }
    registerEventListeners() {
        // event listener to see when desktop Safari audio has been initialized
        this.client.on('media-sdk-change', (payload) => {
            if (!this.joinedToTheCall) {
                return;
            }
            if (payload.type === 'audio' && payload.result === 'success') {
                if (payload.action === 'encode') {
                    // encode for sending audio stream (speak)
                    this.audioEncode = true;
                }
                else if (payload.action === 'decode') {
                    // decode for receiving audio stream (hear)
                    this.audioDecode = true;
                }
                if (this.audioEncode && this.audioDecode && this.startAudionWhenDeviceIsReady) {
                    console.log('audio encode/decode initiated successfully');
                    this.startIncomingAudio();
                }
            }
            if (payload.result !== 'success') {
                console.warn(`error on media SDK change, type ${payload.type}, action: ${payload.action}, result: ${payload.result}`);
            }
        });
        this.client.on('user-added', (payload) => {
            if (!this.joinedToTheCall) {
                return;
            }
            console.log(payload);
            payload.forEach(participantProperties => {
                if (!this.isItMe(participantProperties)) {
                    this.participantJoinedTheCall.next(participantProperties.userId);
                    this.participantJoinStatus.next('JOINED');
                }
            });
            this.updateParticipantStatus();
        });
        this.client.on('user-updated', (payload) => {
            if (!this.joinedToTheCall) {
                return;
            }
            console.log(payload);
            this.updateParticipantStatus();
        });
        this.client.on('user-removed', (payload) => {
            if (!this.joinedToTheCall) {
                return;
            }
            console.log(payload);
            payload.forEach(participantProperties => {
                if (!this.isItMe(participantProperties)) {
                    this.participantLeftTheCall.next(participantProperties.userId);
                    this.participantJoinStatus.next('LEFT');
                    this.participantVideoStarted.next(null);
                    this.participantAudioStarted.next(null);
                    this.participantAudioStopped.next(participantProperties.userId);
                    this.participantVideoStopped.next(participantProperties.userId);
                }
            });
        });
        this.client.on('device-change', () => {
            if (!this.joinedToTheCall) {
                return;
            }
            this.updateAvailableDevices();
        });
        // this.client.on('peer-video-state-change', (payload) => {
        //   if (payload.action === 'Start') {
        //     this.participantVideoStarted.next(payload.userId);
        //   } else if (payload.action === 'Stop') {
        //     this.participantVideoStopped.next(payload.userId);
        //   }
        // });
        this.client.on('connection-change', (payload) => {
            if (!this.joinedToTheCall) {
                return;
            }
            console.log(payload);
            const newStream = this.client.getMediaStream();
            if (newStream) {
                this.stream = newStream;
            }
            if (payload.state === 'Closed') {
                this.zone.run(() => {
                    this.onLeave();
                });
            }
            else if (payload.state === 'Connected') {
                this.connectionStatus.next('CONNECTED');
            }
            else if (payload.state === 'Fail') {
                this.connectionStatus.next('FAILED');
            }
            else if (payload.state === 'Reconnecting') {
                this.connectionStatus.next('CONNECTING');
            }
        });
        if (this.accountService.isCoach()) {
            this.client.on('caption-message', payload => {
                this.submitTranscriptionMessage(payload);
            });
        }
    }
    unregisterEventListeners() {
        this.client.off('media-sdk-change', payload => {
        });
        this.client.off('user-added', payload => {
        });
        this.client.off('user-updated', payload => {
        });
        this.client.off('user-removed', payload => {
        });
        this.client.off('device-change', payload => {
        });
        this.client.off('connection-change', payload => {
        });
    }
    isItMe(user) {
        var _a;
        return ((_a = this.accountService.getCurrentLoggedInUser()) === null || _a === void 0 ? void 0 : _a.login) === user.displayName;
    }
    joinVideoCall(userName, videoCallJoinInfo) {
        this.stream = null;
        this.currentVideoCall = videoCallJoinInfo;
        console.log('zoom join started');
        if (!this.joinCallSubject) {
            this.joinCallSubject = new ReplaySubject(1);
            this.client = ZoomVideo.createClient();
            this.zone.runOutsideAngular(() => {
                console.log('zoom init started a Zoom.client.init (now outside Zone)');
                this.client.init('en-US', 'CDN', {
                    stayAwake: true,
                    leaveOnPageUnload: true,
                    enforceVirtualBackground: BrowserDetector.isLocalHost()
                }).then(value => {
                    console.log('zoom init finished Zoom.client.init');
                    this.client.getLoggerClient({
                        debugMode: false,
                    });
                    this.client.join(videoCallJoinInfo.sessionName, videoCallJoinInfo.token, userName)
                        .then(() => {
                        console.log('zoom join received result from join');
                        this.zone.run(() => {
                            console.log('zoom join received result from join (now inside Zone)');
                            this.onJoin(videoCallJoinInfo);
                            this.joinCallSubject.next();
                        });
                    }).catch((error) => {
                        console.log(error);
                    }).finally(() => {
                        this.joinCallSubject = null;
                    });
                }).catch(reason => {
                    console.error('failed to init zoom');
                    console.error(reason);
                    this.clientInitiatorSubject = null;
                });
            });
        }
        return this.joinCallSubject.asObservable();
    }
    endVideoCall() {
        if (this.client) {
            return from(this.client.leave(true).then(() => {
            }).catch((error) => {
                console.log(error);
                // if call ends successfully we'll receive the 'connection-change' event, but if it fails we need to call onLeave manually
                this.onLeave();
            }));
        }
        else {
            return of();
        }
    }
    leaveVideoCall() {
        if (this.client) {
            return from(this.client.leave(false).then(() => {
            }).catch((error) => {
                console.log(error);
                // if call ends successfully we'll receive the 'connection-change' event, but if it fails we need to call onLeave manually
                this.onLeave();
            }));
        }
        else {
            return of();
        }
    }
    onJoin(videoCallJoinInfo) {
        this.stream = this.client.getMediaStream();
        this.joinedToTheCall = true;
        this.connectionStatus.next('CONNECTED');
        this.registerEventListeners();
        this.submitJoinResult(videoCallJoinInfo);
        this.checkTranscriptionStatusAndStart(videoCallJoinInfo);
        this.updateParticipantStatus(true);
        this.startIncomingAudio();
    }
    checkTranscriptionStatusAndStart(videoCallJoinInfo) {
        if (videoCallJoinInfo.transcriptionEnabled) {
            this.selectedLanguage.next(videoCallJoinInfo.langKey);
            this.startTranscription();
        }
        else {
            this.transcriptionStatus.next('TURNED_OFF');
        }
    }
    onLeave() {
        this.joinedToTheCall = false;
        this.onVideoCallEnded.next();
        try {
            this.unregisterEventListeners();
            this.stopVideo();
            this.stopAudio();
        }
        catch (e) {
        }
    }
    startTranscription() {
        if (this.accountService.isCoach() || this.shouldStartTranscriptionForAllParticipants()) {
            const lttClient = this.client.getLiveTranscriptionClient();
            if (this.transcriptionStatus.value !== 'STARTED') {
                lttClient.startLiveTranscription().then(value => {
                    this.transcriptionStatus.next('STARTED');
                    if (this.selectedLanguage.value) {
                        this.setSpeakingLanguage(this.selectedLanguage.value);
                    }
                });
            }
        }
    }
    shouldStartTranscriptionForAllParticipants() {
        // Zoom considers the English as default language for all participants,
        // if the session language is any language other than English we should start the transcription for all participants
        // and set speaking language to the selected language
        if (this.selectedLanguage.value !== 'en') {
            return true;
        }
        return false;
    }
    turnOffTranscription() {
        if (this.accountService.isCoach()) {
            this.transcriptionStatus.next('TURNED_OFF');
        }
    }
    turnOnTranscription() {
        if (this.accountService.isCoach()) {
            this.startTranscription();
        }
    }
    setSpeakingLanguage(langKey) {
        const lang = this.getLiveTranscriptionLanguage(langKey);
        this.client.getLiveTranscriptionClient().setSpeakingLanguage(lang).then(() => {
            console.log(`set transcription language to: ${langKey}`);
        });
    }
    getLiveTranscriptionLanguage(langKey) {
        if (!langKey) {
            return LiveTranscriptionLanguage.English;
        }
        langKey = langKey.toLowerCase();
        switch (langKey) {
            case 'ar':
                return LiveTranscriptionLanguage.Arabic;
            case 'zh':
                return LiveTranscriptionLanguage["Chinese (Simplified)"];
            case 'cs':
                return LiveTranscriptionLanguage.Czech;
            case 'en':
                return LiveTranscriptionLanguage.English;
            case 'et':
                return LiveTranscriptionLanguage.Estonian;
            case 'fi':
                return LiveTranscriptionLanguage.Finnish;
            case 'fr':
                return LiveTranscriptionLanguage.French;
            case 'de':
                return LiveTranscriptionLanguage.German;
            case 'he':
                return LiveTranscriptionLanguage.Hebrew;
            case 'hi':
                return LiveTranscriptionLanguage.Hindi;
            case 'hu':
                return LiveTranscriptionLanguage.Hungarian;
            case 'id':
                return LiveTranscriptionLanguage.Indonesian;
            case 'it':
                return LiveTranscriptionLanguage.Italian;
            case 'ja':
                return LiveTranscriptionLanguage.Japanese;
            case 'ko':
                return LiveTranscriptionLanguage.Korean;
            case 'ms':
                return LiveTranscriptionLanguage.Malay;
            case 'fa':
                return LiveTranscriptionLanguage.Persian;
            case 'pt':
                return LiveTranscriptionLanguage.Portuguese;
            case 'ro':
                return LiveTranscriptionLanguage.Romanian;
            case 'ru':
                return LiveTranscriptionLanguage.Russian;
            case 'es':
                return LiveTranscriptionLanguage.Spanish;
            case 'sv':
                return LiveTranscriptionLanguage.Swedish;
            case 'tl':
                return LiveTranscriptionLanguage.Tagalog;
            case 'ta':
                return LiveTranscriptionLanguage.Tamil;
            case 'te':
                return LiveTranscriptionLanguage.Telugu;
            case 'tr':
                return LiveTranscriptionLanguage.Turkish;
            case 'uk':
                return LiveTranscriptionLanguage.Ukrainian;
            case 'vi':
                return LiveTranscriptionLanguage.Vietnamese;
            case 'nl':
                return LiveTranscriptionLanguage.Dutch;
            default:
                return LiveTranscriptionLanguage.English;
        }
    }
    submitJoinResult(videoCallJoinInfo) {
        const sessionInfo = this.client.getSessionInfo();
        const videoCallJoinResult = new VideoCallJoinResult(sessionInfo.sessionId, sessionInfo.topic);
        this.videoCallService.submitVideoCallJoinResult(videoCallJoinInfo.sessionId, videoCallJoinResult).subscribe();
    }
    updateParticipantStatus(triggerParticipantJoinedEvent) {
        // enable participants video
        this.client.getAllUser().forEach((user) => {
            if (!this.isItMe(user)) {
                console.log(`updating participant ${user.displayName} status, video: ${user.bVideoOn}, Audio: ${!user.muted}`);
                if (triggerParticipantJoinedEvent) {
                    this.participantJoinedTheCall.next(user.userId);
                    this.participantJoinStatus.next('JOINED');
                }
                if (user.bVideoOn) {
                    if (this.participantVideoStarted.value !== user.userId) {
                        this.participantVideoStopped.next(null);
                        this.participantVideoStarted.next(user.userId);
                    }
                }
                else {
                    if (this.participantVideoStopped.value !== user.userId) {
                        this.participantVideoStarted.next(null);
                        this.participantVideoStopped.next(user.userId);
                    }
                }
                if (user.muted !== undefined && user.muted === false) {
                    if (this.participantAudioStarted.value !== user.userId) {
                        this.participantAudioStopped.next(null);
                        this.participantAudioStarted.next(user.userId);
                    }
                }
                else {
                    if (this.participantAudioStopped.value !== user.userId) {
                        this.participantAudioStarted.next(null);
                        this.participantAudioStopped.next(user.userId);
                    }
                }
            }
        });
    }
    registerSelfVideoContainer(videoContainer) {
        this.selfVideoContainer = videoContainer;
        if (this.videoStatus.value !== 'TURNED_OFF' && this.selectedCamera.value) {
            this.startVideo();
        }
    }
    removeSelfVideoContainer() {
        this.selfVideoContainer = null;
    }
    registerParticipantVideoContainer(videoContainer) {
        this.participantVideoContainer = videoContainer;
    }
    removeParticipantVideoContainer(videoContainer) {
        this.participantVideoContainer = null;
    }
    startParticipantVideo(userId, canvas, width, height, x, y) {
        console.log(`starting participant video: user: ${userId}, width: ${width}, height: ${height}, x: ${x}, y: ${y}`);
        return from(this.stream.renderVideo(canvas, userId, width, height, x, y, 2).catch((error) => {
            console.log('could not start participant video, reason: ' + error.name);
            this.participantVideoStopped.next(userId);
            this.participantVideoStarted.next(null);
            throw "failed to render participant video";
        }));
        // .then(() => {
        //   console.log(`render participant video started successfully. user: ${userId}`);
        //   this.resizeParticipantVideo(canvas, width, height);
        // })
        //   .catch((error: Error) => {
        //     console.log('could not start participant video, reason: ' + error.name);
        //     this.participantVideoStopped.next(userId);
        //   }));
    }
    resizeParticipantVideo(canvas, width, height) {
        if (this.joinedToTheCall) {
            console.log(`resizing participant video: width: ${width}, height: ${height}`);
            this.stream.updateVideoCanvasDimension(canvas, width, height);
        }
    }
    stopParticipantVideo(userId, canvas) {
        console.log(`stopping render video for user: ${userId}`);
        this.stream.stopRenderVideo(canvas, userId).then();
    }
    changeVirtualBackground(newVirtualBackground) {
        if (this.videoStatus.value === 'STARTED' && this.stream.isSupportVirtualBackground()) {
            const virtualBackgroundImageUrl = this.getVirtualBackgroundImageUrl(newVirtualBackground);
            console.log(`virtual background image url: ${virtualBackgroundImageUrl}`);
            this.stream.updateVirtualBackgroundImage(virtualBackgroundImageUrl).then(() => {
                this.virtualBackground.next(newVirtualBackground);
            }).catch(reason => {
                console.error('failed to update virtual background image');
                console.error(reason);
            });
        }
        else {
            this.virtualBackground.next(newVirtualBackground);
        }
    }
    previewVirtualBackground(canvas, newVirtualBackground) {
        const virtualBackgroundImageUrl = this.getVirtualBackgroundImageUrl(newVirtualBackground);
        this.stream.previewVirtualBackground(canvas, virtualBackgroundImageUrl, false).then();
    }
    getVirtualBackgroundImageUrl(virtualBackground) {
        if (virtualBackground === 'BLUR') {
            return 'blur';
        }
        else if (virtualBackground === 'LIGHT') {
            return `${DomainService.getFrontendDomain()}/content/images/video-call/light-virtual-bg.png`;
        }
        else if (virtualBackground === 'GREEN') {
            return `${DomainService.getFrontendDomain()}/content/images/video-call/green-virtual-bg.png`;
        }
        return undefined;
    }
    startVideo(ignoreVirtualBackground) {
        if (!this.selfVideoContainer || this.videoStatus.value === 'STARTED') {
            return;
        }
        const startVideoOptions = {
            hd: false,
            fullHd: false,
            ptz: false
        };
        if (this.selectedCamera.value) {
            startVideoOptions.cameraId = this.selectedCamera.value.deviceId;
        }
        if (this.virtualBackground.value !== 'NONE' && !ignoreVirtualBackground) {
            // if (this.stream.isSupportVirtualBackground()) {
            startVideoOptions.virtualBackground = {
                imageUrl: this.getVirtualBackgroundImageUrl(this.virtualBackground.value),
                cropped: false
            };
            // } else {
            //   console.warn('virtual background is not available on this device');
            // }
        }
        if (this.isRenderSelfViewWithVideoElement()) {
            this.selfVideoContainer.canvasElement.style.display = 'none';
            // start video - video will render automatically on HTML Video element
            startVideoOptions.videoElement = this.selfVideoContainer.videoElement;
            this.stream.startVideo(startVideoOptions).then(() => {
                // show HTML Video element in DOM
                this.selfVideoContainer.videoElement.style.display = 'block';
                this.videoStatus.next('STARTED');
            }).catch((error) => {
                var _a;
                this.videoStatus.next('STOPPED');
                console.log(error);
                if (error.type === 'INVALID_PARAMETERS' && ((_a = error.reason) === null || _a === void 0 ? void 0 : _a.indexOf('background')) >= 0) {
                    this.startVideo(true);
                }
                else if (error.type === 'VIDEO_USER_FORBIDDEN_CAPTURE' || error.type === 'VIDEO_CAMERA_IS_TAKEN') {
                    this.showCameraIsInUseError();
                }
            });
            // desktop Chrome, Edge, and Firefox with SharedArrayBuffer enabled, and all other browsers
        }
        else {
            this.selfVideoContainer.videoElement.style.display = 'none';
            // start video
            this.stream.startVideo(startVideoOptions).then(() => {
                // render video on HTML Canvas element
                const height = this.calculateVideoHeight(this.selfVideoContainer.width);
                this.selfVideoContainer.canvasElement.width = this.selfVideoContainer.width;
                this.selfVideoContainer.canvasElement.height = height;
                this.selfVideoContainer.canvasElement.style.display = 'block';
                this.stream.renderVideo(this.selfVideoContainer.canvasElement, this.client.getCurrentUserInfo().userId, this.selfVideoContainer.width, height, 0, 0, 2).then(() => {
                    // show HTML Canvas element in DOM
                    this.videoStatus.next('STARTED');
                }).catch((error) => {
                    this.videoStatus.next('STOPPED');
                    console.log(error);
                });
            }).catch((error) => {
                var _a;
                this.videoStatus.next('STOPPED');
                console.log(error);
                if (error.type === 'INVALID_PARAMETERS' && ((_a = error.reason) === null || _a === void 0 ? void 0 : _a.indexOf('background')) >= 0) {
                    this.startVideo(true);
                }
                else if (error.type === 'VIDEO_USER_FORBIDDEN_CAPTURE' || error.type === 'VIDEO_CAMERA_IS_TAKEN') {
                    this.showCameraIsInUseError();
                }
            });
        }
    }
    showCameraIsInUseError() {
        this.modalService.open(CameraIsInUsePopupComponent, { size: "lg" });
    }
    calculateVideoHeight(width) {
        // default aspect ratio is: 16:9
        return (width * 9) / 16;
    }
    isRenderSelfViewWithVideoElement() {
        try {
            if (this.stream) {
                return this.stream.isRenderSelfViewWithVideoElement();
            }
        }
        catch (e) {
            console.warn('failed to detect proper self video view element');
            console.warn(e);
        }
        return false;
    }
    stopVideo() {
        var _a, _b, _c, _d;
        if ((_b = (_a = this.client) === null || _a === void 0 ? void 0 : _a.getSessionInfo()) === null || _b === void 0 ? void 0 : _b.isInMeeting) {
            (_c = this.stream) === null || _c === void 0 ? void 0 : _c.stopVideo().then();
            if (!this.isRenderSelfViewWithVideoElement()) {
                (_d = this.stream) === null || _d === void 0 ? void 0 : _d.stopRenderVideo(this.selfVideoContainer.canvasElement, this.client.getCurrentUserInfo().userId).then();
            }
        }
        this.videoStatus.next('STOPPED');
    }
    turnOnVideo() {
        this.videoStatus.next('STOPPED');
    }
    turnOfVideo() {
        var _a;
        (_a = this.stream) === null || _a === void 0 ? void 0 : _a.stopVideo();
        this.videoStatus.next('TURNED_OFF');
    }
    switchCamera(device) {
        var _a;
        if ((device === null || device === void 0 ? void 0 : device.deviceId) !== ((_a = this.selectedCamera.value) === null || _a === void 0 ? void 0 : _a.deviceId)) {
            this.selectedCamera.next(device);
            if (this.stream && device) {
                this.stream.switchCamera(device.deviceId).then(() => {
                });
            }
        }
    }
    switchSpeaker(device) {
        var _a;
        if ((device === null || device === void 0 ? void 0 : device.deviceId) !== ((_a = this.selectedSpeaker.value) === null || _a === void 0 ? void 0 : _a.deviceId)) {
            this.selectedSpeaker.next(device);
            if (this.stream && device) {
                this.stream.switchSpeaker(device.deviceId).then(() => {
                });
            }
        }
    }
    switchMic(device) {
        var _a;
        if (device && (device === null || device === void 0 ? void 0 : device.deviceId) !== ((_a = this.selectedMic.value) === null || _a === void 0 ? void 0 : _a.deviceId)) {
            this.selectedMic.next(device);
            if (this.stream) {
                this.stream.switchMicrophone(device.deviceId).then(() => {
                    if (this.audioStatus.value !== 'STARTED') {
                        this.unmuteAudio();
                    }
                }).catch(reason => {
                    console.log('failed to switch mic');
                    console.log(JSON.stringify(reason));
                });
            }
        }
    }
    switchLanguage(lang) {
        this.selectedLanguage.next(lang);
        if (this.stream) {
            if (this.transcriptionStatus.value === 'STARTED') {
                this.setSpeakingLanguage(lang);
            }
            else {
                this.startTranscription();
            }
        }
    }
    startIncomingAudio() {
        // if desktop Safari https://stackoverflow.com/a/42189492/6592510
        const isMuted = this.audioStatus.value === 'MUTED';
        const startAudioOptions = {
            mute: isMuted
        };
        if (BrowserDetector.isSafari() && BrowserDetector.isDesktop()) {
            // desktop Safari, check if desktop Safari audio has been initialized
            if (this.audioEncode && this.audioDecode) {
                // desktop Safari audio has been initialized, continue to start audio
                startAudioOptions.autoStartAudioInSafari = true;
                this.stream.startAudio(startAudioOptions).then(value => {
                    this.onIncomingAudioStarted();
                }).catch(reason => {
                    this.incomingAudioStatus.next('FAILED');
                    console.error('failed to start audio for Safari');
                    console.error(JSON.stringify(reason));
                }).finally(() => {
                    this.startAudionWhenDeviceIsReady = false;
                });
            }
            else {
                // desktop Safari audio has not been initialized, retry or handle error
                console.log('safari audio has not finished initializing, postpone starting incoming audio');
                this.startAudionWhenDeviceIsReady = true;
            }
        }
        else {
            // not desktop Safari, continue to start audio
            this.stream.startAudio(startAudioOptions).then(value => {
                this.onIncomingAudioStarted();
            }).catch(reason => {
                this.incomingAudioStatus.next('FAILED');
                console.error('failed to start audio');
                console.error(JSON.stringify(reason));
            });
        }
    }
    onIncomingAudioStarted() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.incomingAudioStatus.next('STARTED');
        console.log('incoming audio started successfully');
        try {
            if (this.selectedSpeaker.value && ((_a = this.stream) === null || _a === void 0 ? void 0 : _a.getActiveSpeaker()) !== ((_b = this.selectedSpeaker.value) === null || _b === void 0 ? void 0 : _b.deviceId)) {
                console.log(`selected speaker is different than the active speaker, active: ${(_c = this.stream) === null || _c === void 0 ? void 0 : _c.getActiveSpeaker()}. selected: ${(_d = this.selectedSpeaker.value) === null || _d === void 0 ? void 0 : _d.deviceId}`);
                this.stream.switchSpeaker(this.selectedSpeaker.value.deviceId).then();
            }
        }
        catch (e) {
            console.error('error on changing the default speaker');
            console.log(JSON.stringify(e));
        }
        try {
            if (this.audioStatus.value !== 'MUTED') {
                this.audioStatus.next('STARTED');
                if (this.selectedMic.value && ((_e = this.stream) === null || _e === void 0 ? void 0 : _e.getActiveMicrophone()) !== ((_f = this.selectedMic.value) === null || _f === void 0 ? void 0 : _f.deviceId)) {
                    console.log(`selected mic is different than the active mic, active: ${(_g = this.stream) === null || _g === void 0 ? void 0 : _g.getActiveMicrophone()}. selected: ${(_h = this.selectedMic.value) === null || _h === void 0 ? void 0 : _h.deviceId}`);
                    this.switchMicToSelectedDevice();
                }
            }
        }
        catch (e) {
            console.error('error on changing the default mic');
            console.log(JSON.stringify(e));
        }
    }
    stopAudio() {
        var _a, _b, _c;
        if ((_b = (_a = this.client) === null || _a === void 0 ? void 0 : _a.getSessionInfo()) === null || _b === void 0 ? void 0 : _b.isInMeeting) {
            (_c = this.stream) === null || _c === void 0 ? void 0 : _c.stopAudio();
        }
        this.audioStatus.next('STOPPED');
    }
    muteAudio() {
        var _a;
        (_a = this.stream) === null || _a === void 0 ? void 0 : _a.muteAudio().then(value => {
            this.audioStatus.next('MUTED');
        });
    }
    unmuteAudio() {
        var _a;
        if (this.stream && this.selectedMic.value) {
            (_a = this.stream) === null || _a === void 0 ? void 0 : _a.unmuteAudio().then(value => {
                console.log(value);
                this.audioStatus.next('STARTED');
            }, reason => {
                console.warn('Could not unmute mic, reason: ');
                console.warn(reason);
                this.audioStatus.next('STOPPED');
            });
        }
        else {
            this.audioStatus.next('STOPPED');
        }
    }
    switchMicToSelectedDevice() {
        this.stream.switchMicrophone(this.selectedMic.value.deviceId).then(value1 => {
            this.audioStatus.next('STARTED');
        }, reason => {
            console.warn('Could not switch mic, reason: ');
            console.warn(JSON.stringify(reason));
        });
    }
    toggleAudio() {
        if (this.incomingAudioStatus.value === 'STOPPED') {
            console.log('incoming audio is not started yet, ignored the toggleAudio');
            return;
        }
        if (this.incomingAudioStatus.value === 'FAILED') {
            console.log('incoming audio staring has been failed, trying to start again, then we can unmute the mic');
            this.audioStatus.next('STOPPED');
            this.startIncomingAudio();
            return;
        }
        if (this.audioStatus.value === 'STARTED') {
            this.muteAudio();
        }
        else if (this.audioStatus.value === 'STOPPED') {
            this.unmuteAudio();
        }
        else if (this.audioStatus.value === 'MUTED') {
            this.unmuteAudio();
        }
    }
    toggleVideo() {
        if (this.videoStatus.value === 'STARTED') {
            this.stopVideo();
        }
        else if (this.videoStatus.value === 'STOPPED') {
            this.startVideo();
        }
        else if (this.videoStatus.value === 'TURNED_OFF') {
            this.startVideo();
        }
    }
    getAvailableMicrophones() {
        var _a;
        if (!((_a = this.availableMics.value) === null || _a === void 0 ? void 0 : _a.length)) {
            this.updateAvailableDevices();
        }
        return this.availableMics.asObservable();
    }
    getAvailableDevices(skipPermissionCheck) {
        if (!this.getDevicesObservable) {
            this.getDevicesObservable = from(ZoomVideo.getDevices(skipPermissionCheck)).pipe(finalize(() => {
                this.getDevicesObservable = null;
            }));
        }
        return this.getDevicesObservable;
    }
    updateAvailableDevices(skipPermissionCheck) {
        this.getAvailableDevices(skipPermissionCheck).subscribe(devices => {
            const mics = devices.filter(device => device.kind === 'audioinput');
            this.availableMics.next(mics);
            const cameras = devices.filter(device => device.kind === 'videoinput');
            this.availableCameras.next(cameras);
            const speakers = devices.filter(device => device.kind === 'audiooutput');
            this.availableSpeakers.next(speakers);
        }, (error) => {
            // retry to fetch the devices list but skip permission check
            if (!skipPermissionCheck) {
                this.updateAvailableDevices(true);
            }
            else if (error.name === 'NotAllowedError') {
                this.onDeviceAccessDenied.next(true);
            }
        });
    }
    updateAvailableMics() {
        this.getAvailableDevices().subscribe(devices => {
            const filteredDevices = devices.filter(device => device.kind === 'audioinput');
            this.availableMics.next(filteredDevices);
        }, reason => {
            this.onDeviceAccessDenied.next(true);
        });
    }
    updateAvailableCameras() {
        this.getAvailableDevices().subscribe(devices => {
            const filteredDevices = devices.filter(device => device.kind === 'videoinput');
            this.availableCameras.next(filteredDevices);
        }, reason => {
            this.onDeviceAccessDenied.next(true);
        });
    }
    updateAvailableSpeakers() {
        this.getAvailableDevices().subscribe(devices => {
            const filteredDevices = devices.filter(device => device.kind === 'audiooutput');
            this.availableSpeakers.next(filteredDevices);
        }, reason => {
            this.onDeviceAccessDenied.next(true);
        });
    }
    getAvailableCameras() {
        var _a;
        if (!((_a = this.availableCameras.value) === null || _a === void 0 ? void 0 : _a.length)) {
            this.updateAvailableDevices();
        }
        return this.availableCameras.asObservable();
    }
    getAvailableSpeakers() {
        var _a;
        if (!((_a = this.availableSpeakers.value) === null || _a === void 0 ? void 0 : _a.length)) {
            this.updateAvailableDevices();
        }
        return this.availableSpeakers.asObservable();
    }
    submitTranscriptionMessage(transcriptionMessage) {
        // make sure the transcription is completed, the transcription is not turned off and there are other participants as well
        if (transcriptionMessage.done && this.transcriptionStatus.value !== 'TURNED_OFF' && this.participantJoinStatus.value === 'JOINED') {
            console.log(transcriptionMessage.msgId + ' : ' + transcriptionMessage.text + '(' + transcriptionMessage.displayName + ') T: ' + transcriptionMessage.timestamp);
            const videoCallTranscriptionMessage = new VideoCallTranscriptionMessage(transcriptionMessage.displayName, transcriptionMessage.text, transcriptionMessage.timestamp);
            this.videoCallService.saveTranscriptionMessage(this.currentVideoCall.sessionId, videoCallTranscriptionMessage).subscribe();
        }
    }
    videoStatus$() {
        return this.videoStatus.asObservable();
    }
    audioStatus$() {
        return this.audioStatus.asObservable();
    }
    participantVideoStarted$() {
        return this.participantVideoStarted.asObservable().pipe(filter(value => value != null));
    }
    participantVideoStopped$() {
        return this.participantVideoStopped.asObservable().pipe(filter(value => value != null));
    }
    participantAudioStarted$() {
        return this.participantAudioStarted.asObservable().pipe(filter(value => value != null));
    }
    participantAudioStopped$() {
        return this.participantAudioStopped.asObservable().pipe(filter(value => value != null));
    }
    participantJoinedTheCall$() {
        return this.participantJoinedTheCall.asObservable();
    }
    participantLeftTheCall$() {
        return this.participantLeftTheCall.asObservable();
    }
    onVideoCallEnded$() {
        return this.onVideoCallEnded.asObservable();
    }
    selectedCamera$() {
        return this.selectedCamera.asObservable();
    }
    selectedSpeaker$() {
        return this.selectedSpeaker.asObservable();
    }
    selectedMic$() {
        return this.selectedMic.asObservable();
    }
    onDeviceAccessDenied$() {
        return this.onDeviceAccessDenied.asObservable().pipe(distinctUntilChanged());
    }
    connectionStatus$() {
        return this.connectionStatus.asObservable();
    }
    virtualBackground$() {
        return this.virtualBackground.asObservable();
    }
    virtualBackgroundSupportStatus$() {
        return this.virtualBackgroundSupportStatus.asObservable();
    }
    isVirtualBackgroundEnabled() {
        // if (BrowserDetector.isDesktop()) {
        //   if (this.isSharedArrayBufferEnabled() || BrowserDetector.isLocalHost()) {
        //     return true;
        //   }
        // }
        return false;
    }
    isSharedArrayBufferEnabled() {
        return typeof SharedArrayBuffer === 'function';
    }
}
ZoomService.ɵfac = function ZoomService_Factory(t) { return new (t || ZoomService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.VideoCallService), i0.ɵɵinject(i3.AccountService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.NgbModal)); };
ZoomService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ZoomService, factory: ZoomService.ɵfac, providedIn: 'root' });
export class VideoContainer {
    constructor(canvasElement, width, height, videoElement) {
        this.canvasElement = canvasElement;
        this.width = width;
        this.height = height;
        this.videoElement = videoElement;
    }
}
