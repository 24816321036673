import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { User } from '../../core/user/user.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CoachSlotService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/coach-slot';
    }
    create(coachSlot) {
        coachSlot.coach = new User(coachSlot.coachId);
        return this.http
            .post(`${this.resourceUrl}/create`, coachSlot, { observe: 'response' })
            .pipe(map((res) => this.convertResponseFromServer(res)));
    }
    update(coachSlot) {
        coachSlot.coach = new User(coachSlot.coachId);
        return this.http
            .put(`${this.resourceUrl}/update`, coachSlot, { observe: 'response' })
            .pipe(map((res) => this.convertResponseFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertResponseFromServer(res)));
    }
    queryAllBetween(startDate, endDate) {
        let start = startDate.toISOString();
        let end = endDate.toISOString();
        console.log(start);
        console.log(end);
        return this.http.get(`${this.resourceUrl}/all-for-excell/${start}/${end}`, { observe: 'response' });
    }
    createTimeTable(startDate, endDate) {
        let start = startDate.toISOString();
        let end = endDate.toISOString();
        return this.http.get(`${this.resourceUrl}/time-interval/${start}/${end}`, { observe: 'response' });
    }
    queryAllAvailableBetween(startDate, endDate) {
        let start = startDate.toISOString();
        let end = endDate.toISOString();
        return this.http.get(`${this.resourceUrl}/all-available-for-excel/${start}/${end}`, { responseType: 'blob' });
    }
    queryCoach(coachId, req) {
        const options = createRequestOption(req);
        return this.http
            .get(`${this.resourceUrl}/coach-id/${coachId}`, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertResponseArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    getCoachAvailabilityDashboardData(cadFilter) {
        const headers = new HttpHeaders().set('timeout', '600000');
        return this.http.post(`${this.resourceUrl}/all-dashboard-for-excel`, cadFilter, { observe: 'response', headers });
    }
    convertResponseFromServer(res) {
        if (res.body) {
            res.body.id = res.body.id.toString();
            res.body.startDateTime = moment(res.body.startDateTime, 'YYYY-MM-DDTHH:mm:ssZ');
            res.body.endDateTime = moment(res.body.endDateTime, 'YYYY-MM-DDTHH:mm:ssZ');
            res.body.start = res.body.startDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
            res.body.end = res.body.endDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
            res.body.startTime = res.body.recurrence && res.body.recurrence !== 'none' ? res.body.startDateTime.format('HH:mm') : null;
            res.body.endTime = res.body.recurrence && res.body.recurrence !== 'none' ? res.body.endDateTime.format('HH:mm') : null;
            res.body.nonRecurringEventStart = moment('01-01-2000').add(res.body.startDateTime.hours(), 'hours').add(res.body.startDateTime.minutes(), 'minutes');
            res.body.nonRecurringEventEnd = moment('01-01-2000').add(res.body.endDateTime.hours(), 'hours').add(res.body.endDateTime.minutes(), 'minutes');
            res.body.daysOfWeek = res.body.recurrence && res.body.recurrence === 'weekly' ? [parseInt(res.body.startDateTime.format('E'), 10)] : null;
            res.body.weekDay = parseInt(res.body.startDateTime.format('E'), 10) % 7;
            res.body.title = res.body.recurrence && res.body.recurrence !== 'none' ? res.body.recurrence : '';
            if (res.body.type === 'available') {
                res.body.backgroundColor = '#44a38d';
                res.body.borderColor = '#44a38d';
            }
            if (res.body.type === 'vacation') {
                res.body.backgroundColor = '#B894AA';
                res.body.borderColor = '#B894AA';
            }
        }
        return res;
    }
    convertResponseArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((coachSlot) => {
                coachSlot.id = coachSlot.id.toString();
                coachSlot.startDateTime = moment(coachSlot.startDateTime, 'YYYY-MM-DDTHH:mm:ssZ');
                coachSlot.endDateTime = moment(coachSlot.endDateTime, 'YYYY-MM-DDTHH:mm:ssZ');
                coachSlot.start = coachSlot.startDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
                coachSlot.end = coachSlot.endDateTime.format('YYYY-MM-DDTHH:mm:ssZ');
                coachSlot.startTime = coachSlot.recurrence && coachSlot.recurrence !== 'none' ? coachSlot.startDateTime.format('HH:mm') : null;
                coachSlot.endTime = coachSlot.recurrence && coachSlot.recurrence !== 'none' ? coachSlot.endDateTime.format('HH:mm') : null;
                coachSlot.nonRecurringEventStart = moment('01-01-2000').add(coachSlot.startDateTime.hours(), 'hours').add(coachSlot.startDateTime.minutes(), 'minutes');
                coachSlot.nonRecurringEventEnd = moment('01-01-2000').add(coachSlot.endDateTime.hours(), 'hours').add(coachSlot.endDateTime.minutes(), 'minutes');
                coachSlot.daysOfWeek = coachSlot.recurrence && coachSlot.recurrence === 'weekly' ? [parseInt(coachSlot.startDateTime.format('E'), 10) % 7] : null;
                coachSlot.weekDay = parseInt(coachSlot.startDateTime.format('E'), 10) % 7;
                let sessionType = coachSlot.availableSessionType != null ? coachSlot.availableSessionType : "none";
                coachSlot.title =
                    coachSlot.startDateTime.format('HH:mm') + " - " + coachSlot.endDateTime.format('HH:mm') + "<br>" +
                        "Type: " + coachSlot.type + "<br>" +
                        "Session type: " + sessionType + "<br>" +
                        "Recurrence: " + coachSlot.recurrence;
                if (coachSlot.availableSessionType === 'all') {
                    coachSlot.backgroundColor = '#0A4A3A';
                    coachSlot.borderColor = '#0A4A3A';
                }
                else if (coachSlot.availableSessionType === 'text') {
                    coachSlot.backgroundColor = '#44A38B';
                    coachSlot.borderColor = '#44A38B';
                }
                else if (coachSlot.availableSessionType === 'video') {
                    coachSlot.backgroundColor = '#E0CA58';
                    coachSlot.borderColor = '#E0CA58';
                }
                if (coachSlot.type === 'vacation') {
                    coachSlot.backgroundColor = '#AF3B6E';
                    coachSlot.borderColor = '#AF3B6E';
                }
            });
        }
        return res;
    }
}
CoachSlotService.ɵfac = function CoachSlotService_Factory(t) { return new (t || CoachSlotService)(i0.ɵɵinject(i1.HttpClient)); };
CoachSlotService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CoachSlotService, factory: CoachSlotService.ɵfac, providedIn: 'root' });
