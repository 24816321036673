import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ActivateService {
    constructor(http) {
        this.http = http;
    }
    activate(activationKey) {
        return this.http.get(`${SERVER_API_URL}api/na/account/activate/${activationKey}`, { observe: 'response' });
    }
}
ActivateService.ɵfac = function ActivateService_Factory(t) { return new (t || ActivateService)(i0.ɵɵinject(i1.HttpClient)); };
ActivateService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ActivateService, factory: ActivateService.ɵfac, providedIn: 'root' });
