import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Chart } from "chart.js";
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { JhiLanguageService } from '../../../ng-jhipster/language';
import { drawRoundedEdges } from './chartjs-round-bars';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "../../../ng-jhipster/language";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "ng2-charts";
export class SelfScanTrendModalComponent {
    constructor(activeModal, languageService) {
        this.activeModal = activeModal;
        this.languageService = languageService;
        this.barChartType = 'bar';
        this.barChartPlugins = [DataLabelsPlugin];
        this.groupIndex = 0;
        this.windowWidth = window.innerWidth;
        Chart.elements.Rectangle.prototype.draw = drawRoundedEdges;
    }
    ngOnInit() {
        this.srqHistoryLength = this.srqHistory.length;
        this.loadChart();
    }
    loadChart() {
        this.maxDisplayCount = this.windowWidth >= 992 ? 10 : 6;
        this.displayedSrqs = this.srqHistoryLength <= (this.groupIndex + 1) * this.maxDisplayCount
            ? this.srqHistory.slice(this.groupIndex * this.maxDisplayCount, this.srqHistoryLength)
            : this.srqHistory.slice(this.groupIndex * this.maxDisplayCount, (this.groupIndex + 1) * this.maxDisplayCount);
        this.displayedScores = this.displayedSrqs.map(srq => srq.score);
        this.displayedDates = this.displayedSrqs.map(srq => [
            srq.dateTaken.locale(this.languageService.getCurrentLanguage()).format('D MMM'),
            srq.dateTaken.locale(this.languageService.getCurrentLanguage()).format('hh:mm')
        ]);
        this.displayedColors = this.displayedSrqs.map(srq => {
            if (srq.score <= 3)
                return '#AF3B6E';
            else if (srq.score >= 4 && srq.score <= 13)
                return '#E0CA58';
            else if (srq.score >= 14)
                return '#44A38B';
            else
                return '';
        });
        while (this.displayedScores.length < this.maxDisplayCount) {
            this.displayedScores.push('');
            this.displayedDates.push(['', '']);
            this.displayedColors.push('rgba(255,255,255,0)');
        }
        this.barChartData = [{
                data: this.displayedScores,
                backgroundColor: this.displayedColors,
                borderColor: this.displayedColors,
                hoverBackgroundColor: this.displayedColors,
                hoverBorderColor: this.displayedColors
            }];
        this.barChartLabels = this.displayedDates;
        this.barChartOptions = {
            responsive: true,
            layout: { padding: 30 },
            barThickness: 5 * this.maxDisplayCount,
            scales: {
                yAxes: [{ ticks: { max: 20, min: 0, stepSize: 5 } }],
                xAxes: [{ gridLines: { display: false } }]
            },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    color(context) { return context.dataset.backgroundColor; },
                    font() { return { size: 12, family: 'Poppins' }; }
                },
                legend: { labels: { font: { size: 12 } } }
            }
        };
    }
    swipeLeft() {
        if (this.groupIndex > 0) {
            this.groupIndex--;
            this.loadChart();
        }
    }
    swipeRight(maxDisplay) {
        if (this.srqHistoryLength > maxDisplay * (this.groupIndex + 1)) {
            this.groupIndex++;
            this.loadChart();
        }
    }
    close() {
        this.activeModal.dismiss();
    }
    onResize() {
        let oldWidth = JSON.parse(JSON.stringify(this.windowWidth));
        this.windowWidth = window.innerWidth;
        if ((oldWidth >= 992 && this.windowWidth < 992) || (oldWidth < 992 && this.windowWidth >= 992))
            this.loadChart();
    }
}
SelfScanTrendModalComponent.ɵfac = function SelfScanTrendModalComponent_Factory(t) { return new (t || SelfScanTrendModalComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal), i0.ɵɵdirectiveInject(i2.JhiLanguageService)); };
SelfScanTrendModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SelfScanTrendModalComponent, selectors: [["self-scan-trend-modal"]], hostBindings: function SelfScanTrendModalComponent_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("resize", function SelfScanTrendModalComponent_resize_HostBindingHandler() { return ctx.onResize(); }, false, i0.ɵɵresolveWindow);
    } }, decls: 42, vars: 14, consts: [[1, "olha-theme"], [1, "modal-body"], [1, "d-flex", "justify-content-end"], ["icon", "times-circle", 1, "close-icon", 3, "click"], [1, "d-none", "d-lg-block", "d-xl-block"], [1, "d-flex", "justify-content-between", "align-items-center"], [1, "row", "ml-5"], [1, "legend-box", "bg-success"], ["jhiTranslate", "inukaNgApp.indexUser.resilient1row", 1, "legend-text"], [1, "legend-box", "bg-warning"], ["jhiTranslate", "inukaNgApp.indexUser.atRisk1row", 1, "legend-text"], [1, "legend-box", "bg-danger"], ["jhiTranslate", "inukaNgApp.indexUser.toughPlace1row", 1, "legend-text"], [1, "d-flex", "justify-content-between", "align-items-center", "m-3"], [1, "ml-3", "mr-3", 3, "click"], [1, "arrow"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#left-arrow"], ["jhiTranslate", "inukaNgApp.indexUser.wellbeingResult.previous", 2, "margin-left", "10px"], ["jhiTranslate", "inukaNgApp.indexUser.wellbeingResult.next", 2, "margin-right", "10px"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#right-arrow"], [1, "d-lg-none"], [1, "d-flex", "align-items-center", "m-3"], [2, "display", "block"], ["baseChart", "", 3, "datasets", "labels", "options", "plugins", "chartType", "legend"], [1, "d-lg-none", "mb-4"]], template: function SelfScanTrendModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function SelfScanTrendModalComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelementStart(6, "div", 6);
        i0.ɵɵelement(7, "div", 7);
        i0.ɵɵelement(8, "span", 8);
        i0.ɵɵelement(9, "div", 9);
        i0.ɵɵelement(10, "span", 10);
        i0.ɵɵelement(11, "div", 11);
        i0.ɵɵelement(12, "span", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 13);
        i0.ɵɵelementStart(14, "div", 14);
        i0.ɵɵlistener("click", function SelfScanTrendModalComponent_Template_div_click_14_listener() { return ctx.swipeLeft(); });
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(15, "svg", 15);
        i0.ɵɵelement(16, "use", 16);
        i0.ɵɵelementEnd();
        i0.ɵɵnamespaceHTML();
        i0.ɵɵelement(17, "span", 17);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(18, "div", 14);
        i0.ɵɵlistener("click", function SelfScanTrendModalComponent_Template_div_click_18_listener() { return ctx.swipeRight(10); });
        i0.ɵɵelement(19, "span", 18);
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(20, "svg", 15);
        i0.ɵɵelement(21, "use", 19);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵnamespaceHTML();
        i0.ɵɵelementStart(22, "div", 20);
        i0.ɵɵelementStart(23, "div", 21);
        i0.ɵɵelementStart(24, "div", 14);
        i0.ɵɵlistener("click", function SelfScanTrendModalComponent_Template_div_click_24_listener() { return ctx.swipeLeft(); });
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(25, "svg", 15);
        i0.ɵɵelement(26, "use", 16);
        i0.ɵɵelementEnd();
        i0.ɵɵnamespaceHTML();
        i0.ɵɵelement(27, "span", 17);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(28, "div", 14);
        i0.ɵɵlistener("click", function SelfScanTrendModalComponent_Template_div_click_28_listener() { return ctx.swipeRight(6); });
        i0.ɵɵelement(29, "span", 18);
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(30, "svg", 15);
        i0.ɵɵelement(31, "use", 19);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵnamespaceHTML();
        i0.ɵɵelementStart(32, "div", 22);
        i0.ɵɵelement(33, "canvas", 23);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(34, "div", 24);
        i0.ɵɵelementStart(35, "div", 6);
        i0.ɵɵelement(36, "div", 7);
        i0.ɵɵelement(37, "span", 8);
        i0.ɵɵelement(38, "div", 9);
        i0.ɵɵelement(39, "span", 10);
        i0.ɵɵelement(40, "div", 11);
        i0.ɵɵelement(41, "span", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(14);
        i0.ɵɵclassMap(ctx.groupIndex > 0 ? "swipe-on" : "swipe-off");
        i0.ɵɵadvance(4);
        i0.ɵɵclassMap(ctx.srqHistoryLength > 10 * (ctx.groupIndex + 1) ? "swipe-on" : "swipe-off");
        i0.ɵɵadvance(6);
        i0.ɵɵclassMap(ctx.groupIndex > 0 ? "swipe-on" : "swipe-off");
        i0.ɵɵadvance(4);
        i0.ɵɵclassMap(ctx.srqHistoryLength > 6 * (ctx.groupIndex + 1) ? "swipe-on" : "swipe-off");
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("datasets", ctx.barChartData)("labels", ctx.barChartLabels)("options", ctx.barChartOptions)("plugins", ctx.barChartPlugins)("chartType", ctx.barChartType)("legend", false);
    } }, directives: [i3.FaIconComponent, i4.JhiTranslateDirective, i5.BaseChartDirective], styles: [".close-icon{background:#fff;color:rgba(10,74,58,.2);cursor:pointer}.legend-box{width:15px;height:15px;line-height:30px;margin:2px 5px 2px 2px}.legend-text{font-family:Poppins,sans-serif !important;margin-right:10px}.arrow{height:12px;width:12px}.swipe-on{cursor:pointer}.swipe-off{cursor:not-allowed;opacity:.5}"], encapsulation: 2 });
