import * as i0 from "@angular/core";
export class FilterSessionsPipe {
    constructor() { }
    transform(Session, searchKeyword) {
        if (!searchKeyword)
            return Session;
        return Session
            .filter(c => {
            var _a, _b;
            return ((_a = c.user) === null || _a === void 0 ? void 0 : _a.login.toLocaleLowerCase().includes(searchKeyword.toLocaleLowerCase())) ||
                ((_b = c.sessionStartTime) === null || _b === void 0 ? void 0 : _b.toDate().toString().toLocaleLowerCase().includes(searchKeyword.toLocaleLowerCase()));
        });
    }
}
FilterSessionsPipe.ɵfac = function FilterSessionsPipe_Factory(t) { return new (t || FilterSessionsPipe)(); };
FilterSessionsPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "searchSessions", type: FilterSessionsPipe, pure: false });
