import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import { TranslateService } from "@ngx-translate/core";
import { JhiLanguageService } from '../../../../ng-jhipster/language';
import { JhiEventManager } from '../../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../ng-jhipster/language";
import * as i4 from "../../../../ng-jhipster/service";
import * as i5 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i6 from "@angular/common";
import * as i7 from "ngx-echarts";
function OrganizationDepartmentsEngagementComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "h2", 6);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "div", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 8);
    i0.ɵɵelementStart(7, "h2", 6);
    i0.ɵɵtext(8);
    i0.ɵɵpipe(9, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(10, "div", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(4, 2, ctx_r0.report.averageSrqUsersCount, "1.0-1"));
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(9, 5, ctx_r0.report.averageEndedSessionUsersCount, "1.0-1"));
} }
const _c0 = function () { return { renderer: "svg" }; };
function OrganizationDepartmentsEngagementComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 10);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r1.chartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
export class OrganizationDepartmentsEngagementComponent {
    constructor(organizationDashboardService, translate, languageService, eventManager) {
        this.organizationDashboardService = organizationDashboardService;
        this.translate = translate;
        this.languageService = languageService;
        this.eventManager = eventManager;
        this.isLoadingData = false;
        this.currentLanguage = 'en';
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
        this.currentLanguage = this.languageService.getCurrentLanguage();
        this.eventManager.subscribe('langKeyChanged', () => {
            this.currentLanguage = this.languageService.getCurrentLanguage();
            this.refreshChartData();
        });
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationDepartmentsEngagementReport(this.parameters).subscribe(response => {
            this.report = response.body;
            let threshold = this.report.departmentEngagementStats.length;
            this.refreshChartData();
            this.chartOption.dataZoom = [
                {
                    type: 'slider',
                    yAxisIndex: 0,
                    width: 20,
                    right: 10,
                    start: 100,
                    end: threshold < 6 ? 0 : 100 - 500 / threshold,
                    showDetail: false
                },
                {
                    type: 'inside',
                    id: 'insideY',
                    yAxisIndex: 0,
                    start: 0,
                    end: 100,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true
                }
            ];
            this.isLoadingData = false;
        }, error => {
            console.log(error);
            this.isLoadingData = false;
        });
    }
    refreshChartData() {
        var _a;
        this.chartOption = {
            title: {
                show: false
            },
            grid: {
                top: 10,
                left: '5%',
                right: '5%',
                bottom: 60,
                containLabel: true
            },
            legend: {
                orient: 'horizontal',
                bottom: 5,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            yAxis: {
                type: 'category',
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                axisPointer: {
                    show: false
                }
            },
            xAxis: {
                name: 'Number of users',
                nameLocation: 'middle',
                nameTextStyle: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                nameGap: 30,
                type: 'value',
                boundaryGap: [0, 0.01],
                minInterval: 1,
                max(value) {
                    if (value.max < 10) {
                        return 10;
                    }
                    else {
                        return value.max;
                    }
                },
                splitLine: {
                    show: true,
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            series: [
                {
                    name: this.translate.instant("organizationDashboard.overview.selfScanDone"),
                    type: 'bar',
                    color: '#3CCAEE',
                    encode: {
                        y: 'department',
                        x: 'srqUsersCount' // refer dimensions name
                    },
                    barGap: 0,
                    animationDuration: 3000,
                    label: {
                        show: true,
                        color: '#FFFFFF',
                        fontFamily: "'Open Sans', 'sans-serif'",
                        position: 'inside'
                    },
                    itemStyle: {
                        borderRadius: [0, 4, 4, 0]
                    },
                    barWidth: 20
                },
                {
                    name: this.translate.instant("organizationDashboard.overview.coachingSessionDone"),
                    type: 'bar',
                    color: '#0A4A3A',
                    encode: {
                        y: 'department',
                        x: 'endedSessionUsersCount' // refer dimensions name
                    },
                    barGap: 0,
                    animationDuration: 3000,
                    label: {
                        show: true,
                        color: '#FFFFFF',
                        fontFamily: "'Open Sans', 'sans-serif'",
                        position: 'inside'
                    },
                    itemStyle: {
                        borderRadius: [0, 4, 4, 0]
                    },
                    barWidth: 20
                }
            ]
        };
        const dateset = [];
        if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.departmentEngagementStats) {
            for (const reportItem of this.report.departmentEngagementStats) {
                dateset.unshift([
                    reportItem.departmentName,
                    reportItem.endedSessionsUsersCount,
                    reportItem.srqUsersCount
                ]);
            }
            console.log(dateset);
        }
        this.chartOption.dataset = {
            source: dateset,
            dimensions: ['department', 'endedSessionUsersCount', 'srqUsersCount']
        };
    }
}
OrganizationDepartmentsEngagementComponent.ɵfac = function OrganizationDepartmentsEngagementComponent_Factory(t) { return new (t || OrganizationDepartmentsEngagementComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService), i0.ɵɵdirectiveInject(i2.TranslateService), i0.ɵɵdirectiveInject(i3.JhiLanguageService), i0.ɵɵdirectiveInject(i4.JhiEventManager)); };
OrganizationDepartmentsEngagementComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationDepartmentsEngagementComponent, selectors: [["organization-departments-engagement"]], inputs: { parameters: "parameters" }, decls: 4, vars: 2, consts: [[1, "card", "dashboard-card", "p-2"], ["jhiTranslate", "organizationDashboard.overview.scannersAndCoachesHeader", 1, "m-3", "light-weight"], ["class", "mx-3 my-2 d-flex flex-row", "style", "gap: 10px", 4, "ngIf"], [4, "ngIf"], [1, "mx-3", "my-2", "d-flex", "flex-row", 2, "gap", "10px"], [1, "stat-badge", 2, "background-color", "#F9F4DE"], [1, "m-0"], ["jhiTranslate", "organizationDashboard.overview.scannerBadge"], [1, "stat-badge", 2, "background-color", "#DAEDE8"], ["jhiTranslate", "organizationDashboard.overview.coachBadge"], ["echarts", "", 2, "height", "400px", "width", "100%", 3, "options", "initOpts"]], template: function OrganizationDepartmentsEngagementComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "h4", 1);
        i0.ɵɵtemplate(2, OrganizationDepartmentsEngagementComponent_div_2_Template, 11, 8, "div", 2);
        i0.ɵɵtemplate(3, OrganizationDepartmentsEngagementComponent_ng_container_3_Template, 2, 3, "ng-container", 3);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.report);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isLoadingData && (ctx.chartOption == null ? null : ctx.chartOption.series.length));
    } }, directives: [i5.JhiTranslateDirective, i6.NgIf, i7.NgxEchartsDirective], pipes: [i6.DecimalPipe], styles: [".stat-badge[_ngcontent-%COMP%]{display:flex;flex-direction:column;padding:15px;border-radius:10px;align-content:center;gap:10px;justify-content:center}.stat-badge[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{color:var(--Text-Dark-Green);opacity:60%}@media(min-width: 992px){.stat-badge[_ngcontent-%COMP%]{flex-direction:row;flex-basis:40%;align-content:center;justify-content:center}}"] });
