import { ZoomService } from '../zoom.service';
import * as i0 from "@angular/core";
import * as i1 from "../zoom.service";
import * as i2 from "@angular/common";
function ParticipantStatusComponent_ng_container_0_ng_container_3_span_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 2);
    i0.ɵɵelement(2, "use", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function ParticipantStatusComponent_ng_container_0_ng_container_3_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 2);
    i0.ɵɵelement(2, "use", 3);
    i0.ɵɵelement(3, "use", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function ParticipantStatusComponent_ng_container_0_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ParticipantStatusComponent_ng_container_0_ng_container_3_span_1_Template, 3, 0, "span", 0);
    i0.ɵɵtemplate(2, ParticipantStatusComponent_ng_container_0_ng_container_3_span_2_Template, 4, 0, "span", 0);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.isAudioActive);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r1.isAudioActive);
} }
function ParticipantStatusComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "h4", 1);
    i0.ɵɵtext(2);
    i0.ɵɵtemplate(3, ParticipantStatusComponent_ng_container_0_ng_container_3_Template, 3, 2, "ng-container", 0);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.participantUser.login, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.isParticipantJoined);
} }
export class ParticipantStatusComponent {
    constructor(zoomService) {
        this.zoomService = zoomService;
    }
    ngOnInit() {
        this.participantAudioStartedSubscription = this.zoomService.participantAudioStarted$().subscribe(value => {
            this.isAudioActive = true;
        });
        this.participantAudioStoppedSubscription = this.zoomService.participantAudioStopped$().subscribe(value => {
            this.isAudioActive = false;
        });
        this.participantJoinedTheCallSubscription = this.zoomService.participantJoinedTheCall$().subscribe(userId => {
            this.isParticipantJoined = true;
        });
        this.participantLeftTheCallSubscription = this.zoomService.participantLeftTheCall$().subscribe(userId => {
            this.isParticipantJoined = false;
        });
    }
    ngOnDestroy() {
        var _a, _b, _c, _d;
        (_a = this.participantAudioStoppedSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this.participantAudioStartedSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        (_c = this.participantJoinedTheCallSubscription) === null || _c === void 0 ? void 0 : _c.unsubscribe();
        (_d = this.participantLeftTheCallSubscription) === null || _d === void 0 ? void 0 : _d.unsubscribe();
    }
}
ParticipantStatusComponent.ɵfac = function ParticipantStatusComponent_Factory(t) { return new (t || ParticipantStatusComponent)(i0.ɵɵdirectiveInject(i1.ZoomService)); };
ParticipantStatusComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ParticipantStatusComponent, selectors: [["participant-status"]], inputs: { participantUser: "participantUser" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [1, "video-call-participant-status", "m-0", "py-2", "text-center"], ["width", "22", "height", "22"], ["id", "actual-icon", 0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#microphone-1-light"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#diagonal"]], template: function ParticipantStatusComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, ParticipantStatusComponent_ng_container_0_Template, 4, 2, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.participantUser);
    } }, directives: [i2.NgIf], styles: [".video-call-participant-status[_ngcontent-%COMP%]{background-color:var(--main);color:#fff;font-weight:600;border-radius:6px}svg[_ngcontent-%COMP%]{color:#fff}"] });
