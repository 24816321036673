import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TipService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/tips';
    }
    create(tip) {
        tip = this.removeEmptyFields(tip);
        const copy = this.convertDateFromClient(tip);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    removeEmptyFields(tip) {
        var _a, _b, _c, _d;
        if (tip.srqResultCriteria) {
            if (!((_a = tip.srqResultCriteria.bodyScore) === null || _a === void 0 ? void 0 : _a.length)) {
                tip.srqResultCriteria.bodyScore = null;
            }
            if (!((_b = tip.srqResultCriteria.moodScore) === null || _b === void 0 ? void 0 : _b.length)) {
                tip.srqResultCriteria.moodScore = null;
            }
            if (!((_c = tip.srqResultCriteria.energyScore) === null || _c === void 0 ? void 0 : _c.length)) {
                tip.srqResultCriteria.energyScore = null;
            }
            if (!((_d = tip.srqResultCriteria.purposeScore) === null || _d === void 0 ? void 0 : _d.length)) {
                tip.srqResultCriteria.purposeScore = null;
            }
        }
        if (tip.srqAnswerCriteria && tip.srqAnswerCriteria.questionAnswers) {
            for (let questionId of Object.keys(tip.srqAnswerCriteria.questionAnswers)) {
                if (!tip.srqAnswerCriteria.questionAnswers[questionId] || !tip.srqAnswerCriteria.questionAnswers[questionId].length) {
                    tip.srqAnswerCriteria.questionAnswers[questionId] = undefined;
                }
            }
        }
        return tip;
    }
    update(tip) {
        tip = this.removeEmptyFields(tip);
        const copy = this.convertDateFromClient(tip);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    selectTipByCategory(tipCategory) {
        return this.http.get(`${this.resourceUrl}/suggest/${tipCategory}`, { observe: 'response' });
    }
    selectTip() {
        return this.http.get(`${this.resourceUrl}/suggest`, { observe: 'response' });
    }
    convertDateFromClient(tip) {
        const copy = Object.assign({}, tip, {
        // createDate: tip.createDate && tip.createDate.isValid() ? tip.createDate.toJSON() : undefined,
        // lastModifiedDate: tip.lastModifiedDate && tip.lastModifiedDate.isValid() ? tip.lastModifiedDate.toJSON() : undefined,
        // approvedDate: tip.approvedDate && tip.approvedDate.isValid() ? tip.approvedDate.toJSON() : undefined,
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.createDate = res.body.createDate ? moment(res.body.createDate) : undefined;
            res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
            res.body.approvedDate = res.body.approvedDate ? moment(res.body.approvedDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((tip) => {
                tip.createDate = tip.createDate ? moment(tip.createDate) : undefined;
                tip.lastModifiedDate = tip.lastModifiedDate ? moment(tip.lastModifiedDate) : undefined;
                tip.approvedDate = tip.approvedDate ? moment(tip.approvedDate) : undefined;
            });
        }
        return res;
    }
}
TipService.ɵfac = function TipService_Factory(t) { return new (t || TipService)(i0.ɵɵinject(i1.HttpClient)); };
TipService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TipService, factory: TipService.ɵfac, providedIn: 'root' });
