import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { AuthServerProvider } from '../../core/auth/auth-jwt.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "../../core/auth/auth-jwt.service";
export class AuthInterceptor {
    constructor(localStorage, sessionStorage, authServerProvider) {
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.authServerProvider = authServerProvider;
    }
    intercept(request, next) {
        if (!request || !request.url || (request.url.startsWith('http') && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)))) {
            return next.handle(request);
        }
        const token = this.authServerProvider.getToken();
        if (token === null || token === void 0 ? void 0 : token.length) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        return next.handle(request);
    }
}
AuthInterceptor.ɵfac = function AuthInterceptor_Factory(t) { return new (t || AuthInterceptor)(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i1.SessionStorageService), i0.ɵɵinject(i2.AuthServerProvider)); };
AuthInterceptor.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthInterceptor, factory: AuthInterceptor.ɵfac });
