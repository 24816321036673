import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { SessionStorageService } from "ngx-webstorage";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-webstorage";
export class SessionSettingService {
    constructor(http, sessionStorage) {
        this.http = http;
        this.sessionStorage = sessionStorage;
        this.resourceUrl = SERVER_API_URL + 'api/session-settings';
    }
    create(sessionSetting) {
        return this.http.post(this.resourceUrl, sessionSetting, { observe: 'response' });
    }
    update(sessionSetting) {
        return this.http.put(this.resourceUrl, sessionSetting, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    getOrganizationCodeSessionSettings(organizationCode) {
        const params = new HttpParams().set('organizationCodeId', organizationCode.id.toString());
        return this.http.get(this.resourceUrl, { params, observe: 'response' });
    }
    getSessionDurationByUser(userId) {
        let params = new HttpParams();
        if (userId) {
            params = params.set('userId', userId.toString());
        }
        return this.http.get(`${this.resourceUrl}/session-duration`, { params, observe: 'response' });
    }
    getSessionDurationByUserAndCoach(userId, coachId) {
        let params = new HttpParams();
        if (userId) {
            params = params.set('userId', userId.toString());
        }
        return this.http.get(`${this.resourceUrl}/session-duration/coach/${coachId}`, { params, observe: 'response' });
    }
    getSessionDurationByOrganizationCode(code) {
        return this.http.get(`${this.resourceUrl}/session-duration/organization-code/${code}`, { observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    loadSessionDurationByType(user) {
        let sessionDurationByType;
        if (user) {
            this.getSessionDurationByUser(user.id).subscribe(res1 => {
                sessionDurationByType = res1.body;
                // if video isn't allowed, use text duration instead (just for the description text)
                if (!sessionDurationByType.video) {
                    sessionDurationByType.video = sessionDurationByType.text;
                }
                if (!sessionDurationByType.intro_call) {
                    sessionDurationByType.intro_call = sessionDurationByType.text;
                }
                return sessionDurationByType;
            });
        }
        else {
            const organizationCode = this.sessionStorage.retrieve("organizationCode");
            this.getSessionDurationByOrganizationCode(organizationCode).subscribe(res2 => {
                sessionDurationByType = res2.body;
                // if video isn't allowed, use text duration instead (just for the description text)
                if (!sessionDurationByType.video) {
                    sessionDurationByType.video = sessionDurationByType.text;
                }
                if (!sessionDurationByType.intro_call) {
                    sessionDurationByType.intro_call = sessionDurationByType.text;
                }
                return sessionDurationByType;
            });
        }
    }
}
SessionSettingService.ɵfac = function SessionSettingService_Factory(t) { return new (t || SessionSettingService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionStorageService)); };
SessionSettingService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SessionSettingService, factory: SessionSettingService.ɵfac, providedIn: 'root' });
