import { SessionService } from '../../../../session/session.service';
import { Session } from '../../../../../shared/model/session.model';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "../../../../session/session.service";
import * as i3 from "@angular/common";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "../../../../../ng-jhipster/language/jhi-translate.directive";
const _c0 = function (a0) { return { param: a0 }; };
function SessionEndsInXMinuteComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "fa-icon", 3);
    i0.ɵɵlistener("click", function SessionEndsInXMinuteComponent_div_0_Template_fa_icon_click_2_listener() { i0.ɵɵrestoreView(_r2); const ctx_r1 = i0.ɵɵnextContext(); return ctx_r1.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵelement(4, "h4", 5);
    i0.ɵɵelementStart(5, "div", 6);
    i0.ɵɵelementStart(6, "button", 7);
    i0.ɵɵlistener("click", function SessionEndsInXMinuteComponent_div_0_Template_button_click_6_listener() { i0.ɵɵrestoreView(_r2); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "button", 8);
    i0.ɵɵlistener("click", function SessionEndsInXMinuteComponent_div_0_Template_button_click_7_listener() { i0.ɵɵrestoreView(_r2); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.extendSessionByTenMinutes(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(1, _c0, ctx_r0.minutesLeft));
} }
export class SessionEndsInXMinuteComponent {
    constructor(modalService, sessionService) {
        this.modalService = modalService;
        this.sessionService = sessionService;
    }
    ngOnInit() {
    }
    extendSessionByTenMinutes() {
        this.sessionService.extendSession(this.session.id).subscribe(s => {
            this.close();
        });
    }
    close() {
        this.modalService.dismissAll();
    }
}
SessionEndsInXMinuteComponent.ɵfac = function SessionEndsInXMinuteComponent_Factory(t) { return new (t || SessionEndsInXMinuteComponent)(i0.ɵɵdirectiveInject(i1.NgbModal), i0.ɵɵdirectiveInject(i2.SessionService)); };
SessionEndsInXMinuteComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionEndsInXMinuteComponent, selectors: [["session-ends-in-x-minute"]], inputs: { session: "session", minutesLeft: "minutesLeft" }, decls: 1, vars: 1, consts: [["class", "olha-theme", 4, "ngIf"], [1, "olha-theme"], [1, "d-flex", "justify-content-end"], ["icon", "times-circle", 1, "mt-3", "mr-3", 2, "cursor", "pointer", 3, "click"], [1, "modal-body", "text-center", "pt-0"], ["jhiTranslate", "inukaNgApp.session.yourSessionIsGoingToEnd", 3, "translateValues"], [1, "d-flex", "justify-content-center", "mt-4"], ["type", "button", "jhiTranslate", "sessionChat.back", 1, "btn", "btn-secondary", "m-2", 3, "click"], ["type", "button", "jhiTranslate", "sessionChat.button.extendSession", 1, "btn", "btn-primary", "m-2", 3, "click"]], template: function SessionEndsInXMinuteComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, SessionEndsInXMinuteComponent_div_0_Template, 8, 3, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.minutesLeft);
    } }, directives: [i3.NgIf, i4.FaIconComponent, i5.JhiTranslateDirective], styles: [""] });
