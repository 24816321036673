import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i3 from "@angular/common";
import * as i4 from "ngx-echarts";
const _c0 = function () { return { renderer: "svg" }; };
function OrganizationEngagementStatsComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 4);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r0.chartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
export class OrganizationEngagementStatsComponent {
    constructor(organizationDashboardService) {
        this.organizationDashboardService = organizationDashboardService;
        this.isLoadingData = false;
        this.chartOption = {
            title: {
                show: false
            },
            grid: {
                top: 10,
                left: '7%',
                right: '5%',
                bottom: 60
            },
            legend: {
                orient: 'horizontal',
                bottom: -5,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            tooltip: {
                trigger: 'axis',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            yAxis: {
                type: 'value',
                minInterval: 1,
                max(value) {
                    if (value.max < 10) {
                        return 10;
                    }
                    else {
                        return value.max;
                    }
                },
                splitLine: {
                    show: true
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                splitLine: {
                    show: true
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            series: [
                {
                    name: 'New sign-ups',
                    type: 'bar',
                    color: '#EDB458',
                    encode: {
                        x: 'time',
                        y: 'Registrations' // refer dimensions name
                    },
                    barWidth: '22%',
                    barCategoryGap: '5%',
                    barMaxWidth: 30,
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0]
                    },
                    animationDuration: 3000
                },
                {
                    name: 'Scans done',
                    type: 'bar',
                    color: '#3CCAEE',
                    barWidth: '22%',
                    barCategoryGap: '5%',
                    barMaxWidth: 30,
                    encode: {
                        x: 'time',
                        y: 'Srq' // refer dimensions name
                    },
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0]
                    },
                    animationDuration: 3000
                },
                {
                    name: 'Sessions done',
                    type: 'bar',
                    color: '#0A4A3A',
                    barWidth: '22%',
                    barCategoryGap: '5%',
                    barMaxWidth: 30,
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0]
                    },
                    encode: {
                        x: 'time',
                        y: 'Sessions' // refer dimensions name
                    },
                    animationDuration: 3000
                }
            ]
        };
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationEngagementReport(this.parameters).subscribe(response => {
            this.report = response.body;
            this.refreshChartData();
            this.isLoadingData = false;
        }, error => {
            this.isLoadingData = false;
        });
    }
    refreshChartData() {
        var _a;
        const dateset = [];
        if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.perQuarter) {
            for (const y of Object.keys(this.report.perQuarter))
                for (const q of Object.keys(this.report.perQuarter[y])) {
                    const engagementStats = this.report.perQuarter[y][q];
                    dateset.push([
                        `Q${q} ${y}`,
                        engagementStats.registrationCount,
                        engagementStats.srqCount,
                        engagementStats.sessionsCount
                    ]);
                }
        }
        this.chartOption.dataset = {
            source: dateset,
            dimensions: ['time', 'Registrations', 'Srq', 'Sessions']
        };
    }
}
OrganizationEngagementStatsComponent.ɵfac = function OrganizationEngagementStatsComponent_Factory(t) { return new (t || OrganizationEngagementStatsComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService)); };
OrganizationEngagementStatsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationEngagementStatsComponent, selectors: [["organization-engagement-stats"]], inputs: { parameters: "parameters" }, decls: 4, vars: 1, consts: [[1, "card", "dashboard-card", "p-2"], ["jhiTranslate", "organizationDashboard.overview.engagementHeader", 1, "mt-3", "ml-3", "light-weight"], ["jhiTranslate", "organizationDashboard.overview.engagementIntro", 1, "mb-3", "ml-3"], [4, "ngIf"], ["echarts", "", 1, "e-charts", 3, "options", "initOpts"]], template: function OrganizationEngagementStatsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "h4", 1);
        i0.ɵɵelement(2, "div", 2);
        i0.ɵɵtemplate(3, OrganizationEngagementStatsComponent_ng_container_3_Template, 2, 3, "ng-container", 3);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.isLoadingData && (ctx.chartOption == null ? null : ctx.chartOption.series.length));
    } }, directives: [i2.JhiTranslateDirective, i3.NgIf, i4.NgxEchartsDirective], styles: [".e-charts[_ngcontent-%COMP%]{height:400px;width:100%}"] });
