import * as i0 from "@angular/core";
export class JhiFilterPipe {
    transform(input, filter, field) {
        if (typeof filter === 'undefined' || filter === '') {
            return input;
        }
        // if filter is of type 'function' compute current value of filter, otherwise return filter
        const currentFilter = typeof filter === 'function' ? filter() : filter;
        if (typeof currentFilter === 'number') {
            return input.filter(this.filterByNumber(currentFilter, field));
        }
        if (typeof currentFilter === 'boolean') {
            return input.filter(this.filterByBoolean(currentFilter, field));
        }
        if (typeof currentFilter === 'string') {
            return input.filter(this.filterByString(currentFilter, field));
        }
        if (typeof currentFilter === 'object') {
            // filter by object ignores 'field' if specified
            return input.filter(this.filterByObject(currentFilter));
        }
        // 'symbol' && 'undefined'
        return input.filter(this.filterDefault(currentFilter, field));
    }
    filterByNumber(filter, field) {
        return (value) => (value && !filter) || (typeof value === 'object' && field)
            ? value[field] && typeof value[field] === 'number' && value[field] === filter
            : typeof value === 'number' && value === filter;
    }
    filterByBoolean(filter, field) {
        return (value) => typeof value === 'object' && field
            ? value[field] && typeof value[field] === 'boolean' && value[field] === filter
            : typeof value === 'boolean' && value === filter;
    }
    filterByString(filter, field) {
        return (value) => (value && !filter) || (typeof value === 'object' && field)
            ? value[field] && typeof value[field] === 'string' && value[field].toLowerCase().includes(filter.toLowerCase())
            : typeof value === 'string' && value.toLowerCase().includes(filter.toLowerCase());
    }
    filterDefault(filter, field) {
        return (value) => ((value && !filter) || (typeof value === 'object' && field) ? value[field] && filter === value : filter === value);
    }
    filterByObject(filter) {
        return (value) => {
            const keys = Object.keys(filter);
            let isMatching = false;
            // all fields defined in filter object must match
            for (const key of keys) {
                if (typeof filter[key] === 'number') {
                    isMatching = this.filterByNumber(filter[key])(value[key]);
                }
                else if (typeof filter[key] === 'boolean') {
                    isMatching = this.filterByBoolean(filter[key])(value[key]);
                }
                else if (typeof filter[key] === 'string') {
                    isMatching = this.filterByString(filter[key])(value[key]);
                }
                else {
                    isMatching = this.filterDefault(filter[key])(value[key]);
                }
            }
            return isMatching;
        };
    }
}
JhiFilterPipe.ɵfac = function JhiFilterPipe_Factory(t) { return new (t || JhiFilterPipe)(); };
JhiFilterPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "filter", type: JhiFilterPipe, pure: false });
