import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class RedirectComponent {
    constructor(activatedRoute) {
        this.activatedRoute = activatedRoute;
    }
    ngOnInit() {
        console.log('ngOnInit');
        this.activatedRoute.data.subscribe(data => {
            console.log('data: ' + JSON.stringify(data));
            this.activatedRoute.queryParams.subscribe(params => {
                location.href = params['url'];
            });
        });
    }
}
RedirectComponent.ɵfac = function RedirectComponent_Factory(t) { return new (t || RedirectComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute)); };
RedirectComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: RedirectComponent, selectors: [["redirect-component"]], decls: 0, vars: 0, template: function RedirectComponent_Template(rf, ctx) { }, styles: [""] });
