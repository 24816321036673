import * as i0 from "@angular/core";
export class JhiCapitalizePipe {
    transform(input) {
        if (input !== null) {
            input = input.toLowerCase();
        }
        return input.substring(0, 1).toUpperCase() + input.substring(1);
    }
}
JhiCapitalizePipe.ɵfac = function JhiCapitalizePipe_Factory(t) { return new (t || JhiCapitalizePipe)(); };
JhiCapitalizePipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "capitalize", type: JhiCapitalizePipe, pure: true });
