import { ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionSettingService } from '../../session-setting/session-setting.service';
import { UserService } from '../../../core/user/user.service';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseComponent } from '../../../shared/base.component';
import { SessionDurationSelectionPopupComponent } from '../../../shared/session-duration-selection-popup/session-duration-selection-popup.component';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/router";
import * as i4 from "../../session-setting/session-setting.service";
import * as i5 from "../../../core/user/user.service";
import * as i6 from "../../../shared/coach-info/coach-info.component";
import * as i7 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "@angular/common";
import * as i9 from "../../../shared/session-type-card/session-type-card.component";
function SessionTypeSelectionComponent_div_8_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "session-type-card", 16);
    i0.ɵɵlistener("onSessionTypeSelected", function SessionTypeSelectionComponent_div_8_div_1_Template_session_type_card_onSessionTypeSelected_1_listener($event) { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(2); return ctx_r3.selectSessionType($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const type_r2 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("selectedCoach", ctx_r1.coach)("selected", type_r2 == ctx_r1.selectedSessionType)("sessionType", type_r2)("sessionDurations", ctx_r1.sessionDurationByType[type_r2]);
} }
function SessionTypeSelectionComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵtemplate(1, SessionTypeSelectionComponent_div_8_div_1_Template, 2, 4, "div", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("flex-direction", ctx_r0.isScreenMd ? "row" : "column");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.Object.keys(ctx_r0.sessionDurationByType));
} }
const _c0 = function (a1) { return ["/wellbeing-result2", a1]; };
export class SessionTypeSelectionComponent extends BaseComponent {
    constructor(sessionStorage, modalService, elementRef, activatedRoute, sessionSettingService, userService, router) {
        super(sessionStorage, modalService);
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.elementRef = elementRef;
        this.activatedRoute = activatedRoute;
        this.sessionSettingService = sessionSettingService;
        this.userService = userService;
        this.router = router;
        this.Object = Object;
    }
    ngOnInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F7F4EB';
        this.coachId = this.activatedRoute.snapshot.params["coachId"];
        console.log(this.coachId);
        this.userService.findById(this.coachId).subscribe(c => {
            this.coach = c;
        });
        this.userService.current().subscribe(u => {
            this.currentUser = u;
            this.sessionSettingService.getSessionDurationByUserAndCoach(this.currentUser.id, this.coachId).subscribe(sessionDurationByType => {
                this.sessionDurationByType = sessionDurationByType.body;
                console.log(this.sessionDurationByType);
            });
        });
        this.totalScore = this.sessionStorage.retrieve('totalScore');
    }
    selectSessionType(sessionType) {
        var _a;
        console.log(sessionType);
        this.selectedSessionType = sessionType;
        if (this.sessionDurationByType && ((_a = this.sessionDurationByType[sessionType]) === null || _a === void 0 ? void 0 : _a.length) > 1) {
            this.openSessionDurationSelectionPopup();
        }
    }
    openSessionDurationSelectionPopup() {
        this.selectedSessionDuration = null;
        const modalRef = this.modalService.open(SessionDurationSelectionPopupComponent);
        modalRef.componentInstance.durations = this.sessionDurationByType[this.selectedSessionType];
        modalRef.componentInstance.sessionType = this.selectedSessionType;
        modalRef.result.then(value => {
            if (value) {
                this.selectedSessionDuration = value;
                this.onClickNext();
            }
        }).catch();
    }
    onClickNext() {
        let params = {
            coachId: this.coachId,
            sessionType: this.selectedSessionType
        };
        if (this.selectedSessionDuration) {
            params['duration'] = this.selectedSessionDuration;
        }
        this.router.navigate(['/schedule-session'], { queryParams: params }).then();
        this.modalService.dismissAll();
    }
}
SessionTypeSelectionComponent.ɵfac = function SessionTypeSelectionComponent_Factory(t) { return new (t || SessionTypeSelectionComponent)(i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i3.ActivatedRoute), i0.ɵɵdirectiveInject(i4.SessionSettingService), i0.ɵɵdirectiveInject(i5.UserService), i0.ɵɵdirectiveInject(i3.Router)); };
SessionTypeSelectionComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionTypeSelectionComponent, selectors: [["session-type-selection"]], features: [i0.ɵɵInheritDefinitionFeature], decls: 19, vars: 9, consts: [[1, "olha-theme"], [1, "d-flex", "justify-content-center", "align-items-center", 2, "flex-direction", "column"], [1, "summary-container"], [3, "coachId", "showReasoning"], [1, "selection-container", "d-flex", "justify-content-center"], [1, "olha-theme", "bg-white", "type-selection-card", "d-flex", "justify-content-center", "align-items-center", 2, "flex-direction", "column"], ["jhiTranslate", "sessionTypeSelection.home.selectType"], ["jhiTranslate", "sessionTypeSelection.home.chatRecommended", 2, "max-width", "500px", "text-align", "center"], ["class", "d-flex justify-content-center", 3, "flex-direction", 4, "ngIf"], [1, "container-parent", "container", "mb-0", "mt-0", "d-flex", "justify-content-center", "py-0"], [1, "d-none", "d-lg-block"], [1, "d-flex", "justify-content-center"], ["jhiTranslate", "wellBeingResult.button.back", 1, "btn", "btn-secondary", "m-2", 3, "routerLink"], ["jhiTranslate", "wellBeingResult.button.next", 1, "btn", "btn-primary", "m-2", 3, "click"], [1, "d-lg-none", "mt-0"], [4, "ngFor", "ngForOf"], [3, "selectedCoach", "selected", "sessionType", "sessionDurations", "onSessionTypeSelected"]], template: function SessionTypeSelectionComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelement(3, "coach-info", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelement(6, "h2", 6);
        i0.ɵɵelement(7, "p", 7);
        i0.ɵɵtemplate(8, SessionTypeSelectionComponent_div_8_Template, 2, 3, "div", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 9);
        i0.ɵɵelementStart(10, "div");
        i0.ɵɵelementStart(11, "div", 10);
        i0.ɵɵelementStart(12, "div", 11);
        i0.ɵɵelement(13, "button", 12);
        i0.ɵɵelementStart(14, "button", 13);
        i0.ɵɵlistener("click", function SessionTypeSelectionComponent_Template_button_click_14_listener() { return ctx.onClickNext(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(15, "div", 14);
        i0.ɵɵelementStart(16, "div", 11);
        i0.ɵɵelement(17, "button", 12);
        i0.ɵɵelementStart(18, "button", 13);
        i0.ɵɵlistener("click", function SessionTypeSelectionComponent_Template_button_click_18_listener() { return ctx.onClickNext(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("coachId", ctx.coachId)("showReasoning", true);
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngIf", ctx.sessionDurationByType != null);
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(5, _c0, ctx.totalScore));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(7, _c0, ctx.totalScore));
    } }, directives: [i6.CoachInfoComponent, i7.JhiTranslateDirective, i8.NgIf, i3.RouterLink, i8.NgForOf, i9.SessionTypeCardComponent], styles: [".container[_ngcontent-%COMP%]{border-radius:20px !important}.summary-container[_ngcontent-%COMP%]{width:90% !important;margin:80px 0 0 0 !important}.selection-container[_ngcontent-%COMP%]{width:90% !important;margin:0 !important}.type-selection-card[_ngcontent-%COMP%]{width:100% !important;border-radius:20px;padding:25px 20px;margin:20px}button[_ngcontent-%COMP%]{display:flex;width:200px;height:50px;justify-content:center;align-items:center;flex-shrink:0}"] });
