import { DataSharingConsentService } from '../../data-sharing-consent/data-sharing-consent.service';
import moment from "moment/moment";
import * as i0 from "@angular/core";
import * as i1 from "../../data-sharing-consent/data-sharing-consent.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/slide-toggle";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
function ConsentToShareOrganizationResourceComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "h3", 6);
    i0.ɵɵelement(2, "span", 7);
    i0.ɵɵelementStart(3, "span", 8);
    i0.ɵɵlistener("click", function ConsentToShareOrganizationResourceComponent_div_1_Template_span_click_3_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.isRequest = false; });
    i0.ɵɵelementEnd();
    i0.ɵɵelement(4, "span", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function ConsentToShareOrganizationResourceComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "h4", 10);
    i0.ɵɵelementEnd();
} }
export class ConsentToShareOrganizationResourceComponent {
    constructor(dataSharingConsentService) {
        this.dataSharingConsentService = dataSharingConsentService;
        this.isShared = false;
    }
    ngOnInit() {
        this.dataSharingConsentService.find("ORGANIZATION_RESOURCE", this.userId).subscribe(res => {
            this.organizationalResourcesShared = res.body;
            this.isShared = this.organizationalResourcesShared.consented;
        });
    }
    toggleShareWithCoach(isShared) {
        this.isShared = !isShared;
        this.organizationalResourcesShared.consented = this.isShared;
        this.organizationalResourcesShared.consentDate = null;
        this.organizationalResourcesShared.revokeDate = null;
        this.isShared ? this.organizationalResourcesShared.consentDate = moment() : this.organizationalResourcesShared.revokeDate = moment();
        this.dataSharingConsentService.update(this.organizationalResourcesShared).subscribe(res => {
            console.log(res.body);
        });
        return this.isShared;
    }
}
ConsentToShareOrganizationResourceComponent.ɵfac = function ConsentToShareOrganizationResourceComponent_Factory(t) { return new (t || ConsentToShareOrganizationResourceComponent)(i0.ɵɵdirectiveInject(i1.DataSharingConsentService)); };
ConsentToShareOrganizationResourceComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ConsentToShareOrganizationResourceComponent, selectors: [["consent-to-share-organization-resource"]], inputs: { userId: "userId", isRequest: "isRequest" }, decls: 7, vars: 3, consts: [[1, "olha-theme"], [4, "ngIf"], [1, "d-flex", "align-items-center", "slide-div"], [3, "checked", "toggleChange"], ["jhiTranslate", "inukaNgApp.wellBeingResult2.shareWithCoach", 1, "pl-3", "font-weight-bold"], ["jhiTranslate", "inukaNgApp.wellBeingResult2.shareWithCoachText"], [1, "header-3"], ["jhiTranslate", "inukaNgApp.inukaJourney.coachIsAskingBeforeLink"], ["jhiTranslate", "inukaNgApp.inukaJourney.coachIsAskingLink", 1, "notification-link", 3, "click"], ["jhiTranslate", "inukaNgApp.inukaJourney.coachIsAskingAfterLink"], ["jhiTranslate", "inukaNgApp.wellBeingResult2.shareWithCoachHeader", 1, "header-4"]], template: function ConsentToShareOrganizationResourceComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, ConsentToShareOrganizationResourceComponent_div_1_Template, 5, 0, "div", 1);
        i0.ɵɵtemplate(2, ConsentToShareOrganizationResourceComponent_div_2_Template, 2, 0, "div", 1);
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵelementStart(4, "mat-slide-toggle", 3);
        i0.ɵɵlistener("toggleChange", function ConsentToShareOrganizationResourceComponent_Template_mat_slide_toggle_toggleChange_4_listener() { return ctx.toggleShareWithCoach(ctx.isShared); });
        i0.ɵɵelementEnd();
        i0.ɵɵelement(5, "span", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(6, "p", 5);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isRequest);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isRequest);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("checked", ctx.isShared);
    } }, directives: [i2.NgIf, i3.MatSlideToggle, i4.JhiTranslateDirective], styles: [".header-3[_ngcontent-%COMP%]{margin-bottom:50px}.header-4[_ngcontent-%COMP%]{margin-bottom:20px}.slide-div[_ngcontent-%COMP%]{margin-bottom:15px}[_nghost-%COMP%]  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar{background-color:#44a38b}[_nghost-%COMP%]  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb{background-color:#44a38b}"] });
