import { ElementRef, Renderer2 } from "@angular/core";
import { SessionStorageService } from "ngx-webstorage";
import { NavigationEnd, Router } from "@angular/router";
import { JhiLanguageService } from "../ng-jhipster/language";
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@angular/router";
import * as i3 from "../ng-jhipster/language";
export class WhiteLabelDirective {
    constructor(elementRef, renderer, sessionStorageService, router, languageService) {
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.sessionStorageService = sessionStorageService;
        this.router = router;
        this.languageService = languageService;
        // Preserve alphabetical order in following configuration, the configuration is likely to grow!
        this.laf = {
            "global": {
                "styles": {
                    "dela": [
                        ["color", "#4e738a"],
                        ["fontFamily", '"ScalaSans", sans-serif'],
                    ],
                    "inuka": [
                        ["color", "black"],
                        ["fontFamily", '"Raleway", sans-serif']
                    ]
                },
                "variables": {
                    "dela": [
                        ["--border-rounded-color", "#007fa4"],
                        ["--heading-text-color", "#4e738a"],
                        ["--primary-button-color", "#ed7522"],
                        ["--primary-button-active-color", "rgba(237,117,34,0.8)"],
                    ],
                    "inuka": [
                        ["--border-rounded-color", "#44a38d"],
                        ["--heading-text-color", "#44a38d"],
                        ["--primary-button-color", "#44a38d"],
                        ["--primary-button-active-color", "#177e66"],
                    ]
                }
            },
            "language": {
                "dela": "nl"
            },
            "/register-corporate": {
                "variables": {
                    "dela": [
                        ["--primary-color", "#007fa4"],
                        ["--validation-text-color", "#4e738a"],
                    ]
                }
            },
            "/register-kenya": {
                "variables": {
                    "inuka": [
                        ["--border-rounded-color", "#44a38d"],
                        ["--heading-text-color", "#44a38d"],
                        ["--primary-button-color", "#44a38d"],
                        ["--primary-button-active-color", "#177e66"],
                    ]
                }
            }
        };
    }
    ngOnInit() {
        let organization = this.findOrganizationCode();
        console.log("WhiteLabel organization: " + organization);
        let globalConfiguration = this.laf["global"];
        for (const style of globalConfiguration["styles"][organization]) {
            // console.log("styles: " + JSON.stringify(style));
            this.renderer.setStyle(this.elementRef.nativeElement.ownerDocument.body, style[0], style[1]);
        }
        for (const style of globalConfiguration["variables"][organization]) {
            // console.log("variables: " + JSON.stringify(style));
            document.documentElement.style.setProperty(style[0], style[1]);
        }
        let langKey = this.laf["language"][this.findOrganizationCode()];
        setTimeout(() => {
            if (langKey) {
                console.log("changeLanguage: " + langKey);
                this.languageService.changeLanguage(langKey);
            }
        }, 100);
        this.router.events.subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            let url = event.url;
            let pageConfiguration = this.laf[event.url];
            if (!pageConfiguration) {
                // route specific while-labelling rule does not exist
                console.log("Route specific rules does not exist for: " + url);
                return;
            }
            if (pageConfiguration["variables"] && pageConfiguration["variables"][organization]) {
                let variables = pageConfiguration.variables[organization];
                for (const variable of variables) {
                    console.log("Variable: " + variable);
                    document.documentElement.style.setProperty(variable[0], variable[1]);
                }
            }
            else {
                console.log("Variables do not exist for: " + organization + ", " + url);
                console.log("Variables: " + pageConfiguration);
                console.log("Variables: " + pageConfiguration["variables"]);
                console.log("Variables: " + pageConfiguration["variables"][organization]);
            }
        });
    }
    findOrganizationCode() {
        let organization = "inuka";
        let hostname = window.location.hostname;
        let organizationCodeFromSession = this.sessionStorageService.retrieve('organization');
        if (organizationCodeFromSession) {
            // lets you override active organization by configuration without depending on subdomain
            organization = organizationCodeFromSession;
        }
        else if (hostname === 'localhost' || hostname === 'test.inuka.io') {
            organization = "inuka";
        }
        else if (hostname === 'app.inuka.io') {
            organization = "inuka";
        }
        else if (hostname === 'test-dela.inuka.io') {
            organization = "dela"; // added for test environment
        }
        else {
            // eg: dela.inuka.io -> dela
            organization = hostname.split('.')[0];
        }
        // store it in sessionStorage to make it easily accessible throughout the application
        this.sessionStorageService.store("organization", organization);
        return organization;
    }
}
WhiteLabelDirective.ɵfac = function WhiteLabelDirective_Factory(t) { return new (t || WhiteLabelDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.JhiLanguageService)); };
WhiteLabelDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: WhiteLabelDirective, selectors: [["", "whiteLabel", ""]], inputs: { whiteLabel: "whiteLabel" } });
