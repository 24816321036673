export class SessionSummary {
    constructor(id, creationDate, rating, wentWell, improvable, workedOn, coachId, userId, sessionId, isLastSession, isReferred) {
        this.id = id;
        this.creationDate = creationDate;
        this.rating = rating;
        this.wentWell = wentWell;
        this.improvable = improvable;
        this.workedOn = workedOn;
        this.coachId = coachId;
        this.userId = userId;
        this.sessionId = sessionId;
        this.isLastSession = isLastSession;
        this.isReferred = isReferred;
    }
}
