import { ActionPlanService } from '../../../action-plan/action-plan.service';
import { ActionPlanItemService } from '../../../action-plan-item/action-plan-item.service';
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from '../../../message/message.service';
import { JhiAlertService } from '../../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../../action-plan/action-plan.service";
import * as i2 from "../../../action-plan-item/action-plan-item.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../message/message.service";
import * as i5 from "../../../../ng-jhipster/service";
import * as i6 from "@angular/common";
import * as i7 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "../../session-chat-user/action-plan-form-part/action-plan-form-part.component";
function EditActionPlanComponent_action_plan_form_part_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "action-plan-form-part", 5);
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("session", ctx_r0.session)("actionPlan", ctx_r0.actionPlan)("actionPlanItems", ctx_r0.myFocusThisWeek)("disabled", ctx_r0.disabled)("actionPlanGroup", ctx_r0.myFocusThisWeekGroup);
} }
function EditActionPlanComponent_action_plan_form_part_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "action-plan-form-part", 5);
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("session", ctx_r1.session)("actionPlan", ctx_r1.actionPlan)("actionPlanItems", ctx_r1.theChangeIWantToSee)("disabled", ctx_r1.disabled)("actionPlanGroup", ctx_r1.theChangeIWantToSeeGroup);
} }
function EditActionPlanComponent_action_plan_form_part_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "action-plan-form-part", 5);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("session", ctx_r2.session)("actionPlan", ctx_r2.actionPlan)("actionPlanItems", ctx_r2.thingsICouldTry)("disabled", ctx_r2.disabled)("actionPlanGroup", ctx_r2.thingsICouldTryGroup);
} }
function EditActionPlanComponent_action_plan_form_part_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "action-plan-form-part", 5);
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("session", ctx_r3.session)("actionPlan", ctx_r3.actionPlan)("actionPlanItems", ctx_r3.stepsIWillTake)("disabled", ctx_r3.disabled)("actionPlanGroup", ctx_r3.stepsIWillTakeGroup);
} }
export class EditActionPlanComponent {
    constructor(actionPlanService, actionPlanItemService, translate, messageService, alertService) {
        this.actionPlanService = actionPlanService;
        this.actionPlanItemService = actionPlanItemService;
        this.translate = translate;
        this.messageService = messageService;
        this.alertService = alertService;
        this.myFocusThisWeekGroup = "MY_FOCUS_THIS_WEEK" /* MY_FOCUS_THIS_WEEK */;
        this.theChangeIWantToSeeGroup = "THE_CHANGE_I_WANT_TO_SEE" /* THE_CHANGE_I_WANT_TO_SEE */;
        this.thingsICouldTryGroup = "THINGS_I_COULD_TRY" /* THINGS_I_COULD_TRY */;
        this.stepsIWillTakeGroup = "STEPS_I_WILL_TAKE" /* STEPS_I_WILL_TAKE */;
    }
    set session(value) {
        var _a;
        if ((value === null || value === void 0 ? void 0 : value.id) !== ((_a = this._session) === null || _a === void 0 ? void 0 : _a.id)) {
            this._session = value;
            this.fetchActionPlan();
        }
    }
    get session() {
        return this._session;
    }
    ngOnInit() {
    }
    fetchActionPlan() {
        console.log('fetchActionPlan For Session: ' + this.session.id);
        this.actionPlanService.findBySessionId(this.session.id).subscribe((res) => {
            this.actionPlan = res.body;
            if (this.session.started || this.session.future) {
                this.actionPlanItemService.findItemsByActionPlanId(this.actionPlan.id, false).subscribe((unsharedItemsRes) => {
                    this.extractActionPlanItemsGroups(unsharedItemsRes.body);
                });
            }
            else {
                this.extractActionPlanItemsGroups(this.actionPlan.actionPlanItems);
            }
            // this.actionPlan.actionPlanItems = undefined;
        }, err => console.log(err.message));
    }
    extractActionPlanItemsGroups(items) {
        if (!items) {
            items = []; // prevent null error
        }
        this.myFocusThisWeek = items.filter(i => i.actionPlanGroup === "MY_FOCUS_THIS_WEEK" /* MY_FOCUS_THIS_WEEK */.toString());
        this.theChangeIWantToSee = items.filter(i => i.actionPlanGroup === "THE_CHANGE_I_WANT_TO_SEE" /* THE_CHANGE_I_WANT_TO_SEE */.toString());
        this.thingsICouldTry = items.filter(i => i.actionPlanGroup === "THINGS_I_COULD_TRY" /* THINGS_I_COULD_TRY */.toString());
        this.stepsIWillTake = items.filter(i => i.actionPlanGroup === "STEPS_I_WILL_TAKE" /* STEPS_I_WILL_TAKE */.toString());
    }
    sharePlan() {
        let request = {
            message: this.translate.instant('inukaNgApp.session.wellbeingPlanHasBeenSent'),
            sessionId: this.session.id,
            notifyWellbeingPlanUpdated: true
        };
        this.messageService.shareWellbeingPlan(request).subscribe(() => true, () => console.log('fail'));
        this.alertService.info('inukaNgApp.session.wellbeingPlanHasBeenSent', {});
    }
}
EditActionPlanComponent.ɵfac = function EditActionPlanComponent_Factory(t) { return new (t || EditActionPlanComponent)(i0.ɵɵdirectiveInject(i1.ActionPlanService), i0.ɵɵdirectiveInject(i2.ActionPlanItemService), i0.ɵɵdirectiveInject(i3.TranslateService), i0.ɵɵdirectiveInject(i4.MessageService), i0.ɵɵdirectiveInject(i5.JhiAlertService)); };
EditActionPlanComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: EditActionPlanComponent, selectors: [["edit-action-plan"]], inputs: { session: "session", disabled: "disabled" }, decls: 8, vars: 5, consts: [[1, "olha-theme"], [3, "session", "actionPlan", "actionPlanItems", "disabled", "actionPlanGroup", 4, "ngIf"], [1, "row"], [1, "col-12", "text-center"], ["jhiTranslate", "sessionChat.button.share", 1, "btn", "btn-secondary", 3, "disabled", "click"], [3, "session", "actionPlan", "actionPlanItems", "disabled", "actionPlanGroup"]], template: function EditActionPlanComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, EditActionPlanComponent_action_plan_form_part_1_Template, 1, 5, "action-plan-form-part", 1);
        i0.ɵɵtemplate(2, EditActionPlanComponent_action_plan_form_part_2_Template, 1, 5, "action-plan-form-part", 1);
        i0.ɵɵtemplate(3, EditActionPlanComponent_action_plan_form_part_3_Template, 1, 5, "action-plan-form-part", 1);
        i0.ɵɵtemplate(4, EditActionPlanComponent_action_plan_form_part_4_Template, 1, 5, "action-plan-form-part", 1);
        i0.ɵɵelementStart(5, "div", 2);
        i0.ɵɵelementStart(6, "div", 3);
        i0.ɵɵelementStart(7, "button", 4);
        i0.ɵɵlistener("click", function EditActionPlanComponent_Template_button_click_7_listener() { return ctx.sharePlan(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.myFocusThisWeek);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.theChangeIWantToSee);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.thingsICouldTry);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.stepsIWillTake);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("disabled", ctx.disabled);
    } }, directives: [i6.NgIf, i7.JhiTranslateDirective, i8.ActionPlanFormPartComponent], styles: [""] });
