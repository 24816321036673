import * as i0 from "@angular/core";
export class ConnectingToCallComponent {
    constructor() { }
    ngOnInit() {
    }
}
ConnectingToCallComponent.ɵfac = function ConnectingToCallComponent_Factory(t) { return new (t || ConnectingToCallComponent)(); };
ConnectingToCallComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ConnectingToCallComponent, selectors: [["connecting-to-call"]], decls: 4, vars: 0, consts: [[1, "d-flex", "flex-column", "justify-content-center", "align-items-center", 2, "height", "100%", "width", "100%"], [1, "loader"], [1, "mt-3"]], template: function ConnectingToCallComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "span", 1);
        i0.ɵɵelementStart(2, "h1", 2);
        i0.ɵɵtext(3, "Joining the call");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, styles: [".loader[_ngcontent-%COMP%]{width:16px;height:16px;border-radius:50%;display:block;margin:15px auto;position:relative;background:rgba(68,163,139,.6);box-shadow:-48px 0 rgba(68,163,139,.2),-24px 0 rgba(68,163,139,.4),24px 0 rgba(68,163,139,.8),48px 0 #44a38b;box-sizing:border-box;animation:shadowPulse 2s linear infinite}@keyframes shadowPulse{20%{background:rgba(68,163,139,.4);box-shadow:-48px 0 #44a38b,-24px 0 rgba(68,163,139,.2),24px 0 rgba(68,163,139,.6),48px 0 rgba(68,163,139,.8)}40%{background:rgba(68,163,139,.2);box-shadow:-48px 0 rgba(68,163,139,.8),-24px 0 #44a38b,24px 0 rgba(68,163,139,.4),48px 0 rgba(68,163,139,.6)}60%{background:#44a38b;box-shadow:-48px 0 rgba(68,163,139,.6),-24px 0 rgba(68,163,139,.8),24px 0 rgba(68,163,139,.2),48px 0 rgba(68,163,139,.4)}80%{background:rgba(68,163,139,.8);box-shadow:-48px 0 rgba(68,163,139,.4),-24px 0 rgba(68,163,139,.6),24px 0 #44a38b,48px 0 rgba(68,163,139,.2)}100%{background:rgba(68,163,139,.6);box-shadow:-48px 0 rgba(68,163,139,.2),-24px 0 rgba(68,163,139,.4),24px 0 rgba(68,163,139,.8),48px 0 #44a38b}}"] });
