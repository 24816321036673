import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SecurityPolicyService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/security-policies';
    }
    create(securityPolicy) {
        return this.http.post(this.resourceUrl, securityPolicy, { observe: 'response' });
    }
    update(securityPolicy) {
        return this.http.put(this.resourceUrl, securityPolicy, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    findFrontEndSecurityPolicyByOrganizationCode(organizationCode) {
        return this.http.get(`${this.resourceUrl}/organization-code/${organizationCode}`, { observe: 'response' });
    }
    findFrontEndSecurityPolicyForCurrentUser() {
        return this.http.get(`${this.resourceUrl}/current-user`, { observe: 'response' });
    }
}
SecurityPolicyService.ɵfac = function SecurityPolicyService_Factory(t) { return new (t || SecurityPolicyService)(i0.ɵɵinject(i1.HttpClient)); };
SecurityPolicyService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SecurityPolicyService, factory: SecurityPolicyService.ɵfac, providedIn: 'root' });
