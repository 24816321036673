import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../ng-jhipster/language/jhi-translate.directive";
function GiveChatATryComponent_h3_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h3", 7);
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("jhiTranslate", ctx_r0.header);
} }
export class GiveChatATryComponent {
    constructor() { }
    ngOnInit() { this.header = "sessionSchedule.paragraph." + this.headerWord; }
}
GiveChatATryComponent.ɵfac = function GiveChatATryComponent_Factory(t) { return new (t || GiveChatATryComponent)(); };
GiveChatATryComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: GiveChatATryComponent, selectors: [["give-chat-a-try"]], inputs: { headerWord: "headerWord" }, decls: 12, vars: 1, consts: [[1, "card-body", 2, "font-size", "16px"], [3, "jhiTranslate", 4, "ngIf"], ["jhiTranslate", "sessionSchedule.paragraph.why"], ["jhiTranslate", "sessionSchedule.paragraph.easierToRead"], ["jhiTranslate", "sessionSchedule.paragraph.writingHelps"], ["jhiTranslate", "sessionSchedule.paragraph.anonymous"], ["jhiTranslate", "sessionSchedule.paragraph.switch"], [3, "jhiTranslate"]], template: function GiveChatATryComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, GiveChatATryComponent_h3_1_Template, 1, 1, "h3", 1);
        i0.ɵɵelement(2, "br");
        i0.ɵɵelement(3, "p", 2);
        i0.ɵɵelementStart(4, "ul");
        i0.ɵɵelement(5, "li", 3);
        i0.ɵɵelement(6, "br");
        i0.ɵɵelement(7, "li", 4);
        i0.ɵɵelement(8, "br");
        i0.ɵɵelement(9, "li", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(10, "br");
        i0.ɵɵelement(11, "p", 6);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.header);
    } }, directives: [i1.NgIf, i2.JhiTranslateDirective], styles: [""] });
