import { ElementRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from '../../core/user/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "../../core/user/user.service";
import * as i3 from "@angular/common";
import * as i4 from "./organization-resources.component";
import * as i5 from "../user-interaction/consent-to-share-organization-resource/consent-to-share-organization-resource.component";
function OrganizationResourcesPageComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelement(2, "organization-resources", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 2);
    i0.ɵɵelement(4, "consent-to-share-organization-resource", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("userId", ctx_r0.userId);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("userId", ctx_r0.userId);
} }
export class OrganizationResourcesPageComponent {
    constructor(elementRef, modalService, userService) {
        this.elementRef = elementRef;
        this.modalService = modalService;
        this.userService = userService;
    }
    ngOnInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F7F4EB';
        this.userService.current().subscribe(currentUser => {
            this.userId = currentUser.id;
            console.log("Current user id: " + this.userId);
        });
    }
    closeAll() {
        this.modalService.dismissAll();
    }
}
OrganizationResourcesPageComponent.ɵfac = function OrganizationResourcesPageComponent_Factory(t) { return new (t || OrganizationResourcesPageComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.NgbModal), i0.ɵɵdirectiveInject(i2.UserService)); };
OrganizationResourcesPageComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationResourcesPageComponent, selectors: [["organization-resources-page"]], decls: 1, vars: 1, consts: [["class", "olha-theme wrapper", 4, "ngIf"], [1, "olha-theme", "wrapper"], [1, "container", "col-xl-6", "col-lg-8"], [3, "userId"]], template: function OrganizationResourcesPageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, OrganizationResourcesPageComponent_div_0_Template, 5, 2, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.userId);
    } }, directives: [i3.NgIf, i4.OrganizationResourcesComponent, i5.ConsentToShareOrganizationResourceComponent], styles: [".wrapper[_ngcontent-%COMP%]{margin-top:100px;padding-left:20px;padding-right:20px}.container[_ngcontent-%COMP%]{border-radius:20px;padding:30px;margin:15px auto 15px auto;background:#fff}.close-icon[_ngcontent-%COMP%]{position:absolute !important;top:12px !important;right:12px !important;cursor:pointer !important;color:#0a4a3a !important}.notification-link[_ngcontent-%COMP%]{color:#44a38d;font-weight:bold;text-decoration:underline;cursor:pointer;margin-left:5px}.header-3[_ngcontent-%COMP%]{margin-bottom:50px}"] });
