import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OrganizationCodeExtraQuestionService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/organization-code-extra-questions';
    }
    create(organizationCodeExtraQuestion) {
        return this.http.post(this.resourceUrl, organizationCodeExtraQuestion, { observe: 'response' });
    }
    update(organizationCodeExtraQuestion) {
        return this.http.put(this.resourceUrl, organizationCodeExtraQuestion, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    getByUser(req) {
        const options = createRequestOption(req);
        return this.http.get(`${this.resourceUrl}/current-user`, { params: options, observe: 'response' });
    }
    getByOrganizationCode(code) {
        return this.http.get(`${this.resourceUrl}/organization-code/${code}`, { observe: 'response' });
    }
    getByOrganizationId(id) {
        return this.http.get(`${this.resourceUrl}/organization-id/${id}`, { observe: 'response' });
    }
}
OrganizationCodeExtraQuestionService.ɵfac = function OrganizationCodeExtraQuestionService_Factory(t) { return new (t || OrganizationCodeExtraQuestionService)(i0.ɵɵinject(i1.HttpClient)); };
OrganizationCodeExtraQuestionService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OrganizationCodeExtraQuestionService, factory: OrganizationCodeExtraQuestionService.ɵfac, providedIn: 'root' });
