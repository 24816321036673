import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ActionPlanItemService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/action-plan-items';
    }
    create(actionPlanItem) {
        return this.http.post(this.resourceUrl, actionPlanItem, { observe: 'response' });
    }
    update(actionPlanItem) {
        return this.http.put(this.resourceUrl, actionPlanItem, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    findItemsByActionPlanId(id, shared) {
        return this.http.get(`${this.resourceUrl}/list/${id}?shared=${shared}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
ActionPlanItemService.ɵfac = function ActionPlanItemService_Factory(t) { return new (t || ActionPlanItemService)(i0.ɵɵinject(i1.HttpClient)); };
ActionPlanItemService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ActionPlanItemService, factory: ActionPlanItemService.ɵfac, providedIn: 'root' });
