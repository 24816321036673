import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PasswordResetFinishService } from '../../account/password-reset/finish/password-reset-finish.service';
import { JhiEventManager } from '../../ng-jhipster/service';
import { LeaveNumberModalComponent } from '../login/leave-number-modal.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/forms";
import * as i4 from "../../account/password-reset/finish/password-reset-finish.service";
import * as i5 from "../../ng-jhipster/service";
import * as i6 from "../../shared/unauth-header/unauth-header.component";
import * as i7 from "@angular/common";
import * as i8 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i9 from "@angular/material/form-field";
import * as i10 from "@angular/material/input";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/material/icon";
import * as i13 from "../../shared/password-strength-meter/password-strength-meter.component";
import * as i14 from "../../shared/track-user-activity.directive";
import * as i15 from "@ngx-translate/core";
const _c0 = function () { return { "track": "click", name: "reset_password_btn_clicked" }; };
function PasswordResetComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelement(1, "h3", 7);
    i0.ɵɵelementStart(2, "form", 8);
    i0.ɵɵlistener("ngSubmit", function PasswordResetComponent_div_6_Template_form_ngSubmit_2_listener() { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.requestReset(); });
    i0.ɵɵelementStart(3, "div", 9);
    i0.ɵɵelementStart(4, "mat-form-field", 10);
    i0.ɵɵelementStart(5, "mat-label");
    i0.ɵɵtext(6);
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "input", 11);
    i0.ɵɵlistener("focusin", function PasswordResetComponent_div_6_Template_input_focusin_8_listener() { i0.ɵɵrestoreView(_r4); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.showPasswordMeter = true; })("focusout", function PasswordResetComponent_div_6_Template_input_focusout_8_listener() { i0.ɵɵrestoreView(_r4); const ctx_r6 = i0.ɵɵnextContext(); return ctx_r6.showPasswordMeter = false; })("ngModelChange", function PasswordResetComponent_div_6_Template_input_ngModelChange_8_listener() { i0.ɵɵrestoreView(_r4); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.setPasswordText(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "button", 12);
    i0.ɵɵlistener("click", function PasswordResetComponent_div_6_Template_button_click_9_listener() { i0.ɵɵrestoreView(_r4); const ctx_r8 = i0.ɵɵnextContext(); return ctx_r8.hide = !ctx_r8.hide; });
    i0.ɵɵelementStart(10, "mat-icon", 13);
    i0.ɵɵtext(11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "div", 14);
    i0.ɵɵelement(13, "password-strength-meter-component", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "div", 6);
    i0.ɵɵelement(15, "button", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("formGroup", ctx_r0.resetSubmitForm);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(7, 10, "reset.request.placeholder.password"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("type", ctx_r0.hide ? "password" : "text");
    i0.ɵɵadvance(1);
    i0.ɵɵattribute("aria-label", "Hide password")("aria-pressed", ctx_r0.hide);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.hide ? "visibility_off" : "visibility");
    i0.ɵɵadvance(1);
    i0.ɵɵclassMap(ctx_r0.showPasswordMeter ? "" : "d-none");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("password", ctx_r0.passwordText);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("trackUserActivity", i0.ɵɵpureFunction0(12, _c0));
} }
function PasswordResetComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelement(1, "h3", 17);
    i0.ɵɵelement(2, "p", 18);
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵelement(4, "button", 19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function PasswordResetComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelement(1, "h3", 20);
    i0.ɵɵelementStart(2, "div", 6);
    i0.ɵɵelement(3, "button", 21);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
export class PasswordResetComponent {
    constructor(activatedRoute, modalService, formBuilder, passwordResetFinishService, eventManager) {
        this.activatedRoute = activatedRoute;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.passwordResetFinishService = passwordResetFinishService;
        this.eventManager = eventManager;
        this.passwordTypeInput = 'password';
        this.passwordEyeSlashed = false;
        this.hide = true;
        this.resetSubmitForm = this.formBuilder.group({ password: [''], rememberMe: [false] });
        this.modalOptions = { windowClass: 'modal-window', size: 'md', centered: true };
        this.resetState = 'initialized';
    }
    ngOnInit() {
        this.getResetKey();
        this.eventManager.subscribe('inukaNgApp.httpError', (errorResponse) => {
            this.responseMessage = 'reset.request.paragraph.resetFail';
            this.resetState = 'invalidCode';
            return;
        });
    }
    getResetKey() {
        this.resetCode = this.activatedRoute.snapshot.queryParamMap.get('resetCode');
    }
    onTogglePassword() {
        this.passwordEyeSlashed = !this.passwordEyeSlashed;
        this.passwordTypeInput === 'text' ? (this.passwordTypeInput = 'password') : (this.passwordTypeInput = 'text');
    }
    requestReset() {
        console.log(this.resetSubmitForm.value);
        let password = this.resetSubmitForm.get('password').value.trim();
        if (password.length < 8) {
            this.responseMessage = 'register.messages.error.atLeast8';
            const modalRef = this.modalService.open(LeaveNumberModalComponent, this.modalOptions);
            modalRef.componentInstance.leaveNumberModalRef = modalRef;
            modalRef.componentInstance.displayNLHelpHeader = false;
            modalRef.componentInstance.errorMessage = this.responseMessage;
            return;
        }
        // if (!this.specialCharacters.test(password)) {
        //   this.responseMessage = 'register.messages.error.numberLetterOnlyPassword';
        //   this.classText = 'text-danger';
        //   return;
        // }
        this.passwordResetFinishService.save(this.resetCode, password).subscribe(response => {
            if (response === null) {
                this.resetState = 'passwordChanged';
                return;
            }
        });
    }
    setPasswordText() {
        this.passwordText = this.resetSubmitForm.get('password').value;
    }
}
PasswordResetComponent.ɵfac = function PasswordResetComponent_Factory(t) { return new (t || PasswordResetComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i3.FormBuilder), i0.ɵɵdirectiveInject(i4.PasswordResetFinishService), i0.ɵɵdirectiveInject(i5.JhiEventManager)); };
PasswordResetComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: PasswordResetComponent, selectors: [["password-reset"]], decls: 9, vars: 3, consts: [[1, "olha-theme"], [1, "container", "bg-2"], [1, "text-center", "form-div"], [1, "d-none", "d-md-block", "d-lg-block", "d-xl-block", "text-center"], ["src", "../../../../content/images/inuka-logo-top.svg", "alt", "inuka-logo-top", "width", "70"], ["class", "text-center", 4, "ngIf"], [1, "text-center"], ["jhiTranslate", "reset.request.header.enterNew"], ["name", "form", "role", "form", 3, "formGroup", "ngSubmit"], [1, "d-flex", "flex-column", "form-inside"], ["appearance", "outline", 1, ""], ["matInput", "", "name", "password", "id", "password", "formControlName", "password", 3, "type", "focusin", "focusout", "ngModelChange"], ["type", "button", "mat-icon-button", "", "matSuffix", "", 3, "click"], ["inline", "true"], [1, "mb-2"], [3, "password"], ["type", "submit", "jhiTranslate", "reset.request.button.save", 1, "btn", "btn-primary", "save-button", "mt-4", 3, "trackUserActivity"], ["jhiTranslate", "reset.request.header.success"], ["jhiTranslate", "reset.request.paragraph.canLogin"], ["routerLink", "/login", "type", "button", "jhiTranslate", "reset.request.header.login", 1, "btn", "btn-primary", "login-button", "mt-4"], ["jhiTranslate", "reset.request.paragraph.resetFail"], ["routerLink", "/forgot-password", "type", "button", "jhiTranslate", "reset.request.button.forgotPassword", 1, "btn", "btn-primary", "login-button", "mt-4"]], template: function PasswordResetComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "unauth-header");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵelementStart(4, "div", 3);
        i0.ɵɵelement(5, "img", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, PasswordResetComponent_div_6_Template, 16, 13, "div", 5);
        i0.ɵɵtemplate(7, PasswordResetComponent_div_7_Template, 5, 0, "div", 5);
        i0.ɵɵtemplate(8, PasswordResetComponent_div_8_Template, 4, 0, "div", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("ngIf", ctx.resetState === "initialized");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.resetState === "passwordChanged");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.resetState === "invalidCode");
    } }, directives: [i6.UnauthHeaderComponent, i7.NgIf, i8.JhiTranslateDirective, i3.ɵNgNoValidate, i3.NgControlStatusGroup, i3.FormGroupDirective, i9.MatFormField, i9.MatLabel, i10.MatInput, i3.DefaultValueAccessor, i3.NgControlStatus, i3.FormControlName, i11.MatButton, i9.MatSuffix, i12.MatIcon, i13.PasswordStrengthMeterComponent, i14.TrackUserActivityDirective, i1.RouterLink], pipes: [i15.TranslatePipe], styles: [".container[_ngcontent-%COMP%]{height:auto !important;max-width:500px !important;max-height:570px !important;margin:70px 20px !important;padding-top:0 !important;padding-bottom:0 !important}h3[_ngcontent-%COMP%], form[_ngcontent-%COMP%]{margin-top:30px}.form-div[_ngcontent-%COMP%]{padding:20px !important}.login-button[_ngcontent-%COMP%]{width:200px;height:40px;margin-top:30px}@media(min-width: 576px){.container[_ngcontent-%COMP%]{margin:70px auto !important}}@media(min-width: 768px){.form-div[_ngcontent-%COMP%]{padding:48px !important}.login-button[_ngcontent-%COMP%]{width:250px;height:50px}}"] });
