import { User } from '../../core/user/user.model';
import { Session } from './session.model';
export class Rating {
    constructor(id, appRating, creationDate, message, rating, coach, user, session, approved, langKey) {
        this.id = id;
        this.appRating = appRating;
        this.creationDate = creationDate;
        this.message = message;
        this.rating = rating;
        this.coach = coach;
        this.user = user;
        this.session = session;
        this.approved = approved;
        this.langKey = langKey;
        this.user = new User();
        this.coach = new User();
        this.session = new Session();
    }
}
