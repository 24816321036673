import * as i0 from "@angular/core";
export class PaymentResultComponent {
    constructor() { }
    ngOnInit() { }
}
PaymentResultComponent.ɵfac = function PaymentResultComponent_Factory(t) { return new (t || PaymentResultComponent)(); };
PaymentResultComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: PaymentResultComponent, selectors: [["payment-result"]], decls: 10, vars: 0, template: function PaymentResultComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "p");
        i0.ɵɵtext(1, "payment-result works!");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "p");
        i0.ɵɵtext(3, "payment-result works!");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "p");
        i0.ɵɵtext(5, "payment-result works!");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "p");
        i0.ɵɵtext(7, "payment-result works!");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "p");
        i0.ɵɵtext(9, "payment-result works!");
        i0.ɵɵelementEnd();
    } }, styles: [""] });
