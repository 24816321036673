export class Session {
    constructor(cancellable, rescheduleable, minutesLeftToSessionStart, minutesLeftToSessionEnd, sessionStartTime, coach, id, coachFirstJoin, duration, sessionCount, time, userFirstJoin, userNotified, activeVoucherCode, organizationCodeUsed, cancelOrRescheduleTime, firstMessageTime, lastMessageTime, coachId, userId, actionPlanId, statusOfSession, user, appRating, coachRating, coachScore, coachLogin, userLogin, region, timezone, registrationDate, srqCategory, type, langKey, future, active, started, cancelled, rescheduled, incomplete, ended, completed, noShowUser, noShowCoach, noOneShowed, attendanceType) {
        this.cancellable = cancellable;
        this.rescheduleable = rescheduleable;
        this.minutesLeftToSessionStart = minutesLeftToSessionStart;
        this.minutesLeftToSessionEnd = minutesLeftToSessionEnd;
        this.sessionStartTime = sessionStartTime;
        this.coach = coach;
        this.id = id;
        this.coachFirstJoin = coachFirstJoin;
        this.duration = duration;
        this.sessionCount = sessionCount;
        this.time = time;
        this.userFirstJoin = userFirstJoin;
        this.userNotified = userNotified;
        this.activeVoucherCode = activeVoucherCode;
        this.organizationCodeUsed = organizationCodeUsed;
        this.cancelOrRescheduleTime = cancelOrRescheduleTime;
        this.firstMessageTime = firstMessageTime;
        this.lastMessageTime = lastMessageTime;
        this.coachId = coachId;
        this.userId = userId;
        this.actionPlanId = actionPlanId;
        this.statusOfSession = statusOfSession;
        this.user = user;
        this.appRating = appRating;
        this.coachRating = coachRating;
        this.coachScore = coachScore;
        this.coachLogin = coachLogin;
        this.userLogin = userLogin;
        this.region = region;
        this.timezone = timezone;
        this.registrationDate = registrationDate;
        this.srqCategory = srqCategory;
        this.type = type;
        this.langKey = langKey;
        this.future = future;
        this.active = active;
        this.started = started;
        this.cancelled = cancelled;
        this.rescheduled = rescheduled;
        this.incomplete = incomplete;
        this.ended = ended;
        this.completed = completed;
        this.noShowUser = noShowUser;
        this.noShowCoach = noShowCoach;
        this.noOneShowed = noOneShowed;
        this.attendanceType = attendanceType;
        this.userNotified = this.userNotified || false;
    }
    static changeStatusOfSessionToEnded(session) {
        session.statusOfSession = 'ENDED';
        session.ended = true;
        session.started = false;
    }
}
