import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipe/keys.pipe";
function JhiMetricsEndpointsRequestsComponent_div_2_ng_container_13_tr_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "tr");
    i0.ɵɵelementStart(1, "td");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "td");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "td", 3);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "td", 3);
    i0.ɵɵtext(8);
    i0.ɵɵpipe(9, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const method_r4 = ctx.$implicit;
    const entry_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(method_r4.key);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.key);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(method_r4.value.count);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(9, 4, method_r4.value.mean, "1.0-3"));
} }
function JhiMetricsEndpointsRequestsComponent_div_2_ng_container_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, JhiMetricsEndpointsRequestsComponent_div_2_ng_container_13_tr_1_Template, 10, 7, "tr", 4);
    i0.ɵɵpipe(2, "keys");
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const entry_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(2, 1, entry_r2.value));
} }
function JhiMetricsEndpointsRequestsComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "table", 2);
    i0.ɵɵelementStart(2, "thead");
    i0.ɵɵelementStart(3, "tr");
    i0.ɵɵelementStart(4, "th");
    i0.ɵɵtext(5, "Method");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "th");
    i0.ɵɵtext(7, "Endpoint url");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "th", 3);
    i0.ɵɵtext(9, "Count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "th", 3);
    i0.ɵɵtext(11, "Mean");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "tbody");
    i0.ɵɵtemplate(13, JhiMetricsEndpointsRequestsComponent_div_2_ng_container_13_Template, 3, 3, "ng-container", 4);
    i0.ɵɵpipe(14, "keys");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(13);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(14, 1, ctx_r0.endpointsRequestsMetrics));
} }
export class JhiMetricsEndpointsRequestsComponent {
}
JhiMetricsEndpointsRequestsComponent.ɵfac = function JhiMetricsEndpointsRequestsComponent_Factory(t) { return new (t || JhiMetricsEndpointsRequestsComponent)(); };
JhiMetricsEndpointsRequestsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiMetricsEndpointsRequestsComponent, selectors: [["jhi-metrics-endpoints-requests"]], inputs: { endpointsRequestsMetrics: "endpointsRequestsMetrics", updating: "updating" }, decls: 3, vars: 1, consts: [["class", "table-responsive", 4, "ngIf"], [1, "table-responsive"], [1, "table", "table-striped"], [1, "text-right"], [4, "ngFor", "ngForOf"]], template: function JhiMetricsEndpointsRequestsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "h3");
        i0.ɵɵtext(1, "Endpoints requests (time in millisecond)");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, JhiMetricsEndpointsRequestsComponent_div_2_Template, 15, 3, "div", 0);
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.updating);
    } }, directives: [i1.NgIf, i1.NgForOf], pipes: [i2.JhiKeysPipe, i1.DecimalPipe], encapsulation: 2 });
