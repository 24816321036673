import { CommonModule } from '@angular/common';
import { PayAdyenComponent } from './pay-adyen/pay-adyen.component';
import { RouterModule } from '@angular/router';
import { Authority } from '../../shared/constants/authority.constants';
import { UserRouteAccessService } from '../../core/auth/user-route-access-service';
import { InukaNgSharedModule } from '../../shared/shared.module';
import { PaymentResultComponent } from './payment-result/payment-result.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const paymentRoute = [
    {
        path: 'adyen/:pricingId',
        component: PayAdyenComponent,
        data: { authorities: [Authority.USER], pageTitle: 'app.payment.adyen' },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'result',
        component: PaymentResultComponent,
        data: { authorities: [Authority.USER], pageTitle: 'app.payment.result' },
        canActivate: [UserRouteAccessService]
    }
];
export class PaymentModule {
}
PaymentModule.ɵfac = function PaymentModule_Factory(t) { return new (t || PaymentModule)(); };
PaymentModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: PaymentModule });
PaymentModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[InukaNgSharedModule, CommonModule, RouterModule.forChild(paymentRoute)]] });
