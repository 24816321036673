import { ActivatedRouteSnapshot, Router, Routes } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { ActionPlan } from '../../shared/model/action-plan.model';
import { ActionPlanService } from './action-plan.service';
import * as i0 from "@angular/core";
import * as i1 from "./action-plan.service";
import * as i2 from "@angular/router";
export class ActionPlanResolve {
    constructor(service, router) {
        this.service = service;
        this.router = router;
    }
    resolve(route) {
        const id = route.params['id'];
        if (id) {
            return this.service.find(id).pipe(flatMap((actionPlan) => {
                if (actionPlan.body) {
                    return of(actionPlan.body);
                }
                else {
                    this.router.navigate(['404']);
                    return EMPTY;
                }
            }));
        }
        return of(new ActionPlan());
    }
}
ActionPlanResolve.ɵfac = function ActionPlanResolve_Factory(t) { return new (t || ActionPlanResolve)(i0.ɵɵinject(i1.ActionPlanService), i0.ɵɵinject(i2.Router)); };
ActionPlanResolve.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ActionPlanResolve, factory: ActionPlanResolve.ɵfac, providedIn: 'root' });
export const actionPlanRoute = [];
