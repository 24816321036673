import { ElementRef } from '@angular/core';
import { Location } from "@angular/common";
import { SessionStorageService } from "ngx-webstorage";
import { BaseComponent } from '../../../shared/base.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { TrackerService } from '../../../core/tracker/tracker.service';
import { LookingForHelpTopicsService } from '../../looking-for-help-topics/looking-for-help-topics.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ngx-webstorage";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/router";
import * as i5 from "../../../core/tracker/tracker.service";
import * as i6 from "../../looking-for-help-topics/looking-for-help-topics.service";
import * as i7 from "../../../shared/wellbeing-header/wellbeing-header.component";
import * as i8 from "../../looking-for-help-topics-selector/looking-for-help-topics-selector.component";
import * as i9 from "../../../ng-jhipster/language/jhi-translate.directive";
export class LookingForHelpTopicsComponent extends BaseComponent {
    constructor(location, elementRef, sessionStorage, modalService, router, trackerService, lookingForHelpTopicsService) {
        super(sessionStorage, modalService);
        this.location = location;
        this.elementRef = elementRef;
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.router = router;
        this.trackerService = trackerService;
        this.lookingForHelpTopicsService = lookingForHelpTopicsService;
    }
    ngOnInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
        this.selectedTopics = this.lookingForHelpTopicsService.getSavedHelpTopics();
    }
    onSelectedTopicsChange(selectedTopics) {
        this.selectedTopics = selectedTopics;
        this.lookingForHelpTopicsService.saveHelpTopics(selectedTopics);
    }
    next() {
        this.trackerService.sendActivity('ss_looking_for_help_topics_selected', { 'looking_for_help_topics': this.selectedTopics });
        // this.router.navigate(['/wellbeing-finish']).then();
        this.router.navigate(['/wellbeing-extra-questions/1']).then();
    }
}
LookingForHelpTopicsComponent.ɵfac = function LookingForHelpTopicsComponent_Factory(t) { return new (t || LookingForHelpTopicsComponent)(i0.ɵɵdirectiveInject(i1.Location), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.SessionStorageService), i0.ɵɵdirectiveInject(i3.NgbModal), i0.ɵɵdirectiveInject(i4.Router), i0.ɵɵdirectiveInject(i5.TrackerService), i0.ɵɵdirectiveInject(i6.LookingForHelpTopicsService)); };
LookingForHelpTopicsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: LookingForHelpTopicsComponent, selectors: [["looking-for-help-topics"]], features: [i0.ɵɵInheritDefinitionFeature], decls: 15, vars: 3, consts: [[1, "olha-theme"], [1, "container", "bg-2"], [1, "looking-for-help-container"], [3, "selectedHelpTopics", "selectedHelpTopicsChange"], [1, "d-none", "d-md-block", "d-lg-block", "d-xl-block", "mt-3", "pt-lg-4"], [1, "d-flex", "justify-content-center"], ["jhiTranslate", "inukaNgApp.wellbeingScan.back", "id", "ss-back-btn", 1, "btn", "btn-secondary", "btn-large", "m-2", 3, "click"], ["jhiTranslate", "inukaNgApp.wellbeingScan.next", "id", "ss-submit-help-topics", 1, "btn", "btn-primary", "btn-large", "m-2", 3, "disabled", "click"], [1, "d-md-none", "mt-3"], ["jhiTranslate", "inukaNgApp.wellbeingScan.next", 1, "btn", "btn-primary", "btn-large", "m-2", 3, "disabled", "click"], ["jhiTranslate", "inukaNgApp.wellbeingScan.back", 1, "btn", "btn-secondary", "btn-large", "m-2", 3, "click"]], template: function LookingForHelpTopicsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "wellbeing-header");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵelementStart(4, "div");
        i0.ɵɵelementStart(5, "looking-for-help-topics-selector", 3);
        i0.ɵɵlistener("selectedHelpTopicsChange", function LookingForHelpTopicsComponent_Template_looking_for_help_topics_selector_selectedHelpTopicsChange_5_listener($event) { return ctx.onSelectedTopicsChange($event); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 4);
        i0.ɵɵelementStart(7, "div", 5);
        i0.ɵɵelementStart(8, "button", 6);
        i0.ɵɵlistener("click", function LookingForHelpTopicsComponent_Template_button_click_8_listener() { return ctx.location.back(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "button", 7);
        i0.ɵɵlistener("click", function LookingForHelpTopicsComponent_Template_button_click_9_listener() { return ctx.next(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(10, "div", 8);
        i0.ɵɵelementStart(11, "div", 5);
        i0.ɵɵelementStart(12, "button", 9);
        i0.ɵɵlistener("click", function LookingForHelpTopicsComponent_Template_button_click_12_listener() { return ctx.next(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 5);
        i0.ɵɵelementStart(14, "button", 10);
        i0.ɵɵlistener("click", function LookingForHelpTopicsComponent_Template_button_click_14_listener() { return ctx.location.back(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("selectedHelpTopics", ctx.selectedTopics);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("disabled", !ctx.selectedTopics || !ctx.selectedTopics.length);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("disabled", !ctx.selectedTopics || !ctx.selectedTopics.length);
    } }, directives: [i7.WellbeingHeaderComponent, i8.LookingForHelpTopicsSelectorComponent, i9.JhiTranslateDirective], styles: [".looking-for-help-container[_ngcontent-%COMP%]{max-width:35rem;margin-right:auto;margin-left:auto}.container[_ngcontent-%COMP%]{margin:90px 20px 10px !important;width:auto;max-width:1169px !important;padding-top:30px;text-align:center}@media(min-width: 1200px){.container[_ngcontent-%COMP%]{margin:90px auto 30px !important;padding-top:60px;height:720px}}"] });
