import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PasswordResetInitService {
    constructor(http) {
        this.http = http;
    }
    save(mail) {
        return this.http.post(SERVER_API_URL + 'api/account/reset-password/init', mail);
    }
}
PasswordResetInitService.ɵfac = function PasswordResetInitService_Factory(t) { return new (t || PasswordResetInitService)(i0.ɵɵinject(i1.HttpClient)); };
PasswordResetInitService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PasswordResetInitService, factory: PasswordResetInitService.ɵfac, providedIn: 'root' });
