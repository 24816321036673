import { ActivatedRouteSnapshot, Routes, Router } from '@angular/router';
import { of, EMPTY } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Authority } from '../../shared/constants/authority.constants';
import { UserRouteAccessService } from '../../core/auth/user-route-access-service';
import { SRQCompleted } from '../../shared/model/srq-completed.model';
import { SRQCompletedService } from './srq-completed.service';
import { SRQCompletedDetailComponent } from './srq-completed-detail.component';
import { SRQCompletedUpdateComponent } from './srq-completed-update.component';
import * as i0 from "@angular/core";
import * as i1 from "./srq-completed.service";
import * as i2 from "@angular/router";
export class SRQCompletedResolve {
    constructor(service, router) {
        this.service = service;
        this.router = router;
    }
    resolve(route) {
        const id = route.params['id'];
        if (id) {
            return this.service.find(id).pipe(flatMap((sRQCompleted) => {
                if (sRQCompleted.body) {
                    return of(sRQCompleted.body);
                }
                else {
                    this.router.navigate(['404']);
                    return EMPTY;
                }
            }));
        }
        return of(new SRQCompleted());
    }
}
SRQCompletedResolve.ɵfac = function SRQCompletedResolve_Factory(t) { return new (t || SRQCompletedResolve)(i0.ɵɵinject(i1.SRQCompletedService), i0.ɵɵinject(i2.Router)); };
SRQCompletedResolve.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SRQCompletedResolve, factory: SRQCompletedResolve.ɵfac, providedIn: 'root' });
export const sRQCompletedRoute = [
    {
        path: ':id/view',
        component: SRQCompletedDetailComponent,
        resolve: {
            sRQCompleted: SRQCompletedResolve
        },
        data: {
            authorities: [Authority.MASTERMIND],
            pageTitle: 'inukaNgApp.sRQCompleted.home.title'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'new',
        component: SRQCompletedUpdateComponent,
        resolve: {
            sRQCompleted: SRQCompletedResolve
        },
        data: {
            authorities: [Authority.MASTERMIND],
            pageTitle: 'inukaNgApp.sRQCompleted.home.title'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: ':id/edit',
        component: SRQCompletedUpdateComponent,
        resolve: {
            sRQCompleted: SRQCompletedResolve
        },
        data: {
            authorities: [Authority.MASTERMIND],
            pageTitle: 'inukaNgApp.sRQCompleted.home.title'
        },
        canActivate: [UserRouteAccessService]
    }
];
