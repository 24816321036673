import { ElementRef, EventEmitter } from '@angular/core';
import { ConsentDto } from '../model/consent-dto.model';
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../ng-jhipster/language/jhi-translate.directive";
const _c0 = ["consentTextContainer"];
export class ConsentTextComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.onChange = new EventEmitter();
    }
    ngOnInit() {
        this.translateService.onLangChange.subscribe((event) => {
            this.emitConsent();
        });
    }
    ngAfterViewInit() {
        this.emitConsent();
    }
    emitConsent() {
        const consent = new ConsentDto(this.name, this.consentTextContainer.nativeElement.innerText);
        this.onChange.emit(consent);
    }
}
ConsentTextComponent.ɵfac = function ConsentTextComponent_Factory(t) { return new (t || ConsentTextComponent)(i0.ɵɵdirectiveInject(i1.TranslateService)); };
ConsentTextComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ConsentTextComponent, selectors: [["consent-text"]], viewQuery: function ConsentTextComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.consentTextContainer = _t.first);
    } }, inputs: { name: "name" }, outputs: { onChange: "onChange" }, decls: 2, vars: 1, consts: [[3, "jhiTranslate"], ["consentTextContainer", ""]], template: function ConsentTextComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "span", 0, 1);
    } if (rf & 2) {
        i0.ɵɵpropertyInterpolate1("jhiTranslate", "consents.", ctx.name, "");
    } }, directives: [i2.JhiTranslateDirective], styles: [""] });
