import { TrackerService } from '../tracker/tracker.service';
import { Router } from "@angular/router";
import { AccountService } from '../auth/account.service';
import { BehaviorSubject } from "rxjs";
import { SessionService } from '../../entities/session/session.service';
import { distinct, filter, map, switchMap } from "rxjs/operators";
import { LoginService } from '../login/login.service';
import { VideoCallService } from '../../entities/video-call/video-call.service';
import * as i0 from "@angular/core";
import * as i1 from "../tracker/tracker.service";
import * as i2 from "../auth/account.service";
import * as i3 from "../../entities/session/session.service";
import * as i4 from "@angular/router";
import * as i5 from "../login/login.service";
import * as i6 from "../../entities/video-call/video-call.service";
export class SessionStatusManagerService {
    constructor(trackerService, accountService, sessionService, router, loginService, videoCallService) {
        this.trackerService = trackerService;
        this.accountService = accountService;
        this.sessionService = sessionService;
        this.router = router;
        this.loginService = loginService;
        this.videoCallService = videoCallService;
        this.sessions = [];
        this.onSessionIsAboutToStart$ = new BehaviorSubject(null);
        this.onSessionStarted$ = new BehaviorSubject(null);
    }
    init() {
        this.subscribeToWebsocket();
        this.timer = window.setInterval(() => {
            this.onEachMinute();
        }, 1000 * 60);
        this.getSessionStartedObservable().subscribe(session => {
            if (session) {
                if (this.accountService.isUser()) {
                    this.router.navigate(['/session/chat', session.id]).then();
                }
            }
        });
        this.loginService.onLogout$.subscribe(() => this.resetSessions());
    }
    subscribeToWebsocket() {
        if (this.websocketSubscription) {
            return;
        }
        this.websocketSubscription = this.trackerService.listenerSubject.subscribe(value => {
            const message = value['body'];
            const type = value['type'];
            if (type === 'SESSION_STATUS_CHANGED') {
                let s = message;
                if (s.started) {
                    this.onSessionStarted(s);
                }
                else if (s.ended) {
                }
                this.updateSession(s);
            }
        });
    }
    setSessions(sessions) {
        this.sessions = sessions;
        this.checkSessionsStatus();
    }
    resetSessions() {
        this.sessions = [];
    }
    checkSessionsStatus() {
        var _a;
        if ((_a = this.sessions) === null || _a === void 0 ? void 0 : _a.length) {
            this.sessions.forEach(session => {
                if (session.future || session.active || session.started) {
                    const minutesLeftToSessionStart = this.sessionService.calculateMinutesLeftToSessionStart(session);
                    if (minutesLeftToSessionStart > 0 && minutesLeftToSessionStart <= SessionStatusManagerService.SESSION_IS_ABOUT_TO_START_THRESHOLD) {
                        this.onSessionIsAboutToStart(session);
                    }
                    else if (minutesLeftToSessionStart <= 0 && (session.started || session.future)) {
                        this.onSessionStarted(session);
                    }
                }
            });
        }
    }
    onEachMinute() {
        this.checkSessionsStatus();
    }
    onSessionIsAboutToStart(session) {
        this.onSessionIsAboutToStart$.next(session);
    }
    onSessionStarted(session) {
        this.onSessionStarted$.next(session);
    }
    getSessionIsAboutToStartObservable() {
        return this.onSessionIsAboutToStart$.asObservable()
            .pipe(filter((session) => !!session)) // filer null values
            .pipe(distinct(session => session === null || session === void 0 ? void 0 : session.id))
            .pipe(switchMap(session => {
            return this.sessionService.getSession(session.id).pipe(map(value => {
                const s = value.body;
                this.updateSession(s);
                return s;
            }));
        }));
    }
    updateSession(session) {
        const index = this.sessions.findIndex(s => s.id === session.id);
        if (index >= 0) {
            this.sessions[index] = session;
        }
    }
    getSessionStartedObservable() {
        return this.onSessionStarted$.asObservable()
            .pipe(filter((session) => !!session)) // filer null values
            .pipe(distinct(session => session === null || session === void 0 ? void 0 : session.id))
            .pipe(switchMap(session => {
            return this.sessionService.getSession(session.id).pipe(map(value => {
                const s = value.body;
                this.updateSession(s);
                return s;
            }));
        }));
    }
    navigateToSession(session, forceRedirectToChat) {
        if (session.type === 'text' || forceRedirectToChat) {
            if (this.accountService.isUser()) {
                this.router.navigate(['/session/chat', session.id]).then();
            }
            else {
                this.router.navigate(['/session/chat-coach', session.id]).then();
            }
        }
        else {
            this.videoCallService.isVideoCallInProgress(session).subscribe(isVideoCallInProgress => {
                if (isVideoCallInProgress.body) {
                    if (this.accountService.isUser()) {
                        this.router.navigate(['/session/video', session.id]).then();
                    }
                    else {
                        this.router.navigate(['/session/video-coach', session.id]).then();
                    }
                }
                else {
                    if (this.accountService.isUser()) {
                        this.router.navigate(['/session/chat', session.id]).then();
                    }
                    else {
                        this.router.navigate(['/session/chat-coach', session.id]).then();
                    }
                }
            });
        }
    }
}
SessionStatusManagerService.SESSION_IS_ABOUT_TO_START_THRESHOLD = 10;
SessionStatusManagerService.ɵfac = function SessionStatusManagerService_Factory(t) { return new (t || SessionStatusManagerService)(i0.ɵɵinject(i1.TrackerService), i0.ɵɵinject(i2.AccountService), i0.ɵɵinject(i3.SessionService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.LoginService), i0.ɵɵinject(i6.VideoCallService)); };
SessionStatusManagerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SessionStatusManagerService, factory: SessionStatusManagerService.ɵfac, providedIn: 'root' });
