import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserEmailFeedbackService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/email-feedbacks';
    }
    submitEmailFeedback(feedback) {
        console.log(feedback);
        return this.http.post(SERVER_API_URL + 'api/na/email-feedbacks', feedback, { responseType: 'text' });
    }
    convertDateFromClient(emailFeedback) {
        const copy = Object.assign({}, emailFeedback, {
            emailSendDate: emailFeedback.emailSendDate && emailFeedback.emailSendDate.isValid() ? emailFeedback.emailSendDate.toJSON() : undefined,
            submitDate: emailFeedback.submitDate && emailFeedback.submitDate.isValid() ? emailFeedback.submitDate.toJSON() : undefined,
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.emailSendDate = res.body.emailSendDate ? moment(res.body.emailSendDate) : undefined;
            res.body.submitDate = res.body.submitDate ? moment(res.body.submitDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((emailFeedback) => {
                emailFeedback.emailSendDate = emailFeedback.emailSendDate ? moment(emailFeedback.emailSendDate) : undefined;
                emailFeedback.submitDate = emailFeedback.submitDate ? moment(emailFeedback.submitDate) : undefined;
            });
        }
        return res;
    }
}
UserEmailFeedbackService.ɵfac = function UserEmailFeedbackService_Factory(t) { return new (t || UserEmailFeedbackService)(i0.ɵɵinject(i1.HttpClient)); };
UserEmailFeedbackService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserEmailFeedbackService, factory: UserEmailFeedbackService.ɵfac, providedIn: 'root' });
