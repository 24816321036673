import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common";
import * as i6 from "ngx-echarts";
const _c0 = function () { return { renderer: "svg" }; };
function OrganizationSrqImpactPercentageComponent_ng_container_5_div_8_div_6_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "div", 18);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const department_r8 = ctx.$implicit;
    const ctx_r7 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r7.changeChartOptions[department_r8])("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
function OrganizationSrqImpactPercentageComponent_ng_container_5_div_8_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 16);
    i0.ɵɵtemplate(1, OrganizationSrqImpactPercentageComponent_ng_container_5_div_8_div_6_div_1_Template, 2, 3, "div", 17);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r6.departments);
} }
function OrganizationSrqImpactPercentageComponent_ng_container_5_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "div", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div");
    i0.ɵɵelement(4, "div", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div");
    i0.ɵɵtemplate(6, OrganizationSrqImpactPercentageComponent_ng_container_5_div_8_div_6_Template, 2, 1, "div", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("d-none", ctx_r4.selectedTab !== "firstScan" && (ctx_r4.firstScanChartOption == null ? null : ctx_r4.firstScanChartOption.series.length));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r4.firstScanChartOption)("initOpts", i0.ɵɵpureFunction0(11, _c0));
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("d-none", ctx_r4.selectedTab !== "lastScan" && (ctx_r4.lastScanChartOption == null ? null : ctx_r4.lastScanChartOption.series.length));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r4.lastScanChartOption)("initOpts", i0.ɵɵpureFunction0(12, _c0));
    i0.ɵɵadvance(1);
    i0.ɵɵclassProp("d-none", ctx_r4.selectedTab !== "change" && ctx_r4.departments && ctx_r4.changeChartOptionsCreated);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r4.report);
} }
function OrganizationSrqImpactPercentageComponent_ng_container_5_div_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "fa-icon", 20);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "p", 21);
    i0.ɵɵelementEnd();
} }
function OrganizationSrqImpactPercentageComponent_ng_container_5_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelementStart(2, "div", 7);
    i0.ɵɵlistener("click", function OrganizationSrqImpactPercentageComponent_ng_container_5_Template_div_click_2_listener() { i0.ɵɵrestoreView(_r10); const ctx_r9 = i0.ɵɵnextContext(); return ctx_r9.switchToTab("firstScan"); });
    i0.ɵɵelement(3, "span", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 7);
    i0.ɵɵlistener("click", function OrganizationSrqImpactPercentageComponent_ng_container_5_Template_div_click_4_listener() { i0.ɵɵrestoreView(_r10); const ctx_r11 = i0.ɵɵnextContext(); return ctx_r11.switchToTab("lastScan"); });
    i0.ɵɵelement(5, "span", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 7);
    i0.ɵɵlistener("click", function OrganizationSrqImpactPercentageComponent_ng_container_5_Template_div_click_6_listener() { i0.ɵɵrestoreView(_r10); const ctx_r12 = i0.ɵɵnextContext(); return ctx_r12.switchToTab("change"); });
    i0.ɵɵelement(7, "span", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, OrganizationSrqImpactPercentageComponent_ng_container_5_div_8_Template, 7, 13, "div", 11);
    i0.ɵɵtemplate(9, OrganizationSrqImpactPercentageComponent_ng_container_5_div_9_Template, 4, 0, "div", 12);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngClass", ctx_r1.getTabColor("firstScan"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngClass", ctx_r1.getTabColor("lastScan"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngClass", ctx_r1.getTabColor("change"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.notEnoughData);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.notEnoughData);
} }
function OrganizationSrqImpactPercentageComponent_ng_template_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 22);
    i0.ɵɵelement(1, "p", 23);
    i0.ɵɵelementEnd();
} }
export class OrganizationSrqImpactPercentageComponent {
    constructor(organizationDashboardService) {
        this.organizationDashboardService = organizationDashboardService;
        this.windowWidth = window.innerWidth;
        this.selectedTab = 'firstScan';
        this.minSrqCount = 20;
        this.isLoadingData = false;
        this.changeChartOptionsCreated = false;
        this.leftGrid = '20%';
        this.rightGrid = '10%';
        this.console = console;
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
        this.onResize();
    }
    onResize() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth >= 1450) {
            this.leftGrid = '20%';
        }
        else if (this.windowWidth < 1450 && this.windowWidth >= 1200) {
            this.leftGrid = '25%';
        }
        else if (this.windowWidth < 1200 && this.windowWidth >= 850) {
            this.leftGrid = '15%';
        }
        else if (this.windowWidth < 850 && this.windowWidth >= 650) {
            this.leftGrid = '20%';
        }
        else if (this.windowWidth < 650 && this.windowWidth >= 450) {
            this.leftGrid = '30%';
        }
        else if (this.windowWidth < 450) {
            this.leftGrid = '40%';
        }
        this.refreshChartData();
    }
    switchToTab(tab) {
        this.selectedTab = tab;
    }
    getTabColor(tab) {
        let result = '';
        if (this.selectedTab === tab)
            result = 'page-tab-selected';
        return result;
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationSrqImpactPercentageReport(this.parameters).subscribe(response => {
            this.originalReport = response.body;
            this.excludedDepartments = this.originalReport.firstScans.filter(fs => fs.srqCount != null && fs.srqCount < this.minSrqCount).map(fs => fs.department);
            this.report = JSON.parse(JSON.stringify(this.originalReport));
            this.report.firstScans = this.report.firstScans.filter(fs => fs.srqCount != null && fs.srqCount >= this.minSrqCount);
            this.report.lastScans = this.report.lastScans.filter(ls => ls.srqCount != null && ls.srqCount >= this.minSrqCount);
            this.report.changes = this.report.changes.filter(c => c.srqCount != null && c.srqCount >= this.minSrqCount);
            this.departments = this.report.firstScans.map(fs => fs.department).reverse();
            console.log(this.originalReport);
            console.log(this.report);
            console.log(this.excludedDepartments);
            this.refreshChartData();
            this.isLoadingData = false;
            this.notEnoughData = false;
        }, error => {
            console.log(error);
            if (error.error.title === 'NotEnoughDataForOrganizationReport' || error.statusText === 'Length Required') {
                this.notEnoughData = true;
            }
            this.isLoadingData = false;
        });
    }
    refreshChartData() {
        var _a, _b, _c;
        const dataset = [];
        if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.firstScans) {
            for (const reportItem of this.report.firstScans) {
                dataset.unshift([
                    reportItem.department,
                    reportItem.resilientUsersPercentage,
                    reportItem.atRiskUsersPercentage,
                    reportItem.inAToughPlaceUsersPercentage,
                    reportItem.srqCount
                ]);
            }
        }
        if ((_b = this.report) === null || _b === void 0 ? void 0 : _b.lastScans) {
            for (const reportItem of this.report.lastScans) {
                dataset.unshift([
                    reportItem.department,
                    reportItem.resilientUsersPercentage,
                    reportItem.atRiskUsersPercentage,
                    reportItem.inAToughPlaceUsersPercentage,
                    reportItem.srqCount
                ]);
            }
        }
        if ((_c = this.report) === null || _c === void 0 ? void 0 : _c.changes) {
            for (const reportItem of this.report.changes) {
                dataset.unshift([
                    reportItem.department,
                    reportItem.resilientUsersPercentage,
                    reportItem.atRiskUsersPercentage,
                    reportItem.inAToughPlaceUsersPercentage,
                ]);
            }
        }
        this.firstScanChartOption = {
            tooltip: {
                valueFormatter: v => Math.round(parseFloat(v.toString())) + "%",
                trigger: 'axis',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            legend: {
                orient: 'horizontal',
                bottom: 0,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            grid: {
                top: 10,
                left: this.leftGrid,
                right: this.rightGrid,
                bottom: 60
            },
            xAxis: {
                type: 'value',
                minInterval: 1,
                max: 100,
                splitLine: {
                    show: true
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'",
                    formatter(v) {
                        return v + "%";
                    }
                }
            },
            yAxis: {
                type: 'category',
                boundaryGap: true,
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#0A4A3A'
                    }
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'",
                },
                data: this.report.firstScans.map(fs => fs.department)
            },
            dataZoom: [
                {
                    type: 'slider',
                    yAxisIndex: 0,
                    width: 20,
                    right: 10,
                    start: 100,
                    end: this.report.firstScans.length < 6 ? 0 : 100 - 500 / this.report.firstScans.length,
                    showDetail: false
                },
                {
                    type: 'inside',
                    id: 'insideY',
                    yAxisIndex: 0,
                    start: 0,
                    end: 100,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true
                }
            ],
            series: [
                {
                    name: 'Tough place',
                    type: 'bar',
                    color: '#AF3B6E',
                    stack: 'total',
                    barWidth: 30,
                    label: {
                        color: 'white',
                        show: true,
                        formatter(d) {
                            return d.data > 0 ? Math.round(parseFloat(d.data.toString())) + "%" : "";
                        }
                    },
                    encode: {
                        x: 'inAToughPlaceUsersPercentage',
                        y: 'department'
                    },
                    itemStyle: {
                        borderRadius: [0, 0, 0, 0]
                    },
                    animationDuration: 3000,
                    emphasis: {
                        focus: 'series'
                    },
                    data: this.report.firstScans.map(fs => fs.inAToughPlaceUsersPercentage)
                },
                {
                    name: 'At risk',
                    type: 'bar',
                    color: '#E0CA58',
                    stack: 'total',
                    barWidth: 25,
                    label: {
                        color: 'white',
                        show: true,
                        formatter(d) {
                            return d.data > 0 ? Math.round(parseFloat(d.data.toString())) + "%" : "";
                        }
                    },
                    encode: {
                        x: 'atRiskUsersPercentage',
                        y: 'department'
                    },
                    itemStyle: {
                        borderRadius: [0, 0, 0, 0]
                    },
                    animationDuration: 3000,
                    emphasis: {
                        focus: 'series'
                    },
                    data: this.report.firstScans.map(fs => fs.atRiskUsersPercentage)
                },
                {
                    name: 'Resilient',
                    type: 'bar',
                    color: '#44A38B',
                    stack: 'total',
                    barWidth: 25,
                    label: {
                        color: 'white',
                        show: true,
                        formatter(d) {
                            return d.data > 0 ? Math.round(parseFloat(d.data.toString())) + "%" : "";
                        }
                    },
                    encode: {
                        x: 'resilientUsersPercentage',
                        y: 'department'
                    },
                    itemStyle: {
                        borderRadius: [0, 5, 5, 0]
                    },
                    animationDuration: 3000,
                    emphasis: {
                        focus: 'series'
                    },
                    data: this.report.firstScans.map(fs => fs.resilientUsersPercentage)
                },
            ],
            dataset: {
                source: dataset,
                dimensions: ['department', 'resilientUsersPercentage', 'atRiskUsersPercentage', 'inAToughPlaceUsersPercentage']
            }
        };
        this.lastScanChartOption = {
            tooltip: {
                valueFormatter: v => Math.round(parseFloat(v.toString())) + "%",
                trigger: 'axis',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            legend: {
                orient: 'horizontal',
                bottom: 0,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            grid: {
                top: 10,
                left: this.leftGrid,
                right: this.rightGrid,
                bottom: 60
            },
            xAxis: {
                type: 'value',
                minInterval: 1,
                max: 100,
                splitLine: {
                    show: true
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'",
                    formatter(v) {
                        return v + "%";
                    }
                },
            },
            yAxis: {
                type: 'category',
                boundaryGap: true,
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#0A4A3A'
                    }
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                data: this.report.lastScans.map(ls => ls.department)
            },
            dataZoom: [
                {
                    type: 'slider',
                    yAxisIndex: 0,
                    width: 20,
                    right: 10,
                    start: 100,
                    end: this.report.lastScans.length < 6 ? 0 : 100 - 500 / this.report.lastScans.length,
                    showDetail: false
                },
                {
                    type: 'inside',
                    id: 'insideY',
                    yAxisIndex: 0,
                    start: 0,
                    end: 100,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true
                }
            ],
            series: [
                {
                    name: 'Tough place',
                    type: 'bar',
                    color: '#AF3B6E',
                    stack: 'total',
                    barWidth: 30,
                    label: {
                        color: 'white',
                        show: true,
                        formatter(d) {
                            return d.data > 0 ? Math.round(parseFloat(d.data.toString())) + "%" : "";
                        }
                    },
                    encode: {
                        x: 'inAToughPlaceUsersPercentage',
                        y: 'department'
                    },
                    itemStyle: {
                        borderRadius: [0, 0, 0, 0]
                    },
                    animationDuration: 3000,
                    emphasis: {
                        focus: 'series'
                    },
                    data: this.report.lastScans.map(ls => ls.inAToughPlaceUsersPercentage)
                },
                {
                    name: 'At risk',
                    type: 'bar',
                    color: '#E0CA58',
                    stack: 'total',
                    barWidth: 25,
                    label: {
                        color: 'white',
                        show: true,
                        formatter(d) {
                            return d.data > 0 ? Math.round(parseFloat(d.data.toString())) + "%" : "";
                        }
                    },
                    encode: {
                        x: 'atRiskUsersPercentage',
                        y: 'department'
                    },
                    itemStyle: {
                        borderRadius: [0, 0, 0, 0]
                    },
                    animationDuration: 3000,
                    emphasis: {
                        focus: 'series'
                    },
                    data: this.report.lastScans.map(ls => ls.atRiskUsersPercentage)
                },
                {
                    name: 'Resilient',
                    type: 'bar',
                    color: '#44A38B',
                    stack: 'total',
                    barWidth: 25,
                    label: {
                        color: 'white',
                        show: true,
                        formatter(d) {
                            return d.data > 0 ? Math.round(parseFloat(d.data.toString())) + "%" : "";
                        }
                    },
                    encode: {
                        x: 'resilientUsersPercentage',
                        y: 'department'
                    },
                    itemStyle: {
                        borderRadius: [0, 5, 5, 0]
                    },
                    animationDuration: 3000,
                    emphasis: {
                        focus: 'series'
                    },
                    data: this.report.lastScans.map(ls => ls.resilientUsersPercentage)
                },
            ],
            dataset: {
                source: dataset,
                dimensions: ['department', 'resilientUsersPercentage', 'atRiskUsersPercentage', 'inAToughPlaceUsersPercentage']
            }
        };
        this.changeChartOptions = {};
        this.report.changes.map(c => c.department).forEach(department => {
            let change = this.report.changes.find(c => c.department === department);
            this.changeChartOptions[department] = {
                title: {
                    text: department,
                    textStyle: {
                        color: '#0A4A3A',
                        fontFamily: 'Poppins',
                        fontSize: 18,
                        fontWeight: 600,
                        lineHeight: 18
                    }
                },
                position: 'right',
                tooltip: {
                    valueFormatter: (v) => Math.round(parseFloat(v.toString())) + "%",
                    trigger: 'axis',
                    textStyle: {
                        color: '0A4A3A',
                        fontFamily: "'Open Sans', 'sans-serif'"
                    }
                },
                grid: {
                    top: 40,
                    left: '9%',
                    right: '5%',
                    bottom: 10,
                },
                yAxis: {
                    type: 'value',
                    position: 'top',
                    minInterval: 1,
                    splitLine: {
                        show: true
                    },
                    axisLabel: {
                        color: '#9DB7B0',
                        fontFamily: "'Open Sans', 'sans-serif'",
                        formatter: "{value}%"
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0A4A3A'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#9DB7B0',
                        fontFamily: "'Open Sans', 'sans-serif'"
                    },
                    data: ['Resilient', 'At risk', 'Tough place']
                },
                series: [
                    {
                        type: 'bar',
                        barWidth: 40,
                        barCategoryGap: 40,
                        animationDuration: 3000,
                        data: [
                            {
                                value: change === null || change === void 0 ? void 0 : change.resilientUsersPercentage,
                                itemStyle: {
                                    color: '#44A38B',
                                    borderRadius: (change === null || change === void 0 ? void 0 : change.resilientUsersPercentage) < 0 ? [0, 0, 5, 5] : [5, 5, 0, 0]
                                }
                            },
                            {
                                value: change === null || change === void 0 ? void 0 : change.atRiskUsersPercentage,
                                itemStyle: {
                                    color: '#E0CA58',
                                    borderRadius: (change === null || change === void 0 ? void 0 : change.atRiskUsersPercentage) < 0 ? [0, 0, 5, 5] : [5, 5, 0, 0]
                                }
                            },
                            {
                                value: change === null || change === void 0 ? void 0 : change.inAToughPlaceUsersPercentage,
                                itemStyle: {
                                    color: '#AF3B6E',
                                    borderRadius: (change === null || change === void 0 ? void 0 : change.inAToughPlaceUsersPercentage) < 0 ? [0, 0, 5, 5] : [5, 5, 0, 0]
                                }
                            }
                        ]
                    },
                ]
            };
        });
        this.changeChartOptionsCreated = true;
    }
}
OrganizationSrqImpactPercentageComponent.ɵfac = function OrganizationSrqImpactPercentageComponent_Factory(t) { return new (t || OrganizationSrqImpactPercentageComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService)); };
OrganizationSrqImpactPercentageComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationSrqImpactPercentageComponent, selectors: [["organization-srq-impact-percentage"]], hostBindings: function OrganizationSrqImpactPercentageComponent_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("resize", function OrganizationSrqImpactPercentageComponent_resize_HostBindingHandler() { return ctx.onResize(); }, false, i0.ɵɵresolveWindow);
    } }, inputs: { parameters: "parameters" }, decls: 8, vars: 5, consts: [[1, "card", "dashboard-card", "p-2"], [1, "d-flex", "justify-content-start", "align-items-center"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSrqImpactPercentage.header", 1, "ml-3", "mt-3", "mr-2", "light-weight"], ["icon", "info-circle", 2, "cursor", "pointer", "font-size", "18px", "margin-top", "6px", 3, "ngbPopover", "closeDelay"], ["popover", "ngbPopover"], [4, "ngIf"], ["percentagePopover", ""], [1, "btn", "page-tab", 3, "ngClass", "click"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSrqImpactPercentage.firstScan"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSrqImpactPercentage.lastScan"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSrqImpactPercentage.change"], ["style", "overflow: scroll", 4, "ngIf"], ["class", "not-enough-data-warning d-flex flex-column justify-content-center align-items-center", 4, "ngIf"], [2, "overflow", "scroll"], ["echarts", "", 2, "height", "400px", "width", "100%", 3, "options", "initOpts"], ["class", "grid-container", 4, "ngIf"], [1, "grid-container"], [4, "ngFor", "ngForOf"], ["echarts", "", 1, "e-chart", 3, "options", "initOpts"], [1, "not-enough-data-warning", "d-flex", "flex-column", "justify-content-center", "align-items-center"], ["icon", "database", "size", "2x"], ["jhiTranslate", "inukaNgApp.organizationAdmin.notEnoughData"], [1, "mt-2"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSrqImpactPercentage.percentagePopover"]], template: function OrganizationSrqImpactPercentageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelement(2, "h4", 2);
        i0.ɵɵelement(3, "fa-icon", 3, 4);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, OrganizationSrqImpactPercentageComponent_ng_container_5_Template, 10, 5, "ng-container", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, OrganizationSrqImpactPercentageComponent_ng_template_6_Template, 2, 0, "ng-template", null, 6, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        const _r0 = i0.ɵɵreference(4);
        const _r2 = i0.ɵɵreference(7);
        i0.ɵɵadvance(3);
        i0.ɵɵstyleProp("color", _r0.isOpen() ? "#0A4A3A" : "#9DB7B0");
        i0.ɵɵproperty("ngbPopover", _r2)("closeDelay", 1000);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.isLoadingData);
    } }, directives: [i2.JhiTranslateDirective, i3.FaIconComponent, i4.NgbPopover, i5.NgIf, i5.NgClass, i6.NgxEchartsDirective, i5.NgForOf], styles: [".card[_ngcontent-%COMP%]{max-height:530px}.e-chart[_ngcontent-%COMP%]{margin:5px;height:300px;border-radius:10px;background:#f8f7f2;padding:15px}.not-enough-data-warning[_ngcontent-%COMP%]{width:100%;height:200px}.not-enough-data-warning[_ngcontent-%COMP%]   svg[_ngcontent-%COMP%]   path[_ngcontent-%COMP%]{fill:#9db7b0}.not-enough-data-warning[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin-top:10px;color:var(--green-lighter)}.page-tab[_ngcontent-%COMP%]{border-radius:4px;cursor:pointer;border:1px solid #fff;color:#9db7b0;padding-left:0;padding-right:0;min-width:7rem}.page-tab-selected[_ngcontent-%COMP%]{color:#44a38b;text-decoration:underline}.grid-container[_ngcontent-%COMP%]{display:grid;grid-template-columns:repeat(1, minmax(0, 1fr));padding:10px}.resilient-circle[_ngcontent-%COMP%]{width:14px;height:14px;border-radius:25%;background:#44a38b}.at-risk-circle[_ngcontent-%COMP%]{width:14px;height:14px;border-radius:25%;background:#e0ca58}.tough-place-circle[_ngcontent-%COMP%]{width:14px;height:14px;border-radius:25%;background:#af3b6e}.legend-text[_ngcontent-%COMP%]{color:#0a4a3a;font-family:\"Open Sans\",serif;font-size:12px;font-style:normal;font-weight:400;line-height:140%}@media(min-width: 992px){.grid-container[_ngcontent-%COMP%]{display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));padding:10px}}@media(min-width: 1200px){.not-enough-data-warning[_ngcontent-%COMP%]{height:400px}.grid-container[_ngcontent-%COMP%]{display:grid;grid-template-columns:repeat(1, minmax(0, 1fr));padding:10px}}@media(min-width: 1400px){.grid-container[_ngcontent-%COMP%]{display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));padding:10px}}"] });
