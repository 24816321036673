import { ElementRef } from '@angular/core';
import { AuthServerProvider } from '../../core/auth/auth-jwt.service';
import { LoginService } from '../../core/login/login.service';
import { AccountService } from '../../core/auth/account.service';
import { Router } from "@angular/router";
import { JhiEventManager } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth/auth-jwt.service";
import * as i2 from "../../core/auth/account.service";
import * as i3 from "../../core/login/login.service";
import * as i4 from "@angular/router";
import * as i5 from "../../ng-jhipster/service";
import * as i6 from "../../shared/unauth-header/unauth-header.component";
import * as i7 from "../../shared/util/long-press.directive";
import * as i8 from "../../ng-jhipster/language/jhi-translate.directive";
export class IndexComponent {
    constructor(elementRef, auth, accountService, loginService, router, eventManager) {
        this.elementRef = elementRef;
        this.auth = auth;
        this.accountService = accountService;
        this.loginService = loginService;
        this.router = router;
        this.eventManager = eventManager;
    }
    ngAfterViewInit() {
        if (this.accountService.isAuthenticated()) {
            this.loginService.redirectToHomepage();
        }
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'white';
    }
    ngOnInit() {
        if (this.auth.getToken() != null) {
            this.loginService.redirectToHomepage();
        }
    }
    onGetStarted() {
        this.router.navigate(['/register-new']).then(result => console.log(result));
    }
    onSignIn() {
        this.router.navigate(['/login']).then(result => console.log(result));
    }
    toggleConsoleLogViewer() {
        this.eventManager.broadcast('toggleConsoleLogViewer');
    }
}
IndexComponent.ɵfac = function IndexComponent_Factory(t) { return new (t || IndexComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.AuthServerProvider), i0.ɵɵdirectiveInject(i2.AccountService), i0.ɵɵdirectiveInject(i3.LoginService), i0.ɵɵdirectiveInject(i4.Router), i0.ɵɵdirectiveInject(i5.JhiEventManager)); };
IndexComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: IndexComponent, selectors: [["jhi-index"]], decls: 20, vars: 0, consts: [[1, "olha-theme"], [1, "container", "bg-2"], [1, "d-none", "d-md-block", "d-lg-block", "d-xl-block", "text-center"], ["src", "../../../../content/images/inuka-logo-top.svg", "alt", "inuka-logo-top", "width", "70", "longPress", "", 3, "mouseLongPress"], ["jhiTranslate", "index.feelEvenBetter"], ["jhiTranslate", "index.personalCoach"], ["jhiTranslate", "index.findConnection"], ["jhiTranslate", "index.getStarted", "id", "index-get-started-btn", 1, "btn", "btn-primary", "button-wide", 3, "click"], [1, "already"], ["jhiTranslate", "index.already", 1, "pr-1"], ["routerLink", "/login", "jhiTranslate", "index.logIn", 1, "font-weight-bold"], [1, "d-md-none", "text-center"], ["jhiTranslate", "index.getStarted", 1, "btn", "btn-primary", "button-narrow", 3, "click"]], template: function IndexComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "unauth-header");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵelementStart(4, "img", 3);
        i0.ɵɵlistener("mouseLongPress", function IndexComponent_Template_img_mouseLongPress_4_listener() { return ctx.toggleConsoleLogViewer(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelement(5, "h3", 4);
        i0.ɵɵelement(6, "p", 5);
        i0.ɵɵelement(7, "p", 6);
        i0.ɵɵelementStart(8, "button", 7);
        i0.ɵɵlistener("click", function IndexComponent_Template_button_click_8_listener() { return ctx.onGetStarted(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 8);
        i0.ɵɵelement(10, "span", 9);
        i0.ɵɵelement(11, "a", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(12, "div", 11);
        i0.ɵɵelement(13, "h4", 4);
        i0.ɵɵelement(14, "p", 5);
        i0.ɵɵelement(15, "p", 6);
        i0.ɵɵelementStart(16, "button", 12);
        i0.ɵɵlistener("click", function IndexComponent_Template_button_click_16_listener() { return ctx.onGetStarted(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(17, "div", 8);
        i0.ɵɵelement(18, "span", 9);
        i0.ɵɵelement(19, "a", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, directives: [i6.UnauthHeaderComponent, i7.LongPressDirective, i8.JhiTranslateDirective, i4.RouterLinkWithHref], styles: [".container[_ngcontent-%COMP%]{height:auto !important;max-width:500px !important;max-height:570px !important;margin:70px auto !important}h3[_ngcontent-%COMP%]{margin-top:30px}h4[_ngcontent-%COMP%]{margin-top:42px;font-size:18px !important}.button-wide[_ngcontent-%COMP%]{width:250px;height:50px;margin-top:30px}.button-narrow[_ngcontent-%COMP%]{width:150px;height:40px;margin-top:30px}.already[_ngcontent-%COMP%]{color:#0a4a3a;font-weight:600;margin-top:30px}"] });
