import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl } from "@angular/forms";
import moment from "moment";
import { CoachAvailabilityPreferenceService } from '../admin/coach-availability-preference/coach-availability-preference.service';
import { User } from '../../core/user/user.model';
import { AlertModalComponent } from '../../shared/alert/alert-modal.component';
import { JhiLanguageService } from '../../ng-jhipster/language';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "../admin/coach-availability-preference/coach-availability-preference.service";
import * as i3 from "@angular/forms";
import * as i4 from "../../ng-jhipster/language";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/common";
import * as i7 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "@angular/material/form-field";
import * as i9 from "@angular/material/input";
import * as i10 from "@ngx-translate/core";
const _c0 = function (a0) { return { lastUpdatedDate: a0 }; };
function PlanYourCapacityComponent_div_4_p_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "p", 19);
    i0.ɵɵpipe(1, "date");
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(4, _c0, i0.ɵɵpipeBind2(1, 1, ctx_r1.myAvailabilityPreference.capacityLastUpdated.toDate(), "dd MMM yyyy")));
} }
function PlanYourCapacityComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "h3", 6);
    i0.ɵɵtemplate(3, PlanYourCapacityComponent_div_4_p_3_Template, 2, 6, "p", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "form", 8);
    i0.ɵɵlistener("ngSubmit", function PlanYourCapacityComponent_div_4_Template_form_ngSubmit_4_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.onSubmitPlanYourCapacity(); });
    i0.ɵɵelementStart(5, "div");
    i0.ɵɵelementStart(6, "div", 9);
    i0.ɵɵelement(7, "span", 10);
    i0.ɵɵelementStart(8, "mat-form-field", 11);
    i0.ɵɵelement(9, "input", 12);
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "div", 9);
    i0.ɵɵelement(12, "span", 13);
    i0.ɵɵelementStart(13, "mat-form-field", 11);
    i0.ɵɵelement(14, "input", 12);
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "div", 9);
    i0.ɵɵelement(17, "span", 14);
    i0.ɵɵelement(18, "textarea", 15);
    i0.ɵɵpipe(19, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "div", 16);
    i0.ɵɵtext(21);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(22, "div", 17);
    i0.ɵɵelement(23, "button", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.myAvailabilityPreference.capacityLastUpdated != null);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("formGroup", ctx_r0.planYourCapacityForm);
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(10, 9, "settings.numberOfChat"));
    i0.ɵɵproperty("formControl", ctx_r0.chatSessionsPerWeek);
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(15, 11, "settings.numberOfVideo"));
    i0.ɵɵproperty("formControl", ctx_r0.videoSessionsPerWeek);
    i0.ɵɵadvance(4);
    i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(19, 13, "password.labels.type"));
    i0.ɵɵproperty("formControl", ctx_r0.additionalNotes);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r0.getCommentLength(ctx_r0.myAvailabilityPreference.additionalNotes));
} }
export class PlanYourCapacityComponent {
    constructor(activeModal, coachAvailabilityPreferenceService, formBuilder, modalService, languageService) {
        this.activeModal = activeModal;
        this.coachAvailabilityPreferenceService = coachAvailabilityPreferenceService;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.languageService = languageService;
        this.planYourCapacityForm = this.formBuilder.group({});
        this.modalOptions = { windowClass: 'modal-window', size: 'md', centered: true };
    }
    ngOnInit() {
        console.log(this.myAvailabilityPreference);
        this.chatSessionsPerWeek = new FormControl(this.myAvailabilityPreference.chatSessionsPerWeek);
        this.videoSessionsPerWeek = new FormControl(this.myAvailabilityPreference.videoSessionsPerWeek);
        this.additionalNotes = new FormControl(this.myAvailabilityPreference.additionalNotes);
    }
    onSubmitPlanYourCapacity() {
        this.myAvailabilityPreference.capacityLastUpdated = moment();
        this.myAvailabilityPreference.additionalNotes = this.additionalNotes.value;
        this.myAvailabilityPreference.chatSessionsPerWeek = this.chatSessionsPerWeek.value;
        this.myAvailabilityPreference.videoSessionsPerWeek = this.videoSessionsPerWeek.value;
        console.log(this.myAvailabilityPreference);
        if (this.myAvailabilityPreference.id != null) {
            this.saveMyAvailabilityPreferenceSubscription = this.coachAvailabilityPreferenceService.update(this.myAvailabilityPreference).subscribe(response => {
                console.log(response);
                this.close();
                this.openAlertModal('password.messages.preferenceUpdated');
            }, error => {
                console.log(error);
            });
        }
        else {
            this.myAvailabilityPreference.coach = new User();
            this.myAvailabilityPreference.coach.id = this.coach.coachId;
            this.saveMyAvailabilityPreferenceSubscription = this.coachAvailabilityPreferenceService.create(this.myAvailabilityPreference).subscribe(response => {
                console.log(response);
                this.close();
                this.openAlertModal('password.messages.preferenceCreated');
            }, error => {
                console.log(error);
            });
        }
    }
    openAlertModal(message) {
        const modalRef = this.modalService.open(AlertModalComponent, this.modalOptions);
        modalRef.componentInstance.alertModalRef = modalRef;
        modalRef.componentInstance.language = this.languageService.getCurrentLanguage();
        modalRef.componentInstance.userAlertMessage = message;
    }
    getCommentLength(field) {
        return !field ? 0 : field.length;
    }
    close() {
        this.activeModal.dismiss();
    }
    ngOnDestroy() {
    }
}
PlanYourCapacityComponent.ɵfac = function PlanYourCapacityComponent_Factory(t) { return new (t || PlanYourCapacityComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal), i0.ɵɵdirectiveInject(i2.CoachAvailabilityPreferenceService), i0.ɵɵdirectiveInject(i3.FormBuilder), i0.ɵɵdirectiveInject(i1.NgbModal), i0.ɵɵdirectiveInject(i4.JhiLanguageService)); };
PlanYourCapacityComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: PlanYourCapacityComponent, selectors: [["plan-your-capacity"]], decls: 5, vars: 1, consts: [[1, "olha-theme"], [1, "modal-content", 2, "border", "none!important"], [1, "d-flex", "justify-content-end", "session-summary-icons"], ["icon", "times-circle", 3, "click"], ["class", "modal-body olha-theme modal-body-session-summary", 4, "ngIf"], [1, "modal-body", "olha-theme", "modal-body-session-summary"], ["jhiTranslate", "settings.planYourCapacity"], ["style", "color: #8A9D98", "jhiTranslate", "settings.lastUpdated", 3, "translateValues", 4, "ngIf"], ["name", "planYourCapacityForm", "role", "form", 3, "formGroup", "ngSubmit"], [1, "d-flex", 2, "flex-direction", "column"], ["jhiTranslate", "settings.numberOfChatPerWeek"], ["appearance", "outline", 1, "frequency-form-field"], ["type", "number", "matInput", "", 3, "placeholder", "formControl"], ["jhiTranslate", "settings.numberOfVideoPerWeek"], ["jhiTranslate", "settings.helpYouWith", 2, "margin-bottom", "6px"], ["rows", "5", "maxlength", "500", "name", "additionalNotes", 1, "form-control", 3, "formControl", "placeholder"], [1, "text-right"], [1, "alert-box-actions", 2, "text-align", "center", "margin-top", "10px!important"], ["type", "submit", "jhiTranslate", "sessionChat.button.submit", 1, "btn", "btn-gradient-primary", "font-weight-bold", "rounded-button", 2, "margin", "5px", "border-radius", "40px", "width", "100px"], ["jhiTranslate", "settings.lastUpdated", 2, "color", "#8A9D98", 3, "translateValues"]], template: function PlanYourCapacityComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function PlanYourCapacityComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(4, PlanYourCapacityComponent_div_4_Template, 24, 15, "div", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.myAvailabilityPreference);
    } }, directives: [i5.FaIconComponent, i6.NgIf, i7.JhiTranslateDirective, i3.ɵNgNoValidate, i3.NgControlStatusGroup, i3.FormGroupDirective, i8.MatFormField, i3.NumberValueAccessor, i9.MatInput, i3.DefaultValueAccessor, i3.NgControlStatus, i3.FormControlDirective, i3.MaxLengthValidator], pipes: [i10.TranslatePipe, i6.DatePipe], styles: [".modal-content[_ngcontent-%COMP%]{padding:15px}.modal-body[_ngcontent-%COMP%]{padding-top:0 !important}span[_ngcontent-%COMP%]{font-weight:400;font-size:16px;color:#0a4a3a}.form-control[_ngcontent-%COMP%]{border:1px rgba(138,157,152,.3) solid}input[_ngcontent-%COMP%], textarea[_ngcontent-%COMP%]{outline:#fff none !important}.frequency-form-field[_ngcontent-%COMP%]{flex:1}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-outline{color:#cce9e0 !important}[_nghost-%COMP%]     .mat-form-field-appearance-outline .mat-form-field-outline-thick{color:var(--main) !important}[_nghost-%COMP%]     .mat-form-field-flex{display:inline-flex;align-items:baseline;box-sizing:border-box;width:100%}[_nghost-%COMP%]     .mat-form-field{display:unset !important}"] });
