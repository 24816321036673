import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { SessionStorageService } from "ngx-webstorage";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-webstorage";
export class LookingForHelpTopicsService {
    constructor(http, sessionStorage) {
        this.http = http;
        this.sessionStorage = sessionStorage;
        this.resourceUrl = SERVER_API_URL + 'api/looking-for-help-topics';
    }
    create(lookingForHelpTopics) {
        const copy = this.convertDateFromClient(lookingForHelpTopics);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(lookingForHelpTopics) {
        const copy = this.convertDateFromClient(lookingForHelpTopics);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(lookingForHelpTopics) {
        const copy = Object.assign({}, lookingForHelpTopics, {
            dateTime: lookingForHelpTopics.dateTime && lookingForHelpTopics.dateTime.isValid() ? lookingForHelpTopics.dateTime.toJSON() : undefined,
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.dateTime = res.body.dateTime ? moment(res.body.dateTime) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((lookingForHelpTopics) => {
                lookingForHelpTopics.dateTime = lookingForHelpTopics.dateTime ? moment(lookingForHelpTopics.dateTime) : undefined;
            });
        }
        return res;
    }
    saveHelpTopics(answers) {
        this.sessionStorage.store("helpTopics", answers);
    }
    submitUserLookingForHelpTopics(lookingForHelpTopicsSource) {
        const helpTopics = this.getSavedHelpTopics();
        if (helpTopics && helpTopics.length) {
            if (!lookingForHelpTopicsSource) {
                lookingForHelpTopicsSource = 'SRQ';
            }
            return this.create({
                topics: this.getSavedHelpTopics(),
                source: lookingForHelpTopicsSource
            })
                .pipe(map(() => {
                this.clearSavedHelpTopics();
                return true;
            }));
        }
        return of(false);
    }
    getSavedHelpTopics() {
        return this.sessionStorage.retrieve("helpTopics");
    }
    hasAnySavedHelpTopics() {
        const answers = this.getSavedHelpTopics();
        return answers != null;
    }
    clearSavedHelpTopics() {
        this.sessionStorage.clear("helpTopics");
    }
    getUserLastLookingForHelpTopics() {
        return this.http
            .get(`${this.resourceUrl}/last`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    getUserLookingForHelpTopics(userId) {
        return this.http
            .get(`${this.resourceUrl}/user/${userId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
}
LookingForHelpTopicsService.ɵfac = function LookingForHelpTopicsService_Factory(t) { return new (t || LookingForHelpTopicsService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionStorageService)); };
LookingForHelpTopicsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LookingForHelpTopicsService, factory: LookingForHelpTopicsService.ɵfac, providedIn: 'root' });
