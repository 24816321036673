import { JhiLanguageService } from '../../ng-jhipster/language';
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/language";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
function TipComponent_div_0_p_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "p", 13);
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("innerHTML", ctx_r1.tip.subtitle[ctx_r1.langKey] != null ? ctx_r1.tip.subtitle[ctx_r1.langKey] : ctx_r1.tip.subtitle["en"], i0.ɵɵsanitizeHtml);
} }
function TipComponent_div_0_li_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "span", 7);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const h_r3 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHTML", h_r3[ctx_r2.langKey] != null ? h_r3[ctx_r2.langKey] : h_r3["en"], i0.ɵɵsanitizeHtml);
} }
function TipComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "div", 3);
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵelementStart(4, "div", 5);
    i0.ɵɵelement(5, "img", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div");
    i0.ɵɵelement(7, "h3", 7);
    i0.ɵɵtemplate(8, TipComponent_div_0_p_8_Template, 1, 1, "p", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "div", 9);
    i0.ɵɵelement(10, "p", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "div", 10);
    i0.ɵɵelement(12, "p", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "ul");
    i0.ɵɵtemplate(14, TipComponent_div_0_li_14_Template, 2, 1, "li", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "div", 12);
    i0.ɵɵelement(16, "p", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("src", "../../../../content/images/" + ctx_r0.tip.category + ".png", i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("innerHTML", ctx_r0.tip.title[ctx_r0.langKey] != null ? ctx_r0.tip.title[ctx_r0.langKey] : ctx_r0.tip.title["en"], i0.ɵɵsanitizeHtml);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.tip.subtitle != null);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("innerHTML", ctx_r0.tip.header[ctx_r0.langKey] != null ? ctx_r0.tip.header[ctx_r0.langKey] : ctx_r0.tip.header["en"], i0.ɵɵsanitizeHtml);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("innerHTML", ctx_r0.tip.headlinesTitle[ctx_r0.langKey] != null ? ctx_r0.tip.headlinesTitle[ctx_r0.langKey] : ctx_r0.tip.headlinesTitle["en"], i0.ɵɵsanitizeHtml);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r0.tip.headlines);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("innerHTML", ctx_r0.tip.ending[ctx_r0.langKey] != null ? ctx_r0.tip.ending[ctx_r0.langKey] : ctx_r0.tip.ending["en"], i0.ɵɵsanitizeHtml);
} }
export class TipComponent {
    constructor(languageService, translateService) {
        this.languageService = languageService;
        this.translateService = translateService;
    }
    ngOnInit() {
        console.log(this.tip);
        this.langKey = this.languageService.getCurrentLanguage();
        this.translateService.onLangChange.subscribe(() => {
            this.langKey = this.languageService.getCurrentLanguage();
        });
    }
}
TipComponent.ɵfac = function TipComponent_Factory(t) { return new (t || TipComponent)(i0.ɵɵdirectiveInject(i1.JhiLanguageService), i0.ɵɵdirectiveInject(i2.TranslateService)); };
TipComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TipComponent, selectors: [["tip-component"]], inputs: { tip: "tip" }, decls: 1, vars: 1, consts: [["class", "olha-theme", 4, "ngIf"], [1, "olha-theme"], [2, "height", "100%!important"], [1, "container", "bg-white"], [1, "header-component", "d-flex", "justify-content-start", "align-items-center"], [1, "image-component"], ["alt", "inuka-forest.svg", "width", "100%", 3, "src"], [3, "innerHTML"], ["class", "subtitle", 3, "innerHTML", 4, "ngIf"], [1, "title-component"], [1, "headlines-title-component"], [4, "ngFor", "ngForOf"], [1, "ending-component"], [1, "subtitle", 3, "innerHTML"]], template: function TipComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, TipComponent_div_0_Template, 17, 7, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.tip != null);
    } }, directives: [i3.NgIf, i3.NgForOf], styles: [".container[_ngcontent-%COMP%]{border-radius:20px !important;padding:0 !important}.header-component[_ngcontent-%COMP%]{padding-bottom:10px !important}.image-component[_ngcontent-%COMP%]{padding-left:25px;padding-right:15px}.title-component[_ngcontent-%COMP%]{padding-left:25px}.headlines-title-component[_ngcontent-%COMP%]{padding-left:25px;color:#44a38b;font-size:16px;font-weight:600}.subtitle[_ngcontent-%COMP%]{color:#8a9d98 !important}ul[_ngcontent-%COMP%]{list-style:none;padding-inline-start:20px !important}ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]:before{content:\"\u2713\";font-weight:600;font-size:16px;color:#44a38b;padding-right:10px;position:absolute;left:0;top:-2px}li[_ngcontent-%COMP%]{position:relative;padding-left:1.5em;padding-bottom:10px}.ending-component[_ngcontent-%COMP%]{padding-left:25px}"] });
