import { ElementRef, EventEmitter } from "@angular/core";
import { fromEvent, merge, of, timer } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import * as i0 from "@angular/core";
export class LongPressDirective {
    constructor(elementRef) {
        this.elementRef = elementRef;
        this.threshold = 500;
        this.mouseLongPress = new EventEmitter();
        const mousedown = fromEvent(elementRef.nativeElement, "mousedown").pipe(filter(event => event.button === 0), // Only allow left button (Primary button)
        map((event) => {
            this.downEvent = event;
            return true;
        }) // turn on threshold counter
        );
        const touchstart = fromEvent(elementRef.nativeElement, 'touchstart').pipe(map((event) => {
            this.downEvent = event;
            return true;
        }));
        const touchEnd = fromEvent(elementRef.nativeElement, 'touchend').pipe(map((event) => {
            return false;
        }));
        const touchCancel = fromEvent(elementRef.nativeElement, 'touchcancel').pipe(map((event) => {
            return false;
        }));
        const mouseup = fromEvent(window, "mouseup").pipe(filter(event => event.button === 0), // Only allow left button (Primary button)
        map((value) => {
            return false;
        }) // reset threshold counter
        );
        this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd, touchCancel)
            .pipe(switchMap(state => state ? timer(this.threshold, 100) : of(null)))
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
            var _a;
            if (value) {
                (_a = this.downEvent) === null || _a === void 0 ? void 0 : _a.preventDefault();
                this.mouseLongPress.emit();
            }
        });
    }
    ngOnDestroy() {
        if (this.eventSubscribe) {
            this.eventSubscribe.unsubscribe();
        }
    }
}
LongPressDirective.ɵfac = function LongPressDirective_Factory(t) { return new (t || LongPressDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
LongPressDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: LongPressDirective, selectors: [["", "longPress", ""]], outputs: { mouseLongPress: "mouseLongPress" } });
