import * as i0 from "@angular/core";
import * as i1 from "../../language/jhi-translate.directive";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../pipe/keys.pipe";
function JhiJvmMemoryComponent_div_2_div_1_span_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "number");
    i0.ɵɵpipe(5, "number");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const entry_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.key);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2(" (", i0.ɵɵpipeBind2(4, 3, entry_r2.value.used / 1048576, "1.0-0"), "M / ", i0.ɵɵpipeBind2(5, 6, entry_r2.value.max / 1048576, "1.0-0"), "M) ");
} }
function JhiJvmMemoryComponent_div_2_div_1_ng_template_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "number");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const entry_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.key);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(4, 2, entry_r2.value.used / 1048576, "1.0-0"), "M");
} }
function JhiJvmMemoryComponent_div_2_div_1_ngb_progressbar_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ngb-progressbar", 6);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const entry_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("value", 100 * entry_r2.value.used / entry_r2.value.max)("striped", true)("animated", false);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(3, 4, entry_r2.value.used * 100 / entry_r2.value.max, "1.0-0"), "%");
} }
function JhiJvmMemoryComponent_div_2_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, JhiJvmMemoryComponent_div_2_div_1_span_1_Template, 6, 9, "span", 3);
    i0.ɵɵelementStart(2, "div");
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, JhiJvmMemoryComponent_div_2_div_1_ng_template_5_Template, 5, 5, "ng-template", null, 4, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵtemplate(7, JhiJvmMemoryComponent_div_2_div_1_ngb_progressbar_7_Template, 4, 7, "ngb-progressbar", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const entry_r2 = ctx.$implicit;
    const _r4 = i0.ɵɵreference(6);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", entry_r2.value.max != -1)("ngIfElse", _r4);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("Committed : ", i0.ɵɵpipeBind2(4, 4, entry_r2.value.committed / 1048576, "1.0-0"), "M");
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", entry_r2.value.max != -1);
} }
function JhiJvmMemoryComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, JhiJvmMemoryComponent_div_2_div_1_Template, 8, 7, "div", 2);
    i0.ɵɵpipe(2, "keys");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(2, 1, ctx_r0.jvmMemoryMetrics));
} }
export class JhiJvmMemoryComponent {
}
JhiJvmMemoryComponent.ɵfac = function JhiJvmMemoryComponent_Factory(t) { return new (t || JhiJvmMemoryComponent)(); };
JhiJvmMemoryComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiJvmMemoryComponent, selectors: [["jhi-jvm-memory"]], inputs: { jvmMemoryMetrics: "jvmMemoryMetrics", updating: "updating" }, decls: 3, vars: 1, consts: [["jhiTranslate", "metrics.jvm.memory.title"], [4, "ngIf"], [4, "ngFor", "ngForOf"], [4, "ngIf", "ngIfElse"], ["other", ""], ["type", "success", 3, "value", "striped", "animated", 4, "ngIf"], ["type", "success", 3, "value", "striped", "animated"]], template: function JhiJvmMemoryComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "h4", 0);
        i0.ɵɵtext(1, "Memory");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, JhiJvmMemoryComponent_div_2_Template, 3, 3, "div", 1);
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.updating);
    } }, directives: [i1.JhiTranslateDirective, i2.NgIf, i2.NgForOf, i3.NgbProgressbar], pipes: [i4.JhiKeysPipe, i2.DecimalPipe], encapsulation: 2 });
