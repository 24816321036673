import { JhiLanguageService } from '../../../ng-jhipster/language';
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "../../../ng-jhipster/language";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i4 from "@angular/router";
export class DoScanComponent {
    constructor(languageService, translateService) {
        this.languageService = languageService;
        this.translateService = translateService;
        this.existingLangKeys = ['en', 'nl'];
    }
    ngOnInit() {
        this.langKey = this.languageService.getCurrentLanguage();
        this.translateService.onLangChange.subscribe(() => {
            this.langKey = this.languageService.getCurrentLanguage();
        });
    }
    getImageRoute() {
        if (this.existingLangKeys.includes(this.langKey)) {
            return '../../../../../../content/images/inuka/blob-with-categories-' + this.langKey + '.png';
        }
        else {
            return '../../../../../../content/images/inuka/blob-with-categories-en.png';
        }
    }
}
DoScanComponent.ɵfac = function DoScanComponent_Factory(t) { return new (t || DoScanComponent)(i0.ɵɵdirectiveInject(i1.JhiLanguageService), i0.ɵɵdirectiveInject(i2.TranslateService)); };
DoScanComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: DoScanComponent, selectors: [["do-scan"]], decls: 16, vars: 2, consts: [[1, "olha-theme"], [1, "container", "bg-white"], [1, "d-flex", "justify-content-between"], [1, "text"], ["jhiTranslate", "inukaNgApp.indexUser.doScan.header", 1, "header"], [1, "d-flex", "justify-content-center", "d-md-none"], ["alt", "blob-with-categories.png", 1, "blob-image", 3, "src"], [1, "mt-2"], ["jhiTranslate", "inukaNgApp.indexUser.doScan.discover"], ["jhiTranslate", "inukaNgApp.indexUser.doScan.proven"], [1, "mt-3", "h5"], [1, "d-flex", "justify-content-center"], ["routerLink", "/wellbeing-start", "jhiTranslate", "inukaNgApp.indexUser.doScan.startSelfScan", 1, "btn", "btn-primary", "ended-reschedule"], [1, "d-none", "d-md-block", "d-lg-block", "d-xl-block", "blob-div"]], template: function DoScanComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelement(4, "h3", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelement(6, "img", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 7);
        i0.ɵɵelement(8, "span", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 7);
        i0.ɵɵelement(10, "span", 9);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "div", 10);
        i0.ɵɵelementStart(12, "div", 11);
        i0.ɵɵelement(13, "button", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(14, "div", 13);
        i0.ɵɵelement(15, "img", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("src", ctx.getImageRoute(), i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(9);
        i0.ɵɵproperty("src", ctx.getImageRoute(), i0.ɵɵsanitizeUrl);
    } }, directives: [i3.JhiTranslateDirective, i4.RouterLink], styles: [".container[_ngcontent-%COMP%]{padding:30px !important;border-radius:20px !important}.blob-image[_ngcontent-%COMP%]{width:133px !important;height:auto !important}.text[_ngcontent-%COMP%]{margin-right:20px !important;text-align:center !important;font-family:\"Open Sans\",sans-serif !important;font-size:16px !important}.header[_ngcontent-%COMP%]{padding:0 0 9px 0 !important;text-align:center !important}@media(min-width: 768px){.header[_ngcontent-%COMP%]{padding:0 30px 10px 0 !important;text-align:left !important}.text[_ngcontent-%COMP%]{text-align:left !important}.blob-image[_ngcontent-%COMP%]{width:140% !important;height:auto !important}.blob-div[_ngcontent-%COMP%]{width:70% !important}}@media(min-width: 992px){.blob-div[_ngcontent-%COMP%]{width:100% !important}}@media(min-width: 1200px){.blob-div[_ngcontent-%COMP%]{width:70% !important}}"] });
