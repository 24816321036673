import { ElementRef } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { PasswordResetInitService } from '../../account/password-reset/init/password-reset-init.service';
import { AccountService } from '../../core/auth/account.service';
import { LoginService } from '../../core/login/login.service';
import { LeaveNumberModalComponent } from '../login/leave-number-modal.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JhiEventManager } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../account/password-reset/init/password-reset-init.service";
import * as i2 from "@angular/forms";
import * as i3 from "../../core/login/login.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../core/auth/account.service";
import * as i6 from "../../ng-jhipster/service";
import * as i7 from "../../shared/unauth-header/unauth-header.component";
import * as i8 from "@angular/common";
import * as i9 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i10 from "@angular/material/form-field";
import * as i11 from "@angular/material/input";
import * as i12 from "../../shared/track-user-activity.directive";
import * as i13 from "@angular/router";
import * as i14 from "@ngx-translate/core";
const _c0 = ["email"];
const _c1 = function () { return { "track": "click", name: "reset_password_btn_clicked" }; };
function ForgotPasswordComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelement(1, "h3", 7);
    i0.ɵɵelementStart(2, "form", 8);
    i0.ɵɵlistener("ngSubmit", function ForgotPasswordComponent_div_6_Template_form_ngSubmit_2_listener() { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.requestReset(); });
    i0.ɵɵelementStart(3, "div", 9);
    i0.ɵɵelementStart(4, "mat-form-field", 10);
    i0.ɵɵelementStart(5, "mat-label");
    i0.ɵɵtext(6);
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(8, "input", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "div", 6);
    i0.ɵɵelement(10, "button", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "div", 13);
    i0.ɵɵelement(12, "a", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("formGroup", ctx_r0.resetRequestForm);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(7, 3, "login.form.emailAddress"));
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("trackUserActivity", i0.ɵɵpureFunction0(5, _c1));
} }
function ForgotPasswordComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelement(1, "h3", 15);
    i0.ɵɵelement(2, "p", 16);
    i0.ɵɵelement(3, "p", 17);
    i0.ɵɵelementEnd();
} }
function ForgotPasswordComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelement(1, "h3", 18);
    i0.ɵɵelement(2, "p", 19);
    i0.ɵɵelementStart(3, "p", 20);
    i0.ɵɵlistener("click", function ForgotPasswordComponent_div_8_Template_p_click_3_listener() { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.passwordReset = null; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
export class ForgotPasswordComponent {
    constructor(passwordResetInitService, formBuilder, loginService, modalService, accountService, eventManager) {
        this.passwordResetInitService = passwordResetInitService;
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.modalService = modalService;
        this.accountService = accountService;
        this.eventManager = eventManager;
        this.resetRequestForm = this.formBuilder.group({ email: ['', [Validators.email]] });
        this.modalOptions = { windowClass: 'modal-window', size: 'md', centered: true };
    }
    ngOnInit() {
        if (this.accountService.isAuthenticated()) {
            this.loginService.redirectToHomepage();
        }
        this.errorSubscription = this.eventManager.subscribe('inukaNgApp.httpError', (errorResponse) => {
            if (errorResponse.content.error) {
                let response = errorResponse.content.error.detail;
                if (response === 'Reset password attempt limit is exceeded') {
                    this.openModal('reset.request.messages.attemptLimit');
                }
                else {
                    this.openModal('reset.request.messages.unspecifiedError');
                }
                return;
            }
            else {
                console.log("No errors");
                return;
            }
        });
    }
    openModal(message) {
        const modalRef = this.modalService.open(LeaveNumberModalComponent, this.modalOptions);
        modalRef.componentInstance.leaveNumberModalRef = modalRef;
        modalRef.componentInstance.displayNLHelpHeader = false;
        modalRef.componentInstance.errorMessage = message;
    }
    ngAfterViewInit() { if (this.email) {
        this.email.nativeElement.focus();
    } }
    requestReset() {
        let email = this.resetRequestForm.get('email').value;
        if (!email || email.length === 0) {
            this.openModal('register.messages.error.invalidEmail');
            return;
        }
        this.passwordResetInitService.save(email).subscribe(success => {
            this.passwordReset = 'success';
        }, errorResponse => {
            if (errorResponse.error === null || errorResponse.error.detail !== 'Reset password attempt limit is exceeded') {
                this.passwordReset = 'fail';
            }
        });
    }
    ngOnDestroy() {
        if (this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
    }
}
ForgotPasswordComponent.ɵfac = function ForgotPasswordComponent_Factory(t) { return new (t || ForgotPasswordComponent)(i0.ɵɵdirectiveInject(i1.PasswordResetInitService), i0.ɵɵdirectiveInject(i2.FormBuilder), i0.ɵɵdirectiveInject(i3.LoginService), i0.ɵɵdirectiveInject(i4.NgbModal), i0.ɵɵdirectiveInject(i5.AccountService), i0.ɵɵdirectiveInject(i6.JhiEventManager)); };
ForgotPasswordComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ForgotPasswordComponent, selectors: [["jhi-forgot-password"]], viewQuery: function ForgotPasswordComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.email = _t.first);
    } }, decls: 9, vars: 3, consts: [[1, "olha-theme"], [1, "container", "bg-2"], [1, "text-center", "bg-2", "form-div"], [1, "d-none", "d-md-block", "d-lg-block", "d-xl-block", "text-center"], ["src", "../../../../content/images/inuka-logo-top.svg", "alt", "inuka-logo-top", "width", "70"], ["class", "text-center", 4, "ngIf"], [1, "text-center"], ["jhiTranslate", "reset.request.header.enterEmail"], ["name", "form", "role", "form", 3, "formGroup", "ngSubmit"], [1, "d-flex", "flex-column", "form-inside"], ["appearance", "outline", 1, ""], ["matInput", "", "name", "email", "id", "email", "formControlName", "email"], ["type", "submit", "jhiTranslate", "reset.request.button.reset", 1, "btn", "btn-primary", "reset-password-button", "mt-4", 3, "trackUserActivity"], [1, "log-in"], ["routerLink", "/login", "jhiTranslate", "reset.request.header.login", 1, "font-weight-bold"], ["jhiTranslate", "reset.request.header.clickLink"], ["jhiTranslate", "reset.request.paragraph.resetLinkSent"], ["jhiTranslate", "reset.request.paragraph.checkSpam"], ["jhiTranslate", "reset.request.header.noEmail"], ["jhiTranslate", "reset.request.paragraph.differentEmail"], ["jhiTranslate", "reset.request.paragraph.tryAnother", 1, "different-email", 3, "click"]], template: function ForgotPasswordComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "unauth-header");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵelementStart(4, "div", 3);
        i0.ɵɵelement(5, "img", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, ForgotPasswordComponent_div_6_Template, 13, 6, "div", 5);
        i0.ɵɵtemplate(7, ForgotPasswordComponent_div_7_Template, 4, 0, "div", 5);
        i0.ɵɵtemplate(8, ForgotPasswordComponent_div_8_Template, 4, 0, "div", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("ngIf", !ctx.passwordReset);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.passwordReset === "success");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.passwordReset === "fail");
    } }, directives: [i7.UnauthHeaderComponent, i8.NgIf, i9.JhiTranslateDirective, i2.ɵNgNoValidate, i2.NgControlStatusGroup, i2.FormGroupDirective, i10.MatFormField, i10.MatLabel, i11.MatInput, i2.DefaultValueAccessor, i2.NgControlStatus, i2.FormControlName, i12.TrackUserActivityDirective, i13.RouterLinkWithHref], pipes: [i14.TranslatePipe], styles: [".container[_ngcontent-%COMP%]{height:auto !important;max-width:500px !important;max-height:570px !important;margin:70px 20px !important;padding-top:0 !important;padding-bottom:0 !important}h3[_ngcontent-%COMP%], form[_ngcontent-%COMP%]{margin-top:30px}.form-div[_ngcontent-%COMP%]{padding:20px !important}.reset-password-button[_ngcontent-%COMP%]{width:180px;height:40px;margin-top:30px}.log-in[_ngcontent-%COMP%], .different-email[_ngcontent-%COMP%]{color:#0a4a3a;font-weight:600;margin-top:30px;text-decoration:underline}.different-email[_ngcontent-%COMP%]{cursor:pointer}@media(min-width: 576px){.container[_ngcontent-%COMP%]{margin:70px auto !important}}@media(min-width: 768px){.form-div[_ngcontent-%COMP%]{padding:48px !important}.reset-password-button[_ngcontent-%COMP%]{width:250px;height:50px}}"] });
