import { OrganizationResourceService } from '../../admin/organization-resource/organization-resource.service';
import { JhiLanguageService } from '../../ng-jhipster/language';
import { JhiEventManager } from '../../ng-jhipster/service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SRQCompletedService } from '../srq-completed/srq-completed.service';
import { AccountService } from '../../core/auth/account.service';
import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/language";
import * as i2 from "../../admin/organization-resource/organization-resource.service";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../srq-completed/srq-completed.service";
import * as i5 from "../../ng-jhipster/service";
import * as i6 from "../../core/auth/account.service";
import * as i7 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "@angular/common";
import * as i9 from "../../shared/auto-direction-switch/auto-direction-switch/auto-direction-switch.component";
function OrganizationResourcesComponent_div_2_a_5_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 13);
    i0.ɵɵlistener("click", function OrganizationResourcesComponent_div_2_a_5_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r9); const or_r1 = i0.ɵɵnextContext().$implicit; const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.selectResource(or_r1.text); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 14);
    i0.ɵɵelement(2, "use", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function OrganizationResourcesComponent_div_2__svg_use_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "use", 16);
} }
function OrganizationResourcesComponent_div_2__svg_use_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "use", 17);
} }
function OrganizationResourcesComponent_div_2_div_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵelement(1, "auto-direction-switch", 19);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const or_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", or_r1.text, i0.ɵɵsanitizeHtml);
} }
function OrganizationResourcesComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵlistener("click", function OrganizationResourcesComponent_div_2_Template_div_click_2_listener() { const restoredCtx = i0.ɵɵrestoreView(_r12); const or_r1 = restoredCtx.$implicit; const ctx_r11 = i0.ɵɵnextContext(); return ctx_r11.selectCategory(or_r1.name); });
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵelement(4, "h4", 7);
    i0.ɵɵtemplate(5, OrganizationResourcesComponent_div_2_a_5_Template, 3, 0, "a", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(6, "svg", 9);
    i0.ɵɵtemplate(7, OrganizationResourcesComponent_div_2__svg_use_7_Template, 1, 0, "use", 10);
    i0.ɵɵtemplate(8, OrganizationResourcesComponent_div_2__svg_use_8_Template, 1, 0, "use", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(9, OrganizationResourcesComponent_div_2_div_9_Template, 2, 1, "div", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const or_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵstyleProp("color", ctx_r0.determineCategoryColor(or_r1.name));
    i0.ɵɵproperty("jhiTranslate", "wellBeingResult." + ctx_r0.determineCategoryHeader(or_r1.name));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.isPopup && ctx_r0.isCoach);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedCategory !== ctx_r0.determineCategoryHeader(or_r1.name));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedCategory === ctx_r0.determineCategoryHeader(or_r1.name));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedCategory === ctx_r0.determineCategoryHeader(or_r1.name));
} }
export class OrganizationResourcesComponent {
    constructor(languageService, organizationResourceService, modalService, srqService, eventManager, accountService) {
        this.languageService = languageService;
        this.organizationResourceService = organizationResourceService;
        this.modalService = modalService;
        this.srqService = srqService;
        this.eventManager = eventManager;
        this.accountService = accountService;
        this.langKey = this.languageService.getCurrentLanguage() ? this.languageService.getCurrentLanguage() : "en";
        this.isCoach = this.accountService.isCoach();
    }
    ngOnInit() {
        console.log(this.userId);
        this.organizationResourceService.getOrganizationResourcesByUserIdAndLangkey(this.userId, this.langKey).subscribe(res => {
            this.organizationResources = res.body || [];
        });
        this.srqService.getLastSrqResultOfCurrentUser(this.userId).subscribe((srq) => {
            this.lastTestScore = srq.body.score;
            if (this.lastTestScore <= 3) {
                this.selectedCategory = "tough place";
            }
            else if (this.lastTestScore >= 4 && this.lastTestScore <= 13) {
                this.selectedCategory = "at risk";
            }
            else if (this.lastTestScore >= 14) {
                this.selectedCategory = "resilient";
            }
        });
    }
    determineCategoryColor(category) {
        let c = this.determineCategoryHeader(category);
        if (c === "resilient")
            return "#44A38B";
        else if (c === "at risk")
            return "#E0CA58";
        else if (c === "tough place")
            return "#AF3B6E";
        else {
            console.log("Category not recognized");
            return "";
        }
    }
    determineCategoryHeader(category) {
        if (category.includes("resilient"))
            return "resilient";
        else if (category.includes("at") && category.includes("risk"))
            return "at risk";
        else if (category.includes("tough") && category.includes("place"))
            return "tough place";
        else {
            console.log("Category not recognized");
            return "";
        }
    }
    selectCategory(category) {
        let c = this.determineCategoryHeader(category);
        if (this.selectedCategory !== c) {
            this.selectedCategory = c;
        }
        else {
            this.selectedCategory = null;
        }
    }
    selectResource(text) {
        this.eventManager.broadcast({ name: "organizationResourceSelected", content: text });
        this.modalService.dismissAll();
    }
}
OrganizationResourcesComponent.ɵfac = function OrganizationResourcesComponent_Factory(t) { return new (t || OrganizationResourcesComponent)(i0.ɵɵdirectiveInject(i1.JhiLanguageService), i0.ɵɵdirectiveInject(i2.OrganizationResourceService), i0.ɵɵdirectiveInject(i3.NgbModal), i0.ɵɵdirectiveInject(i4.SRQCompletedService), i0.ɵɵdirectiveInject(i5.JhiEventManager), i0.ɵɵdirectiveInject(i6.AccountService)); };
OrganizationResourcesComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationResourcesComponent, selectors: [["organization-resources"]], inputs: { userId: "userId", isPopup: "isPopup" }, decls: 3, vars: 1, consts: [[1, "olha-theme", "p-4"], ["jhiTranslate", "inukaNgApp.wellBeingResult2.moreSupport", 1, "header-3"], ["class", "mb-3 cursor-pointer pr-2", 4, "ngFor", "ngForOf"], [1, "mb-3", "cursor-pointer", "pr-2"], [1, "position-relative", "px-1", "category-div", 2, "cursor", "pointer"], [1, "category-header", 3, "click"], [1, "d-flex", "justify-content-start", "header-4"], [3, "jhiTranslate"], ["class", "d-flex justify-content-end align-items-start mb-2 ml-2", 3, "click", 4, "ngIf"], [1, "angle-icon"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#angle-down", 4, "ngIf"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#angle-up", 4, "ngIf"], ["style", "overflow-wrap: anywhere;", 4, "ngIf"], [1, "d-flex", "justify-content-end", "align-items-start", "mb-2", "ml-2", 3, "click"], [2, "height", "18px", "width", "17px"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#share"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#angle-down"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#angle-up"], [2, "overflow-wrap", "anywhere"], [3, "innerHtml"]], template: function OrganizationResourcesComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "h3", 1);
        i0.ɵɵtemplate(2, OrganizationResourcesComponent_div_2_Template, 10, 7, "div", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.organizationResources);
    } }, directives: [i7.JhiTranslateDirective, i8.NgForOf, i8.NgIf, i9.AutoDirectionSwitchComponent], styles: [".angle-icon[_ngcontent-%COMP%]{position:absolute;top:5px !important;right:0 !important;height:12px;width:22px}.header-3[_ngcontent-%COMP%]{margin-bottom:50px}.header-4[_ngcontent-%COMP%]{margin-bottom:20px}.category-header[_ngcontent-%COMP%]{margin-bottom:15px}.category-div[_ngcontent-%COMP%]{margin-bottom:30px}"] });
