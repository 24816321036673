import { TranslateService } from '@ngx-translate/core';
import { JhiConfigService } from '../config.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../config.service";
export class JhiLanguageService {
    constructor(translateService, configService) {
        this.translateService = translateService;
        this.configService = configService;
        this.currentLang = 'en';
    }
    init() {
        const config = this.configService.getConfig();
        this.currentLang = config.defaultI18nLang;
        this.translateService.setDefaultLang(this.currentLang);
        this.translateService.use(this.currentLang);
    }
    changeLanguage(languageKey) {
        this.currentLang = languageKey;
        this.configService.CONFIG_OPTIONS.defaultI18nLang = languageKey;
        this.translateService.use(this.currentLang);
    }
    /**
     * @deprecated Will be removed when releasing generator-jhipster v7
     */
    getCurrent() {
        return Promise.resolve(this.currentLang);
    }
    getCurrentLanguage() {
        return this.currentLang;
    }
}
JhiLanguageService.ɵfac = function JhiLanguageService_Factory(t) { return new (t || JhiLanguageService)(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.JhiConfigService)); };
JhiLanguageService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: JhiLanguageService, factory: JhiLanguageService.ɵfac, providedIn: 'root' });
