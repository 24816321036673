import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ConversationnService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/conversationns';
    }
    create(conversationn) {
        const copy = this.convertDateFromClient(conversationn);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(conversationn) {
        return this.http.put(this.resourceUrl, conversationn, { observe: 'response' });
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    getConversationByUserId(id) {
        return this.http
            .get(`${this.resourceUrl}/user/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    getConversationByUserIdAndCoachId(userId, coachId) {
        return this.http
            .get(`${this.resourceUrl}/user/${userId}/coach/${coachId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    convertDateFromClient(conversationn) {
        const copy = Object.assign({}, conversationn, {
            createDate: conversationn.createDate && conversationn.createDate.isValid() ? conversationn.createDate.toJSON() : undefined
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.createDate = res.body.createDate ? moment(res.body.createDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((conversationn) => {
                conversationn.createDate = conversationn.createDate ? moment(conversationn.createDate) : undefined;
            });
        }
        return res;
    }
}
ConversationnService.ɵfac = function ConversationnService_Factory(t) { return new (t || ConversationnService)(i0.ɵɵinject(i1.HttpClient)); };
ConversationnService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ConversationnService, factory: ConversationnService.ɵfac, providedIn: 'root' });
