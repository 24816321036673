import { ElementRef } from '@angular/core';
import * as i0 from "@angular/core";
export class InputAutoDirectionSwitchDirective {
    constructor(inputElement) {
        this.inputElement = inputElement;
    }
    ngAfterContentChecked() {
        this.changeDirectionIfNeeded();
    }
    onKeyup() {
        this.changeDirectionIfNeeded();
    }
    changeDirectionIfNeeded() {
        let text;
        if (this.inputElement.nativeElement.innerText) {
            text = this.inputElement.nativeElement.innerText;
        }
        else if (this.inputElement.nativeElement.value) {
            text = this.inputElement.nativeElement.value;
        }
        if (text) {
            if (this.isContainRightToLeftChar(text)) {
                this.setDirectionToRtl();
            }
            else {
                this.setDirectionToLtr();
            }
        }
    }
    isContainRightToLeftChar(text) {
        return InputAutoDirectionSwitchDirective.rtlLanguagesRegexp.test(text);
    }
    setDirectionToRtl() {
        this.inputElement.nativeElement.style.direction = 'rtl';
        this.inputElement.nativeElement.style.textAlign = 'right';
    }
    setDirectionToLtr() {
        this.inputElement.nativeElement.style.direction = 'ltr';
        this.inputElement.nativeElement.style.textAlign = 'left';
    }
}
InputAutoDirectionSwitchDirective.rtlLanguagesRegexp = /[\u0600-\u06FF]+/;
InputAutoDirectionSwitchDirective.ɵfac = function InputAutoDirectionSwitchDirective_Factory(t) { return new (t || InputAutoDirectionSwitchDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
InputAutoDirectionSwitchDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: InputAutoDirectionSwitchDirective, selectors: [["", "inputAutoDirectionSwitch", ""]], hostBindings: function InputAutoDirectionSwitchDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("keyup", function InputAutoDirectionSwitchDirective_keyup_HostBindingHandler() { return ctx.onKeyup(); });
    } } });
