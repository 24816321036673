import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OfflineMessageService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/offline-messages';
    }
    create(offlineMessage) {
        const copy = this.convertDateFromClient(offlineMessage);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(offlineMessage) {
        const copy = this.convertDateFromClient(offlineMessage);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    getConversationsOfCurrentUser() {
        return this.http
            .get(SERVER_API_URL + `api/conversations/current-user-conversations`)
            .pipe(map((res) => this.convertDateArrayFromServerForConversation(res)));
    }
    convertDateFromClient(offlineMessage) {
        const copy = Object.assign({}, offlineMessage, {
            createDate: offlineMessage.createDate && offlineMessage.createDate.isValid() ? offlineMessage.createDate.toJSON() : undefined
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.createDate = res.body.createDate ? moment(res.body.createDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((offlineMessage) => {
                offlineMessage.createDate = offlineMessage.createDate ? moment(offlineMessage.createDate) : undefined;
            });
        }
        return res;
    }
    convertDateArrayFromServerForConversation(res) {
        if (res) {
            res.forEach((rating) => {
                let m = rating.lastMessage;
                if (m === null)
                    return;
                m.createDate = m.createDate ? moment(m.createDate) : undefined;
            });
        }
        return res;
    }
    unreadMessageCount() {
        return this.http.get(`${this.resourceUrl}/current-user/unread-message-count`, { observe: 'response' });
    }
}
OfflineMessageService.ɵfac = function OfflineMessageService_Factory(t) { return new (t || OfflineMessageService)(i0.ɵɵinject(i1.HttpClient)); };
OfflineMessageService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OfflineMessageService, factory: OfflineMessageService.ɵfac, providedIn: 'root' });
