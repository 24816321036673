import * as i0 from "@angular/core";
import * as i1 from "../../language/jhi-translate.directive";
import * as i2 from "@angular/common";
import * as i3 from "../../pipe/keys.pipe";
function JhiMetricsCacheComponent_div_2_tr_23_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "tr");
    i0.ɵɵelementStart(1, "td");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "td", 14);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "td", 14);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "td", 14);
    i0.ɵɵtext(8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "td", 14);
    i0.ɵɵtext(10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "td", 14);
    i0.ɵɵtext(12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "td", 14);
    i0.ɵɵtext(14);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "td", 14);
    i0.ɵɵtext(16);
    i0.ɵɵpipe(17, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(18, "td", 14);
    i0.ɵɵtext(19);
    i0.ɵɵpipe(20, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const entry_r2 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.key);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.value["cache.gets.hit"]);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.value["cache.gets.miss"]);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.value["cache.gets.hit"] + entry_r2.value["cache.gets.miss"]);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.value["cache.puts"]);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.value["cache.removals"]);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r2.value["cache.evictions"]);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(17, 9, ctx_r1.filterNaN(100 * entry_r2.value["cache.gets.hit"] / (entry_r2.value["cache.gets.hit"] + entry_r2.value["cache.gets.miss"])), "1.0-4"), " ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(20, 12, ctx_r1.filterNaN(100 * entry_r2.value["cache.gets.miss"] / (entry_r2.value["cache.gets.hit"] + entry_r2.value["cache.gets.miss"])), "1.0-4"), " ");
} }
function JhiMetricsCacheComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "table", 3);
    i0.ɵɵelementStart(2, "thead");
    i0.ɵɵelementStart(3, "tr");
    i0.ɵɵelementStart(4, "th", 4);
    i0.ɵɵtext(5, "Cache name");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "th", 5);
    i0.ɵɵtext(7, "Cache Hits");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "th", 6);
    i0.ɵɵtext(9, "Cache Misses");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "th", 7);
    i0.ɵɵtext(11, "Cache Gets");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "th", 8);
    i0.ɵɵtext(13, "Cache Puts");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "th", 9);
    i0.ɵɵtext(15, "Cache Removals");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "th", 10);
    i0.ɵɵtext(17, "Cache Evictions");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(18, "th", 11);
    i0.ɵɵtext(19, "Cache Hit %");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "th", 12);
    i0.ɵɵtext(21, "Cache Miss %");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(22, "tbody");
    i0.ɵɵtemplate(23, JhiMetricsCacheComponent_div_2_tr_23_Template, 21, 15, "tr", 13);
    i0.ɵɵpipe(24, "keys");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(23);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(24, 1, ctx_r0.cacheMetrics));
} }
export class JhiMetricsCacheComponent {
    filterNaN(input) {
        if (isNaN(input)) {
            return 0;
        }
        return input;
    }
}
JhiMetricsCacheComponent.ɵfac = function JhiMetricsCacheComponent_Factory(t) { return new (t || JhiMetricsCacheComponent)(); };
JhiMetricsCacheComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiMetricsCacheComponent, selectors: [["jhi-metrics-cache"]], inputs: { cacheMetrics: "cacheMetrics", updating: "updating" }, decls: 3, vars: 1, consts: [["jhiTranslate", "metrics.cache.title"], ["class", "table-responsive", 4, "ngIf"], [1, "table-responsive"], [1, "table", "table-striped"], ["jhiTranslate", "metrics.cache.cachename"], ["data-translate", "metrics.cache.hits", 1, "text-right"], ["data-translate", "metrics.cache.misses", 1, "text-right"], ["data-translate", "metrics.cache.gets", 1, "text-right"], ["data-translate", "metrics.cache.puts", 1, "text-right"], ["data-translate", "metrics.cache.removals", 1, "text-right"], ["data-translate", "metrics.cache.evictions", 1, "text-right"], ["data-translate", "metrics.cache.hitPercent", 1, "text-right"], ["data-translate", "metrics.cache.missPercent", 1, "text-right"], [4, "ngFor", "ngForOf"], [1, "text-right"]], template: function JhiMetricsCacheComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "h3", 0);
        i0.ɵɵtext(1, "Cache statistics");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, JhiMetricsCacheComponent_div_2_Template, 25, 3, "div", 1);
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.updating);
    } }, directives: [i1.JhiTranslateDirective, i2.NgIf, i2.NgForOf], pipes: [i3.JhiKeysPipe, i2.DecimalPipe], encapsulation: 2 });
