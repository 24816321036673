export class TrackerActivity {
    constructor(name, page, idKey, time, properties, sessionId, userLogin, ipAddress) {
        this.name = name;
        this.page = page;
        this.idKey = idKey;
        this.time = time;
        this.properties = properties;
        this.sessionId = sessionId;
        this.userLogin = userLogin;
        this.ipAddress = ipAddress;
    }
}
