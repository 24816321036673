import { AccountService } from '../auth/account.service';
import { LocalStorageService } from "ngx-webstorage";
import { UUID } from "angular2-uuid";
import { ActivatedRoute } from "@angular/router";
import { TrackerActivity } from '../tracker/tracker-activity.model';
import { fromEvent } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "../auth/account.service";
import * as i2 from "ngx-webstorage";
import * as i3 from "@angular/router";
export class TrackerActivityFactoryService {
    constructor(accountService, localStorage, activatedRoute) {
        this.accountService = accountService;
        this.localStorage = localStorage;
        this.activatedRoute = activatedRoute;
        this.endsWithNumberRegex = new RegExp(/\/\d+$/);
        // Since we enable Statistics cookies in Cookiebot consent by default, the default value for this variable could be true
        this.isAllowedToStorePreAuthorizationKey = true;
        this.accountService.getAuthenticationState().subscribe((account) => {
            this.isAuthenticated = !!account;
        });
        this.activatedRoute.queryParams.subscribe((params) => {
            let newOrganizationCode = null;
            if (params['organizationCode']) {
                newOrganizationCode = params['organizationCode'];
            }
            if (newOrganizationCode !== this.organizationCode) {
                this.organizationCode = newOrganizationCode;
            }
            else {
                // clear the organization code to make sure we don't send it along with any other event
                this.organizationCode = null;
            }
        });
        fromEvent(window, 'CookiebotOnConsentReady').subscribe(e => {
            if (window['Cookiebot'] && window['Cookiebot'].consent) {
                this.isAllowedToStorePreAuthorizationKey = window['Cookiebot'].consent.statistics;
                if (this.isAllowedToStorePreAuthorizationKey) {
                    this.saveIdKey();
                }
                else {
                    this.deleteIdKey();
                }
            }
        });
    }
    create(name, page, properties) {
        let idKey = null;
        if (!this.isAuthenticated) {
            idKey = this.getIdKey();
        }
        page = this.formatPageProperty(page);
        if (page && this.organizationCode) {
            if (!properties) {
                properties = {};
            }
            properties['organization_code'] = this.organizationCode;
        }
        return new TrackerActivity(name, page, idKey, (Date.now() / 1000).toString(), properties);
    }
    getIdKey() {
        if (!this.idKey) {
            let previousIdKey = this.loadIdKey();
            if (previousIdKey) {
                this.idKey = previousIdKey;
            }
            else {
                this.idKey = TrackerActivityFactoryService.generateIdKey();
                this.saveIdKey();
            }
        }
        return this.idKey;
    }
    loadIdKey() {
        return this.localStorage.retrieve(TrackerActivityFactoryService.PreAuthorizationIdKeyBrowserStorageName);
    }
    saveIdKey() {
        if (this.idKey && this.isAllowedToStorePreAuthorizationKey) {
            this.localStorage.store(TrackerActivityFactoryService.PreAuthorizationIdKeyBrowserStorageName, this.idKey);
            return true;
        }
        return false;
    }
    deleteIdKey() {
        if (!this.isAllowedToStorePreAuthorizationKey) {
            this.localStorage.clear(TrackerActivityFactoryService.PreAuthorizationIdKeyBrowserStorageName);
        }
    }
    static generateIdKey() {
        return UUID.UUID();
    }
    formatPageProperty(page) {
        if (page) {
            // remove the numeric ids from path variable, /session/chat/1234 => /session/chat, /wellbeing-scan/7 => /wellbeing-scan
            page = page.replace(this.endsWithNumberRegex, '');
        }
        return page;
    }
}
TrackerActivityFactoryService.PreAuthorizationIdKeyBrowserStorageName = 'pre_register_idkey';
TrackerActivityFactoryService.ɵfac = function TrackerActivityFactoryService_Factory(t) { return new (t || TrackerActivityFactoryService)(i0.ɵɵinject(i1.AccountService), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.ActivatedRoute)); };
TrackerActivityFactoryService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TrackerActivityFactoryService, factory: TrackerActivityFactoryService.ɵfac, providedIn: 'root' });
