import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ScriptService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/scripts';
    }
    create(script) {
        return this.http.post(this.resourceUrl, script, { observe: 'response' });
    }
    update(script) {
        return this.http.put(this.resourceUrl, script, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    findAll() {
        return this.http.get(`${this.resourceUrl}-all`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    findByLangKey(langKey) {
        return this.http.get(`${this.resourceUrl}-by-lang?langKey=${langKey}`, { observe: 'response' });
    }
    getScriptChaptersByLangKey(langKey) {
        return this.http.get(`${this.resourceUrl}/chapters?langKey=${langKey}`);
    }
    getScriptCategoriesByLangKey(langKey) {
        return this.http.get(`${this.resourceUrl}/categories?langKey=${langKey}`);
    }
}
ScriptService.ɵfac = function ScriptService_Factory(t) { return new (t || ScriptService)(i0.ɵɵinject(i1.HttpClient)); };
ScriptService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ScriptService, factory: ScriptService.ɵfac, providedIn: 'root' });
