// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application
export const VERSION = process.env.VERSION;
export const ENV = process.env.NODE_ENV;
export const DEBUG_INFO_ENABLED = Boolean(process.env.DEBUG_INFO_ENABLED);
export const SERVER_API_URL = process.env.SERVER_API_URL;
export const CUSTOMER_CARE_EMAIL_FOR_COACH = process.env.CUSTOMER_CARE_EMAIL_FOR_COACH;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;
export const CELLULANT_CHECKOUT_URL = process.env.CELLULANT_CHECKOUT_URL;
export const ADYEN_DROPIN_URL = process.env.ADYEN_DROPIN_URL;
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_TRACING_ORIGIN = process.env.SENTRY_TRACING_ORIGIN;
export const S3BUCKET_BASE_URL = process.env.S3BUCKET_BASE_URL;
export const COUNTRIES = [
    'Afghanistan',
    'Åland',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bonaire, Sint Eustatius, and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos [Keeling] Islands',
    'Colombia',
    'Comoros',
    'Congo Republic',
    'Cook Islands',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czechia',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'DR Congo',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Falkland Islands',
    'Faroe Islands',
    'Federated States of Micronesia',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Hashemite Kingdom of Jordan',
    'Heard Island and McDonald Islands',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jersey',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Luxembourg',
    'Macao',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'North Korea',
    'North Macedonia',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn Islands',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Republic of Lithuania',
    'Republic of Moldova',
    'Réunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Barthélemy',
    'Saint Helena',
    'Saint Lucia',
    'Saint Martin',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'São Tomé and Príncipe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'St Kitts and Nevis',
    'Sudan',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'U.S. Minor Outlying Islands',
    'U.S. Virgin Islands',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe'
];
export const TIMEZONES = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmara',
    'Africa/Asmera',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Timbuktu',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/ComodRivadavia',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Aruba',
    'America/Asuncion',
    'America/Atikokan',
    'America/Atka',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Buenos_Aires',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Catamarca',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Coral_Harbour',
    'America/Cordoba',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Ensenada',
    'America/Fort_Nelson',
    'America/Fort_Wayne',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Jujuy',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Knox_IN',
    'America/Kralendijk',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Lower_Princes',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Marigot',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Mendoza',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Montreal',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Acre',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Rosario',
    'America/Santa_Isabel',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Shiprock',
    'America/Sitka',
    'America/St_Barthelemy',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Virgin',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/McMurdo',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/South_Pole',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Arctic/Longyearbyen',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Ashkhabad',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Calcutta',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Chongqing',
    'Asia/Chungking',
    'Asia/Colombo',
    'Asia/Dacca',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Gaza',
    'Asia/Harbin',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Istanbul',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kashgar',
    'Asia/Kathmandu',
    'Asia/Katmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macao',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qyzylorda',
    'Asia/Rangoon',
    'Asia/Riyadh',
    'Asia/Saigon',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Tel_Aviv',
    'Asia/Thimbu',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ujung_Pandang',
    'Asia/Ulaanbaatar',
    'Asia/Ulan_Bator',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faeroe',
    'Atlantic/Faroe',
    'Atlantic/Jan_Mayen',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Canberra',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/LHI',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/NSW',
    'Australia/North',
    'Australia/Perth',
    'Australia/Queensland',
    'Australia/South',
    'Australia/Sydney',
    'Australia/Tasmania',
    'Australia/Victoria',
    'Australia/West',
    'Australia/Yancowinna',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belfast',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Busingen',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Guernsey',
    'Europe/Helsinki',
    'Europe/Isle_of_Man',
    'Europe/Istanbul',
    'Europe/Jersey',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Mariehamn',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Nicosia',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Tiraspol',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Johnston',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Ponape',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Samoa',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Truk',
    'Pacific/Wake',
    'Pacific/Wallis',
    'Pacific/Yap',
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Pacific-New',
    'US/Samoa'
];
export const COUNTRY_CALLING_CODES_AND_FLAGS = [
    {
        "name": "Afghanistan",
        "dialCode": "+93",
        "isoCode": "AF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/af.svg"
    },
    {
        "name": "Aland Islands",
        "dialCode": "+358",
        "isoCode": "AX",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ax.svg"
    },
    {
        "name": "Albania",
        "dialCode": "+355",
        "isoCode": "AL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/al.svg"
    },
    {
        "name": "Algeria",
        "dialCode": "+213",
        "isoCode": "DZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dz.svg"
    },
    {
        "name": "American Samoa",
        "dialCode": "+1684",
        "isoCode": "AS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/as.svg"
    },
    {
        "name": "Andorra",
        "dialCode": "+376",
        "isoCode": "AD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ad.svg"
    },
    {
        "name": "Angola",
        "dialCode": "+244",
        "isoCode": "AO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ao.svg"
    },
    {
        "name": "Anguilla",
        "dialCode": "+1264",
        "isoCode": "AI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ai.svg"
    },
    {
        "name": "Antarctica",
        "dialCode": "+672",
        "isoCode": "AQ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/aq.svg"
    },
    {
        "name": "Antigua and Barbuda",
        "dialCode": "+1268",
        "isoCode": "AG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ag.svg"
    },
    {
        "name": "Argentina",
        "dialCode": "+54",
        "isoCode": "AR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ar.svg"
    },
    {
        "name": "Armenia",
        "dialCode": "+374",
        "isoCode": "AM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/am.svg"
    },
    {
        "name": "Aruba",
        "dialCode": "+297",
        "isoCode": "AW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/aw.svg"
    },
    {
        "name": "Ascension Island",
        "dialCode": "+247",
        "isoCode": "AC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ac.svg"
    },
    {
        "name": "Australia",
        "dialCode": "+61",
        "isoCode": "AU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/au.svg"
    },
    {
        "name": "Austria",
        "dialCode": "+43",
        "isoCode": "AT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/at.svg"
    },
    {
        "name": "Azerbaijan",
        "dialCode": "+994",
        "isoCode": "AZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/az.svg"
    },
    {
        "name": "Bahamas",
        "dialCode": "+1242",
        "isoCode": "BS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bs.svg"
    },
    {
        "name": "Bahrain",
        "dialCode": "+973",
        "isoCode": "BH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bh.svg"
    },
    {
        "name": "Bangladesh",
        "dialCode": "+880",
        "isoCode": "BD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bd.svg"
    },
    {
        "name": "Barbados",
        "dialCode": "+1246",
        "isoCode": "BB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bb.svg"
    },
    {
        "name": "Belarus",
        "dialCode": "+375",
        "isoCode": "BY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/by.svg"
    },
    {
        "name": "Belgium",
        "dialCode": "+32",
        "isoCode": "BE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/be.svg"
    },
    {
        "name": "Belize",
        "dialCode": "+501",
        "isoCode": "BZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bz.svg"
    },
    {
        "name": "Benin",
        "dialCode": "+229",
        "isoCode": "BJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bj.svg"
    },
    {
        "name": "Bermuda",
        "dialCode": "+1441",
        "isoCode": "BM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bm.svg"
    },
    {
        "name": "Bhutan",
        "dialCode": "+975",
        "isoCode": "BT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bt.svg"
    },
    {
        "name": "Bolivia",
        "dialCode": "+591",
        "isoCode": "BO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bo.svg"
    },
    {
        "name": "Bosnia and Herzegovina",
        "dialCode": "+387",
        "isoCode": "BA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ba.svg"
    },
    {
        "name": "Botswana",
        "dialCode": "+267",
        "isoCode": "BW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bw.svg"
    },
    {
        "name": "Brazil",
        "dialCode": "+55",
        "isoCode": "BR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/br.svg"
    },
    {
        "name": "British Indian Ocean Territory",
        "dialCode": "+246",
        "isoCode": "IO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/io.svg"
    },
    {
        "name": "Brunei Darussalam",
        "dialCode": "+673",
        "isoCode": "BN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bn.svg"
    },
    {
        "name": "Bulgaria",
        "dialCode": "+359",
        "isoCode": "BG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bg.svg"
    },
    {
        "name": "Burkina Faso",
        "dialCode": "+226",
        "isoCode": "BF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bf.svg"
    },
    {
        "name": "Burundi",
        "dialCode": "+257",
        "isoCode": "BI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bi.svg"
    },
    {
        "name": "Cambodia",
        "dialCode": "+855",
        "isoCode": "KH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kh.svg"
    },
    {
        "name": "Cameroon",
        "dialCode": "+237",
        "isoCode": "CM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cm.svg"
    },
    {
        "name": "Canada",
        "dialCode": "+1",
        "isoCode": "CA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ca.svg"
    },
    {
        "name": "Cape Verde",
        "dialCode": "+238",
        "isoCode": "CV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cv.svg"
    },
    {
        "name": "Cayman Islands",
        "dialCode": "+1345",
        "isoCode": "KY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ky.svg"
    },
    {
        "name": "Central African Republic",
        "dialCode": "+236",
        "isoCode": "CF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cf.svg"
    },
    {
        "name": "Chad",
        "dialCode": "+235",
        "isoCode": "TD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/td.svg"
    },
    {
        "name": "Chile",
        "dialCode": "+56",
        "isoCode": "CL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cl.svg"
    },
    {
        "name": "China",
        "dialCode": "+86",
        "isoCode": "CN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cn.svg"
    },
    {
        "name": "Christmas Island",
        "dialCode": "+61",
        "isoCode": "CX",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cx.svg"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "dialCode": "+61",
        "isoCode": "CC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cc.svg"
    },
    {
        "name": "Colombia",
        "dialCode": "+57",
        "isoCode": "CO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/co.svg"
    },
    {
        "name": "Comoros",
        "dialCode": "+269",
        "isoCode": "KM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/km.svg"
    },
    {
        "name": "Congo",
        "dialCode": "+242",
        "isoCode": "CG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cg.svg"
    },
    {
        "name": "Cook Islands",
        "dialCode": "+682",
        "isoCode": "CK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ck.svg"
    },
    {
        "name": "Costa Rica",
        "dialCode": "+506",
        "isoCode": "CR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cr.svg"
    },
    {
        "name": "Croatia",
        "dialCode": "+385",
        "isoCode": "HR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hr.svg"
    },
    {
        "name": "Cuba",
        "dialCode": "+53",
        "isoCode": "CU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cu.svg"
    },
    {
        "name": "Cyprus",
        "dialCode": "+357",
        "isoCode": "CY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cy.svg"
    },
    {
        "name": "Czech Republic",
        "dialCode": "+420",
        "isoCode": "CZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cz.svg"
    },
    {
        "name": "Democratic Republic of the Congo",
        "dialCode": "+243",
        "isoCode": "CD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cd.svg"
    },
    {
        "name": "Denmark",
        "dialCode": "+45",
        "isoCode": "DK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dk.svg"
    },
    {
        "name": "Djibouti",
        "dialCode": "+253",
        "isoCode": "DJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dj.svg"
    },
    {
        "name": "Dominica",
        "dialCode": "+1767",
        "isoCode": "DM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dm.svg"
    },
    {
        "name": "Dominican Republic",
        "dialCode": "+1849",
        "isoCode": "DO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/do.svg"
    },
    {
        "name": "Ecuador",
        "dialCode": "+593",
        "isoCode": "EC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ec.svg"
    },
    {
        "name": "Egypt",
        "dialCode": "+20",
        "isoCode": "EG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/eg.svg"
    },
    {
        "name": "El Salvador",
        "dialCode": "+503",
        "isoCode": "SV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sv.svg"
    },
    {
        "name": "Equatorial Guinea",
        "dialCode": "+240",
        "isoCode": "GQ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gq.svg"
    },
    {
        "name": "Eritrea",
        "dialCode": "+291",
        "isoCode": "ER",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/er.svg"
    },
    {
        "name": "Estonia",
        "dialCode": "+372",
        "isoCode": "EE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ee.svg"
    },
    {
        "name": "Eswatini",
        "dialCode": "+268",
        "isoCode": "SZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sz.svg"
    },
    {
        "name": "Ethiopia",
        "dialCode": "+251",
        "isoCode": "ET",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/et.svg"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "dialCode": "+500",
        "isoCode": "FK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fk.svg"
    },
    {
        "name": "Faroe Islands",
        "dialCode": "+298",
        "isoCode": "FO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fo.svg"
    },
    {
        "name": "Fiji",
        "dialCode": "+679",
        "isoCode": "FJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fj.svg"
    },
    {
        "name": "Finland",
        "dialCode": "+358",
        "isoCode": "FI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fi.svg"
    },
    {
        "name": "France",
        "dialCode": "+33",
        "isoCode": "FR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fr.svg"
    },
    {
        "name": "French Guiana",
        "dialCode": "+594",
        "isoCode": "GF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gf.svg"
    },
    {
        "name": "French Polynesia",
        "dialCode": "+689",
        "isoCode": "PF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pf.svg"
    },
    {
        "name": "Gabon",
        "dialCode": "+241",
        "isoCode": "GA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ga.svg"
    },
    {
        "name": "Gambia",
        "dialCode": "+220",
        "isoCode": "GM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gm.svg"
    },
    {
        "name": "Georgia",
        "dialCode": "+995",
        "isoCode": "GE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ge.svg"
    },
    {
        "name": "Germany",
        "dialCode": "+49",
        "isoCode": "DE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/de.svg"
    },
    {
        "name": "Ghana",
        "dialCode": "+233",
        "isoCode": "GH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gh.svg"
    },
    {
        "name": "Gibraltar",
        "dialCode": "+350",
        "isoCode": "GI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gi.svg"
    },
    {
        "name": "Greece",
        "dialCode": "+30",
        "isoCode": "GR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gr.svg"
    },
    {
        "name": "Greenland",
        "dialCode": "+299",
        "isoCode": "GL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gl.svg"
    },
    {
        "name": "Grenada",
        "dialCode": "+1473",
        "isoCode": "GD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gd.svg"
    },
    {
        "name": "Guadeloupe",
        "dialCode": "+590",
        "isoCode": "GP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gp.svg"
    },
    {
        "name": "Guam",
        "dialCode": "+1671",
        "isoCode": "GU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gu.svg"
    },
    {
        "name": "Guatemala",
        "dialCode": "+502",
        "isoCode": "GT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gt.svg"
    },
    {
        "name": "Guernsey",
        "dialCode": "+44-1481",
        "isoCode": "GG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gg.svg"
    },
    {
        "name": "Guinea",
        "dialCode": "+224",
        "isoCode": "GN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gn.svg"
    },
    {
        "name": "Guinea-Bissau",
        "dialCode": "+245",
        "isoCode": "GW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gw.svg"
    },
    {
        "name": "Guyana",
        "dialCode": "+592",
        "isoCode": "GY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gy.svg"
    },
    {
        "name": "Haiti",
        "dialCode": "+509",
        "isoCode": "HT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ht.svg"
    },
    {
        "name": "Holy See (Vatican City State)",
        "dialCode": "+379",
        "isoCode": "VA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/va.svg"
    },
    {
        "name": "Honduras",
        "dialCode": "+504",
        "isoCode": "HN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hn.svg"
    },
    {
        "name": "Hong Kong",
        "dialCode": "+852",
        "isoCode": "HK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hk.svg"
    },
    {
        "name": "Hungary",
        "dialCode": "+36",
        "isoCode": "HU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hu.svg"
    },
    {
        "name": "Iceland",
        "dialCode": "+354",
        "isoCode": "IS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/is.svg"
    },
    {
        "name": "India",
        "dialCode": "+91",
        "isoCode": "IN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/in.svg"
    },
    {
        "name": "Indonesia",
        "dialCode": "+62",
        "isoCode": "ID",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/id.svg"
    },
    {
        "name": "Iran",
        "dialCode": "+98",
        "isoCode": "IR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ir.svg"
    },
    {
        "name": "Iraq",
        "dialCode": "+964",
        "isoCode": "IQ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/iq.svg"
    },
    {
        "name": "Ireland",
        "dialCode": "+353",
        "isoCode": "IE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ie.svg"
    },
    {
        "name": "Isle of Man",
        "dialCode": "+44-1624",
        "isoCode": "IM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/im.svg"
    },
    {
        "name": "Israel",
        "dialCode": "+972",
        "isoCode": "IL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/il.svg"
    },
    {
        "name": "Italy",
        "dialCode": "+39",
        "isoCode": "IT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/it.svg"
    },
    {
        "name": "Ivory Coast / Cote d'Ivoire",
        "dialCode": "+225",
        "isoCode": "CI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ci.svg"
    },
    {
        "name": "Jamaica",
        "dialCode": "+1876",
        "isoCode": "JM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/jm.svg"
    },
    {
        "name": "Japan",
        "dialCode": "+81",
        "isoCode": "JP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/jp.svg"
    },
    {
        "name": "Jersey",
        "dialCode": "+44-1534",
        "isoCode": "JE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/je.svg"
    },
    {
        "name": "Jordan",
        "dialCode": "+962",
        "isoCode": "JO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/jo.svg"
    },
    {
        "name": "Kazakhstan",
        "dialCode": "+77",
        "isoCode": "KZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kz.svg"
    },
    {
        "name": "Kenya",
        "dialCode": "+254",
        "isoCode": "KE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ke.svg"
    },
    {
        "name": "Kiribati",
        "dialCode": "+686",
        "isoCode": "KI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ki.svg"
    },
    {
        "name": "Korea, Democratic People's Republic of Korea",
        "dialCode": "+850",
        "isoCode": "KP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kp.svg"
    },
    {
        "name": "Korea, Republic of South Korea",
        "dialCode": "+82",
        "isoCode": "KR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kr.svg"
    },
    {
        "name": "Kosovo",
        "dialCode": "+383",
        "isoCode": "XK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/xk.svg"
    },
    {
        "name": "Kuwait",
        "dialCode": "+965",
        "isoCode": "KW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kw.svg"
    },
    {
        "name": "Kyrgyzstan",
        "dialCode": "+996",
        "isoCode": "KG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kg.svg"
    },
    {
        "name": "Laos",
        "dialCode": "+856",
        "isoCode": "LA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/la.svg"
    },
    {
        "name": "Latvia",
        "dialCode": "+371",
        "isoCode": "LV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lv.svg"
    },
    {
        "name": "Lebanon",
        "dialCode": "+961",
        "isoCode": "LB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lb.svg"
    },
    {
        "name": "Lesotho",
        "dialCode": "+266",
        "isoCode": "LS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ls.svg"
    },
    {
        "name": "Liberia",
        "dialCode": "+231",
        "isoCode": "LR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lr.svg"
    },
    {
        "name": "Libya",
        "dialCode": "+218",
        "isoCode": "LY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ly.svg"
    },
    {
        "name": "Liechtenstein",
        "dialCode": "+423",
        "isoCode": "LI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/li.svg"
    },
    {
        "name": "Lithuania",
        "dialCode": "+370",
        "isoCode": "LT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lt.svg"
    },
    {
        "name": "Luxembourg",
        "dialCode": "+352",
        "isoCode": "LU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lu.svg"
    },
    {
        "name": "Macau",
        "dialCode": "+853",
        "isoCode": "MO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mo.svg"
    },
    {
        "name": "Madagascar",
        "dialCode": "+261",
        "isoCode": "MG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mg.svg"
    },
    {
        "name": "Malawi",
        "dialCode": "+265",
        "isoCode": "MW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mw.svg"
    },
    {
        "name": "Malaysia",
        "dialCode": "+60",
        "isoCode": "MY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/my.svg"
    },
    {
        "name": "Maldives",
        "dialCode": "+960",
        "isoCode": "MV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mv.svg"
    },
    {
        "name": "Mali",
        "dialCode": "+223",
        "isoCode": "ML",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ml.svg"
    },
    {
        "name": "Malta",
        "dialCode": "+356",
        "isoCode": "MT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mt.svg"
    },
    {
        "name": "Marshall Islands",
        "dialCode": "+692",
        "isoCode": "MH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mh.svg"
    },
    {
        "name": "Martinique",
        "dialCode": "+596",
        "isoCode": "MQ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mq.svg"
    },
    {
        "name": "Mauritania",
        "dialCode": "+222",
        "isoCode": "MR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mr.svg"
    },
    {
        "name": "Mauritius",
        "dialCode": "+230",
        "isoCode": "MU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mu.svg"
    },
    {
        "name": "Mayotte",
        "dialCode": "+262",
        "isoCode": "YT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/yt.svg"
    },
    {
        "name": "Mexico",
        "dialCode": "+52",
        "isoCode": "MX",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mx.svg"
    },
    {
        "name": "Micronesia, Federated States of Micronesia",
        "dialCode": "+691",
        "isoCode": "FM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fm.svg"
    },
    {
        "name": "Moldova",
        "dialCode": "+373",
        "isoCode": "MD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/md.svg"
    },
    {
        "name": "Monaco",
        "dialCode": "+377",
        "isoCode": "MC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mc.svg"
    },
    {
        "name": "Mongolia",
        "dialCode": "+976",
        "isoCode": "MN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mn.svg"
    },
    {
        "name": "Montenegro",
        "dialCode": "+382",
        "isoCode": "ME",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/me.svg"
    },
    {
        "name": "Montserrat",
        "dialCode": "+1664",
        "isoCode": "MS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ms.svg"
    },
    {
        "name": "Morocco",
        "dialCode": "+212",
        "isoCode": "MA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ma.svg"
    },
    {
        "name": "Mozambique",
        "dialCode": "+258",
        "isoCode": "MZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mz.svg"
    },
    {
        "name": "Myanmar",
        "dialCode": "+95",
        "isoCode": "MM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mm.svg"
    },
    {
        "name": "Namibia",
        "dialCode": "+264",
        "isoCode": "NA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/na.svg"
    },
    {
        "name": "Nauru",
        "dialCode": "+674",
        "isoCode": "NR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nr.svg"
    },
    {
        "name": "Nepal",
        "dialCode": "+977",
        "isoCode": "NP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/np.svg"
    },
    {
        "name": "Netherlands",
        "dialCode": "+31",
        "isoCode": "NL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nl.svg"
    },
    {
        "name": "Netherlands Antilles",
        "dialCode": "+599",
        "isoCode": "AN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/an.svg"
    },
    {
        "name": "New Caledonia",
        "dialCode": "+687",
        "isoCode": "NC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nc.svg"
    },
    {
        "name": "New Zealand",
        "dialCode": "+64",
        "isoCode": "NZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nz.svg"
    },
    {
        "name": "Nicaragua",
        "dialCode": "+505",
        "isoCode": "NI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ni.svg"
    },
    {
        "name": "Niger",
        "dialCode": "+227",
        "isoCode": "NE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ne.svg"
    },
    {
        "name": "Nigeria",
        "dialCode": "+234",
        "isoCode": "NG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ng.svg"
    },
    {
        "name": "Niue",
        "dialCode": "+683",
        "isoCode": "NU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nu.svg"
    },
    {
        "name": "Norfolk Island",
        "dialCode": "+672",
        "isoCode": "NF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/nf.svg"
    },
    {
        "name": "North Macedonia",
        "dialCode": "+389",
        "isoCode": "MK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mk.svg"
    },
    {
        "name": "Northern Mariana Islands",
        "dialCode": "+1670",
        "isoCode": "MP",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mp.svg"
    },
    {
        "name": "Norway",
        "dialCode": "+47",
        "isoCode": "NO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/no.svg"
    },
    {
        "name": "Oman",
        "dialCode": "+968",
        "isoCode": "OM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/om.svg"
    },
    {
        "name": "Pakistan",
        "dialCode": "+92",
        "isoCode": "PK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pk.svg"
    },
    {
        "name": "Palau",
        "dialCode": "+680",
        "isoCode": "PW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pw.svg"
    },
    {
        "name": "Palestine",
        "dialCode": "+970",
        "isoCode": "PS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ps.svg"
    },
    {
        "name": "Panama",
        "dialCode": "+507",
        "isoCode": "PA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pa.svg"
    },
    {
        "name": "Papua New Guinea",
        "dialCode": "+675",
        "isoCode": "PG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pg.svg"
    },
    {
        "name": "Paraguay",
        "dialCode": "+595",
        "isoCode": "PY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/py.svg"
    },
    {
        "name": "Peru",
        "dialCode": "+51",
        "isoCode": "PE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pe.svg"
    },
    {
        "name": "Philippines",
        "dialCode": "+63",
        "isoCode": "PH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ph.svg"
    },
    {
        "name": "Pitcairn",
        "dialCode": "+872",
        "isoCode": "PN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pn.svg"
    },
    {
        "name": "Poland",
        "dialCode": "+48",
        "isoCode": "PL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pl.svg"
    },
    {
        "name": "Portugal",
        "dialCode": "+351",
        "isoCode": "PT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pt.svg"
    },
    {
        "name": "Puerto Rico",
        "dialCode": "+1939",
        "isoCode": "PR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pr.svg"
    },
    {
        "name": "Qatar",
        "dialCode": "+974",
        "isoCode": "QA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/qa.svg"
    },
    {
        "name": "Reunion",
        "dialCode": "+262",
        "isoCode": "RE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/re.svg"
    },
    {
        "name": "Romania",
        "dialCode": "+40",
        "isoCode": "RO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ro.svg"
    },
    {
        "name": "Russia",
        "dialCode": "+7",
        "isoCode": "RU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ru.svg"
    },
    {
        "name": "Rwanda",
        "dialCode": "+250",
        "isoCode": "RW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/rw.svg"
    },
    {
        "name": "Saint Barthelemy",
        "dialCode": "+590",
        "isoCode": "BL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/bl.svg"
    },
    {
        "name": "Saint Helena, Ascension and Tristan Da Cunha",
        "dialCode": "+290",
        "isoCode": "SH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sh.svg"
    },
    {
        "name": "Saint Kitts and Nevis",
        "dialCode": "+1869",
        "isoCode": "KN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/kn.svg"
    },
    {
        "name": "Saint Lucia",
        "dialCode": "+1758",
        "isoCode": "LC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lc.svg"
    },
    {
        "name": "Saint Martin",
        "dialCode": "+590",
        "isoCode": "MF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/mf.svg"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "dialCode": "+508",
        "isoCode": "PM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pm.svg"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "dialCode": "+1784",
        "isoCode": "VC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/vc.svg"
    },
    {
        "name": "Samoa",
        "dialCode": "+685",
        "isoCode": "WS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ws.svg"
    },
    {
        "name": "San Marino",
        "dialCode": "+378",
        "isoCode": "SM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sm.svg"
    },
    {
        "name": "Sao Tome and Principe",
        "dialCode": "+239",
        "isoCode": "ST",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/st.svg"
    },
    {
        "name": "Saudi Arabia",
        "dialCode": "+966",
        "isoCode": "SA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sa.svg"
    },
    {
        "name": "Senegal",
        "dialCode": "+221",
        "isoCode": "SN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sn.svg"
    },
    {
        "name": "Serbia",
        "dialCode": "+381",
        "isoCode": "RS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/rs.svg"
    },
    {
        "name": "Seychelles",
        "dialCode": "+248",
        "isoCode": "SC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sc.svg"
    },
    {
        "name": "Sierra Leone",
        "dialCode": "+232",
        "isoCode": "SL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sl.svg"
    },
    {
        "name": "Singapore",
        "dialCode": "+65",
        "isoCode": "SG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sg.svg"
    },
    {
        "name": "Sint Maarten",
        "dialCode": "+1721",
        "isoCode": "SX",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sx.svg"
    },
    {
        "name": "Slovakia",
        "dialCode": "+421",
        "isoCode": "SK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sk.svg"
    },
    {
        "name": "Slovenia",
        "dialCode": "+386",
        "isoCode": "SI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/si.svg"
    },
    {
        "name": "Solomon Islands",
        "dialCode": "+677",
        "isoCode": "SB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sb.svg"
    },
    {
        "name": "Somalia",
        "dialCode": "+252",
        "isoCode": "SO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/so.svg"
    },
    {
        "name": "South Africa",
        "dialCode": "+27",
        "isoCode": "ZA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/za.svg"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "dialCode": "+500",
        "isoCode": "GS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gs.svg"
    },
    {
        "name": "South Sudan",
        "dialCode": "+211",
        "isoCode": "SS",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ss.svg"
    },
    {
        "name": "Spain",
        "dialCode": "+34",
        "isoCode": "ES",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/es.svg"
    },
    {
        "name": "Sri Lanka",
        "dialCode": "+94",
        "isoCode": "LK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/lk.svg"
    },
    {
        "name": "Sudan",
        "dialCode": "+249",
        "isoCode": "SD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sd.svg"
    },
    {
        "name": "Suriname",
        "dialCode": "+597",
        "isoCode": "SR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sr.svg"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "dialCode": "+47",
        "isoCode": "SJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sj.svg"
    },
    {
        "name": "Sweden",
        "dialCode": "+46",
        "isoCode": "SE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/se.svg"
    },
    {
        "name": "Switzerland",
        "dialCode": "+41",
        "isoCode": "CH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ch.svg"
    },
    {
        "name": "Syrian Arab Republic",
        "dialCode": "+963",
        "isoCode": "SY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sy.svg"
    },
    {
        "name": "Taiwan",
        "dialCode": "+886",
        "isoCode": "TW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tw.svg"
    },
    {
        "name": "Tajikistan",
        "dialCode": "+992",
        "isoCode": "TJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tj.svg"
    },
    {
        "name": "Tanzania, United Republic of Tanzania",
        "dialCode": "+255",
        "isoCode": "TZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tz.svg"
    },
    {
        "name": "Thailand",
        "dialCode": "+66",
        "isoCode": "TH",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/th.svg"
    },
    {
        "name": "Timor-Leste",
        "dialCode": "+670",
        "isoCode": "TL",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tl.svg"
    },
    {
        "name": "Togo",
        "dialCode": "+228",
        "isoCode": "TG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tg.svg"
    },
    {
        "name": "Tokelau",
        "dialCode": "+690",
        "isoCode": "TK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tk.svg"
    },
    {
        "name": "Tonga",
        "dialCode": "+676",
        "isoCode": "TO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/to.svg"
    },
    {
        "name": "Trinidad and Tobago",
        "dialCode": "+1868",
        "isoCode": "TT",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tt.svg"
    },
    {
        "name": "Tunisia",
        "dialCode": "+216",
        "isoCode": "TN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tn.svg"
    },
    {
        "name": "Turkey",
        "dialCode": "+90",
        "isoCode": "TR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tr.svg"
    },
    {
        "name": "Turkmenistan",
        "dialCode": "+993",
        "isoCode": "TM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tm.svg"
    },
    {
        "name": "Turks and Caicos Islands",
        "dialCode": "+1649",
        "isoCode": "TC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tc.svg"
    },
    {
        "name": "Tuvalu",
        "dialCode": "+688",
        "isoCode": "TV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/tv.svg"
    },
    {
        "name": "Uganda",
        "dialCode": "+256",
        "isoCode": "UG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ug.svg"
    },
    {
        "name": "Ukraine",
        "dialCode": "+380",
        "isoCode": "UA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ua.svg"
    },
    {
        "name": "United Arab Emirates",
        "dialCode": "+971",
        "isoCode": "AE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ae.svg"
    },
    {
        "name": "United Kingdom",
        "dialCode": "+44",
        "isoCode": "GB",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gb.svg"
    },
    {
        "name": "United States",
        "dialCode": "+1",
        "isoCode": "US",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg"
    },
    {
        "name": "United States Minor Outlying Islands",
        "dialCode": "+246",
        "isoCode": "UMI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/umi.svg"
    },
    {
        "name": "Uruguay",
        "dialCode": "+598",
        "isoCode": "UY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/uy.svg"
    },
    {
        "name": "Uzbekistan",
        "dialCode": "+998",
        "isoCode": "UZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/uz.svg"
    },
    {
        "name": "Vanuatu",
        "dialCode": "+678",
        "isoCode": "VU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/vu.svg"
    },
    {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "dialCode": "+58",
        "isoCode": "VE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ve.svg"
    },
    {
        "name": "Vietnam",
        "dialCode": "+84",
        "isoCode": "VN",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/vn.svg"
    },
    {
        "name": "Virgin Islands, British",
        "dialCode": "+1284",
        "isoCode": "VG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/vg.svg"
    },
    {
        "name": "Virgin Islands, U.S.",
        "dialCode": "+1340",
        "isoCode": "VI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/vi.svg"
    },
    {
        "name": "Wallis and Futuna",
        "dialCode": "+681",
        "isoCode": "WF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/wf.svg"
    },
    {
        "name": "Yemen",
        "dialCode": "+967",
        "isoCode": "YE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ye.svg"
    },
    {
        "name": "Zambia",
        "dialCode": "+260",
        "isoCode": "ZM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/zm.svg"
    },
    {
        "name": "Zimbabwe",
        "dialCode": "+263",
        "isoCode": "ZW",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/zw.svg"
    }
];
export const LANGUAGES = [
    { id: 1, code: "ab", flagCode: "ge", name: "Abkhaz", nativeName: "аҧсуа", coachReasoning: "" },
    { id: 2, code: "aa", flagCode: "et", name: "Afar", nativeName: "Afaraf", coachReasoning: "" },
    { id: 3, code: "af", flagCode: "za", name: "Afrikaans", nativeName: "Afrikaans", coachReasoning: "" },
    { id: 4, code: "ak", flagCode: "gh", name: "Akan", nativeName: "Akan", coachReasoning: "" },
    { id: 5, code: "sq", flagCode: "al", name: "Albanian", nativeName: "Shqip", coachReasoning: "" },
    { id: 6, code: "am", flagCode: "et", name: "Amharic", nativeName: "አማርኛ", coachReasoning: "" },
    { id: 7, code: "ar", flagCode: "sa", name: "Arabic", nativeName: "العربية", coachReasoning: "" },
    { id: 8, code: "an", flagCode: "es", name: "Aragonese", nativeName: "Aragonés", coachReasoning: "" },
    { id: 9, code: "hy", flagCode: "am", name: "Armenian", nativeName: "Հայերեն", coachReasoning: "" },
    { id: 10, code: "as", flagCode: "in", name: "Assamese", nativeName: "অসমীয়া", coachReasoning: "" },
    { id: 11, code: "av", flagCode: "az", name: "Avaric", nativeName: "авар", coachReasoning: "" },
    { id: 12, code: "ae", flagCode: "ir", name: "Avestan", nativeName: "Avesta", coachReasoning: "" },
    { id: 13, code: "ay", flagCode: "bo", name: "Aymara", nativeName: "Aymar", coachReasoning: "" },
    { id: 14, code: "az", flagCode: "az", name: "Azerbaijani", nativeName: "Azərbaycan", coachReasoning: "" },
    { id: 15, code: "bm", flagCode: "ml", name: "Bambara", nativeName: "Bamanankan", coachReasoning: "" },
    { id: 16, code: "ba", flagCode: "ru", name: "Bashkir", nativeName: "башҡорт", coachReasoning: "" },
    { id: 17, code: "eu", flagCode: "es", name: "Basque", nativeName: "Euskara", coachReasoning: "" },
    { id: 18, code: "be", flagCode: "by", name: "Belarusian", nativeName: "Беларуская", coachReasoning: "" },
    { id: 19, code: "bn", flagCode: "bd", name: "Bengali", nativeName: "বাংলা", coachReasoning: "" },
    { id: 20, code: "bh", flagCode: "in", name: "Bihari", nativeName: "भोजपुरी", coachReasoning: "" },
    { id: 21, code: "bi", flagCode: "vu", name: "Bislama", nativeName: "Bislama", coachReasoning: "" },
    { id: 22, code: "bs", flagCode: "ba", name: "Bosnian", nativeName: "Bosanski", coachReasoning: "" },
    { id: 23, code: "br", flagCode: "fr", name: "Breton", nativeName: "Brezhoneg", coachReasoning: "" },
    { id: 24, code: "bg", flagCode: "bg", name: "Bulgarian", nativeName: "български", coachReasoning: "" },
    { id: 25, code: "my", flagCode: "mm", name: "Burmese", nativeName: "ဗမာစာ", coachReasoning: "" },
    { id: 26, code: "ca", flagCode: "es", name: "Catalan", nativeName: "Català", coachReasoning: "" },
    { id: 27, code: "ch", flagCode: "mp", name: "Chamorro", nativeName: "Chamoru", coachReasoning: "" },
    { id: 28, code: "ce", flagCode: "ru", name: "Chechen", nativeName: "нохчийн", coachReasoning: "" },
    { id: 29, code: "ny", flagCode: "mw", name: "Chichewa", nativeName: "ChiCheŵa", coachReasoning: "" },
    { id: 30, code: "zh", flagCode: "cn", name: "Chinese", nativeName: "中文", coachReasoning: "" },
    { id: 31, code: "cv", flagCode: "ru", name: "Chuvash", nativeName: "чӑваш", coachReasoning: "" },
    { id: 32, code: "kw", flagCode: "gb", name: "Cornish", nativeName: "Kernewek", coachReasoning: "" },
    { id: 33, code: "co", flagCode: "fr", name: "Corsican", nativeName: "Corsu", coachReasoning: "" },
    { id: 34, code: "cr", flagCode: "ca", name: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ", coachReasoning: "" },
    { id: 35, code: "hr", flagCode: "hr", name: "Croatian", nativeName: "Hrvatski", coachReasoning: "" },
    { id: 36, code: "cz", flagCode: "cz", name: "Czech", nativeName: "Česky", coachReasoning: "" },
    { id: 37, code: "da", flagCode: "dk", name: "Danish", nativeName: "Dansk", coachReasoning: "" },
    { id: 38, code: "dv", flagCode: "mv", name: "Divehi", nativeName: "ދިވެހި", coachReasoning: "" },
    { id: 39, code: "nl", flagCode: "nl", name: "Dutch", nativeName: "Nederlands", coachReasoning: "" },
    { id: 40, code: "en", flagCode: "gb", name: "English", nativeName: "English", coachReasoning: "" },
    // { id: 41, code: "eo", flagCode: "eo", name: "Esperanto", nativeName: "Esperanto", coachReasoning: ""},
    { id: 42, code: "et", flagCode: "ee", name: "Estonian", nativeName: "Eesti", coachReasoning: "" },
    { id: 43, code: "ee", flagCode: "tg", name: "Ewe", nativeName: "Eʋegbe", coachReasoning: "" },
    { id: 44, code: "fo", flagCode: "fo", name: "Faroese", nativeName: "Føroyskt", coachReasoning: "" },
    { id: 45, code: "fj", flagCode: "fj", name: "Fijian", nativeName: "Vosa vaka-Viti", coachReasoning: "" },
    { id: 46, code: "fi", flagCode: "fi", name: "Finnish", nativeName: "Suomen", coachReasoning: "" },
    { id: 47, code: "fr", flagCode: "fr", name: "French", nativeName: "Français", coachReasoning: "" },
    { id: 48, code: "ff", flagCode: "ml", name: "Fula Fulah Pulaar Pular", nativeName: "Fulfulde Pulaar Pular", coachReasoning: "" },
    { id: 49, code: "gl", flagCode: "es", name: "Galician", nativeName: "Galego", coachReasoning: "" },
    { id: 50, code: "ka", flagCode: "ge", name: "Georgian", nativeName: "ქართული", coachReasoning: "" },
    { id: 51, code: "de", flagCode: "de", name: "German", nativeName: "Deutsch", coachReasoning: "" },
    { id: 52, code: "el", flagCode: "gr", name: "Greek", nativeName: "Ελληνικά", coachReasoning: "" },
    { id: 53, code: "gn", flagCode: "py", name: "Guaraní", nativeName: "Avañeẽ", coachReasoning: "" },
    { id: 54, code: "gu", flagCode: "in", name: "Gujarati", nativeName: "ગુજરાતી", coachReasoning: "" },
    { id: 55, code: "ht", flagCode: "ht", name: "Haitian", nativeName: "Ayisyen", coachReasoning: "" },
    { id: 56, code: "ha", flagCode: "ne", name: "Hausa", nativeName: "Hausa هَوُسَ", coachReasoning: "" },
    { id: 57, code: "he", flagCode: "il", name: "Hebrew", nativeName: "עברית", coachReasoning: "" },
    { id: 58, code: "hz", flagCode: "na", name: "Herero", nativeName: "Otjiherero", coachReasoning: "" },
    { id: 59, code: "hi", flagCode: "in", name: "Hindi", nativeName: "हिन्दी हिंदी", coachReasoning: "" },
    { id: 60, code: "ho", flagCode: "pg", name: "Hiri Motu", nativeName: "Hiri Motu", coachReasoning: "" },
    { id: 61, code: "hu", flagCode: "hu", name: "Hungarian", nativeName: "Magyar", coachReasoning: "" },
    // { id: 62, code: "ia", flagCode: "ia", name: "Interlingua", nativeName: "Interlingua", coachReasoning: ""},
    { id: 63, code: "id", flagCode: "id", name: "Indonesian", nativeName: "Bahasa Indonesia", coachReasoning: "" },
    // { id: 64, code: "ie", flagCode: "ie", name: "Interlingue", nativeName: "Occidental Interlingue", coachReasoning: ""},
    { id: 65, code: "ga", flagCode: "ie", name: "Irish", nativeName: "Gaeilge", coachReasoning: "" },
    { id: 66, code: "ig", flagCode: "ng", name: "Igbo", nativeName: "Igbo", coachReasoning: "" },
    { id: 67, code: "ik", flagCode: "us", name: "Inupiaq", nativeName: "Iñupiaq", coachReasoning: "" },
    // { id: 68, code: "io", flagCode: "io", name: "Ido", nativeName: "Ido", coachReasoning: ""},
    { id: 69, code: "is", flagCode: "is", name: "Icelandic", nativeName: "Íslenska", coachReasoning: "" },
    { id: 70, code: "it", flagCode: "it", name: "Italian", nativeName: "Italiano", coachReasoning: "" },
    { id: 71, code: "iu", flagCode: "ca", name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ", coachReasoning: "" },
    { id: 72, code: "ja", flagCode: "jp", name: "Japanese", nativeName: "日本語", coachReasoning: "" },
    { id: 73, code: "jv", flagCode: "id", name: "Javanese", nativeName: "Basa Jawa", coachReasoning: "" },
    { id: 74, code: "kl", flagCode: "gl", name: "Greenlandic", nativeName: "Kalaallisut kalaallit oqaasii", coachReasoning: "" },
    { id: 75, code: "kn", flagCode: "in", name: "Kannada", nativeName: "ಕನ್ನಡ", coachReasoning: "" },
    { id: 76, code: "kr", flagCode: "td", name: "Kanuri", nativeName: "Kanuri", coachReasoning: "" },
    { id: 77, code: "ks", flagCode: "in", name: "Kashmiri", nativeName: "कश्मीरी كشميري‎", coachReasoning: "" },
    { id: 78, code: "kk", flagCode: "kz", name: "Kazakh", nativeName: "Қазақ", coachReasoning: "" },
    { id: 79, code: "km", flagCode: "kh", name: "Khmer", nativeName: "ភាសាខ្មែរ", coachReasoning: "" },
    { id: 80, code: "ki", flagCode: "ke", name: "Gikuyu", nativeName: "Gĩkũyũ", coachReasoning: "" },
    { id: 81, code: "rw", flagCode: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda", coachReasoning: "" },
    { id: 82, code: "ky", flagCode: "kg", name: "Kyrgyz", nativeName: "кыргыз", coachReasoning: "" },
    { id: 83, code: "kv", flagCode: "ru", name: "Komi", nativeName: "коми", coachReasoning: "" },
    { id: 84, code: "kg", flagCode: "ao", name: "Kongo", nativeName: "KiKongo", coachReasoning: "" },
    { id: 85, code: "ko", flagCode: "kr", name: "Korean", nativeName: "조선말", coachReasoning: "" },
    { id: 86, code: "ku", flagCode: "tr", name: "Kurdish", nativeName: "Kurdî", coachReasoning: "" },
    { id: 87, code: "kj", flagCode: "ao", name: "Kuanyama", nativeName: "Kuanyama", coachReasoning: "" },
    { id: 88, code: "la", flagCode: "va", name: "Latin", nativeName: "Latine", coachReasoning: "" },
    { id: 89, code: "lb", flagCode: "lu", name: "Luxembourgish", nativeName: "Lëtzebuergesch", coachReasoning: "" },
    { id: 90, code: "lg", flagCode: "ug", name: "Luganda", nativeName: "Luganda", coachReasoning: "" },
    { id: 91, code: "li", flagCode: "nl", name: "Limburgish", nativeName: "Limburgs", coachReasoning: "" },
    { id: 92, code: "ln", flagCode: "cd", name: "Lingala", nativeName: "Lingála", coachReasoning: "" },
    { id: 93, code: "lo", flagCode: "la", name: "Lao", nativeName: "ພາສາລາວ", coachReasoning: "" },
    { id: 94, code: "lt", flagCode: "lt", name: "Lithuanian", nativeName: "Lietuvių", coachReasoning: "" },
    { id: 95, code: "lu", flagCode: "cd", name: "Luba-Katanga", nativeName: "Luba-Katanga", coachReasoning: "" },
    { id: 96, code: "lv", flagCode: "lv", name: "Latvian", nativeName: "latviešu", coachReasoning: "" },
    { id: 97, code: "gv", flagCode: "im", name: "Manx", nativeName: "Gaelg Gailck", coachReasoning: "" },
    { id: 98, code: "mk", flagCode: "mk", name: "Macedonian", nativeName: "македонски", coachReasoning: "" },
    { id: 99, code: "mg", flagCode: "mg", name: "Malagasy", nativeName: "Malagasy", coachReasoning: "" },
    { id: 100, code: "ms", flagCode: "id", name: "Malay", nativeName: " Melayu", coachReasoning: "" },
    { id: 101, code: "ml", flagCode: "in", name: "Malayalam", nativeName: "മലയാളം", coachReasoning: "" },
    { id: 102, code: "mt", flagCode: "mt", name: "Maltese", nativeName: "Malti", coachReasoning: "" },
    { id: 103, code: "mi", flagCode: "nz", name: "Māori", nativeName: "Māori", coachReasoning: "" },
    { id: 104, code: "mr", flagCode: "in", name: "Marathi", nativeName: "मराठी", coachReasoning: "" },
    { id: 105, code: "mh", flagCode: "mh", name: "Marshallese", nativeName: "Kajin M̧ajeļ", coachReasoning: "" },
    { id: 106, code: "mn", flagCode: "mn", name: "Mongolian", nativeName: "монгол", coachReasoning: "" },
    { id: 107, code: "na", flagCode: "nr", name: "Nauru", nativeName: "Naoero", coachReasoning: "" },
    { id: 108, code: "nv", flagCode: "us", name: "Navajo Navaho", nativeName: "Diné bizaad Dinékʼehǰí", coachReasoning: "" },
    { id: 110, code: "nd", flagCode: "zw", name: "Ndebele", nativeName: "IsiNdebele", coachReasoning: "" },
    { id: 111, code: "ne", flagCode: "np", name: "Nepali", nativeName: "नेपाली", coachReasoning: "" },
    { id: 112, code: "ng", flagCode: "na", name: "Ndonga", nativeName: "Owambo", coachReasoning: "" },
    { id: 114, code: "no", flagCode: "no", name: "Norwegian", nativeName: "Norsk", coachReasoning: "" },
    { id: 115, code: "ii", flagCode: "cn", name: "Nuosu", nativeName: "Nuosuhxop", coachReasoning: "" },
    { id: 117, code: "oc", flagCode: "es", name: "Occitan", nativeName: "Occitan", coachReasoning: "" },
    { id: 118, code: "oj", flagCode: "ca", name: "Ojibwe Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ", coachReasoning: "" },
    // { id: 119, code: "cu", flagCode: "cu", name: "Slavic", nativeName: "словѣньскъ", coachReasoning: ""},
    { id: 120, code: "om", flagCode: "et", name: "Oromo", nativeName: "Oromoo", coachReasoning: "" },
    { id: 121, code: "or", flagCode: "in", name: "Oriya", nativeName: "ଓଡ଼ିଆ", coachReasoning: "" },
    { id: 122, code: "os", flagCode: "ru", name: "Ossetian", nativeName: "ирон æвзаг", coachReasoning: "" },
    { id: 123, code: "pa", flagCode: "pk", name: "Punjabi", nativeName: "ਪੰਜਾਬੀ پنجابی‎", coachReasoning: "" },
    // { id: 124, code: "pi", flagCode: "pi", name: "Pāli", nativeName: "पाऴि", coachReasoning: ""},
    { id: 125, code: "fa", flagCode: "ir", name: "Persian", nativeName: "فارسی", coachReasoning: "" },
    { id: 126, code: "pl", flagCode: "pl", name: "Polish", nativeName: "Polski", coachReasoning: "" },
    { id: 127, code: "ps", flagCode: "pk", name: "Pashto", nativeName: "پښتو", coachReasoning: "" },
    { id: 128, code: "pt", flagCode: "pt", name: "Portuguese", nativeName: "Português", coachReasoning: "" },
    { id: 129, code: "qu", flagCode: "pe", name: "Quechua", nativeName: "Kichwa", coachReasoning: "" },
    { id: 130, code: "rm", flagCode: "ch", name: "Romansh", nativeName: "Rumantsch", coachReasoning: "" },
    { id: 131, code: "rn", flagCode: "bi", name: "Kirundi", nativeName: "KiRundi", coachReasoning: "" },
    { id: 132, code: "ro", flagCode: "ro", name: "Romanian", nativeName: "Română", coachReasoning: "" },
    { id: 133, code: "ru", flagCode: "ru", name: "Russian", nativeName: "русский", coachReasoning: "" },
    { id: 134, code: "sa", flagCode: "in", name: "Sanskrit", nativeName: "संस्कृतम्", coachReasoning: "" },
    { id: 135, code: "sc", flagCode: "it", name: "Sardinian", nativeName: "Sardu", coachReasoning: "" },
    { id: 136, code: "sd", flagCode: "pk", name: "Sindhi", nativeName: "सिन्धी سنڌي سندھی‎", coachReasoning: "" },
    { id: 137, code: "se", flagCode: "no", name: "Northern Sami", nativeName: "Davvisámegiella", coachReasoning: "" },
    { id: 138, code: "sm", flagCode: "ws", name: "Samoan", nativeName: "Faa Samoa", coachReasoning: "" },
    { id: 139, code: "sg", flagCode: "cf", name: "Sango", nativeName: "yângâ tî sängö", coachReasoning: "" },
    { id: 140, code: "sr", flagCode: "rs", name: "Serbian", nativeName: "српски", coachReasoning: "" },
    { id: 141, code: "gd", flagCode: "gb", name: "Gaelic", nativeName: "Gàidhlig", coachReasoning: "" },
    { id: 142, code: "sn", flagCode: "zw", name: "Shona", nativeName: "ChiShona", coachReasoning: "" },
    { id: 143, code: "si", flagCode: "lk", name: "Sinhala", nativeName: "සිංහල", coachReasoning: "" },
    { id: 144, code: "sk", flagCode: "sk", name: "Slovak", nativeName: "Slovenčina", coachReasoning: "" },
    { id: 145, code: "sl", flagCode: "si", name: "Slovene", nativeName: "Slovenščina", coachReasoning: "" },
    { id: 146, code: "so", flagCode: "so", name: "Somali", nativeName: "Af Soomaali", coachReasoning: "" },
    { id: 147, code: "st", flagCode: "za", name: "Southern Sotho", nativeName: "Sesotho", coachReasoning: "" },
    { id: 148, code: "es", flagCode: "es", name: "Spanish", nativeName: "Español", coachReasoning: "" },
    { id: 149, code: "su", flagCode: "id", name: "Sundanese", nativeName: "Basa Sunda", coachReasoning: "" },
    { id: 150, code: "sw", flagCode: "tz", name: "Swahili", nativeName: "Kiswahili", coachReasoning: "" },
    { id: 151, code: "ss", flagCode: "za", name: "Swati", nativeName: "SiSwati", coachReasoning: "" },
    { id: 152, code: "sv", flagCode: "se", name: "Swedish", nativeName: "Svenska", coachReasoning: "" },
    { id: 153, code: "ta", flagCode: "in", name: "Tamil", nativeName: "தமிழ்", coachReasoning: "" },
    { id: 154, code: "te", flagCode: "in", name: "Telugu", nativeName: "తెలుగు", coachReasoning: "" },
    { id: 155, code: "tg", flagCode: "tj", name: "Tajik", nativeName: "тоҷикӣ toğikī تاجیکی‎", coachReasoning: "" },
    { id: 156, code: "th", flagCode: "th", name: "Thai", nativeName: "ไทย", coachReasoning: "" },
    { id: 157, code: "ti", flagCode: "er", name: "Tigrinya", nativeName: "ትግርኛ", coachReasoning: "" },
    { id: 158, code: "bo", flagCode: "cn", name: "Tibetan", nativeName: "བོད་ཡིག", coachReasoning: "" },
    { id: 159, code: "tk", flagCode: "tm", name: "Turkmen", nativeName: "Türkmen Түркмен", coachReasoning: "" },
    { id: 160, code: "tl", flagCode: "ph", name: "Tagalog", nativeName: "Wikang Tagalog", coachReasoning: "" },
    { id: 161, code: "tn", flagCode: "bw", name: "Tswana", nativeName: "Setswana", coachReasoning: "" },
    { id: 162, code: "to", flagCode: "to", name: "Tonga", nativeName: "Faka Tonga", coachReasoning: "" },
    { id: 163, code: "tr", flagCode: "tr", name: "Turkish", nativeName: "Türkçe", coachReasoning: "" },
    { id: 164, code: "ts", flagCode: "za", name: "Tsonga", nativeName: "Xitsonga", coachReasoning: "" },
    { id: 165, code: "tt", flagCode: "ru", name: "Tatar", nativeName: "татарча tatarça تاتارچا‎", coachReasoning: "" },
    { id: 166, code: "tw", flagCode: "gh", name: "Twi", nativeName: "Twi", coachReasoning: "" },
    { id: 167, code: "ty", flagCode: "pf", name: "Tahitian", nativeName: "Reo Tahiti", coachReasoning: "" },
    { id: 168, code: "ug", flagCode: "cn", name: "Uyghur", nativeName: "Uyƣurqə ئۇيغۇرچە‎", coachReasoning: "" },
    { id: 169, code: "uk", flagCode: "ua", name: "Ukrainian", nativeName: "українська", coachReasoning: "" },
    { id: 170, code: "ur", flagCode: "pk", name: "Urdu", nativeName: "اردو", coachReasoning: "" },
    { id: 171, code: "uz", flagCode: "uz", name: "Uzbek", nativeName: "Zbek Ўзбек أۇزبېك‎", coachReasoning: "" },
    { id: 172, code: "ve", flagCode: "za", name: "Venda", nativeName: "Tshivenḓa", coachReasoning: "" },
    { id: 173, code: "vi", flagCode: "vn", name: "Vietnamese", nativeName: "Việt", coachReasoning: "" },
    // { id: 174, code: "vo", flagCode: "vo", name: "Volapük", nativeName: "Volapük", coachReasoning: ""},
    { id: 175, code: "wa", flagCode: "be", name: "Walloon", nativeName: "Walon", coachReasoning: "" },
    { id: 176, code: "cy", flagCode: "gb", name: "Welsh", nativeName: "Cymraeg", coachReasoning: "" },
    { id: 177, code: "wo", flagCode: "sn", name: "Wolof", nativeName: "Wollof", coachReasoning: "" },
    { id: 178, code: "fy", flagCode: "nl", name: "Frisian", nativeName: "Frysk", coachReasoning: "" },
    { id: 179, code: "xh", flagCode: "za", name: "Xhosa", nativeName: "IsiXhosa", coachReasoning: "" },
    { id: 180, code: "yi", flagCode: "ru", name: "Yiddish", nativeName: "ייִדיש", coachReasoning: "" },
    { id: 181, code: "yo", flagCode: "ng", name: "Yoruba", nativeName: "Yorùbá", coachReasoning: "" },
    { id: 182, code: "za", flagCode: "cn", name: "Zhuang Chuang", nativeName: "Saɯ cueŋƅ, Saw cuengh", coachReasoning: "" },
    { id: 183, code: "in", flagCode: "in", name: "Hindi", nativeName: "हिन्दी हिंदी", coachReasoning: "" },
    // { id: 184, code: "gb", flagCode: "gb", name: "English", nativeName: "English", coachReasoning: ""},
    { id: 185, code: "gr", flagCode: "gr", name: "Greek", nativeName: "Ελληνικά", coachReasoning: "" },
];
export const PAYMENT_PROVIDERS = ['Cellulant', 'Mollie'];
export const CURRENCY_CODE = ['EUR', 'USD', 'KES'];
// export const TRANSLATED_LOGINS = ["Customer Care", "Notification(s)"];
export const TRANSLATED_LOGINS = ["Customer Care"];
export const CUSTOMER_CARE_CANNED_RESPONSES = {
    en: [
        {
            "tag": "coach",
            "text": "I would like to set you up with one of our certified coaches who will take you through a well-being scan and chat with you about what you are going through. " +
                "Would you be open to that?",
            "meaning": "I would like to set you up with one of our certified coaches who will take you through a well-being scan and chat with you about what you are going through. " +
                "Would you be open to that?",
        },
        {
            "tag": "time",
            "text": "When would you be available for a 60 minutes chat?",
            "meaning": "When would you be available for a 60 minutes chat?"
        },
        {
            "tag": "coach-set-up",
            "text": "I have found you a coach!\r\n" +
                "Please click on the link I send you next and your coach will be with you at the agreed time." +
                // "<br>" +
                "\r\n" +
                "Enjoy your session. 😊",
            "meaning": "I have found you a coach!\r\n" +
                "Please click on the link I send you next and your coach will be with you at the agreed time." +
                // "<br>" +
                "\r\n" +
                "Enjoy your session. 😊"
        },
        {
            "tag": "ke-payment",
            "text": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/5d2qlq8dtwjm' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/5d2qlq8dtwjm\" " +
                "to pay for 1 Inuka session at KSH 625." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/us35jwd74yvg' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/us35jwd74yvg\" " +
                "to pay for 4 Inuka sessions at KSH 2250." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you.",
            "meaning": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/5d2qlq8dtwjm' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/5d2qlq8dtwjm\" " +
                "to pay for 1 Inuka session at KSH 625." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/us35jwd74yvg' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/us35jwd74yvg\" " +
                "to pay for 4 Inuka sessions at KSH 2250." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you."
        },
        {
            "tag": "ngn-payment",
            "text": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/j04v3qbjozu6' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/j04v3qbjozu6\" " +
                "to pay for 1 Inuka session at NGN 2200." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/0owa8puv9lem' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/0owa8puv9lem\" " +
                "to pay for 4 Inuka sessions at NGN 7950." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you.",
            "meaning": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/j04v3qbjozu6' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/j04v3qbjozu6\" " +
                "to pay for 1 Inuka session at NGN 2200." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/0owa8puv9lem' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/0owa8puv9lem\" " +
                "to pay for 4 Inuka sessions at NGN 7950." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you."
        },
        {
            "tag": "ugx-payment",
            "text": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/nc1wx3oglvdw' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/nc1wx3oglvdw\" " +
                "to pay for 1 Inuka session at UGX 21,500." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/wr4ddl19jnex' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/wr4ddl19jnex\" " +
                "to pay for 4 Inuka sessions at UGX 76,870." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you.",
            "meaning": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/nc1wx3oglvdw' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/nc1wx3oglvdw\" " +
                "to pay for 1 Inuka session at UGX 21,500." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/wr4ddl19jnex' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/wr4ddl19jnex\" " +
                "to pay for 4 Inuka sessions at UGX 76,870." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you."
        },
        {
            "tag": "tc-1",
            "text": "Let me find you an available coach. Meanwhile, please go through Inuka's terms and conditions:" +
                // "<br>" +
                "\r\n" +
                "1. You agree you are 18 years or older and that you are not in crisis, homicidal, suicidal, abusing anyone or posing a threat to yourself or others." +
                // "<br>" +
                "\r\n" +
                "2. You agree that payment for use of Inuka services is done in advance of the coaching session." +
                // "<br>" +
                "\r\n" +
                "3. You also acknowledge that reimbursement for a coaching session is only done in case of a no-show of the Guide, and only if you flag the session.",
            "meaning": "Let me find you an available coach. Meanwhile, please go through Inuka's terms and conditions:" +
                // "<br>" +
                "\r\n" +
                "1. You agree you are 18 years or older and that you are not in crisis, homicidal, suicidal, abusing anyone or posing a threat to yourself or others." +
                // "<br>" +
                "\r\n" +
                "2. You agree that payment for use of Inuka services is done in advance of the coaching session." +
                // "<br>" +
                "\r\n" +
                "3. You also acknowledge that reimbursement for a coaching session is only done in case of a no-show of the Guide, and only if you flag the session."
        },
        {
            "tag": "tc-2",
            "text": "4. Your privacy is highly valued. We abide by the strict European General Data Protection Regulation." +
                // "<br>" +
                "\r\n" +
                "5. We will always ask for your consent before collecting your data. We only ask you for the personal information we need to offer the best service possible.",
            "meaning": "4. Your privacy is highly valued. We abide by the strict European General Data Protection Regulation." +
                // "<br>" +
                "\r\n" +
                "5. We will always ask for your consent before collecting your data. We only ask you for the personal information we need to offer the best service possible."
        },
        {
            "tag": "tc-3",
            "text": "6. We shield your data from anyone or anything (including ourselves) who doesn’t need to see it. " +
                "For example, the coaches and their supervisors only see the chat and self-scan results, but do not have access to your email or phone number, unless you share it with them.",
            "meaning": "6. We shield your data from anyone or anything (including ourselves) who doesn’t need to see it. " +
                "For example, the coaches and their supervisors only see the chat and self-scan results, but do not have access to your email or phone number, unless you share it with them."
        },
        {
            "tag": "tc-4",
            "text": "7. Inuka may disclose the Client’s non-anonymous information to third parties if Inuka reasonably believes that disclosure is necessary in order to comply with the law, or if there's a suspicion of criminal activity. " +
                "Before the local authorities are contacted, a mental health professional is consulted, validating the suspicion. The Client is always informed of such disclosure.",
            "meaning": "7. Inuka may disclose the Client’s non-anonymous information to third parties if Inuka reasonably believes that disclosure is necessary in order to comply with the law, or if there's a suspicion of criminal activity. " +
                "Before the local authorities are contacted, a mental health professional is consulted, validating the suspicion. The Client is always informed of such disclosure."
        },
        {
            "tag": "tc-5",
            "text": "8. We constantly check and re-check all the preventive measures we have in place to protect your data. " +
                "We are honest and transparent about our activities concerning your data." +
                // "<br>" +
                "\r\n" +
                "9. We enable you to have full control over your data, regardless of the country you live in.",
            "meaning": "8. We constantly check and re-check all the preventive measures we have in place to protect your data. " +
                "We are honest and transparent about our activities concerning your data." +
                // "<br>" +
                "\r\n" +
                "9. We enable you to have full control over your data, regardless of the country you live in."
        },
        {
            "tag": "tc-6",
            "text": "10. We have an independent Data Protection Officer appointed to ensure independent, high-quality, specialized privacy measures." +
                // "<br>" +
                "\r\n" +
                "11. Please note: Facebook is able to see WhatsApp Meta-Data. We also don't guarantee privacy if you choose to use a modified version of WhatsApp.",
            "meaning": "10. We have an independent Data Protection Officer appointed to ensure independent, high-quality, specialized privacy measures." +
                // "<br>" +
                "\r\n" +
                "11. Please note: Facebook is able to see WhatsApp Meta-Data. We also don't guarantee privacy if you choose to use a modified version of WhatsApp."
        },
        {
            "tag": "tc-7",
            "text": "To read the full Terms and Conditions please " +
                // "click <a target='_blank' rel='noopener noreferrer' href='https://inuka.io/terms-and-conditions/' style='color: #44a38d'><b>here</b></a>.<br>" +
                "go to \"https://inuka.io/terms-and-conditions/\". " +
                "To read Privacy document please " +
                // "click <a target='_blank' rel='noopener noreferrer' href='https://inuka.io/privacy-policy/' style='color: #44a38d'><b>here</b></a>." +
                "go to \"https://inuka.io/privacy-policy/\". ",
            "meaning": "To read the full Terms and Conditions please " +
                // "click <a target='_blank' rel='noopener noreferrer' href='https://inuka.io/terms-and-conditions/' style='color: #44a38d'><b>here</b></a>.<br>" +
                "go to \"https://inuka.io/terms-and-conditions/\". " +
                "To read Privacy document please " +
                // "click <a target='_blank' rel='noopener noreferrer' href='https://inuka.io/privacy-policy/' style='color: #44a38d'><b>here</b></a>." +
                "go to \"https://inuka.io/privacy-policy/\". "
        },
        {
            "tag": "tc-8",
            "text": "Once you are done with going through the document, please let me know by sending a simple \"Read\" and I will proceed with setting you up with a coach. " +
                "Feel free to ask any question. 🙂",
            "meaning": "Once you are done with going through the document, please let me know by sending a simple \"Read\" and I will proceed with setting you up with a coach. " +
                "Feel free to ask any question. 🙂"
        },
        {
            "tag": "FB1",
            "text": "Good day and thank you for your enquiry. The Friendship Bench is offering *FREE* therapy sessions through Whatsapp calls or WhatsApp messages. Our sessions are done from Monday - Friday 9am- 5pm.",
            "meaning": "Good day and thank you for your enquiry. The Friendship Bench is offering *FREE* therapy sessions through Whatsapp calls or WhatsApp messages. Our sessions are done from Monday - Friday 9am- 5pm."
        },
        {
            "tag": "FB2",
            "text": "We offer a maximum of 6 sessions per person. First sessions last for 45minutes to an hour and consequent sessions will last no longer than 45 minutes. ",
            "meaning": "We offer a maximum of 6 sessions per person. First sessions last for 45minutes to an hour and consequent sessions will last no longer than 45 minutes. "
        },
        {
            "tag": "FB3",
            "text": " *Please note you are allowed to reschedule for sessions not more than 2 times, the 3rd time you are sent back to the openline for re-booking* .",
            "meaning": " *Please note you are allowed to reschedule for sessions not more than 2 times, the 3rd time you are sent back to the openline for re-booking* ."
        },
        {
            "tag": "FB4",
            "text": "As Friendship Bench, *we are not here to provide solutions or advice* but rather *we are here to empower and support you so that you build your resilience and ability to tackle life’s inevitable challenges*.",
            "meaning": "As Friendship Bench, *we are not here to provide solutions or advice* but rather *we are here to empower and support you so that you build your resilience and ability to tackle life’s inevitable challenges*."
        },
        {
            "tag": "FB5",
            "text": "All bookings are made on this number, and when you feel that you are ready to book, we will send you our Terms of Service, which you are supposed to go through before one of our Friendship Bench Open liners gets in touch with you for your sessions.",
            "meaning": "All bookings are made on this number, and when you feel that you are ready to book, we will send you our Terms of Service, which you are supposed to go through before one of our Friendship Bench Open liners gets in touch with you for your sessions."
        },
        {
            "tag": "FB6",
            "text": "Thank you. Whilst we look for the next available slot, kindly let us know of how you got to know about our services: \r\n" +
                "- Facebook\r\n" +
                "- Instagram \r\n" +
                "- Twitter \r\n" +
                "- Website \r\n" +
                "- Friend \r\n" +
                "- Relative \r\n" +
                "- Radio\r\n" +
                "- Workplace - please state where \r\n" +
                "- School / University- please state where \r\n" +
                "- Any other - please specify.",
            "meaning": "Thank you. Whilst we look for the next available slot, kindly let us know of how you got to know about our services: \r\n" +
                "- Facebook\r\n" +
                "- Instagram \r\n" +
                "- Twitter \r\n" +
                "- Website \r\n" +
                "- Friend \r\n" +
                "- Relative \r\n" +
                "- Radio\r\n" +
                "- Workplace - please state where \r\n" +
                "- School / University- please state where \r\n" +
                "- Any other - please specify."
        },
        {
            "tag": "FBTC1",
            "text": "Please go through the Terms of Service that I am going to send and if you agree to what is in there, please send your age and gender as an acknowledgement, so that I confirm your booking",
            "meaning": "Please go through the Terms of Service that I am going to send and if you agree to what is in there, please send your age and gender as an acknowledgement, so that I confirm your booking"
        },
        {
            "tag": "FBTC2",
            "text": "Friendship Bench Terms of Service:\r\n" +
                "In exchange for participation in the Counselling Services offered at Friendship Bench, located at 4 Weale Road, Milton Park, Harare, I hereby agree as follows: ",
            "meaning": "Friendship Bench Terms of Service:\r\n" +
                "In exchange for participation in the Counselling Services offered at Friendship Bench, located at 4 Weale Road, Milton Park, Harare, I hereby agree as follows: "
        },
        {
            "tag": "FBTC3",
            "text": "1. I and anyone claiming on my behalf release and forever discharge Friendship Bench and its affiliates, successors and assigns, officers, employees, representatives, partners, agents and anyone claiming through them, in their individual and/or corporate capacities from causes of action of any nature and kind, known or unknown, which I may have against Friendship Bench arising out of or relating to any injury, loss or damage to person and property that may be sustained as a result of participation in the Counselling Services. ",
            "meaning": "1. I and anyone claiming on my behalf release and forever discharge Friendship Bench and its affiliates, successors and assigns, officers, employees, representatives, partners, agents and anyone claiming through them, in their individual and/or corporate capacities from causes of action of any nature and kind, known or unknown, which I may have against Friendship Bench arising out of or relating to any injury, loss or damage to person and property that may be sustained as a result of participation in the Counselling Services. "
        },
        {
            "tag": "FBTC4",
            "text": "2. I understand that participation in the Counselling Services involves inherent risks, including risk of physical or psychological injury, pain, suffering or illness, and I assume all related risks and voluntarily participate in the Activity.",
            "meaning": "2. I understand that participation in the Counselling Services involves inherent risks, including risk of physical or psychological injury, pain, suffering or illness, and I assume all related risks and voluntarily participate in the Activity."
        },
        {
            "tag": "FBTC5",
            "text": "3. I agree to indemnify Friendship Bench against any and all claims, actions, lawsuits, damages and judgments, including attorney’s fees, arising out of or relating to my participation in the Activity",
            "meaning": "3. I agree to indemnify Friendship Bench against any and all claims, actions, lawsuits, damages and judgments, including attorney’s fees, arising out of or relating to my participation in the Activity"
        },
        {
            "tag": "FBTC6",
            "text": "4. I understand that Friendship Bench does not offer me anything else other than Counselling Services. ",
            "meaning": "4. I understand that Friendship Bench does not offer me anything else other than Counselling Services. "
        },
        {
            "tag": "FBTC7",
            "text": "5. I understand that Friendship Bench is not in lieu of any medical treatment. The Counselling Services I will receive from Friendship Bench are not intended to be a substitute for professional medical advice, diagnosis, or treatment.",
            "meaning": "5. I understand that Friendship Bench is not in lieu of any medical treatment. The Counselling Services I will receive from Friendship Bench are not intended to be a substitute for professional medical advice, diagnosis, or treatment."
        },
        {
            "tag": "FBTC8",
            "text": "6. I understand that Friendship Bench is a research organization, and they can therefore use the data collected from their counselling services for their research. We will record sessions randomly for quality control, supervision purposes and research. We ask you to specifically tell us if you do not want your sessions recorded.",
            "meaning": "6. I understand that Friendship Bench is a research organization, and they can therefore use the data collected from their counselling services for their research. We will record sessions randomly for quality control, supervision purposes and research. We ask you to specifically tell us if you do not want your sessions recorded."
        },
        {
            "tag": "FBTC9",
            "text": "7. I understand that the Customer Care Department from Friendship Bench can contact me with regards to my booking and sessions. ",
            "meaning": "7. I understand that the Customer Care Department from Friendship Bench can contact me with regards to my booking and sessions. "
        },
        {
            "tag": "FBTC10",
            "text": "8. I have carefully read and fully understand all the provisions of this Release and am freely, knowingly and voluntarily entering into this Release.  ",
            "meaning": "8. I have carefully read and fully understand all the provisions of this Release and am freely, knowingly and voluntarily entering into this Release.  "
        },
        {
            "tag": "FBTC11",
            "text": "Please send your age and gender as an acknowledgement of the Terms of Service of the Friendship Bench.",
            "meaning": "Please send your age and gender as an acknowledgement of the Terms of Service of the Friendship Bench."
        }
    ],
    ar: [
        {
            "tag": "coach",
            "text": "أودّ أن أضعك على اتّصال مع أحد مدرّبينا المعتمدين، والذي سيقوم بدوره بمرافقتك أثناء إجراء فحص العافية، وسيجري معك دردشة حول ما تمرّ به. هل أنت مستعدّ لذلك؟",
            "meaning": "I would like to set you up with one of our certified coaches who will take you through a well-being scan and chat with you about what you are going through. " +
                "Would you be open to that?",
        },
        {
            "tag": "time",
            "text": "متى يمكنك أن تكون جاهزًا لإجراء جلسة دردشة مدّتها ساعة؟",
            "meaning": "When would you be available for a 60 minutes chat?"
        },
        {
            "tag": "coach-set-up",
            "text": "عثرت لك على المدرّب المناسب!\r\n" +
                "رجاء، اضغط على الرابط الذي سأرسله لك، وسيكون مدرّبك موجودًا لأجلك في الوقت المتّفق عليه." +
                // "<br>" +
                "\r\n" +
                "استمتع بالجلسة. 😊",
            "meaning": "I have found you a coach!\r\n" +
                "Please click on the link I send you next and your coach will be with you at the agreed time." +
                // "<br>" +
                "\r\n" +
                "Enjoy your session. 😊"
        },
        {
            "tag": "ke-payment",
            "text": "إليك الروابط التي يمكنك الدفع من خلالها:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/5d2qlq8dtwjm' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/5d2qlq8dtwjm\" " +
                "to pay for 1 Inuka session at KSH 625." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/us35jwd74yvg' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/us35jwd74yvg\" " +
                "to pay for 4 Inuka sessions at KSH 2250." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you.",
            "meaning": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/5d2qlq8dtwjm' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/5d2qlq8dtwjm\" " +
                "to pay for 1 Inuka session at KSH 625." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/us35jwd74yvg' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/us35jwd74yvg\" " +
                "to pay for 4 Inuka sessions at KSH 2250." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you."
        },
        {
            "tag": "ngn-payment",
            "text": "إليك الروابط التي يمكنك الدفع من خلالها:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/j04v3qbjozu6' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/j04v3qbjozu6\" " +
                "to pay for 1 Inuka session at NGN 2200." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/0owa8puv9lem' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/0owa8puv9lem\" " +
                "to pay for 4 Inuka sessions at NGN 7950." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you.",
            "meaning": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/j04v3qbjozu6' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/j04v3qbjozu6\" " +
                "to pay for 1 Inuka session at NGN 2200." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/0owa8puv9lem' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/0owa8puv9lem\" " +
                "to pay for 4 Inuka sessions at NGN 7950." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you."
        },
        {
            "tag": "ugx-payment",
            "text": "إليك الروابط التي يمكنك الدفع من خلالها:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/nc1wx3oglvdw' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/nc1wx3oglvdw\" " +
                "to pay for 1 Inuka session at UGX 21,500." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/wr4ddl19jnex' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/wr4ddl19jnex\" " +
                "to pay for 4 Inuka sessions at UGX 76,870." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you.",
            "meaning": "Here are our payment links:" +
                // "<br><br>" +
                "\r\n\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/nc1wx3oglvdw' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/nc1wx3oglvdw\" " +
                "to pay for 1 Inuka session at UGX 21,500." +
                // "<br>" +
                "\r\n" +
                // "Click <a target='_blank' rel='noopener noreferrer' href='https://flutterwave.com/pay/wr4ddl19jnex' style='color: #44a38d'><b>here</b></a> " +
                "Go to \"https://flutterwave.com/pay/wr4ddl19jnex\" " +
                "to pay for 4 Inuka sessions at UGX 76,870." +
                // "<br><br>" +
                "\r\n\r\n" +
                "Do send the message you get to verify the transaction. Thank you."
        },
        {
            "tag": "tc-1",
            "text": "أمهلني بعض الوقت لأعثر لك على مدرّب مُتاح. في الأثناء، أرجو منك الاطّلاع على الشروط والأحكام التي نلتزم بها في إنوكا:" +
                // "<br>" +
                "\r\n" +
                "1. أنت توافق على أنّك تبلغ من العمر 18 عامًا أو أكثر، وأنّك لا تمرّ بأزمة، ولم ترتكب جريمة، وليست لديك رغبة في الانتحار، ولا تسيء معاملة أحد، ولا تشكّل خطرًا على نفسك أو الآخرين." +
                // "<br>" +
                "\r\n" +
                "2. أنت توافق على أنّك ستدفع مقابل خدمات إنوكا مقدّمًا قبل جلسة التدريب." +
                // "<br>" +
                "\r\n" +
                "3. أنت تقرّ أنّك لن تطلب استعادة تكلفة الجلسة إلّا في حال عدم حضور المدرّب، أو في حال قمت بوضع علامة على الجلسة.",
            "meaning": "Let me find you an available coach. Meanwhile, please go through Inuka's terms and conditions:" +
                // "<br>" +
                "\r\n" +
                "1. You agree you are 18 years or older and that you are not in crisis, homicidal, suicidal, abusing anyone or posing a threat to yourself or others." +
                // "<br>" +
                "\r\n" +
                "2. You agree that payment for use of Inuka services is done in advance of the coaching session." +
                // "<br>" +
                "\r\n" +
                "3. You also acknowledge that reimbursement for a coaching session is only done in case of a no-show of the Guide, and only if you flag the session."
        },
        {
            "tag": "tc-2",
            "text": "4. نحن نحترم خصوصيّتك جيّدًا، ونلتزم بمعايير مجلس النظام الأوروبي العام لحماية البيانات." +
                // "<br>" +
                "\r\n" +
                "5. سنطلب موافقتك دائمًا قبل الحصول على بياناتك. نحن نطلب معلوماتك الشخصيّة التي نحتاج إليها فقط لنوفّر لك أفضل خدمة ممكنة.",
            "meaning": "4. Your privacy is highly valued. We abide by the strict European General Data Protection Regulation." +
                // "<br>" +
                "\r\n" +
                "5. We will always ask for your consent before collecting your data. We only ask you for the personal information we need to offer the best service possible."
        },
        {
            "tag": "tc-3",
            "text": "6. نحن نحمي بياناتك من كل شيء وكل شخص لا يحتاج إلى الاطّلاع عليها (بمن في ذلك نحن). فعلى سبيل المثال، يطّلع المدرّبون والمشرفون عليهم على الدردشة ونتائج فحص العافية فقط، ولكن ليست لديهم الصلاحيّة في الوصول إلى بريدك الإلكتروني أو رقم هاتفك، إلّا إذا قمت أنت بمشاركتهم هذه المعلومات.",
            "meaning": "6. We shield your data from anyone or anything (including ourselves) who doesn’t need to see it. " +
                "For example, the coaches and their supervisors only see the chat and self-scan results, but do not have access to your email or phone number, unless you share it with them."
        },
        {
            "tag": "tc-4",
            "text": "7. قد تضطرّ إنوكا على الإفصاح عن معلومات العميل غير السريّة إلى أطراف ثالثة، في حال شعرت أنّ هناك ضرورة لمثل هذا الإجراء امتثالًا للقوانين، أو في حال كان هناك اشتباه في وجود أي نشاط إجرامي. وقبل الاتّصال بالسلطات المحليّة، ستقوم إنوكا باستشارة أخصائي الصحّة النفسيّة للتحقّق من صحّة الاشتباه، ويتمّ إبلاغ العميل بالكشف عن معلوماته للسلطات المعنيّة.",
            "meaning": "7. Inuka may disclose the Client’s non-anonymous information to third parties if Inuka reasonably believes that disclosure is necessary in order to comply with the law, or if there's a suspicion of criminal activity. " +
                "Before the local authorities are contacted, a mental health professional is consulted, validating the suspicion. The Client is always informed of such disclosure."
        },
        {
            "tag": "tc-5",
            "text": "8. We constantly check and re-check all the preventive measures we have in place to protect your data. We are honest and transparent about our activities concerning your data." +
                // "<br>" +
                "\r\n" +
                "9. نحن نتحقّق بشكل دوري من التدابير الوقائيّة التي نطبّقها لحماية بياناتك، ونتصرّف بصدق وشفافيّة عاليَين حيال النشاطات المتعلّقة بها.",
            "meaning": "8. We constantly check and re-check all the preventive measures we have in place to protect your data. " +
                "We are honest and transparent about our activities concerning your data." +
                // "<br>" +
                "\r\n" +
                "9. We enable you to have full control over your data, regardless of the country you live in."
        },
        {
            "tag": "tc-6",
            "text": "10. لدينا في إنوكا مسؤول حماية بيانات مستقلّ تمّ تعيينه لضمان تطبيق إجراءات خصوصيّة مستقلّة ومتخصّصة وعالية الجودة." +
                // "<br>" +
                "\r\n" +
                "11. يُرجى الانتباه إلى أنّ فيسبوك قادر على رؤية وقراءة بيانات الواتساب، كما أنّه لا يمكننا ضمان خصوصيّة بياناتك في حال اخترت استخدام نسخة معدّلة من واتساب.",
            "meaning": "10. We have an independent Data Protection Officer appointed to ensure independent, high-quality, specialized privacy measures." +
                // "<br>" +
                "\r\n" +
                "11. Please note: Facebook is able to see WhatsApp Meta-Data. We also don't guarantee privacy if you choose to use a modified version of WhatsApp."
        },
        {
            "tag": "tc-7",
            "text": "لقراءة كامل الشروط والأحكام، يُرجى الذهاب إلى ‘https://inuka.io/terms-and-conditions/'، ولقراءة مستند الخصويّة، يُرجى الذهاب إلى ‘https://inuka.io/privacy-policy/’.",
            "meaning": "To read the full Terms and Conditions please " +
                // "click <a target='_blank' rel='noopener noreferrer' href='https://inuka.io/terms-and-conditions/' style='color: #44a38d'><b>here</b></a>.<br>" +
                "go to \"https://inuka.io/terms-and-conditions/\". " +
                "To read Privacy document please " +
                // "click <a target='_blank' rel='noopener noreferrer' href='https://inuka.io/privacy-policy/' style='color: #44a38d'><b>here</b></a>." +
                "go to \"https://inuka.io/privacy-policy/\". "
        },
        {
            "tag": "tc-8",
            "text": "عند انتهائك من قراءة المستند، الرجاء إبلاغي بذلك بمجرّد إرسال \"تمّت القراءة\"، وسأكمل من طرفي عمليّة وصلك بالمدرّب. رجاءً، لا تتردّد في طرح أي سؤال. 🙂",
            "meaning": "Once you are done with going through the document, please let me know by sending a simple \"Read\" and I will proceed with setting you up with a coach. " +
                "Feel free to ask any question. 🙂"
        }
    ]
};
export const NEW_CLIENTS_INTERVALS = ["EACH_7_DAYS", "EACH_30_DAYS"];
export const DAYS_OF_THE_WEEK = ["monday" /* monday */, "tuesday" /* tuesday */, "wednesday" /* wednesday */, "thursday" /* thursday */, "friday" /* friday */, "saturday" /* saturday */, "sunday" /* sunday */];
export const COACH_LABELS = [
    { name: "psychologist", en: "Psychologist", de: "Psychologe", nl: "Psycholoog", icon: "user-graduate" },
    { name: "colleague", en: "Your colleague", de: "Dein Kollege", nl: "Je collega", icon: "user-tie" },
];
export function debug(msg, category) {
    if (DEBUG_INFO_ENABLED || localStorage.getItem("debug") === "true") {
        category = category !== undefined ? "[" + category + "]" : "";
        console.log(category + msg);
    }
}
