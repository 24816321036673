import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "@angular/common";
import * as i3 from "../organization-looking-for-help-topics-count/organization-looking-for-help-topics-count.component";
import * as i4 from "../organization-extra-question-answers/organization-extra-question-answers.component";
import * as i5 from "../organization-free-text-extra-question-answers/organization-free-text-extra-question-answers.component";
function OrganizationInsightsDashboardComponent_ng_container_0_div_5_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "organization-extra-question-answers", 5);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const extraQuestionAnswers_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("extraQuestionAnswers", extraQuestionAnswers_r2);
} }
function OrganizationInsightsDashboardComponent_ng_container_0_div_5_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "organization-free-text-extra-question-answers", 5);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const extraQuestionAnswers_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("extraQuestionAnswers", extraQuestionAnswers_r2);
} }
function OrganizationInsightsDashboardComponent_ng_container_0_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, OrganizationInsightsDashboardComponent_ng_container_0_div_5_ng_container_1_Template, 2, 1, "ng-container", 0);
    i0.ɵɵtemplate(2, OrganizationInsightsDashboardComponent_ng_container_0_div_5_ng_container_2_Template, 2, 1, "ng-container", 0);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const extraQuestionAnswers_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", extraQuestionAnswers_r2.extraQuestion.type !== "FREE_TEXT");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", extraQuestionAnswers_r2.extraQuestion.type === "FREE_TEXT");
} }
function OrganizationInsightsDashboardComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵelementContainerStart(3);
    i0.ɵɵelement(4, "organization-looking-for-help-topics-count", 3);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, OrganizationInsightsDashboardComponent_ng_container_0_div_5_Template, 3, 2, "div", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.report.extraQuestionAnswers);
} }
export class OrganizationInsightsDashboardComponent {
    constructor(organizationDashboardService) {
        this.organizationDashboardService = organizationDashboardService;
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
    }
    loadReport() {
        this.organizationDashboardService.getOrganizationExtraQuestionsReport(this.parameters).subscribe(response => {
            this.report = response.body;
        });
    }
}
OrganizationInsightsDashboardComponent.ɵfac = function OrganizationInsightsDashboardComponent_Factory(t) { return new (t || OrganizationInsightsDashboardComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService)); };
OrganizationInsightsDashboardComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationInsightsDashboardComponent, selectors: [["organization-insights-dashboard"]], inputs: { parameters: "parameters" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [1, "d-flex", "justify-content-between", "reports-container"], [1, "report-card"], [3, "parameters"], ["class", "report-card", 4, "ngFor", "ngForOf"], [3, "extraQuestionAnswers"]], template: function OrganizationInsightsDashboardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, OrganizationInsightsDashboardComponent_ng_container_0_Template, 6, 2, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.report);
    } }, directives: [i2.NgIf, i3.OrganizationLookingForHelpTopicsCountComponent, i2.NgForOf, i4.OrganizationExtraQuestionAnswersComponent, i5.OrganizationFreeTextExtraQuestionAnswersComponent], styles: [".reports-container[_ngcontent-%COMP%]{gap:20px;flex-wrap:wrap}.report-card[_ngcontent-%COMP%]{width:100%}"] });
