export class SessionCreateDTO {
    constructor(coachId, selectedDate, hour, isCoach, selectedUserId, type, selectedDateString, langKey, duration) {
        this.coachId = coachId;
        this.selectedDate = selectedDate;
        this.dateString = selectedDate.format('DD/MM/YYYY');
        this.userId = selectedUserId;
        this.type = type;
        this.selectedDateString = selectedDateString;
        this.langKey = langKey;
        // let hourParts = hour.split(":");
        // this.sessionStartTime = selectedDate.startOf("day").add(hourParts[0], "hour").add(hourParts[1], "minutes").add();
        this.sessionStartTime = selectedDate;
        this.isCoach = isCoach;
        this.duration = duration;
    }
    getCoachId() {
        return this.coachId;
    }
    getUserId() {
        return this.userId;
    }
    getDateString() {
        return this.dateString;
    }
}
