import { JhiAlertService } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/service";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
function AlertComponent_div_1_ngb_alert_1_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ngb-alert", 4);
    i0.ɵɵlistener("close", function AlertComponent_div_1_ngb_alert_1_Template_ngb_alert_close_0_listener() { i0.ɵɵrestoreView(_r4); const alert_r1 = i0.ɵɵnextContext().$implicit; const ctx_r3 = i0.ɵɵnextContext(); return alert_r1.close(ctx_r3.alerts); });
    i0.ɵɵelement(1, "pre", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const alert_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("type", alert_r1.type);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHTML", alert_r1.msg, i0.ɵɵsanitizeHtml);
} }
function AlertComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, AlertComponent_div_1_ngb_alert_1_Template, 2, 2, "ngb-alert", 3);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const alert_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.setClasses(alert_r1));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", alert_r1 && alert_r1.type && alert_r1.msg);
} }
export class AlertComponent {
    constructor(alertService) {
        this.alertService = alertService;
        this.alerts = [];
    }
    ngOnInit() {
        this.alerts = this.alertService.get();
    }
    setClasses(alert) {
        const classes = { 'jhi-toast': Boolean(alert.toast) };
        if (alert.position) {
            return Object.assign(Object.assign({}, classes), { [alert.position]: true });
        }
        return classes;
    }
    ngOnDestroy() {
        this.alertService.clear();
    }
}
AlertComponent.ɵfac = function AlertComponent_Factory(t) { return new (t || AlertComponent)(i0.ɵɵdirectiveInject(i1.JhiAlertService)); };
AlertComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AlertComponent, selectors: [["jhi-alert"]], decls: 2, vars: 1, consts: [["hidden", "", "role", "alert", 1, "alerts"], [3, "ngClass", 4, "ngFor", "ngForOf"], [3, "ngClass"], [3, "type", "close", 4, "ngIf"], [3, "type", "close"], [3, "innerHTML"]], template: function AlertComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, AlertComponent_div_1_Template, 2, 2, "div", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.alerts);
    } }, directives: [i2.NgForOf, i2.NgClass, i2.NgIf, i3.NgbAlert], encapsulation: 2 });
