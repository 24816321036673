import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent } from '../../../../shared/base.component';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JhiLanguageService } from '../../../../ng-jhipster/language';
import { JhiEventManager } from '../../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../../ng-jhipster/language";
import * as i4 from "../../../../ng-jhipster/service";
import * as i5 from "../../organization-dashboard.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "@angular/common";
import * as i9 from "ngx-echarts";
const _c0 = function () { return { renderer: "svg" }; };
function OrganizationLookingForHelpTopicsCountComponent_ng_container_3_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 4);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r2.chartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
function OrganizationLookingForHelpTopicsCountComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, OrganizationLookingForHelpTopicsCountComponent_ng_container_3_ng_container_1_Template, 2, 3, "ng-container", 3);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.isLoadingData && (ctx_r0.chartOption == null ? null : ctx_r0.chartOption.series.length));
} }
function OrganizationLookingForHelpTopicsCountComponent_ng_container_4_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 6);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r3.leftChartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
function OrganizationLookingForHelpTopicsCountComponent_ng_container_4_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 6);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r4.rightChartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
function OrganizationLookingForHelpTopicsCountComponent_ng_container_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtemplate(2, OrganizationLookingForHelpTopicsCountComponent_ng_container_4_ng_container_2_Template, 2, 3, "ng-container", 3);
    i0.ɵɵtemplate(3, OrganizationLookingForHelpTopicsCountComponent_ng_container_4_ng_container_3_Template, 2, 3, "ng-container", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.isLoadingData && (ctx_r1.chartOption == null ? null : ctx_r1.chartOption.series.length));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r1.isLoadingData && (ctx_r1.chartOption == null ? null : ctx_r1.chartOption.series.length));
} }
export class OrganizationLookingForHelpTopicsCountComponent extends BaseComponent {
    constructor(sessionStorage, modalService, languageService, eventManager, organizationDashboardService, translate) {
        super(sessionStorage, modalService);
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.languageService = languageService;
        this.eventManager = eventManager;
        this.organizationDashboardService = organizationDashboardService;
        this.translate = translate;
        this.isLoadingData = false;
        this.currentLanguage = 'en';
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
        this.currentLanguage = this.languageService.getCurrentLanguage();
        this.eventManager.subscribe('langKeyChanged', () => {
            this.currentLanguage = this.languageService.getCurrentLanguage();
            this.refreshChartData();
        });
    }
    loadReport() {
        console.log(this.parameters);
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationLookingForHelpTopicsCountReport(this.parameters).subscribe(response => {
            this.report = response.body;
            console.log(this.report);
            this.rightReport = JSON.parse(JSON.stringify(this.report));
            this.leftReport = JSON.parse(JSON.stringify(this.report));
            let fullLength = this.report.organizationLookingForHelpTopicsCountList.length;
            let halfLength = Math.ceil(fullLength / 2);
            this.rightReport.organizationLookingForHelpTopicsCountList = this.rightReport.organizationLookingForHelpTopicsCountList.slice(0, halfLength);
            this.leftReport.organizationLookingForHelpTopicsCountList = this.leftReport.organizationLookingForHelpTopicsCountList.slice(halfLength, fullLength);
            this.max = this.report.organizationLookingForHelpTopicsCountList[fullLength - 1].count;
            this.refreshChartData();
            this.isLoadingData = false;
        }, error => {
            this.isLoadingData = false;
        });
    }
    refreshChartData() {
        var _a, _b, _c;
        this.chartOption = {
            title: {
                show: false
            },
            grid: {
                top: 10,
                left: '5%',
                right: '5%',
                bottom: 60,
                containLabel: true,
                width: 'auto',
                height: 'auto'
            },
            legend: {
                show: false,
                orient: 'horizontal',
                bottom: 5,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            yAxis: {
                type: 'category',
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'",
                    overflow: 'truncate',
                    width: 300
                },
                axisPointer: {
                    show: false
                },
            },
            xAxis: {
                name: this.translate.instant("inukaNgApp.organizationAdmin.lookingForHelpTopicsCount.numberOfPeople"),
                nameLocation: 'middle',
                nameTextStyle: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                nameGap: 30,
                type: 'value',
                boundaryGap: [0, 0.01],
                minInterval: 1,
                splitLine: {
                    show: true,
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            series: [
                {
                    name: this.translate.instant("inukaNgApp.organizationAdmin.lookingForHelpTopicsCount.numberOfPeople"),
                    type: 'bar',
                    barCategoryGap: '10%',
                    color: '#44A38B',
                    encode: {
                        y: 'topic',
                        x: 'count' // refer dimensions name
                    },
                    animationDuration: 3000,
                    label: {
                        show: true,
                        color: '#000000',
                        fontFamily: "'Open Sans', 'sans-serif'",
                        position: 'outside'
                    },
                    itemStyle: {
                        borderRadius: [0, 4, 4, 0]
                    },
                    barWidth: 15,
                }
            ]
        };
        const dateset = [];
        if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.organizationLookingForHelpTopicsCountList) {
            for (const reportItem of this.report.organizationLookingForHelpTopicsCountList) {
                dateset.push([
                    reportItem.topic[this.currentLanguage],
                    reportItem.count
                ]);
            }
        }
        this.chartOption.dataset = {
            source: dateset,
            dimensions: ['topic', 'count']
        };
        this.rightChartOption = {
            title: {
                show: false
            },
            grid: {
                top: 10,
                left: '5%',
                right: '5%',
                bottom: 60,
                containLabel: true,
                width: 'auto',
                height: 'auto'
            },
            legend: {
                show: false,
                orient: 'horizontal',
                bottom: 5,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            yAxis: {
                type: 'category',
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'",
                    overflow: 'truncate',
                    width: 300
                },
                axisPointer: {
                    show: false
                },
            },
            xAxis: {
                name: this.translate.instant("inukaNgApp.organizationAdmin.lookingForHelpTopicsCount.numberOfPeople"),
                nameLocation: 'middle',
                nameTextStyle: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                nameGap: 30,
                type: 'value',
                boundaryGap: [0, 0.01],
                minInterval: 1,
                max: this.max + 1,
                splitLine: {
                    show: true,
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            series: [
                {
                    name: this.translate.instant("inukaNgApp.organizationAdmin.lookingForHelpTopicsCount.numberOfPeople"),
                    type: 'bar',
                    barCategoryGap: '10%',
                    color: '#44A38B',
                    encode: {
                        y: 'topic',
                        x: 'count' // refer dimensions name
                    },
                    animationDuration: 3000,
                    label: {
                        show: true,
                        color: '#000000',
                        fontFamily: "'Open Sans', 'sans-serif'",
                        position: 'outside'
                    },
                    itemStyle: {
                        borderRadius: [0, 4, 4, 0]
                    },
                    barWidth: 20,
                }
            ]
        };
        const rightDateset = [];
        if ((_b = this.rightReport) === null || _b === void 0 ? void 0 : _b.organizationLookingForHelpTopicsCountList) {
            for (const reportItem of this.rightReport.organizationLookingForHelpTopicsCountList) {
                rightDateset.push([
                    reportItem.topic[this.currentLanguage],
                    reportItem.count
                ]);
            }
        }
        this.rightChartOption.dataset = {
            source: rightDateset,
            dimensions: ['topic', 'count']
        };
        this.leftChartOption = {
            title: {
                show: false
            },
            grid: {
                top: 10,
                left: '5%',
                right: '5%',
                bottom: 60,
                containLabel: true,
                width: 'auto',
                height: 'auto'
            },
            legend: {
                show: false,
                orient: 'horizontal',
                bottom: 5,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            yAxis: {
                type: 'category',
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'",
                    overflow: 'truncate',
                    width: 300
                },
                axisPointer: {
                    show: false
                },
            },
            xAxis: {
                name: this.translate.instant("inukaNgApp.organizationAdmin.lookingForHelpTopicsCount.numberOfPeople"),
                nameLocation: 'middle',
                nameTextStyle: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                nameGap: 30,
                type: 'value',
                boundaryGap: [0, 0.01],
                minInterval: 1,
                max: this.max + 1,
                splitLine: {
                    show: true,
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            series: [
                {
                    name: this.translate.instant("inukaNgApp.organizationAdmin.lookingForHelpTopicsCount.numberOfPeople"),
                    type: 'bar',
                    barCategoryGap: '10%',
                    color: '#44A38B',
                    encode: {
                        y: 'topic',
                        x: 'count' // refer dimensions name
                    },
                    animationDuration: 3000,
                    label: {
                        show: true,
                        color: '#000000',
                        fontFamily: "'Open Sans', 'sans-serif'",
                        position: 'outside'
                    },
                    itemStyle: {
                        borderRadius: [0, 4, 4, 0]
                    },
                    barWidth: 20,
                }
            ]
        };
        const leftDateset = [];
        if ((_c = this.leftReport) === null || _c === void 0 ? void 0 : _c.organizationLookingForHelpTopicsCountList) {
            for (const reportItem of this.leftReport.organizationLookingForHelpTopicsCountList) {
                leftDateset.push([
                    reportItem.topic[this.currentLanguage],
                    reportItem.count
                ]);
            }
        }
        this.leftChartOption.dataset = {
            source: leftDateset,
            dimensions: ['topic', 'count']
        };
    }
}
OrganizationLookingForHelpTopicsCountComponent.ɵfac = function OrganizationLookingForHelpTopicsCountComponent_Factory(t) { return new (t || OrganizationLookingForHelpTopicsCountComponent)(i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i3.JhiLanguageService), i0.ɵɵdirectiveInject(i4.JhiEventManager), i0.ɵɵdirectiveInject(i5.OrganizationDashboardService), i0.ɵɵdirectiveInject(i6.TranslateService)); };
OrganizationLookingForHelpTopicsCountComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationLookingForHelpTopicsCountComponent, selectors: [["organization-looking-for-help-topics-count"]], inputs: { parameters: "parameters" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 5, vars: 2, consts: [[1, "d-flex", "flex-row", "flex-wrap", "justify-content-between", 2, "gap", "20px"], [1, "card", "dashboard-card", "p-2"], ["jhiTranslate", "inukaNgApp.organizationAdmin.lookingForHelpTopicsCount.header", 1, "m-3", "light-weight"], [4, "ngIf"], ["echarts", "", 2, "height", "460px", "width", "100%", 3, "options", "initOpts"], [1, "d-flex", "justify-content-between", "align-items-center"], ["echarts", "", 2, "height", "406px", "width", "100%", 3, "options", "initOpts"]], template: function OrganizationLookingForHelpTopicsCountComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelement(2, "h4", 2);
        i0.ɵɵtemplate(3, OrganizationLookingForHelpTopicsCountComponent_ng_container_3_Template, 2, 1, "ng-container", 3);
        i0.ɵɵtemplate(4, OrganizationLookingForHelpTopicsCountComponent_ng_container_4_Template, 4, 2, "ng-container", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.windowWidth < 1440);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.windowWidth >= 1440);
    } }, directives: [i7.JhiTranslateDirective, i8.NgIf, i9.NgxEchartsDirective], styles: [""] });
