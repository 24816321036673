export class CoachAvailabilityPreference {
    constructor(id, newClientsNumber, newClientsInterval, startDate, endDate, chatSessionsPerWeek, videoSessionsPerWeek, additionalNotes, frequencyLastUpdated, capacityLastUpdated, coach) {
        this.id = id;
        this.newClientsNumber = newClientsNumber;
        this.newClientsInterval = newClientsInterval;
        this.startDate = startDate;
        this.endDate = endDate;
        this.chatSessionsPerWeek = chatSessionsPerWeek;
        this.videoSessionsPerWeek = videoSessionsPerWeek;
        this.additionalNotes = additionalNotes;
        this.frequencyLastUpdated = frequencyLastUpdated;
        this.capacityLastUpdated = capacityLastUpdated;
        this.coach = coach;
    }
}
