import { EventEmitter } from '@angular/core';
import ZoomVideo from "@zoom/videosdk";
import { ZoomService } from '../zoom.service';
import * as i0 from "@angular/core";
import * as i1 from "../zoom.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/slider";
function AudioPreviewComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 9);
    i0.ɵɵelement(2, "use", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function AudioPreviewComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 9);
    i0.ɵɵelement(2, "use", 10);
    i0.ɵɵelement(3, "use", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
export class AudioPreviewComponent {
    constructor(zoomService) {
        this.zoomService = zoomService;
        this.isMute = false;
        this.onMuted = new EventEmitter();
        this.onVolumeChanged = new EventEmitter();
    }
    set deviceId(value) {
        if (value && value !== this.deviceId) {
            this._deviceId = value;
            if (this.localAudioTrack) {
                this.localAudioTrack.stop().then();
            }
            this.localAudioTrack = ZoomVideo.createLocalAudioTrack(this.deviceId);
            this.localAudioTrack.start().then(v => {
                console.log('volume');
                console.log(this.localAudioTrack.getCurrentVolume());
            });
        }
    }
    get deviceId() {
        return this._deviceId;
    }
    ngOnDestroy() {
        var _a;
        (_a = this.localAudioTrack) === null || _a === void 0 ? void 0 : _a.stop().then();
    }
    ngOnInit() {
    }
    previewMicrophoneButton() {
        this.localAudioTrack.unmute();
    }
    stopPreviewMicrophoneButton() {
        this.localAudioTrack.mute();
    }
    toggleMute() {
        this.isMute = !this.isMute;
        if (this.isMute) {
            this.zoomService.muteAudio();
        }
        else {
            this.zoomService.unmuteAudio();
        }
    }
    formatLabel(value) {
        return `${value}`;
    }
    updateValue(event) {
        this.onVolumeChanged.emit(event.value);
    }
}
AudioPreviewComponent.ɵfac = function AudioPreviewComponent_Factory(t) { return new (t || AudioPreviewComponent)(i0.ɵɵdirectiveInject(i1.ZoomService)); };
AudioPreviewComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AudioPreviewComponent, selectors: [["audio-preview"]], inputs: { deviceId: "deviceId" }, outputs: { onMuted: "onMuted", onVolumeChanged: "onVolumeChanged" }, decls: 11, vars: 7, consts: [[1, "w-100"], [1, "d-flex", "justify-content-center", "align-items-center"], ["id", "volume-toggle", 1, "m-1", 3, "click"], [4, "ngIf"], ["id", "volume-icon", 1, "m-1"], ["width", "17", "height", "17"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#microphone-2"], ["id", "volume-slider", 1, "m-1", "w-75", "d-none"], [1, "m-2", "w-100", "slider", 3, "disabled", "max", "min", "step", "displayWith"], ["width", "22", "height", "22"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#microphone-1"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#diagonal"]], template: function AudioPreviewComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵlistener("click", function AudioPreviewComponent_Template_div_click_2_listener() { return ctx.toggleMute(); });
        i0.ɵɵtemplate(3, AudioPreviewComponent_div_3_Template, 3, 0, "div", 3);
        i0.ɵɵtemplate(4, AudioPreviewComponent_div_4_Template, 4, 0, "div", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 4);
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(6, "svg", 5);
        i0.ɵɵelement(7, "use", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵnamespaceHTML();
        i0.ɵɵelementStart(8, "div", 7);
        i0.ɵɵelementStart(9, "mat-slider", 8);
        i0.ɵɵelement(10, "input");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.isMute);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isMute);
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("disabled", ctx.isMute)("max", 100)("min", 0)("step", 1)("displayWith", ctx.formatLabel);
    } }, directives: [i2.NgIf, i3.MatSlider], styles: ["[_nghost-%COMP%]     .slider .mat-slider-thumb{background-color:#8a9d98}[_nghost-%COMP%]     .slider .mat-slider-track-fill{background-color:#8a9d98}[_nghost-%COMP%]     .slider .mat-slider-track-background{background-color:#cce9e0}"] });
