import { JhiConfigService } from '../config.service';
import * as i0 from "@angular/core";
import * as i1 from "../config.service";
import * as i2 from "@angular/common";
import * as i3 from "../language/jhi-translate.directive";
function JhiItemCountComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtext(1, " /* [attr.translateValues] is used to get entire values in tests */ ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("translateValues", ctx_r0.i18nValues());
} }
function JhiItemCountComponent_ng_template_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtext(0);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate3(" Showing ", (ctx_r2.page - 1) * ctx_r2.itemsPerPage == 0 ? 1 : (ctx_r2.page - 1) * ctx_r2.itemsPerPage + 1, " - ", ctx_r2.page * ctx_r2.itemsPerPage < ctx_r2.total ? ctx_r2.page * ctx_r2.itemsPerPage : ctx_r2.total, " of ", ctx_r2.total, " items. ");
} }
/**
 * A component that will take care of item count statistics of a pagination.
 */
export class JhiItemCountComponent {
    constructor(config) {
        this.i18nEnabled = config.CONFIG_OPTIONS.i18nEnabled;
    }
    /**
     * "translate-values" JSON of the template
     */
    i18nValues() {
        const first = (this.page - 1) * this.itemsPerPage === 0 ? 1 : (this.page - 1) * this.itemsPerPage + 1;
        const second = this.page * this.itemsPerPage < this.total ? this.page * this.itemsPerPage : this.total;
        return {
            first,
            second,
            total: this.total
        };
    }
}
JhiItemCountComponent.ɵfac = function JhiItemCountComponent_Factory(t) { return new (t || JhiItemCountComponent)(i0.ɵɵdirectiveInject(i1.JhiConfigService)); };
JhiItemCountComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiItemCountComponent, selectors: [["jhi-item-count"]], inputs: { page: "page", total: "total", itemsPerPage: "itemsPerPage" }, decls: 3, vars: 2, consts: [["class", "info jhi-item-count", "jhiTranslate", "global.item-count", 3, "translateValues", 4, "ngIf", "ngIfElse"], ["class", "info jhi-item-count"], ["noI18n", ""], ["jhiTranslate", "global.item-count", 1, "info", "jhi-item-count", 3, "translateValues"]], template: function JhiItemCountComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, JhiItemCountComponent_div_0_Template, 2, 1, "div", 0);
        i0.ɵɵtemplate(1, JhiItemCountComponent_ng_template_1_Template, 1, 3, "ng-template", 1, 2, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        const _r1 = i0.ɵɵreference(2);
        i0.ɵɵproperty("ngIf", ctx.i18nEnabled)("ngIfElse", _r1);
    } }, directives: [i2.NgIf, i3.JhiTranslateDirective], encapsulation: 2 });
