import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i4 from "../../shared/alert/alert-error.component";
import * as i5 from "@fortawesome/angular-fontawesome";
const _c0 = function (a1) { return ["/session", a1, "view"]; };
function VideoCallDetailComponent_div_2_div_66_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "a", 21);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, ctx_r1.videoCall.session == null ? null : ctx_r1.videoCall.session.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r1.videoCall.session == null ? null : ctx_r1.videoCall.session.id);
} }
const _c1 = function (a1) { return ["/video-call", a1, "edit"]; };
function VideoCallDetailComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "h2");
    i0.ɵɵelementStart(2, "span", 3);
    i0.ɵɵtext(3, "Video Call");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "hr");
    i0.ɵɵelement(6, "jhi-alert-error");
    i0.ɵɵelementStart(7, "dl", 4);
    i0.ɵɵelementStart(8, "dt");
    i0.ɵɵelementStart(9, "span", 5);
    i0.ɵɵtext(10, "External Id");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "dd");
    i0.ɵɵelementStart(12, "span");
    i0.ɵɵtext(13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "dt");
    i0.ɵɵelementStart(15, "span", 6);
    i0.ɵɵtext(16, "External Session Key");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(17, "dd");
    i0.ɵɵelementStart(18, "span");
    i0.ɵɵtext(19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "dt");
    i0.ɵɵelementStart(21, "span", 7);
    i0.ɵɵtext(22, "Start Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(23, "dd");
    i0.ɵɵelementStart(24, "span");
    i0.ɵɵtext(25);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(26, "dt");
    i0.ɵɵelementStart(27, "span", 8);
    i0.ɵɵtext(28, "End Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(29, "dd");
    i0.ɵɵelementStart(30, "span");
    i0.ɵɵtext(31);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(32, "dt");
    i0.ɵɵelementStart(33, "span", 9);
    i0.ɵɵtext(34, "Status");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(35, "dd");
    i0.ɵɵelementStart(36, "span");
    i0.ɵɵtext(37);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(38, "dt");
    i0.ɵɵelementStart(39, "span", 10);
    i0.ɵɵtext(40, "Duration");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(41, "dd");
    i0.ɵɵelementStart(42, "span");
    i0.ɵɵtext(43);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(44, "dt");
    i0.ɵɵelementStart(45, "span", 11);
    i0.ɵɵtext(46, "Participants Count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(47, "dd");
    i0.ɵɵelementStart(48, "span");
    i0.ɵɵtext(49);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(50, "dt");
    i0.ɵɵelementStart(51, "span", 12);
    i0.ɵɵtext(52, "Has Video");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(53, "dd");
    i0.ɵɵelementStart(54, "span");
    i0.ɵɵtext(55);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(56, "dt");
    i0.ɵɵelementStart(57, "span", 13);
    i0.ɵɵtext(58, "Has Voip");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(59, "dd");
    i0.ɵɵelementStart(60, "span");
    i0.ɵɵtext(61);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(62, "dt");
    i0.ɵɵelementStart(63, "span", 14);
    i0.ɵɵtext(64, "Session");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(65, "dd");
    i0.ɵɵtemplate(66, VideoCallDetailComponent_div_2_div_66_Template, 3, 4, "div", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(67, "button", 15);
    i0.ɵɵlistener("click", function VideoCallDetailComponent_div_2_Template_button_click_67_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.previousState(); });
    i0.ɵɵelement(68, "fa-icon", 16);
    i0.ɵɵtext(69, "\u00A0");
    i0.ɵɵelementStart(70, "span", 17);
    i0.ɵɵtext(71, "Back");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(72, "button", 18);
    i0.ɵɵelement(73, "fa-icon", 19);
    i0.ɵɵtext(74, "\u00A0");
    i0.ɵɵelementStart(75, "span", 20);
    i0.ɵɵtext(76, "Edit");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.videoCall.id, "");
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.externalId);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.externalSessionKey);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.startTime);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.endTime);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.status);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.duration);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.participantsCount);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.hasVideo);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.videoCall.hasVoip);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", ctx_r0.videoCall.session);
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(12, _c1, ctx_r0.videoCall.id));
} }
export class VideoCallDetailComponent {
    constructor(activatedRoute) {
        this.activatedRoute = activatedRoute;
        this.videoCall = null;
    }
    ngOnInit() {
        this.activatedRoute.data.subscribe(({ videoCall }) => (this.videoCall = videoCall));
    }
    previousState() {
        window.history.back();
    }
}
VideoCallDetailComponent.ɵfac = function VideoCallDetailComponent_Factory(t) { return new (t || VideoCallDetailComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute)); };
VideoCallDetailComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: VideoCallDetailComponent, selectors: [["jhi-video-call-detail"]], decls: 3, vars: 1, consts: [[1, "row", "justify-content-center"], [1, "col-8"], [4, "ngIf"], ["jhiTranslate", "inukaNgApp.videoCall.detail.title"], [1, "row-md", "jh-entity-details"], ["jhiTranslate", "inukaNgApp.videoCall.externalId"], ["jhiTranslate", "inukaNgApp.videoCall.externalSessionKey"], ["jhiTranslate", "inukaNgApp.videoCall.startTime"], ["jhiTranslate", "inukaNgApp.videoCall.endTime"], ["jhiTranslate", "inukaNgApp.videoCall.status"], ["jhiTranslate", "inukaNgApp.videoCall.duration"], ["jhiTranslate", "inukaNgApp.videoCall.participantsCount"], ["jhiTranslate", "inukaNgApp.videoCall.hasVideo"], ["jhiTranslate", "inukaNgApp.videoCall.hasVoip"], ["jhiTranslate", "inukaNgApp.videoCall.session"], ["type", "submit", 1, "btn", "btn-info", 3, "click"], ["icon", "arrow-left"], ["jhiTranslate", "entity.action.back"], ["type", "button", 1, "btn", "btn-primary", 3, "routerLink"], ["icon", "pencil-alt"], ["jhiTranslate", "entity.action.edit"], [3, "routerLink"]], template: function VideoCallDetailComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵtemplate(2, VideoCallDetailComponent_div_2_Template, 77, 14, "div", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.videoCall);
    } }, directives: [i2.NgIf, i3.JhiTranslateDirective, i4.AlertErrorComponent, i5.FaIconComponent, i1.RouterLink, i1.RouterLinkWithHref], encapsulation: 2 });
