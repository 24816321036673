import { ActivatedRoute } from '@angular/router';
import { LoginModalService } from '../../core/login/login-modal.service';
import { ActivateService } from './activate.service';
import * as i0 from "@angular/core";
import * as i1 from "./activate.service";
import * as i2 from "../../core/login/login-modal.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
function ActivateComponent_p_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 14);
    i0.ɵɵtext(1, " Activating your account... ");
    i0.ɵɵelementEnd();
} }
function ActivateComponent_p_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 15);
    i0.ɵɵtext(1, " Invalid activation code! ");
    i0.ɵɵelementEnd();
} }
function ActivateComponent_p_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 16);
    i0.ɵɵtext(1, " Your account activated. ");
    i0.ɵɵelementEnd();
} }
function ActivateComponent_p_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 17);
    i0.ɵɵtext(1, " This account is already activated. ");
    i0.ɵɵelementEnd();
} }
export class ActivateComponent {
    constructor(activateService, loginModalService, activatedRoute) {
        this.activateService = activateService;
        this.loginModalService = loginModalService;
        this.activatedRoute = activatedRoute;
        this.error = false;
        this.errorUserAlreadyUnsubscribed = false;
        this.success = false;
        this.activationKey = '';
    }
    ngOnInit() {
        this.activateAccount();
    }
    activateAccount() {
        this.activationKey = this.activatedRoute.snapshot.params.activationKey;
        if (this.activationKey !== undefined) {
            this.activateService.activate(this.activationKey).subscribe(() => (this.success = true), response => {
                if (response.status === 302) {
                    this.errorUserAlreadyUnsubscribed = true;
                }
                else
                    this.error = true;
            });
        }
    }
}
ActivateComponent.ɵfac = function ActivateComponent_Factory(t) { return new (t || ActivateComponent)(i0.ɵɵdirectiveInject(i1.ActivateService), i0.ɵɵdirectiveInject(i2.LoginModalService), i0.ɵɵdirectiveInject(i3.ActivatedRoute)); };
ActivateComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ActivateComponent, selectors: [["activate-account"]], decls: 16, vars: 4, consts: [[1, "page"], [1, "page-single"], [1, "container"], [1, "row"], [1, "col", "mx-auto"], [1, "row", "justify-content-center"], [1, "col-md-8"], [1, "card-group", "mb-0"], [1, "card", "p-4"], [1, "card-body"], ["class", "text-muted", 4, "ngIf"], ["class", "text-red", 4, "ngIf"], ["class", "text-success", 4, "ngIf"], ["class", "text-orange", 4, "ngIf"], [1, "text-muted"], [1, "text-red"], [1, "text-success"], [1, "text-orange"]], template: function ActivateComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelementStart(6, "div", 6);
        i0.ɵɵelementStart(7, "div", 7);
        i0.ɵɵelementStart(8, "div", 8);
        i0.ɵɵelementStart(9, "div", 9);
        i0.ɵɵelementStart(10, "h1");
        i0.ɵɵtext(11, "Account Activation");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(12, ActivateComponent_p_12_Template, 2, 0, "p", 10);
        i0.ɵɵtemplate(13, ActivateComponent_p_13_Template, 2, 0, "p", 11);
        i0.ɵɵtemplate(14, ActivateComponent_p_14_Template, 2, 0, "p", 12);
        i0.ɵɵtemplate(15, ActivateComponent_p_15_Template, 2, 0, "p", 13);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(12);
        i0.ɵɵproperty("ngIf", !ctx.error && !ctx.success);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.error);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.success);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.errorUserAlreadyUnsubscribed);
    } }, directives: [i4.NgIf], encapsulation: 2 });
