import { OrganizationExtraQuestionAnswers } from '../../../../shared/model/organization-extra-questions-report.model';
import { TranslateService } from "@ngx-translate/core";
import { JhiLanguageService } from '../../../../ng-jhipster/language';
import * as i0 from "@angular/core";
import * as i1 from "../../../../ng-jhipster/language";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "ngx-echarts";
import * as i5 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i6 from "@angular/material/expansion";
const _c0 = function () { return { renderer: "svg" }; };
function OrganizationExtraQuestionAnswersComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 7);
    i0.ɵɵlistener("chartClick", function OrganizationExtraQuestionAnswersComponent_div_5_Template_div_chartClick_1_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.onChartClick($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r1.chartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
function OrganizationExtraQuestionAnswersComponent_div_6_ng_container_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 14);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const freeTextAnswer_r8 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(freeTextAnswer_r8);
} }
const _c1 = function (a0) { return { height: a0 }; };
function OrganizationExtraQuestionAnswersComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelementStart(1, "h4", 9);
    i0.ɵɵelementStart(2, "span", 10, 11);
    i0.ɵɵtext(4, "Text answers");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 12);
    i0.ɵɵtemplate(7, OrganizationExtraQuestionAnswersComponent_div_6_ng_container_7_Template, 3, 1, "ng-container", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const _r6 = i0.ɵɵreference(3);
    const ctx_r2 = i0.ɵɵnextContext();
    const _r0 = i0.ɵɵreference(2);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(4, _c1, _r0.offsetHeight + "px"));
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" (", ctx_r2.freeTextExtraQuestionOptionTitle, ")");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(6, _c1, _r0.offsetHeight - _r6.offsetHeight + "px"));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r2.freeTextExtraQuestionOptionAnswers.freeTextAnswers);
} }
function OrganizationExtraQuestionAnswersComponent_div_7_ng_container_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 14);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const freeTextAnswer_r10 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(freeTextAnswer_r10);
} }
function OrganizationExtraQuestionAnswersComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵelementStart(1, "mat-expansion-panel");
    i0.ɵɵelementStart(2, "mat-expansion-panel-header");
    i0.ɵɵelementStart(3, "mat-panel-title");
    i0.ɵɵelementStart(4, "h4", 3);
    i0.ɵɵelementStart(5, "span", 10);
    i0.ɵɵtext(6, "Text answers");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 16);
    i0.ɵɵtemplate(9, OrganizationExtraQuestionAnswersComponent_div_7_ng_container_9_Template, 3, 1, "ng-container", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(7);
    i0.ɵɵtextInterpolate1(" (", ctx_r3.freeTextExtraQuestionOptionTitle, ")");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r3.freeTextExtraQuestionOptionAnswers.freeTextAnswers);
} }
export class OrganizationExtraQuestionAnswersComponent {
    constructor(languageService, translateService) {
        this.languageService = languageService;
        this.translateService = translateService;
        this.chartOption = {
            title: {
                show: false
            },
            grid: {
                top: 10,
                left: '5%',
                right: '5%',
                bottom: 60,
                containLabel: true,
                width: 'auto',
                height: 'auto'
            },
            legend: {
                show: false,
                orient: 'horizontal',
                bottom: 5,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            tooltip: {
                trigger: 'item',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            yAxis: {
                type: 'category',
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'",
                    overflow: 'truncate',
                    width: 300
                },
                axisPointer: {
                    show: false
                },
            },
            xAxis: {
                name: 'Number of times this was voted for',
                nameLocation: 'middle',
                nameTextStyle: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                nameGap: 30,
                type: 'value',
                boundaryGap: [0, 0.01],
                minInterval: 1,
                max(value) {
                    if (value.max < 10) {
                        return 10;
                    }
                    else {
                        return value.max;
                    }
                },
                splitLine: {
                    show: true,
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            series: [
                {
                    name: 'Number of times this was voted for',
                    type: 'bar',
                    color: '#44A38B',
                    encode: {
                        y: 'extraQuestionOptionTitle',
                        x: 'answersCount' // refer dimensions name
                    },
                    animationDuration: 3000,
                    label: {
                        show: true,
                        color: '#000000',
                        fontFamily: "'Open Sans', 'sans-serif'",
                        position: 'outside'
                    },
                    itemStyle: {
                        borderRadius: [0, 4, 4, 0]
                    },
                    barWidth: 20,
                }
            ]
        };
    }
    ngOnDestroy() {
        var _a;
        (_a = this.langChangeSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    set extraQuestionAnswers(value) {
        this._extraQuestionAnswers = value;
        this.refreshChartData();
    }
    get extraQuestionAnswers() {
        return this._extraQuestionAnswers;
    }
    ngOnInit() {
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.refreshChartData();
        });
    }
    refreshChartData() {
        this.title = this.selectTitleBasedOnLanguage(this.extraQuestionAnswers.extraQuestion.translations);
        const dateset = [];
        if (this.extraQuestionAnswers) {
            for (const extraQuestionOptionAnswers of this.extraQuestionAnswers.extraQuestionOptionAnswers) {
                const title = this.selectTitleBasedOnLanguage(extraQuestionOptionAnswers.extraQuestionOption.translations);
                dateset.unshift([
                    extraQuestionOptionAnswers.extraQuestionOption.id,
                    title,
                    extraQuestionOptionAnswers.answersCount
                ]);
            }
            console.log(dateset);
        }
        this.chartOption.dataset = {
            source: dateset,
            dimensions: ['extraQuestionOptionId', 'extraQuestionOptionTitle', 'answersCount']
        };
    }
    selectTitleBasedOnLanguage(titleTranslations) {
        const currentLanguage = this.languageService.getCurrentLanguage();
        let title = titleTranslations[currentLanguage];
        if (title && title.trim().length) {
            return title;
        }
        else {
            return titleTranslations['en'];
        }
    }
    onChartClick(event) {
        var _a, _b;
        console.log(event);
        // we assume extraQuestionOptionId is stored as the first item in the dataset dimensions
        const extraQuestionOptionId = event.data[0];
        // we assume extraQuestionOption Title is stored as the second item in the dataset dimensions
        this.freeTextExtraQuestionOptionTitle = event.data[1];
        if (((_a = this.freeTextExtraQuestionOptionAnswers) === null || _a === void 0 ? void 0 : _a.extraQuestionOption.id) === extraQuestionOptionId) {
            // unselect the currently selected extra question option if user clicked on the same option again
            this.freeTextExtraQuestionOptionAnswers = null;
            return;
        }
        const extraQuestionOptionAnswers = this.extraQuestionAnswers.extraQuestionOptionAnswers.find(x => x.extraQuestionOption.id === extraQuestionOptionId);
        if ((_b = extraQuestionOptionAnswers === null || extraQuestionOptionAnswers === void 0 ? void 0 : extraQuestionOptionAnswers.freeTextAnswers) === null || _b === void 0 ? void 0 : _b.length) {
            this.freeTextExtraQuestionOptionAnswers = extraQuestionOptionAnswers;
        }
        else {
            this.freeTextExtraQuestionOptionAnswers = null;
        }
    }
}
OrganizationExtraQuestionAnswersComponent.ɵfac = function OrganizationExtraQuestionAnswersComponent_Factory(t) { return new (t || OrganizationExtraQuestionAnswersComponent)(i0.ɵɵdirectiveInject(i1.JhiLanguageService), i0.ɵɵdirectiveInject(i2.TranslateService)); };
OrganizationExtraQuestionAnswersComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationExtraQuestionAnswersComponent, selectors: [["organization-extra-question-answers"]], inputs: { extraQuestionAnswers: "extraQuestionAnswers" }, decls: 8, vars: 6, consts: [[1, "d-flex", "flex-row", "flex-wrap", "justify-content-between", 2, "gap", "20px"], [1, "card", "dashboard-card", "p-2"], ["extraQuestionChartContainer", ""], [1, "m-3", "light-weight"], ["style", "", 4, "ngIf"], ["class", "d-none d-xl-flex card dashboard-card p-2 extra-question-free-text-answers-container", 3, "ngStyle", 4, "ngIf"], ["class", "d-xl-none extra-question-free-text-answers-container", 4, "ngIf"], ["echarts", "", 2, "max-height", "400px", "width", "100%", 3, "options", "initOpts", "chartClick"], [1, "d-none", "d-xl-flex", "card", "dashboard-card", "p-2", "extra-question-free-text-answers-container", 3, "ngStyle"], [1, "p-3", "light-weight"], ["jhiTranslate", "organizationDashboard.insights.extraQuestions.freeTextExtraQuestionOption"], ["extraQuestionFreeTextOptionHeader", ""], [1, "flex", "flex-column", 2, "overflow", "scroll", 3, "ngStyle"], [4, "ngFor", "ngForOf"], [1, "extra-question-free-text-answer"], [1, "d-xl-none", "extra-question-free-text-answers-container"], [1, "flex", "flex-column"]], template: function OrganizationExtraQuestionAnswersComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1, 2);
        i0.ɵɵelementStart(3, "h4", 3);
        i0.ɵɵtext(4);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, OrganizationExtraQuestionAnswersComponent_div_5_Template, 2, 3, "div", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, OrganizationExtraQuestionAnswersComponent_div_6_Template, 8, 8, "div", 5);
        i0.ɵɵtemplate(7, OrganizationExtraQuestionAnswersComponent_div_7_Template, 10, 2, "div", 6);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵclassProp("extra-question-answers-chart-container", ctx.freeTextExtraQuestionOptionAnswers);
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate(ctx.title);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.extraQuestionAnswers && (ctx.chartOption == null ? null : ctx.chartOption.series.length));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.freeTextExtraQuestionOptionAnswers);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.freeTextExtraQuestionOptionAnswers);
    } }, directives: [i3.NgIf, i4.NgxEchartsDirective, i3.NgStyle, i5.JhiTranslateDirective, i3.NgForOf, i6.MatExpansionPanel, i6.MatExpansionPanelHeader, i6.MatExpansionPanelTitle], styles: [".extra-question-answers-chart-container[_ngcontent-%COMP%]{flex:0 0 100%;transition:flex 2s}.extra-question-free-text-answers-container[_ngcontent-%COMP%]{transition:flex 2s;flex:0 0 100%;overflow:hidden}.extra-question-free-text-answer[_ngcontent-%COMP%]{padding:10px;margin:0px 10px 10px 20px;border-radius:4px;background:#f7f4eb}.mat-expansion-panel[_ngcontent-%COMP%]{box-shadow:none;border-radius:10px;border:1px solid #cedbd7}@media(min-width: 1200px){.extra-question-answers-chart-container[_ngcontent-%COMP%]{flex:0 0 70% !important}.extra-question-free-text-answers-container[_ngcontent-%COMP%]{flex:0 0 28% !important}}"] });
