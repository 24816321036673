import { RouterModule } from '@angular/router';
import { InukaNgSharedModule } from '../../shared/shared.module';
import { indexUserRoute } from './index-user.route';
import { InukaNgActionPlanModule } from '../action-plan/action-plan.module';
import { MatTooltipModule } from "@angular/material/tooltip";
import { InukaNgSessionModule } from '../session/session.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class IndexUserModule {
}
IndexUserModule.ɵfac = function IndexUserModule_Factory(t) { return new (t || IndexUserModule)(); };
IndexUserModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: IndexUserModule });
IndexUserModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[InukaNgSharedModule, RouterModule.forChild(indexUserRoute), InukaNgActionPlanModule, MatTooltipModule, InukaNgSessionModule]] });
