import { TemplateRef } from '@angular/core';
import * as momenttz from 'moment-timezone';
import { AccountService } from '../../core/auth/account.service';
import { NavbarInukaService } from '../navbar-inuka/navbar-inuka.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { TimeZoneService } from '../../core/util/timzone.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth/account.service";
import * as i2 from "../navbar-inuka/navbar-inuka.service";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/router";
import * as i5 from "../../core/util/timzone.service";
import * as i6 from "@angular/common";
import * as i7 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "@fortawesome/angular-fontawesome";
const _c0 = ["successModal"];
const _c1 = function (a0) { return { newTimezone: a0 }; };
function UpdateTimezoneNavbarNotificationComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelement(2, "span", 3);
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵelementStart(4, "button", 5);
    i0.ɵɵlistener("click", function UpdateTimezoneNavbarNotificationComponent_ng_container_0_Template_button_click_4_listener() { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.updateTimezone(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(1, _c1, ctx_r0.timezone));
} }
function UpdateTimezoneNavbarNotificationComponent_ng_template_1_button_5_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function UpdateTimezoneNavbarNotificationComponent_ng_template_1_button_5_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r10); const modal_r5 = i0.ɵɵnextContext().$implicit; return modal_r5.dismiss("Cross click"); });
    i0.ɵɵelementEnd();
} }
function UpdateTimezoneNavbarNotificationComponent_ng_template_1_button_6_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 14);
    i0.ɵɵlistener("click", function UpdateTimezoneNavbarNotificationComponent_ng_template_1_button_6_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r12); const ctx_r11 = i0.ɵɵnextContext(2); return ctx_r11.navigateToCoachAvailabilities(); });
    i0.ɵɵelementEnd();
} }
function UpdateTimezoneNavbarNotificationComponent_ng_template_1_Template(rf, ctx) { if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelementStart(1, "div", 7);
    i0.ɵɵelementStart(2, "fa-icon", 8);
    i0.ɵɵlistener("click", function UpdateTimezoneNavbarNotificationComponent_ng_template_1_Template_fa_icon_click_2_listener() { const restoredCtx = i0.ɵɵrestoreView(_r14); const modal_r5 = restoredCtx.$implicit; return modal_r5.dismiss("Cross click"); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 9);
    i0.ɵɵelement(4, "h4", 10);
    i0.ɵɵtemplate(5, UpdateTimezoneNavbarNotificationComponent_ng_template_1_button_5_Template, 1, 0, "button", 11);
    i0.ɵɵtemplate(6, UpdateTimezoneNavbarNotificationComponent_ng_template_1_button_6_Template, 1, 0, "button", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", !ctx_r2.isCoach);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.isCoach);
} }
export class UpdateTimezoneNavbarNotificationComponent {
    constructor(accountService, navbarInukaService, modalService, router, timeZoneService) {
        this.accountService = accountService;
        this.navbarInukaService = navbarInukaService;
        this.modalService = modalService;
        this.router = router;
        this.timeZoneService = timeZoneService;
        this.timezone = momenttz.tz.guess();
    }
    set user(value) {
        this._user = value;
        this.compareTimezones();
    }
    get user() {
        return this._user;
    }
    ngOnInit() {
    }
    updateTimezone() {
        this.accountService.saveTimezone(this.timezone).subscribe(value => {
            this.navbarInukaService.hideEmbeddedComponent();
            this.modalService.open(this.successModal, { size: 'md' });
        });
    }
    navigateToCoachAvailabilities() {
        this.modalService.dismissAll();
        this.router.navigate(['/my-availability']).then();
    }
    compareTimezones() {
        if (this.timeZoneService.isUserAndBrowserTimezonesTheSame(this.user)) {
            this.timezoneIsDifferent = false;
        }
        else {
            this.timezoneIsDifferent = true;
        }
    }
}
UpdateTimezoneNavbarNotificationComponent.ɵfac = function UpdateTimezoneNavbarNotificationComponent_Factory(t) { return new (t || UpdateTimezoneNavbarNotificationComponent)(i0.ɵɵdirectiveInject(i1.AccountService), i0.ɵɵdirectiveInject(i2.NavbarInukaService), i0.ɵɵdirectiveInject(i3.NgbModal), i0.ɵɵdirectiveInject(i4.Router), i0.ɵɵdirectiveInject(i5.TimeZoneService)); };
UpdateTimezoneNavbarNotificationComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: UpdateTimezoneNavbarNotificationComponent, selectors: [["update-timezone-navbar-notification"]], viewQuery: function UpdateTimezoneNavbarNotificationComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.successModal = _t.first);
    } }, inputs: { user: "user", isCoach: "isCoach" }, decls: 3, vars: 1, consts: [[4, "ngIf"], ["successModal", ""], [1, "col", "text-center", "p-3", "p-xl-0"], ["jhiTranslate", "global.messages.updateTimezone.message", 1, "text-success", "font-weight-bold", 3, "translateValues"], [1, "ml-3", "mt-2", "d-lg-inline-block"], ["jhiTranslate", "global.messages.updateTimezone.updateTimezone", 1, "btn", "btn-primary", 3, "click"], [1, "olha-theme"], [1, "d-flex", "justify-content-end"], ["icon", "times-circle", 1, "mt-3", "mr-3", 2, "cursor", "pointer", 3, "click"], [1, "modal-body", "text-center"], ["jhiTranslate", "global.messages.updateTimezone.successMessage", 1, "mb-4"], ["class", "btn btn-primary", "jhiTranslate", "global.messages.updateTimezone.successButton", 3, "click", 4, "ngIf"], ["class", "ml-3 btn btn-link", "jhiTranslate", "global.messages.updateTimezone.reviewAvailableTimes", 3, "click", 4, "ngIf"], ["jhiTranslate", "global.messages.updateTimezone.successButton", 1, "btn", "btn-primary", 3, "click"], ["jhiTranslate", "global.messages.updateTimezone.reviewAvailableTimes", 1, "ml-3", "btn", "btn-link", 3, "click"]], template: function UpdateTimezoneNavbarNotificationComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, UpdateTimezoneNavbarNotificationComponent_ng_container_0_Template, 5, 3, "ng-container", 0);
        i0.ɵɵtemplate(1, UpdateTimezoneNavbarNotificationComponent_ng_template_1_Template, 7, 2, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.timezoneIsDifferent);
    } }, directives: [i6.NgIf, i7.JhiTranslateDirective, i8.FaIconComponent], styles: [""] });
