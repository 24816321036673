import moment from "moment";
import * as i0 from "@angular/core";
export class FormatDateStringPipe {
    transform(date, format) {
        return moment(date).format(format);
    }
}
FormatDateStringPipe.ɵfac = function FormatDateStringPipe_Factory(t) { return new (t || FormatDateStringPipe)(); };
FormatDateStringPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "formatDateString", type: FormatDateStringPipe, pure: true });
FormatDateStringPipe.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FormatDateStringPipe, factory: FormatDateStringPipe.ɵfac, providedIn: 'root' });
