import { ElementRef, SimpleChanges } from "@angular/core";
import { CoachUserNoteService } from '../../../session/session-chat-coach/coach-user-note/coach-user-note.service';
import { ActivatedRoute } from "@angular/router";
import { TrackerService } from '../../../../core/tracker/tracker.service';
import { AccountService } from '../../../../core/auth/account.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../session/session-chat-coach/coach-user-note/coach-user-note.service";
import * as i2 from "@angular/router";
import * as i3 from "../../../../core/tracker/tracker.service";
import * as i4 from "../../../../core/auth/account.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
function CoachUserNoteComponent_div_0_textarea_1_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "textarea", 5, 6);
    i0.ɵɵlistener("ngModelChange", function CoachUserNoteComponent_div_0_textarea_1_Template_textarea_ngModelChange_0_listener($event) { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(2); return ctx_r5.coachUserNote.text = $event; })("keyup", function CoachUserNoteComponent_div_0_textarea_1_Template_textarea_keyup_0_listener($event) { i0.ɵɵrestoreView(_r6); const _r4 = i0.ɵɵreference(1); const ctx_r7 = i0.ɵɵnextContext(2); return ctx_r7.onInput($event, _r4); })("focusout", function CoachUserNoteComponent_div_0_textarea_1_Template_textarea_focusout_0_listener() { i0.ɵɵrestoreView(_r6); const ctx_r8 = i0.ɵɵnextContext(2); return ctx_r8.onTextareaFocusOut(); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵstyleProp("height", !ctx_r1.isEmbedded ? "85%" : "100%");
    i0.ɵɵpropertyInterpolate("maxlength", ctx_r1.charLimit);
    i0.ɵɵproperty("ngModel", ctx_r1.coachUserNote.text);
} }
function CoachUserNoteComponent_div_0_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r2.coachUserNote.text);
} }
const _c0 = function (a0) { return { "color": a0 }; };
function CoachUserNoteComponent_div_0_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelementStart(1, "small", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(3, _c0, ctx_r3.charLength > ctx_r3.charLimit ? "red" : "#7CBFAE"));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2("", ctx_r3.charLength, " / ", ctx_r3.charLimit, "");
} }
function CoachUserNoteComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, CoachUserNoteComponent_div_0_textarea_1_Template, 2, 4, "textarea", 2);
    i0.ɵɵtemplate(2, CoachUserNoteComponent_div_0_span_2_Template, 2, 1, "span", 3);
    i0.ɵɵtemplate(3, CoachUserNoteComponent_div_0_div_3_Template, 3, 5, "div", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("height", !ctx_r0.isEmbedded ? "" : ctx_r0.height);
    i0.ɵɵproperty("ngClass", !ctx_r0.isEmbedded ? "offset-lg-3 col-lg-6 offset-xl-3 col-xl-6 offset-md-1 col-md-10 col-12 notes-page" : "");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.isMastermind && !ctx_r0.isSupervisor);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.isMastermind || ctx_r0.isSupervisor);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.isMastermind && !ctx_r0.isSupervisor);
} }
export class CoachUserNoteComponent {
    constructor(coachUserNoteService, activatedRoute, elementRef, trackerService, accountService) {
        this.coachUserNoteService = coachUserNoteService;
        this.activatedRoute = activatedRoute;
        this.elementRef = elementRef;
        this.trackerService = trackerService;
        this.accountService = accountService;
        this.charLimit = 2000;
        this.charLength = 0;
    }
    ngOnInit() {
        this.isSupervisor = this.accountService.isSupervisor();
        this.isMastermind = this.accountService.isMastermind();
        this.activatedRoute.params.subscribe((params) => {
            this.userId = this.userId ? this.userId : params["userId"];
            this.coachId = this.coachId ? this.coachId : params["coachId"];
            if (!this.isEmbedded) {
                this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F7F4EB';
                this.elementRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
                this.elementRef.nativeElement.ownerDocument.body.style.margin = 'auto';
            }
            this.getCoachUserNote();
        });
        this.subscribeToWebsocket();
    }
    ngOnChanges(changes) {
        this.charLength = 0;
        this.getCoachUserNote();
    }
    subscribeToWebsocket() {
        this.subscription = this.trackerService.listenerSubject.subscribe(value => {
            const text = value['body'].text;
            const userId = value['body'].user.id;
            const coachId = value['body'].coach.id;
            const type = value['type'];
            if (type === 'COACH_USER_NOTE_UPDATED' && userId === this.userId && coachId === this.coachId) {
                this.coachUserNote.text = text;
            }
            else {
                console.log('CoachUserNoteComponent ignoring ' + type + ' message');
            }
        });
    }
    getCoachUserNote() {
        this.coachUserNoteService.find(this.coachId, this.userId).subscribe((res) => {
            var _a, _b;
            if (res.body != null) {
                this.coachUserNote = res.body;
            }
            else {
                this.coachUserNote = { id: null, coach: { id: this.coachId }, user: { id: this.userId }, text: '' };
            }
            this.charLength = (_b = (_a = this.coachUserNote) === null || _a === void 0 ? void 0 : _a.text) === null || _b === void 0 ? void 0 : _b.length;
        }, err => console.log(err));
    }
    onInput($event, container) {
        this.charLength = container.value.length;
    }
    onTextareaFocusOut() {
        if (this.coachUserNote.id != null) {
            this.coachUserNoteService.update(this.coachUserNote).subscribe((res) => this.coachUserNote = res.body, err => console.log(err));
        }
        else {
            this.coachUserNoteService.create(this.coachUserNote).subscribe((res) => this.coachUserNote = res.body, err => console.log(err));
        }
    }
}
CoachUserNoteComponent.ɵfac = function CoachUserNoteComponent_Factory(t) { return new (t || CoachUserNoteComponent)(i0.ɵɵdirectiveInject(i1.CoachUserNoteService), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i3.TrackerService), i0.ɵɵdirectiveInject(i4.AccountService)); };
CoachUserNoteComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: CoachUserNoteComponent, selectors: [["coach-user-note"]], inputs: { coachId: "coachId", userId: "userId", height: "height", isEmbedded: "isEmbedded" }, features: [i0.ɵɵNgOnChangesFeature], decls: 1, vars: 1, consts: [["class", "olha-theme text-area-parent", 3, "height", "ngClass", 4, "ngIf"], [1, "olha-theme", "text-area-parent", 3, "ngClass"], ["type", "text", "class", "form-control", 3, "maxlength", "height", "ngModel", "ngModelChange", "keyup", "focusout", 4, "ngIf"], [4, "ngIf"], ["class", "indicator-div", 4, "ngIf"], ["type", "text", 1, "form-control", 3, "maxlength", "ngModel", "ngModelChange", "keyup", "focusout"], ["textArea", ""], [1, "indicator-div"], [1, "float-right", 3, "ngStyle"]], template: function CoachUserNoteComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, CoachUserNoteComponent_div_0_Template, 4, 6, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.coachUserNote != null);
    } }, directives: [i5.NgIf, i5.NgClass, i6.DefaultValueAccessor, i6.MaxLengthValidator, i6.NgControlStatus, i6.NgModel, i5.NgStyle], styles: [".text-area-parent[_ngcontent-%COMP%]{padding:20px;height:100% !important}textarea[_ngcontent-%COMP%]{border:1px solid #e1e1e1 !important;border-radius:10px !important;resize:none !important;color:#8a9d98 !important}.indicator-div[_ngcontent-%COMP%]{line-height:1.2;margin-top:3px !important;position:relative;bottom:30px;right:15px}.notes-page[_ngcontent-%COMP%]{margin-top:100px}"] });
