import { map } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { STATUS_DISPLAYED_ON_RECEIVER } from '../../../shared/model/message.model';
import { Linkify } from '../../../core/util/linkify';
export class MessageRepository {
    constructor(messageService) {
        this.messageService = messageService;
        this.batchSize = 50;
        this.displayTranscriptions = false;
        this.lastIndex = -1;
        this.onDataFetch$ = new Subject();
        this.cacheByIndex = new Map();
        this.messageIndexById = new Map();
    }
    static createConversationMessageRepository(messageService, userId, coachId) {
        const messageLoader = new MessageRepository(messageService);
        messageLoader.userId = userId;
        messageLoader.coachId = coachId;
        return messageLoader;
    }
    static createSessionMessageRepository(messageService, sessionId, displayTranscriptions) {
        const messageLoader = new MessageRepository(messageService);
        messageLoader.sessionId = sessionId;
        messageLoader.displayTranscriptions = displayTranscriptions;
        return messageLoader;
    }
    init() {
        return this.getMessages(this.batchSize);
    }
    getMessages(count, index) {
        this.onDataFetch$.next(true);
        let messageFetcher;
        if (this.sessionId) {
            messageFetcher = this.messageService.getMessagesBySession(this.sessionId, count, index, this.displayTranscriptions);
        }
        else if (this.userId && this.coachId) {
            messageFetcher = this.messageService.getMessagesByConversation(this.userId, this.coachId, count, index);
        }
        return messageFetcher.pipe(map(messageList => {
            var _a;
            if ((_a = messageList.body) === null || _a === void 0 ? void 0 : _a.messages) {
                const total = messageList.body.totalCount;
                const size = messageList.body.messages.length;
                messageList.body.messages.forEach((item, i) => this.persist(messageList.body.startIndex + i, item));
            }
            this.onDataFetch$.next(false);
            return messageList.body;
        }));
    }
    request(index, count) {
        console.log(`requesting [${index}...${index + count}] items`);
        // try cache
        const cached = this.takeFromCache(index, count);
        if (cached) {
            console.log(`taken from cache (${count})`);
            return of(cached);
        }
        // request
        return this.getMessages(count, index).pipe(map(value => value.messages));
    }
    get(index) {
        return this.cacheByIndex.get(index);
    }
    getByMessageId(id) {
        let messageIndex = this.messageIndexById.get(id);
        if (messageIndex !== undefined) {
            return this.cacheByIndex.get(messageIndex);
        }
        return null;
    }
    updateToDisplayedStatus(messageId) {
        for (let message of this.cacheByIndex.values()) {
            if (message.id <= messageId) {
                message.status = STATUS_DISPLAYED_ON_RECEIVER;
            }
        }
    }
    updateReaction(messageId, receiverReaction) {
        const message = this.getByMessageId(messageId);
        if (message) {
            message.receiverReaction = receiverReaction;
        }
    }
    persist(index, item) {
        if (!this.messageIndexById.has(item.id)) {
            item = this.initMessage(index, item);
            this.cacheByIndex.set(index, item);
            this.messageIndexById.set(item.id, index);
            if (index > this.lastIndex) {
                this.lastIndex = index;
            }
        }
    }
    append(item) {
        const index = this.lastIndex + 1;
        this.persist(index, item);
    }
    takeFromCache(index, count) {
        const cached = [];
        for (let i = index; i < index + count; i++) {
            const item = this.cacheByIndex.get(i);
            if (item) {
                cached.push(item);
            }
        }
        return cached.length === count ? cached : null;
    }
    initMessage(index, message) {
        if (message.replyId) {
            this.messageService.find(message.replyId).subscribe(replayedMessage => {
                message.replyMessage = replayedMessage.body;
            });
        }
        if (message.type === 'text') {
            message.message = Linkify.convertUrlAndEmailsToLink(message.message);
        }
        message.isFirstSessionMessage = this.isStartOfSession(message, index - 1);
        return message;
    }
    isStartOfSession(m, previousMessageIndex) {
        if (previousMessageIndex < 0 && m.sessionId != null)
            return true;
        let previousMessage = this.get(previousMessageIndex);
        if (previousMessage && m.sessionId && previousMessage.sessionId !== m.sessionId) {
            return true;
        }
        return false;
    }
    dispose() {
        delete this.messageIndexById;
        delete this.cacheByIndex;
        this.onDataFetch$.complete();
    }
}
