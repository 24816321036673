import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
export class LoginModalService {
    constructor(modalService) {
        this.modalService = modalService;
        this.isOpen = false;
    }
    open() {
        if (this.isOpen) {
            return;
        }
        // this.isOpen = true;
        // const modalRef: NgbModalRef = this.modalService.open(LoginModalComponent);
        // modalRef.result.finally(() => (this.isOpen = false));
    }
}
LoginModalService.ɵfac = function LoginModalService_Factory(t) { return new (t || LoginModalService)(i0.ɵɵinject(i1.NgbModal)); };
LoginModalService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LoginModalService, factory: LoginModalService.ɵfac, providedIn: 'root' });
