export class SRQCompleted {
    constructor(id, dateTaken, score, bodyScore, moodScore, energyScore, outlookScore, user) {
        this.id = id;
        this.dateTaken = dateTaken;
        this.score = score;
        this.bodyScore = bodyScore;
        this.moodScore = moodScore;
        this.energyScore = energyScore;
        this.outlookScore = outlookScore;
        this.user = user;
    }
}
