import { ElementRef } from "@angular/core";
import { TrackerService } from '../core/tracker/tracker.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/tracker/tracker.service";
export class TrackUserActivityDirective {
    constructor(elRef, trackerService) {
        this.elRef = elRef;
        this.trackerService = trackerService;
    }
    onClick() {
        if (this.trackUserActivity.track !== 'click')
            return;
        console.log("click: " + this.trackUserActivity);
        this.trackerService.sendActivity(this.trackUserActivity.name);
    }
    onFocus() {
        if (this.trackUserActivity.track !== 'focus')
            return;
        console.log("focusin: " + this.trackUserActivity);
        this.trackerService.sendActivity(this.trackUserActivity.name);
    }
    onBlur() {
        if (this.trackUserActivity.track !== 'focusout')
            return;
        console.log("focusout: " + this.trackUserActivity);
        this.trackerService.sendActivity(this.trackUserActivity.name);
    }
}
TrackUserActivityDirective.ɵfac = function TrackUserActivityDirective_Factory(t) { return new (t || TrackUserActivityDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.TrackerService)); };
TrackUserActivityDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TrackUserActivityDirective, selectors: [["", "trackUserActivity", ""]], hostBindings: function TrackUserActivityDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function TrackUserActivityDirective_click_HostBindingHandler() { return ctx.onClick(); })("focusin", function TrackUserActivityDirective_focusin_HostBindingHandler() { return ctx.onFocus(); })("focusout", function TrackUserActivityDirective_focusout_HostBindingHandler() { return ctx.onBlur(); });
    } }, inputs: { trackUserActivity: "trackUserActivity" } });
