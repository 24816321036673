import * as i0 from "@angular/core";
export class JhiTruncateWordsPipe {
    transform(input, words) {
        if (isNaN(words)) {
            return input;
        }
        if (words <= 0) {
            return '';
        }
        if (input) {
            const inputWords = input.split(/\s+/);
            if (inputWords.length > words) {
                input = inputWords.slice(0, words).join(' ') + '...';
            }
        }
        return input;
    }
}
JhiTruncateWordsPipe.ɵfac = function JhiTruncateWordsPipe_Factory(t) { return new (t || JhiTruncateWordsPipe)(); };
JhiTruncateWordsPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "truncateWords", type: JhiTruncateWordsPipe, pure: true });
