import { HttpClient } from '@angular/common/http';
import * as momenttz from "moment-timezone";
import { SERVER_API_URL } from '../../app.constants';
import { map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
export class OrganizationDashboardService {
    constructor(http, translateService) {
        this.http = http;
        this.translateService = translateService;
        this.resourceUrl = SERVER_API_URL + 'api/organization-dashboard';
    }
    setTimeZone(parameters) {
        if (!this.timeZone) {
            this.timeZone = momenttz.tz.guess();
        }
        parameters.timeZone = this.timeZone;
    }
    getOrganizationUsageOverTimeReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/usage-over-time`, parameters, { observe: 'response' });
    }
    getUserAuthorizedOrganizations() {
        return this.http
            .get(`${this.resourceUrl}/organizations`, { observe: 'response' });
    }
    getAuthorizedRatingsInfo(parameters) {
        return this.http
            .post(`${this.resourceUrl}/rating-info/${parameters.organizationId}`, parameters, { observe: 'response' });
    }
    getOrganizationStatsReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/stats`, parameters, { observe: 'response' });
    }
    getOrganizationNumberOfSessionsReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/number-of-sessions`, parameters, { observe: 'response' });
    }
    getOrganizationFirstTimeCoacheesAndSessionsOverTimeReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/first-time-coachees-and-sessions-over-time`, parameters, { observe: 'response' });
    }
    getOrganizationEngagementReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/engagement-stats`, parameters, { observe: 'response' });
    }
    getOrganizationDepartmentsEngagementReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/departments-engagement`, parameters, { observe: 'response' });
    }
    getOrganizationSrqStatsReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/srq-stats`, parameters, { observe: 'response' });
    }
    getOrganizationSrqStatusReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/srq-status`, parameters, { observe: 'response' });
    }
    getOrganizationSrqImpactPercentageReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/srq-impact-percentage`, parameters, { observe: 'response' })
            .pipe(map((res) => this.translate(res)));
    }
    getOrganizationWellbeingImprovementReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/srq-impact-percentage`, parameters, { observe: 'response' });
    }
    getOrganizationLookingForHelpTopicsCountReport(parameters) {
        this.setTimeZone(parameters);
        return this.http.post(`${this.resourceUrl}/${parameters.organizationId}/looking-for-help-topics-count`, parameters, { observe: 'response' });
    }
    translate(res) {
        if (res.body) {
            console.log(res.body);
            let departments = res.body.firstScans.map(fs => fs.department);
            for (let i = 0; i < departments.length; i++) {
                if (departments[i] === "inukaNgApp.organizationAdmin.organizationSrqImpactPercentage.Company total") {
                    let change = res.body.changes.find(c => c.department === departments[i]);
                    let firstScan = res.body.firstScans.find(fs => fs.department === departments[i]);
                    let lastScan = res.body.lastScans.find(ls => ls.department === departments[i]);
                    change.department = this.translateService.instant(change.department);
                    firstScan.department = this.translateService.instant(firstScan.department);
                    lastScan.department = this.translateService.instant(lastScan.department);
                    departments[i] = this.translateService.instant(departments[i]);
                    res.body.departments = departments;
                }
            }
        }
        return res;
    }
    getOrganizationExtraQuestionsReport(parameters) {
        this.setTimeZone(parameters);
        return this.http
            .post(`${this.resourceUrl}/${parameters.organizationId}/extra-questions`, parameters, { observe: 'response' });
    }
}
OrganizationDashboardService.ɵfac = function OrganizationDashboardService_Factory(t) { return new (t || OrganizationDashboardService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService)); };
OrganizationDashboardService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OrganizationDashboardService, factory: OrganizationDashboardService.ɵfac, providedIn: 'root' });
