import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import * as i0 from "@angular/core";
export class NgbDateMomentAdapter extends NgbDateAdapter {
    fromModel(date) {
        if (date && moment.isMoment(date) && date.isValid()) {
            return { year: date.year(), month: date.month() + 1, day: date.date() };
        }
        // ! can be removed after https://github.com/ng-bootstrap/ng-bootstrap/issues/1544 is resolved
        return null;
    }
    toModel(date) {
        // ! after null can be removed after https://github.com/ng-bootstrap/ng-bootstrap/issues/1544 is resolved
        return date ? moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD') : null;
    }
}
NgbDateMomentAdapter.ɵfac = /*@__PURE__*/ function () { let ɵNgbDateMomentAdapter_BaseFactory; return function NgbDateMomentAdapter_Factory(t) { return (ɵNgbDateMomentAdapter_BaseFactory || (ɵNgbDateMomentAdapter_BaseFactory = i0.ɵɵgetInheritedFactory(NgbDateMomentAdapter)))(t || NgbDateMomentAdapter); }; }();
NgbDateMomentAdapter.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NgbDateMomentAdapter, factory: NgbDateMomentAdapter.ɵfac });
