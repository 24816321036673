import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ResourceForHelpService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/resource-for-helps';
    }
    create(resourceForHelp) {
        return this.http.post(this.resourceUrl, resourceForHelp, { observe: 'response' });
    }
    update(resourceForHelp) {
        return this.http.put(this.resourceUrl, resourceForHelp, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    queryAsList() {
        return this.http.get(`${this.resourceUrl}/as-list`, { observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    findByRegion(region) {
        return this.http.get(`${this.resourceUrl}/resources-by-region?region=${region}`, { observe: 'response' });
    }
}
ResourceForHelpService.ɵfac = function ResourceForHelpService_Factory(t) { return new (t || ResourceForHelpService)(i0.ɵɵinject(i1.HttpClient)); };
ResourceForHelpService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ResourceForHelpService, factory: ResourceForHelpService.ɵfac, providedIn: 'root' });
