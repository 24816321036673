import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { JhiConfigService } from '../config.service';
import { JhiSortDirective } from './sort.directive';
import * as i0 from "@angular/core";
import * as i1 from "./sort.directive";
import * as i2 from "../config.service";
export class JhiSortByDirective {
    constructor(jhiSort, configService) {
        this.jhiSort = jhiSort;
        this.jhiSort = jhiSort;
        const config = configService.getConfig();
        this.sortIcon = config.sortIcon;
        this.sortAscIcon = config.sortAscIcon;
        this.sortDescIcon = config.sortDescIcon;
    }
    ngAfterContentInit() {
        if (this.jhiSort.predicate && this.jhiSort.predicate !== '_score' && this.jhiSort.predicate === this.jhiSortBy) {
            this.updateIconDefinition(this.iconComponent, this.jhiSort.ascending ? this.sortAscIcon : this.sortDescIcon);
            this.jhiSort.activeIconComponent = this.iconComponent;
        }
    }
    onClick() {
        if (this.jhiSort.predicate && this.jhiSort.predicate !== '_score') {
            this.jhiSort.sort(this.jhiSortBy);
            this.updateIconDefinition(this.jhiSort.activeIconComponent, this.sortIcon);
            this.updateIconDefinition(this.iconComponent, this.jhiSort.ascending ? this.sortAscIcon : this.sortDescIcon);
            this.jhiSort.activeIconComponent = this.iconComponent;
        }
    }
    updateIconDefinition(iconComponent, icon) {
        if (iconComponent) {
            iconComponent.icon = icon.iconName;
            iconComponent.render();
        }
    }
}
JhiSortByDirective.ɵfac = function JhiSortByDirective_Factory(t) { return new (t || JhiSortByDirective)(i0.ɵɵdirectiveInject(i1.JhiSortDirective, 1), i0.ɵɵdirectiveInject(i2.JhiConfigService)); };
JhiSortByDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: JhiSortByDirective, selectors: [["", "jhiSortBy", ""]], contentQueries: function JhiSortByDirective_ContentQueries(rf, ctx, dirIndex) { if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, FaIconComponent, 7);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.iconComponent = _t.first);
    } }, hostBindings: function JhiSortByDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function JhiSortByDirective_click_HostBindingHandler() { return ctx.onClick(); });
    } }, inputs: { jhiSortBy: "jhiSortBy" } });
