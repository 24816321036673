export var Authority;
(function (Authority) {
    Authority["MASTERMIND"] = "ROLE_MASTERMIND";
    Authority["SUPERVISOR"] = "ROLE_SUPERVISOR";
    Authority["ADMIN"] = "ROLE_ADMIN";
    Authority["USER"] = "ROLE_USER";
    Authority["COACH"] = "ROLE_COACH";
    Authority["CUSTOMER_CARE"] = "ROLE_CUSTOMER_CARE";
    Authority["ORGANIZATION_ADMIN"] = "ROLE_ORGANIZATION_ADMIN";
})(Authority || (Authority = {}));
