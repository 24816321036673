import { ZoomService } from '../zoom.service';
import * as i0 from "@angular/core";
import * as i1 from "../zoom.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@ngx-translate/core";
function AudioTogglerComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 3);
    i0.ɵɵelement(2, "use", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function AudioTogglerComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(1, "svg", 3);
    i0.ɵɵelement(2, "use", 5);
    i0.ɵɵelement(3, "use", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
const _c0 = function () { return { "color": "#44A38B" }; };
const _c1 = function () { return { "color": "#E1E1E1" }; };
export class AudioTogglerComponent {
    constructor(zoomService) {
        this.zoomService = zoomService;
        this.showLabel = true;
        this.hover = false;
    }
    ngOnInit() {
        this.audioStatusSubscription = this.zoomService.audioStatus$().subscribe(audioStatus => {
            if (audioStatus === 'STARTED') {
                this.isAudioEnabled = true;
            }
            else {
                this.isAudioEnabled = false;
            }
        });
    }
    toggleAudio() {
        this.zoomService.toggleAudio();
    }
    getTooltipClass() {
        if (this.tooltipPosition === 'below')
            return 'feedback-tool-tip-below';
        else if (this.tooltipPosition === 'above')
            return 'feedback-tool-tip-above';
        else
            return '';
    }
    ngOnDestroy() {
        var _a;
        (_a = this.audioStatusSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
}
AudioTogglerComponent.ɵfac = function AudioTogglerComponent_Factory(t) { return new (t || AudioTogglerComponent)(i0.ɵɵdirectiveInject(i1.ZoomService)); };
AudioTogglerComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AudioTogglerComponent, selectors: [["audio-toggler"]], inputs: { showLabel: "showLabel", tooltipPosition: "tooltipPosition" }, decls: 5, vars: 11, consts: [[1, "box-button", "box-button-toggle", "d-flex", "align-items-center", "justify-content-center", "m-1", 3, "ngClass", "ngStyle", "matTooltipPosition", "matTooltipClass", "matTooltip", "click", "mouseover", "mouseleave"], [1, "m-1"], [4, "ngIf"], ["width", "22", "height", "22"], ["id", "actual-icon", 0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#microphone-light"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#microphone"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#diagonal"]], template: function AudioTogglerComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function AudioTogglerComponent_Template_div_click_0_listener() { return ctx.toggleAudio(); })("mouseover", function AudioTogglerComponent_Template_div_mouseover_0_listener() { return ctx.hover = true; })("mouseleave", function AudioTogglerComponent_Template_div_mouseleave_0_listener() { return ctx.hover = false; });
        i0.ɵɵpipe(1, "translate");
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵtemplate(3, AudioTogglerComponent_div_3_Template, 3, 0, "div", 2);
        i0.ɵɵtemplate(4, AudioTogglerComponent_div_4_Template, 4, 0, "div", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("ngClass", ctx.isAudioEnabled ? "box-button-toggle-active" : "")("ngStyle", ctx.hover ? i0.ɵɵpureFunction0(9, _c0) : i0.ɵɵpureFunction0(10, _c1))("matTooltipPosition", ctx.tooltipPosition)("matTooltipClass", "audio-feedback-tool-tip-" + ctx.tooltipPosition)("matTooltip", i0.ɵɵpipeBind1(1, 7, ctx.isAudioEnabled ? "sessionChat.coachSessionControl.muteMicrophone" : "sessionChat.coachSessionControl.turnOnMicrophone"));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.isAudioEnabled);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isAudioEnabled);
    } }, directives: [i2.NgClass, i2.NgStyle, i3.MatTooltip, i2.NgIf], pipes: [i4.TranslatePipe], styles: [".box-button[_ngcontent-%COMP%]{font-family:Poppins,sans-serif;font-weight:400;width:80px;height:50px;color:#44a38b;background:#f7eed2;border-radius:8px;flex-direction:column;cursor:pointer}.box-button-toggle[_ngcontent-%COMP%]:active, .box-button-dropdown[_ngcontent-%COMP%]:hover, .box-button-toggle-active[_ngcontent-%COMP%]{color:#f7eed2;background:#44a38b}[_nghost-%COMP%]  .audio-feedback-tool-tip-below{color:#fff !important;background:#44a38b !important;border:1px solid #44a38b !important;overflow:visible !important;font-size:14px !important}[_nghost-%COMP%]  .audio-feedback-tool-tip-below:before{content:\"\";position:absolute;left:calc(50% - 10px) !important;top:-11px;z-index:10000;border-bottom:10px solid #44a38b;border-left:10px solid transparent;border-right:10px solid transparent}[_nghost-%COMP%]  .audio-feedback-tool-tip-above{color:#fff !important;background:#44a38b !important;border:1px solid #44a38b !important;overflow:visible !important;font-size:14px !important}[_nghost-%COMP%]  .audio-feedback-tool-tip-above:before{content:\"\";position:absolute;left:calc(50% - 10px) !important;top:34px !important;transform:rotate(180deg);z-index:10000;border-bottom:10px solid #44a38b;border-left:10px solid transparent;border-right:10px solid transparent}"] });
