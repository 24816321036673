import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ENV, VERSION } from '../../app.constants';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RefreshRequestComponent } from '../../shared/refresh-request/refresh-request.component';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
export class ApplicationVersionInterceptor {
    constructor(modalService) {
        this.modalService = modalService;
        this.refreshRequestDisplayed = false;
    }
    intercept(request, next) {
        return next.handle(request).pipe(tap((event) => {
            if (event instanceof HttpResponse) {
                event.headers.keys().forEach(entry => {
                    if (entry === "X-Inuka-Version") {
                        let v = event.headers.get(entry);
                        if (v !== VERSION && ENV === 'production' && !this.refreshRequestDisplayed) {
                            this.modalService.open(RefreshRequestComponent, { centered: true });
                            this.refreshRequestDisplayed = true;
                        }
                    }
                });
            }
        }));
    }
}
ApplicationVersionInterceptor.ɵfac = function ApplicationVersionInterceptor_Factory(t) { return new (t || ApplicationVersionInterceptor)(i0.ɵɵinject(i1.NgbModal)); };
ApplicationVersionInterceptor.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ApplicationVersionInterceptor, factory: ApplicationVersionInterceptor.ɵfac });
