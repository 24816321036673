import { OrganizationDashboardService } from '../../organization-dashboard.service';
import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i3 from "@angular/common";
import * as i4 from "ngx-echarts";
const _c0 = function () { return { renderer: "svg" }; };
function OrganizationUsageOverTimeComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 4);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("options", ctx_r0.chartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
export class OrganizationUsageOverTimeComponent {
    constructor(organizationDashboardService) {
        this.organizationDashboardService = organizationDashboardService;
        this.isLoadingData = false;
        this.chartOption = {
            title: {
                show: false
            },
            grid: {
                top: 10,
                left: '7%',
                right: '5%',
                bottom: 60
            },
            legend: {
                orient: 'horizontal',
                bottom: -3,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            tooltip: {
                trigger: 'axis',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                axisPointer: {
                    label: {
                        formatter(params) {
                            let date = new Date(params.value);
                            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                        }
                    }
                }
            },
            yAxis: {
                type: 'value',
                minInterval: 1,
                min: 0,
                max(value) {
                    if (value.max < 10) {
                        return 10;
                    }
                    else {
                        return value.max;
                    }
                },
                splitLine: {
                    show: true
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            xAxis: {
                type: 'time',
                boundaryGap: false,
                minInterval: 3600 * 1000 * 24,
                splitLine: {
                    show: true
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'",
                    hideOverlap: true
                }
            },
            series: [
                {
                    name: 'New sign-ups over time',
                    type: 'line',
                    color: '#EDB458',
                    encode: {
                        x: 'timestamp',
                        y: 'Registrations' // refer dimensions name
                    },
                    symbol: 'none',
                    lineStyle: {
                        cap: 'round'
                    },
                    animationDuration: 3000
                },
                {
                    name: 'Scans done over time',
                    type: 'line',
                    color: '#3CCAEE',
                    encode: {
                        x: 'timestamp',
                        y: 'Srq' // refer dimensions name
                    },
                    symbol: 'none',
                    lineStyle: {
                        cap: 'round'
                    },
                    animationDuration: 3000
                },
                {
                    name: 'First-sessions over time',
                    type: 'line',
                    color: '#84A49C',
                    encode: {
                        x: 'timestamp',
                        y: 'First-sessions' // refer dimensions name
                    },
                    symbol: 'none',
                    lineStyle: {
                        cap: 'round'
                    },
                    animationDuration: 3000
                },
                {
                    name: 'Sessions done',
                    type: 'line',
                    color: '#0A4A3A',
                    encode: {
                        x: 'timestamp',
                        y: 'Sessions' // refer dimensions name
                    },
                    symbol: 'none',
                    lineStyle: {
                        cap: 'round'
                    },
                    animationDuration: 3000
                }
            ]
        };
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationUsageOverTimeReport(this.parameters).subscribe(response => {
            this.report = response.body;
            console.log(this.report);
            this.refreshChartData();
            this.isLoadingData = false;
        }, error => {
            this.isLoadingData = false;
        });
    }
    refreshChartData() {
        var _a;
        const dateset = [];
        if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.usagePerDay) {
            for (const reportItem of this.report.usagePerDay) {
                dateset.push([
                    reportItem.date,
                    reportItem.registrationCount,
                    reportItem.srqCount,
                    reportItem.firstSessionCount,
                    reportItem.sessionCount,
                ]);
            }
        }
        this.chartOption.dataset = {
            source: dateset,
            dimensions: ['timestamp', 'Registrations', 'Srq', 'First-sessions', 'Sessions']
        };
    }
}
OrganizationUsageOverTimeComponent.ɵfac = function OrganizationUsageOverTimeComponent_Factory(t) { return new (t || OrganizationUsageOverTimeComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService)); };
OrganizationUsageOverTimeComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationUsageOverTimeComponent, selectors: [["organization-usage-over-time"]], inputs: { parameters: "parameters" }, decls: 4, vars: 1, consts: [[1, "card", "dashboard-card", "pb-5", "pl-2", "pt-2"], ["jhiTranslate", "organizationDashboard.overview.usageOverTimeHeader", 1, "mt-3", "ml-3", "light-weight"], ["jhiTranslate", "organizationDashboard.overview.usageOverTimeIntro", 1, "mb-3", "ml-3"], [4, "ngIf"], ["echarts", "", 2, "height", "400px", 3, "options", "initOpts"]], template: function OrganizationUsageOverTimeComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "h4", 1);
        i0.ɵɵelement(2, "div", 2);
        i0.ɵɵtemplate(3, OrganizationUsageOverTimeComponent_ng_container_3_Template, 2, 3, "ng-container", 3);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.isLoadingData && (ctx.chartOption == null ? null : ctx.chartOption.series.length));
    } }, directives: [i2.JhiTranslateDirective, i3.NgIf, i4.NgxEchartsDirective], styles: [""] });
