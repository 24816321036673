import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SERVER_API_URL } from '../../app.constants';
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-webstorage";
import * as i3 from "@angular/router";
export class AuthServerProvider {
    constructor(http, $localStorage, $sessionStorage, router) {
        this.http = http;
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.router = router;
    }
    getToken() {
        return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken') || '';
    }
    login(credentials) {
        console.log("credentials:");
        console.log(credentials);
        const headers = new HttpHeaders().set('timeout', '60000');
        return this.http
            .post(SERVER_API_URL + 'api/authentication/login', credentials, { headers })
            .pipe(map(response => {
            console.log(response);
            if (response.jwtToken) {
                this.authenticateSuccess(response.jwtToken.id_token, credentials.rememberMe);
            }
            else if (response.identificationUrl) { // redirect to authentication url
                this.router.navigate(['redirect-to-idp'], {
                    state: {
                        identificationUrl: response.identificationUrl,
                        identityProvider: response.identityProvider
                    }
                });
            }
            return response;
        }));
    }
    loginViaOAuth(oAuthAuthenticationInput) {
        const headers = new HttpHeaders().set('timeout', '60000');
        return this.http
            .post(SERVER_API_URL + 'api/authentication/oauth/verify', oAuthAuthenticationInput, { headers })
            .pipe(map(response => {
            console.log(response);
            if (response.jwtToken) {
                this.authenticateSuccess(response.jwtToken.id_token, oAuthAuthenticationInput.rememberMe);
            }
            else if (response.identificationUrl) { // redirect to authentication url
                this.router.navigate(['redirect-to-idp'], {
                    state: {
                        identificationUrl: response.identificationUrl,
                        identityProvider: response.identityProvider
                    }
                });
            }
            return response;
        }));
    }
    logout() {
        return new Observable(observer => {
            this.$localStorage.clear('authenticationToken');
            this.$sessionStorage.clear('authenticationToken');
            observer.complete();
        });
    }
    authenticateSuccess(jwt, rememberMe) {
        console.log('authenticateSuccess');
        if (rememberMe) {
            this.$localStorage.store('authenticationToken', jwt);
        }
        else {
            this.$sessionStorage.store('authenticationToken', jwt);
        }
    }
    authenticateEmail(email) {
        return this.http.post(SERVER_API_URL + 'api/authenticate-email', email, { responseType: 'text' });
    }
    loginViaMagicLink(magicLoginAuthenticationInput) {
        const headers = new HttpHeaders().set('timeout', '60000');
        return this.http
            .post(SERVER_API_URL + 'api/authentication/magic-login/verify', magicLoginAuthenticationInput, { headers })
            .pipe(map(response => {
            console.log(response);
            if (response.jwtToken) {
                this.authenticateSuccess(response.jwtToken.id_token, true);
            }
            else if (response.identificationUrl) { // redirect to authentication url
                this.router.navigate(['redirect-to-idp'], {
                    state: {
                        identificationUrl: response.identificationUrl,
                        identityProvider: response.identityProvider
                    }
                });
            }
            return response;
        }));
    }
    loginAsGhost(ghostUserDto) {
        const headers = new HttpHeaders().set('timeout', '60000');
        return this.http.post(SERVER_API_URL + 'api/register-ghost', ghostUserDto, { headers }).pipe(map(response => {
            this.authenticateSuccess(response.id_token, true);
            return true;
        }));
    }
    loginByJwtToken(jwt) {
        this.authenticateSuccess(jwt.id_token, true);
    }
}
AuthServerProvider.ɵfac = function AuthServerProvider_Factory(t) { return new (t || AuthServerProvider)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i2.SessionStorageService), i0.ɵɵinject(i3.Router)); };
AuthServerProvider.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthServerProvider, factory: AuthServerProvider.ɵfac, providedIn: 'root' });
