import { SERVER_API_URL } from '../../app.constants';
import { HttpClient } from "@angular/common/http";
import { JhiEventManager } from '../../ng-jhipster/service';
import { DatePipe } from "@angular/common";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../ng-jhipster/service";
import * as i3 from "@angular/common";
export class WhatsappService {
    constructor(http, eventManager, datePipe) {
        this.http = http;
        this.eventManager = eventManager;
        this.datePipe = datePipe;
        this.resourceUrl = SERVER_API_URL + 'api/sessions';
    }
}
WhatsappService.ɵfac = function WhatsappService_Factory(t) { return new (t || WhatsappService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JhiEventManager), i0.ɵɵinject(i3.DatePipe)); };
WhatsappService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: WhatsappService, factory: WhatsappService.ɵfac, providedIn: 'root' });
