import { ErrorComponent } from './error.component';
export const errorRoute = [
    {
        path: 'error',
        component: ErrorComponent,
        data: {
            authorities: [],
            pageTitle: 'error.title'
        }
    },
    {
        path: 'accessdenied',
        component: ErrorComponent,
        data: {
            authorities: [],
            pageTitle: 'error.title',
            errorMessage: 'error.http.403'
        }
    },
    {
        path: '404',
        component: ErrorComponent,
        data: {
            authorities: [],
            pageTitle: 'error.title',
            errorMessage: 'error.http.404'
        }
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];
