import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/language/jhi-translate.directive";
const _c0 = function (a0, a1) { return { inukaSubstitute: a0, coachSubstitute: a1 }; };
const _c1 = function (a0) { return { coachSubstitute: a0 }; };
export class WhatPeopleSayComponent {
    constructor() {
        this.inukaSubstitute = window.userCountry === 'Zimbabwe' ? 'Friendship Bench - Inuka' : 'Inuka';
        this.coachSubstitute = window.userCountry === 'Zimbabwe' ? 'buddy' : 'coach';
    }
    ngOnInit() {
    }
}
WhatPeopleSayComponent.ɵfac = function WhatPeopleSayComponent_Factory(t) { return new (t || WhatPeopleSayComponent)(); };
WhatPeopleSayComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WhatPeopleSayComponent, selectors: [["what-people-say"]], decls: 13, vars: 11, consts: [[1, "page", 2, "background", "white"], [1, "page-single", 2, "background-color", "white", "border-top-left-radius", "20px", "border-top-right-radius", "20px", "margin-top", "100px"], [1, "container-fluid"], [1, "row", "fadeIn", "animated"], [1, "offset-md-2", "col-md-8", "offset-md-2", "offset-lg-4", "col-lg-4", "offset-lg-4", 2, "padding-left", "45px", "padding-right", "45px"], ["jhiTranslate", "whatPeopleSay.title", 1, "text-uppercase", "text-center", 2, "margin-top", "40px"], [2, "margin-bottom", "40px", "font-size", "14px", "text-align", "justify"], ["jhiTranslate", "whatPeopleSay.commentsFrom", 3, "translateValues"], [2, "color", "#44A38D", "margin-top", "30px", "margin-bottom", "30px"], ["jhiTranslate", "whatPeopleSay.itWasAGood"], ["jhiTranslate", "whatPeopleSay.iReallyLoved"], ["jhiTranslate", "whatPeopleSay.iLovedTheSession", 3, "translateValues"], ["jhiTranslate", "whatPeopleSay.methodIsDesigned", 3, "translateValues"]], template: function WhatPeopleSayComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelement(5, "h2", 5);
        i0.ɵɵelementStart(6, "div", 6);
        i0.ɵɵelement(7, "p", 7);
        i0.ɵɵelementStart(8, "div", 8);
        i0.ɵɵelement(9, "p", 9);
        i0.ɵɵelement(10, "p", 10);
        i0.ɵɵelement(11, "p", 11);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(12, "p", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(7);
        i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction2(3, _c0, ctx.inukaSubstitute, ctx.coachSubstitute));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(6, _c1, ctx.coachSubstitute));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction2(8, _c0, ctx.inukaSubstitute, ctx.coachSubstitute));
    } }, directives: [i1.JhiTranslateDirective], styles: [""] });
