import { DayAvailableCoachSlots } from '../../../session/schedule-session/available-coach-slots/day-available-coach-slots.model';
import moment from "moment";
export class AvailableCoachSlots {
    constructor(availableSessionResponse) {
        this.init(availableSessionResponse);
    }
    init(availableSessionResponse) {
        const coachesDetailsMap = new Map();
        if (availableSessionResponse.coachDetails) {
            availableSessionResponse.coachDetails.forEach(coachDetails => {
                coachesDetailsMap.set(coachDetails.coachId, coachDetails);
            });
        }
        const coachesReasoningMap = new Map();
        if (availableSessionResponse.coachReasonings) {
            availableSessionResponse.coachReasonings.forEach(coachReasoning => {
                let coachReasonings = coachesReasoningMap.get(coachReasoning.coach.id);
                if (!coachReasonings) {
                    coachReasonings = [];
                    coachesReasoningMap.set(coachReasoning.coach.id, coachReasonings);
                }
                coachReasonings.push(coachReasoning);
            });
        }
        const dayAvailableCoachesSlotsMap = new Map();
        if (availableSessionResponse.coachesSlots) {
            for (const slots of availableSessionResponse.coachesSlots) {
                const coach = slots.coach;
                for (const sessionStartTime of slots.sessionStartTimes) {
                    const day = moment(sessionStartTime).startOf("day");
                    let dayAvailableCoachSlots = dayAvailableCoachesSlotsMap.get(day.unix());
                    if (!dayAvailableCoachSlots) {
                        dayAvailableCoachSlots = new DayAvailableCoachSlots(day);
                        dayAvailableCoachesSlotsMap.set(day.unix(), dayAvailableCoachSlots);
                    }
                    dayAvailableCoachSlots.addCoachSlot(coach, sessionStartTime, coachesDetailsMap.get(coach.id), coachesReasoningMap.get(coach.id));
                }
            }
            this.days = [];
            for (const date of dayAvailableCoachesSlotsMap.keys()) {
                this.days.push(dayAvailableCoachesSlotsMap.get(date));
            }
            this.days.sort((a, b) => a.day.unix() - b.day.unix());
        }
    }
}
