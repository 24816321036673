import { ActivatedRoute, ActivationStart, Router, RoutesRecognized } from "@angular/router";
import { filter, pairwise } from "rxjs/operators";
import { AccountService } from '../auth/account.service';
import { LoginService } from '../login/login.service';
import { SessionStorageService } from "ngx-webstorage";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../auth/account.service";
import * as i3 from "../login/login.service";
import * as i4 from "ngx-webstorage";
export const flowConfig = {
    "ssf": {
        "name": "ssf",
        "displayName": "Self scan first",
        "state": "waiting",
        "entryPoint": "/wellbeing-start",
        "exitPoint": "/wellbeing-result",
        "skipFlowIfAuthenticated": true,
        "queryParamNames": ["flow", "organizationCode", "langKey"],
        "queryParamMap": {},
        "data": {
            "organization": ""
        },
        "redirects": [
            { "from": "/index-user", "to": "/wellbeing-result" },
        ],
        "returnableRedirects": [
            { "from": "/session/schedule-session", "to": "/register-corporate" },
            { "from": "/match-with-a-coach-start", "to": "/register-corporate" },
        ]
    },
    "ssf-kenya": {
        "name": "ssf-kenya",
        "displayName": "Self scan first (Kenya register page)",
        "state": "waiting",
        "entryPoint": "/wellbeing-start",
        "exitPoint": "/wellbeing-result",
        "skipFlowIfAuthenticated": true,
        "queryParamNames": ["flow", "organizationCode", "langKey"],
        "queryParamMap": {},
        "data": {
            "organization": ""
        },
        "redirects": [
            { "from": "/register-corporate", "to": "/register-kenya" },
            { "from": "/index-user", "to": "/wellbeing-result" },
        ],
        "returnableRedirects": [
            { "from": "/session/schedule-session", "to": "/register-kenya" },
            { "from": "/match-with-a-coach-start", "to": "/register-kenya" },
        ]
    },
    "sss": {
        "name": "sss",
        "displayName": "Standalone Self scan (without registration)",
        "state": "waiting",
        "entryPoint": "/wellbeing-start-standalone",
        "exitPoint": "/",
        "skipFlowIfAuthenticated": true,
        "queryParamNames": ["flow", "organizationCode", "langKey", "callback"],
        "queryParamMap": {},
        "data": {
            "organization": ""
        },
        "redirects": [
            { "from": "/register-corporate", "to": "/wellbeing-result" }
        ],
    },
    "rf": {
        "name": "rf",
        "displayName": "Register first",
        "state": "waiting",
        "entryPoint": "/register-corporate",
        "exitPoint": "/wellbeing-start",
        "skipFlowIfAuthenticated": true,
        "queryParamNames": ["flow", "organizationCode", "langKey"],
        "queryParamMap": {},
        "data": {
            "organization": ""
        },
        "redirects": [
            { "from": "/index-user", "to": "/wellbeing-start" },
        ],
    },
    "b2c": {
        "name": "b2c",
        "displayName": "Register first B2C",
        "state": "waiting",
        "entryPoint": "/register-new",
        "exitPoint": "/wellbeing-start",
        "skipFlowIfAuthenticated": true,
        "queryParamNames": ["flow", "organizationCode", "langKey"],
        "queryParamMap": {},
        "data": {
            "organization": ""
        },
        "redirects": [
            { "from": "/index-user", "to": "/wellbeing-start" },
        ],
    },
    "rf-kenya": {
        "name": "rf-kenya",
        "displayName": "Register first (Kenya register page)",
        "state": "waiting",
        "entryPoint": "/register-kenya",
        "exitPoint": "/wellbeing-start",
        "skipFlowIfAuthenticated": true,
        "queryParamNames": ["flow", "organizationCode", "langKey"],
        "queryParamMap": {},
        "data": {
            "organization": ""
        },
        "redirects": [
            { "from": "/index-user", "to": "/wellbeing-start" },
        ],
    }
};
export class FlowManagerService {
    constructor(router, accountService, loginService, sessionStorage, activatedRoute) {
        this.router = router;
        this.accountService = accountService;
        this.loginService = loginService;
        this.sessionStorage = sessionStorage;
        this.activatedRoute = activatedRoute;
        console.log("flow RouteService initialized");
    }
    /** call this method in Angular Module constructor to subscribe to events on application start (not on component initiation) and don't miss any event */
    init() {
        this.router.events.subscribe(e => {
            if (e instanceof ActivationStart) {
                this.onActivationStart(e);
            }
            else {
                // console.log("flow not Activation start");
            }
        });
        this.router.events
            .pipe(filter(e => e instanceof RoutesRecognized), pairwise())
            .subscribe((events) => {
            if (events && events.length) {
                this.previousUrl = events[0].urlAfterRedirects;
            }
        });
    }
    onActivationStart(e) {
        let currentFlow = this.getCurrentFlow();
        if (currentFlow && this.flowInRunningStatus()) {
            const currentPath = this.getPath(e.snapshot);
            if (currentFlow.redirects) {
                for (let redirect of currentFlow.redirects) {
                    if (redirect.from === currentPath && redirect.to !== currentPath) {
                        this.router.navigate([redirect.to]).then();
                        return;
                    }
                }
            }
            if (currentFlow.returnableRedirects) {
                for (let redirect of currentFlow.returnableRedirects) {
                    if (redirect.from === currentPath && redirect.to !== currentPath) {
                        this.router.navigate([redirect.to]).then();
                        this.redirectedFromReturnableUrl = currentPath;
                        return;
                    }
                }
            }
            if (currentPath === currentFlow.exitPoint) {
                this.finishFlow(currentFlow);
            }
        }
    }
    getPath(activatedRouteSnapshot) {
        if (activatedRouteSnapshot['_routerState']) {
            const url = activatedRouteSnapshot['_routerState'].url;
            const queryStringStartIndex = url.indexOf('?');
            if (queryStringStartIndex >= 0) {
                return url.substring(0, queryStringStartIndex);
            }
            else {
                return url;
            }
        }
        return "";
    }
    getFlow(flow) {
        return flowConfig[flow];
    }
    startFlowByName(flowShortName) {
        let flow = this.getFlow(flowShortName);
        if (flow) {
            this.clearFlowStoredParams();
            if (flow["skipFlowIfAuthenticated"] && this.accountService.isAuthenticated()) {
                this.loginService.redirectToHomepage();
            }
            else {
                this.startFlow(flow);
            }
        }
    }
    continueFlowByName(flowShortName) {
        let flow = this.getFlow(flowShortName);
        if (flow) {
            this.clearFlowStoredParams();
            this.setCurrentFlow(flow);
            console.log("continue flow waiting -> running");
            this.resetFlowData(flow);
            this.resetFlowState();
        }
    }
    startFlow(flow) {
        if (this.flowInWaitingStatus()) { // start flow
            // Store queryParams in SessionStorage since it is difficult to pass data among multiple steps.
            this.activatedRoute.snapshot.queryParamMap.keys.forEach(k => this.sessionStorage.store(k, this.activatedRoute.snapshot.queryParamMap.get(k)));
            this.setCurrentFlow(flow);
            console.log("flow startFlow waiting -> running");
            this.resetFlowData(flow);
            this.resetFlowState();
            this.navigateToEntryPoint(flow);
        }
        else if (this.flowInRunningStatus()) {
        }
        else if (this.flowInFinishedStatus()) {
        }
        else {
            console.error("flow Invalid state: " + this.getFlowState());
        }
    }
    setCurrentFlow(flow) {
        this.sessionStorage.store("flowName", flow["name"]);
    }
    getCurrentFlow() {
        let flowName = this.sessionStorage.retrieve("flowName");
        if (flowName) {
            return this.getFlow(flowName);
        }
        return null;
    }
    isAnyFlowInProgress() {
        if (this.getCurrentFlow()) {
            return true;
        }
        else {
            return false;
        }
    }
    isFlowInProgress(flowName) {
        var _a;
        if (((_a = this.getCurrentFlow()) === null || _a === void 0 ? void 0 : _a.name) === flowName) {
            return true;
        }
        else {
            return false;
        }
    }
    resetFlowData(flow) {
        // TODO use flow["data"] instead of hardcoding flow data
        console.log("flow clearing session data");
        this.sessionStorage.clear("srqAnswers");
        this.sessionStorage.clear("helpTopics");
        if (flow["queryParameterMap"] !== undefined) {
            let queryParamNames = flow["queryParamNames"];
            for (let i = 0; i < queryParamNames.length; i++) {
                let paramName = queryParamNames[i];
                let flowElement = flow["queryParameterMap"];
                let paramVal = flowElement[paramName];
                console.log("flow :" + paramName + ": " + paramVal);
                this.sessionStorage.store(paramName, paramVal);
            }
        }
    }
    getFlowParameterValues() {
        const flow = this.getCurrentFlow();
        const parameterValues = {};
        if (flow.queryParamNames) {
            let queryParamNames = flow.queryParamNames;
            for (let i = 0; i < queryParamNames.length; i++) {
                const paramName = queryParamNames[i];
                const paramValue = this.sessionStorage.retrieve(paramName);
                if (paramValue) {
                    parameterValues[paramName] = paramValue;
                }
            }
        }
        return parameterValues;
    }
    getFlowParameterValue(paramName) {
        if (this.isAnyFlowInProgress()) {
            return this.sessionStorage.retrieve(paramName);
        }
    }
    getFlowParameter(parameterName) {
        const flow = this.getCurrentFlow();
        if (flow["queryParamNames"] !== undefined) {
            let queryParamNames = flow["queryParamNames"];
            for (let i = 0; i < queryParamNames.length; i++) {
                let paramName = queryParamNames[i];
                if (paramName === parameterName) {
                    return this.sessionStorage.retrieve(parameterName);
                }
            }
        }
        return null;
    }
    resetFlowState() {
        this.setFlowState("running");
    }
    flowInWaitingStatus() {
        return this.getFlowState() === "waiting";
    }
    flowInRunningStatus() {
        return this.getFlowState() === "running";
    }
    flowInFinishedStatus() {
        return this.getFlowState() === "finished";
    }
    getFlowState() {
        let retrieve = this.sessionStorage.retrieve("flowState");
        if (retrieve === null) {
            console.log("flow initialize state");
            this.setFlowState("waiting");
        }
        return this.sessionStorage.retrieve("flowState");
    }
    setFlowState(state) {
        // flow["state"] = state;
        this.sessionStorage.store("flowState", state);
    }
    navigateToEntryPoint(flow) {
        let entryPoint = flow["entryPoint"];
        this.router.navigate([entryPoint], { queryParamsHandling: "preserve" });
    }
    getPreviousUrl() {
        return this.previousUrl;
    }
    finishFlow(flow) {
        this.resetFlowData(flow);
        this.clearFlowStoredParams();
        this.redirectedFromReturnableUrl = null;
    }
    clearFlowStoredParams() {
        this.sessionStorage.clear("flowState");
        this.sessionStorage.clear("flowName");
        this.sessionStorage.clear("flow");
    }
    getActiveFlows() {
        const flowConfigs = [];
        Object.entries(flowConfig).forEach(([key, value], index) => {
            flowConfigs.push(value);
        });
        flowConfigs.sort((a, b) => a.name.localeCompare(b.name));
        return flowConfigs;
    }
    getFlowNameFromUrlQueryParams() {
        let flowName = this.activatedRoute.snapshot.queryParamMap.get(FlowManagerService.FLOW_PARAMETER_NAME);
        return flowName;
    }
    getEmailFromUrlQueryParams() {
        let email = this.activatedRoute.snapshot.queryParamMap.get(FlowManagerService.EMAIL_PARAMETER_NAME);
        return email;
    }
    getReturnPath(flow) {
        var _a, _b;
        const currentUrl = this.router.url;
        const currentPath = this.getPath(this.activatedRoute.snapshot);
        if (flow.returnableRedirects) {
            const returnableMatchedWithToAndFrom = (_a = flow.returnableRedirects.find(value => value.to === currentPath && value.from === this.redirectedFromReturnableUrl)) === null || _a === void 0 ? void 0 : _a.from;
            if (returnableMatchedWithToAndFrom) {
                return returnableMatchedWithToAndFrom;
            }
            else {
                // just match using to
                return (_b = flow.returnableRedirects.find(value => value.to === currentPath)) === null || _b === void 0 ? void 0 : _b.from;
            }
        }
        return null;
    }
    navigateToNextPage(nextPagePath) {
        if (this.isAnyFlowInProgress() && this.redirectedFromReturnableUrl) {
            let currentFlow = this.getCurrentFlow();
            const returnPath = this.getReturnPath(currentFlow);
            if (returnPath) {
                this.finishFlow(currentFlow);
                this.router.navigate([returnPath]).then();
                return;
            }
        }
        this.router.navigate([nextPagePath]).then();
    }
}
FlowManagerService.FLOW_PARAMETER_NAME = 'flow';
FlowManagerService.EMAIL_PARAMETER_NAME = 'email';
FlowManagerService.ɵfac = function FlowManagerService_Factory(t) { return new (t || FlowManagerService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AccountService), i0.ɵɵinject(i3.LoginService), i0.ɵɵinject(i4.SessionStorageService), i0.ɵɵinject(i1.ActivatedRoute)); };
FlowManagerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FlowManagerService, factory: FlowManagerService.ɵfac, providedIn: 'root' });
