export class CoachStatistics {
    constructor(coachId, 
    // public clients?: number,
    sessionsBooked, sessionsDone, 
    // public minutesCoached?: number,
    // public clientsFlagged?: number,
    clientNoShow, yourNoShow, 
    // public yourNoShow?: number,
    // public yourRating?: number,
    // public numberOfReviews?: number,
    startDate, endDate) {
        this.coachId = coachId;
        this.sessionsBooked = sessionsBooked;
        this.sessionsDone = sessionsDone;
        this.clientNoShow = clientNoShow;
        this.yourNoShow = yourNoShow;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
