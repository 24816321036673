import { JhiEventManager } from '../../ng-jhipster/service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import * as Sentry from "@sentry/angular";
import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/service";
export const slowResponseTimeoutMilliSec = 15000;
export class ErrorHandlerInterceptor {
    constructor(eventManager) {
        this.eventManager = eventManager;
        this.serverIsDown = false;
        this.excludedInterceptRequests = ['api/coach-slot/all-dashboard-for-excel'];
        this.displayLoadingSpinnerRequests = [
            'api/authenticate', 'api/register', 'api/sessions/available', 'api/sessions/schedule', '/api/sessions/all', 'api/sessions/currentUser',
            '/api/users/all', '/api/conversations/conversation', 'api/messages/conversationId', 'api/messages/sessionId', 'api/messages/get',
            'api/messages/messages-for-session', 'api/messages/userId', 'api/get', 'api/questionarre-answers/save', 'all-dashboard-for-excel',
            'all-available-for-excel', 'all-real-for-excel'
        ];
    }
    intercept(request, next) {
        let method = request.method;
        let urlWithParams = request.urlWithParams;
        const startedTimestamp = performance.now();
        let started = true;
        // const slowResponseTimeout = setTimeout(() => {
        //   if (started) {
        //     const elapsed = Date.now() - startedTimestamp;
        //     for (const x of this.excludedInterceptRequests) {
        //       if (!request.url.includes(x)) {
        //         debug("HTTP connection slow, " + urlWithParams + ", elapsed: " + elapsed );
        //         this.eventManager.broadcast({name: "slowInternetSpeed", content: ""});
        //         return;
        //       }
        //     }
        //   }
        // }, slowResponseTimeoutMilliSec);
        let ok;
        this.displayLoadingSpinner(request.url);
        return next.handle(request).pipe(tap((event) => {
            ok = event instanceof HttpResponse ? 'succeeded' : '';
            this.serverIsDown = false;
        }, (err) => {
            if (err.name && (err.name).toString() === 'TimeoutError') {
                this.eventManager.broadcast({ name: 'requestTimeout', content: err });
                return;
            }
            ok = "failed";
            if (!navigator.onLine) {
                console.log("Navigator offline");
            }
            if (!(err.status === 401 && (err.message === '' || (err.url && err.url.includes('api/account'))))) {
                if (err.status !== 504 && err.status !== 406 && err.status !== 404) {
                    console.log(err);
                    this.eventManager.broadcast({ name: 'inukaNgApp.httpError', content: err });
                }
            }
            // 504: webpack dev server in dev mode
            // 502: aws load balancer
            if ((err.status === 504 || err.status === 502) && !this.serverIsDown) {
                this.serverIsDown = true;
                this.eventManager.broadcast({ name: 'serverIsDown', content: "" });
            }
            else if (err.status === 0) {
                for (const x of this.excludedInterceptRequests) {
                    if (!request.url.includes(x)) {
                        this.eventManager.broadcast({ name: 'internetIsDown', content: "" });
                        return;
                    }
                }
            }
        }), finalize(() => {
            // if (slowResponseTimeout) {
            //   clearTimeout(slowResponseTimeout);
            // }
            started = false;
            this.eventManager.broadcast("httpRequestFinished");
            const elapsed = performance.now() - startedTimestamp;
            if (elapsed > slowResponseTimeoutMilliSec) {
                this.eventManager.broadcast({ name: "slowInternetSpeed", content: "" });
                Sentry.captureException(new Error(`Highly Slow response: ${method} "${urlWithParams}", elapsed: ${elapsed}`));
            }
            else if (elapsed > 5000) {
                console.warn(`Warning! ${method} "${urlWithParams}" took ${elapsed} ms.`);
                Sentry.captureException(new Error(`Slow response: ${method} "${urlWithParams}", elapsed: ${elapsed}`));
            }
        }));
    }
    displayLoadingSpinner(url) {
        for (const x of this.displayLoadingSpinnerRequests) {
            if (url.includes(x)) {
                this.eventManager.broadcast("httpRequestStarted");
                return;
            }
        }
    }
}
ErrorHandlerInterceptor.ɵfac = function ErrorHandlerInterceptor_Factory(t) { return new (t || ErrorHandlerInterceptor)(i0.ɵɵinject(i1.JhiEventManager)); };
ErrorHandlerInterceptor.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ErrorHandlerInterceptor, factory: ErrorHandlerInterceptor.ɵfac });
