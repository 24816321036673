import { BaseComponent } from '../../../shared/base.component';
import { Session } from '../../../shared/model/session.model';
import { MessageService } from '../../message/message.service';
import { JhiAlertService } from '../../../ng-jhipster/service';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgZone, TemplateRef } from "@angular/core";
import { debug } from '../../../app.constants';
import moment from "moment";
import { SessionService } from '../../session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "../../message/message.service";
import * as i2 from "../../../ng-jhipster/service";
import * as i3 from "ngx-webstorage";
import * as i4 from "../../session/session.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
const _c0 = ["sessionEndsIn"];
export class SessionChatComponent extends BaseComponent {
    constructor(messageService, alertService, sessionStorage, sessionService, modalService, ngZone) {
        super(sessionStorage, modalService);
        this.messageService = messageService;
        this.alertService = alertService;
        this.sessionStorage = sessionStorage;
        this.sessionService = sessionService;
        this.modalService = modalService;
        this.ngZone = ngZone;
        this.minutesLeft = 0;
        this.secondsLeft = 0;
        this.showSessionSummary = false;
        this.endsIn = 'inukaNgApp.session.yourSessionIsGoingToEnd';
        this.secondsLeftInterval = 0;
    }
    updateMinutesLeftRecurring(type) {
        this.updateMinutesLeft();
        console.log('updateMinutesLeftRecurring, minutes left: ' + this.minutesLeft + ", seconds left: " + this.secondsLeft);
        if (this.session.ended) {
            return;
        }
        if (type === 2) {
            if (this.minutesLeft === 10) {
                this.endsInParam = 10;
                this.modalService.open(this.sessionEndsIn);
                // this.alertService.info('inukaNgApp.session.yourSessionIsGoingToEnd', {param: 10});
            }
            else if (this.minutesLeft === 5) {
                this.endsInParam = 5;
                this.modalService.open(this.sessionEndsIn);
                // this.alertService.info('inukaNgApp.session.yourSessionIsGoingToEnd', {param: 5});
            }
            else if (this.minutesLeft === 1) {
                this.displayCountdownModal();
            }
            else if (this.minutesLeft === 0) {
                // ignored since this scenario is handled in updateMinutesLeftRecurring() method
            }
        }
        // initialize timer
        if (this.minutesLeft > 0) {
            let nextRepeat = this.secondsLeft % 60 === 0 ? 60 : this.secondsLeft % 60;
            console.log("nextRepeat: " + nextRepeat + " seconds later");
            this.updateMinutesLeftRecurringHandle = setTimeout(() => {
                debug("setTimeout 1: " + this.minutesLeft);
                // this.updateMinutesLeftRecurring(type);
            }, nextRepeat * 1000);
        }
        else {
            console.log('Minutes left timer ended');
            // SESSION_EXTENDED ws message already updates session, this is added just in case.
            if (!this.session.ended)
                this.showSessionSummary = true;
            this.session.minutesLeftToSessionEnd = 0;
            Session.changeStatusOfSessionToEnded(this.session);
        }
    }
    updateMinutesLeft() {
        const sessionEnd = moment(this.session.sessionStartTime).add(this.session.duration, 'minutes');
        const now = moment.now();
        const secondsLeft = sessionEnd.diff(now, 'seconds');
        let partialMinute = secondsLeft % 60 > 0 ? 1 : 0;
        const minutesLeft = sessionEnd.diff(now, 'minutes') + partialMinute;
        this.minutesLeft = minutesLeft > 0 ? minutesLeft : 0;
        this.secondsLeft = secondsLeft > 0 ? secondsLeft : 0;
        // Following code block may be used for debugging purpopses
        // if (this.secondsLeftInterval !== 0) {
        //   clearInterval(this.secondsLeftInterval);
        // }
        //
        // this.secondsLeftInterval = setInterval(() => {
        //   this.secondsLeft--;
        //   console.log("secondsLeft: " + this.secondsLeft);
        // }, 1000);
    }
}
SessionChatComponent.ɵfac = function SessionChatComponent_Factory(t) { return new (t || SessionChatComponent)(i0.ɵɵdirectiveInject(i1.MessageService), i0.ɵɵdirectiveInject(i2.JhiAlertService), i0.ɵɵdirectiveInject(i3.SessionStorageService), i0.ɵɵdirectiveInject(i4.SessionService), i0.ɵɵdirectiveInject(i5.NgbModal), i0.ɵɵdirectiveInject(i0.NgZone)); };
SessionChatComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionChatComponent, selectors: [["ng-component"]], viewQuery: function SessionChatComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sessionEndsIn = _t.first);
    } }, features: [i0.ɵɵInheritDefinitionFeature], decls: 0, vars: 0, template: function SessionChatComponent_Template(rf, ctx) { }, encapsulation: 2 });
