import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import './vendor';
import { InukaNgSharedModule } from './shared/shared.module';
import { InukaNgCoreModule } from './core/core.module';
import { InukaNgAppRoutingModule } from './app-routing.module';
import { InukaNgEntityModule } from './entities/entity.module';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { MainComponent } from './layouts/main/main.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { ENV, SENTRY_DSN } from "./app.constants";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxUiLoaderModule, POSITION } from "ngx-ui-loader";
import * as i0 from "@angular/core";
import * as i1 from "ngx-ui-loader";
const ngxUiLoaderConfig = {
    bgsPosition: POSITION.centerCenter,
    fgsType: "three-bounce",
    minTime: 10,
    fgsSize: 60,
};
if (SENTRY_DSN) {
    Sentry.init({
        environment: ENV,
        dsn: SENTRY_DSN,
        integrations: [
        // new Integrations.BrowserTracing({ tracingOrigins: [SENTRY_TRACING_ORIGIN], routingInstrumentation: Sentry.routingInstrumentation,}),
        ],
        tracesSampleRate: 0.0,
        debug: false,
        denyUrls: [],
        release: "1",
    });
}
else {
    console.log("Sentry does not exist");
}
export class InukaNgAppModule {
}
InukaNgAppModule.ɵfac = function InukaNgAppModule_Factory(t) { return new (t || InukaNgAppModule)(); };
InukaNgAppModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgAppModule, bootstrap: [MainComponent] });
InukaNgAppModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
        NgbActiveModal,
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, logErrors: true }) },
        { provide: Sentry.TraceService, deps: [Router], },
        { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true, },
    ], imports: [[
            BrowserModule,
            BrowserAnimationsModule,
            InukaNgSharedModule,
            InukaNgCoreModule,
            // jhipster-needle-angular-add-module JHipster will add new module here
            InukaNgEntityModule,
            InukaNgAppRoutingModule,
            NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        ]] });
