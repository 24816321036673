/*
 Copyright 2016-2021 the original author or authors from the JHipster project.

 This file is part of the JHipster project, see https://www.jhipster.tech/
 for more information.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JhiModuleConfig } from './config';
import { JhiMissingTranslationHandler } from './language/jhi-missing-translation.config';
import * as i0 from "@angular/core";
import * as i1 from "./pipe/capitalize.pipe";
import * as i2 from "./pipe/filter.pipe";
import * as i3 from "./pipe/keys.pipe";
import * as i4 from "./pipe/order-by.pipe";
import * as i5 from "./pipe/pure-filter.pipe";
import * as i6 from "./pipe/truncate-characters.pipe";
import * as i7 from "./pipe/truncate-words.pipe";
import * as i8 from "./directive/max.directive";
import * as i9 from "./directive/min.directive";
import * as i10 from "./directive/maxbytes.directive";
import * as i11 from "./directive/minbytes.directive";
import * as i12 from "./directive/sort.directive";
import * as i13 from "./directive/sort-by.directive";
import * as i14 from "./component/jhi-item-count.component";
import * as i15 from "./component/jhi-boolean.component";
import * as i16 from "./component/metrics/jhi-jvm-memory.component";
import * as i17 from "./component/metrics/jhi-jvm-threads.component";
import * as i18 from "./component/metrics/jhi-metrics-request.component";
import * as i19 from "./component/metrics/jhi-metrics-endpoints-requests";
import * as i20 from "./component/metrics/jhi-metrics-cache.component";
import * as i21 from "./component/metrics/jhi-metrics-datasource.component";
import * as i22 from "./component/metrics/jhi-metrics-system.component";
import * as i23 from "./component/metrics/jhi-metrics-garbagecollector.component";
import * as i24 from "./component/metrics/jhi-metrics-modal-threads.component";
export function translatePartialLoader(http) {
    return new TranslateHttpLoader(http, 'i18n/', `.json?buildTimestamp=${process.env.BUILD_TIMESTAMP}`);
}
export function missingTranslationHandler(configService) {
    return new JhiMissingTranslationHandler(configService);
}
export class NgJhipsterModule {
    static forRoot(moduleConfig) {
        return {
            ngModule: NgJhipsterModule,
            providers: [{ provide: JhiModuleConfig, useValue: moduleConfig }],
        };
    }
}
NgJhipsterModule.ɵfac = function NgJhipsterModule_Factory(t) { return new (t || NgJhipsterModule)(); };
NgJhipsterModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: NgJhipsterModule });
NgJhipsterModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[CommonModule, NgbModule, FormsModule], CommonModule] });
