import { ElementRef, EventEmitter } from "@angular/core";
import { AccountService } from '../../../core/auth/account.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionService } from '../../session/session.service';
import { SessionControlComponent } from '../../session/session-chat-coach/session-control.component/session-control.component';
import { MessageService } from '../../message/message.service';
import { SessionStorageService } from "ngx-webstorage";
import { TranslateService } from "@ngx-translate/core";
import { CoachDetailsService } from '../../slot/coach-details.service';
import { Session } from '../../../shared/model/session.model';
import { UserService } from '../../../core/user/user.service';
import { TrackerService } from '../../../core/tracker/tracker.service';
import { JhiAlertService } from '../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../message/message.service";
import * as i2 from "../../../ng-jhipster/service";
import * as i3 from "ngx-webstorage";
import * as i4 from "../../session/session.service";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../core/auth/account.service";
import * as i8 from "../../slot/coach-details.service";
import * as i9 from "../../../core/user/user.service";
import * as i10 from "../../../core/tracker/tracker.service";
import * as i11 from "@fortawesome/angular-fontawesome";
import * as i12 from "@angular/router";
import * as i13 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i14 from "@angular/common";
import * as i15 from "../audio-toggler/audio-toggler.component";
import * as i16 from "../video-toggler/video-toggler.component";
import * as i17 from "@angular/forms";
import * as i18 from "../../../shared/auto-direction-switch/input-auto-direction-switch.directive";
import * as i19 from "../../../shared/modal-success/modal-success.component";
const _c0 = ["reportClientModal"];
function UserSessionControlLowerMobileComponent_li_10_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 16);
    i0.ɵɵlistener("click", function UserSessionControlLowerMobileComponent_li_10_Template_li_click_0_listener() { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.openReportCoachModal(); });
    i0.ɵɵelement(1, "span", 17);
    i0.ɵɵelementEnd();
} }
function UserSessionControlLowerMobileComponent_ng_template_19_div_0_small_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "small", 27);
} }
function UserSessionControlLowerMobileComponent_ng_template_19_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵelementStart(1, "div", 21);
    i0.ɵɵelement(2, "h3", 22);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div");
    i0.ɵɵelement(4, "br");
    i0.ɵɵelementStart(5, "textarea", 23);
    i0.ɵɵlistener("ngModelChange", function UserSessionControlLowerMobileComponent_ng_template_19_div_0_Template_textarea_ngModelChange_5_listener($event) { i0.ɵɵrestoreView(_r10); const ctx_r9 = i0.ɵɵnextContext(2); return ctx_r9.reasonOfFlagging = $event; });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, UserSessionControlLowerMobileComponent_ng_template_19_div_0_small_7_Template, 1, 0, "small", 24);
    i0.ɵɵelement(8, "br");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "div", 25);
    i0.ɵɵelementStart(10, "button", 26);
    i0.ɵɵlistener("click", function UserSessionControlLowerMobileComponent_ng_template_19_div_0_Template_button_click_10_listener() { i0.ɵɵrestoreView(_r10); const ctx_r11 = i0.ɵɵnextContext(2); return ctx_r11.reportClient(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(6, 3, "sessionChat.placeholder.enter"));
    i0.ɵɵproperty("ngModel", ctx_r6.reasonOfFlagging);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r6.showReasonOfFlaggingRequired);
} }
function UserSessionControlLowerMobileComponent_ng_template_19_modal_success_1_Template(rf, ctx) { if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(0, "modal-success", 28);
    i0.ɵɵlistener("dismissed", function UserSessionControlLowerMobileComponent_ng_template_19_modal_success_1_Template_modal_success_dismissed_0_listener($event) { i0.ɵɵrestoreView(_r13); const ctx_r12 = i0.ɵɵnextContext(2); return ctx_r12.onDismiss($event); });
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("titleText", "sessionChat.title.done")("message", i0.ɵɵpipeBind1(1, 2, ctx_r7.reportSuccessMessage));
} }
function UserSessionControlLowerMobileComponent_ng_template_19_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵtemplate(0, UserSessionControlLowerMobileComponent_ng_template_19_div_0_Template, 11, 5, "div", 18);
    i0.ɵɵtemplate(1, UserSessionControlLowerMobileComponent_ng_template_19_modal_success_1_Template, 2, 4, "modal-success", 19);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", !ctx_r2.flagged);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.flagged);
} }
export class UserSessionControlLowerMobileComponent extends SessionControlComponent {
    constructor(messageService, alertService, sessionStorage, sessionService, modalService, translate, accountService, coachDetailsService, userService, trackerService) {
        super(messageService, alertService, sessionStorage, sessionService, modalService, translate, accountService, coachDetailsService, trackerService);
        this.messageService = messageService;
        this.alertService = alertService;
        this.sessionStorage = sessionStorage;
        this.sessionService = sessionService;
        this.modalService = modalService;
        this.translate = translate;
        this.accountService = accountService;
        this.coachDetailsService = coachDetailsService;
        this.userService = userService;
        this.trackerService = trackerService;
        this.onLeaveCall = new EventEmitter();
        this.flagged = false;
        this.reasonOfFlagging = '';
        this.above = 'above';
    }
    ngOnInit() {
        super.ngOnInit();
    }
    leaveCall() {
        this.onLeaveCall.emit();
    }
    openReportCoachModal() {
        this.reportSuccessMessage = 'sessionChat.messages.coachIsFlagged';
        this.modalService.open(this.reportClientModal);
    }
    reportClient() {
        this.showReasonOfFlaggingRequired = false;
        this.flagged = false;
        if (this.reasonOfFlagging === '') {
            this.showReasonOfFlaggingRequired = true;
            return;
        }
        this.userService.reportClient({ id: this.session.user.id, reasonOfFlagging: this.reasonOfFlagging }, this.session.id).subscribe(() => {
            this.flagged = true;
        }, error => console.error('client cannot be flagged: ' + error));
    }
    onDismiss($event) {
        console.log($event);
        this.flagged = false;
    }
}
UserSessionControlLowerMobileComponent.ɵfac = function UserSessionControlLowerMobileComponent_Factory(t) { return new (t || UserSessionControlLowerMobileComponent)(i0.ɵɵdirectiveInject(i1.MessageService), i0.ɵɵdirectiveInject(i2.JhiAlertService), i0.ɵɵdirectiveInject(i3.SessionStorageService), i0.ɵɵdirectiveInject(i4.SessionService), i0.ɵɵdirectiveInject(i5.NgbModal), i0.ɵɵdirectiveInject(i6.TranslateService), i0.ɵɵdirectiveInject(i7.AccountService), i0.ɵɵdirectiveInject(i8.CoachDetailsService), i0.ɵɵdirectiveInject(i9.UserService), i0.ɵɵdirectiveInject(i10.TrackerService)); };
UserSessionControlLowerMobileComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: UserSessionControlLowerMobileComponent, selectors: [["user-session-control-lower-mobile"]], viewQuery: function UserSessionControlLowerMobileComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.reportClientModal = _t.first);
    } }, inputs: { session: "session" }, outputs: { onLeaveCall: "onLeaveCall" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 21, vars: 3, consts: [[1, "olha-theme"], [1, "d-flex", "align-items-center", "justify-content-around", "nav-bar"], [1, "box-button", "box-button-dropdown", "d-flex", "align-items-center", "justify-content-center", "m-1"], ["ngbDropdown", "", "placement", "top-left", 1, "d-inline-block", "text-dark", "align-middle"], ["ngbDropdownToggle", "", "href", "javascript:void(0);", 1, "language-anchor"], ["icon", "ellipsis-h", 2, "font-size", "18px"], ["ngbDropdownMenu", "", 1, "dropdown-menu"], [1, "dropdown-item", "p-0"], ["target", "_blank", "jhiTranslate", "sessionChat.button.resources", "routerLink", "/resources-for-help", 1, "item-bold", "light-green", "dropdown-item", "p-0"], ["class", "dropdown-item p-0", 3, "click", 4, "ngIf"], ["showLabel", "false", 3, "tooltipPosition"], [1, "mr-2"], [1, "stop-button", 3, "click"], ["width", "22", "height", "22"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#stop-call"], ["reportClientModal", ""], [1, "dropdown-item", "p-0", 3, "click"], ["jhiTranslate", "sessionChat.button.reportCoach", 1, "item-bold", "light-green", "dropdown-item", "p-0"], ["class", "modal-body", 4, "ngIf"], [3, "titleText", "message", "dismissed", 4, "ngIf"], [1, "modal-body"], [1, "text-center", "my-4"], ["jhiTranslate", "sessionChat.header.reason"], ["inputAutoDirectionSwitch", "", "id", "session-chat-report-reason-input", "rows", "5", 1, "form-control", 3, "placeholder", "ngModel", "ngModelChange"], ["jhiTranslate", "sessionChat.paragraph.reason", 4, "ngIf"], [1, "text-center"], ["id", "session-chat-report-submit", "type", "button", "jhiTranslate", "sessionChat.button.submit", 1, "btn-2", "btn-2-black", 3, "click"], ["jhiTranslate", "sessionChat.paragraph.reason"], [3, "titleText", "message", "dismissed"]], template: function UserSessionControlLowerMobileComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "span", 3);
        i0.ɵɵelementStart(4, "a", 4);
        i0.ɵɵelementStart(5, "div", 2);
        i0.ɵɵelement(6, "fa-icon", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "ul", 6);
        i0.ɵɵelementStart(8, "li", 7);
        i0.ɵɵelement(9, "a", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(10, UserSessionControlLowerMobileComponent_li_10_Template, 2, 0, "li", 9);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "div");
        i0.ɵɵelement(12, "audio-toggler", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div");
        i0.ɵɵelement(14, "video-toggler", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(15, "div", 11);
        i0.ɵɵelementStart(16, "button", 12);
        i0.ɵɵlistener("click", function UserSessionControlLowerMobileComponent_Template_button_click_16_listener() { return ctx.leaveCall(); });
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(17, "svg", 13);
        i0.ɵɵelement(18, "use", 14);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(19, UserSessionControlLowerMobileComponent_ng_template_19_Template, 2, 2, "ng-template", null, 15, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        i0.ɵɵadvance(10);
        i0.ɵɵproperty("ngIf", ctx.session != null);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("tooltipPosition", ctx.above);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("tooltipPosition", ctx.above);
    } }, directives: [i5.NgbDropdown, i5.NgbDropdownToggle, i11.FaIconComponent, i5.NgbDropdownMenu, i12.RouterLinkWithHref, i13.JhiTranslateDirective, i14.NgIf, i15.AudioTogglerComponent, i16.VideoTogglerComponent, i17.DefaultValueAccessor, i18.InputAutoDirectionSwitchDirective, i17.NgControlStatus, i17.NgModel, i19.ModalSuccessComponent], pipes: [i6.TranslatePipe], styles: [".nav-bar[_ngcontent-%COMP%]{background:#f7eed2}.stop-button[_ngcontent-%COMP%]{font-family:Poppins,sans-serif;font-weight:400;width:40px;height:40px;background:var(--accent-2);color:#f7eed2;border-radius:8px;border:none !important;cursor:pointer}.box-button[_ngcontent-%COMP%]{font-family:Poppins,sans-serif;font-weight:400;width:50px;height:50px;color:#44a38b;background:#f7eed2;border-radius:8px;cursor:pointer}.box-button-toggle[_ngcontent-%COMP%]:active, .box-button-dropdown[_ngcontent-%COMP%]:hover, .box-button-toggle-active[_ngcontent-%COMP%]{color:#f7eed2;background:#44a38b}.dropdown-menu[_ngcontent-%COMP%]{padding:15px 25px;box-shadow:0px -2px 15px rgba(0,0,0,.14);border-radius:4px 4px 0 0;border:none;min-width:-moz-fit-content;min-width:fit-content;position:fixed}.dropdown-toggle[_ngcontent-%COMP%]:after{display:none !important}.dropdown-item-disabled[_ngcontent-%COMP%]{background-color:#fff;color:#7cbfae !important;font-weight:bold}.dropdown-item[_ngcontent-%COMP%]:hover, .dropdown-item-disabled[_ngcontent-%COMP%]:hover{background-color:#fff;color:#0a4a3a;text-decoration:underline}a[_ngcontent-%COMP%]{text-decoration:none}.item-bold[_ngcontent-%COMP%]{margin-bottom:15px;font-family:\"Poppins\",serif;font-style:normal;font-weight:700;font-size:14px;line-height:25px;color:#0a4a3a;cursor:pointer}.light-green[_ngcontent-%COMP%]{color:#44a38b !important}"] });
