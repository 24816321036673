import { Subject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AccountService } from '../auth/account.service';
import { AuthServerProvider } from '../auth/auth-jwt.service';
import { Router } from '@angular/router';
import { WellbeingScanService } from '../../entities/wellbeing-scan/wellbeing-scan.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/account.service";
import * as i2 from "../auth/auth-jwt.service";
import * as i3 from "@angular/router";
import * as i4 from "../../entities/wellbeing-scan/wellbeing-scan.service";
export class LoginService {
    constructor(accountService, authServerProvider, router, wellbeingScanService) {
        this.accountService = accountService;
        this.authServerProvider = authServerProvider;
        this.router = router;
        this.wellbeingScanService = wellbeingScanService;
        this.onLogout$ = new Subject();
    }
    login(credentials) {
        return this.authServerProvider.login(credentials).pipe(flatMap(() => this.accountService.identity(true)));
    }
    loginViaOAuthCode(oAuthAuthenticationInput) {
        return this.authServerProvider.loginViaOAuth(oAuthAuthenticationInput).pipe(flatMap(() => this.accountService.identity(true)));
    }
    loginViaMagicLink(magicLoginAuthenticationInput) {
        return this.authServerProvider.loginViaMagicLink(magicLoginAuthenticationInput).pipe(flatMap(() => this.accountService.identity(true)));
    }
    logout() {
        this.authServerProvider.logout().subscribe(() => { }, () => { }, () => {
            this.accountService.authenticate(null);
            this.onLogout$.next();
        });
    }
    // TODO consider removing after migration
    redirectToHomepage() {
        let srqAnswers = this.wellbeingScanService.getSavedSrqAnswers();
        if (srqAnswers != null && srqAnswers.length === 20) {
            this.wellbeingScanService.submitAnswers().subscribe((submitted) => {
                if (submitted) {
                    console.log('Save success');
                    this.router.navigate(['/wellbeing-result']).then();
                }
            });
        }
        else {
            this.accountService.redirectToHomePage();
        }
        return false;
    }
}
LoginService.ɵfac = function LoginService_Factory(t) { return new (t || LoginService)(i0.ɵɵinject(i1.AccountService), i0.ɵɵinject(i2.AuthServerProvider), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.WellbeingScanService)); };
LoginService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LoginService, factory: LoginService.ɵfac, providedIn: 'root' });
