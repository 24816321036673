import { Session } from '../model/session.model';
import { CalendarEventLinkService } from '../../core/util/calendar-event-link.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/util/calendar-event-link.service";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "../../ng-jhipster/language/jhi-translate.directive";
export class AddToCalendarComponent {
    constructor(calendarEventLinkService) {
        this.calendarEventLinkService = calendarEventLinkService;
    }
    set session(value) {
        var _a;
        if ((value === null || value === void 0 ? void 0 : value.id) !== ((_a = this._session) === null || _a === void 0 ? void 0 : _a.id)) {
            this._session = value;
            this.createCalendarReminders();
        }
    }
    get session() {
        return this._session;
    }
    ngOnInit() {
    }
    // https://outlook.office.com/calendar/0/deeplink/compose?allday=false&body=Nice%20description&startdt=2022-10-25T21%3A15%3A00%2B00%3A00&enddt=2022-10-25T21%3A45%3A00%2B00%3A00&subject=Your Inuka session&location=Inuka&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent
    createCalendarReminders() {
        const calendarEventLinks = this.calendarEventLinkService.generateCalendarEventLinks(this.session);
        this.googleReminder = calendarEventLinks.google;
        this.outlookReminder = calendarEventLinks.outlook;
        this.yahooReminder = calendarEventLinks.yahoo;
    }
    toggleDropdown(event) { }
    collapseDropdown(event) { event.close(); }
}
AddToCalendarComponent.ɵfac = function AddToCalendarComponent_Factory(t) { return new (t || AddToCalendarComponent)(i0.ɵɵdirectiveInject(i1.CalendarEventLinkService)); };
AddToCalendarComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AddToCalendarComponent, selectors: [["add-to-calendar"]], inputs: { session: "session" }, decls: 20, vars: 3, consts: [[1, "text-center"], ["ngbDropdown", "", 1, "emoji-dropdown", 3, "openChange"], ["calendarReminderDropdown", "ngbDropdown"], ["ngbDropdownToggle", "", 1, "btn", "btn-link", "navbar-toggler"], ["icon", "calendar", 1, "calendar-icon"], ["jhiTranslate", "sessionSchedule.paragraph.calendar", 1, "calendar-text"], ["ngbDropdownMenu", "", 1, "add-dropdown", 3, "click"], [3, "click"], ["role", "button", 2, "padding", "5px 5px 5px 10px"], ["target", "_blank", 3, "href"], ["src", "../../../../content/images/inuka/google_64px.png", "alt", "google", 2, "height", "15px", "width", "auto"], ["jhiTranslate", "sessionSchedule.paragraph.googleCalendar", 2, "padding-left", "5px", "font-size", "14px", "color", "black", "text-decoration", "none"], ["src", "../../../../content/images/inuka/outlook-app-icon@2x.png", "alt", "outlook", 2, "height", "15px", "width", "auto"], ["jhiTranslate", "sessionSchedule.paragraph.outlookCalendar", 2, "padding-left", "5px", "font-size", "14px", "color", "black", "text-decoration", "none"], ["src", "../../../../content/images/inuka/yahoo-app-icon.jpg", "alt", "yahoo", 2, "height", "15px", "width", "auto"], ["jhiTranslate", "sessionSchedule.paragraph.yahooCalendar", 2, "padding-left", "5px", "font-size", "14px", "color", "black", "text-decoration", "none"]], template: function AddToCalendarComponent_Template(rf, ctx) { if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1, 2);
        i0.ɵɵlistener("openChange", function AddToCalendarComponent_Template_div_openChange_1_listener($event) { return ctx.toggleDropdown($event); });
        i0.ɵɵelementStart(3, "button", 3);
        i0.ɵɵelement(4, "fa-icon", 4);
        i0.ɵɵelement(5, "span", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 6);
        i0.ɵɵlistener("click", function AddToCalendarComponent_Template_div_click_6_listener() { i0.ɵɵrestoreView(_r1); const _r0 = i0.ɵɵreference(2); return ctx.collapseDropdown(_r0); });
        i0.ɵɵelementStart(7, "div", 7);
        i0.ɵɵlistener("click", function AddToCalendarComponent_Template_div_click_7_listener() { i0.ɵɵrestoreView(_r1); const _r0 = i0.ɵɵreference(2); return ctx.collapseDropdown(_r0); });
        i0.ɵɵelementStart(8, "div", 8);
        i0.ɵɵelementStart(9, "a", 9);
        i0.ɵɵelement(10, "img", 10);
        i0.ɵɵelement(11, "span", 11);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(12, "div", 8);
        i0.ɵɵelementStart(13, "a", 9);
        i0.ɵɵelement(14, "img", 12);
        i0.ɵɵelement(15, "span", 13);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(16, "div", 8);
        i0.ɵɵelementStart(17, "a", 9);
        i0.ɵɵelement(18, "img", 14);
        i0.ɵɵelement(19, "span", 15);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(9);
        i0.ɵɵpropertyInterpolate("href", ctx.googleReminder, i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(4);
        i0.ɵɵpropertyInterpolate("href", ctx.outlookReminder, i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(4);
        i0.ɵɵpropertyInterpolate("href", ctx.yahooReminder, i0.ɵɵsanitizeUrl);
    } }, directives: [i2.NgbDropdown, i2.NgbDropdownToggle, i3.FaIconComponent, i4.JhiTranslateDirective, i2.NgbDropdownMenu], styles: [".navbar-toggler[_ngcontent-%COMP%]{padding:0 !important}.text-center[_ngcontent-%COMP%]{line-height:17px !important}.calendar-icon[_ngcontent-%COMP%]{color:#44a38b !important;font-size:14px !important}.calendar-text[_ngcontent-%COMP%]{color:#44a38b !important;font-weight:bold !important;padding-left:5px;font-size:14px}.add-dropdown[_ngcontent-%COMP%]{min-width:170px !important}"] });
