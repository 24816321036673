import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i4 from "../../../../shared/session-type/session-type.component";
const _c0 = function (a0) { return { value: a0 }; };
export class SessionTypeNotAllowedPopupComponent {
    constructor(modalService) {
        this.modalService = modalService;
    }
    ngOnInit() {
    }
    close() {
        this.modalService.dismissAll();
    }
}
SessionTypeNotAllowedPopupComponent.ɵfac = function SessionTypeNotAllowedPopupComponent_Factory(t) { return new (t || SessionTypeNotAllowedPopupComponent)(i0.ɵɵdirectiveInject(i1.NgbModal)); };
SessionTypeNotAllowedPopupComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionTypeNotAllowedPopupComponent, selectors: [["session-type-not-allowed-popup"]], inputs: { sessionType: "sessionType", duration: "duration" }, decls: 15, vars: 6, consts: [[1, "olha-theme", "px-2", "pt-1", "pb-3"], [1, "text-center"], [1, "text-right"], ["icon", "times-circle", 1, "close-icon", 3, "click"], [1, "mt-4", "d-flex", "justify-content-center", "text-center"], ["jhiTranslate", "sessionSchedule.paragraph.sessionTypeNotAllowed"], [1, "ml-1"], [3, "sessionType", "showIcon", "showLabel"], ["jhiTranslate", "sessionSchedule.paragraph.durationValue", 3, "translateValues"]], template: function SessionTypeNotAllowedPopupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function SessionTypeNotAllowedPopupComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div");
        i0.ɵɵelementStart(6, "span", 5);
        i0.ɵɵtext(7, "You can't schedule a session of type ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "div", 6);
        i0.ɵɵelement(9, "session-type", 7);
        i0.ɵɵtext(10, "\u00A0 ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "div", 6);
        i0.ɵɵtext(12, " (");
        i0.ɵɵelement(13, "span", 8);
        i0.ɵɵtext(14, ") ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(9);
        i0.ɵɵproperty("sessionType", ctx.sessionType)("showIcon", false)("showLabel", false);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(4, _c0, ctx.duration));
    } }, directives: [i2.FaIconComponent, i3.JhiTranslateDirective, i4.SessionTypeComponent], styles: [""] });
