import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/language/jhi-translate.directive";
const _c0 = function (a0) { return { inukaSubstitute: a0 }; };
const _c1 = function (a0) { return { coachSubstitute: a0 }; };
export class InukaServicesComponent {
    constructor() {
        this.inukaSubstitute = window.userCountry === 'Zimbabwe' ? 'Friendship Bench - Inuka' : 'Inuka';
        this.coachSubstitute = window.userCountry === 'Zimbabwe' ? 'buddy' : 'coach';
    }
    ngOnInit() { }
}
InukaServicesComponent.ɵfac = function InukaServicesComponent_Factory(t) { return new (t || InukaServicesComponent)(); };
InukaServicesComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: InukaServicesComponent, selectors: [["jhi-inuka-services"]], decls: 21, vars: 12, consts: [[1, "page-single", 2, "background-color", "white", "border-top-left-radius", "20px", "border-top-right-radius", "20px"], [1, "container-fluid"], [1, "row", "fadeIn", "animated"], [1, "offset-md-2", "col-md-8", "offset-md-2", "offset-lg-4", "col-lg-4", "offset-lg-4", 2, "padding-left", "45px", "padding-right", "45px"], ["jhiTranslate", "inukaServices.aboutOurServices", 1, "text-uppercase", "text-center", 2, "margin-top", "120px"], [2, "margin-bottom", "40px"], ["jhiTranslate", "inukaServices.supportsYouTo", 2, "font-size", "14px", "text-align", "justify", "margin-bottom", "30px", 3, "translateValues"], [1, "text-center", 2, "margin-bottom", "30px"], [1, "text-uppercase", 2, "color", "black", "margin-bottom", "5px"], ["jhiTranslate", "inukaServices.aTrained", 2, "font-size", "14px", 3, "translateValues"], ["jhiTranslate", "inukaServices.privateOneOnOne", 2, "font-size", "14px"], ["jhiTranslate", "inukaServices.theScienceBased", 2, "font-size", "14px", 3, "translateValues"], ["jhiTranslate", "inukaServices.messageSupport", 2, "font-size", "14px"], ["jhiTranslate", "inukaServices.chatWithYour", 2, "font-size", "14px", "text-align", "justify", "margin-bottom", "30px", 3, "translateValues"]], template: function InukaServicesComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelement(4, "h2", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelement(6, "p", 6);
        i0.ɵɵelementStart(7, "div", 7);
        i0.ɵɵelementStart(8, "h2", 8);
        i0.ɵɵtext(9, "1.");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(10, "p", 9);
        i0.ɵɵelementStart(11, "h2", 8);
        i0.ɵɵtext(12, "2.");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(13, "p", 10);
        i0.ɵɵelementStart(14, "h2", 8);
        i0.ɵɵtext(15, "3.");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(16, "p", 11);
        i0.ɵɵelementStart(17, "h2", 8);
        i0.ɵɵtext(18, "4.");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(19, "p", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(20, "p", 13);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(4, _c0, ctx.inukaSubstitute));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(6, _c1, ctx.coachSubstitute));
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(8, _c0, ctx.inukaSubstitute));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(10, _c1, ctx.coachSubstitute));
    } }, directives: [i1.JhiTranslateDirective], styles: [""] });
