import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { LANGUAGES, SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SessionService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/sessions';
    }
    // create(session: ISession): Observable<EntityResponseType> {
    //   const copy = this.convertDateFromClient(session);
    //   return this.http
    //     .post<ISession>(this.resourceUrl, copy, { observe: 'response' })
    //     .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    // }
    //
    // update(session: ISession): Observable<EntityResponseType> {
    //   const copy = this.convertDateFromClient(session);
    //   return this.http
    //     .put<ISession>(this.resourceUrl, copy, { observe: 'response' })
    //     .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
    // }
    updateStatus(sessionId, status, attendanceType, reason) {
        return this.http.patch(`${this.resourceUrl}/${sessionId}`, { status, attendanceType, reason }, { observe: 'response' });
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    getSession(id) {
        return this.http.get(`${this.resourceUrl}/get/${id}`, { observe: 'response' }).pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req, selectedRegions) {
        const options = createRequestOption(req);
        return this.http
            .post(`${this.resourceUrl}/list`, selectedRegions, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    findAllSessionsByRegions(selectedRegions) {
        return this.http
            .post(`${this.resourceUrl}/all`, selectedRegions, { observe: 'response' })
            .pipe(map((res) => this.convertArrayFromServer(res)));
    }
    queryForManagement(req, searchParam, selectedRegions) {
        console.log(searchParam);
        console.log(selectedRegions);
        const options = createRequestOption(req);
        return this.http.post(`${this.resourceUrl}/searchParam`, { searchParam, selectedRegions }, { params: options, observe: 'response' });
    }
    queryAllRealBetween(startDate, endDate) {
        let start = startDate.toISOString();
        let end = endDate.toISOString();
        return this.http.get(`${this.resourceUrl}/all-real-for-excel/${start}/${end}`, { responseType: 'blob' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(session) {
        return Object.assign({}, session, {
            coachFirstJoin: session.coachFirstJoin && session.coachFirstJoin.isValid() ? session.coachFirstJoin.toJSON() : undefined,
            sessionStartTime: session.sessionStartTime && session.sessionStartTime.isValid() ? session.sessionStartTime.toJSON() : undefined,
            time: session.time && session.time.isValid() ? session.time.toJSON() : undefined,
            userFirstJoin: session.userFirstJoin && session.userFirstJoin.isValid() ? session.userFirstJoin.toJSON() : undefined,
            cancelOrRescheduleTime: session.cancelOrRescheduleTime && session.cancelOrRescheduleTime.isValid() ? session.cancelOrRescheduleTime.toJSON() : undefined,
            firstMessageTime: session.firstMessageTime && session.firstMessageTime.isValid() ? session.firstMessageTime.toJSON() : undefined,
            lastMessageTime: session.lastMessageTime && session.lastMessageTime.isValid() ? session.lastMessageTime.toJSON() : undefined
        });
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.coachFirstJoin = res.body.coachFirstJoin ? moment(res.body.coachFirstJoin) : undefined;
            res.body.sessionStartTime = res.body.sessionStartTime ? moment(res.body.sessionStartTime) : undefined;
            res.body.time = res.body.time ? moment(res.body.time) : undefined;
            res.body.userFirstJoin = res.body.userFirstJoin ? moment(res.body.userFirstJoin) : undefined;
            res.body.cancelOrRescheduleTime = res.body.cancelOrRescheduleTime ? moment(res.body.cancelOrRescheduleTime) : undefined;
            res.body.firstMessageTime = res.body.firstMessageTime ? moment(res.body.firstMessageTime) : undefined;
            res.body.lastMessageTime = res.body.lastMessageTime ? moment(res.body.lastMessageTime) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((session) => {
                session.coachFirstJoin = session.coachFirstJoin ? moment(session.coachFirstJoin) : undefined;
                session.sessionStartTime = session.sessionStartTime ? moment(session.sessionStartTime, "YYYY-MM-DDTHH:mmZ") : undefined;
                session.time = session.time ? moment(session.time) : undefined;
                session.userFirstJoin = session.userFirstJoin ? moment(session.userFirstJoin) : undefined;
                session.cancelOrRescheduleTime = session.cancelOrRescheduleTime ? moment(session.cancelOrRescheduleTime) : undefined;
                session.firstMessageTime = session.firstMessageTime ? moment(session.firstMessageTime) : undefined;
                session.lastMessageTime = session.lastMessageTime ? moment(session.lastMessageTime) : undefined;
            });
        }
        return res;
    }
    convertArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((session) => {
                let language = LANGUAGES.find(l => l.code === session.langKey);
                session.langKey = language != null ? language.name : "";
            });
        }
        return res;
    }
    getSessionCount(id) {
        return this.http
            .get(`${this.resourceUrl}/session-count-for-user?id=${id}`, { observe: 'response' });
    }
    getSessionsByUserId(userId, includeCanceled) {
        let url = `${this.resourceUrl}/userId/${userId}`;
        if (includeCanceled) {
            url += '?includeCanceled=true';
        }
        return this.http
            .get(url, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    recentSessions() {
        return this.http
            .get(`${this.resourceUrl}/current-user`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    activeSession() {
        return this.http
            .get(`${this.resourceUrl}/current-user/active`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    futureSession() {
        return this.http
            .get(`${this.resourceUrl}/current-user/future`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    cancelSession(id) {
        return this.http.delete(`${this.resourceUrl}/cancel-session?id=${id}`, { observe: 'response' });
    }
    endSession(id) {
        console.log('endSession');
        return this.http.delete(`${this.resourceUrl}/end-session?id=${id}`, { observe: 'response' });
    }
    saveSessionSummary(sessionSummary) {
        return this.http.post(`${this.resourceUrl}/session-summary`, sessionSummary, { observe: 'response' });
    }
    addCancellationReason(cancellationReason, sessionId) {
        console.log(cancellationReason);
        return this.http.post(`${this.resourceUrl}/add-cancellation-reason/${sessionId}`, cancellationReason, { observe: 'response' });
    }
    getSessionSummaryBySessionId(sessionId) {
        console.log(sessionId);
        return this.http.get(`${this.resourceUrl}/session-summary/${sessionId}`, { observe: 'response' });
    }
    extendSession(id) {
        console.log('extendSession');
        return this.http.put(`${this.resourceUrl}/extend-session?id=${id}`, { observe: 'response' });
    }
    finishedSessions(id) {
        return this.http.get(`${this.resourceUrl}/finished-sessions?userId=${id}`, { observe: 'response' });
    }
    getPastSessionCounts(id) {
        return this.http.get(`${this.resourceUrl}/past-session-counts?userId=${id}`, { observe: 'response' });
    }
    sessionAvailable(data, isScheduled, sessionId, autoLookup) {
        // console.log("sessionAvailable")
        let httpParams = new HttpParams();
        if (autoLookup) {
            httpParams = httpParams.set('autoLookup', String(autoLookup));
        }
        if (isScheduled === 'update' && sessionId)
            return this.http
                .post(this.resourceUrl + '/available/' + isScheduled + '/' + sessionId, data, { params: httpParams })
                .pipe(map((res) => this.convertDateArrayFromSlotAndCoachMap(res)));
        else
            return this.http
                .post(this.resourceUrl + '/available', data, { params: httpParams })
                .pipe(map((res) => this.convertDateArrayFromSlotAndCoachMap(res)));
    }
    convertDateArrayFromSlotAndCoachMap(res2) {
        if (!res2 || !res2.coachesSlots) {
            return res2;
        }
        for (let slotAndCoach of res2.coachesSlots) {
            let sessionStartTimes = slotAndCoach.sessionStartTimes;
            if (sessionStartTimes === undefined)
                continue;
            for (let i = 0; i < sessionStartTimes.length; i++) {
                sessionStartTimes[i] = sessionStartTimes[i] ? moment(sessionStartTimes[i]) : undefined;
            }
        }
        return res2;
    }
    scheduleSession(data) {
        return this.http.post(this.resourceUrl + '/schedule', data);
    }
    reschedule(data) {
        return this.http.put(this.resourceUrl + '/reschedule', data);
    }
    getAllSessionsCountUserByUser() {
        return this.http.get(`${this.resourceUrl}/current-user/count`);
    }
    getAllTextSessionsCountUserByUser() {
        return this.http.get(`${this.resourceUrl}/current-user/text-count`);
    }
    getAllSessionsCountUserByCoach(userId) {
        return this.http.get(`${this.resourceUrl}/coach/count/${userId}`);
    }
    getAllTextSessionsCountUserByCoach(userId) {
        return this.http.get(`${this.resourceUrl}/coach/text-count/${userId}`);
    }
    futureControl(selectedUserId) {
        return this.http.get(`${this.resourceUrl}/future/${selectedUserId}`, { observe: 'response' });
    }
    getCoachName(sessionId) {
        return this.http.get(`${this.resourceUrl}/coach-of-session?sessionId=${sessionId}`, { observe: 'response', responseType: 'text' });
    }
    sessionsForActionPlan(actionPlanId) {
        return this.http.get(`${this.resourceUrl}/listByActionPlan?actionPlanId=${actionPlanId}`, { observe: 'response' });
    }
    creditControl(selectedUserId) {
        return this.http.get(this.resourceUrl + '/credit/' + selectedUserId);
    }
    decideWhichSessionToDisplay(sessions, endeds, includeCancelledSession) {
        if (sessions === undefined || (sessions === null || sessions === void 0 ? void 0 : sessions.length) === 0) {
            return null;
        }
        let starteds = sessions.filter(s => s.started);
        let actives = sessions.filter(s => s.active);
        let futures = sessions.filter(s => s.future);
        if (starteds.length > 0) {
            // If length is 2, there are 2 consecutive sessions. Length cannot be more than 2.
            return starteds[starteds.length - 1];
        }
        else if (actives.length > 0) {
            return actives[0]; // length shouldn't be more than 1 since it is impossible to have multiple active sessions
        }
        else if (futures.length > 0) {
            futures.sort((a, b) => (a.sessionStartTime < b.sessionStartTime ? -1 : 1));
            return futures[0];
        }
        else if (endeds.length > 0) {
            return endeds[0];
        }
        if (includeCancelledSession) {
            let cancelled = sessions.filter(s => s.cancelled);
            if (cancelled.length > 0) {
                return cancelled[0];
            }
        }
        return null;
    }
    calculateMinutesLeftToSessionStart(session) {
        return this.calculateMinutesLeft(session.sessionStartTime);
    }
    calculateMinutesLeft(time) {
        let now = moment();
        let diffInSeconds = time.diff(now, 'seconds');
        if (diffInSeconds > 0) {
            return Math.ceil(diffInSeconds / 60); // seconds to minute, round up: 0->0, 4->4, 4.3->5, 4.8->5
        }
        return 0;
    }
    findLastEndedSessionForUser(userId) {
        return this.http
            .get(`${this.resourceUrl}/last-ended/${userId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    getAllOrganizationCodesUsed() {
        return this.http.get(`${this.resourceUrl}/all-organization-codes-used`, { observe: 'response' });
    }
    changeSessionLanguage(langKey, sessionId) {
        return this.http.put(`${this.resourceUrl}/${sessionId}/language/${langKey}`, null, { observe: 'response' });
    }
}
SessionService.ɵfac = function SessionService_Factory(t) { return new (t || SessionService)(i0.ɵɵinject(i1.HttpClient)); };
SessionService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SessionService, factory: SessionService.ɵfac, providedIn: 'root' });
