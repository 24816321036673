import { RouterModule } from '@angular/router';
import { UserRouteAccessService } from '../../core/auth/user-route-access-service';
import { Authority } from '../../shared/constants/authority.constants';
import { OrganizationSelectionComponent } from './organization-selection/organization-selection.component';
import { OrganizationDashboardIndexComponent } from './organization-dashboard-index/organization-dashboard-index.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const routes = [
    {
        path: 'organization-selection',
        component: OrganizationSelectionComponent,
        canActivate: [UserRouteAccessService],
        data: {
            authorities: [Authority.ORGANIZATION_ADMIN, Authority.MASTERMIND, Authority.ADMIN, Authority.SUPERVISOR],
            pageTitle: 'home.title'
        },
    },
    {
        path: 'organization-dashboard-index/:organizationId',
        component: OrganizationDashboardIndexComponent,
        canActivate: [UserRouteAccessService],
        data: {
            authorities: [Authority.ORGANIZATION_ADMIN, Authority.MASTERMIND, Authority.ADMIN, Authority.SUPERVISOR],
            pageTitle: 'home.title'
        }
    }
];
export class OrganizationDashboardRoutingModule {
}
OrganizationDashboardRoutingModule.ɵfac = function OrganizationDashboardRoutingModule_Factory(t) { return new (t || OrganizationDashboardRoutingModule)(); };
OrganizationDashboardRoutingModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: OrganizationDashboardRoutingModule });
OrganizationDashboardRoutingModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[RouterModule.forChild(routes)], RouterModule] });
