import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "../../language/jhi-translate.directive";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "../../pipe/keys.pipe";
import * as i6 from "../../pipe/pure-filter.pipe";
function JhiThreadModalComponent_div_35_div_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 30);
    i0.ɵɵelementStart(1, "samp");
    i0.ɵɵtext(2);
    i0.ɵɵelementStart(3, "code");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, ")");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(6, "span", 31);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const st_r3 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2("", st_r3.value.className, ".", st_r3.value.methodName, "(");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2("", st_r3.value.fileName, ":", st_r3.value.lineNumber, "");
} }
function JhiThreadModalComponent_div_35_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵelementStart(1, "h6");
    i0.ɵɵelementStart(2, "span", 16);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4);
    i0.ɵɵelementStart(5, "a", 17);
    i0.ɵɵlistener("click", function JhiThreadModalComponent_div_35_Template_a_click_5_listener() { const restoredCtx = i0.ɵɵrestoreView(_r5); const entry_r1 = restoredCtx.$implicit; return entry_r1.show = !entry_r1.show; });
    i0.ɵɵelementStart(6, "span", 18);
    i0.ɵɵtext(7, "Show StackTrace");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "span", 19);
    i0.ɵɵtext(9, "Hide StackTrace");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "div", 20);
    i0.ɵɵelementStart(11, "div", 21);
    i0.ɵɵtemplate(12, JhiThreadModalComponent_div_35_div_12_Template, 7, 4, "div", 22);
    i0.ɵɵpipe(13, "keys");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "table", 23);
    i0.ɵɵelementStart(15, "thead");
    i0.ɵɵelementStart(16, "tr");
    i0.ɵɵelementStart(17, "th", 24);
    i0.ɵɵtext(18, "Blocked Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(19, "th", 25);
    i0.ɵɵtext(20, "Blocked Count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(21, "th", 26);
    i0.ɵɵtext(22, "Waited Time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(23, "th", 27);
    i0.ɵɵtext(24, "Waited Count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(25, "th", 28);
    i0.ɵɵtext(26, "Lock Name");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(27, "tbody");
    i0.ɵɵelementStart(28, "tr");
    i0.ɵɵelementStart(29, "td");
    i0.ɵɵtext(30);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(31, "td");
    i0.ɵɵtext(32);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(33, "td");
    i0.ɵɵtext(34);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(35, "td");
    i0.ɵɵtext(36);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(37, "td", 29);
    i0.ɵɵelementStart(38, "code");
    i0.ɵɵtext(39);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const entry_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngClass", ctx_r0.getBadgeClass(entry_r1.value.threadState));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(entry_r1.value.threadState);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2("\u00A0", entry_r1.value.threadName, " (ID ", entry_r1.value.threadId, ") ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("hidden", entry_r1.show);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("hidden", !entry_r1.show);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("hidden", !entry_r1.show);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(13, 14, entry_r1.value.stackTrace));
    i0.ɵɵadvance(18);
    i0.ɵɵtextInterpolate(entry_r1.value.blockedTime);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r1.value.blockedCount);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r1.value.waitedTime);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r1.value.waitedCount);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", entry_r1.value.lockName);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(entry_r1.value.lockName);
} }
export class JhiThreadModalComponent {
    constructor(activeModal) {
        this.activeModal = activeModal;
        this.threadDumpAll = 0;
        this.threadDumpBlocked = 0;
        this.threadDumpRunnable = 0;
        this.threadDumpTimedWaiting = 0;
        this.threadDumpWaiting = 0;
    }
    ngOnInit() {
        this.threadDump.forEach((value) => {
            if (value.threadState === 'RUNNABLE') {
                this.threadDumpRunnable += 1;
            }
            else if (value.threadState === 'WAITING') {
                this.threadDumpWaiting += 1;
            }
            else if (value.threadState === 'TIMED_WAITING') {
                this.threadDumpTimedWaiting += 1;
            }
            else if (value.threadState === 'BLOCKED') {
                this.threadDumpBlocked += 1;
            }
        });
        this.threadDumpAll = this.threadDumpRunnable + this.threadDumpWaiting + this.threadDumpTimedWaiting + this.threadDumpBlocked;
    }
    getBadgeClass(threadState) {
        if (threadState === 'RUNNABLE') {
            return 'badge-success';
        }
        else if (threadState === 'WAITING') {
            return 'badge-info';
        }
        else if (threadState === 'TIMED_WAITING') {
            return 'badge-warning';
        }
        else if (threadState === 'BLOCKED') {
            return 'badge-danger';
        }
    }
}
JhiThreadModalComponent.ɵfac = function JhiThreadModalComponent_Factory(t) { return new (t || JhiThreadModalComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal)); };
JhiThreadModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiThreadModalComponent, selectors: [["jhi-thread-modal"]], decls: 41, vars: 13, consts: [[1, "modal-header"], ["jhiTranslate", "metrics.jvm.threads.dump.title", 1, "modal-title"], ["type", "button", 1, "close", 3, "click"], [1, "modal-body"], [1, "badge", "badge-primary", 3, "click"], [1, "badge", "badge-pill", "badge-default"], [1, "badge", "badge-success", 3, "click"], [1, "badge", "badge-info", 3, "click"], [1, "badge", "badge-warning", 3, "click"], [1, "badge", "badge-danger", 3, "click"], [1, "mt-2"], ["type", "text", 1, "form-control", 3, "ngModel", "ngModelChange"], ["class", "pad", 4, "ngFor", "ngForOf"], [1, "modal-footer"], ["type", "button", "data-dismiss", "modal", 1, "btn", "btn-secondary", "float-left", 3, "click"], [1, "pad"], [1, "badge", 3, "ngClass"], ["href", "javascript:void(0);", 3, "click"], ["jhiTranslate", "metrics.jvm.threads.dump.show", 3, "hidden"], ["jhiTranslate", "metrics.jvm.threads.dump.hide", 3, "hidden"], [1, "card", 3, "hidden"], [1, "card-body"], ["class", "break", 4, "ngFor", "ngForOf"], [1, "table", "table-sm", "table-responsive"], ["jhiTranslate", "metrics.jvm.threads.dump.blockedtime"], ["jhiTranslate", "metrics.jvm.threads.dump.blockedcount"], ["jhiTranslate", "metrics.jvm.threads.dump.waitedtime"], ["jhiTranslate", "metrics.jvm.threads.dump.waitedcount"], ["jhiTranslate", "metrics.jvm.threads.dump.lockname"], [1, "thread-dump-modal-lock", 3, "title"], [1, "break"], [1, "mt-1"]], template: function JhiThreadModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "h4", 1);
        i0.ɵɵtext(2, "Threads dump");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "button", 2);
        i0.ɵɵlistener("click", function JhiThreadModalComponent_Template_button_click_3_listener() { return ctx.activeModal.dismiss("closed"); });
        i0.ɵɵtext(4, "\u00D7");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 3);
        i0.ɵɵelementStart(6, "span", 4);
        i0.ɵɵlistener("click", function JhiThreadModalComponent_Template_span_click_6_listener() { return ctx.threadDumpFilter = {}; });
        i0.ɵɵtext(7, " All\u00A0");
        i0.ɵɵelementStart(8, "span", 5);
        i0.ɵɵtext(9);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtext(10, "\u00A0 ");
        i0.ɵɵelementStart(11, "span", 6);
        i0.ɵɵlistener("click", function JhiThreadModalComponent_Template_span_click_11_listener() { return ctx.threadDumpFilter = { threadState: "RUNNABLE" }; });
        i0.ɵɵtext(12, " Runnable\u00A0");
        i0.ɵɵelementStart(13, "span", 5);
        i0.ɵɵtext(14);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtext(15, "\u00A0 ");
        i0.ɵɵelementStart(16, "span", 7);
        i0.ɵɵlistener("click", function JhiThreadModalComponent_Template_span_click_16_listener() { return ctx.threadDumpFilter = { threadState: "WAITING" }; });
        i0.ɵɵtext(17, "Waiting\u00A0");
        i0.ɵɵelementStart(18, "span", 5);
        i0.ɵɵtext(19);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtext(20, "\u00A0 ");
        i0.ɵɵelementStart(21, "span", 8);
        i0.ɵɵlistener("click", function JhiThreadModalComponent_Template_span_click_21_listener() { return ctx.threadDumpFilter = { threadState: "TIMED_WAITING" }; });
        i0.ɵɵtext(22, " Timed Waiting\u00A0");
        i0.ɵɵelementStart(23, "span", 5);
        i0.ɵɵtext(24);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtext(25, "\u00A0 ");
        i0.ɵɵelementStart(26, "span", 9);
        i0.ɵɵlistener("click", function JhiThreadModalComponent_Template_span_click_26_listener() { return ctx.threadDumpFilter = { threadState: "BLOCKED" }; });
        i0.ɵɵtext(27, "Blocked\u00A0");
        i0.ɵɵelementStart(28, "span", 5);
        i0.ɵɵtext(29);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtext(30, "\u00A0 ");
        i0.ɵɵelementStart(31, "div", 10);
        i0.ɵɵtext(32, "\u00A0");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(33, " Filter ");
        i0.ɵɵelementStart(34, "input", 11);
        i0.ɵɵlistener("ngModelChange", function JhiThreadModalComponent_Template_input_ngModelChange_34_listener($event) { return ctx.threadDumpFilter = $event; });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(35, JhiThreadModalComponent_div_35_Template, 40, 16, "div", 12);
        i0.ɵɵpipe(36, "keys");
        i0.ɵɵpipe(37, "pureFilter");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(38, "div", 13);
        i0.ɵɵelementStart(39, "button", 14);
        i0.ɵɵlistener("click", function JhiThreadModalComponent_Template_button_click_39_listener() { return ctx.activeModal.dismiss("closed"); });
        i0.ɵɵtext(40, " Done ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(9);
        i0.ɵɵtextInterpolate(ctx.threadDumpAll);
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate(ctx.threadDumpRunnable);
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate(ctx.threadDumpWaiting);
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate(ctx.threadDumpTimedWaiting);
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate(ctx.threadDumpBlocked);
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngModel", ctx.threadDumpFilter);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(36, 7, i0.ɵɵpipeBind3(37, 9, ctx.threadDump, ctx.threadDumpFilter, "lockName")));
    } }, directives: [i2.JhiTranslateDirective, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel, i4.NgForOf, i4.NgClass], pipes: [i5.JhiKeysPipe, i6.JhiPureFilterPipe], encapsulation: 2 });
