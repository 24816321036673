import { EventEmitter } from '@angular/core';
import { ExtraQuestionAnswer } from '../../../shared/model/extra-question-answer.model';
import { JhiLanguageService } from '../../../ng-jhipster/language';
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "../../../ng-jhipster/language";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/checkbox";
import * as i5 from "@angular/material/radio";
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_mat_checkbox_4_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-checkbox", 17);
    i0.ɵɵlistener("change", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_mat_checkbox_4_Template_mat_checkbox_change_0_listener($event) { i0.ɵɵrestoreView(_r12); const questionOption_r6 = i0.ɵɵnextContext().$implicit; const ctx_r10 = i0.ɵɵnextContext(3); return ctx_r10.onCheckboxChange(questionOption_r6, $event); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r6 = i0.ɵɵnextContext().$implicit;
    const ctx_r7 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", questionOption_r6.translations[ctx_r7.language] ? questionOption_r6.translations[ctx_r7.language] : questionOption_r6.translations["en"], " ");
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_mat_radio_button_5_Template(rf, ctx) { if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-radio-button", 18);
    i0.ɵɵlistener("change", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_mat_radio_button_5_Template_mat_radio_button_change_0_listener($event) { i0.ɵɵrestoreView(_r16); const questionOption_r6 = i0.ɵɵnextContext().$implicit; const ctx_r14 = i0.ɵɵnextContext(3); return ctx_r14.onRadioChange(questionOption_r6, $event); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r6 = i0.ɵɵnextContext().$implicit;
    const ctx_r8 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("value", questionOption_r6);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", questionOption_r6.translations[ctx_r8.language] ? questionOption_r6.translations[ctx_r8.language] : questionOption_r6.translations["en"], " ");
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_button_6_Template(rf, ctx) { if (rf & 1) {
    const _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 19);
    i0.ɵɵlistener("click", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_button_6_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r20); const questionOption_r6 = i0.ɵɵnextContext().$implicit; const ctx_r18 = i0.ɵɵnextContext(3); return ctx_r18.toggleButtonSelection(questionOption_r6); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r6 = i0.ɵɵnextContext().$implicit;
    const ctx_r9 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r9.answers.has(questionOption_r6.id) ? "btn-primary" : "btn-secondary");
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(questionOption_r6.translations[ctx_r9.language] ? questionOption_r6.translations[ctx_r9.language] : questionOption_r6.translations["en"]);
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 11);
    i0.ɵɵelementStart(2, "div", 12);
    i0.ɵɵelementStart(3, "div", 13);
    i0.ɵɵtemplate(4, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_mat_checkbox_4_Template, 2, 1, "mat-checkbox", 14);
    i0.ɵɵtemplate(5, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_mat_radio_button_5_Template, 2, 2, "mat-radio-button", 15);
    i0.ɵɵtemplate(6, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_button_6_Template, 2, 2, "button", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵclassProp("justify-content-center", ctx_r3.question.type === "SINGLE_CHOICE_BUTTON" || ctx_r3.question.type === "MULTI_CHOICE_BUTTON");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.question.type === "MULTI_CHOICE");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.question.type === "SINGLE_CHOICE");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.question.type === "SINGLE_CHOICE_BUTTON" || ctx_r3.question.type === "MULTI_CHOICE_BUTTON");
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_mat_checkbox_4_Template(rf, ctx) { if (rf & 1) {
    const _r29 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-checkbox", 17);
    i0.ɵɵlistener("change", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_mat_checkbox_4_Template_mat_checkbox_change_0_listener($event) { i0.ɵɵrestoreView(_r29); const questionOption_r23 = i0.ɵɵnextContext().$implicit; const ctx_r27 = i0.ɵɵnextContext(4); return ctx_r27.onCheckboxChange(questionOption_r23, $event); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r23 = i0.ɵɵnextContext().$implicit;
    const ctx_r24 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", questionOption_r23.translations[ctx_r24.language] ? questionOption_r23.translations[ctx_r24.language] : questionOption_r23.translations["en"], " ");
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_mat_radio_button_5_Template(rf, ctx) { if (rf & 1) {
    const _r33 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-radio-button", 18);
    i0.ɵɵlistener("change", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_mat_radio_button_5_Template_mat_radio_button_change_0_listener($event) { i0.ɵɵrestoreView(_r33); const questionOption_r23 = i0.ɵɵnextContext().$implicit; const ctx_r31 = i0.ɵɵnextContext(4); return ctx_r31.onRadioChange(questionOption_r23, $event); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r23 = i0.ɵɵnextContext().$implicit;
    const ctx_r25 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("value", questionOption_r23);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", questionOption_r23.translations[ctx_r25.language] ? questionOption_r23.translations[ctx_r25.language] : questionOption_r23.translations["en"], " ");
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_button_6_Template(rf, ctx) { if (rf & 1) {
    const _r37 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 19);
    i0.ɵɵlistener("click", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_button_6_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r37); const questionOption_r23 = i0.ɵɵnextContext().$implicit; const ctx_r35 = i0.ɵɵnextContext(4); return ctx_r35.toggleButtonSelection(questionOption_r23); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r23 = i0.ɵɵnextContext().$implicit;
    const ctx_r26 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngClass", ctx_r26.answers.has(questionOption_r23.id) ? "btn-primary" : "btn-secondary");
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(questionOption_r23.translations[ctx_r26.language] ? questionOption_r23.translations[ctx_r26.language] : questionOption_r23.translations["en"]);
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 11);
    i0.ɵɵelementStart(2, "div", 12);
    i0.ɵɵelementStart(3, "div", 13);
    i0.ɵɵtemplate(4, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_mat_checkbox_4_Template, 2, 1, "mat-checkbox", 14);
    i0.ɵɵtemplate(5, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_mat_radio_button_5_Template, 2, 2, "mat-radio-button", 15);
    i0.ɵɵtemplate(6, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_button_6_Template, 2, 2, "button", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r22 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(3);
    i0.ɵɵclassProp("justify-content-center", ctx_r22.question.type === "SINGLE_CHOICE_BUTTON" || ctx_r22.question.type === "MULTI_CHOICE_BUTTON");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r22.question.type === "MULTI_CHOICE");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r22.question.type === "SINGLE_CHOICE");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r22.question.type === "SINGLE_CHOICE_BUTTON" || ctx_r22.question.type === "MULTI_CHOICE_BUTTON");
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵtemplate(1, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_ng_container_1_Template, 7, 5, "ng-container", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r4.secondColumnExtraQuestionOptions);
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_mat_checkbox_2_Template(rf, ctx) { if (rf & 1) {
    const _r45 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-checkbox", 27);
    i0.ɵɵlistener("change", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_mat_checkbox_2_Template_mat_checkbox_change_0_listener($event) { i0.ɵɵrestoreView(_r45); const questionOption_r40 = i0.ɵɵnextContext().$implicit; const ctx_r43 = i0.ɵɵnextContext(4); return ctx_r43.onCheckboxChange(questionOption_r40, $event); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r40 = i0.ɵɵnextContext().$implicit;
    const ctx_r41 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("checked", ctx_r41.answers.has(questionOption_r40.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", questionOption_r40.translations[ctx_r41.language] ? questionOption_r40.translations[ctx_r41.language] : questionOption_r40.translations["en"], " ");
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_mat_radio_button_3_Template(rf, ctx) { if (rf & 1) {
    const _r49 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-radio-button", 28);
    i0.ɵɵlistener("change", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_mat_radio_button_3_Template_mat_radio_button_change_0_listener($event) { i0.ɵɵrestoreView(_r49); const questionOption_r40 = i0.ɵɵnextContext().$implicit; const ctx_r47 = i0.ɵɵnextContext(4); return ctx_r47.onRadioChange(questionOption_r40, $event); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r40 = i0.ɵɵnextContext().$implicit;
    const ctx_r42 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("value", questionOption_r40)("checked", ctx_r42.answers.has(questionOption_r40.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", questionOption_r40.translations[ctx_r42.language] ? questionOption_r40.translations[ctx_r42.language] : questionOption_r40.translations["en"], " ");
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    const _r52 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 22);
    i0.ɵɵtemplate(2, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_mat_checkbox_2_Template, 2, 2, "mat-checkbox", 23);
    i0.ɵɵtemplate(3, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_mat_radio_button_3_Template, 2, 3, "mat-radio-button", 24);
    i0.ɵɵelementStart(4, "div", 25);
    i0.ɵɵelementStart(5, "input", 26);
    i0.ɵɵlistener("keyup", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_Template_input_keyup_5_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r52); const questionOption_r40 = restoredCtx.$implicit; const ctx_r51 = i0.ɵɵnextContext(4); return ctx_r51.onTextboxChange(questionOption_r40, $event); })("focusout", function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_Template_input_focusout_5_listener() { i0.ɵɵrestoreView(_r52); const ctx_r53 = i0.ɵɵnextContext(4); return ctx_r53.onTextareaFocusOut(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const questionOption_r40 = ctx.$implicit;
    const ctx_r39 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r39.question.type === "MULTI_CHOICE");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r39.question.type === "SINGLE_CHOICE");
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("placeholder", questionOption_r40.translations[ctx_r39.language] ? questionOption_r40.translations[ctx_r39.language] : questionOption_r40.translations["en"]);
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 21);
    i0.ɵɵtemplate(1, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_ng_container_1_Template, 6, 3, "ng-container", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r5.freeTextExtraQuestionOptions);
} }
function WellbeingScanExtraQuestionComponent_div_0_ng_container_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵelementStart(2, "div", 7);
    i0.ɵɵtemplate(3, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_ng_container_3_Template, 7, 5, "ng-container", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_4_Template, 2, 1, "div", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_div_5_Template, 2, 1, "div", 10);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("col-lg-6", ctx_r1.secondColumnExtraQuestionOptions == null ? null : ctx_r1.secondColumnExtraQuestionOptions.length);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r1.firstColumnExtraQuestionOptions);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.secondColumnExtraQuestionOptions == null ? null : ctx_r1.secondColumnExtraQuestionOptions.length);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.freeTextExtraQuestionOptions == null ? null : ctx_r1.freeTextExtraQuestionOptions.length);
} }
function WellbeingScanExtraQuestionComponent_div_0_div_5_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r57 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelementStart(1, "div", 31);
    i0.ɵɵelementStart(2, "label");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "textarea", 32);
    i0.ɵɵlistener("focusout", function WellbeingScanExtraQuestionComponent_div_0_div_5_div_1_Template_textarea_focusout_4_listener() { i0.ɵɵrestoreView(_r57); const ctx_r56 = i0.ɵɵnextContext(3); return ctx_r56.onTextareaFocusOut(); })("keyup", function WellbeingScanExtraQuestionComponent_div_0_div_5_div_1_Template_textarea_keyup_4_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r57); const questionOption_r55 = restoredCtx.$implicit; const ctx_r58 = i0.ɵɵnextContext(3); return ctx_r58.onTextboxChange(questionOption_r55, $event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const questionOption_r55 = ctx.$implicit;
    const ctx_r54 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(questionOption_r55.translations[ctx_r54.language] ? questionOption_r55.translations[ctx_r54.language] : questionOption_r55.translations["en"]);
} }
function WellbeingScanExtraQuestionComponent_div_0_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 29);
    i0.ɵɵtemplate(1, WellbeingScanExtraQuestionComponent_div_0_div_5_div_1_Template, 5, 1, "div", 30);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r2.question.extraQuestionOptions);
} }
function WellbeingScanExtraQuestionComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "h2", 3);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, WellbeingScanExtraQuestionComponent_div_0_ng_container_4_Template, 6, 5, "ng-container", 4);
    i0.ɵɵtemplate(5, WellbeingScanExtraQuestionComponent_div_0_div_5_Template, 2, 1, "div", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r0.question.translations[ctx_r0.language] ? ctx_r0.question.translations[ctx_r0.language] : ctx_r0.question.translations["en"]);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.question.type !== "FREE_TEXT");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.question.type === "FREE_TEXT");
} }
export class WellbeingScanExtraQuestionComponent {
    constructor(languageService, translateService) {
        this.languageService = languageService;
        this.translateService = translateService;
        // Extra question option id -> Extra question answer
        this.answers = new Map();
        this.tempQuestionOptionTexts = {};
        this.firstColumnExtraQuestionOptions = [];
        this.secondColumnExtraQuestionOptions = [];
        this.freeTextExtraQuestionOptions = [];
        this.onAnswered = new EventEmitter();
        this.language = languageService.getCurrentLanguage();
        this.langChangeSubscription = translateService.onLangChange.subscribe(() => {
            this.language = languageService.getCurrentLanguage();
        });
    }
    set question(value) {
        this._question = value;
        this.answers = new Map();
        this.tempQuestionOptionTexts = {};
        this.divideExtraQuestionOptions(value);
    }
    get question() {
        return this._question;
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        var _a;
        (_a = this.langChangeSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    onRadioChange(extraQuestionOption, e) {
        // radio button is single selection, remove the other answers
        console.log(e.value);
        this.answers = new Map();
        this.addAnswer(extraQuestionOption);
        this.emitAnswers();
    }
    onCheckboxChange(extraQuestionOption, e) {
        if (e.checked) {
            if (!this.answers.has(extraQuestionOption.id)) {
                this.addAnswer(extraQuestionOption);
            }
        }
        else {
            // remove the answer
            this.answers.delete(extraQuestionOption.id);
        }
        this.emitAnswers();
    }
    toggleButtonSelection(extraQuestionOption) {
        if (this.answers.has(extraQuestionOption.id)) {
            this.answers.delete(extraQuestionOption.id);
        }
        else {
            this.addAnswer(extraQuestionOption);
        }
        this.emitAnswers();
    }
    onTextboxChange(extraQuestionOption, e) {
        const answer = this.answers.get(extraQuestionOption.id);
        const text = (e.target).value;
        if (answer) {
            answer.text = text;
        }
        this.tempQuestionOptionTexts[extraQuestionOption.id] = text;
    }
    onTextareaFocusOut() {
        for (const questionOptionId of Object.keys(this.tempQuestionOptionTexts)) {
            if (!this.answers.has(Number(questionOptionId))) {
                const questionOption = this.question.extraQuestionOptions.find(o => o.id === Number(questionOptionId));
                this.addAnswer(questionOption);
            }
        }
        this.emitAnswers();
    }
    addAnswer(extraQuestionOption) {
        if (this.question.type === 'SINGLE_CHOICE_BUTTON' || this.question.type === 'SINGLE_CHOICE') {
            // remove the existing answers, since it's a single choice question
            this.answers.clear();
            this.tempQuestionOptionTexts = {};
        }
        const text = this.tempQuestionOptionTexts[extraQuestionOption.id];
        const answer = new ExtraQuestionAnswer(null, text, null, extraQuestionOption);
        this.answers.set(extraQuestionOption.id, answer);
    }
    emitAnswers() {
        const answersToEmit = [];
        for (const extraQuestionAnswer of this.answers.values()) {
            answersToEmit.push(extraQuestionAnswer);
        }
        this.onAnswered.emit(answersToEmit);
    }
    divideExtraQuestionOptions(extraQuestion) {
        this.firstColumnExtraQuestionOptions = [];
        this.secondColumnExtraQuestionOptions = [];
        this.freeTextExtraQuestionOptions = [];
        if (extraQuestion.type !== 'FREE_TEXT') {
            for (const extraQuestionOption of extraQuestion.extraQuestionOptions) {
                if (extraQuestionOption.type === 'FREE_TEXT') {
                    this.freeTextExtraQuestionOptions.push(extraQuestionOption);
                }
                else {
                    this.firstColumnExtraQuestionOptions.push(extraQuestionOption);
                }
            }
            if (this.firstColumnExtraQuestionOptions.length >= 2) {
                const totalNonFreeTextExtraQuestionOptions = this.firstColumnExtraQuestionOptions.length;
                if (totalNonFreeTextExtraQuestionOptions > 5) {
                    const firstColumnOptionsCount = Math.ceil(totalNonFreeTextExtraQuestionOptions / 2);
                    this.secondColumnExtraQuestionOptions = this.firstColumnExtraQuestionOptions.slice(firstColumnOptionsCount, totalNonFreeTextExtraQuestionOptions);
                    this.firstColumnExtraQuestionOptions = this.firstColumnExtraQuestionOptions.slice(0, firstColumnOptionsCount);
                }
            }
        }
    }
}
WellbeingScanExtraQuestionComponent.ɵfac = function WellbeingScanExtraQuestionComponent_Factory(t) { return new (t || WellbeingScanExtraQuestionComponent)(i0.ɵɵdirectiveInject(i1.JhiLanguageService), i0.ɵɵdirectiveInject(i2.TranslateService)); };
WellbeingScanExtraQuestionComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WellbeingScanExtraQuestionComponent, selectors: [["wellbeing-scan-extra-question"]], inputs: { question: "question", language: "language" }, outputs: { onAnswered: "onAnswered" }, decls: 1, vars: 1, consts: [["class", "bg-2", 4, "ngIf"], [1, "bg-2"], [1, "text-center", "px-lg-5", "mx-lg-5"], ["id", "ss-question-text", 1, "question-text"], [4, "ngIf"], ["class", "row", 4, "ngIf"], [1, "row", "mt-5"], [1, "col-12"], [4, "ngFor", "ngForOf"], ["class", "col-12 col-lg-6", 4, "ngIf"], ["class", "row pl-lg-5", 4, "ngIf"], [1, "px-lg-4", "extra-question-option"], [1, "row", "mb-2"], [1, "col", "text-left", "px-lg-5", "d-flex", "align-items-top"], ["class", "check-box", 3, "change", 4, "ngIf"], ["class", "check-box", 3, "value", "change", 4, "ngIf"], ["class", "btn btn-large border-0 m-2", 3, "ngClass", "click", 4, "ngIf"], [1, "check-box", 3, "change"], [1, "check-box", 3, "value", "change"], [1, "btn", "btn-large", "border-0", "m-2", 3, "ngClass", "click"], [1, "col-12", "col-lg-6"], [1, "row", "pl-lg-5"], [1, "col-12", "pl-lg-5", "text-left"], ["class", "check-box", 3, "checked", "change", 4, "ngIf"], ["class", "check-box", 3, "value", "checked", "change", 4, "ngIf"], [1, "form-group", "px-2", "d-inline-block", "mb-0", 2, "width", "80%"], ["type", "text", 1, "mt-2", "p-4", "form-control", 3, "placeholder", "keyup", "focusout"], [1, "check-box", 3, "checked", "change"], [1, "check-box", 3, "value", "checked", "change"], [1, "row"], ["class", "col-12", 4, "ngFor", "ngForOf"], [1, "form-group", "px-2", "pt-4"], ["type", "text", "rows", "2", 1, "mt-2", "p-4", "form-control", 3, "focusout", "keyup"]], template: function WellbeingScanExtraQuestionComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, WellbeingScanExtraQuestionComponent_div_0_Template, 6, 3, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.question);
    } }, directives: [i3.NgIf, i3.NgForOf, i4.MatCheckbox, i5.MatRadioButton, i3.NgClass], styles: ["[_nghost-%COMP%]  .mat-checkbox-inner-container{margin:unset !important;margin-right:12px !important}[_nghost-%COMP%]  .mat-checkbox-frame{border:1px solid #44a38d !important;border-radius:4px !important}.extra-question-option[_ngcontent-%COMP%]{min-height:4.5rem}"] });
