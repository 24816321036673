export class ActionPlanItem {
    constructor(id, actionPlanGroup, itemText, status, actionPlan, shared, sort) {
        this.id = id;
        this.actionPlanGroup = actionPlanGroup;
        this.itemText = itemText;
        this.status = status;
        this.actionPlan = actionPlan;
        this.shared = shared;
        this.sort = sort;
        this.status = this.status || false;
        this.shared = this.shared || false;
    }
}
