import { ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackerService } from "../../core/tracker/tracker.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../core/tracker/tracker.service";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "../../shared/language/language-selector/language-selector.component";
import * as i6 from "../../shared/auth/has-any-authority.directive";
import * as i7 from "@angular/common";
import * as i8 from "../../shared/track-user-activity.directive";
function OnboardingComponent_a_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 13);
    i0.ɵɵelement(1, "img", 14);
    i0.ɵɵelementEnd();
} }
const _c0 = function (a0) { return { "background-color": a0 }; };
function OnboardingComponent_div_16_a_4_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 21);
    i0.ɵɵlistener("click", function OnboardingComponent_div_16_a_4_Template_a_click_0_listener() { const restoredCtx = i0.ɵɵrestoreView(_r5); const number_r3 = restoredCtx.$implicit; const ctx_r4 = i0.ɵɵnextContext(2); return ctx_r4.goToPage(number_r3); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const number_r3 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(1, _c0, ctx_r2.currentPageNumber === number_r3 ? "#44A38D" : "white"));
} }
const _c1 = function () { return { "track": "click", "name": "reg_corp_info_skip_btn_clicked" }; };
function OnboardingComponent_div_16_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵelementStart(1, "div", 16);
    i0.ɵɵelement(2, "a", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 18);
    i0.ɵɵtemplate(4, OnboardingComponent_div_16_a_4_Template, 1, 3, "a", 19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div", 16);
    i0.ɵɵelementStart(6, "a", 20);
    i0.ɵɵlistener("click", function OnboardingComponent_div_16_Template_a_click_6_listener() { i0.ɵɵrestoreView(_r7); const ctx_r6 = i0.ɵɵnextContext(); return ctx_r6.goToPage(ctx_r6.currentPageNumber + 1); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("trackUserActivity", i0.ɵɵpureFunction0(2, _c1));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.numbers);
} }
const _c2 = function () { return ["ROLE_USER"]; };
export class OnboardingComponent {
    constructor(router, activatedRoute, elementRef, trackerService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.elementRef = elementRef;
        this.trackerService = trackerService;
        this.numbers = [0, 1, 2, 3, 4, 5];
        this.imagePath = '../../../../content/images/onboarding/';
    }
    ngOnInit() {
        let url = window.location.href;
        if (url.includes('info=')) {
            this.activatedRoute.queryParams.subscribe(p => {
                this.currentPageNumber = parseInt(p['info'], 10);
            });
            this.router.navigate(['/onboarding'], { queryParams: { info: this.currentPageNumber } });
        }
        else {
            this.currentPageNumber = 0;
            this.router.navigate(['/onboarding'], { queryParams: { info: this.currentPageNumber } });
        }
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
    }
    goToPage(pageNumber) {
        this.currentPageNumber = pageNumber;
        if (this.currentPageNumber === this.numbers.length) {
            this.router.navigate(['/wellbeing-start']);
        }
        else {
            this.router.navigate(['/onboarding'], { queryParams: { info: this.currentPageNumber } });
            this.trackerService.sendActivity("reg_corp_info_open_" + this.currentPageNumber);
        }
    }
    handWritingText() {
        return 'inukaNgApp.onboarding.handWriting.h' + this.currentPageNumber;
    }
    onBoardingText() {
        return 'inukaNgApp.onboarding.text.t' + this.currentPageNumber;
    }
    onBoardingImage() {
        return this.imagePath + this.currentPageNumber + '.png';
    }
}
OnboardingComponent.ɵfac = function OnboardingComponent_Factory(t) { return new (t || OnboardingComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.TrackerService)); };
OnboardingComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OnboardingComponent, selectors: [["onboarding-component"]], decls: 17, vars: 6, consts: [[1, "page"], [1, ""], ["id", "page-navbar", 1, "navbar", "navbar-dark"], ["jhiTranslate", "inukaNgApp.onboarding.header", 2, "margin-top", "14px", "color", "black"], [2, "float", "right"], ["routerLink", "/session/inbox/customer-care", 4, "jhiHasAnyAuthority"], [1, "page-single"], [2, "display", "block"], [2, "display", "block", "text-align", "center", "margin-bottom", "20px"], ["id", "onBoarding-image", "alt", "onboarding image", 3, "src"], [1, "text-uppercase", 2, "display", "block", "text-align", "center", "font-weight", "bold", "font-size", "18px", 3, "jhiTranslate"], [2, "min-height", "5rem", "display", "block", "text-align", "center", "font-size", "16px", 3, "jhiTranslate"], ["style", "display: flex; justify-content: space-between; font-weight: bold; position: relative;", 4, "ngIf"], ["routerLink", "/session/inbox/customer-care"], ["src", "../../../../content/images/inuka/help-24px@2x.png", "alt", "help-24px image", 2, "height", "26px"], [2, "display", "flex", "justify-content", "space-between", "font-weight", "bold", "position", "relative"], [2, "color", "#44A38D", "text-decoration", "none", "font-size", "18px"], ["href", "javascript:void(0)", "routerLink", "/wellbeing-start", "jhiTranslate", "inukaNgApp.onboarding.skip", 3, "trackUserActivity"], [2, "text-align", "center", "position", "absolute", "left", "50%", "top", "5px", "transform", "translateX(-50%)"], ["class", "dot", 3, "ngStyle", "click", 4, "ngFor", "ngForOf"], ["href", "javascript:void(0)", "jhiTranslate", "inukaNgApp.onboarding.next", 3, "click"], [1, "dot", 3, "ngStyle", "click"]], template: function OnboardingComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "nav", 2);
        i0.ɵɵelement(3, "p");
        i0.ɵɵelementStart(4, "div");
        i0.ɵɵelement(5, "h2", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 4);
        i0.ɵɵelement(7, "language-selector");
        i0.ɵɵtemplate(8, OnboardingComponent_a_8_Template, 2, 0, "a", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 6);
        i0.ɵɵelementStart(10, "div", 7);
        i0.ɵɵelementStart(11, "span", 8);
        i0.ɵɵelement(12, "img", 9);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(13, "span", 10);
        i0.ɵɵelement(14, "span", 11);
        i0.ɵɵelement(15, "br");
        i0.ɵɵtemplate(16, OnboardingComponent_div_16_Template, 7, 3, "div", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(8);
        i0.ɵɵproperty("jhiHasAnyAuthority", i0.ɵɵpureFunction0(5, _c2));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("src", ctx.onBoardingImage(), i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("jhiTranslate", ctx.handWritingText());
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("jhiTranslate", ctx.onBoardingText());
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.currentPageNumber < 6);
    } }, directives: [i3.NgbNavbar, i4.JhiTranslateDirective, i5.LanguageSelectorComponent, i6.HasAnyAuthorityDirective, i7.NgIf, i1.RouterLinkWithHref, i8.TrackUserActivityDirective, i7.NgForOf, i7.NgStyle], styles: [".page-single[_ngcontent-%COMP%]{background-color:#fff;border-top-left-radius:20px;border-top-right-radius:20px;margin-top:80px}#onBoarding-image[_ngcontent-%COMP%]{width:300px;height:300px}"] });
