import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';
import * as i0 from "@angular/core";
// precalculated exponential backoff times, you can use this tools to calculate http://backoffcalculator.com/?rate=2&interval=1&attempts=10
export const retryAttemptBackoff = {
    1: { 'delay': 1000, 'totalWaitTime': 1000 },
    2: { 'delay': 2000, 'totalWaitTime': 3000 },
    3: { 'delay': 4000, 'totalWaitTime': 7000 },
    4: { 'delay': 8000, 'totalWaitTime': 15000 },
    5: { 'delay': 16000, 'totalWaitTime': 31000 },
    6: { 'delay': 32000, 'totalWaitTime': 63000 }
};
export const maxRetryCount = 5; // based on precalculated backoff times 5 retry mean each request will be retried for 31 seconds at max
// Normally we don't retry POST,PUT,DELETE requests because can cause duplication,
// We can add any POST,PUT,DELETE endpoints to this list if we are sure that they don't add/update/delete any data on server/database
export const retryableEndpoint = [
    '/api/authenticate',
    '/api/sessions/available'
];
// Http response codes that we hope can respond correctly after some retry
export const retryableResponseCodes = [0, 500, 502, 503, 504];
export class RetryFailedRequestInterceptor {
    constructor() {
    }
    intercept(req, next) {
        return next.handle(req)
            .pipe(retryWhen(errors => errors.pipe(mergeMap((error, i) => {
            const retryAttempt = i + 1;
            if (this.isRequestRetryable(req) && this.isErrorRetryable(error) && retryAttempt < maxRetryCount) {
                return timer(retryAttemptBackoff[retryAttempt].delay);
            }
            else {
                return throwError(error);
            }
        }))));
    }
    isRequestRetryable(req) {
        if (req.method.indexOf('GET') === 0) {
            return true;
        }
        // is POST,PUT,DELETE but included in the exception endpoints list
        if (this.isInRetryableEndpoints(req)) {
            return true;
        }
        return false;
    }
    isErrorRetryable(error) {
        if (error instanceof HttpErrorResponse) {
            if (retryableResponseCodes.includes(error.status)) {
                return true;
            }
        }
        if (error.name && (error.name).toString() === 'TimeoutError') {
            return true;
        }
        return false;
    }
    isInRetryableEndpoints(req) {
        for (const endPoint of retryableEndpoint) {
            if (req.url.endsWith(endPoint)) {
                return true;
            }
        }
        return false;
    }
}
RetryFailedRequestInterceptor.ɵfac = function RetryFailedRequestInterceptor_Factory(t) { return new (t || RetryFailedRequestInterceptor)(); };
RetryFailedRequestInterceptor.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RetryFailedRequestInterceptor, factory: RetryFailedRequestInterceptor.ɵfac });
