import { ElementRef } from '@angular/core';
import { FlowManagerService } from '../../core/util/flow-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/util/flow-manager.service";
export class HideIfFlowDirective {
    constructor(el, flowManagerService) {
        this.el = el;
        this.flowManagerService = flowManagerService;
        this.flow = '';
    }
    set jhiHideIfFlow(value) {
        this.flow = value;
    }
    get jhiHideIfFlow() {
        return this.flow;
    }
    toggleElement() {
        if (this.flowManagerService.isFlowInProgress(this.flow)) {
            this.el.nativeElement.style.visibility = 'hidden';
        }
    }
    ngOnInit() {
        this.toggleElement();
    }
}
HideIfFlowDirective.ɵfac = function HideIfFlowDirective_Factory(t) { return new (t || HideIfFlowDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.FlowManagerService)); };
HideIfFlowDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: HideIfFlowDirective, selectors: [["", "jhiHideIfFlow", ""]], inputs: { jhiHideIfFlow: "jhiHideIfFlow" } });
