import { ElementRef, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from '../../core/user/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "../../core/user/user.service";
import * as i3 from "@angular/common";
import * as i4 from "./resources-for-help.component";
function ResourcesForHelpPageComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "resources-for-help", 3);
    i0.ɵɵlistener("resourceSelected", function ResourcesForHelpPageComponent_div_0_Template_resources_for_help_resourceSelected_2_listener($event) { i0.ɵɵrestoreView(_r2); const ctx_r1 = i0.ɵɵnextContext(); return ctx_r1.onResourceSelected($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("user", ctx_r0.user);
} }
export class ResourcesForHelpPageComponent {
    constructor(elementRef, modalService, userService) {
        this.elementRef = elementRef;
        this.modalService = modalService;
        this.userService = userService;
        this.resourceSelected = new EventEmitter();
    }
    ngOnInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F7F4EB';
        this.userService.current().subscribe(currentUser => {
            this.user = currentUser;
            console.log("Current user id: " + this.user.id);
        });
    }
    onResourceSelected($event) {
        this.resourceSelected.emit({ resource: $event.resource });
    }
    closeAll() {
        this.modalService.dismissAll();
    }
}
ResourcesForHelpPageComponent.ɵfac = function ResourcesForHelpPageComponent_Factory(t) { return new (t || ResourcesForHelpPageComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.NgbModal), i0.ɵɵdirectiveInject(i2.UserService)); };
ResourcesForHelpPageComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ResourcesForHelpPageComponent, selectors: [["resources-for-help-page"]], outputs: { resourceSelected: "resourceSelected" }, decls: 1, vars: 1, consts: [["class", "olha-theme", "style", "margin-top: 100px;", 4, "ngIf"], [1, "olha-theme", 2, "margin-top", "100px"], [1, "container", "col-10"], [3, "user", "resourceSelected"]], template: function ResourcesForHelpPageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, ResourcesForHelpPageComponent_div_0_Template, 3, 1, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.user.id);
    } }, directives: [i3.NgIf, i4.ResourcesForHelpComponent], styles: [".container[_ngcontent-%COMP%]{border-radius:20px;padding:30px;margin:15px auto 15px auto;background:#fff}.close-icon[_ngcontent-%COMP%]{position:absolute !important;top:12px !important;right:12px !important;cursor:pointer !important;color:#0a4a3a !important}.notification-link[_ngcontent-%COMP%]{color:#44a38d;font-weight:bold;text-decoration:underline;cursor:pointer;margin-left:5px}.header-3[_ngcontent-%COMP%]{margin-bottom:50px}"] });
