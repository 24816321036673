import * as __NgCli_bootstrap_1 from "@angular/platform-browser";
import './polyfills';
import { ProdConfig } from './blocks/config/prod.config';
import { InukaNgAppModule } from './app.module';
import { VERSION } from './app.constants';
ProdConfig();
if (module['hot']) {
    module['hot'].accept();
}
__NgCli_bootstrap_1.platformBrowser()
    .bootstrapModule(InukaNgAppModule, { preserveWhitespaces: true })
    // eslint-disable-next-line no-console
    .then(() => {
    console.log('Application started');
    console.log(VERSION);
})
    .catch(err => {
    console.error(err);
});
