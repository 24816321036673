import { ElementRef } from '@angular/core';
import { ZoomService } from '../zoom.service';
import { Session } from '../../../shared/model/session.model';
import * as i0 from "@angular/core";
import * as i1 from "../zoom.service";
import * as i2 from "@angular/common";
import * as i3 from "../participant-video/participant-video.component";
import * as i4 from "../self-video/self-video.component";
import * as i5 from "../participant-status/participant-status.component";
import * as i6 from "../connecting-to-call/connecting-to-call.component";
import * as i7 from "../bad-network/bad-network.component";
const _c0 = ["participantVideoContainer"];
function ZoomCallComponent_connecting_to_call_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "connecting-to-call");
} }
function ZoomCallComponent_bad_network_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "bad-network");
} }
const _c1 = function (a0) { return { "d-none": a0 }; };
export class ZoomCallComponent {
    constructor(zoomService) {
        this.zoomService = zoomService;
    }
    set height(value) {
        if (value !== this._height) {
            this._height = value;
            this.resizeVideo();
        }
    }
    get height() {
        return this._height;
    }
    ngOnDestroy() {
        var _a;
        (_a = this.connectionStatusSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    ngAfterViewInit() {
        this.resizeVideo();
        this.connectionStatusSubscription = this.zoomService.connectionStatus$().subscribe(status => {
            this.connectionStatus = status;
        });
    }
    resizeVideo() {
        if (this.participantVideoContainer) {
            this.participantVideoWidth = this.participantVideoContainer.nativeElement.offsetWidth;
            this.participantVideoHeight = this.participantVideoContainer.nativeElement.offsetHeight;
        }
    }
    ngOnInit() {
    }
}
ZoomCallComponent.ɵfac = function ZoomCallComponent_Factory(t) { return new (t || ZoomCallComponent)(i0.ɵɵdirectiveInject(i1.ZoomService)); };
ZoomCallComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ZoomCallComponent, selectors: [["zoom-call"]], viewQuery: function ZoomCallComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.participantVideoContainer = _t.first);
    } }, inputs: { height: "height", session: "session", selfUser: "selfUser", participantUser: "participantUser" }, decls: 13, vars: 22, consts: [[2, "position", "relative", "background-color", "#DFF1EC"], [2, "width", "100%", 3, "ngClass"], ["participantVideoContainer", ""], [2, "width", "100%", "display", "block", 3, "width", "height", "participantUser"], [3, "ngClass"], [4, "ngIf"], [1, "self-video-container", 3, "ngClass"], ["selfVideoContainer", ""], [3, "width", "height", "selfUser"], [1, "participant-status-container"], [3, "participantUser"]], template: function ZoomCallComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1, 2);
        i0.ɵɵelement(3, "participant-video", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵtemplate(5, ZoomCallComponent_connecting_to_call_5_Template, 1, 0, "connecting-to-call", 5);
        i0.ɵɵtemplate(6, ZoomCallComponent_bad_network_6_Template, 1, 0, "bad-network", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 6);
        i0.ɵɵelementStart(8, "div", null, 7);
        i0.ɵɵelement(10, "self-video", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "div", 9);
        i0.ɵɵelement(12, "participant-status", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        const _r0 = i0.ɵɵreference(2);
        const _r3 = i0.ɵɵreference(9);
        i0.ɵɵadvance(1);
        i0.ɵɵstyleProp("height", ctx.height + "px");
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(16, _c1, ctx.connectionStatus !== "CONNECTED"));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("width", _r0.offsetWidth)("height", _r0.offsetHeight)("participantUser", ctx.participantUser);
        i0.ɵɵadvance(1);
        i0.ɵɵstyleProp("height", ctx.height + "px");
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(18, _c1, ctx.connectionStatus === "CONNECTED"));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.connectionStatus === "CONNECTING");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.connectionStatus === "FAILED");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(20, _c1, ctx.connectionStatus !== "CONNECTED"));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("width", _r3.offsetWidth)("height", _r3.offsetHeight)("selfUser", ctx.selfUser);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("participantUser", ctx.participantUser);
    } }, directives: [i2.NgClass, i3.ParticipantVideoComponent, i2.NgIf, i4.SelfVideoComponent, i5.ParticipantStatusComponent, i6.ConnectingToCallComponent, i7.BadNetworkComponent], styles: [".self-video-container[_ngcontent-%COMP%]{display:inline-flex;width:45%;height:auto;position:absolute;right:0;bottom:0;flex-direction:column;justify-content:center}.participant-status-container[_ngcontent-%COMP%]{position:absolute;left:0;bottom:0;width:30%;height:auto}@media(min-width: 1200px){.self-video-container[_ngcontent-%COMP%]{width:30%}.participant-status-container[_ngcontent-%COMP%]{width:20%}}"] });
