import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserDetailsService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/user-details';
    }
    create(userDetails) {
        return this.http.post(this.resourceUrl, userDetails, { observe: 'response' });
    }
    update(userDetails) {
        return this.http.put(this.resourceUrl, userDetails, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
UserDetailsService.ɵfac = function UserDetailsService_Factory(t) { return new (t || UserDetailsService)(i0.ɵɵinject(i1.HttpClient)); };
UserDetailsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserDetailsService, factory: UserDetailsService.ɵfac, providedIn: 'root' });
