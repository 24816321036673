import { ActivatedRoute } from "@angular/router";
import { UserService } from '../../core/user/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../core/user/user.service";
import * as i3 from "@angular/common";
function UnsubscribeComponent_div_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelementStart(1, "strong");
    i0.ɵɵtext(2, "You have been unsubscribed from Inuka notifications.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function UnsubscribeComponent_div_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelementStart(1, "strong");
    i0.ɵɵtext(2, "This account is already unsubscribed from Inuka notifications.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function UnsubscribeComponent_div_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelementStart(1, "strong");
    i0.ɵɵtext(2, "Invalid unsubscription code. Please contact ");
    i0.ɵɵelementStart(3, "a", 9);
    i0.ɵɵtext(4, "support");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, ".");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
export class UnsubscribeComponent {
    constructor(route, userService) {
        this.route = route;
        this.userService = userService;
        this.code = '';
        this.success = false;
        this.errorCodeDoesntExists = false;
        this.errorUserAlreadyUnsubscribed = false;
    }
    ngOnInit() { }
    unsubscribe() {
        this.code = this.route.snapshot.params.activationKey;
        if (this.code !== undefined) {
            this.userService.unsubscribe(this.code).subscribe(() => (this.success = true), response => this.processError(response));
        }
        else
            this.errorCodeDoesntExists = true;
    }
    processError(response) {
        if (response.status === 404) {
            this.errorCodeDoesntExists = true;
        }
        else if (response.status === 302) {
            this.errorUserAlreadyUnsubscribed = true;
        }
    }
}
UnsubscribeComponent.ɵfac = function UnsubscribeComponent_Factory(t) { return new (t || UnsubscribeComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.UserService)); };
UnsubscribeComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: UnsubscribeComponent, selectors: [["unsubscribe-component"]], decls: 17, vars: 4, consts: [[1, "container"], [1, "row", "m-3"], [1, "offset-md-2", "col-md-8", "offset-sm-1", "col-sm-9", "text-center"], [1, "offset-md-3", "col-md-6", "offset-sm-2", "col-sm-8", "text-center"], [1, "btn", "btn-primary", 3, "disabled", "click"], ["class", "alert alert-success", 4, "ngIf"], ["class", "alert alert-danger", 4, "ngIf"], [1, "alert", "alert-success"], [1, "alert", "alert-danger"], ["href", "mailto:inukacares@inukacoaching.com"]], template: function UnsubscribeComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵtext(3, " Are you sure you wish to unsubscribe from Inuka notifications? ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 1);
        i0.ɵɵelementStart(5, "div", 3);
        i0.ɵɵelementStart(6, "button", 4);
        i0.ɵɵlistener("click", function UnsubscribeComponent_Template_button_click_6_listener() { return ctx.unsubscribe(); });
        i0.ɵɵtext(7, "Yes, please");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "div", 1);
        i0.ɵɵelementStart(9, "div", 3);
        i0.ɵɵtemplate(10, UnsubscribeComponent_div_10_Template, 3, 0, "div", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "div", 1);
        i0.ɵɵelementStart(12, "div", 3);
        i0.ɵɵtemplate(13, UnsubscribeComponent_div_13_Template, 3, 0, "div", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(14, "div", 1);
        i0.ɵɵelementStart(15, "div", 3);
        i0.ɵɵtemplate(16, UnsubscribeComponent_div_16_Template, 6, 0, "div", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("disabled", ctx.success || ctx.errorUserAlreadyUnsubscribed || ctx.errorCodeDoesntExists);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.success);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.errorUserAlreadyUnsubscribed);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.errorCodeDoesntExists);
    } }, directives: [i3.NgIf], encapsulation: 2 });
