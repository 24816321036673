import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class RequestTimeoutInterceptor {
    constructor() {
        this.defaultTimeout = 180000; // 3min
    }
    intercept(req, next) {
        const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
        const timeoutValueNumeric = Number(timeoutValue);
        return next.handle(req).pipe(timeout(timeoutValueNumeric));
    }
}
RequestTimeoutInterceptor.ɵfac = function RequestTimeoutInterceptor_Factory(t) { return new (t || RequestTimeoutInterceptor)(); };
RequestTimeoutInterceptor.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RequestTimeoutInterceptor, factory: RequestTimeoutInterceptor.ɵfac });
