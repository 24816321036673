import { EventEmitter } from "@angular/core";
import moment from "moment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/form-field";
import * as i2 from "@angular/material/select";
import * as i3 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i4 from "@angular/material/slide-toggle";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/core";
import * as i7 from "@ngx-translate/core";
function TimezoneDropdownComponent_mat_option_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 12);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 13);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const timezone_r3 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", timezone_r3);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(timezone_r3);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r2.isAmPm ? ctx_r2.currentTime.tz(timezone_r3).format("h:mm a") : ctx_r2.currentTime.tz(timezone_r3).format("HH:mm"), " ");
} }
export class TimezoneDropdownComponent {
    constructor() {
        this.onTimezoneSelected = new EventEmitter();
        this.onAmPmChanged = new EventEmitter();
        this.isAmPm = false;
    }
    ngOnInit() {
        this.currentTime = moment();
        this.displayedTimezones = this.timezones;
    }
    selectTimezone() {
        console.log(this.timezones);
        this.onTimezoneSelected.emit(this.selectedTimezone);
    }
    toggleAmPm(isAmPm) {
        this.isAmPm = !isAmPm;
        this.onAmPmChanged.emit(this.isAmPm);
        return this.isAmPm;
    }
    onKeyUp(event) {
        this.displayedTimezones = this.timezones.filter(t => t.toLowerCase().includes(event.toLowerCase()));
    }
}
TimezoneDropdownComponent.ɵfac = function TimezoneDropdownComponent_Factory(t) { return new (t || TimezoneDropdownComponent)(); };
TimezoneDropdownComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TimezoneDropdownComponent, selectors: [["timezone-dropdown"]], inputs: { selectedTimezone: "selectedTimezone", timezones: "timezones" }, outputs: { onTimezoneSelected: "onTimezoneSelected", onAmPmChanged: "onAmPmChanged" }, decls: 16, vars: 10, consts: [["appearance", "outline", "floatLabel", "never", 1, "timezone-selector"], ["tabindex", "-1", "panelClass", "schedule-session-selectors-dropdown-panel", 3, "value", "placeholder", "valueChange", "closed"], ["select", ""], [1, "p-0", "px-3", "pt-3", "time-format", "d-flex", "align-items-center", "justify-content-between"], ["jhiTranslate", "sessionSchedule.paragraph.timeFormat"], [1, "d-flex", "align-items-center"], ["jhiTranslate", "sessionSchedule.paragraph.amPm", 1, "pr-2"], [3, "checked", "toggleChange"], [1, "p-2", "d-flex", "align-items-center", "justify-content-center"], ["type", "text", 1, "timezone-search", 3, "placeholder", "keyup"], ["searchBox", ""], [3, "value", 4, "ngFor", "ngForOf"], [3, "value"], [1, "float-right"]], template: function TimezoneDropdownComponent_Template(rf, ctx) { if (rf & 1) {
        const _r4 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "mat-form-field", 0);
        i0.ɵɵelementStart(1, "mat-select", 1, 2);
        i0.ɵɵlistener("valueChange", function TimezoneDropdownComponent_Template_mat_select_valueChange_1_listener($event) { return ctx.selectedTimezone = $event; })("closed", function TimezoneDropdownComponent_Template_mat_select_closed_1_listener() { return ctx.selectTimezone(); });
        i0.ɵɵpipe(3, "translate");
        i0.ɵɵelementStart(4, "div", 3);
        i0.ɵɵelement(5, "span", 4);
        i0.ɵɵelementStart(6, "span", 5);
        i0.ɵɵelement(7, "span", 6);
        i0.ɵɵelementStart(8, "mat-slide-toggle", 7);
        i0.ɵɵlistener("toggleChange", function TimezoneDropdownComponent_Template_mat_slide_toggle_toggleChange_8_listener() { return ctx.toggleAmPm(ctx.isAmPm); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 8);
        i0.ɵɵelementStart(10, "input", 9, 10);
        i0.ɵɵlistener("keyup", function TimezoneDropdownComponent_Template_input_keyup_10_listener() { i0.ɵɵrestoreView(_r4); const _r1 = i0.ɵɵreference(11); return ctx.onKeyUp(_r1.value); });
        i0.ɵɵpipe(12, "translate");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "mat-select-trigger");
        i0.ɵɵtext(14);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(15, TimezoneDropdownComponent_mat_option_15_Template, 5, 3, "mat-option", 11);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        const _r0 = i0.ɵɵreference(2);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("value", ctx.selectedTimezone)("placeholder", i0.ɵɵpipeBind1(3, 6, "sessionSchedule.header.timezone"));
        i0.ɵɵadvance(7);
        i0.ɵɵproperty("checked", ctx.isAmPm);
        i0.ɵɵadvance(2);
        i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(12, 8, "sessionSchedule.paragraph.search"));
        i0.ɵɵadvance(4);
        i0.ɵɵtextInterpolate1(" ", _r0.value, " ");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.displayedTimezones);
    } }, directives: [i1.MatFormField, i2.MatSelect, i3.JhiTranslateDirective, i4.MatSlideToggle, i2.MatSelectTrigger, i5.NgForOf, i6.MatOption], pipes: [i7.TranslatePipe], styles: [".timezone-div[_ngcontent-%COMP%]{height:40px;width:100%;border:1px solid var(--border-color);border-radius:.3rem;background:#fff}.dropdown-toggle[_ngcontent-%COMP%]::after{display:none}.dropdown-menu-timezone[_ngcontent-%COMP%]{height:330px;width:100%;overflow-y:scroll;top:35px !important;transform:translate(-1px, 0px) !important}a.timezone-dropdown-item[_ngcontent-%COMP%]{background-color:#fff !important;color:#000 !important;padding:15px 8px;font-size:13px}a.timezone-dropdown-item[_ngcontent-%COMP%]:hover{background-color:#44a38d !important;color:#fff !important}.time-format[_ngcontent-%COMP%]{padding:15px 8px;font-size:13px}.timezone-search[_ngcontent-%COMP%]{height:40px;width:100%;border:1px solid var(--border-color);border-radius:.3rem;margin:8px 3px 12px 8px;line-height:normal !important}.border-top-parent[_ngcontent-%COMP%]{position:relative}.border-top[_ngcontent-%COMP%]{border-top:1px solid var(--border-color);position:absolute;top:0;left:2%;right:1px}[_nghost-%COMP%]  .mat-form-field-infix{width:260px}"] });
