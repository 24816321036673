import { HttpClient } from '@angular/common/http';
import { CELLULANT_CHECKOUT_URL, SERVER_API_URL } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PaymentLog {
}
export class PaymentAndSessionData {
}
export class PaymentService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/pay';
    }
    getAvailablePaymentMethods(params) {
        return this.http.post(`${this.resourceUrl}/adyen/payment-methods`, params, { observe: 'response' });
    }
    makePayment(data) {
        return this.http.post(`${this.resourceUrl}/adyen`, data, { observe: 'response' });
    }
    getPaymentLog(paymentId) {
        return this.http.get(`${this.resourceUrl}/current-user-payment-log?id=${paymentId}`, { observe: 'response' });
    }
    onPayment(pricing, route, sessionCreateDTO, voucherCode) {
        console.log("selected pricing: " + pricing + ", voucherCode: " + voucherCode);
        let origin = window.location.origin;
        if (pricing.paymentProvider === "Cellulant") {
            console.log("selected method: Cellulant");
            this.makePaymentCellulant(pricing, sessionCreateDTO, voucherCode);
            // } else if (pricing.paymentProvider === "Adyen") {
            //   console.log("selected method: Adyen");
            //   this.router.navigate(["pay-adyen", pricing.id]);
        }
        else if (pricing.paymentProvider === "Mollie") {
            console.log("selected method: Mollie");
            this.mollieCreatePayment(pricing.id, origin, route, sessionCreateDTO, voucherCode).subscribe(res => {
                let s = JSON.stringify(res.body["_links"]["checkout"]["href"]);
                s = s.replace(/"/g, "");
                console.log("link:" + s);
                document.location.replace(s);
            }, err => console.error("Error creating payment link: " + err));
        }
    }
    makePaymentCellulant(pricing, sessionCreateDTO, voucherCode) {
        let today = new Date();
        today.setHours(today.getHours() + 1);
        let commonProperties = {
        // customerFirstName: "Name",
        // customerLastName: "Surname",
        // customerEmail: "inukacares@inuka.io",
        // MSISDN: "",
        // accountNumber: "acc_001",
        // dueDate: todayStr, // should be in the future
        // countryCode: "US",
        // languageCode: "EN",
        // accessKey: CELLULANT_ACCESS_KEY,
        // successRedirectUrl: CELLULANT_SUCCESS_CALLBACK,
        // failRedirectUrl: CELLULANT_FAILURE_CALLBACK,
        // paymentWebhookUrl: CELLULANT_WEBHOOK_URL
        };
        if (sessionCreateDTO.sessionStartTime !== null) {
            // commonProperties.successRedirectUrl += '/' + sessionCreateDTO.getCoachId() + '/' + sessionCreateDTO.getUserId() + '/' + sessionCreateDTO.sessionStartTime.toISOString();
        }
        let selectedService = {
            // serviceCode: CELLULANT_SERVICE_CODE,
            // serviceDescription: pricing.productName,
            // amount: pricing.price,
            // currencyCode: pricing.currencyCode,
            // merchantTransactionID: sessionStorage.getItem("sessionUserId") + "_" + selectedProductId + "_" + Math.random().toString(36).substr(2, 9),
            productId: pricing.id
        };
        let merchantProperties = Object.assign({ params: '' }, commonProperties, selectedService, sessionCreateDTO, { voucherCode });
        this.initializePayment('Cellulant', merchantProperties).subscribe(res => {
            console.log("Cellulant response: " + res.body);
            checkout(res.body);
        });
    }
    mollieCreatePayment(productId, origin, route, sessionCreateDTO, voucherCode) {
        let coachId = sessionCreateDTO.coachId;
        return this.initializePayment('Mollie', { productId, origin, route, coachId, voucherCode });
    }
    initializePayment(paymentProvider, params) {
        return this.http.post(`${this.resourceUrl}/init?provider=${paymentProvider}`, params, { observe: 'response' });
    }
    addCellulantSupport() {
        let script = document.createElement("script");
        script.src = CELLULANT_CHECKOUT_URL;
        // console.log(script.src);
        script.id = "mula-checkout-library";
        script.onload = () => {
            console.log("Running load button");
        };
        document.body.appendChild(script); // added Mula script
    }
}
PaymentService.ɵfac = function PaymentService_Factory(t) { return new (t || PaymentService)(i0.ɵɵinject(i1.HttpClient)); };
PaymentService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PaymentService, factory: PaymentService.ɵfac, providedIn: 'root' });
