import moment from "moment/moment";
import { AccountService } from '../auth/account.service';
import { TranslateService } from "@ngx-translate/core";
import { LANGUAGES } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "../auth/account.service";
import * as i2 from "@ngx-translate/core";
export class CalendarEventLinkService {
    constructor(accountService, translateService) {
        this.accountService = accountService;
        this.translateService = translateService;
        this.languagesKeyToName = LANGUAGES;
    }
    generateCalendarEventLinks(session) {
        let duration = session.duration;
        const sessionType = this.translateService.instant(`sessionSchedule.paragraph.${session.type}`);
        let title = this.translateService.instant('sessionSchedule.paragraph.calendarEventTitle', { type: sessionType });
        if (this.accountService.isCoach()) {
            title = `Inuka ${session.type} session with ${session.user.login}`;
        }
        title = encodeURIComponent(title);
        let description = this.formatDescription(session);
        description = encodeURIComponent(description);
        let location = `https://app.inuka.io/#/login?email=${this.accountService.getCurrentLoggedInUser().email}`;
        location = encodeURIComponent(location);
        const google = this.generateForGoogle(session, duration, title, description, location);
        const outlook = this.generateForOutlook(session, duration, title, description, location);
        const yahoo = this.generateForYahoo(title, description, session, duration, location);
        const calendarEventLinks = new CalendarEventLinks(google, outlook, yahoo);
        return calendarEventLinks;
    }
    generateForYahoo(title, description, session, duration, location) {
        return `https://calendar.yahoo.com/?v=60&title=${title}&desc=${description}&in_loc=Inuka&st=`
            + moment(session.sessionStartTime).format("YYYYMMDDTHHmmZ") + "&et="
            + moment(session.sessionStartTime).add(duration, "minutes").format("YYYYMMDDTHHmmZ");
    }
    generateForOutlook(session, duration, title, description, location) {
        // outlook doesnot support new line \n in the description, we should replace the url encoded char of new line with comma
        description = description.replace(/%0A/g, ', ');
        return "https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent&startdt="
            + moment(session.sessionStartTime).add(-1, 'minutes').format("YYYY-MM-DDTHH:mm") + "&enddt="
            + moment(session.sessionStartTime).add(duration, "minutes").format("YYYY-MM-DDTHH:mm")
            + `&subject=${title}&body=${description}&location=${location}&path=%2Fcalendar%2Faction`;
    }
    generateForGoogle(session, duration, title, description, location) {
        return "https://calendar.google.com/calendar/render?action=TEMPLATE&dates="
            + moment(session.sessionStartTime).add(1, 'minutes').format("YYYYMMDDTHHmmZ") + "%2F"
            + moment(session.sessionStartTime).add(duration, "minutes").format('YYYYMMDDTHHmmZ')
            + `&text=${title}&details=${description}&location=${location}&allday=false`;
    }
    formatDescription(session) {
        const descriptionParams = {
            language: this.findLanguageNameByLangKey(session.langKey),
            duration: session.duration,
            type: this.translateService.instant(`sessionSchedule.paragraph.${session.type}`)
        };
        if (this.accountService.isCoach()) {
            descriptionParams['user'] = session.user.login;
            return this.translateService.instant('sessionSchedule.paragraph.calendarEventDescriptionCoach', descriptionParams);
        }
        else {
            return this.translateService.instant('sessionSchedule.paragraph.calendarEventDescription', descriptionParams);
        }
    }
    findLanguageNameByLangKey(lang) {
        var _a;
        return (_a = this.languagesKeyToName.find(l2 => l2.code === lang)) === null || _a === void 0 ? void 0 : _a.name;
    }
}
CalendarEventLinkService.ɵfac = function CalendarEventLinkService_Factory(t) { return new (t || CalendarEventLinkService)(i0.ɵɵinject(i1.AccountService), i0.ɵɵinject(i2.TranslateService)); };
CalendarEventLinkService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CalendarEventLinkService, factory: CalendarEventLinkService.ɵfac, providedIn: 'root' });
export class CalendarEventLinks {
    constructor(google, outlook, yahoo) {
        this.google = google;
        this.outlook = outlook;
        this.yahoo = yahoo;
    }
}
