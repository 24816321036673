import { SimpleChanges } from "@angular/core";
import { ActionPlanService } from '../../action-plan/action-plan.service';
import { ActionPlanItemService } from '../../action-plan-item/action-plan-item.service';
import * as i0 from "@angular/core";
import * as i1 from "../../action-plan/action-plan.service";
import * as i2 from "../../action-plan-item/action-plan-item.service";
import * as i3 from "@angular/common";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "../../action-plan/action-plan/action-plan.component";
function ActionPlanListComponent_div_1__svg_use_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "use", 11);
} }
function ActionPlanListComponent_div_1__svg_use_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "use", 12);
} }
function ActionPlanListComponent_div_1_div_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelement(1, "action-plan", 14);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("actionPlan", ctx_r5.actionPlan)("isActionPlanList", true)("isCollapsed", false);
} }
function ActionPlanListComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵelement(2, "h4", 2);
    i0.ɵɵelementStart(3, "span", 3);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "date");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 4);
    i0.ɵɵlistener("click", function ActionPlanListComponent_div_1_Template_div_click_6_listener() { const restoredCtx = i0.ɵɵrestoreView(_r7); const i_r2 = restoredCtx.index; const ctx_r6 = i0.ɵɵnextContext(); return ctx_r6.selectActionPlan(i_r2); });
    i0.ɵɵelement(7, "span", 5);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(8, "svg", 6);
    i0.ɵɵtemplate(9, ActionPlanListComponent_div_1__svg_use_9_Template, 1, 0, "use", 7);
    i0.ɵɵtemplate(10, ActionPlanListComponent_div_1__svg_use_10_Template, 1, 0, "use", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(11, ActionPlanListComponent_div_1_div_11_Template, 2, 3, "div", 9);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelement(12, "hr", 10);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const a_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("id", a_r1.id);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(5, 5, a_r1.creationDate, "dd MMM yyyy"));
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedIndex !== i_r2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedIndex === i_r2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedIndex === i_r2 && (ctx_r0.actionPlan == null ? null : ctx_r0.actionPlan.actionPlanItems) != null);
} }
export class ActionPlanListComponent {
    constructor(actionPlanService, actionPlanItemService) {
        this.actionPlanService = actionPlanService;
        this.actionPlanItemService = actionPlanItemService;
        this.selectedIndex = -1;
    }
    ngOnChanges(changes) {
        if (this.userId != null)
            this.getActionPlanList();
    }
    getActionPlanList() {
        this.actionPlan = null;
        this.selectedIndex = -1;
        this.actionPlanServiceSubscription = this.actionPlanService.findActionPlansByUserIdExcludeInvalidStatus(this.userId)
            .subscribe((res) => {
            this.actionPlanList = res.body;
            console.log(this.actionPlanList);
        });
    }
    selectActionPlan(index) {
        if (this.selectedIndex === index) {
            this.selectedIndex = -1;
        }
        else {
            this.selectedIndex = index;
            this.actionPlan = this.actionPlanList[index];
            this.actionPlanItemServiceSubscription = this.actionPlanItemService.findItemsByActionPlanId(this.actionPlan.id, true)
                .subscribe((res) => {
                this.actionPlan.actionPlanItems = res.body || [];
                console.log(this.actionPlan);
            });
        }
    }
    ngOnDestroy() {
        if (this.actionPlanServiceSubscription)
            this.actionPlanServiceSubscription.unsubscribe();
        if (this.actionPlanItemServiceSubscription)
            this.actionPlanItemServiceSubscription.unsubscribe();
    }
}
ActionPlanListComponent.ɵfac = function ActionPlanListComponent_Factory(t) { return new (t || ActionPlanListComponent)(i0.ɵɵdirectiveInject(i1.ActionPlanService), i0.ɵɵdirectiveInject(i2.ActionPlanItemService)); };
ActionPlanListComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ActionPlanListComponent, selectors: [["action-plan-list"]], inputs: { userId: "userId" }, features: [i0.ɵɵNgOnChangesFeature], decls: 2, vars: 1, consts: [[4, "ngFor", "ngForOf"], [1, "d-flex", "justify-content-between", "align-items-center", "action-plan-item"], ["jhiTranslate", "inukaNgApp.actionPlanItem.actionPlan", 1, "action-plan-header", 3, "id"], [1, "action-plan-date"], [1, "action-plan-details-div", 3, "click"], ["jhiTranslate", "inukaNgApp.indexUser.details", 1, "action-plan-details"], [1, "angle-icon"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#angle-down-17", 4, "ngIf"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#angle-up-17", 4, "ngIf"], ["style", "padding: 15px 30px;", 4, "ngIf"], [2, "margin", "0"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#angle-down-17"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#angle-up-17"], [2, "padding", "15px 30px"], [3, "actionPlan", "isActionPlanList", "isCollapsed"]], template: function ActionPlanListComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div");
        i0.ɵɵtemplate(1, ActionPlanListComponent_div_1_Template, 13, 8, "div", 0);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.actionPlanList);
    } }, directives: [i3.NgForOf, i4.JhiTranslateDirective, i3.NgIf, i5.ActionPlanComponent], pipes: [i3.DatePipe], styles: [".action-plan-item[_ngcontent-%COMP%]{padding:15px 30px}.angle-icon[_ngcontent-%COMP%]{height:8px;width:17px}.action-plan-header[_ngcontent-%COMP%]{margin-bottom:0}.action-plan-date[_ngcontent-%COMP%]{font-family:\"Open Sans\",serif;color:#8a9d98;font-size:14px}.action-plan-details-div[_ngcontent-%COMP%]{color:#44a38b;font-size:14px;font-weight:700;text-decoration:underline;cursor:pointer}.action-plan-details[_ngcontent-%COMP%]{margin-right:5px}"] });
