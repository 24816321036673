import { ElementRef } from '@angular/core';
import { TrackerService } from '../../../core/tracker/tracker.service';
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from '../../../core/auth/account.service';
import { SRQCompletedService } from '../../srq-completed/srq-completed.service';
import { FlowManagerService } from '../../../core/util/flow-manager.service';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from '../../../core/user/user.service';
import { OrganizationCodeExtraQuestionService } from '../../organization-code-extra-question/organization-code-extra-question.service';
import { BaseComponent } from '../../../shared/base.component';
import { Location } from "@angular/common";
import { WellbeingScanService } from '../../wellbeing-scan/wellbeing-scan.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../core/tracker/tracker.service";
import * as i2 from "@angular/router";
import * as i3 from "../../../core/auth/account.service";
import * as i4 from "../../srq-completed/srq-completed.service";
import * as i5 from "../../../core/util/flow-manager.service";
import * as i6 from "ngx-webstorage";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../core/user/user.service";
import * as i9 from "../../organization-code-extra-question/organization-code-extra-question.service";
import * as i10 from "@angular/common";
import * as i11 from "../../wellbeing-scan/wellbeing-scan.service";
import * as i12 from "../../../shared/wellbeing-header/wellbeing-header.component";
import * as i13 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i14 from "../wellbeing-scan-extra-question/wellbeing-scan-extra-question.component";
function WellbeingScanExtraQuestionsComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "div", 3);
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵelement(4, "h4", 5);
    i0.ɵɵelementStart(5, "div", 6);
    i0.ɵɵelementStart(6, "wellbeing-scan-extra-question", 7);
    i0.ɵɵlistener("onAnswered", function WellbeingScanExtraQuestionsComponent_div_1_Template_wellbeing_scan_extra_question_onAnswered_6_listener($event) { i0.ɵɵrestoreView(_r2); const ctx_r1 = i0.ɵɵnextContext(); return ctx_r1.onAnswered($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 8);
    i0.ɵɵelementStart(8, "div", 9);
    i0.ɵɵelementStart(9, "button", 10);
    i0.ɵɵlistener("click", function WellbeingScanExtraQuestionsComponent_div_1_Template_button_click_9_listener() { i0.ɵɵrestoreView(_r2); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.location.back(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "button", 11);
    i0.ɵɵlistener("click", function WellbeingScanExtraQuestionsComponent_div_1_Template_button_click_10_listener() { i0.ɵɵrestoreView(_r2); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.next(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "div", 12);
    i0.ɵɵelementStart(12, "div", 9);
    i0.ɵɵelementStart(13, "button", 13);
    i0.ɵɵlistener("click", function WellbeingScanExtraQuestionsComponent_div_1_Template_button_click_13_listener() { i0.ɵɵrestoreView(_r2); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.next(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "div", 9);
    i0.ɵɵelementStart(15, "button", 14);
    i0.ɵɵlistener("click", function WellbeingScanExtraQuestionsComponent_div_1_Template_button_click_15_listener() { i0.ɵɵrestoreView(_r2); const ctx_r6 = i0.ɵɵnextContext(); return ctx_r6.location.back(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "div", 15);
    i0.ɵɵelement(17, "span", 16);
    i0.ɵɵelement(18, "span", 17);
    i0.ɵɵelement(19, "span", 18);
    i0.ɵɵelement(20, "span", 19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("question", ctx_r0.currentQuestion.extraQuestion);
} }
export class WellbeingScanExtraQuestionsComponent extends BaseComponent {
    constructor(elementRef, trackerService, router, accountService, srqService, flowManagerService, activatedRoute, sessionStorage, modalService, userService, organizationCodeExtraQuestionService, location, wellbeingScanService) {
        super(sessionStorage, modalService);
        this.elementRef = elementRef;
        this.trackerService = trackerService;
        this.router = router;
        this.accountService = accountService;
        this.srqService = srqService;
        this.flowManagerService = flowManagerService;
        this.activatedRoute = activatedRoute;
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.userService = userService;
        this.organizationCodeExtraQuestionService = organizationCodeExtraQuestionService;
        this.location = location;
        this.wellbeingScanService = wellbeingScanService;
        this.currentQuestionIndex = 0;
        this.answers = [];
        this.account = null;
        this.extraQuestionAnswers = {};
    }
    ngOnInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
        this.activatedRoute.params.subscribe((params) => {
            let question = params["question"];
            if (question) {
                this.currentQuestionIndex = Number(question);
            }
            console.log("flow currentQuestion: " + this.currentQuestionIndex);
            this.loadOrganizationCodeExtraQuestions();
        });
        // if (this.routeService.getPreviousUrl().startsWith('/wellbeing-start')) {
        //   this.trackerService.sendActivity("self_scan_started");
        // }
    }
    loadOrganizationCodeExtraQuestions() {
        var _a;
        if ((_a = this.organizationCodeExtraQuestions) === null || _a === void 0 ? void 0 : _a.length) {
            this.selectCurrentQuestion();
        }
        if (this.accountService.isAuthenticated()) {
            this.organizationCodeExtraQuestionService.getByUser().subscribe(organizationCodeExtraQuestions => {
                this.organizationCodeExtraQuestions = organizationCodeExtraQuestions.body;
                this.selectCurrentQuestion();
            });
        }
        else {
            const organizationCode = this.sessionStorage.retrieve("organizationCode");
            this.organizationCodeExtraQuestionService.getByOrganizationCode(organizationCode).subscribe(organizationCodeExtraQuestions => {
                this.organizationCodeExtraQuestions = organizationCodeExtraQuestions.body;
                this.selectCurrentQuestion();
            });
        }
    }
    selectCurrentQuestion() {
        var _a;
        // there is no extra question, redirect to wellbeing-finish
        if (!((_a = this.organizationCodeExtraQuestions) === null || _a === void 0 ? void 0 : _a.length)) {
            this.router.navigate(["/wellbeing-finish", "noExtraQuestion"]).then();
        }
        if (!this.currentQuestionIndex) {
            this.currentQuestionIndex = 1;
        }
        this.currentQuestion = this.organizationCodeExtraQuestions[this.currentQuestionIndex - 1];
    }
    next() {
        if (this.currentQuestionIndex < this.organizationCodeExtraQuestions.length) {
            this.currentQuestionIndex++;
            this.router.navigate(["/wellbeing-extra-questions", this.currentQuestionIndex]);
        }
        else {
            this.saveAnswers();
            this.router.navigate(["/wellbeing-finish", ""]).then();
        }
    }
    saveAnswers() {
        let answers = [];
        for (const questionId of Object.keys(this.extraQuestionAnswers)) {
            answers = answers.concat(this.extraQuestionAnswers[questionId]);
        }
        this.wellbeingScanService.saveExtraQuestionAnswers(answers);
    }
    onAnswered(answers) {
        this.extraQuestionAnswers[this.currentQuestion.id] = answers;
        console.log(this.extraQuestionAnswers);
    }
}
WellbeingScanExtraQuestionsComponent.ɵfac = function WellbeingScanExtraQuestionsComponent_Factory(t) { return new (t || WellbeingScanExtraQuestionsComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.TrackerService), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.AccountService), i0.ɵɵdirectiveInject(i4.SRQCompletedService), i0.ɵɵdirectiveInject(i5.FlowManagerService), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i6.SessionStorageService), i0.ɵɵdirectiveInject(i7.NgbModal), i0.ɵɵdirectiveInject(i8.UserService), i0.ɵɵdirectiveInject(i9.OrganizationCodeExtraQuestionService), i0.ɵɵdirectiveInject(i10.Location), i0.ɵɵdirectiveInject(i11.WellbeingScanService)); };
WellbeingScanExtraQuestionsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WellbeingScanExtraQuestionsComponent, selectors: [["wellbeing-scan-extra-questions"]], features: [i0.ɵɵInheritDefinitionFeature], decls: 2, vars: 1, consts: [["class", "olha-theme", 4, "ngIf"], [1, "olha-theme"], [1, "container", "bg-2", "px-lg-5"], [1, "d-flex", "flex-column", "justify-content-between", "h-100"], [1, "last-seven-div"], ["jhiTranslate", "inukaNgApp.extraQuestion.questionPageHeader", 2, "font-weight", "600", "display", "none"], [2, "min-height", "22rem"], [3, "question", "onAnswered"], [1, "d-none", "d-md-block", "d-lg-block", "d-xl-block", "mt-3"], [1, "d-flex", "justify-content-center"], ["jhiTranslate", "inukaNgApp.wellbeingScan.back", "id", "ss-back-btn", 1, "btn", "btn-secondary", "m-2", "btn-large", 3, "click"], ["jhiTranslate", "inukaNgApp.wellbeingScan.next", "id", "ss-next-extra-questions", 1, "btn", "btn-primary", "m-2", "btn-large", 3, "click"], [1, "d-md-none", "mt-3"], ["jhiTranslate", "inukaNgApp.wellbeingScan.next", 1, "btn", "btn-primary", "m-2", "btn-large", 3, "click"], ["jhiTranslate", "inukaNgApp.wellbeingScan.back", 1, "btn", "btn-secondary", "m-2", "btn-large", 3, "click"], [1, "mt-5", "px-2", "px-lg-5", 2, "font-size", "85%"], ["jhiTranslate", "inukaNgApp.extraQuestion.questionPageFooter1", 1, "font-weight-bold"], ["jhiTranslate", "inukaNgApp.extraQuestion.questionPageFooter2"], ["jhiTranslate", "inukaNgApp.extraQuestion.questionPageFooter3", 1, "font-weight-bold"], ["jhiTranslate", "inukaNgApp.extraQuestion.questionPageFooter4"]], template: function WellbeingScanExtraQuestionsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "wellbeing-header");
        i0.ɵɵtemplate(1, WellbeingScanExtraQuestionsComponent_div_1_Template, 21, 1, "div", 0);
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.currentQuestion);
    } }, directives: [i12.WellbeingHeaderComponent, i10.NgIf, i13.JhiTranslateDirective, i14.WellbeingScanExtraQuestionComponent], styles: [".olha-theme[_ngcontent-%COMP%]{position:relative}.container[_ngcontent-%COMP%]{margin:90px 20px 10px !important;height:auto;width:auto;max-width:1169px !important;padding-top:30px;text-align:center}.absolute-container[_ngcontent-%COMP%]{position:absolute;bottom:-50px;right:-30px}@media(min-width: 768px){.question-text[_ngcontent-%COMP%]{font-size:26px}.test-image[_ngcontent-%COMP%]{height:170px;width:170px}.mt-55[_ngcontent-%COMP%]{margin-top:55px}}@media(min-width: 1200px){.container[_ngcontent-%COMP%]{margin:90px auto 30px !important;padding-top:60px;height:720px}}@media(min-width: 1150px){.why-17-div[_ngcontent-%COMP%]{width:350px}}@media(min-width: 1250px){.absolute-container[_ngcontent-%COMP%]{right:-50px}.why-17-div[_ngcontent-%COMP%]{width:400px}}@media(min-width: 1350px){.absolute-container[_ngcontent-%COMP%]{right:-100px}}"] });
