import { HttpClient } from "@angular/common/http";
import { SERVER_API_URL } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CoachDetailsService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/coach-details';
    }
    findCoachDetails(coachId) {
        return this.http.get(`${this.resourceUrl}/` + coachId, { observe: 'response' });
    }
    updateCoachDetails(coachDetails) {
        return this.http.post(`${this.resourceUrl}`, coachDetails, { observe: 'response' });
    }
    uploadFile(file) {
        return this.http.put(`${this.resourceUrl}/upload`, file, { observe: 'response' });
    }
    queryAllCoachDetails() {
        return this.http.get(`${this.resourceUrl}/all-coach-details-for-excel`, { observe: 'response' });
    }
}
CoachDetailsService.ɵfac = function CoachDetailsService_Factory(t) { return new (t || CoachDetailsService)(i0.ɵɵinject(i1.HttpClient)); };
CoachDetailsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CoachDetailsService, factory: CoachDetailsService.ɵfac, providedIn: 'root' });
