import { JhiLanguageService } from '../../ng-jhipster/language';
import { JhiEventManager } from '../../ng-jhipster/service';
import { CoachDetailsService } from '../../entities/slot/coach-details.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileDTO } from '../model/fileDTO.model';
import { CoachDetails } from '../model/coach-details.model';
import { AlertModalComponent } from '../alert/alert-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "../../entities/slot/coach-details.service";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../ng-jhipster/language";
import * as i4 from "../../ng-jhipster/service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@ngx-translate/core";
function ProfileImageComponent_div_1_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵstyleProp("font-size", ctx_r1.fontSize + "px")("width", ctx_r1.size + "px")("height", ctx_r1.size + "px");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.coachLogin[0]);
} }
function ProfileImageComponent_div_1_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 8);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵstyleProp("background-image", "url(" + (ctx_r2.coachDetails == null ? null : ctx_r2.coachDetails.imageUrl) + ")")("width", ctx_r2.size + "px")("height", ctx_r2.size + "px");
} }
function ProfileImageComponent_div_1_div_3_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵlistener("click", function ProfileImageComponent_div_1_div_3_Template_div_click_0_listener() { i0.ɵɵrestoreView(_r6); const _r4 = i0.ɵɵreference(2); return _r4.click(); });
    i0.ɵɵelementStart(1, "input", 10, 11);
    i0.ɵɵlistener("change", function ProfileImageComponent_div_1_div_3_Template_input_change_1_listener($event) { i0.ɵɵrestoreView(_r6); const ctx_r7 = i0.ɵɵnextContext(2); return ctx_r7.uploadProfilePicture($event, 200); });
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(3, "svg", 12);
    i0.ɵɵelement(4, "use", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function ProfileImageComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, ProfileImageComponent_div_1_div_1_Template, 3, 7, "div", 3);
    i0.ɵɵtemplate(2, ProfileImageComponent_div_1_div_2_Template, 1, 6, "div", 4);
    i0.ɵɵtemplate(3, ProfileImageComponent_div_1_div_3_Template, 5, 0, "div", 5);
    i0.ɵɵelement(4, "img", 6);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.hasImage);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.hasImage);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.doUpload);
    i0.ɵɵadvance(1);
    i0.ɵɵclassMap(ctx_r0.getClass());
    i0.ɵɵproperty("matTooltip", i0.ɵɵpipeBind1(5, 8, "sessionChat.paragraph.videoCallTooltip"))("matTooltipPosition", "below")("matTooltipClass", "expand-tool-tip");
} }
export class ProfileImageComponent {
    constructor(coachDetailsService, modalService, languageService, eventManager) {
        this.coachDetailsService = coachDetailsService;
        this.modalService = modalService;
        this.languageService = languageService;
        this.eventManager = eventManager;
        this.selectedFile = null;
        this.modalOptions = { windowClass: 'modal-window', size: 'md', centered: true };
    }
    set coachId(value) {
        this._coachId = value;
    }
    get coachId() {
        return this._coachId;
    }
    set coachLogin(value) {
        this._coachLogin = value;
    }
    get coachLogin() {
        return this._coachLogin;
    }
    set coachDetails(value) {
        this._coachDetails = value;
        this.loadCoachDetails();
    }
    get coachDetails() {
        return this._coachDetails;
    }
    ngOnInit() {
        this.fontSize = 0.75 * this.size;
        if (this.coachId != null) {
            this.loadCoachDetails();
        }
        this.eventSubscriber = this.eventManager.subscribe('coachProfileImageUpdated', () => {
            this.loadCoachDetails();
        });
    }
    loadCoachDetails() {
        this.coachDetailsService.findCoachDetails(this.coachId).subscribe(response => {
            var _a;
            this._coachDetails = response.body;
            this.videoEnabled = this.coachDetails.videoChatEnabled;
            this.hasImage = ((_a = this.coachDetails) === null || _a === void 0 ? void 0 : _a.imageUrl) != null;
        });
    }
    uploadProfilePicture(event, maxWidth) {
        this.selectedFile = event.target.files[0];
        console.log(event);
        if (this.selectedFile != null && this.selectedFile.type.includes('image')) {
            const myReader = new FileReader();
            myReader.readAsDataURL(this.selectedFile);
            myReader.onload = () => {
                const img = new Image();
                img.src = myReader.result.toString();
                img.onload = () => {
                    let imageWidth = img.width;
                    let imageHeight = img.height;
                    let resizeFactor;
                    let data = img.src.split(';')[1].split(',')[1];
                    let type = this.selectedFile.type;
                    if (imageWidth > maxWidth) {
                        resizeFactor = maxWidth / imageWidth;
                        imageWidth = resizeFactor * imageWidth;
                        imageHeight = resizeFactor * imageHeight;
                        const elem = document.createElement('canvas');
                        elem.width = imageWidth;
                        elem.height = imageHeight;
                        const ctx = elem.getContext('2d');
                        ctx.drawImage(img, 0, 0, elem.width, elem.height);
                        let canvasURL = ctx.canvas.toDataURL();
                        data = canvasURL.split(':')[1].split(';')[1].split(',')[1];
                        type = canvasURL.split(':')[1].split(';')[0];
                    }
                    console.log(imageWidth);
                    console.log(imageHeight);
                    this.fileDTO = new FileDTO(this.coachId, '', type, data);
                    console.log(this.fileDTO);
                    this.coachDetailsService.uploadFile(this.fileDTO).subscribe((response) => {
                        console.log(response);
                        this.coachDetails = response.body;
                        this.eventManager.broadcast('coachProfileImageUpdated');
                    }, (errorResponse) => {
                        this.openAlertModal('settings.messages.error.' + errorResponse.error.errorKey);
                    });
                };
            };
        }
        else {
            this.openAlertModal('settings.messages.error.mustBeImage');
        }
    }
    openAlertModal(message) {
        const modalRef = this.modalService.open(AlertModalComponent, this.modalOptions);
        modalRef.componentInstance.alertModalRef = modalRef;
        modalRef.componentInstance.language = this.languageService.getCurrentLanguage();
        modalRef.componentInstance.userAlertMessage = message;
    }
    getClass() {
        let result;
        if (this.size === 90)
            result = 'size-90';
        else
            result = 'size-default';
        return result;
    }
    ngOnDestroy() {
        if (this.eventSubscriber)
            this.eventManager.destroy(this.eventSubscriber);
    }
}
ProfileImageComponent.ɵfac = function ProfileImageComponent_Factory(t) { return new (t || ProfileImageComponent)(i0.ɵɵdirectiveInject(i1.CoachDetailsService), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i3.JhiLanguageService), i0.ɵɵdirectiveInject(i4.JhiEventManager)); };
ProfileImageComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ProfileImageComponent, selectors: [["profile-image"]], inputs: { coachId: "coachId", coachLogin: "coachLogin", doUpload: "doUpload", videoEnabled: "videoEnabled", size: "size", smallIconSize: "smallIconSize", coachDetails: "coachDetails" }, decls: 2, vars: 1, consts: [[1, "olha-theme"], ["class", "d-flex justify-content-center align-items-center text-center position-relative", 4, "ngIf"], [1, "d-flex", "justify-content-center", "align-items-center", "text-center", "position-relative"], ["class", "first-letter d-flex justify-content-center align-items-center", 3, "font-size", "width", "height", 4, "ngIf"], ["class", "profile-icon", 3, "background-image", "width", "height", 4, "ngIf"], ["class", "d-flex justify-content-center align-items-center edit-icon-div", 3, "click", 4, "ngIf"], ["src", "../../../../content/images/video-icon.png", "alt", "video-icon", 2, "position", "absolute", "height", "20px", "width", "20px", 3, "matTooltip", "matTooltipPosition", "matTooltipClass"], [1, "first-letter", "d-flex", "justify-content-center", "align-items-center"], [1, "profile-icon"], [1, "d-flex", "justify-content-center", "align-items-center", "edit-icon-div", 3, "click"], ["type", "file", 1, "d-none", 3, "change"], ["imageInput", ""], [1, "edit-icon"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#edit-icon-white"]], template: function ProfileImageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, ProfileImageComponent_div_1_Template, 6, 10, "div", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.coachLogin != null && ctx.coachDetails != null);
    } }, directives: [i5.NgIf, i6.MatTooltip], pipes: [i7.TranslatePipe], styles: [".profile-icon[_ngcontent-%COMP%]{display:inline-block;border-radius:50%;background-repeat:no-repeat;background-position:50% 50%;background-size:cover}.first-letter[_ngcontent-%COMP%]{border-radius:50%;background-color:#dff1ec;font-weight:900 !important;color:#44a38b}.edit-icon-div[_ngcontent-%COMP%]{height:30px;width:30px;position:absolute;bottom:3px;right:5%;border:3px solid #fff;border-radius:50%;background-color:#44a38b;cursor:pointer}.edit-icon[_ngcontent-%COMP%]{height:14px;width:14px}.video-white-icon-div[_ngcontent-%COMP%]{height:30px;width:30px;position:absolute;bottom:0;right:0;border:3px solid #fff;border-radius:50%;background-color:#44a38b}.video-white-icon-div-small[_ngcontent-%COMP%]{height:25px;width:25px;position:absolute;bottom:-5px;right:-5px;border:3px solid #fff;border-radius:50%;background-color:#44a38b}.video-white-icon[_ngcontent-%COMP%]{height:14px;width:14px;margin-top:2px;margin-left:2px}.size-default[_ngcontent-%COMP%]{top:48px;right:3px}.size-90[_ngcontent-%COMP%]{top:70px;right:10px} .expand-tool-tip{color:#fff !important;background:#44a38b !important;border:1px solid #44a38b !important;overflow:visible !important}  .expand-tool-tip:before{content:\"\";position:absolute;bottom:100%;left:47%;border-bottom:5px solid #44a38b;border-left:5px solid transparent;border-right:5px solid transparent;z-index:10000}"] });
