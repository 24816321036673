import * as i0 from "@angular/core";
export class PaymentSuccessComponent {
    constructor() { }
    ngOnInit() {
    }
}
PaymentSuccessComponent.ɵfac = function PaymentSuccessComponent_Factory(t) { return new (t || PaymentSuccessComponent)(); };
PaymentSuccessComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: PaymentSuccessComponent, selectors: [["payment-success"]], decls: 16, vars: 0, consts: [[1, "page"], [1, "page-single"], [1, "container"], [1, "row"], [1, "col", "mx-auto"], [1, "text-center", "mb-6"], ["src", "../../../../../content/images/inuka/logo-without-text.png", "alt", "", 1, ""], [1, "row", "justify-content-center"], [1, "col-md-8"], [1, "card"], [1, "card-body", "text-center"], [1, "text-primary"], [1, "text-center"], ["onclick", "location.href = '#/session/schedule-session'", 1, "btn", "btn-primary"]], template: function PaymentSuccessComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelement(6, "img", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 7);
        i0.ɵɵelementStart(8, "div", 8);
        i0.ɵɵelementStart(9, "div", 9);
        i0.ɵɵelementStart(10, "div", 10);
        i0.ɵɵelementStart(11, "h4", 11);
        i0.ɵɵtext(12, "Payment is successful");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 12);
        i0.ɵɵelementStart(14, "button", 13);
        i0.ɵɵtext(15, "Schedule a session now");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, styles: [""] });
