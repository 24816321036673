import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/common";
import * as i3 from "../../ng-jhipster/language/jhi-translate.directive";
function SessionDurationSelectionPopupComponent_div_0_h3_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h3", 7);
} }
function SessionDurationSelectionPopupComponent_div_0_h3_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h3", 8);
} }
function SessionDurationSelectionPopupComponent_div_0_h3_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h3", 9);
} }
const _c0 = function (a0) { return { value: a0 }; };
function SessionDurationSelectionPopupComponent_div_0_ng_container_5_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 10);
    i0.ɵɵlistener("click", function SessionDurationSelectionPopupComponent_div_0_ng_container_5_Template_button_click_1_listener() { const restoredCtx = i0.ɵɵrestoreView(_r7); const duration_r5 = restoredCtx.$implicit; const ctx_r6 = i0.ɵɵnextContext(2); return ctx_r6.selectSessionDuration(duration_r5); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(2, "svg", 11);
    i0.ɵɵelement(3, "use", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelement(4, "div", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const duration_r5 = ctx.$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵattribute("href", "../../../../content/images/sprite.svg?v=3#duration-clock", null, "xlink");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(2, _c0, duration_r5));
} }
function SessionDurationSelectionPopupComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, SessionDurationSelectionPopupComponent_div_0_h3_1_Template, 1, 0, "h3", 2);
    i0.ɵɵtemplate(2, SessionDurationSelectionPopupComponent_div_0_h3_2_Template, 1, 0, "h3", 3);
    i0.ɵɵtemplate(3, SessionDurationSelectionPopupComponent_div_0_h3_3_Template, 1, 0, "h3", 4);
    i0.ɵɵelementStart(4, "div", 5);
    i0.ɵɵtemplate(5, SessionDurationSelectionPopupComponent_div_0_ng_container_5_Template, 5, 4, "ng-container", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.sessionType === "text");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.sessionType === "video");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.sessionType === "intro_call");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r0.durations);
} }
export class SessionDurationSelectionPopupComponent {
    constructor(activeModal) {
        this.activeModal = activeModal;
    }
    ngOnInit() {
    }
    selectSessionDuration(duration) {
        this.activeModal.close(duration);
    }
}
SessionDurationSelectionPopupComponent.ɵfac = function SessionDurationSelectionPopupComponent_Factory(t) { return new (t || SessionDurationSelectionPopupComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal)); };
SessionDurationSelectionPopupComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionDurationSelectionPopupComponent, selectors: [["session-duration-selection-popup"]], inputs: { sessionType: "sessionType", durations: "durations" }, decls: 1, vars: 1, consts: [["class", "olha-theme p-4 text-center", 4, "ngIf"], [1, "olha-theme", "p-4", "text-center"], ["jhiTranslate", "sessionSchedule.paragraph.sessionDurationSelection.text", 4, "ngIf"], ["jhiTranslate", "sessionSchedule.paragraph.sessionDurationSelection.video", 4, "ngIf"], ["jhiTranslate", "sessionSchedule.paragraph.sessionDurationSelection.intro_call", 4, "ngIf"], [1, "d-flex", "justify-content-center", 2, "gap", "10px"], [4, "ngFor", "ngForOf"], ["jhiTranslate", "sessionSchedule.paragraph.sessionDurationSelection.text"], ["jhiTranslate", "sessionSchedule.paragraph.sessionDurationSelection.video"], ["jhiTranslate", "sessionSchedule.paragraph.sessionDurationSelection.intro_call"], [1, "btn", "btn-secondary", 2, "border-radius", "10px", 3, "click"], [2, "height", "18px", "width", "18px", "margin", "0 5px 0 7px"], [0, "xlink", "href", ""], ["jhiTranslate", "sessionSchedule.paragraph.durationValue", 3, "translateValues"]], template: function SessionDurationSelectionPopupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, SessionDurationSelectionPopupComponent_div_0_Template, 6, 4, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.sessionType && ctx.durations);
    } }, directives: [i2.NgIf, i2.NgForOf, i3.JhiTranslateDirective], styles: [""] });
