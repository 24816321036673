import { FlowManagerService } from '../../../core/util/flow-manager.service';
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from '../../../core/auth/account.service';
import { WellbeingScanService } from '../wellbeing-scan.service';
import { forkJoin } from "rxjs";
import { AuthServerProvider } from '../../../core/auth/auth-jwt.service';
import { TrackerActivityFactoryService } from '../../../core/tracker/tracker-activity-factory.service';
import { flatMap } from "rxjs/internal/operators";
import { GhostUserDto } from '../../../core/user/ghost-user-dto.model';
import { LookingForHelpTopicsService } from '../../looking-for-help-topics/looking-for-help-topics.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../core/util/flow-manager.service";
import * as i2 from "@angular/router";
import * as i3 from "../../../core/auth/account.service";
import * as i4 from "../../looking-for-help-topics/looking-for-help-topics.service";
import * as i5 from "../wellbeing-scan.service";
import * as i6 from "../../../core/auth/auth-jwt.service";
import * as i7 from "../../../core/tracker/tracker-activity-factory.service";
import * as i8 from "@angular/common";
import * as i9 from "@angular/forms";
function WellbeingScanStandaloneCallbackComponent_form_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "form", 1);
    i0.ɵɵelement(1, "input", 2);
    i0.ɵɵelement(2, "input", 3);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("action", ctx_r0.callback, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("value", ctx_r0.score);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("value", ctx_r0.stage);
} }
export class WellbeingScanStandaloneCallbackComponent {
    constructor(flowManagerService, router, route, accountService, lookingForHelpTopicsService, wellbeingScanService, authServerProvider, trackerActivityFactoryService) {
        this.flowManagerService = flowManagerService;
        this.router = router;
        this.route = route;
        this.accountService = accountService;
        this.lookingForHelpTopicsService = lookingForHelpTopicsService;
        this.wellbeingScanService = wellbeingScanService;
        this.authServerProvider = authServerProvider;
        this.trackerActivityFactoryService = trackerActivityFactoryService;
    }
    ngOnInit() {
        this.callback = this.flowManagerService.getFlowParameter('callback');
        this.route.queryParams.subscribe(params => {
            this.score = params['score'];
            this.stage = params['stage'];
        });
    }
    ngAfterViewInit() {
        if (!this.accountService.isAuthenticated()) {
            const saveDataRequests = [];
            if (this.lookingForHelpTopicsService.hasAnySavedHelpTopics()) {
                saveDataRequests.push(this.lookingForHelpTopicsService.submitUserLookingForHelpTopics());
            }
            if (this.wellbeingScanService.hasAnySavedSrqAnswers()) {
                saveDataRequests.push(this.wellbeingScanService.submitAnswers());
            }
            if (saveDataRequests.length) {
                const parallelSaveDataRequests = forkJoin(saveDataRequests);
                const idKey = this.trackerActivityFactoryService.getIdKey();
                const organizationCode = this.flowManagerService.getFlowParameter('organizationCode');
                const ghostUserDto = new GhostUserDto(idKey, organizationCode);
                this.authServerProvider.loginAsGhost(ghostUserDto)
                    .pipe(flatMap(() => parallelSaveDataRequests))
                    .subscribe(() => { }, error => { }, () => {
                    // send save data requests in parallel
                    this.authServerProvider.logout();
                    this.redirectToCallBackUrl();
                });
            }
        }
        else {
            this.redirectToCallBackUrl();
        }
    }
    redirectToCallBackUrl() {
        setTimeout(() => {
            if (this.callback) {
                this.callback = decodeURIComponent(this.callback);
                const form = document.getElementById('callback-form');
                form.submit();
            }
            else {
                this.router.navigate([WellbeingScanStandaloneCallbackComponent.DEFAULT_CALL_BACK]).then();
            }
        }, 10);
    }
}
WellbeingScanStandaloneCallbackComponent.DEFAULT_CALL_BACK = '/';
WellbeingScanStandaloneCallbackComponent.ɵfac = function WellbeingScanStandaloneCallbackComponent_Factory(t) { return new (t || WellbeingScanStandaloneCallbackComponent)(i0.ɵɵdirectiveInject(i1.FlowManagerService), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i3.AccountService), i0.ɵɵdirectiveInject(i4.LookingForHelpTopicsService), i0.ɵɵdirectiveInject(i5.WellbeingScanService), i0.ɵɵdirectiveInject(i6.AuthServerProvider), i0.ɵɵdirectiveInject(i7.TrackerActivityFactoryService)); };
WellbeingScanStandaloneCallbackComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WellbeingScanStandaloneCallbackComponent, selectors: [["wellbeing-scan-standalone-callback"]], decls: 1, vars: 1, consts: [["id", "callback-form", "class", "d-none", "method", "post", 3, "action", 4, "ngIf"], ["id", "callback-form", "method", "post", 1, "d-none", 3, "action"], ["type", "text", "name", "score", 3, "value"], ["type", "text", "name", "stage", 3, "value"]], template: function WellbeingScanStandaloneCallbackComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, WellbeingScanStandaloneCallbackComponent_form_0_Template, 3, 3, "form", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.callback);
    } }, directives: [i8.NgIf, i9.ɵNgNoValidate, i9.NgControlStatusGroup, i9.NgForm], styles: [""] });
