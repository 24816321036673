import { NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import SockJS from 'sockjs-client';
import * as Stomp from 'webstomp-client';
import { AuthServerProvider } from '../auth/auth-jwt.service';
import { debug, SERVER_API_URL } from '../../app.constants';
import { JhiEventManager } from '../../ng-jhipster/service';
import { AccountService } from '../auth/account.service';
import { TrackerActivityFactoryService } from '../tracker/tracker-activity-factory.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../auth/auth-jwt.service";
import * as i3 from "@angular/common";
import * as i4 from "../../ng-jhipster/service";
import * as i5 from "../tracker/tracker-activity-factory.service";
import * as i6 from "../auth/account.service";
export class TrackerService {
    constructor(router, authServerProvider, location, eventManager, trackerActivityFactoryService, accountService, zone) {
        this.router = router;
        this.authServerProvider = authServerProvider;
        this.location = location;
        this.eventManager = eventManager;
        this.trackerActivityFactoryService = trackerActivityFactoryService;
        this.accountService = accountService;
        this.zone = zone;
        this.stompClient = null;
        this.routerSubscription = null;
        this.connectionSubject = new ReplaySubject(1);
        this.connectionSubscription = null;
        this.stompSubscription = null;
        this.listenerSubject = new Subject();
        this.waitingForSendActivities = [];
        this.subscribeCount = 0;
        this.connectAttempt = 0;
        this.reconnectionAttempt = 0;
        this.disconnectCalled = false;
        this.reconnectInterval = null;
        this.connected = false;
        this.internetWasDown = false;
        this.establishingConnectionWarningIsDisplayed = false;
    }
    init() {
        console.log("Websocket service initializing");
        this.accountService.getAuthenticationState().subscribe((account) => {
            if (this.isConnected()) {
                this.disconnect();
            }
            if (account) {
                this.connect(account.id);
            }
            else {
                console.log("Websocket: user is not logged in");
                this.connect();
            }
        });
        if (!this.routerSubscription) {
            this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.sendActivity('page_view'));
        }
    }
    connect(userId) {
        if (userId) {
            this.userId = userId;
        }
        if (this.isConnected()) {
            console.log('Websocket: already connected. Subscription: ' + this.stompSubscription);
            return;
        }
        else if (this.connectAttempt === 1) {
            console.log("Websocket: connection in progress");
            return;
        }
        else {
            this.connectAttempt++;
            console.log('Websocket: connection request');
        }
        // building absolute path so that websocket doesn't fail when deploying with a context path
        let url = this.prepareUrl();
        const socket = new SockJS(url);
        socket.onopen = () => {
            debug('Websocket: socket open.');
        };
        socket.addEventListener('close', (event) => {
            console.log('Websocket: close: ' + JSON.stringify(event)); // {"type":"close","bubbles":false,"cancelable":false,"timeStamp":1597232936541,"wasClean":true,"code":1001,"reason":""}
            this.subscribeCount--;
            this.stompSubscription = undefined;
            if (!this.reconnectInterval) {
                this.reconnect();
            }
            else {
                console.log('Websocket: reconnect handler is already active,');
            }
        });
        const options = { debug: false };
        this.stompClient = Stomp.over(socket, options);
        const headers = {};
        this.stompClient.connect(headers, (c) => {
            console.log('Websocket: stompClient connected');
            this.connectionSubject.next();
            this.sendWaitingActivities();
            if (this.userId) {
                this.subscribeToUserTopic('/topic/users/' + this.userId);
            }
            else {
                console.log("Websocket not subscribed to user topic, messages will not be received");
            }
        }, (e) => {
            console.log("Websocket error: " + e);
            this.connectAttempt--;
        });
    }
    disconnect() {
        console.log('Websocket: disconnecting');
        this.disconnectCalled = true;
        this.unsubscribe();
        this.connectionSubject = new ReplaySubject(1);
        if (this.stompClient) {
            if (this.stompClient.connected) {
                this.stompClient.disconnect();
            }
            this.stompClient = null;
        }
        this.connectAttempt = 0;
    }
    receive() {
        return this.listenerSubject;
    }
    subscribe() {
        if (this.connectionSubscription) {
            return;
        }
        this.connectionSubscription = this.connectionSubject.subscribe(() => {
            if (this.stompClient) {
                this.stompSubscription = this.stompClient.subscribe('/topic/tracker', (data) => {
                    this.zone.run(() => {
                        this.listenerSubject.next(JSON.parse(data.body));
                    });
                });
            }
        });
    }
    sendWaitingActivities() {
        if (this.stompClient && this.stompClient.connected && this.waitingForSendActivities && this.waitingForSendActivities.length) {
            while (this.waitingForSendActivities.length) {
                let activity = this.waitingForSendActivities.shift();
                this.sendActivityToWebSocket(activity);
            }
        }
    }
    subscribeTo(topic) {
        if (this.stompSubscription) {
            console.log('Websocket: already subscribed');
            return;
        }
        debug('Websocket: subscribing to: ' + topic);
        if (this.subscribeCount > 1) {
            console.log('Websocket: multiple subscription attempt');
            return;
        }
        if (this.stompClient) {
            if (!this.stompClient.connected) {
                console.warn('Websocket: stomp client not connected');
            }
            this.stompSubscription = this.stompClient.subscribe(topic, (data) => {
                const value = JSON.parse(data.body);
                debug('Websocket: received: ' + value.type);
                this.zone.run(() => {
                    this.listenerSubject.next(value);
                });
            }, { id: this.authServerProvider.getToken() });
            console.log('Websocket: subscribed to stomp client');
            this.eventManager.broadcast({ name: 'connectedToWebsocket', content: false });
        }
        else {
            console.warn('Websocket: unable to subscribe, client does not exist');
        }
        // });
    }
    unsubscribe() {
        if (this.stompSubscription) {
            this.stompSubscription.unsubscribe();
            this.stompSubscription = null;
        }
        if (this.connectionSubscription) {
            this.connectionSubscription.unsubscribe();
            this.connectionSubscription = null;
        }
    }
    sendActivity(activityName, properties) {
        let trackerActivity = this.trackerActivityFactoryService.create(activityName, this.router.routerState.snapshot.url.split('?')[0], properties);
        if (this.stompClient && this.stompClient.connected) {
            this.sendActivityToWebSocket(trackerActivity);
        }
        else {
            console.log("Websocket: Store message in queue");
            // if the websocket is not ready, add the activity to waiting list
            this.waitingForSendActivities.push(trackerActivity);
        }
    }
    sendActivityToWebSocket(trackerActivity) {
        this.stompClient.send('/topic/activity', // destination
        JSON.stringify(trackerActivity), // body
        {} // header
        );
    }
    isConnected() {
        const b = this.stompClient && this.stompClient.connected;
        debug('Websocket: Connection status: ' + b);
        return b;
    }
    reconnect() {
        if (this.isConnected() || this.disconnectCalled) {
            console.log('Websocket: disabling reconnect handler');
            this.reset();
        }
        else {
            console.log('Websocket: reconnecting');
            let nextRetry = 5 + this.reconnectionAttempt;
            this.reconnectInterval = setTimeout(() => this.reconnect(), nextRetry * 1000);
            if (!navigator.onLine) {
                // this scenario alrady is handled by page-warning.component
                console.log('Websocket: Internet connection is down. Wait until internet connection is back.');
            }
            else {
                if (!this.establishingConnectionWarningIsDisplayed) {
                    // showWarning: Establishing connection with Inuka servers
                    this.establishingConnectionWarningIsDisplayed = true;
                    this.eventManager.broadcast({ name: 'reconnectingToWebsocket', content: false });
                }
            }
            this.reconnectionAttempt++;
            console.log('Websocket: Reconnect attempt: ' + this.reconnectionAttempt);
            debug('Websocket: Next retry after: ' + nextRetry + " seconds");
            this.connect();
        }
    }
    reset() {
        clearInterval(this.reconnectInterval);
        this.reconnectInterval = undefined;
        this.reconnectionAttempt = 0;
        this.disconnectCalled = false;
    }
    prepareUrl() {
        let url = SERVER_API_URL + 'websocket/tracker';
        const authToken = this.authServerProvider.getToken();
        if (authToken) {
            url += '?access_token=' + authToken;
        }
        return url;
    }
    subscribeToUserTopic(topic) {
        console.log("Websocket subscribeToUserTopic: " + topic);
        try {
            this.subscribeCount++;
            this.subscribeTo(topic);
        }
        catch (err) {
            console.warn('Websocket: subscribe failed: ' + err + ', trying again');
            this.subscribeCount--;
            setTimeout(() => {
                this.subscribeToUserTopic(topic);
            }, 3000);
        }
    }
}
TrackerService.ɵfac = function TrackerService_Factory(t) { return new (t || TrackerService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthServerProvider), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i4.JhiEventManager), i0.ɵɵinject(i5.TrackerActivityFactoryService), i0.ɵɵinject(i6.AccountService), i0.ɵɵinject(i0.NgZone)); };
TrackerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TrackerService, factory: TrackerService.ɵfac, providedIn: 'root' });
