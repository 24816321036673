export class CoachSlot {
    constructor(id, coachId, startDateTime, endDateTime, nonRecurringEventStart, nonRecurringEventEnd, start, end, startTime, endTime, daysOfWeek, weekDay, limitValue, title, eventContent, recurrence, type, availableSessionType, backgroundColor, borderColor, coach) {
        this.id = id;
        this.coachId = coachId;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        this.nonRecurringEventStart = nonRecurringEventStart;
        this.nonRecurringEventEnd = nonRecurringEventEnd;
        this.start = start;
        this.end = end;
        this.startTime = startTime;
        this.endTime = endTime;
        this.daysOfWeek = daysOfWeek;
        this.weekDay = weekDay;
        this.limitValue = limitValue;
        this.title = title;
        this.eventContent = eventContent;
        this.recurrence = recurrence;
        this.type = type;
        this.availableSessionType = availableSessionType;
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
        this.coach = coach;
    }
}
