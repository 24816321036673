import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OrganizationResourceService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/organization-resources';
    }
    create(organizationResource) {
        return this.http.post(this.resourceUrl, organizationResource, { observe: 'response' });
    }
    update(organizationResource) {
        return this.http.put(this.resourceUrl, organizationResource, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    getOrganizationResourcesByOrganizationId(organizationId) {
        return this.http.get(`${this.resourceUrl}/organization-id/${organizationId}`, { observe: 'response' });
    }
    getOrganizationResourcesByUserIdAndLangkey(userId, langKey) {
        return this.http.get(`${this.resourceUrl}/user-id/${userId}/lang-key/${langKey}`, { observe: 'response' });
    }
    areAnyOrganizationResourcesExistByUserIdAndLangKey(userId, langKey) {
        return this.http.get(`${this.resourceUrl}/user-id/${userId}/lang-key/${langKey}/exist`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    queryForManagement(selectedRegions, selectedOrganizations, selectedOrganizationCodes, searchParam, req) {
        const options = createRequestOption(req);
        return this.http.post(`${this.resourceUrl}/searchParam`, { searchParam, selectedRegions, selectedOrganizations, selectedOrganizationCodes }, { params: options, observe: 'response' });
    }
}
OrganizationResourceService.ɵfac = function OrganizationResourceService_Factory(t) { return new (t || OrganizationResourceService)(i0.ɵɵinject(i1.HttpClient)); };
OrganizationResourceService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OrganizationResourceService, factory: OrganizationResourceService.ɵfac, providedIn: 'root' });
