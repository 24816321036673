export class VideoCall {
    constructor(id, externalId, externalSessionKey, startTime, endTime, status, duration, participantsCount, hasVideo, hasVoip, session) {
        this.id = id;
        this.externalId = externalId;
        this.externalSessionKey = externalSessionKey;
        this.startTime = startTime;
        this.endTime = endTime;
        this.status = status;
        this.duration = duration;
        this.participantsCount = participantsCount;
        this.hasVideo = hasVideo;
        this.hasVoip = hasVoip;
        this.session = session;
        this.hasVideo = this.hasVideo || false;
        this.hasVoip = this.hasVoip || false;
    }
}
