import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i3 from "@angular/common";
import * as i4 from "ngx-echarts";
import * as i5 from "@fortawesome/angular-fontawesome";
const _c0 = function () { return { renderer: "svg" }; };
function OrganizationSrqStatusComponent_ng_container_3_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 6);
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("options", ctx_r1.chartOption)("initOpts", i0.ɵɵpureFunction0(2, _c0));
} }
function OrganizationSrqStatusComponent_ng_container_3_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "fa-icon", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "p", 9);
    i0.ɵɵelementEnd();
} }
function OrganizationSrqStatusComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, OrganizationSrqStatusComponent_ng_container_3_div_1_Template, 1, 3, "div", 4);
    i0.ɵɵtemplate(2, OrganizationSrqStatusComponent_ng_container_3_div_2_Template, 4, 0, "div", 5);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.notEnoughData && (ctx_r0.chartOption == null ? null : ctx_r0.chartOption.series.length));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.notEnoughData);
} }
export class OrganizationSrqStatusComponent {
    constructor(organizationDashboardService) {
        this.organizationDashboardService = organizationDashboardService;
        this.isLoadingData = false;
        this.chartOption = {
            title: {
                show: false
            },
            grid: {
                top: 10,
                left: '7%',
                right: '5%',
                bottom: 60
            },
            legend: {
                orient: 'horizontal',
                bottom: 5,
                textStyle: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                },
                lineStyle: {
                    width: 0
                },
                itemWidth: 15
            },
            tooltip: {
                trigger: 'axis',
                textStyle: {
                    color: '0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            yAxis: {
                type: 'value',
                minInterval: 1,
                splitLine: {
                    show: true
                },
                axisLabel: {
                    color: '#9DB7B0',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#0A4A3A'
                    }
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: '#0A4A3A',
                    fontFamily: "'Open Sans', 'sans-serif'"
                }
            },
            series: [
                {
                    name: 'Resilient',
                    type: 'bar',
                    color: '#44A38B',
                    encode: {
                        x: 'scan',
                        y: 'resilientUsersCount' // refer dimensions name
                    },
                    barWidth: '22%',
                    barCategoryGap: '5%',
                    barMaxWidth: 50,
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0]
                    },
                    animationDuration: 3000
                },
                {
                    name: 'At risk',
                    type: 'bar',
                    color: '#E0CA58',
                    barWidth: '22%',
                    barCategoryGap: '5%',
                    barMaxWidth: 50,
                    encode: {
                        x: 'scan',
                        y: 'atRiskUsersCount' // refer dimensions name
                    },
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0]
                    },
                    animationDuration: 3000
                },
                {
                    name: 'Tough place',
                    type: 'bar',
                    color: '#AF3B6E',
                    barWidth: '22%',
                    barCategoryGap: '5%',
                    barMaxWidth: 50,
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0]
                    },
                    encode: {
                        x: 'scan',
                        y: 'inAToughPlaceUsersCount' // refer dimensions name
                    },
                    animationDuration: 3000,
                }
            ]
        };
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationSrqStatusReport(this.parameters).subscribe(response => {
            this.report = response.body;
            this.refreshChartData();
            this.isLoadingData = false;
            this.notEnoughData = false;
        }, error => {
            if (error.error.title === 'NotEnoughDataForOrganizationReport') {
                this.notEnoughData = true;
            }
            this.isLoadingData = false;
        });
    }
    refreshChartData() {
        var _a, _b;
        const dateset = [];
        if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.firstScan) {
            const srqStatus = this.report.firstScan;
            dateset.push([
                `First scan`,
                srqStatus.resilientUsersCount,
                srqStatus.atRiskUsersCount,
                srqStatus.inAToughPlaceUsersCount
            ]);
        }
        if ((_b = this.report) === null || _b === void 0 ? void 0 : _b.lastScan) {
            const srqStatus = this.report.lastScan;
            dateset.push([
                `Last scan`,
                srqStatus.resilientUsersCount,
                srqStatus.atRiskUsersCount,
                srqStatus.inAToughPlaceUsersCount
            ]);
        }
        this.chartOption.dataset = {
            source: dateset,
            dimensions: ['scan', 'resilientUsersCount', 'atRiskUsersCount', 'inAToughPlaceUsersCount']
        };
    }
}
OrganizationSrqStatusComponent.ɵfac = function OrganizationSrqStatusComponent_Factory(t) { return new (t || OrganizationSrqStatusComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService)); };
OrganizationSrqStatusComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationSrqStatusComponent, selectors: [["organization-srq-status"]], inputs: { parameters: "parameters" }, decls: 4, vars: 1, consts: [[1, "card", "dashboard-card", "p-2"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSrqStatus.header", 1, "mt-3", "ml-3", "light-weight"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSrqStatus.description", 1, "mb-3", "ml-3"], [4, "ngIf"], ["echarts", "", "style", "height: 400px; width: 100%", 3, "options", "initOpts", 4, "ngIf"], ["class", "not-enough-data-warning d-flex flex-column justify-content-center align-items-center", 4, "ngIf"], ["echarts", "", 2, "height", "400px", "width", "100%", 3, "options", "initOpts"], [1, "not-enough-data-warning", "d-flex", "flex-column", "justify-content-center", "align-items-center"], ["icon", "database", "size", "2x"], ["jhiTranslate", "inukaNgApp.organizationAdmin.notEnoughData"]], template: function OrganizationSrqStatusComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "h4", 1);
        i0.ɵɵelement(2, "div", 2);
        i0.ɵɵtemplate(3, OrganizationSrqStatusComponent_ng_container_3_Template, 3, 2, "ng-container", 3);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.isLoadingData);
    } }, directives: [i2.JhiTranslateDirective, i3.NgIf, i4.NgxEchartsDirective, i5.FaIconComponent], styles: [".not-enough-data-warning[_ngcontent-%COMP%]{width:100%;height:200px}.not-enough-data-warning[_ngcontent-%COMP%]   svg[_ngcontent-%COMP%]   path[_ngcontent-%COMP%]{fill:#9db7b0}.not-enough-data-warning[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin-top:10px;color:var(--green-lighter)}@media(min-width: 1200px){.not-enough-data-warning[_ngcontent-%COMP%]{height:400px}}"] });
