import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../ng-jhipster/language/jhi-translate.directive";
function SessionDurationsListComponent_ng_container_0_ng_container_1_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1, " \u00A0");
    i0.ɵɵelement(2, "span", 3);
    i0.ɵɵtext(3, "\u00A0 ");
    i0.ɵɵelementEnd();
} }
const _c0 = function (a0) { return { value: a0 }; };
function SessionDurationsListComponent_ng_container_0_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "span", 2);
    i0.ɵɵtemplate(2, SessionDurationsListComponent_ng_container_0_ng_container_1_span_2_Template, 4, 0, "span", 0);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const duration_r2 = ctx.$implicit;
    const durationIndex_r3 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(2, _c0, duration_r2));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", durationIndex_r3 < ctx_r1.durations.length - 1);
} }
function SessionDurationsListComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, SessionDurationsListComponent_ng_container_0_ng_container_1_Template, 3, 4, "ng-container", 1);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.durations);
} }
export class SessionDurationsListComponent {
    constructor() { }
    ngOnInit() {
    }
}
SessionDurationsListComponent.ɵfac = function SessionDurationsListComponent_Factory(t) { return new (t || SessionDurationsListComponent)(); };
SessionDurationsListComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionDurationsListComponent, selectors: [["session-durations-list"]], inputs: { durations: "durations" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [4, "ngFor", "ngForOf"], ["jhiTranslate", "sessionSchedule.paragraph.durationValue", 3, "translateValues"], ["jhiTranslate", "sessionSchedule.paragraph.or"]], template: function SessionDurationsListComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, SessionDurationsListComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.durations);
    } }, directives: [i1.NgIf, i1.NgForOf, i2.JhiTranslateDirective], styles: ["span[_ngcontent-%COMP%]{color:var(--Text-Green-Light-Text, #9DB7B0)}"] });
