import * as i0 from "@angular/core";
import * as i1 from "../../../ng-jhipster/language/jhi-translate.directive";
export class BadNetworkComponent {
    constructor() { }
    ngOnInit() {
    }
}
BadNetworkComponent.ɵfac = function BadNetworkComponent_Factory(t) { return new (t || BadNetworkComponent)(); };
BadNetworkComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: BadNetworkComponent, selectors: [["bad-network"]], decls: 5, vars: 0, consts: [[1, "d-flex", "flex-column", "justify-content-center", "align-items-center", 2, "height", "100%", "width", "100%"], ["src", "../../../../../content/images/bad-network.svg", 2, "width", "20%", "height", "auto"], ["jhiTranslate", "inukaNgApp.videoCall.label.badNetwork", 1, "mt-3"], ["jhiTranslate", "inukaNgApp.videoCall.label.refreshWhenConnected", 1, "mt-3"]], template: function BadNetworkComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "img", 1);
        i0.ɵɵelementStart(2, "h1", 2);
        i0.ɵɵtext(3, "Bad network quality");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(4, "h3", 3);
        i0.ɵɵelementEnd();
    } }, directives: [i1.JhiTranslateDirective], styles: [""] });
