import { ElementRef } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseComponent } from '../../../shared/base.component';
import { SessionStorageService } from "ngx-webstorage";
import { UserService } from '../../../core/user/user.service';
import { LookingForHelpTopicsService } from '../../looking-for-help-topics/looking-for-help-topics.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../core/user/user.service";
import * as i4 from "../../looking-for-help-topics/looking-for-help-topics.service";
import * as i5 from "../../../shared/wellbeing-header/wellbeing-header.component";
import * as i6 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i7 from "../../../shared/flow/hide-if-flow.directive";
import * as i8 from "@angular/router";
import * as i9 from "../../../shared/track-user-activity.directive";
const _c0 = function () { return { "track": "click", "name": "ss_booksession_btn_clicked" }; };
const _c1 = function () { return { "track": "click", "name": "ss_how_letsdo_btn_clicked" }; };
export class MatchWithACoachStartComponent extends BaseComponent {
    constructor(elementRef, sessionStorage, modalService, userService, lookingForHelpTopicsService) {
        super(sessionStorage, modalService);
        this.elementRef = elementRef;
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.userService = userService;
        this.lookingForHelpTopicsService = lookingForHelpTopicsService;
        this.flowName = "";
        this.startNowLink = "";
    }
    ngOnInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
        this.userServiceSubscription = this.userService.current().subscribe(user => {
            this.currentUser = user;
            this.lookingForHelpTopicsService.getUserLastLookingForHelpTopics().subscribe((r) => {
                const topics = r.body.topics;
                this.showHelpList = topics === null || topics === undefined;
                this.startNowLink = this.showHelpList ? '/match-with-a-coach/1' : '/match-with-a-coach/2';
            }, error => {
                console.log(error);
                if (error.status === 404) {
                    this.startNowLink = '/match-with-a-coach/1';
                }
            });
        });
    }
    ngOnDestroy() {
        var _a;
        (_a = this.userServiceSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
}
MatchWithACoachStartComponent.ɵfac = function MatchWithACoachStartComponent_Factory(t) { return new (t || MatchWithACoachStartComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i3.UserService), i0.ɵɵdirectiveInject(i4.LookingForHelpTopicsService)); };
MatchWithACoachStartComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: MatchWithACoachStartComponent, selectors: [["match-with-a-coach-start"]], features: [i0.ɵɵInheritDefinitionFeature], decls: 25, vars: 10, consts: [[1, "olha-theme"], [1, "container", "bg-2"], [1, "d-none", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-block", "mt-4"], ["jhiTranslate", "inukaNgApp.wellbeingStart.matchToCoach"], [1, "wellbeing-start-text"], ["jhiTranslate", "inukaNgApp.wellbeingStart.3Coaches", 2, "font-weight", "normal"], [1, "d-sm-none"], ["jhiTranslate", "inukaNgApp.wellbeingStart.chooseOptions", 2, "font-weight", "normal"], ["src", "../../../../../content/images/wellbeing/blob-hero.png", "alt", "blob-hero"], [1, "d-none", "d-md-block", "d-lg-block", "d-xl-block"], ["jhiHideIfFlow", "sss", "routerLink", "/session/schedule-session", "id", "ss-book-session-btn", 1, "btn", "btn-secondary", "align-items-center", "justify-content-center", 3, "trackUserActivity"], ["jhiTranslate", "inukaNgApp.wellbeingStart.viewAllCoaches", 2, "font-weight", "normal"], ["id", "ss-start-ss-btn", 1, "btn", "btn-primary", "align-items-center", "justify-content-center", 3, "routerLink", "trackUserActivity"], ["jhiTranslate", "inukaNgApp.wellbeingStart.startNow", 2, "font-weight", "normal", "color", "white"], [1, "d-md-none"], ["routerLink", "/session/schedule-session", 1, "btn", "btn-secondary", "align-items-center", "justify-content-center", 3, "trackUserActivity"], [1, "btn", "btn-primary", "align-items-center", "justify-content-center", 3, "routerLink", "trackUserActivity"]], template: function MatchWithACoachStartComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "wellbeing-header");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵelement(4, "h2", 3);
        i0.ɵɵelementStart(5, "div", 4);
        i0.ɵɵelement(6, "h4", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 6);
        i0.ɵɵelement(8, "h3", 3);
        i0.ɵɵelementStart(9, "div", 4);
        i0.ɵɵelement(10, "h4", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "div");
        i0.ɵɵelement(12, "img", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 9);
        i0.ɵɵelementStart(14, "button", 10);
        i0.ɵɵelement(15, "h5", 11);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(16, "button", 12);
        i0.ɵɵelement(17, "h5", 13);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(18, "div", 14);
        i0.ɵɵelementStart(19, "div");
        i0.ɵɵelementStart(20, "button", 15);
        i0.ɵɵelement(21, "h5", 11);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(22, "div");
        i0.ɵɵelementStart(23, "button", 16);
        i0.ɵɵelement(24, "h5", 13);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(14);
        i0.ɵɵproperty("trackUserActivity", i0.ɵɵpureFunction0(6, _c0));
        i0.ɵɵadvance(2);
        i0.ɵɵpropertyInterpolate("routerLink", ctx.startNowLink);
        i0.ɵɵproperty("trackUserActivity", i0.ɵɵpureFunction0(7, _c1));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("trackUserActivity", i0.ɵɵpureFunction0(8, _c0));
        i0.ɵɵadvance(3);
        i0.ɵɵpropertyInterpolate("routerLink", ctx.startNowLink);
        i0.ɵɵproperty("trackUserActivity", i0.ɵɵpureFunction0(9, _c1));
    } }, directives: [i5.WellbeingHeaderComponent, i6.JhiTranslateDirective, i7.HideIfFlowDirective, i8.RouterLink, i9.TrackUserActivityDirective], styles: [".wellbeing-start-text[_ngcontent-%COMP%]{display:block;margin:20px auto;width:300px;text-align:center;font-size:.75rem}.wellbeing-start-button[_ngcontent-%COMP%]{border-radius:1.7rem;width:10rem;font-size:1rem}.container[_ngcontent-%COMP%]{margin:90px 20px 40px !important;height:auto !important;width:auto;max-width:1169px !important;padding-top:30px;text-align:center}img[_ngcontent-%COMP%]{height:186px;width:211px;margin-top:15px}button[_ngcontent-%COMP%]{margin:0 10px 10px 10px;line-height:25px;height:40px;width:152px}h5[_ngcontent-%COMP%]{font-size:12px;margin:0}ul[_ngcontent-%COMP%]{padding-left:15px}.arrow-down-icon[_ngcontent-%COMP%]{height:7px;width:14px;margin-top:10px}@media(min-width: 576px){.container[_ngcontent-%COMP%]{margin:120px 20px 130px !important;padding-top:50px}img[_ngcontent-%COMP%]{margin:20px}.wellbeing-start-text[_ngcontent-%COMP%]{width:500px;margin-bottom:30px;font-size:1rem}button[_ngcontent-%COMP%]{height:50px;width:200px}h5[_ngcontent-%COMP%]{font-size:14px}}@media(min-width: 768px){.container[_ngcontent-%COMP%]{height:720px !important}img[_ngcontent-%COMP%]{height:255px;width:290px;margin:30px}.wellbeing-start-text[_ngcontent-%COMP%]{width:670px;margin-bottom:30px;font-size:1rem}}@media(min-width: 1200px){.container[_ngcontent-%COMP%]{margin:120px auto 130px !important;padding-top:50px}}"] });
