import { TemplateRef } from "@angular/core";
import { UserService } from '../../core/user/user.service';
import { BaseComponent } from '../base.component';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CoachDetailsService } from '../../entities/slot/coach-details.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../core/user/user.service";
import * as i4 from "../../entities/slot/coach-details.service";
import * as i5 from "@angular/common";
import * as i6 from "../upload-image-component/profile-image.component";
import * as i7 from "../badge/badge.component";
import * as i8 from "../../entities/session/schedule-session/supported-languages/supported-languages.component";
import * as i9 from "../session-type/session-type.component";
import * as i10 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i11 from "@fortawesome/angular-fontawesome";
import * as i12 from "../alert/alert-error.component";
import * as i13 from "../coach-bio/coach-bio.component";
const _c0 = ["coachBioModal"];
function CoachInfoComponent_div_1_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "badge-component", 16);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("coachDetails", ctx_r5.coachDetails);
} }
function CoachInfoComponent_div_1_div_8_Template(rf, ctx) { if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 17);
    i0.ɵɵelementStart(1, "ngb-rating", 18);
    i0.ɵɵlistener("rateChange", function CoachInfoComponent_div_1_div_8_Template_ngb_rating_rateChange_1_listener($event) { i0.ɵɵrestoreView(_r13); const ctx_r12 = i0.ɵɵnextContext(2); return ctx_r12.coach.coachScorec = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "span", 19);
    i0.ɵɵelementStart(3, "u", 20);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    const _r1 = i0.ɵɵreference(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("rate", ctx_r6.coach.coachScorec)("starTemplate", _r1)("readonly", true);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(5, 4, ctx_r6.coach.coachScorec, "1.0-1"), " ");
} }
function CoachInfoComponent_div_1_div_9_Template(rf, ctx) { if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 21);
    i0.ɵɵelementStart(1, "ngb-rating", 18);
    i0.ɵɵlistener("rateChange", function CoachInfoComponent_div_1_div_9_Template_ngb_rating_rateChange_1_listener($event) { i0.ɵɵrestoreView(_r15); const ctx_r14 = i0.ɵɵnextContext(2); return ctx_r14.coach.coachScorec = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "span", 19);
    i0.ɵɵelementStart(3, "u", 20);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    const _r1 = i0.ɵɵreference(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("rate", ctx_r7.coach.coachScorec)("starTemplate", _r1)("readonly", true);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(5, 4, ctx_r7.coach.coachScorec, "1.0-1"));
} }
function CoachInfoComponent_div_1_supported_languages_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "supported-languages", 22);
} if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("list", ctx_r8.coachLanguages);
} }
const _c1 = function () { return ["en"]; };
function CoachInfoComponent_div_1_supported_languages_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "supported-languages", 22);
} if (rf & 2) {
    i0.ɵɵproperty("list", i0.ɵɵpureFunction0(1, _c1));
} }
function CoachInfoComponent_div_1_div_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 23);
    i0.ɵɵelement(1, "session-type", 24);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("sessionType", ctx_r10.selectedSessionType);
} }
function CoachInfoComponent_div_1_div_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 25);
    i0.ɵɵelement(1, "h4", 26);
    i0.ɵɵelementStart(2, "div");
    i0.ɵɵelementStart(3, "p");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r11.coachReasoning);
} }
const _c2 = function () { return []; };
function CoachInfoComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("click", function CoachInfoComponent_div_1_Template_div_click_0_listener() { i0.ɵɵrestoreView(_r17); const ctx_r16 = i0.ɵɵnextContext(); return ctx_r16.displayCoachBio(ctx_r16.coach); });
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelement(2, "profile-image", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 7);
    i0.ɵɵelementStart(4, "div", 8);
    i0.ɵɵelementStart(5, "h4", 9);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, CoachInfoComponent_div_1_div_7_Template, 2, 1, "div", 10);
    i0.ɵɵtemplate(8, CoachInfoComponent_div_1_div_8_Template, 6, 7, "div", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(9, CoachInfoComponent_div_1_div_9_Template, 6, 7, "div", 12);
    i0.ɵɵelementStart(10, "div");
    i0.ɵɵtemplate(11, CoachInfoComponent_div_1_supported_languages_11_Template, 1, 1, "supported-languages", 13);
    i0.ɵɵtemplate(12, CoachInfoComponent_div_1_supported_languages_12_Template, 1, 2, "supported-languages", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(13, CoachInfoComponent_div_1_div_13_Template, 2, 1, "div", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(14, CoachInfoComponent_div_1_div_14_Template, 5, 1, "div", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("flex-direction", ctx_r0.isScreenMd ? "row" : "column");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("coachId", ctx_r0.coachId)("coachLogin", ctx_r0.coach.login)("videoEnabled", ctx_r0.coachDetails == null ? null : ctx_r0.coachDetails.videoChatEnabled)("size", 90);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r0.coach.login);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.coachDetails);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedSessionType != null);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedSessionType === null || ctx_r0.selectedSessionType === undefined);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.coachLanguages != null && ctx_r0.coachLanguages != i0.ɵɵpureFunction0(14, _c2));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.coachLanguages === null || ctx_r0.coachLanguages === undefined || ctx_r0.coachLanguages === i0.ɵɵpureFunction0(15, _c2));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.selectedSessionType != null);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.showReasoning === true);
} }
const _c3 = function () { return ["fas", "star"]; };
const _c4 = function () { return ["far", "star"]; };
function CoachInfoComponent_ng_template_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 27);
    i0.ɵɵelementStart(1, "span", 28);
    i0.ɵɵelementStart(2, "em", 29);
    i0.ɵɵelement(3, "fa-icon", 30);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "em", 31);
    i0.ɵɵelement(5, "fa-icon", 30);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const fill_r18 = ctx.fill;
    i0.ɵɵclassProp("full", fill_r18 === 100);
    i0.ɵɵadvance(1);
    i0.ɵɵstyleProp("width", fill_r18, "%");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(6, _c3));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(7, _c4));
} }
function CoachInfoComponent_ng_template_4_Template(rf, ctx) { if (rf & 1) {
    const _r21 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 32);
    i0.ɵɵelementStart(1, "fa-icon", 33);
    i0.ɵɵlistener("click", function CoachInfoComponent_ng_template_4_Template_fa_icon_click_1_listener() { i0.ɵɵrestoreView(_r21); const ctx_r20 = i0.ɵɵnextContext(); return ctx_r20.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "div", 34);
    i0.ɵɵelement(3, "jhi-alert-error");
    i0.ɵɵelement(4, "coach-bio", 35);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("coach", ctx_r4.coachBioCoach);
} }
export class CoachInfoComponent extends BaseComponent {
    constructor(sessionStorage, modalService, userService, coachDetailsService) {
        super(sessionStorage, modalService);
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.userService = userService;
        this.coachDetailsService = coachDetailsService;
    }
    ngOnInit() {
        this.userService.findById(this.coachId).subscribe((coach) => {
            this.coach = coach;
            console.log(this.coach);
            this.coachDetailsService.findCoachDetails(this.coachId).subscribe(response2 => {
                this.coachDetails = response2.body;
            });
        });
        this.userService.getCoachReasoningLanguages(this.coachId).subscribe((coachLanguages) => {
            this.coachLanguages = coachLanguages || [];
        });
        this.userService.getCoachReasoning(this.coachId, 'en').subscribe((coachReasoning) => {
            this.coachReasoning = coachReasoning;
        });
    }
    displayCoachBio(coach) {
        this.coachBioCoach = coach;
        this.modalService.open(this.coachBioModal, { size: 'lg', backdrop: 'static', centered: true });
    }
    close() {
        this.modalService.dismissAll();
    }
}
CoachInfoComponent.ɵfac = function CoachInfoComponent_Factory(t) { return new (t || CoachInfoComponent)(i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i3.UserService), i0.ɵɵdirectiveInject(i4.CoachDetailsService)); };
CoachInfoComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: CoachInfoComponent, selectors: [["coach-info"]], viewQuery: function CoachInfoComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.coachBioModal = _t.first);
    } }, inputs: { coachId: "coachId", selectedSessionType: "selectedSessionType", showReasoning: "showReasoning" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 6, vars: 3, consts: [[1, "olha-theme", "bg-white", "coach-info-card"], ["style", "cursor: pointer", "class", "d-flex justify-content-start align-items-start", 3, "flex-direction", "click", 4, "ngIf"], ["t", ""], ["coachBioModal", ""], [1, "d-flex", "justify-content-start", "align-items-start", 2, "cursor", "pointer", 3, "click"], [1, "coach-image-div"], [3, "coachId", "coachLogin", "videoEnabled", "size"], [1, "coach-name-div"], [1, "d-flex", "justify-content-start", "align-items-center", "mb-2", "coach-header-div"], [2, "margin", "0!important"], [4, "ngIf"], ["class", "ml-2", 4, "ngIf"], ["class", "mb-2", 4, "ngIf"], [3, "list", 4, "ngIf"], ["style", "font-size: 16px", 4, "ngIf"], ["class", "coach-text-div", 4, "ngIf"], [3, "coachDetails"], [1, "ml-2"], ["max", "5", 3, "rate", "starTemplate", "readonly", "rateChange"], [1, "px-2"], [2, "text-decoration", "none"], [1, "mb-2"], [3, "list"], [2, "font-size", "16px"], ["showIcon", "false", 3, "sessionType"], [1, "coach-text-div"], ["jhiTranslate", "settings.headers.Why do I coach", 1, "mb-2"], [1, "star"], [1, "half"], [2, "color", "#44A38D"], [3, "icon"], [1, "cursor", 2, "color", "#44A38D"], [1, "olha-theme", "p-4", "coach-bio"], ["icon", "times-circle", 1, "close", 3, "click"], [1, "main-body"], [3, "coach"]], template: function CoachInfoComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, CoachInfoComponent_div_1_Template, 15, 16, "div", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, CoachInfoComponent_ng_template_2_Template, 6, 8, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
        i0.ɵɵtemplate(4, CoachInfoComponent_ng_template_4_Template, 5, 1, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        i0.ɵɵstyleProp("padding", ctx.isScreenMd ? "20px" : "10px");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.coach != null);
    } }, directives: [i5.NgIf, i6.ProfileImageComponent, i7.BadgeComponent, i2.NgbRating, i8.SupportedLanguagesComponent, i9.SessionTypeComponent, i10.JhiTranslateDirective, i11.FaIconComponent, i12.AlertErrorComponent, i13.CoachBioComponent], pipes: [i5.DecimalPipe], styles: [".coach-bio[_ngcontent-%COMP%]{min-height:600px;max-height:800px}.coach-info-card[_ngcontent-%COMP%]{border-radius:20px;margin:20px;max-height:500px;overflow:scroll}.coach-header-div[_ngcontent-%COMP%]{color:var(--text-dark-green, #0A4A3A);font-family:Poppins,serif;font-size:14px;font-style:normal;font-weight:600;line-height:130%}.coach-image-div[_ngcontent-%COMP%], .coach-name-div[_ngcontent-%COMP%], .coach-text-div[_ngcontent-%COMP%]{padding:10px}.star[_ngcontent-%COMP%]{position:relative;display:inline-block;font-size:1rem}.half[_ngcontent-%COMP%]{position:absolute;display:inline-block;overflow:hidden}.badge[_ngcontent-%COMP%]{background:rgba(68,163,141,.1);color:#44a38b;border-radius:16px;padding:1px 20px;width:120px;height:30px;margin:10px}"] });
