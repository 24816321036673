import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import * as i0 from "@angular/core";
export class NavbarInukaService {
    constructor(componentFactoryResolver) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.navbarTitle = 'inuka';
        this.embeddedComponentContainers = [];
    }
    get hasAnyEmbeddedComponent() {
        return this.embeddedComponentType;
    }
    registerAsEmbeddedComponentHost(embeddedComponentContainer) {
        if (embeddedComponentContainer) {
            this.embeddedComponentContainers.push(embeddedComponentContainer);
            if (this.embeddedComponentType) {
                this.createComponent();
            }
        }
    }
    showEmbeddedComponent(componentType, componentData) {
        var _a;
        this.embeddedComponentType = componentType;
        this.embeddedComponentData = componentData;
        if ((_a = this.embeddedComponentContainers) === null || _a === void 0 ? void 0 : _a.length) {
            this.createComponent();
        }
    }
    hideEmbeddedComponent() {
        var _a;
        this.embeddedComponentType = null;
        this.embeddedComponentData = null;
        if ((_a = this.embeddedComponentContainers) === null || _a === void 0 ? void 0 : _a.length) {
            for (const embeddedComponentContainer of this.embeddedComponentContainers) {
                embeddedComponentContainer.clear();
            }
        }
    }
    createComponent() {
        for (const viewContainerRef of this.embeddedComponentContainers) {
            viewContainerRef.clear();
            // create the component factory
            const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(this.embeddedComponentType);
            // add the component to the view
            const componentRef = viewContainerRef.createComponent(dynamicComponentFactory);
            if (this.embeddedComponentData) {
                // set Input params for embedded component
                Object.keys(this.embeddedComponentData)
                    .map(x => componentRef.instance[x] = this.embeddedComponentData[x]);
            }
        }
    }
}
NavbarInukaService.ɵfac = function NavbarInukaService_Factory(t) { return new (t || NavbarInukaService)(i0.ɵɵinject(i0.ComponentFactoryResolver)); };
NavbarInukaService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NavbarInukaService, factory: NavbarInukaService.ɵfac, providedIn: 'root' });
