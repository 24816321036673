import * as i0 from "@angular/core";
/**
 * An utility service for link parsing.
 */
export class JhiParseLinks {
    constructor() { }
    /**
     * Method to parse the links
     */
    parse(header) {
        if (header.length === 0) {
            throw new Error('input must not be of zero length');
        }
        // Split parts by comma
        const parts = header.split(',');
        const links = {};
        // Parse each part into a named link
        parts.forEach(p => {
            const section = p.split(';');
            if (section.length !== 2) {
                throw new Error('section could not be split on ";"');
            }
            const url = section[0].replace(/<(.*)>/, '$1').trim();
            const queryString = {};
            url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => (queryString[$1] = $3));
            let page = queryString.page;
            if (typeof page === 'string') {
                page = parseInt(page, 10);
            }
            const name = section[1].replace(/rel="(.*)"/, '$1').trim();
            links[name] = page;
        });
        return links;
    }
}
JhiParseLinks.ɵfac = function JhiParseLinks_Factory(t) { return new (t || JhiParseLinks)(); };
JhiParseLinks.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: JhiParseLinks, factory: JhiParseLinks.ɵfac, providedIn: 'root' });
