import { HttpClient } from "@angular/common/http";
import { SERVER_API_URL } from '../../app.constants';
import { map } from "rxjs/operators";
import moment from "moment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FeedbackService {
    constructor(http) {
        this.http = http;
    }
    createFeedback(feedback) {
        console.log(feedback);
        const copy = this.convertDateFromClient(feedback);
        return this.http
            .post(SERVER_API_URL + 'api/na/feedback', copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    sendFeedbackMessage(message) {
        console.log(message);
        const copy = this.convertDateFromClient(message);
        return this.http.post(SERVER_API_URL + 'api/na/email-feedback-message', copy, { responseType: 'text' });
    }
    // getAll(): Observable<IFeedback[]> {
    //   return this.http.get<IFeedback[]>(SERVER_API_URL + 'api/feedbacks/all');
    // }
    convertDateFromClient(feedback) {
        const copy = Object.assign({}, feedback, {
            creationDate: feedback.creationDate && feedback.creationDate.isValid() ? feedback.creationDate.toJSON() : undefined
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.creationDate = res.body.creationDate ? moment(res.body.creationDate) : undefined;
        }
        return res;
    }
}
FeedbackService.ɵfac = function FeedbackService_Factory(t) { return new (t || FeedbackService)(i0.ɵɵinject(i1.HttpClient)); };
FeedbackService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FeedbackService, factory: FeedbackService.ɵfac, providedIn: 'root' });
