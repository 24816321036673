import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/common";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
function TranscriptionStatusUpdatedPopupComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵelementStart(2, "fa-icon", 4);
    i0.ɵɵlistener("click", function TranscriptionStatusUpdatedPopupComponent_div_1_Template_fa_icon_click_2_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "h4", 5);
    i0.ɵɵelement(4, "p", 6);
    i0.ɵɵelementEnd();
} }
function TranscriptionStatusUpdatedPopupComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵelementStart(2, "fa-icon", 4);
    i0.ɵɵlistener("click", function TranscriptionStatusUpdatedPopupComponent_div_2_Template_fa_icon_click_2_listener() { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "h4", 7);
    i0.ɵɵtext(4, "Your coach has turned off the transcription");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "p", 8);
    i0.ɵɵtext(6, "You can ask your coach to turn it on again");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
export class TranscriptionStatusUpdatedPopupComponent {
    constructor(modalService) {
        this.modalService = modalService;
    }
    ngOnInit() {
    }
    close() {
        this.modalService.dismissAll();
    }
}
TranscriptionStatusUpdatedPopupComponent.ɵfac = function TranscriptionStatusUpdatedPopupComponent_Factory(t) { return new (t || TranscriptionStatusUpdatedPopupComponent)(i0.ɵɵdirectiveInject(i1.NgbModal)); };
TranscriptionStatusUpdatedPopupComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TranscriptionStatusUpdatedPopupComponent, selectors: [["transcription-status-updated-popup"]], inputs: { isTranscriptionEnabled: "isTranscriptionEnabled" }, decls: 3, vars: 2, consts: [[1, "olha-theme", "px-4", "pt-1", "pb-3"], ["class", "text-center", 4, "ngIf"], [1, "text-center"], [1, "text-right"], ["icon", "times-circle", 1, "close-icon", 3, "click"], ["jhiTranslate", "sessionChat.coachSessionControl.transcriptionIsTurnedOn", 1, "mt-4"], ["jhiTranslate", "sessionChat.coachSessionControl.transcriptionInTurnedOnDescription"], ["jhiTranslate", "sessionChat.coachSessionControl.transcriptionIsTurnedOff", 1, "mt-4"], ["jhiTranslate", "sessionChat.coachSessionControl.askToTurnOnTranscription"]], template: function TranscriptionStatusUpdatedPopupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, TranscriptionStatusUpdatedPopupComponent_div_1_Template, 5, 0, "div", 1);
        i0.ɵɵtemplate(2, TranscriptionStatusUpdatedPopupComponent_div_2_Template, 7, 0, "div", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isTranscriptionEnabled);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isTranscriptionEnabled);
    } }, directives: [i2.NgIf, i3.FaIconComponent, i4.JhiTranslateDirective], styles: [".close-icon[_ngcontent-%COMP%]{position:absolute !important;top:12px !important;right:12px !important;cursor:pointer !important;color:#0a4a3a !important}"] });
