import { RouterModule } from '@angular/router';
import { InukaNgSharedModule } from '../../shared/shared.module';
import { videoCallRoute } from './video-call.route';
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class InukaNgVideoCallModule {
}
InukaNgVideoCallModule.ɵfac = function InukaNgVideoCallModule_Factory(t) { return new (t || InukaNgVideoCallModule)(); };
InukaNgVideoCallModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgVideoCallModule });
InukaNgVideoCallModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[InukaNgSharedModule, RouterModule.forChild(videoCallRoute), MatSliderModule, MatTooltipModule]] });
