export const BENEFIT_TYPE = ['CREDIT', 'DISCOUNT'];
export class Voucher {
    constructor(id, code, benefitType, voucherCodeDiscount, numberOfUses, currentUse, deleted, numberOfSessions, oneTime, used, expiryDate, organization) {
        this.id = id;
        this.code = code;
        this.benefitType = benefitType;
        this.voucherCodeDiscount = voucherCodeDiscount;
        this.numberOfUses = numberOfUses;
        this.currentUse = currentUse;
        this.deleted = deleted;
        this.numberOfSessions = numberOfSessions;
        this.oneTime = oneTime;
        this.used = used;
        this.expiryDate = expiryDate;
        this.organization = organization;
        this.deleted = this.deleted || false;
        this.oneTime = this.oneTime || false;
        this.used = this.used || false;
    }
}
