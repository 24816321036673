export class CoachSlots {
    constructor(coach, sessionStartTimes, coachDetails, coachReasonings) {
        this.coach = coach;
        this.sessionStartTimes = sessionStartTimes;
        this.coachDetails = coachDetails;
        this.coachReasonings = coachReasonings;
        this.coachReasoningByLang = new Map();
        this.coachLanguages = [];
        for (let i = 0; i < coachReasonings.length; i++) {
            if (!coachReasonings[i].deleted) {
                this.coachReasoningByLang.set(coachReasonings[i].lang, coachReasonings[i]);
                this.coachLanguages.push(coachReasonings[i].lang);
            }
        }
        // for (const coachReasoning of coachReasonings) {
        //   if(!coachReasoning.deleted) {
        //     this.coachReasoningByLang.set(coachReasoning.lang, coachReasoning);
        //     this.coachLanguages.push(coachReasoning.lang);
        //   }
        // }
    }
}
export class CoachDetails {
    constructor(id, coachId, textChatEnabled, videoChatEnabled, acceptNewClients, imageUrl) {
        this.id = id;
        this.coachId = coachId;
        this.textChatEnabled = textChatEnabled;
        this.videoChatEnabled = videoChatEnabled;
        this.acceptNewClients = acceptNewClients;
        this.imageUrl = imageUrl;
    }
}
