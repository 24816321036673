import { BaseComponent } from '../../../shared/base.component';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "../../../shared/auto-direction-switch/auto-direction-switch/auto-direction-switch.component";
function ActionPlanComponent_div_1_button_3_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵlistener("click", function ActionPlanComponent_div_1_button_3_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r7); const ctx_r6 = i0.ɵɵnextContext(2); return ctx_r6.showAllInPopup(); });
    i0.ɵɵtext(1, "View Details ");
    i0.ɵɵelementEnd();
} }
function ActionPlanComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "h3", 8);
    i0.ɵɵtemplate(3, ActionPlanComponent_div_1_button_3_Template, 2, 0, "button", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", !ctx_r0.showAll && (ctx_r0.actionPlan == null ? null : ctx_r0.actionPlan.actionPlanItems == null ? null : ctx_r0.actionPlan.actionPlanItems.length));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.dateFormatted(ctx_r0.actionPlan == null ? null : ctx_r0.actionPlan.creationDate), " ");
} }
function ActionPlanComponent_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 11);
    i0.ɵɵelementContainerEnd();
} }
function ActionPlanComponent_div_3_ng_container_1_section_1_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "auto-direction-switch", 14);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r15 = ctx.$implicit;
    const ctx_r14 = i0.ɵɵnextContext(4);
    i0.ɵɵclassProp("selected-action-plan-item", i_r15.status);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", i_r15.itemText, i0.ɵɵsanitizeHtml)("showEllipsis", ctx_r14.showEllipsis);
} }
function ActionPlanComponent_div_3_ng_container_1_section_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section");
    i0.ɵɵelement(1, "h4", 12);
    i0.ɵɵelementStart(2, "ol");
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_1_section_1_li_3_Template, 2, 4, "li", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r10.myFocusThisWeek);
} }
function ActionPlanComponent_div_3_ng_container_1_section_2_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "auto-direction-switch", 14);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r17 = ctx.$implicit;
    const ctx_r16 = i0.ɵɵnextContext(4);
    i0.ɵɵclassProp("selected-action-plan-item", i_r17.status);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", i_r17.itemText, i0.ɵɵsanitizeHtml)("showEllipsis", ctx_r16.showEllipsis);
} }
function ActionPlanComponent_div_3_ng_container_1_section_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section");
    i0.ɵɵelement(1, "h4", 15);
    i0.ɵɵelementStart(2, "ul");
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_1_section_2_li_3_Template, 2, 4, "li", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r11.theChangeIWantToSee);
} }
function ActionPlanComponent_div_3_ng_container_1_section_3_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "auto-direction-switch", 14);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r19 = ctx.$implicit;
    const ctx_r18 = i0.ɵɵnextContext(4);
    i0.ɵɵclassProp("selected-action-plan-item", i_r19.status);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", i_r19.itemText, i0.ɵɵsanitizeHtml)("showEllipsis", ctx_r18.showEllipsis);
} }
function ActionPlanComponent_div_3_ng_container_1_section_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section");
    i0.ɵɵelement(1, "h4", 16);
    i0.ɵɵelementStart(2, "ol");
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_1_section_3_li_3_Template, 2, 4, "li", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r12 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r12.thingsICouldTry);
} }
function ActionPlanComponent_div_3_ng_container_1_section_4_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "auto-direction-switch", 14);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r21 = ctx.$implicit;
    const ctx_r20 = i0.ɵɵnextContext(4);
    i0.ɵɵclassProp("selected-action-plan-item", i_r21.status);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", i_r21.itemText, i0.ɵɵsanitizeHtml)("showEllipsis", ctx_r20.showEllipsis);
} }
function ActionPlanComponent_div_3_ng_container_1_section_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section");
    i0.ɵɵelement(1, "h4", 17);
    i0.ɵɵelementStart(2, "ol");
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_1_section_4_li_3_Template, 2, 4, "li", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r13 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r13.stepsIWillTake);
} }
function ActionPlanComponent_div_3_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ActionPlanComponent_div_3_ng_container_1_section_1_Template, 4, 1, "section", 2);
    i0.ɵɵtemplate(2, ActionPlanComponent_div_3_ng_container_1_section_2_Template, 4, 1, "section", 2);
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_1_section_3_Template, 4, 1, "section", 2);
    i0.ɵɵtemplate(4, ActionPlanComponent_div_3_ng_container_1_section_4_Template, 4, 1, "section", 2);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r8.isCollapsed && (ctx_r8.myFocusThisWeek == null ? null : ctx_r8.myFocusThisWeek.length));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r8.theChangeIWantToSee == null ? null : ctx_r8.theChangeIWantToSee.length);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r8.isCollapsed && (ctx_r8.thingsICouldTry == null ? null : ctx_r8.thingsICouldTry.length));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r8.stepsIWillTake == null ? null : ctx_r8.stepsIWillTake.length);
} }
function ActionPlanComponent_div_3_ng_container_2_section_1_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "auto-direction-switch", 18);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r27 = ctx.$implicit;
    const ctx_r26 = i0.ɵɵnextContext(4);
    i0.ɵɵclassProp("selected-action-plan-item", i_r27.status);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", i_r27.itemText, i0.ɵɵsanitizeHtml)("showEllipsis", ctx_r26.showEllipsis);
} }
function ActionPlanComponent_div_3_ng_container_2_section_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section");
    i0.ɵɵelement(1, "h4", 12);
    i0.ɵɵelementStart(2, "ol");
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_2_section_1_li_3_Template, 2, 4, "li", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r22 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r22.myFocusThisWeek);
} }
function ActionPlanComponent_div_3_ng_container_2_section_2_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "auto-direction-switch", 18);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r29 = ctx.$implicit;
    const ctx_r28 = i0.ɵɵnextContext(4);
    i0.ɵɵclassProp("selected-action-plan-item", i_r29.status);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", i_r29.itemText, i0.ɵɵsanitizeHtml)("showEllipsis", ctx_r28.showEllipsis);
} }
function ActionPlanComponent_div_3_ng_container_2_section_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section");
    i0.ɵɵelement(1, "h4", 15);
    i0.ɵɵelementStart(2, "ul");
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_2_section_2_li_3_Template, 2, 4, "li", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r23 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r23.theChangeIWantToSee);
} }
function ActionPlanComponent_div_3_ng_container_2_section_3_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "auto-direction-switch", 18);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r31 = ctx.$implicit;
    const ctx_r30 = i0.ɵɵnextContext(4);
    i0.ɵɵclassProp("selected-action-plan-item", i_r31.status);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", i_r31.itemText, i0.ɵɵsanitizeHtml)("showEllipsis", ctx_r30.showEllipsis);
} }
function ActionPlanComponent_div_3_ng_container_2_section_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section");
    i0.ɵɵelement(1, "h4", 16);
    i0.ɵɵelementStart(2, "ol");
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_2_section_3_li_3_Template, 2, 4, "li", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r24 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r24.thingsICouldTry);
} }
function ActionPlanComponent_div_3_ng_container_2_section_4_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "auto-direction-switch", 18);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const i_r33 = ctx.$implicit;
    const ctx_r32 = i0.ɵɵnextContext(4);
    i0.ɵɵclassProp("selected-action-plan-item", i_r33.status);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", i_r33.itemText, i0.ɵɵsanitizeHtml)("showEllipsis", ctx_r32.showEllipsis);
} }
function ActionPlanComponent_div_3_ng_container_2_section_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section");
    i0.ɵɵelement(1, "h4", 17);
    i0.ɵɵelementStart(2, "ol");
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_2_section_4_li_3_Template, 2, 4, "li", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r25 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r25.stepsIWillTake);
} }
function ActionPlanComponent_div_3_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ActionPlanComponent_div_3_ng_container_2_section_1_Template, 4, 1, "section", 2);
    i0.ɵɵtemplate(2, ActionPlanComponent_div_3_ng_container_2_section_2_Template, 4, 1, "section", 2);
    i0.ɵɵtemplate(3, ActionPlanComponent_div_3_ng_container_2_section_3_Template, 4, 1, "section", 2);
    i0.ɵɵtemplate(4, ActionPlanComponent_div_3_ng_container_2_section_4_Template, 4, 1, "section", 2);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r9.myFocusThisWeek == null ? null : ctx_r9.myFocusThisWeek.length);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r9.theChangeIWantToSee == null ? null : ctx_r9.theChangeIWantToSee.length);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r9.thingsICouldTry == null ? null : ctx_r9.thingsICouldTry.length);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r9.stepsIWillTake == null ? null : ctx_r9.stepsIWillTake.length);
} }
function ActionPlanComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ActionPlanComponent_div_3_ng_container_1_Template, 5, 4, "ng-container", 2);
    i0.ɵɵtemplate(2, ActionPlanComponent_div_3_ng_container_2_Template, 5, 4, "ng-container", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("action-plan-container", ctx_r2.showAll && ctx_r2.showScroll);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r2.showAll);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.showAll);
} }
function ActionPlanComponent_button_5_Template(rf, ctx) { if (rf & 1) {
    const _r35 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 19);
    i0.ɵɵlistener("click", function ActionPlanComponent_button_5_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r35); const ctx_r34 = i0.ɵɵnextContext(); return ctx_r34.changeCollapsed(false); });
    i0.ɵɵtext(1, "View Details ");
    i0.ɵɵelementEnd();
} }
function ActionPlanComponent_button_6_Template(rf, ctx) { if (rf & 1) {
    const _r37 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 20);
    i0.ɵɵlistener("click", function ActionPlanComponent_button_6_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r37); const ctx_r36 = i0.ɵɵnextContext(); return ctx_r36.changeCollapsed(true); });
    i0.ɵɵtext(1, "Less Details ");
    i0.ɵɵelementEnd();
} }
export class ActionPlanComponent extends BaseComponent {
    constructor(sessionStorage, modalService) {
        super(sessionStorage, modalService);
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.showAll = false;
        this.isCollapsed = true;
        this.showScroll = false;
    }
    set actionPlan(value) {
        this._actionPlan = value;
        this.extractActionItems();
    }
    get actionPlan() {
        return this._actionPlan;
    }
    ngOnInit() {
        if (this.showAll) {
            this.isCollapsed = false;
        }
    }
    extractActionItems() {
        if (this.actionPlan) {
            this.myFocusThisWeek = this.actionPlan.actionPlanItems.filter(i => i.actionPlanGroup === "MY_FOCUS_THIS_WEEK" /* MY_FOCUS_THIS_WEEK */.toString());
            this.theChangeIWantToSee = this.actionPlan.actionPlanItems.filter(i => i.actionPlanGroup === "THE_CHANGE_I_WANT_TO_SEE" /* THE_CHANGE_I_WANT_TO_SEE */.toString());
            this.thingsICouldTry = this.actionPlan.actionPlanItems.filter(i => i.actionPlanGroup === "THINGS_I_COULD_TRY" /* THINGS_I_COULD_TRY */.toString());
            this.stepsIWillTake = this.actionPlan.actionPlanItems.filter(i => i.actionPlanGroup === "STEPS_I_WILL_TAKE" /* STEPS_I_WILL_TAKE */.toString());
        }
        else {
            this.myFocusThisWeek = [];
            this.theChangeIWantToSee = [];
            this.thingsICouldTry = [];
            this.stepsIWillTake = [];
        }
    }
    showAllInPopup() {
        const modalRef = this.modalService.open(ActionPlanComponent, { windowClass: 'modal-window', size: 'lg' });
        modalRef.componentInstance.actionPlan = this.actionPlan;
        modalRef.componentInstance.showAll = true;
        modalRef.componentInstance.showScroll = false;
    }
    changeCollapsed(newValue) {
        this.isCollapsed = newValue;
        this.showEllipsis = newValue;
    }
}
ActionPlanComponent.ɵfac = function ActionPlanComponent_Factory(t) { return new (t || ActionPlanComponent)(i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.NgbModal)); };
ActionPlanComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ActionPlanComponent, selectors: [["action-plan"]], inputs: { actionPlan: "actionPlan", showAll: "showAll", isCollapsed: "isCollapsed", showScroll: "showScroll", isActionPlanList: "isActionPlanList", showEllipsis: "showEllipsis" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 7, vars: 9, consts: [[1, "olha-theme", 2, "word-break", "break-word"], ["class", "d-flex justify-content-between mb-3", 4, "ngIf"], [4, "ngIf"], [3, "action-plan-container", 4, "ngIf"], [1, "text-center"], ["class", "btn btn-link d-inline d-md-none ml-2", "jhiTranslate", "inukaNgApp.indexUser.viewDetails", 3, "click", 4, "ngIf"], ["class", "btn btn-link d-inline d-md-none ml-2", "jhiTranslate", "inukaNgApp.indexUser.viewLessDetails", 3, "click", 4, "ngIf"], [1, "d-flex", "justify-content-between", "mb-3"], ["jhiTranslate", "inukaNgApp.indexUser.myWellbeing", 1, "d-inline-block"], ["class", "btn btn-link d-none d-md-inline-block ml-4", "jhiTranslate", "inukaNgApp.indexUser.viewDetails", 3, "click", 4, "ngIf"], ["jhiTranslate", "inukaNgApp.indexUser.viewDetails", 1, "btn", "btn-link", "d-none", "d-md-inline-block", "ml-4", 3, "click"], ["jhiTranslate", "inukaNgApp.indexUser.wellbeingPlan", 1, "p-3", "mt-3", "text-light", "text-center"], ["jhiTranslate", "inukaNgApp.indexUser.myLife"], [3, "selected-action-plan-item", 4, "ngFor", "ngForOf"], [3, "innerHtml", "showEllipsis"], ["jhiTranslate", "inukaNgApp.indexUser.theChange"], ["jhiTranslate", "inukaNgApp.indexUser.things"], ["jhiTranslate", "inukaNgApp.indexUser.step"], [1, "d-inline-block", 3, "innerHtml", "showEllipsis"], ["jhiTranslate", "inukaNgApp.indexUser.viewDetails", 1, "btn", "btn-link", "d-inline", "d-md-none", "ml-2", 3, "click"], ["jhiTranslate", "inukaNgApp.indexUser.viewLessDetails", 1, "btn", "btn-link", "d-inline", "d-md-none", "ml-2", 3, "click"]], template: function ActionPlanComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, ActionPlanComponent_div_1_Template, 6, 2, "div", 1);
        i0.ɵɵtemplate(2, ActionPlanComponent_ng_container_2_Template, 2, 0, "ng-container", 2);
        i0.ɵɵtemplate(3, ActionPlanComponent_div_3_Template, 3, 4, "div", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵtemplate(5, ActionPlanComponent_button_5_Template, 2, 0, "button", 5);
        i0.ɵɵtemplate(6, ActionPlanComponent_button_6_Template, 2, 0, "button", 6);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵclassProp("p-4", ctx.showAll)("limitHeight", !ctx.showAll && ctx.isCollapsed);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isActionPlanList);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !(ctx.actionPlan == null ? null : ctx.actionPlan.actionPlanItems == null ? null : ctx.actionPlan.actionPlanItems.length));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.actionPlan == null ? null : ctx.actionPlan.actionPlanItems == null ? null : ctx.actionPlan.actionPlanItems.length);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.showAll && ctx.isCollapsed);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.showAll && !ctx.isCollapsed);
    } }, directives: [i3.NgIf, i4.JhiTranslateDirective, i3.NgForOf, i5.AutoDirectionSwitchComponent], styles: [".action-plan-container[_ngcontent-%COMP%]{max-height:20rem;overflow-y:scroll;margin-bottom:1rem}.limitHeight[_ngcontent-%COMP%]{max-height:10.5rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.selected-action-plan-item[_ngcontent-%COMP%]{font-weight:bold}auto-direction-switch[_ngcontent-%COMP%]{vertical-align:text-top !important}"], changeDetection: 0 });
