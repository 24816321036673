import { WellbeingResultCardComponent } from '../../../entities/index-user/wellbeing-result-card/wellbeing-result-card.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/common";
import * as i3 from "../../../ng-jhipster/language/jhi-translate.directive";
function WellbeingResultBadgeComponent_span_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
} }
function WellbeingResultBadgeComponent_span_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 7);
} }
function WellbeingResultBadgeComponent_span_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
} }
export class WellbeingResultBadgeComponent extends WellbeingResultCardComponent {
    constructor(modalService) {
        super(modalService);
        this.modalService = modalService;
    }
    set wellbeingResult(value) {
        this._wellbeingResult = value;
        if (value) {
            this.totalScore = value.score;
            this.determineTotalScoreStage(value.score);
        }
    }
    get wellbeingResult() {
        return this._wellbeingResult;
    }
    ngOnInit() {
    }
}
WellbeingResultBadgeComponent.ɵfac = function WellbeingResultBadgeComponent_Factory(t) { return new (t || WellbeingResultBadgeComponent)(i0.ɵɵdirectiveInject(i1.NgbModal)); };
WellbeingResultBadgeComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WellbeingResultBadgeComponent, selectors: [["wellbeing-result-badge"]], inputs: { wellbeingResult: "wellbeingResult" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 7, vars: 16, consts: [[1, "wellbeing-result-badge"], [1, "wellbeing-result-badge-score", "h5"], [1, "wellbeing-result-badge-title"], ["class", "h6", "jhiTranslate", "wellBeingResult.stages.atTough.title", 4, "ngIf"], ["class", "h6", "jhiTranslate", "wellBeingResult.stages.atRisk.title", 4, "ngIf"], ["class", "h6", "jhiTranslate", "wellBeingResult.stages.resilient.title", 4, "ngIf"], ["jhiTranslate", "wellBeingResult.stages.atTough.title", 1, "h6"], ["jhiTranslate", "wellBeingResult.stages.atRisk.title", 1, "h6"], ["jhiTranslate", "wellBeingResult.stages.resilient.title", 1, "h6"]], template: function WellbeingResultBadgeComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "span", 1);
        i0.ɵɵtext(2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵtemplate(4, WellbeingResultBadgeComponent_span_4_Template, 1, 0, "span", 3);
        i0.ɵɵtemplate(5, WellbeingResultBadgeComponent_span_5_Template, 1, 0, "span", 4);
        i0.ɵɵtemplate(6, WellbeingResultBadgeComponent_span_6_Template, 1, 0, "span", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵclassProp("bg-danger", ctx.isAtTough)("bg-warning", ctx.isAtRisk)("bg-success", ctx.isResilient);
        i0.ɵɵadvance(1);
        i0.ɵɵclassProp("text-danger", ctx.isAtTough)("text-warning", ctx.isAtRisk)("text-success", ctx.isResilient);
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate(ctx.totalScore);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.isAtTough);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isAtRisk);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isResilient);
    } }, directives: [i2.NgIf, i3.JhiTranslateDirective], styles: [".wellbeing-result-badge[_ngcontent-%COMP%]{height:27px;width:140px;border-radius:.3rem;display:inline-block;padding:.2rem}.wellbeing-result-badge-score[_ngcontent-%COMP%]{background-color:#fff;border-radius:.2rem;display:inline-block;width:1.2rem;text-align:center}.wellbeing-result-badge-title[_ngcontent-%COMP%]{float:right;margin-right:.3rem}.wellbeing-result-badge-title[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]{color:#fff}"] });
