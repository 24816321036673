import * as i0 from "@angular/core";
import * as i1 from "../../language/jhi-translate.directive";
import * as i2 from "@angular/common";
function JhiMetricsDatasourceComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "table", 3);
    i0.ɵɵelementStart(2, "thead");
    i0.ɵɵelementStart(3, "tr");
    i0.ɵɵelementStart(4, "th");
    i0.ɵɵelementStart(5, "span", 4);
    i0.ɵɵtext(6, "Connection Pool Usage");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "th", 5);
    i0.ɵɵtext(9, "Count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "th", 6);
    i0.ɵɵtext(11, "Mean");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "th", 7);
    i0.ɵɵtext(13, "Min");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "th", 8);
    i0.ɵɵtext(15, "p50");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "th", 9);
    i0.ɵɵtext(17, "p75");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(18, "th", 10);
    i0.ɵɵtext(19, "p95");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "th", 11);
    i0.ɵɵtext(21, "p99");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(22, "th", 12);
    i0.ɵɵtext(23, "Max");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(24, "tbody");
    i0.ɵɵelementStart(25, "tr");
    i0.ɵɵelementStart(26, "td");
    i0.ɵɵtext(27, "Acquire");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(28, "td", 13);
    i0.ɵɵtext(29);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(30, "td", 13);
    i0.ɵɵtext(31);
    i0.ɵɵpipe(32, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(33, "td", 13);
    i0.ɵɵtext(34);
    i0.ɵɵpipe(35, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(36, "td", 13);
    i0.ɵɵtext(37);
    i0.ɵɵpipe(38, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(39, "td", 13);
    i0.ɵɵtext(40);
    i0.ɵɵpipe(41, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(42, "td", 13);
    i0.ɵɵtext(43);
    i0.ɵɵpipe(44, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(45, "td", 13);
    i0.ɵɵtext(46);
    i0.ɵɵpipe(47, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(48, "td", 13);
    i0.ɵɵtext(49);
    i0.ɵɵpipe(50, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(51, "tr");
    i0.ɵɵelementStart(52, "td");
    i0.ɵɵtext(53, "Creation");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(54, "td", 13);
    i0.ɵɵtext(55);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(56, "td", 13);
    i0.ɵɵtext(57);
    i0.ɵɵpipe(58, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(59, "td", 13);
    i0.ɵɵtext(60);
    i0.ɵɵpipe(61, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(62, "td", 13);
    i0.ɵɵtext(63);
    i0.ɵɵpipe(64, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(65, "td", 13);
    i0.ɵɵtext(66);
    i0.ɵɵpipe(67, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(68, "td", 13);
    i0.ɵɵtext(69);
    i0.ɵɵpipe(70, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(71, "td", 13);
    i0.ɵɵtext(72);
    i0.ɵɵpipe(73, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(74, "td", 13);
    i0.ɵɵtext(75);
    i0.ɵɵpipe(76, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(77, "tr");
    i0.ɵɵelementStart(78, "td");
    i0.ɵɵtext(79, "Usage");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(80, "td", 13);
    i0.ɵɵtext(81);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(82, "td", 13);
    i0.ɵɵtext(83);
    i0.ɵɵpipe(84, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(85, "td", 13);
    i0.ɵɵtext(86);
    i0.ɵɵpipe(87, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(88, "td", 13);
    i0.ɵɵtext(89);
    i0.ɵɵpipe(90, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(91, "td", 13);
    i0.ɵɵtext(92);
    i0.ɵɵpipe(93, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(94, "td", 13);
    i0.ɵɵtext(95);
    i0.ɵɵpipe(96, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(97, "td", 13);
    i0.ɵɵtext(98);
    i0.ɵɵpipe(99, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(100, "td", 13);
    i0.ɵɵtext(101);
    i0.ɵɵpipe(102, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(7);
    i0.ɵɵtextInterpolate4(" (active: ", ctx_r0.datasourceMetrics.active.value, ", min: ", ctx_r0.datasourceMetrics.min.value, ", max: ", ctx_r0.datasourceMetrics.max.value, ", idle: ", ctx_r0.datasourceMetrics.idle.value, ") ");
    i0.ɵɵadvance(22);
    i0.ɵɵtextInterpolate(ctx_r0.datasourceMetrics.acquire.count);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(32, 28, ctx_r0.filterNaN(ctx_r0.datasourceMetrics.acquire.mean), "1.0-2"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(35, 31, ctx_r0.datasourceMetrics.acquire["0.0"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(38, 34, ctx_r0.datasourceMetrics.acquire["0.5"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(41, 37, ctx_r0.datasourceMetrics.acquire["0.75"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(44, 40, ctx_r0.datasourceMetrics.acquire["0.95"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(47, 43, ctx_r0.datasourceMetrics.acquire["0.99"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(50, 46, ctx_r0.filterNaN(ctx_r0.datasourceMetrics.acquire.max), "1.0-2"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.datasourceMetrics.creation.count);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(58, 49, ctx_r0.filterNaN(ctx_r0.datasourceMetrics.creation.mean), "1.0-2"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(61, 52, ctx_r0.datasourceMetrics.creation["0.0"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(64, 55, ctx_r0.datasourceMetrics.creation["0.5"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(67, 58, ctx_r0.datasourceMetrics.creation["0.75"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(70, 61, ctx_r0.datasourceMetrics.creation["0.95"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(73, 64, ctx_r0.datasourceMetrics.creation["0.99"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(76, 67, ctx_r0.filterNaN(ctx_r0.datasourceMetrics.creation.max), "1.0-2"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.datasourceMetrics.usage.count);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(84, 70, ctx_r0.filterNaN(ctx_r0.datasourceMetrics.usage.mean), "1.0-2"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(87, 73, ctx_r0.datasourceMetrics.usage["0.0"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(90, 76, ctx_r0.datasourceMetrics.usage["0.5"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(93, 79, ctx_r0.datasourceMetrics.usage["0.75"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(96, 82, ctx_r0.datasourceMetrics.usage["0.95"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(99, 85, ctx_r0.datasourceMetrics.usage["0.99"], "1.0-3"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(102, 88, ctx_r0.filterNaN(ctx_r0.datasourceMetrics.usage.max), "1.0-2"));
} }
export class JhiMetricsDatasourceComponent {
    filterNaN(input) {
        if (isNaN(input)) {
            return 0;
        }
        return input;
    }
}
JhiMetricsDatasourceComponent.ɵfac = function JhiMetricsDatasourceComponent_Factory(t) { return new (t || JhiMetricsDatasourceComponent)(); };
JhiMetricsDatasourceComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiMetricsDatasourceComponent, selectors: [["jhi-metrics-datasource"]], inputs: { datasourceMetrics: "datasourceMetrics", updating: "updating" }, decls: 3, vars: 1, consts: [["jhiTranslate", "metrics.datasource.title"], ["class", "table-responsive", 4, "ngIf"], [1, "table-responsive"], [1, "table", "table-striped"], ["jhiTranslate", "metrics.datasource.usage"], ["jhiTranslate", "metrics.datasource.count", 1, "text-right"], ["jhiTranslate", "metrics.datasource.mean", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.min", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.p50", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.p75", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.p95", 1, "text-right"], ["jhiTranslate", "metrics.servicesstats.table.p99", 1, "text-right"], ["jhiTranslate", "metrics.datasource.max", 1, "text-right"], [1, "text-right"]], template: function JhiMetricsDatasourceComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "h3", 0);
        i0.ɵɵtext(1, "DataSource statistics (time in millisecond)");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, JhiMetricsDatasourceComponent_div_2_Template, 103, 91, "div", 1);
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.updating);
    } }, directives: [i1.JhiTranslateDirective, i2.NgIf], pipes: [i2.DecimalPipe], encapsulation: 2 });
