import { CoachSlots } from '../../../session/schedule-session/available-coach-slots/coach-slots.model';
export class DayAvailableCoachSlots {
    constructor(day) {
        this.day = day;
        this.coachesSlots = [];
    }
    addCoachSlot(coach, sessionStartTime, coachDetails, coachReasonings) {
        let coachSlots = this.coachesSlots.find(cs => cs.coach.id === coach.id);
        if (!coachSlots) {
            coachSlots = new CoachSlots(coach, [], coachDetails, coachReasonings);
            this.coachesSlots.push(coachSlots);
        }
        coachSlots.sessionStartTimes.push(sessionStartTime);
    }
    set(other) {
        this.coachesSlots = other.coachesSlots;
        this.day = other.day;
    }
}
