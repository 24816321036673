import { EventEmitter } from '@angular/core';
import { DialogComponent } from '../dialog.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@fortawesome/angular-fontawesome";
const _c0 = ["*"];
export class ModalComponent extends DialogComponent {
    constructor(router, activeModal, modalService) {
        super(activeModal);
        this.router = router;
        this.activeModal = activeModal;
        this.modalService = modalService;
        this.dismissed = new EventEmitter();
    }
    ngOnInit() { }
    dismissHandler() {
        this.modalService.dismissAll();
    }
}
ModalComponent.ɵfac = function ModalComponent_Factory(t) { return new (t || ModalComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.NgbActiveModal), i0.ɵɵdirectiveInject(i2.NgbModal)); };
ModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ModalComponent, selectors: [["modal-component"]], inputs: { titleText: "titleText", navigateToQueryParams: "navigateToQueryParams" }, outputs: { dismissed: "dismissed" }, features: [i0.ɵɵInheritDefinitionFeature], ngContentSelectors: _c0, decls: 13, vars: 1, consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "data-dismiss", "modal", "aria-hidden", "true", 1, "close", 3, "click"], [1, "modal-body"], [1, "modal-footer"], ["type", "button", "data-dismiss", "modal", 1, "btn", "btn-secondary", 3, "click"], ["icon", "check"]], template: function ModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "h4", 1);
        i0.ɵɵtext(2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "button", 2);
        i0.ɵɵlistener("click", function ModalComponent_Template_button_click_3_listener() { return ctx.cancel(); });
        i0.ɵɵtext(4, "\u00D7");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 3);
        i0.ɵɵprojection(6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 4);
        i0.ɵɵelementStart(8, "button", 5);
        i0.ɵɵlistener("click", function ModalComponent_Template_button_click_8_listener() { return ctx.dismissHandler(); });
        i0.ɵɵelement(9, "fa-icon", 6);
        i0.ɵɵtext(10, "\u00A0 ");
        i0.ɵɵelementStart(11, "span");
        i0.ɵɵtext(12, "OK");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate(ctx.titleText);
    } }, directives: [i3.FaIconComponent], styles: [""] });
