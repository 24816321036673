import moment from "moment/moment";
import { DayAvailableCoachSlots } from '../../../session/schedule-session/available-coach-slots/day-available-coach-slots.model';
import { WeekDay } from '../../../session/schedule-session/available-coach-slots/week-day';
export class AvailableCoachSlotsCalendar {
    constructor(availableCoachSlots, availablePeriodStart, mode, timezone) {
        this.availableCoachSlots = availableCoachSlots;
        this.availablePeriodStart = availablePeriodStart;
        this.mode = mode;
        this.timezone = timezone;
        this.weeks = [];
        this.weekDays = [];
        this.dayAvailabilitiesUnixDateMap = new Map();
        if (availablePeriodStart) {
            this.updateNextPeriodStaringDay();
            this.updatePreviousPeriodStaringDay();
        }
        this.initWeekDays();
        this.initWeeks();
        if (availableCoachSlots) {
            this.init(availableCoachSlots);
        }
    }
    initWeekDays() {
        for (let i = 1; i <= 7; i++) {
            this.weekDays.push(new WeekDay(i));
        }
    }
    init(availableCoachSlots) {
        var _a;
        if ((_a = availableCoachSlots === null || availableCoachSlots === void 0 ? void 0 : availableCoachSlots.days) === null || _a === void 0 ? void 0 : _a.length) {
            this.hasAnyAvailableSlot = true;
            for (const dayAvailability of availableCoachSlots.days) {
                let dayAvailableCoachSlots = this.dayAvailabilitiesUnixDateMap.get(dayAvailability.day.unix());
                if (dayAvailableCoachSlots) {
                    dayAvailableCoachSlots.set(dayAvailability);
                }
            }
            this.fillWeeksEmptyDays();
        }
    }
    fillWeeksEmptyDays() {
        var _a, _b;
        // first week
        if (this.weeks.length && ((_a = this.weeks[0]) === null || _a === void 0 ? void 0 : _a.length) < 7) {
            while (this.weeks[0].length < 7) {
                this.weeks[0].unshift(new DayAvailableCoachSlots());
            }
        }
        for (let i = 0; i < 7; i++) {
            if (!this.weeks[0][i]) {
                this.weeks[0][i] = new DayAvailableCoachSlots();
            }
        }
        // last week
        if (this.weeks.length > 1 && ((_b = this.weeks[this.weeks.length - 1]) === null || _b === void 0 ? void 0 : _b.length) < 7) {
            while (this.weeks[this.weeks.length - 1].length < 7) {
                this.weeks[this.weeks.length - 1].push(new DayAvailableCoachSlots());
            }
        }
        for (let i = 0; i < 7; i++) {
            if (!this.weeks[this.weeks.length - 1][i]) {
                this.weeks[this.weeks.length - 1][i] = new DayAvailableCoachSlots();
            }
        }
    }
    fillTheGapFromPeriodStartTime(availableCoachSlots) {
        const firstAvailableDay = availableCoachSlots.days[0].day;
        if (this.availablePeriodStart.unix() < firstAvailableDay.unix()) {
            let gap = moment(this.availablePeriodStart).startOf("day");
            while (gap.unix() < firstAvailableDay.unix()) {
                const emptyDayAvailability = new DayAvailableCoachSlots(gap);
                this.addToWeeks(emptyDayAvailability);
                gap = moment(gap).add(1, "day");
            }
        }
    }
    initWeeks() {
        let firstDayOfPeriod;
        let lastDayOfPeriod;
        if (this.mode === "weekly") {
            firstDayOfPeriod = moment(this.getPreviousWeekStartingDay()).add(7, 'day');
            lastDayOfPeriod = moment(this.getNextWeekStartingDay()).add(-1, 'day');
        }
        else if (this.mode === "monthly") {
            firstDayOfPeriod = moment(this.getPreviousMonthStartingDay()).add(1, 'month');
            lastDayOfPeriod = moment(this.getNextMonthStartingDay()).add(-1, 'day');
        }
        let gap = moment(firstDayOfPeriod).startOf("day");
        while (gap.unix() <= lastDayOfPeriod.unix()) {
            const emptyDayAvailability = new DayAvailableCoachSlots(gap);
            this.addToWeeks(emptyDayAvailability);
            this.dayAvailabilitiesUnixDateMap.set(gap.unix(), emptyDayAvailability);
            gap = moment(gap).add(1, "day");
        }
    }
    addToWeeks(dayAvailability) {
        if (this.isInRange(dayAvailability.day, this.mode)) {
            let week;
            if (this.isMonday(dayAvailability.day) || this.weeks.length === 0) {
                week = this.initWeek(dayAvailability.day);
                this.weeks.push(week);
            }
            else {
                week = this.weeks[this.weeks.length - 1];
            }
            week[dayAvailability.day.isoWeekday() - 1] = dayAvailability;
        }
    }
    initWeek(date) {
        let week = [];
        for (let i = 1; i <= 7; i++) {
            const day = moment(date).isoWeekday(i);
            if (this.isInRange(day, this.mode)) {
                week[i - 1] = new DayAvailableCoachSlots(day);
            }
        }
        return week;
    }
    isInRange(day, mode) {
        if (mode === "weekly" && day.isBefore(this.getNextWeekStartingDay())) {
            return true;
        }
        else if (mode === "monthly" && day.month() === this.availablePeriodStart.month()) {
            return true;
        }
        else {
            return false;
        }
    }
    isMonday(day) {
        if ((day === null || day === void 0 ? void 0 : day.isoWeekday()) === 1) {
            return true;
        }
        else {
            return false;
        }
    }
    static getTotalDaysInMonth(availablePeriodStart, timezone) {
        return moment(availablePeriodStart).tz(timezone).daysInMonth();
    }
    static getCurrentMonthStartingDay(timezone) {
        return moment().startOf('month').tz(timezone);
    }
    static getCurrentWeekStartingDay(timezone) {
        return moment().startOf('isoWeek').tz(timezone);
    }
    static getDefaultAutoLookupTries(mode) {
        if (mode === "weekly") {
            return 4;
        }
        else { // monthly
            return 1;
        }
    }
    static getMaxAutoLookupTriesExtension(mode) {
        if (mode === "weekly") {
            return 20; // six month
        }
        else { // monthly
            return 4;
        }
    }
    static extendAutoLookupTries(mode, currentAutoLookupTries) {
        return currentAutoLookupTries + this.getMaxAutoLookupTriesExtension(mode);
    }
    getNextWeekStartingDay() {
        return moment(this.availablePeriodStart).startOf('isoWeek').tz(this.timezone).add(1, 'weeks');
    }
    getNextMonthStartingDay() {
        return moment(this.availablePeriodStart).startOf('month').tz(this.timezone).add(1, 'months');
    }
    getPreviousWeekStartingDay() {
        return moment(this.availablePeriodStart).startOf('isoWeek').tz(this.timezone).add(-1, 'weeks');
    }
    getPreviousMonthStartingDay() {
        return moment(this.availablePeriodStart).startOf('month').tz(this.timezone).add(-1, 'months');
    }
    updateNextPeriodStaringDay() {
        if (this.mode === "weekly") {
            this.nextPeriodStaringDay = this.getNextWeekStartingDay();
        }
        else { // monthly
            this.nextPeriodStaringDay = this.getNextMonthStartingDay();
        }
    }
    updatePreviousPeriodStaringDay() {
        this.previousPeriodStaringDay = null;
        if (this.mode === "weekly") {
            const previousPeriodStaringDay = this.getPreviousWeekStartingDay();
            if (previousPeriodStaringDay.isSameOrAfter(AvailableCoachSlotsCalendar.getCurrentWeekStartingDay(this.timezone))) {
                this.previousPeriodStaringDay = previousPeriodStaringDay;
            }
        }
        else { // monthly
            const previousPeriodStaringDay = this.getPreviousMonthStartingDay();
            if (previousPeriodStaringDay.isSameOrAfter(AvailableCoachSlotsCalendar.getCurrentMonthStartingDay(this.timezone))) {
                this.previousPeriodStaringDay = previousPeriodStaringDay;
            }
        }
    }
    findByDate(date) {
        var _a, _b;
        for (const week of this.weeks) {
            for (const day of week) {
                if (((_a = day.day) === null || _a === void 0 ? void 0 : _a.date()) === (date === null || date === void 0 ? void 0 : date.date())) {
                    if ((_b = day.coachesSlots) === null || _b === void 0 ? void 0 : _b.length) {
                        return day;
                    }
                }
            }
        }
        return null;
    }
}
