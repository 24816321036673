import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { DatePipe } from "@angular/common";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/common";
export class SRQCompletedService {
    constructor(http, datePipe) {
        this.http = http;
        this.datePipe = datePipe;
        this.resourceUrl = SERVER_API_URL + 'api/srq-completeds';
        this.displayBarPlot = true;
        this.displayStackedBarPlot = false;
    }
    create(sRQCompleted) {
        const copy = this.convertDateFromClient(sRQCompleted);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(sRQCompleted) {
        const copy = this.convertDateFromClient(sRQCompleted);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    queryForManagement(req, searchParam, selectedRegions) {
        const options = createRequestOption(req);
        return this.http.post(`${this.resourceUrl}/searchParam`, { searchParam, selectedRegions }, { params: options, observe: 'response' });
    }
    findAllResultsByRegions(selectedRegions) {
        return this.http
            .post(`${this.resourceUrl}/all`, selectedRegions, { observe: 'response' })
            .pipe(map((res) => this.convertDateToString(res)));
    }
    convertDateToString(res) {
        if (res.body) {
            res.body.forEach((s) => {
                s.creationDate = this.datePipe.transform(s.creationDate, "yyyy-MM-ddThh:mm:ss");
                s.testTime = this.datePipe.transform(s.testTime, "yyyy-MM-ddThh:mm:ss");
            });
        }
        return res;
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(sRQCompleted) {
        const copy = Object.assign({}, sRQCompleted, {
            dateTaken: sRQCompleted.dateTaken && sRQCompleted.dateTaken.isValid() ? sRQCompleted.dateTaken.toJSON() : undefined
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.dateTaken = res.body.dateTaken ? moment(res.body.dateTaken) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((sRQCompleted) => {
                sRQCompleted.dateTaken = sRQCompleted.dateTaken ? moment(sRQCompleted.dateTaken) : undefined;
            });
        }
        return res;
    }
    srqHistory() {
        return this.http
            .get(`${this.resourceUrl}/current-user`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    srqHistoryByUser(userId) {
        return this.http
            .get(`${this.resourceUrl}/user-srq-results?userId=${userId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    getLastSrqResultOfCurrentUser(userId) {
        return this.http
            .get(`${this.resourceUrl}/last-srq-result?userId=${userId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    getBackgroundColor(score) {
        if (score === null || score === undefined) {
            console.log('no self-scan is taken.');
            return 'white';
        }
        else if (score > 13) {
            return '#A9D3C7';
        }
        else if (score > 3) {
            return '#EAD88C';
        }
        else if (score <= 3) {
            return '#B894AA';
        }
        else {
            console.log('invalid score');
        }
        return '';
    }
    getSrqResult(score) {
        if (score === null || score === undefined) {
            console.log('no self-scan is taken.');
            return 'noScore';
        }
        else if (score > 13) {
            return 'resilient';
        }
        else if (score > 3) {
            return 'atRisk';
        }
        else if (score <= 3) {
            return 'toughPlace';
        }
        else {
            console.log('invalid score');
        }
        return '';
    }
    toughPlace(score) {
        return score <= 3;
    }
    atRisk(score) {
        return score > 3 && score <= 13;
    }
    resilient(score) {
        return score > 13;
    }
}
SRQCompletedService.ɵfac = function SRQCompletedService_Factory(t) { return new (t || SRQCompletedService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DatePipe)); };
SRQCompletedService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SRQCompletedService, factory: SRQCompletedService.ɵfac, providedIn: 'root' });
