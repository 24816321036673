import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { DATE_FORMAT } from '../../shared/constants/input.constants';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserVoucherService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/user-vouchers';
        this.resourceUrl2 = SERVER_API_URL + 'api/user-vouchers/list';
        this.resourceUrl3 = SERVER_API_URL + 'api/voucher';
        this.resourceUrl4 = SERVER_API_URL + 'api/user-vouchers/list-by-voucher';
    }
    create(userVoucher) {
        const copy = this.convertDateFromClient(userVoucher);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(userVoucher) {
        const copy = this.convertDateFromClient(userVoucher);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    findList(id) {
        return this.http
            .get(`${this.resourceUrl2}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    findCodeList(id) {
        return this.http.get(`${this.resourceUrl}/code-list/${id}`, { observe: 'response' });
    }
    findListByVoucher(id) {
        return this.http
            .get(`${this.resourceUrl4}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    addCode(voucherCode, userId = 0) {
        return this.http
            .get(`${this.resourceUrl3}/add?code=${voucherCode}&userId=${userId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    convertDateFromClient(userVoucher) {
        const copy = Object.assign({}, userVoucher, {
            assignedAt: userVoucher.assignedAt && userVoucher.assignedAt.isValid() ? userVoucher.assignedAt.format(DATE_FORMAT) : undefined
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.assignedAt = res.body.assignedAt ? moment(res.body.assignedAt) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((userVoucher) => {
                userVoucher.assignedAt = userVoucher.assignedAt ? moment(userVoucher.assignedAt) : undefined;
            });
        }
        return res;
    }
}
UserVoucherService.ɵfac = function UserVoucherService_Factory(t) { return new (t || UserVoucherService)(i0.ɵɵinject(i1.HttpClient)); };
UserVoucherService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserVoucherService, factory: UserVoucherService.ɵfac, providedIn: 'root' });
