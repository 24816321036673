import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { map } from "rxjs/operators";
import { LANGUAGES } from '../../core/language/language.constants';
import { SessionStorageService } from "ngx-webstorage";
import { AlertModalComponent } from '../../shared/alert/alert-modal.component';
import { JhiLanguageService } from '../../ng-jhipster/language';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "ngx-webstorage";
import * as i4 from "../../ng-jhipster/language";
export class RegisterService {
    constructor(http, modalService, sessionStorage, languageService) {
        this.http = http;
        this.modalService = modalService;
        this.sessionStorage = sessionStorage;
        this.languageService = languageService;
        this.availableLanguages = LANGUAGES;
    }
    save(account) {
        account.langKey = this.languageService.getCurrentLanguage();
        console.log("account.langKey: " + account.langKey);
        console.log(account);
        const headers = new HttpHeaders().set('timeout', '60000');
        return this.http.post(SERVER_API_URL + 'api/register', account, { headers, responseType: 'text' });
    }
    registerAnonymous(account) {
        account.langKey = this.languageService.getCurrentLanguage();
        console.log("account.langKey: " + account.langKey);
        console.log(account);
        const headers = new HttpHeaders().set('timeout', '60000');
        return this.http.post(SERVER_API_URL + 'api/register', account, { headers, responseType: 'text' });
    }
    openAlertModal(message) {
        const modalRef = this.modalService.open(AlertModalComponent);
        modalRef.componentInstance.alertModalRef = modalRef;
        modalRef.componentInstance.language = this.languageService.getCurrentLanguage();
        modalRef.componentInstance.userAlertMessage = message;
    }
    generateUsername() {
        return this.http
            .get(SERVER_API_URL + 'api/generate-username', { responseType: 'text' })
            .pipe(map(response => response.toString()));
    }
}
RegisterService.ɵfac = function RegisterService_Factory(t) { return new (t || RegisterService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NgbModal), i0.ɵɵinject(i3.SessionStorageService), i0.ɵɵinject(i4.JhiLanguageService)); };
RegisterService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RegisterService, factory: RegisterService.ɵfac, providedIn: 'root' });
