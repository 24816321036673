import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { JhiAlertService } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/service";
export class NotificationInterceptor {
    constructor(alertService) {
        this.alertService = alertService;
    }
    intercept(request, next) {
        return next.handle(request).pipe(tap((event) => {
            if (event instanceof HttpResponse) {
                let alert = null;
                let alertParams = null;
                event.headers.keys().forEach(entry => {
                    if (entry.toLowerCase().endsWith('app-alert')) {
                        alert = event.headers.get(entry);
                    }
                    else if (entry.toLowerCase().endsWith('app-params')) {
                        alertParams = decodeURIComponent(event.headers.get(entry).replace(/\+/g, ' '));
                    }
                });
                if (alert) {
                    this.alertService.success(alert, { param: alertParams });
                }
            }
        }));
    }
}
NotificationInterceptor.ɵfac = function NotificationInterceptor_Factory(t) { return new (t || NotificationInterceptor)(i0.ɵɵinject(i1.JhiAlertService)); };
NotificationInterceptor.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NotificationInterceptor, factory: NotificationInterceptor.ɵfac });
