import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserOrganizationCodeService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/user-organization-codes';
    }
    create(userOrganizationCode) {
        const copy = this.convertDateFromClient(userOrganizationCode);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(userOrganizationCode) {
        const copy = this.convertDateFromClient(userOrganizationCode);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    findCodeById(id) {
        return this.http
            .get(`${this.resourceUrl}/by-code-id/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    findCodeCountByUserId(userId) {
        return this.http.get(`${this.resourceUrl}/count-by-user-id/${userId}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(userOrganizationCode) {
        return Object.assign({}, userOrganizationCode, { assignedAt: userOrganizationCode.assignedAt && userOrganizationCode.assignedAt.isValid() ? userOrganizationCode.assignedAt.toJSON() : undefined
        });
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.assignedAt = res.body.assignedAt ? moment(res.body.assignedAt) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((userOrganizationCode) => {
                userOrganizationCode.assignedAt = userOrganizationCode.assignedAt ? moment(userOrganizationCode.assignedAt) : undefined;
            });
        }
        return res;
    }
}
UserOrganizationCodeService.ɵfac = function UserOrganizationCodeService_Factory(t) { return new (t || UserOrganizationCodeService)(i0.ɵɵinject(i1.HttpClient)); };
UserOrganizationCodeService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserOrganizationCodeService, factory: UserOrganizationCodeService.ɵfac, providedIn: 'root' });
