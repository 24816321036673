import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { TrackerService } from '../../core/tracker/tracker.service';
import { JhiEventManager } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/tracker/tracker.service";
import * as i3 from "../../ng-jhipster/service";
export class UserInteractionService {
    constructor(http, trackerService, eventManager) {
        this.http = http;
        this.trackerService = trackerService;
        this.eventManager = eventManager;
        this.resourceUrl = SERVER_API_URL + 'api/user-interactions';
    }
    init() {
        this.subscribeToWebsocket();
    }
    subscribeToWebsocket() {
        if (this.websocketSubscription) {
            return;
        }
        this.websocketSubscription = this.trackerService.listenerSubject.subscribe(value => {
            const message = value['body'];
            const type = value['type'];
            if (type === 'SESSION_MESSAGE' || type === 'OFFLINE_MESSAGE_RECEIVED') {
                this.onMessageReceived(message);
            }
        });
    }
    onMessageReceived(message) {
        if (message.type === 'userInteractionStarted') {
            this.handleUserInteractionStarted(message.type);
        }
        else if (message.type === 'userInteractionFinished') {
            this.handleUserInteractionFinished(message.type);
        }
    }
    handleUserInteractionStarted(userInteractionType) {
        if (userInteractionType === "userInteractionStarted") {
            this.eventManager.broadcast('userInteractionStartedForConsentToShareOrganizationResource');
        }
    }
    handleUserInteractionFinished(userInteractionType) {
        if (userInteractionType === "userInteractionFinished") {
            this.eventManager.broadcast('userInteractionFinishedForConsentToShareOrganizationResource');
        }
    }
    startUserInteraction(type, userId) {
        return this.http
            .post(`${this.resourceUrl}/start/${type}/${userId}`, {}, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.startedDate = res.body.startedDate ? moment(res.body.startedDate) : undefined;
        }
        return res;
    }
}
UserInteractionService.ɵfac = function UserInteractionService_Factory(t) { return new (t || UserInteractionService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TrackerService), i0.ɵɵinject(i3.JhiEventManager)); };
UserInteractionService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserInteractionService, factory: UserInteractionService.ɵfac, providedIn: 'root' });
