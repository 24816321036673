import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@ctrl/ngx-emoji-mart";
const _c0 = function () { return { "border": "none", "z-index": 2000, "position": "static" }; };
function MessageReactionSelectorComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵlistener("click", function MessageReactionSelectorComponent_ng_container_3_Template_div_click_1_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵelementStart(3, "span", 6);
    i0.ɵɵlistener("click", function MessageReactionSelectorComponent_ng_container_3_Template_span_click_3_listener() { i0.ɵɵrestoreView(_r3); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.selectReaction("\uD83D\uDC4D"); });
    i0.ɵɵtext(4, "\uD83D\uDC4D");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "span", 7);
    i0.ɵɵlistener("click", function MessageReactionSelectorComponent_ng_container_3_Template_span_click_5_listener() { i0.ɵɵrestoreView(_r3); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.selectReaction("\u2764"); });
    i0.ɵɵtext(6, "\u2764");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "span", 6);
    i0.ɵɵlistener("click", function MessageReactionSelectorComponent_ng_container_3_Template_span_click_7_listener() { i0.ɵɵrestoreView(_r3); const ctx_r6 = i0.ɵɵnextContext(); return ctx_r6.selectReaction("\uD83D\uDE02"); });
    i0.ɵɵtext(8, "\uD83D\uDE02");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "span", 6);
    i0.ɵɵlistener("click", function MessageReactionSelectorComponent_ng_container_3_Template_span_click_9_listener() { i0.ɵɵrestoreView(_r3); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.selectReaction("\uD83D\uDE2E"); });
    i0.ɵɵtext(10, "\uD83D\uDE2E");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "span", 6);
    i0.ɵɵlistener("click", function MessageReactionSelectorComponent_ng_container_3_Template_span_click_11_listener() { i0.ɵɵrestoreView(_r3); const ctx_r8 = i0.ɵɵnextContext(); return ctx_r8.selectReaction("\uD83D\uDE25"); });
    i0.ɵɵtext(12, "\uD83D\uDE25");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "span", 6);
    i0.ɵɵlistener("click", function MessageReactionSelectorComponent_ng_container_3_Template_span_click_13_listener() { i0.ɵɵrestoreView(_r3); const ctx_r9 = i0.ɵɵnextContext(); return ctx_r9.selectReaction("\uD83D\uDE4F"); });
    i0.ɵɵtext(14, "\uD83D\uDE4F");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "div", 8, 9);
    i0.ɵɵelementStart(17, "span", 10);
    i0.ɵɵelement(18, "fa-icon", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(19, "div", 12);
    i0.ɵɵelementStart(20, "emoji-mart", 13);
    i0.ɵɵlistener("emojiClick", function MessageReactionSelectorComponent_ng_container_3_Template_emoji_mart_emojiClick_20_listener($event) { i0.ɵɵrestoreView(_r3); const _r1 = i0.ɵɵreference(16); const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.selectReactionFromFullSelector($event, _r1); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵclassProp("message-reaction-selected-emoji", (ctx_r0.message == null ? null : ctx_r0.message.receiverReaction) === "\uD83D\uDC4D");
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("message-reaction-selected-emoji", (ctx_r0.message == null ? null : ctx_r0.message.receiverReaction) === "\u2764");
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("message-reaction-selected-emoji", (ctx_r0.message == null ? null : ctx_r0.message.receiverReaction) === "\uD83D\uDE02");
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("message-reaction-selected-emoji", (ctx_r0.message == null ? null : ctx_r0.message.receiverReaction) === "\uD83D\uDE2E");
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("message-reaction-selected-emoji", (ctx_r0.message == null ? null : ctx_r0.message.receiverReaction) === "\uD83D\uDE25");
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("message-reaction-selected-emoji", (ctx_r0.message == null ? null : ctx_r0.message.receiverReaction) === "\uD83D\uDE4F");
    i0.ɵɵadvance(7);
    i0.ɵɵstyleMap(i0.ɵɵpureFunction0(18, _c0));
    i0.ɵɵproperty("skin", 6)("hideObsolete", true)("showSingleCategory", true)("perLine", 8);
} }
export class MessageReactionSelectorComponent {
    constructor() {
        this.onReactionSelected = new EventEmitter();
        this.view = 'addButton';
    }
    ngOnInit() {
    }
    openSmallSelector() {
        this.view = 'smallSelector';
    }
    close() {
        this.view = 'addButton';
    }
    selectReaction(emoji) {
        console.log('selected: ' + emoji);
        if (emoji === this.message.receiverReaction) {
            emoji = null;
        }
        this.onReactionSelected.emit(emoji);
        this.close();
    }
    selectReactionFromFullSelector(emojiClickedEvent, dropdown) {
        console.log(emojiClickedEvent);
        if (emojiClickedEvent) {
            this.selectReaction(emojiClickedEvent.emoji.native);
            dropdown.close();
        }
    }
}
MessageReactionSelectorComponent.ɵfac = function MessageReactionSelectorComponent_Factory(t) { return new (t || MessageReactionSelectorComponent)(); };
MessageReactionSelectorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: MessageReactionSelectorComponent, selectors: [["message-reaction-selector"]], inputs: { message: "message" }, outputs: { onReactionSelected: "onReactionSelected" }, decls: 4, vars: 1, consts: [[1, "message-reaction-selector-add-button", 3, "click"], ["width", "19px", "height", "19px"], [0, "xlink", "href", "../../../../../../../../content/images/sprite.svg?v=3#smiling-face-empty"], [4, "ngIf"], [1, "message-reaction-selector-overlay", 3, "click"], [1, "small-message-reaction-selector-container", "d-flex", "justify-content-between"], [1, "small-message-reaction-selector-emoji", 3, "click"], [1, "small-message-reaction-selector-emoji", "message-reaction-selected-emoji", 3, "click"], ["ngbDropdown", "", "container", "'body'", "placement", "top-right", 1, "emoji-dropdown"], ["emojiDropdown", "ngbDropdown"], ["ngbDropdownToggle", "", 1, "full-emoji-selector-toggler"], ["icon", "plus"], ["ngbDropdownMenu", "", 2, "border", "none", "box-shadow", "0 2px 6px rgba(0,0,0,.2)"], ["title", "Pick your emoji", "isNative", "true", "emoji", "point_up", 3, "skin", "hideObsolete", "showSingleCategory", "perLine", "emojiClick"]], template: function MessageReactionSelectorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "span", 0);
        i0.ɵɵlistener("click", function MessageReactionSelectorComponent_Template_span_click_0_listener() { return ctx.openSmallSelector(); });
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(1, "svg", 1);
        i0.ɵɵelement(2, "use", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(3, MessageReactionSelectorComponent_ng_container_3_Template, 21, 19, "ng-container", 3);
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.view === "smallSelector");
    } }, directives: [i1.NgIf, i2.NgbDropdown, i2.NgbDropdownToggle, i3.FaIconComponent, i2.NgbDropdownMenu, i4.PickerComponent], styles: [".message-reaction-selector-add-button[_ngcontent-%COMP%]{cursor:pointer;margin-left:1px}.message-reaction-selector-overlay[_ngcontent-%COMP%]{position:fixed;top:0;left:0;width:100%;height:100%;z-index:2000}.small-message-reaction-selector-container[_ngcontent-%COMP%]{z-index:3000;background-color:#fff;border-radius:30px;box-shadow:0 2px 6px rgba(0,0,0,.2);position:absolute;transform:translate3d(0, 0, 0);width:19.2rem;top:-60px;right:-10px}.small-message-reaction-selector-emoji[_ngcontent-%COMP%], .full-emoji-selector-toggler[_ngcontent-%COMP%]{cursor:pointer;font-size:1.5rem}.message-reaction-selected-emoji[_ngcontent-%COMP%]{border-radius:50%;height:2.5rem;width:2.5rem;text-align:center;display:inline-block;background-color:var(--bg-light-green)}.full-emoji-selector-toggler[_ngcontent-%COMP%]{color:var(--main);border-radius:50%;width:2.5rem;height:2.5rem;display:inline-block;text-align:center;background-color:var(--bg-light-green)}"] });
