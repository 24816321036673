export class Script {
    constructor(id, category, chapter, language, region, sessionNumber, text, type) {
        this.id = id;
        this.category = category;
        this.chapter = chapter;
        this.language = language;
        this.region = region;
        this.sessionNumber = sessionNumber;
        this.text = text;
        this.type = type;
    }
}
