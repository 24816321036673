import { JhiLanguageService } from '../../../../ng-jhipster/language';
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "../../../../ng-jhipster/language";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../shared/auto-direction-switch/auto-direction-switch/auto-direction-switch.component";
function CoachReasoningComponent_span_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵelement(1, "auto-direction-switch", 1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHtml", ctx_r0.currentLanguageCoachReasoningText, i0.ɵɵsanitizeHtml);
} }
export class CoachReasoningComponent {
    constructor(languageService, translateService) {
        this.languageService = languageService;
        this.translateService = translateService;
        // this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
        //   this.updateCurrentLanguageCoachReasoning();
        // });
    }
    set coachReasoningByLang(value) {
        this._coachReasoningByLang = value;
        this.updateCurrentLanguageCoachReasoning();
    }
    get coachReasoningByLang() {
        return this._coachReasoningByLang;
    }
    ngOnInit() {
    }
    updateCurrentLanguageCoachReasoning() {
        if (!this.coachReasoningByLang.size) {
            return;
        }
        const currentLanguage = this.languageService.getCurrentLanguage();
        if (this.coachReasoningByLang.has(currentLanguage)) { // user selected language
            this.currentLanguageCoachReasoningText = '"' + this.coachReasoningByLang.get((currentLanguage)).coachReasoningText + '"';
        }
        else if (this.coachReasoningByLang.has('en')) { // english
            this.currentLanguageCoachReasoningText = '"' + this.coachReasoningByLang.get('en').coachReasoningText + '"';
        }
        else { // any other existing language
            this.currentLanguageCoachReasoningText = '"' + this.getFirstCoachReasoningText() + '"';
        }
    }
    getFirstCoachReasoningText() {
        for (const lang of Array.from(this.coachReasoningByLang.keys())) {
            return this.coachReasoningByLang.get(lang).coachReasoningText;
        }
        return null;
    }
}
CoachReasoningComponent.ɵfac = function CoachReasoningComponent_Factory(t) { return new (t || CoachReasoningComponent)(i0.ɵɵdirectiveInject(i1.JhiLanguageService), i0.ɵɵdirectiveInject(i2.TranslateService)); };
CoachReasoningComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: CoachReasoningComponent, selectors: [["coach-reasoning"]], inputs: { coachReasoningByLang: "coachReasoningByLang" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [3, "innerHtml"]], template: function CoachReasoningComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, CoachReasoningComponent_span_0_Template, 2, 1, "span", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.currentLanguageCoachReasoningText);
    } }, directives: [i3.NgIf, i4.AutoDirectionSwitchComponent], styles: [""] });
