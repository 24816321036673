import { isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { StateStorageService } from './state-storage.service';
import { LoginService } from '../login/login.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../login/login.service";
import * as i3 from "./account.service";
import * as i4 from "./state-storage.service";
export class UserRouteAccessService {
    constructor(router, loginService, accountService, stateStorageService) {
        this.router = router;
        this.loginService = loginService;
        this.accountService = accountService;
        this.stateStorageService = stateStorageService;
    }
    canActivate(route, state) {
        // console.log("canActivate(), component: " + route.component[name] + ", authorities: " + route.data['authorities'] + ", url: " + state.url);
        const authorities = route.data['authorities'];
        // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkLogin(authorities, state.url);
    }
    checkLogin(authorities, url) {
        return this.accountService.identity().pipe(map(account => {
            if (!authorities || authorities.length === 0) {
                return true;
            }
            if (account) {
                const hasAnyAuthority = this.accountService.hasAnyAuthority(authorities);
                if (hasAnyAuthority) {
                    return true;
                }
                if (isDevMode()) {
                    console.error('User has not any of required authorities: ', authorities);
                }
                // TODO consider using loginService.redirectToHomepage() service
                if (this.accountService.hasAnyAuthority(['ROLE_ORGANIZATION_ADMIN'])) {
                    location.href = '/#/organization-dashboard-index';
                }
                else if (this.accountService.hasAnyAuthority(['ROLE_COACH'])) {
                    // after coach schedules a session, coach is redirected to /#/index-user
                    location.href = '/#/index-coach';
                }
                else if (this.accountService.hasAnyAuthority(['ROLE_CUSTOMER_CARE'])) {
                    location.href = '/#/session/inbox/all';
                }
                else if (this.accountService.hasAnyAuthority(['ROLE_SUPERVISOR', 'ROLE_ADMIN', 'ROLE_MASTERMIND'])) {
                    // this block is added just in case
                    location.href = '/#/admin/session-management';
                }
                else {
                    this.router.navigate(['accessdenied']);
                }
                return false;
            }
            this.stateStorageService.storeUrl(url);
            this.router.navigate(['']);
            // this.loginModalService.open();
            return false;
        }));
    }
}
UserRouteAccessService.ɵfac = function UserRouteAccessService_Factory(t) { return new (t || UserRouteAccessService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LoginService), i0.ɵɵinject(i3.AccountService), i0.ɵɵinject(i4.StateStorageService)); };
UserRouteAccessService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserRouteAccessService, factory: UserRouteAccessService.ɵfac, providedIn: 'root' });
