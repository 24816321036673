import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from "@angular/forms";
import { UserService } from '../../core/user/user.service';
import { RegisterService } from '../../account/register/register.service';
import { COUNTRY_CALLING_CODES_AND_FLAGS } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/forms";
import * as i3 from "../../account/register/register.service";
import * as i4 from "../../core/user/user.service";
import * as i5 from "@angular/common";
import * as i6 from "@fortawesome/angular-fontawesome";
import * as i7 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "@angular/material/form-field";
import * as i9 from "@angular/material/select";
import * as i10 from "@angular/material/input";
import * as i11 from "@angular/material/core";
import * as i12 from "@ngx-translate/core";
function ContactMeModalComponent_div_1_mat_option_20_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 22);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 23);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const cc_r5 = ctx.$implicit;
    i0.ɵɵproperty("value", cc_r5);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(cc_r5.name);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(cc_r5.dialCode);
} }
function ContactMeModalComponent_div_1_mat_error_26_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, "register.messages.error.invalidPhonePattern"), " ");
} }
function ContactMeModalComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵelementStart(2, "fa-icon", 4);
    i0.ɵɵlistener("click", function ContactMeModalComponent_div_1_Template_fa_icon_click_2_listener() { i0.ɵɵrestoreView(_r7); const ctx_r6 = i0.ɵɵnextContext(); return ctx_r6.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 5);
    i0.ɵɵelementStart(4, "div", 6);
    i0.ɵɵelement(5, "img", 7);
    i0.ɵɵelement(6, "h3", 8);
    i0.ɵɵelement(7, "p", 9);
    i0.ɵɵelementStart(8, "form", 10);
    i0.ɵɵlistener("ngSubmit", function ContactMeModalComponent_div_1_Template_form_ngSubmit_8_listener() { i0.ɵɵrestoreView(_r7); const ctx_r8 = i0.ɵɵnextContext(); return ctx_r8.leaveYourNumber(); });
    i0.ɵɵelementStart(9, "div", 11);
    i0.ɵɵelementStart(10, "mat-form-field", 12);
    i0.ɵɵelementStart(11, "mat-label");
    i0.ɵɵtext(12);
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "mat-select", 13, 14);
    i0.ɵɵlistener("ngModelChange", function ContactMeModalComponent_div_1_Template_mat_select_ngModelChange_14_listener($event) { i0.ɵɵrestoreView(_r7); const ctx_r9 = i0.ɵɵnextContext(); return ctx_r9.callingCode = $event; });
    i0.ɵɵelementStart(16, "mat-select-trigger");
    i0.ɵɵtext(17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(18, "input", 15, 16);
    i0.ɵɵlistener("keyup", function ContactMeModalComponent_div_1_Template_input_keyup_18_listener() { i0.ɵɵrestoreView(_r7); const _r2 = i0.ɵɵreference(19); const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.filter(_r2); });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(20, ContactMeModalComponent_div_1_mat_option_20_Template, 5, 3, "mat-option", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(21, "mat-form-field", 18);
    i0.ɵɵelementStart(22, "mat-label");
    i0.ɵɵtext(23);
    i0.ɵɵpipe(24, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(25, "input", 19);
    i0.ɵɵtemplate(26, ContactMeModalComponent_div_1_mat_error_26_Template, 3, 3, "mat-error", 20);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelement(27, "button", 21);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(8);
    i0.ɵɵproperty("formGroup", ctx_r0.phoneNumberForm);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(13, 7, "register.placeholder.countryCode"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngModel", ctx_r0.callingCode);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r0.phoneNumberForm.controls["countryCode"].value.dialCode);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r0.filteredList);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(24, 9, "register.placeholder.telInput"));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.phoneNumberForm.get("phoneNumber").errors == null ? null : ctx_r0.phoneNumberForm.get("phoneNumber").errors.pattern);
} }
export class ContactMeModalComponent {
    constructor(activeModal, formBuilder, registerService, userService) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.registerService = registerService;
        this.userService = userService;
        this.callingCodes = COUNTRY_CALLING_CODES_AND_FLAGS;
        this.phonePattern = new RegExp('^[0-9]+$');
        this.phoneNumberForm = this.formBuilder.group({
            countryCode: [''],
            phoneNumber: ['', [Validators.pattern('^[_0-9]+$')]],
        });
    }
    ngOnInit() {
        this.filteredList = this.callingCodes;
        this.userService.getLoginCountry().subscribe(country => {
            this.callingCode = this.callingCodes.find(cc => country === cc.name);
        }, error => {
            console.log("ipStack error: " + JSON.stringify(error));
        });
    }
    close() {
        this.activeModal.dismiss();
    }
    filter(event) {
        let searchValue = event.value;
        this.filteredList = this.callingCodes.filter(cc => cc.name.includes(searchValue) ||
            cc.name.toLowerCase().includes(searchValue) ||
            cc.dialCode.includes(searchValue));
    }
    leaveYourNumber() {
        let phoneNumberWithoutPrefix = this.phoneNumberForm.get(['phoneNumber']).value;
        let phoneNumber = phoneNumberWithoutPrefix ? this.callingCode.dialCode + phoneNumberWithoutPrefix : null;
        if (phoneNumberWithoutPrefix && !this.phonePattern.test(phoneNumberWithoutPrefix)) {
            this.phoneNumberForm.get(['phoneNumber']).setErrors({ pattern: true });
        }
        if (this.phoneNumberForm.valid) {
            this.contactUserSubscription = this.userService.contactUser(phoneNumber).subscribe((response) => {
                console.log(response);
                this.contactUserModalRef.close();
            }, (error) => {
                console.log(error);
            });
        }
    }
}
ContactMeModalComponent.ɵfac = function ContactMeModalComponent_Factory(t) { return new (t || ContactMeModalComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal), i0.ɵɵdirectiveInject(i2.FormBuilder), i0.ɵɵdirectiveInject(i3.RegisterService), i0.ɵɵdirectiveInject(i4.UserService)); };
ContactMeModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ContactMeModalComponent, selectors: [["contact-me-modal"]], decls: 2, vars: 1, consts: [[1, "olha-theme"], ["class", "modal-body", 4, "ngIf"], [1, "modal-body"], [1, "d-flex", "justify-content-end"], ["icon", "times-circle", 1, "close-icon", 3, "click"], [1, "modal-div"], [1, "text-center"], ["src", "../../../../content/images/call-me.svg", "alt", "", 2, "width", "136px!important", "height", "154px!important"], ["jhiTranslate", "inukaNgApp.wellBeingResult2.howCoach.callMe"], ["jhiTranslate", "inukaNgApp.wellBeingResult2.howCoach.leaveYourNumber"], ["name", "form", "role", "form", 3, "formGroup", "ngSubmit"], [1, "d-flex", "justify-content-center"], ["hideRequiredMarker", "", "appearance", "outline", 1, "mr-3", 2, "max-width", "100px"], ["id", "dial-mat-select", "tabindex", "-1", "formControlName", "countryCode", "required", "", 3, "ngModel", "ngModelChange"], ["select", ""], ["id", "dial-code-search", 3, "keyup"], ["searchComponent", ""], [3, "value", 4, "ngFor", "ngForOf"], ["hideRequiredMarker", "", "appearance", "outline", 1, "w-100", 2, "min-width", "100px"], ["matInput", "", "formControlName", "phoneNumber", "type", "tel", "required", ""], [4, "ngIf"], ["type", "submit", "jhiTranslate", "inukaNgApp.wellBeingResult2.howCoach.submit", 1, "btn", "btn-primary", "mt-4"], [3, "value"], [2, "float", "right"]], template: function ContactMeModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, ContactMeModalComponent_div_1_Template, 28, 11, "div", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.callingCode);
    } }, directives: [i5.NgIf, i6.FaIconComponent, i7.JhiTranslateDirective, i2.ɵNgNoValidate, i2.NgControlStatusGroup, i2.FormGroupDirective, i8.MatFormField, i8.MatLabel, i9.MatSelect, i2.NgControlStatus, i2.FormControlName, i2.RequiredValidator, i9.MatSelectTrigger, i5.NgForOf, i10.MatInput, i2.DefaultValueAccessor, i11.MatOption, i8.MatError], pipes: [i12.TranslatePipe], styles: [".modal-div[_ngcontent-%COMP%]{padding:20px 40px 20px 40px}h3[_ngcontent-%COMP%]{margin-top:10px}p[_ngcontent-%COMP%]{margin-bottom:25px}button[_ngcontent-%COMP%]{height:50px;width:200px}#dial-mat-select[_ngcontent-%COMP%]{overflow-x:hidden}#dial-code-search[_ngcontent-%COMP%]{height:40px;border:1px solid #dfdfdf;border-radius:4px;padding:10px;position:sticky;top:10px;left:10px;right:10px;width:95%;z-index:100}.mat-form-field-appearance-outline[_ngcontent-%COMP%]   .mat-form-field-outline[_ngcontent-%COMP%]{color:var(--main) !important}[_nghost-%COMP%]  .mat-form-field-flex{max-height:53px}[_nghost-%COMP%]  #dial-mat-select-panel{width:312px}[_nghost-%COMP%]  .mat-form-field-infix{width:100px !important}[_nghost-%COMP%]  .mat-form-field-appearance-outline .mat-form-field-outline{color:#44a38d !important}"] });
