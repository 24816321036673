import { LANGUAGES } from '../../app.constants';
import * as i0 from "@angular/core";
export class FindLanguageEntityFromKeyPipe {
    constructor() {
        this.languages = LANGUAGES;
    }
    transform(lang) {
        if (lang === 'gb')
            lang = 'en';
        return this.languages.find(l => l.code === lang);
    }
}
FindLanguageEntityFromKeyPipe.ɵfac = function FindLanguageEntityFromKeyPipe_Factory(t) { return new (t || FindLanguageEntityFromKeyPipe)(); };
FindLanguageEntityFromKeyPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "findLanguageEntityFromKey", type: FindLanguageEntityFromKeyPipe, pure: true });
FindLanguageEntityFromKeyPipe.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FindLanguageEntityFromKeyPipe, factory: FindLanguageEntityFromKeyPipe.ɵfac, providedIn: 'root' });
