import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProblemDetail } from '../error/problem-detail.model';
import { ProblemDetailPopupComponent } from '../error/problem-detail-popup/problem-detail-popup.component';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
export class ErrorHandlerService {
    constructor(translateService, modalService) {
        this.translateService = translateService;
        this.modalService = modalService;
    }
    handleError(error) {
        console.log(error);
        const problemDetail = this.problemDetail(error);
        if (problemDetail) {
            const translatedProblemDetail = this.translate(problemDetail);
            if (translatedProblemDetail === null || translatedProblemDetail === void 0 ? void 0 : translatedProblemDetail.title) {
                this.showProblemDetailPopup(translatedProblemDetail);
            }
            else {
                console.warn(`Couldn't find the translation for error ${problemDetail.title}`);
            }
        }
        // handler other type of error, like network connection errors
    }
    problemDetail(error) {
        if (error.error && error.error.title && error.error.status) {
            return error.error;
        }
        return null;
    }
    translate(problemDetail) {
        const translatedProblemDetail = this.clone(problemDetail);
        translatedProblemDetail.title = this.translateTitle(problemDetail);
        translatedProblemDetail.detail = this.translateDetail(problemDetail);
        return translatedProblemDetail;
    }
    showProblemDetailPopup(problemDetail) {
        const modalRef = this.modalService.open(ProblemDetailPopupComponent, { size: "sm" });
        modalRef.componentInstance.problemDetail = problemDetail;
    }
    translateDetail(problemDetail) {
        if (problemDetail && problemDetail.title) {
            const detailTranslationKey = this.getDetailTranslationKey(problemDetail);
            if (detailTranslationKey) {
                try {
                    const translation = this.translateService.instant(detailTranslationKey);
                    if (translation && translation.length) {
                        return translation;
                    }
                }
                catch (_a) {
                }
            }
        }
        return null;
    }
    translateTitle(problemDetail) {
        if (problemDetail && problemDetail.title) {
            const titleTranslationKey = this.getTitleTranslationKey(problemDetail);
            if (titleTranslationKey) {
                try {
                    const translation = this.translateService.instant(titleTranslationKey);
                    if (translation && translation.length) {
                        return translation;
                    }
                }
                catch (_a) {
                }
            }
        }
        return null;
    }
    getTitleTranslationKey(problemDetail) {
        var _a;
        if (problemDetail && ((_a = problemDetail.title) === null || _a === void 0 ? void 0 : _a.length)) {
            return `error.${problemDetail.title}.title`;
        }
        return null;
    }
    getDetailTranslationKey(problemDetail) {
        var _a;
        if (problemDetail && ((_a = problemDetail.title) === null || _a === void 0 ? void 0 : _a.length)) {
            return `error.${problemDetail.title}.detail`;
        }
        return null;
    }
    clone(problemDetail) {
        return new ProblemDetail(problemDetail.title, problemDetail.detail);
    }
}
ErrorHandlerService.ɵfac = function ErrorHandlerService_Factory(t) { return new (t || ErrorHandlerService)(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.NgbModal)); };
ErrorHandlerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ErrorHandlerService, factory: ErrorHandlerService.ɵfac, providedIn: 'root' });
