import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DataSharingConsentService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/data-sharing-consents';
    }
    update(dataSharingConsent) {
        return this.http
            .put(this.resourceUrl, dataSharingConsent, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(type, userId) {
        return this.http.get(`${this.resourceUrl}/user?userId=${userId}&type=${type}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.consentDate = res.body.consentDate ? moment(res.body.consentDate) : undefined;
            res.body.revokeDate = res.body.revokeDate ? moment(res.body.revokeDate) : undefined;
        }
        return res;
    }
}
DataSharingConsentService.ɵfac = function DataSharingConsentService_Factory(t) { return new (t || DataSharingConsentService)(i0.ɵɵinject(i1.HttpClient)); };
DataSharingConsentService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DataSharingConsentService, factory: DataSharingConsentService.ɵfac, providedIn: 'root' });
