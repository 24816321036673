import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../ng-jhipster/language/jhi-translate.directive";
function SessionTypeComponent_span_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 4);
} }
const _c0 = function (a0) { return { "margin-top": a0 }; };
function SessionTypeComponent__svg_svg_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 5);
    i0.ɵɵelement(1, "use", 6);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(2, _c0, ctx_r1.sessionType === "video" ? "4px" : "0"));
    i0.ɵɵadvance(1);
    i0.ɵɵattribute("href", "../../../../content/images/sprite.svg?v=3#" + ctx_r1.sessionType, null, "xlink");
} }
export class SessionTypeComponent {
    constructor() {
        this.showIcon = true;
        this.showLabel = true;
    }
    ngOnInit() {
    }
}
SessionTypeComponent.ɵfac = function SessionTypeComponent_Factory(t) { return new (t || SessionTypeComponent)(); };
SessionTypeComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionTypeComponent, selectors: [["session-type"]], inputs: { sessionType: "sessionType", showIcon: "showIcon", showLabel: "showLabel" }, decls: 4, vars: 3, consts: [[1, "d-flex", "justify-content-start", "align-items-center"], ["style", "font-weight: bold;", "jhiTranslate", "sessionSchedule.paragraph.sessionType", 4, "ngIf"], ["style", "height: 14px; width: 14px; margin: 0 5px 0 7px;", 3, "ngStyle", 4, "ngIf"], [1, "description", 3, "jhiTranslate"], ["jhiTranslate", "sessionSchedule.paragraph.sessionType", 2, "font-weight", "bold"], [2, "height", "14px", "width", "14px", "margin", "0 5px 0 7px", 3, "ngStyle"], [0, "xlink", "href", ""]], template: function SessionTypeComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, SessionTypeComponent_span_1_Template, 1, 0, "span", 1);
        i0.ɵɵtemplate(2, SessionTypeComponent__svg_svg_2_Template, 2, 4, "svg", 2);
        i0.ɵɵelement(3, "span", 3);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.showLabel);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.showIcon);
        i0.ɵɵadvance(1);
        i0.ɵɵpropertyInterpolate("jhiTranslate", "sessionSchedule.paragraph." + ctx.sessionType);
    } }, directives: [i1.NgIf, i2.JhiTranslateDirective, i1.NgStyle], styles: [""] });
