import { SessionStorageService } from "ngx-webstorage";
import { ExtraQuestionAnswerService } from '../../admin/extra-question/extra-question-answer.service';
import { AccountService } from '../../core/auth/account.service';
import { QuestionarreAnswerService } from '../questionarre-answer/questionarre-answer.service';
import { flatMap, map } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { GhostUserDto } from '../../core/user/ghost-user-dto.model';
import { AuthServerProvider } from '../../core/auth/auth-jwt.service';
import { TrackerActivityFactoryService } from '../../core/tracker/tracker-activity-factory.service';
import { LookingForHelpTopicsService } from '../looking-for-help-topics/looking-for-help-topics.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth/account.service";
import * as i2 from "ngx-webstorage";
import * as i3 from "../questionarre-answer/questionarre-answer.service";
import * as i4 from "../../admin/extra-question/extra-question-answer.service";
import * as i5 from "../looking-for-help-topics/looking-for-help-topics.service";
import * as i6 from "../../core/auth/auth-jwt.service";
import * as i7 from "../../core/tracker/tracker-activity-factory.service";
export class WellbeingScanService {
    constructor(accountService, sessionStorage, questionarreAnswerService, extraQuestionAnswerService, lookingForHelpTopicsService, authServerProvider, trackerActivityFactoryService) {
        this.accountService = accountService;
        this.sessionStorage = sessionStorage;
        this.questionarreAnswerService = questionarreAnswerService;
        this.extraQuestionAnswerService = extraQuestionAnswerService;
        this.lookingForHelpTopicsService = lookingForHelpTopicsService;
        this.authServerProvider = authServerProvider;
        this.trackerActivityFactoryService = trackerActivityFactoryService;
    }
    saveSrqAnswers(answers) {
        this.sessionStorage.store("srqAnswers", answers);
    }
    saveExtraQuestionAnswers(answers) {
        this.sessionStorage.store("extraQuestionAnswers", answers);
    }
    submitAnswers() {
        const srqAnswers = this.getSavedSrqAnswers();
        const extraQuestionAnswers = this.getSavedExtraQuestionAnswers();
        if (srqAnswers && srqAnswers.length) {
            return this.questionarreAnswerService.saveWellbeingScan(srqAnswers).pipe(flatMap(saveSrqResponse => {
                if (extraQuestionAnswers && extraQuestionAnswers.length) {
                    const srqCompleted = saveSrqResponse.body;
                    return this.extraQuestionAnswerService.save(srqCompleted.id, extraQuestionAnswers);
                }
                else {
                    return of({});
                }
            })).pipe(map(() => {
                this.clearSavedSrqAnswers();
                this.clearSavedExtraQuestionAnswers();
                return true;
            }));
        }
        return of(false);
    }
    getSavedSrqAnswers() {
        const answers = this.sessionStorage.retrieve("srqAnswers");
        return answers;
    }
    getSavedExtraQuestionAnswers() {
        const answers = this.sessionStorage.retrieve("extraQuestionAnswers");
        return answers;
    }
    hasAnySavedSrqAnswers() {
        const answers = this.getSavedSrqAnswers();
        return answers != null;
    }
    hasAnySavedExtraQuestionAnswers() {
        const answers = this.getSavedExtraQuestionAnswers();
        return answers != null;
    }
    clearSavedSrqAnswers() {
        this.sessionStorage.clear("srqAnswers");
    }
    clearSavedExtraQuestionAnswers() {
        this.sessionStorage.clear("extraQuestionAnswers");
    }
    finishStandaloneSelfScanFlow(totalScore, stage, organizationCode, callback) {
        if (!this.accountService.isAuthenticated()) {
            const saveDataRequests = [];
            if (this.lookingForHelpTopicsService.hasAnySavedHelpTopics()) {
                saveDataRequests.push(this.lookingForHelpTopicsService.submitUserLookingForHelpTopics());
            }
            if (this.hasAnySavedSrqAnswers()) {
                saveDataRequests.push(this.submitAnswers());
            }
            if (saveDataRequests.length) {
                const parallelSaveDataRequests = forkJoin(saveDataRequests);
                const idKey = this.trackerActivityFactoryService.getIdKey();
                const ghostUserDto = new GhostUserDto(idKey, organizationCode);
                this.authServerProvider.loginAsGhost(ghostUserDto)
                    .pipe(flatMap(() => parallelSaveDataRequests))
                    .subscribe(() => { }, error => { }, () => {
                    // send save data requests in parallel
                    this.authServerProvider.logout().subscribe(() => {
                    }, () => {
                    }, () => {
                        this.redirectToCallBackUrl(totalScore, stage, callback);
                    });
                });
            }
            else {
                this.redirectToCallBackUrl(totalScore, stage, callback);
            }
        }
        else {
            this.redirectToCallBackUrl(totalScore, stage, callback);
        }
    }
    redirectToCallBackUrl(totalScore, stage, callback) {
        setTimeout(() => {
            let callbackLink = document.getElementById('callback-link');
            if (callbackLink) {
                callbackLink.click();
                return;
            }
            if (callback) {
                callback = decodeURIComponent(callback);
                const body = document.getElementsByTagName('body')[0];
                // If we submit the callbackLink via Post method the client's browser will block it as a popup, we have to use GET method but then we can't sent the self scan results (due to privacy issues)
                // const str = `<callbackLink id="callback-callbackLink" class="d-none" method="post" target="_blank" action="${callback}"><input type="text" name="score" value="${totalScore}"><input type="text" name="stage" value="${stage}"></callbackLink>`;
                const str = `<a id="callback-link" class="d-none" target="_blank" href="${callback}"></a>`;
                const temp = document.createElement('div');
                temp.innerHTML = str;
                while (temp.firstChild) {
                    body.appendChild(temp.firstChild);
                }
                callbackLink = document.getElementById('callback-link');
                callbackLink.click();
            }
        }, 10);
    }
}
WellbeingScanService.DEFAULT_CALL_BACK = '/';
WellbeingScanService.ɵfac = function WellbeingScanService_Factory(t) { return new (t || WellbeingScanService)(i0.ɵɵinject(i1.AccountService), i0.ɵɵinject(i2.SessionStorageService), i0.ɵɵinject(i3.QuestionarreAnswerService), i0.ɵɵinject(i4.ExtraQuestionAnswerService), i0.ɵɵinject(i5.LookingForHelpTopicsService), i0.ɵɵinject(i6.AuthServerProvider), i0.ɵɵinject(i7.TrackerActivityFactoryService)); };
WellbeingScanService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: WellbeingScanService, factory: WellbeingScanService.ɵfac, providedIn: 'root' });
