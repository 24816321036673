import { TranslateService } from "@ngx-translate/core";
import { OrganizationExtraQuestionAnswers } from '../../../../shared/model/organization-extra-questions-report.model';
import { JhiLanguageService } from '../../../../ng-jhipster/language';
import * as i0 from "@angular/core";
import * as i1 from "../../../../ng-jhipster/language";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
function OrganizationFreeTextExtraQuestionAnswersComponent_ng_container_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const freeTextAnswer_r1 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(freeTextAnswer_r1);
} }
export class OrganizationFreeTextExtraQuestionAnswersComponent {
    constructor(languageService, translateService) {
        this.languageService = languageService;
        this.translateService = translateService;
    }
    ngOnDestroy() {
        var _a;
        (_a = this.langChangeSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    set extraQuestionAnswers(value) {
        this._extraQuestionAnswers = value;
        this.refreshFreeTextAnswers();
    }
    get extraQuestionAnswers() {
        return this._extraQuestionAnswers;
    }
    ngOnInit() {
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.refreshFreeTextAnswers();
        });
    }
    refreshFreeTextAnswers() {
        this.title = this.selectTitleBasedOnLanguage(this.extraQuestionAnswers.extraQuestion.translations);
        const freeTextAnswers = [];
        if (this.extraQuestionAnswers) {
            for (const extraQuestionOptionAnswers of this.extraQuestionAnswers.extraQuestionOptionAnswers) {
                if (extraQuestionOptionAnswers.freeTextAnswers) {
                    for (const freeTextAnswer of extraQuestionOptionAnswers.freeTextAnswers) {
                        if (freeTextAnswer != null && freeTextAnswer.trim().length)
                            freeTextAnswers.push(freeTextAnswer);
                    }
                }
            }
        }
        this.freeTextExtraQuestionOptionAnswers = freeTextAnswers;
    }
    selectTitleBasedOnLanguage(titleTranslations) {
        const currentLanguage = this.languageService.getCurrentLanguage();
        let title = titleTranslations[currentLanguage];
        if (title && title.trim().length) {
            return title;
        }
        else {
            return titleTranslations['en'];
        }
    }
}
OrganizationFreeTextExtraQuestionAnswersComponent.ɵfac = function OrganizationFreeTextExtraQuestionAnswersComponent_Factory(t) { return new (t || OrganizationFreeTextExtraQuestionAnswersComponent)(i0.ɵɵdirectiveInject(i1.JhiLanguageService), i0.ɵɵdirectiveInject(i2.TranslateService)); };
OrganizationFreeTextExtraQuestionAnswersComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationFreeTextExtraQuestionAnswersComponent, selectors: [["organization-free-text-extra-question-answers"]], inputs: { extraQuestionAnswers: "extraQuestionAnswers" }, decls: 6, vars: 2, consts: [[1, "d-flex", "flex-row", "flex-wrap", "justify-content-between", 2, "gap", "20px"], [1, "card", "dashboard-card", "p-2"], [1, "m-3", "light-weight"], [1, "d-flex", "flex-column", "flex-xl-row", "flex-xl-wrap", 2, "overflow", "scroll", "max-height", "400px"], [4, "ngFor", "ngForOf"], [1, "extra-question-free-text-answer"]], template: function OrganizationFreeTextExtraQuestionAnswersComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "h4", 2);
        i0.ɵɵtext(3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 3);
        i0.ɵɵtemplate(5, OrganizationFreeTextExtraQuestionAnswersComponent_ng_container_5_Template, 3, 1, "ng-container", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate(ctx.title);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.freeTextExtraQuestionOptionAnswers);
    } }, directives: [i3.NgForOf], styles: [".extra-question-free-text-answer[_ngcontent-%COMP%]{padding:10px;margin:0px 10px 10px 20px;border-radius:4px;background:#f7f4eb;flex-basis:30%}"] });
