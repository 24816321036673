import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { map } from "rxjs/operators";
import moment from "moment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class QuestionarreAnswerService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/questionarre-answers';
    }
    // Deprecated
    create(questionarreAnswer) {
        return this.http.post(this.resourceUrl, questionarreAnswer, { observe: 'response' });
    }
    // Deprecated
    update(questionarreAnswer) {
        return this.http.put(this.resourceUrl, questionarreAnswer, { observe: 'response' });
    }
    // Deprecated
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    // Deprecated
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    // Deprecated
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    saveWellbeingScan(a) {
        let x = { answers: a };
        return this.http.post(`${this.resourceUrl}/save`, x, { observe: 'response' });
    }
    calculateWellbeingScan(a) {
        let x = { answers: a };
        return this.http.post(`${this.resourceUrl}/calculate`, x, { observe: 'response' })
            .pipe(map((res) => {
            if (res.body) {
                res.body.dateTaken = res.body.dateTaken ? moment(res.body.dateTaken) : undefined;
            }
            return res;
        }));
    }
    findBySrqId(srqId) {
        return this.http.get(`${this.resourceUrl}/srq-answers?srqId=${srqId}`, { observe: 'response' });
    }
    findAnswer17BySrqId(srqId) {
        return this.http.get(`${this.resourceUrl}/q17-answer?srqId=${srqId}`, { observe: 'response' });
    }
}
QuestionarreAnswerService.ɵfac = function QuestionarreAnswerService_Factory(t) { return new (t || QuestionarreAnswerService)(i0.ɵɵinject(i1.HttpClient)); };
QuestionarreAnswerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: QuestionarreAnswerService, factory: QuestionarreAnswerService.ɵfac, providedIn: 'root' });
