import { ComponentFactoryResolver } from '@angular/core';
import * as momenttz from "moment-timezone";
import * as i0 from "@angular/core";
export class TimeZoneService {
    constructor(componentFactoryResolver) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.timezone = momenttz.tz.guess();
    }
    isUserAndBrowserTimezonesTheSame(user) {
        if (user.timezone === this.timezone) {
            return true;
        }
        else {
            return false;
        }
    }
}
TimeZoneService.ɵfac = function TimeZoneService_Factory(t) { return new (t || TimeZoneService)(i0.ɵɵinject(i0.ComponentFactoryResolver)); };
TimeZoneService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TimeZoneService, factory: TimeZoneService.ɵfac, providedIn: 'root' });
