import { LOCALE_ID } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookieModule } from 'ngx-cookie';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import locale from '@angular/common/locales/en';
import moment from 'moment';
import { NgbDateAdapter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter } from '../shared/util/datepicker-adapter';
import { AuthInterceptor } from '../blocks/interceptor/auth.interceptor';
import { AuthExpiredInterceptor } from '../blocks/interceptor/auth-expired.interceptor';
import { ErrorHandlerInterceptor } from '../blocks/interceptor/errorhandler.interceptor';
import { NotificationInterceptor } from '../blocks/interceptor/notification.interceptor';
import { fontAwesomeIcons } from './icons/font-awesome-icons';
import { SessionStatusManagerService } from "../core/session/session-status-manager.service";
import { FlowManagerService } from "../core/util/flow-manager.service";
import { TrackerService } from "../core/tracker/tracker.service";
import { RequestTimeoutInterceptor } from "../blocks/interceptor/request-timeout.interceptor";
import { RetryFailedRequestInterceptor } from "../blocks/interceptor/retry-failed-request.interceptor";
import { UserInteractionService } from "../entities/user-interaction/user-interaction.service";
import { ApplicationVersionInterceptor } from "../blocks/interceptor/application-version.interceptor";
import { missingTranslationHandler, NgJhipsterModule, translatePartialLoader } from "../ng-jhipster/ng-jhipster.module";
import { JhiConfigService } from "../ng-jhipster/config.service";
import { JhiLanguageService } from "../ng-jhipster/language";
import * as i0 from "@angular/core";
import * as i1 from "@fortawesome/angular-fontawesome";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../ng-jhipster/language";
import * as i4 from "../core/session/session-status-manager.service";
import * as i5 from "../core/util/flow-manager.service";
import * as i6 from "../core/tracker/tracker.service";
import * as i7 from "../entities/user-interaction/user-interaction.service";
import * as i8 from "ngx-cookie";
import * as i9 from "ngx-webstorage";
import * as i10 from "../ng-jhipster/ng-jhipster.module";
import * as i11 from "@ngx-translate/core";
export class InukaNgCoreModule {
    constructor(iconLibrary, dpConfig, languageService, sessionStatusManagerService, flowManagerService, trackerService, userInteractionService) {
        registerLocaleData(locale);
        iconLibrary.addIcons(...fontAwesomeIcons);
        dpConfig.minDate = { year: moment().year() - 100, month: 1, day: 1 };
        languageService.init();
        sessionStatusManagerService.init();
        flowManagerService.init();
        trackerService.init();
        userInteractionService.init();
    }
}
InukaNgCoreModule.ɵfac = function InukaNgCoreModule_Factory(t) { return new (t || InukaNgCoreModule)(i0.ɵɵinject(i1.FaIconLibrary), i0.ɵɵinject(i2.NgbDatepickerConfig), i0.ɵɵinject(i3.JhiLanguageService), i0.ɵɵinject(i4.SessionStatusManagerService), i0.ɵɵinject(i5.FlowManagerService), i0.ɵɵinject(i6.TrackerService), i0.ɵɵinject(i7.UserInteractionService)); };
InukaNgCoreModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgCoreModule });
InukaNgCoreModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'en'
        },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter },
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestTimeoutInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApplicationVersionInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryFailedRequestInterceptor,
            multi: true
        },
    ], imports: [[
            HttpClientModule,
            CookieModule.forRoot(),
            NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
            NgJhipsterModule.forRoot({
                // set below to true to make alerts look like toast
                alertAsToast: true,
                alertTimeout: 5000,
                i18nEnabled: true,
                defaultI18nLang: 'en'
            }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: translatePartialLoader,
                    deps: [HttpClient]
                },
                missingTranslationHandler: {
                    provide: MissingTranslationHandler,
                    useFactory: missingTranslationHandler,
                    deps: [JhiConfigService]
                }
            }),
            FontAwesomeModule
        ]] });
