import { PaymentService } from '../payment.service';
import { ADYEN_DROPIN_URL } from '../../../app.constants';
import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../payment.service";
import * as i2 from "@angular/router";
export class PayAdyenComponent {
    constructor(adyenService, activatedRoute) {
        this.adyenService = adyenService;
        this.activatedRoute = activatedRoute;
    }
    ngOnInit() {
        this.pricingId = this.activatedRoute.snapshot.params['pricingId'];
        console.log('id: ' + this.pricingId);
        this.addAdyenSupport();
    }
    showFinalResult(response) { }
    makeDetailsCall(data) {
        let promise = new Promise((resolve, reject) => {
            // do a thing, possibly async, then…
            let x = 1;
            if (x === 2) {
                resolve(null);
            }
            else {
                reject(Error('It broke'));
            }
        });
        return promise;
    }
    makePayment(data) {
        console.log('data: ' + JSON.stringify(data));
        let promise = new Promise((resolve, reject) => {
            // do a thing, possibly async, then…
            this.adyenService.makePayment(data).subscribe(() => {
                resolve(null);
            }, () => {
                reject(Error('It broke'));
            });
        });
        return promise;
    }
    addAdyenSupport() {
        let script = document.createElement('script');
        script.src = ADYEN_DROPIN_URL;
        // console.log(script.src);
        script.onload = () => {
            console.log('Loaded adyen js');
            this.adyenService
                .getAvailablePaymentMethods({
                productId: this.pricingId,
                channel: 'WEB',
                shopperLocale: 'en-US'
            })
                .subscribe(res => {
                let paymentMethods = res.body;
                console.log('response: ' + JSON.stringify(paymentMethods));
                if (paymentMethods.oneClickPaymentMethods === null) {
                    paymentMethods.oneClickPaymentMethods = [];
                }
                if (paymentMethods.storedPaymentMethods === null) {
                    paymentMethods.storedPaymentMethods = [];
                }
                const configuration = {
                    paymentMethodsResponse: paymentMethods,
                    clientKey: 'test_5ALTOBIDBRAW7NZJEGNFP5TNPQ2MCMBH',
                    locale: 'en-US',
                    environment: 'test',
                    onSubmit: (state, dropin) => {
                        // Your function calling your server to make the `/payments` request
                        this.makePayment(state.data)
                            .then(response => {
                            if (response.action) {
                                // Drop-in handles the action object from the /payments response
                                dropin.handleAction(response.action);
                            }
                            else {
                                // Your function to show the final result to the shopper
                                this.showFinalResult(response);
                            }
                        })
                            .catch(error => {
                            throw Error(error);
                        });
                    },
                    onAdditionalDetails: (state, dropin) => {
                        // Your function calling your server to make a `/payments/details` request
                        this.makeDetailsCall(state.data)
                            .then(response => {
                            if (response.action) {
                                // Drop-in handles the action object from the /payments response
                                dropin.handleAction(response.action);
                            }
                            else {
                                // Your function to show the final result to the shopper
                                this.showFinalResult(response);
                            }
                        })
                            .catch(error => {
                            throw Error(error);
                        });
                    },
                    paymentMethodsConfiguration: {
                        card: {
                            // Example optional configuration for Cards
                            hasHolderName: true,
                            holderNameRequired: true,
                            enableStoreDetails: true,
                            hideCVC: false,
                            name: 'Credit or debit card'
                        }
                    }
                };
                displayAdyenPaymentForm(configuration);
            });
        };
        document.body.appendChild(script);
        let css = document.createElement('link');
        css.rel = 'stylesheet';
        css.type = 'text/css';
        css.href = 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.13.0/adyen.css';
        console.log(css.href);
        css.onload = () => {
            console.log('Loaded adyen css');
        };
        document.body.appendChild(css);
    }
}
PayAdyenComponent.ɵfac = function PayAdyenComponent_Factory(t) { return new (t || PayAdyenComponent)(i0.ɵɵdirectiveInject(i1.PaymentService), i0.ɵɵdirectiveInject(i2.ActivatedRoute)); };
PayAdyenComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: PayAdyenComponent, selectors: [["pay-adyen"]], decls: 4, vars: 0, consts: [[1, "container"], ["id", "dropin-container"]], template: function PayAdyenComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "p");
        i0.ɵɵtext(2, "pay-adyen works!");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(3, "div", 1);
        i0.ɵɵelementEnd();
    } }, styles: [""] });
export class Response {
    constructor(action) {
        this.action = action;
    }
}
export class StateData {
}
