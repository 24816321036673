import { VideoContainer, ZoomService } from '../zoom.service';
import * as i0 from "@angular/core";
import * as i1 from "../zoom.service";
import * as i2 from "@angular/common";
import * as i3 from "../turned-off-video/turned-off-video.component";
function SelfVideoComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵelement(1, "turned-off-video", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("user", ctx_r0.selfUser);
} }
export class SelfVideoComponent {
    constructor(zoomService) {
        this.zoomService = zoomService;
    }
    ngAfterViewInit() {
        this.connectionStatusSubscription = this.zoomService.connectionStatus$().subscribe(status => {
            if (status === 'CONNECTED') {
                // waite for the view to be initialized and receive the width and height values
                const selfViewVideoElement = document.getElementById('my-self-view-video');
                const selfViewCanvasElement = document.getElementById('my-self-view-canvas');
                setTimeout(() => {
                    console.log(`starting self video width: ${this.width}, height: ${this.height}`);
                    this.zoomService.registerSelfVideoContainer(new VideoContainer(selfViewCanvasElement, this.width, this.height, selfViewVideoElement));
                }, 2);
            }
        });
    }
    ngOnInit() {
        this.isCapturingVideoSubscription = this.zoomService.videoStatus$().subscribe(value => {
            this.isCapturingVideo = value === 'STARTED';
        });
    }
    ngOnDestroy() {
        var _a, _b;
        (_a = this.isCapturingVideoSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.zoomService.removeSelfVideoContainer();
        (_b = this.connectionStatusSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
    }
}
SelfVideoComponent.ɵfac = function SelfVideoComponent_Factory(t) { return new (t || SelfVideoComponent)(i0.ɵɵdirectiveInject(i1.ZoomService)); };
SelfVideoComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SelfVideoComponent, selectors: [["self-video"]], inputs: { width: "width", height: "height", selfUser: "selfUser" }, decls: 3, vars: 5, consts: [["id", "my-self-view-video", 2, "width", "100%", "height", "auto"], ["id", "my-self-view-canvas", 2, "width", "100%", "height", "auto"], ["class", "no-video-container", 4, "ngIf"], [1, "no-video-container"], [3, "user"]], template: function SelfVideoComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "video", 0);
        i0.ɵɵelement(1, "canvas", 1);
        i0.ɵɵtemplate(2, SelfVideoComponent_div_2_Template, 2, 1, "div", 2);
    } if (rf & 2) {
        i0.ɵɵclassProp("d-none", !ctx.isCapturingVideo);
        i0.ɵɵadvance(1);
        i0.ɵɵclassProp("d-none", !ctx.isCapturingVideo);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isCapturingVideo);
    } }, directives: [i2.NgIf, i3.TurnedOffVideoComponent], styles: ["#my-self-view-video[_ngcontent-%COMP%], #my-self-view-canvas[_ngcontent-%COMP%], .no-video-container[_ngcontent-%COMP%]{border:1px solid #0a4a3a;border-radius:5px}.no-video-container[_ngcontent-%COMP%]{background-color:#dff1ec}"] });
