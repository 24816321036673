import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { SERVER_API_URL } from '../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProfileService {
    constructor(http) {
        this.http = http;
        this.infoUrl = SERVER_API_URL + 'management/info';
    }
    getProfileInfo() {
        if (this.profileInfo$) {
            return this.profileInfo$;
        }
        this.profileInfo$ = this.http.get(this.infoUrl).pipe(map((response) => {
            const profileInfo = {
                activeProfiles: response.activeProfiles,
                inProduction: response.activeProfiles && response.activeProfiles.includes('prod'),
                swaggerEnabled: response.activeProfiles && response.activeProfiles.includes('swagger')
            };
            if (response.activeProfiles && response['display-ribbon-on-profiles']) {
                const displayRibbonOnProfiles = response['display-ribbon-on-profiles'].split(',');
                const ribbonProfiles = displayRibbonOnProfiles.filter(profile => response.activeProfiles && response.activeProfiles.includes(profile));
                if (ribbonProfiles.length > 0) {
                    profileInfo.ribbonEnv = ribbonProfiles[0];
                }
            }
            return profileInfo;
        }), shareReplay());
        return this.profileInfo$;
    }
}
ProfileService.ɵfac = function ProfileService_Factory(t) { return new (t || ProfileService)(i0.ɵɵinject(i1.HttpClient)); };
ProfileService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ProfileService, factory: ProfileService.ɵfac, providedIn: 'root' });
