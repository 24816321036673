import { __awaiter } from "tslib";
import { Datasource } from 'ngx-ui-scroll';
import { map } from "rxjs/operators";
export class VirtualScrollService {
    constructor() {
        this.bufferSize = 10;
    }
    static create(messageRepository) {
        const virtualScrollService = new VirtualScrollService();
        virtualScrollService.messageRepository = messageRepository;
        virtualScrollService.init();
        return virtualScrollService;
    }
    init() {
        const startIndex = this.messageRepository.lastIndex;
        this.dataSource = new Datasource({
            get: (index, count) => this.messageRepository.request(index, count),
            settings: {
                minIndex: 0,
                maxIndex: this.messageRepository.lastIndex,
                bufferSize: this.bufferSize,
                startIndex,
                inverse: true
            },
            devSettings: {
                debug: false
            }
        });
        this.haveHiddenItem = this.dataSource.adapter.lastVisible$.pipe(map(lastVisibleItem => lastVisibleItem.$index !== this.messageRepository.lastIndex));
    }
    scrollToBottom() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.dataSource.adapter.relax();
            yield this.dataSource.adapter.fix({ scrollPosition: Infinity });
            // await this.dataSource.adapter.clip();
        });
    }
    appendNewMessage(message, scrollToBottom) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.dataSource.adapter.relax();
            this.messageRepository.append(message);
            yield this.dataSource.adapter.append({ items: [message] });
            if (scrollToBottom) {
                yield this.dataSource.adapter.fix({ scrollPosition: Infinity });
            }
            // await this.dataSource.adapter.clip();
        });
    }
    isFirstVisible(index) {
        return index === this.dataSource.adapter.firstVisible.$index;
    }
    getPreviousVisible(index) {
        if (index >= this.dataSource.adapter.firstVisible.$index) {
            return this.messageRepository.get(index - 1);
        }
        return null;
    }
    getFirstVisibleObservable() {
        return this.dataSource.adapter.firstVisible$.pipe(map(value => {
            if ((value === null || value === void 0 ? void 0 : value.data) && Object.keys(value.data).length) {
                return value.data;
            }
            return null;
        }));
    }
    getLastVisibleObservable() {
        return this.dataSource.adapter.lastVisible$.pipe(map(value => {
            if ((value === null || value === void 0 ? void 0 : value.data) && Object.keys(value.data).length) {
                return value.data;
            }
            return null;
        }));
    }
    isLastVisibleObservable() {
        return this.dataSource.adapter.lastVisible$.pipe(map(value => {
            return value.$index >= this.messageRepository.lastIndex;
        }));
    }
    checkSize() {
        this.dataSource.adapter.check().then();
    }
}
