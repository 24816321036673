import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faArrowDown, faArrowLeft, faArrowRight, faAsterisk, faBan, faBars, faBell, faBook, faCalendar, faCalendarAlt, faChartLine, faCheck, faCheckCircle, faCheckSquare, faChevronCircleDown, faChevronCircleUp, faChevronDown, faChevronRight, faCloud, faCommentDots, faDatabase, faDownload, faEllipsisH, faEllipsisV, faEnvelope, faEnvelopeOpen, faEnvelopeOpenText, faExclamationCircle, faEye, faEyeSlash, faFileAlt, faFileDownload, faFlag, faFrown, faGlobe, faGraduationCap, faHdd, faHeart, faHome, faInfoCircle, faLink, faList, faListUl, faLock, faPencilAlt, faPhone, faPlus, faQuestion, faQuestionCircle, faReply, faRoad, faSave, faSearch, faSignInAlt, faSignOutAlt, faSmile, faSort, faSortDown, faSortUp, faSpinner, faSquare, faSquareFull, faStar, faSync, faTachometerAlt, faTasks, faTh, faThList, faTimes, faTimesCircle, faTrashAlt, faUndo, faUser, faUserGraduate, faUserPlus, faUserTie, faVideo, faWrench } from '@fortawesome/free-solid-svg-icons';
// regular fonts (ici bos olanlar)
import { faSmile as farSmile, faStar as farStar } from '@fortawesome/free-regular-svg-icons';
// jhipster-needle-add-icon-import
export const fontAwesomeIcons = [
    faUser,
    faSort,
    faSortUp,
    faSortDown,
    faSync,
    faEye,
    faEyeSlash,
    faBan,
    faTimes,
    faArrowLeft,
    faArrowRight,
    faSave,
    faPlus,
    faPencilAlt,
    faBars,
    faHome,
    faThList,
    faUserPlus,
    faRoad,
    faTachometerAlt,
    faHeart,
    faList,
    faBell,
    faTasks,
    faBook,
    faHdd,
    faFlag,
    faWrench,
    faLock,
    faCloud,
    faSignOutAlt,
    faSignInAlt,
    faCalendarAlt,
    faCalendar,
    faSearch,
    faTrashAlt,
    faAsterisk,
    faCheck,
    faSquare,
    faSquareFull,
    faCheckSquare,
    faCheckCircle,
    faInfoCircle,
    faExclamationCircle,
    faUndo,
    faEnvelope,
    faEnvelopeOpen,
    faEllipsisV,
    faEllipsisH,
    faStar,
    farStar,
    faArrowDown,
    faChevronDown,
    faChevronRight,
    faTimesCircle,
    faFileAlt,
    faDownload,
    faListUl,
    faSmile,
    faFrown,
    faReply,
    faEnvelopeOpenText,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faChevronCircleDown,
    faChevronCircleUp,
    faTh,
    faSpinner,
    faPhone,
    faVideo,
    faCommentDots,
    faQuestionCircle,
    faQuestion,
    faFileDownload,
    faLink,
    farSmile,
    faGlobe,
    faGraduationCap,
    faChartLine,
    faUserTie,
    faUserGraduate,
    faDatabase
];
// jhipster-needle-add-icon-import
