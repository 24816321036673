import * as i0 from "@angular/core";
export class DomainService {
    static getFrontendDomain() {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const domain = `${protocol}//${host}`;
        return domain;
    }
}
DomainService.ɵfac = function DomainService_Factory(t) { return new (t || DomainService)(); };
DomainService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DomainService, factory: DomainService.ɵfac, providedIn: 'root' });
