import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i3 from "@angular/forms";
import * as i4 from "@ngx-translate/core";
function LookingForHelpTopicsSelectorComponent_h2_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h2", 5);
} }
function LookingForHelpTopicsSelectorComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelement(1, "h4", 7);
    i0.ɵɵelementEnd();
} }
function LookingForHelpTopicsSelectorComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 8);
    i0.ɵɵlistener("click", function LookingForHelpTopicsSelectorComponent_ng_container_3_Template_button_click_1_listener() { const restoredCtx = i0.ɵɵrestoreView(_r6); const topic_r4 = restoredCtx.$implicit; const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.toggleSelection(topic_r4); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const topic_r4 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate1("jhiTranslate", "inukaNgApp.lookingForHelpTopics.topics.", topic_r4, "");
    i0.ɵɵpropertyInterpolate1("id", "help-topic-", topic_r4, "");
    i0.ɵɵproperty("ngClass", ctx_r2.selectedTopics[topic_r4] ? "btn-primary" : "btn-secondary");
} }
function LookingForHelpTopicsSelectorComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵelementStart(1, "input", 10);
    i0.ɵɵlistener("ngModelChange", function LookingForHelpTopicsSelectorComponent_div_4_Template_input_ngModelChange_1_listener($event) { i0.ɵɵrestoreView(_r8); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.otherHelpTopic = $event; })("keyup", function LookingForHelpTopicsSelectorComponent_div_4_Template_input_keyup_1_listener() { i0.ɵɵrestoreView(_r8); const ctx_r9 = i0.ɵɵnextContext(); return ctx_r9.onOtherHelpUpdate(); });
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("placeholder", i0.ɵɵpipeBind1(2, 2, "inukaNgApp.lookingForHelpTopics.topics.other"));
    i0.ɵɵproperty("ngModel", ctx_r3.otherHelpTopic);
} }
export class LookingForHelpTopicsSelectorComponent {
    constructor() {
        this.showHeader = true;
        this.showParagraph = false;
        this.showTextBox = true;
        this.selectedHelpTopicsChange = new EventEmitter();
        this.helpTopics = [
            'work-life_balance',
            'finances',
            'relationships',
            'lifestyle',
            'career',
            'leadership',
            'communication',
            'productivity',
            'work_load',
            'stress',
            'time_management',
            'worrying',
            'sleep_issues',
            'confidence',
            'my_purpose',
            'i_do_not_know',
        ];
        this.selectedTopics = {};
    }
    set selectedHelpTopics(value) {
        if (value) {
            value.forEach(helpTopic => {
                if (this.helpTopics.includes(helpTopic)) {
                    this.selectedTopics[helpTopic] = true;
                }
                else {
                    this.otherHelpTopic = helpTopic;
                }
            });
        }
    }
    ngOnInit() {
    }
    toggleSelection(helpTopic) {
        if (this.selectedTopics[helpTopic]) {
            delete this.selectedTopics[helpTopic];
        }
        else {
            this.selectedTopics[helpTopic] = true;
        }
        this.emitChanges();
    }
    onOtherHelpUpdate() {
        this.emitChanges();
    }
    emitChanges() {
        let topics = Object.keys(this.selectedTopics);
        if (this.otherHelpTopic && this.otherHelpTopic.trim().length) {
            topics.push(this.otherHelpTopic.trim());
        }
        this.selectedHelpTopicsChange.emit(topics);
    }
}
LookingForHelpTopicsSelectorComponent.ɵfac = function LookingForHelpTopicsSelectorComponent_Factory(t) { return new (t || LookingForHelpTopicsSelectorComponent)(); };
LookingForHelpTopicsSelectorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: LookingForHelpTopicsSelectorComponent, selectors: [["looking-for-help-topics-selector"]], inputs: { showHeader: "showHeader", showParagraph: "showParagraph", showTextBox: "showTextBox", selectedHelpTopics: "selectedHelpTopics" }, outputs: { selectedHelpTopicsChange: "selectedHelpTopicsChange" }, decls: 5, vars: 5, consts: [["class", "text-center", "jhiTranslate", "inukaNgApp.lookingForHelpTopics.title", 4, "ngIf"], ["class", "choose-text", 4, "ngIf"], [2, "max-width", "560px", "margin", "auto", 3, "ngClass"], [4, "ngFor", "ngForOf"], ["class", "form-group px-2 pt-4", 4, "ngIf"], ["jhiTranslate", "inukaNgApp.lookingForHelpTopics.title", 1, "text-center"], [1, "choose-text"], ["jhiTranslate", "inukaNgApp.wellbeingStart.chooseOptions", 2, "font-weight", "normal"], [1, "btn", "border-0", "m-2", 3, "ngClass", "jhiTranslate", "id", "click"], [1, "form-group", "px-2", "pt-4"], ["type", "text", "name", "other", 1, "mt-2", "p-4", "form-control", 3, "ngModel", "placeholder", "ngModelChange", "keyup"]], template: function LookingForHelpTopicsSelectorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, LookingForHelpTopicsSelectorComponent_h2_0_Template, 1, 0, "h2", 0);
        i0.ɵɵtemplate(1, LookingForHelpTopicsSelectorComponent_div_1_Template, 2, 0, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵtemplate(3, LookingForHelpTopicsSelectorComponent_ng_container_3_Template, 2, 3, "ng-container", 3);
        i0.ɵɵtemplate(4, LookingForHelpTopicsSelectorComponent_div_4_Template, 3, 4, "div", 4);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.showHeader);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.showParagraph);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngClass", ctx.showHeader ? "mt-4" : "mt-2");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.helpTopics);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.showTextBox);
    } }, directives: [i1.NgIf, i1.NgClass, i1.NgForOf, i2.JhiTranslateDirective, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel], pipes: [i4.TranslatePipe], styles: [".btn[_ngcontent-%COMP%]{min-width:auto !important;font-weight:400 !important}"] });
