import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import * as i0 from "@angular/core";
export class JhiModuleConfig {
    constructor() {
        this.sortIcon = faSort;
        this.sortAscIcon = faSortUp;
        this.sortDescIcon = faSortDown;
        this.i18nEnabled = false;
        this.defaultI18nLang = 'en';
        this.noi18nMessage = 'translation-not-found';
        this.alertAsToast = false;
        this.alertTimeout = 5000;
        this.classBadgeTrue = 'badge badge-success';
        this.classBadgeFalse = 'badge badge-danger';
        this.classTrue = 'fa fa-lg fa-check text-success';
        this.classFalse = 'fa fa-lg fa-times text-danger';
    }
}
JhiModuleConfig.ɵfac = function JhiModuleConfig_Factory(t) { return new (t || JhiModuleConfig)(); };
JhiModuleConfig.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: JhiModuleConfig, factory: JhiModuleConfig.ɵfac, providedIn: 'root' });
