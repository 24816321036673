import { FlowManagerService } from '../../core/util/flow-manager.service';
import { ActivatedRoute, Router } from "@angular/router";
import { TrackerService } from '../../core/tracker/tracker.service';
import { AuthServerProvider } from '../../core/auth/auth-jwt.service';
import { AccountService } from '../../core/auth/account.service';
import { LoginService } from '../../core/login/login.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/util/flow-manager.service";
import * as i2 from "@angular/router";
import * as i3 from "../../core/tracker/tracker.service";
import * as i4 from "../../core/auth/auth-jwt.service";
import * as i5 from "../../core/auth/account.service";
import * as i6 from "../../core/login/login.service";
export class LandingComponent {
    constructor(flowManagerService, activatedRoute, trackerService, authServerProvider, router, accountService, loginService) {
        this.flowManagerService = flowManagerService;
        this.activatedRoute = activatedRoute;
        this.trackerService = trackerService;
        this.authServerProvider = authServerProvider;
        this.router = router;
        this.accountService = accountService;
        this.loginService = loginService;
    }
    ngOnDestroy() {
        var _a;
        (_a = this.authenticateEmailSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    ngOnInit() {
        // logout the user if it's logged in already
        this.accountService.identity().subscribe(account => {
            if (account) {
                this.loginService.logout();
            }
        });
        const email = this.flowManagerService.getEmailFromUrlQueryParams();
        if (email === null || email === void 0 ? void 0 : email.length) {
            this.authenticateEmailSubscription = this.authServerProvider.authenticateEmail(email).subscribe(response => {
                if (response === 'Not Found') {
                    this.startFlow();
                }
                else {
                    this.router.navigate(['/magic-login'], { queryParams: { email } });
                }
            });
        }
        else {
            this.startFlow();
        }
    }
    startFlow() {
        let flowName = this.flowManagerService.getFlowNameFromUrlQueryParams();
        this.trackerService.sendActivity('landing_page_open', { 'flow': flowName });
        if (flowName) {
            this.flowManagerService.startFlowByName(flowName);
        }
    }
}
LandingComponent.ɵfac = function LandingComponent_Factory(t) { return new (t || LandingComponent)(i0.ɵɵdirectiveInject(i1.FlowManagerService), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i3.TrackerService), i0.ɵɵdirectiveInject(i4.AuthServerProvider), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i5.AccountService), i0.ɵɵdirectiveInject(i6.LoginService)); };
LandingComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: LandingComponent, selectors: [["landing-page"]], decls: 0, vars: 0, template: function LandingComponent_Template(rf, ctx) { }, styles: [""] });
