import { TranslateService } from '@ngx-translate/core';
import { JhiAlertService, JhiEventManager } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
function AlertErrorComponent_div_1_ngb_alert_1_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ngb-alert", 4);
    i0.ɵɵlistener("close", function AlertErrorComponent_div_1_ngb_alert_1_Template_ngb_alert_close_0_listener() { i0.ɵɵrestoreView(_r4); const alert_r1 = i0.ɵɵnextContext().$implicit; const ctx_r3 = i0.ɵɵnextContext(); return alert_r1.close(ctx_r3.alerts); });
    i0.ɵɵelement(1, "pre", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const alert_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("type", alert_r1.type);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("innerHTML", alert_r1.msg, i0.ɵɵsanitizeHtml);
} }
function AlertErrorComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, AlertErrorComponent_div_1_ngb_alert_1_Template, 2, 2, "ngb-alert", 3);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const alert_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.setClasses(alert_r1));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", alert_r1 && alert_r1.type && alert_r1.msg);
} }
export class AlertErrorComponent {
    constructor(alertService, eventManager, translateService) {
        this.alertService = alertService;
        this.eventManager = eventManager;
        this.alerts = [];
        this.errorListener = eventManager.subscribe('inukaNgApp.error', (response) => {
            const errorResponse = response.content;
            this.addErrorAlert(errorResponse.message, errorResponse.key, errorResponse.params);
        });
        this.httpErrorListener = eventManager.subscribe('inukaNgApp.httpError', (response) => {
            const httpErrorResponse = response.content;
            console.log('Error: ' + JSON.stringify(httpErrorResponse));
            switch (httpErrorResponse.status) {
                // connection refused, server not reachable
                case 0:
                    this.addErrorAlert('Server not reachable', 'error.server.not.reachable');
                    break;
                case 400: {
                    const arr = httpErrorResponse.headers.keys();
                    let errorHeader = null;
                    let entityKey = null;
                    if (httpErrorResponse.error.errorKey === 'organizationcodeexists')
                        return;
                    arr.forEach(entry => {
                        if (entry.toLowerCase().endsWith('app-error')) {
                            errorHeader = httpErrorResponse.headers.get(entry);
                        }
                        else if (entry.toLowerCase().endsWith('app-params')) {
                            entityKey = httpErrorResponse.headers.get(entry);
                        }
                    });
                    if (errorHeader) {
                        let key = 'global.menu.entities.' + entityKey;
                        console.log('ERROR HEADER KEY: ' + key);
                        const entityName = translateService.instant('global.menu.entities.' + entityKey);
                        this.addErrorAlert(errorHeader, errorHeader, { entityName });
                    }
                    else if (httpErrorResponse.error !== '' && httpErrorResponse.error.fieldErrors) {
                        const fieldErrors = httpErrorResponse.error.fieldErrors;
                        for (const fieldError of fieldErrors) {
                            if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
                                fieldError.message = 'Size';
                            }
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                            const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                            console.log('FIELD ERROR: ' + fieldError);
                            console.log('FIELD ERROR OBJECT NAME: ' + fieldError.objectName);
                            console.log('FIELD ERROR CONVERTED FIELD: ' + convertedField);
                            const fieldName = translateService.instant('inukaNgApp.' + fieldError.objectName + '.' + convertedField);
                            this.addErrorAlert('Error on field "' + fieldName + '"', 'error.' + fieldError.message, { fieldName });
                        }
                    }
                    else if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                        this.addErrorAlert(httpErrorResponse.error.message, httpErrorResponse.error.message, httpErrorResponse.error.params);
                    }
                    else {
                        this.addErrorAlert(httpErrorResponse.error);
                    }
                    break;
                }
                case 404:
                    this.addErrorAlert('Not found', 'error.url.not.found');
                    break;
                default:
                    if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                        this.addErrorAlert(httpErrorResponse.error.message);
                    }
                    else {
                        this.addErrorAlert(httpErrorResponse.error);
                    }
            }
        });
    }
    setClasses(alert) {
        const classes = { 'jhi-toast': Boolean(alert.toast) };
        if (alert.position) {
            return Object.assign(Object.assign({}, classes), { [alert.position]: true });
        }
        return classes;
    }
    ngOnDestroy() {
        if (this.errorListener) {
            this.eventManager.destroy(this.errorListener);
        }
        if (this.httpErrorListener) {
            this.eventManager.destroy(this.httpErrorListener);
        }
    }
    addErrorAlert(message, key, data) {
        message = key && key !== null ? key : message;
        const newAlert = {
            type: 'danger',
            msg: message,
            params: data,
            timeout: 5000,
            toast: this.alertService.isToast(),
            scoped: true
        };
        this.alerts.push(this.alertService.addAlert(newAlert, this.alerts));
    }
}
AlertErrorComponent.ɵfac = function AlertErrorComponent_Factory(t) { return new (t || AlertErrorComponent)(i0.ɵɵdirectiveInject(i1.JhiAlertService), i0.ɵɵdirectiveInject(i1.JhiEventManager), i0.ɵɵdirectiveInject(i2.TranslateService)); };
AlertErrorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AlertErrorComponent, selectors: [["jhi-alert-error"]], decls: 2, vars: 1, consts: [["hidden", "", "role", "alert", 1, "alerts"], [3, "ngClass", 4, "ngFor", "ngForOf"], [3, "ngClass"], [3, "type", "close", 4, "ngIf"], [3, "type", "close"], [3, "innerHTML"]], template: function AlertErrorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, AlertErrorComponent_div_1_Template, 2, 2, "div", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.alerts);
    } }, directives: [i3.NgForOf, i3.NgClass, i3.NgIf, i4.NgbAlert], encapsulation: 2 });
