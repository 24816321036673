import { CoachDetailsService } from '../../slot/coach-details.service';
import { AccountService } from '../../../core/auth/account.service';
import * as i0 from "@angular/core";
import * as i1 from "../../slot/coach-details.service";
import * as i2 from "../../../core/auth/account.service";
import * as i3 from "@angular/common";
const _c0 = function (a0) { return { "font-size": a0 }; };
function TurnedOffVideoComponent_ng_container_0_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(2, _c0, ctx_r1.showLarge ? "18vw" : "6vw"));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.user.login[0], " ");
} }
const _c1 = function (a0) { return { "background-image": a0 }; };
function TurnedOffVideoComponent_ng_container_0_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵelement(2, "div");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(1, _c1, "url(" + ctx_r2.profileImageUrl + ")"));
} }
function TurnedOffVideoComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵtemplate(3, TurnedOffVideoComponent_ng_container_0_div_3_Template, 4, 4, "div", 3);
    i0.ɵɵtemplate(4, TurnedOffVideoComponent_ng_container_0_div_4_Template, 3, 3, "div", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", !ctx_r0.profileImageUrl);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.profileImageUrl);
} }
export class TurnedOffVideoComponent {
    constructor(coachDetailsService, accountService) {
        this.coachDetailsService = coachDetailsService;
        this.accountService = accountService;
    }
    set user(value) {
        var _a;
        if ((value === null || value === void 0 ? void 0 : value.id) !== ((_a = this._user) === null || _a === void 0 ? void 0 : _a.id)) {
            this._user = value;
            this.getProfileImageUrl();
        }
    }
    get user() {
        return this._user;
    }
    ngOnInit() {
    }
    getProfileImageUrl() {
        if (this.accountService.isCoachUser(this.user)) {
            this.coachDetailsService.findCoachDetails(this.user.id).subscribe(coachDetails => {
                this.profileImageUrl = coachDetails.body.imageUrl;
            });
        }
    }
}
TurnedOffVideoComponent.ɵfac = function TurnedOffVideoComponent_Factory(t) { return new (t || TurnedOffVideoComponent)(i0.ɵɵdirectiveInject(i1.CoachDetailsService), i0.ɵɵdirectiveInject(i2.AccountService)); };
TurnedOffVideoComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TurnedOffVideoComponent, selectors: [["turned-off-video"]], inputs: { showLarge: "showLarge", user: "user" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [1, "d-flex", "justify-content-center", "p-3"], [2, "flex-basis", "28%"], ["style", "position: relative;width: 100%;padding-bottom: 100%", 4, "ngIf"], [2, "position", "relative", "width", "100%", "padding-bottom", "100%"], [1, "text-center", "d-flex", "justify-content-center", "align-items-center", "text-uppercase", 2, "position", "absolute", "width", "100%", "height", "100%", "background-color", "#44A38B", "border-radius", "50%", "color", "#FFFFFF", "font-weight", "700", 3, "ngStyle"], [1, "text-center", "p-2", "text-uppercase", 2, "position", "absolute", "width", "100%", "height", "100%", "background-color", "#44A38B", "border-radius", "50%", "background-repeat", "no-repeat", "background-position", "center center", "background-size", "cover", 3, "ngStyle"]], template: function TurnedOffVideoComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, TurnedOffVideoComponent_ng_container_0_Template, 5, 2, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.user);
    } }, directives: [i3.NgIf, i3.NgStyle], styles: [""] });
