import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { DatePipe } from "@angular/common";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/common";
export class RatingService {
    constructor(http, datePipe) {
        this.http = http;
        this.datePipe = datePipe;
        this.resourceUrl = SERVER_API_URL + 'api/ratings';
    }
    create(rating) {
        const copy = this.convertDateFromClient(rating);
        return this.http.post(this.resourceUrl, copy, { observe: 'response' });
    }
    update(rating) {
        const copy = this.convertDateFromClient(rating);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    findBySessionId(sessionId) {
        return this.http
            .get(`${this.resourceUrl}/sessionId/${sessionId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    queryWithRegions(req, selectedRegions) {
        const options = createRequestOption(req);
        return this.http
            .post(`${this.resourceUrl}/regions`, selectedRegions, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    queryWithSearchParam(req, searchParam, selectedRegions) {
        if (searchParam === '')
            return this.queryWithRegions(req, selectedRegions);
        const options = createRequestOption(req);
        return this.http.post(`${this.resourceUrl}/searchParam`, { searchParam, selectedRegions }, { params: options, observe: 'response' });
    }
    findAllResultsByRegions(selectedRegions) {
        return this.http.post(`${this.resourceUrl}/all`, selectedRegions, { observe: 'response' });
    }
    findAllRatingsForUser() {
        return this.http
            .get(`${this.resourceUrl}/all-for-user`, { observe: 'response' })
            .pipe(map((res) => this.convertDateToString(res)));
    }
    convertDateToString(res) {
        if (res.body) {
            res.body.forEach((s) => { s.creationDate = this.datePipe.transform(s.creationDate, "yyyy-MM-ddTHH:mm:ss"); });
        }
        return res;
    }
    queryForCoachId(coachId) {
        return this.http
            .get(`${this.resourceUrl}/coachId/${coachId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(rating) {
        const copy = Object.assign({}, rating, {
            creationDate: rating.creationDate && rating.creationDate.isValid() ? rating.creationDate.toJSON() : undefined
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.creationDate = res.body.creationDate ? moment(res.body.creationDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((rating) => {
                rating.creationDate = rating.creationDate ? moment(rating.creationDate) : undefined;
            });
        }
        return res;
    }
    getTestimonials(langKey, count) {
        return this.http
            .get(`${this.resourceUrl}/testimonials?langKey=${langKey}&count=${count}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
}
RatingService.ɵfac = function RatingService_Factory(t) { return new (t || RatingService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DatePipe)); };
RatingService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RatingService, factory: RatingService.ɵfac, providedIn: 'root' });
