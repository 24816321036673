import { SimpleChanges } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i3 from "@angular/material/checkbox";
export class PasswordStrengthMeterComponent {
    constructor(formBuilder) {
        this.formBuilder = formBuilder;
        this.uppercase = new RegExp('(?=.*[A-Z])');
        this.lowercase = new RegExp('(?=.*[a-z])');
        this.special = new RegExp('(?=.*[$&+,:;=?@#|\'<>.^*()%!_-])');
        this.number = new RegExp('(?=.*[0-9])');
        this.letter = new RegExp('(?=.*[a-zA-Z])');
        this.passwordStrengthFactor = 0;
        this.longerThan8 = 0;
        this.isUppercase = 0;
        this.isLowercase = 0;
        this.isSpecial = 0;
        this.isNumberLetter = 0;
        this.checkboxForm = this.formBuilder.group({
            eight: [{ value: '', disabled: true }],
            uppercase: [{ value: '', disabled: true }],
            lowercase: [{ value: '', disabled: true }],
            special: [{ value: '', disabled: true }],
            numberLetter: [{ value: '', disabled: true }],
        });
    }
    set password(value) { this._password = value; }
    get password() { return this._password; }
    ngOnInit() {
        this.calculatePasswordStrengthFactor();
    }
    ngOnChanges(changes) {
        this.calculatePasswordStrengthFactor();
    }
    isAtLeast8() {
        return this.password != null ? this.password.length > 7 : false;
    }
    containsUppercase() {
        return this.password != null ? this.uppercase.test(this.password) : false;
    }
    containsLowercase() {
        return this.password != null ? this.lowercase.test(this.password) : false;
    }
    containsSpecial() {
        return this.password != null ? this.special.test(this.password) : false;
    }
    containsNumberLetter() {
        return this.password != null ? this.number.test(this.password) && this.letter.test(this.password) : false;
    }
    calculatePasswordStrengthFactor() {
        if (this.password != null) {
            this.longerThan8 = this.isAtLeast8() ? 1 : 0;
            this.isUppercase = this.containsUppercase() ? 1 : 0;
            this.isLowercase = this.containsLowercase() ? 1 : 0;
            this.isSpecial = this.containsSpecial() ? 1 : 0;
            this.isNumberLetter = this.containsNumberLetter() ? 1 : 0;
        }
        this.passwordStrengthFactor = (this.longerThan8 + this.isUppercase + this.isLowercase + this.isSpecial + this.isNumberLetter) * 20;
    }
    getColor() {
        if (this.passwordStrengthFactor >= 80)
            return 'rgba(68, 163, 139, 1)';
        else if (this.passwordStrengthFactor >= 60)
            return 'rgba(224, 202, 88, 1)';
        else if (this.passwordStrengthFactor >= 20)
            return 'rgba(175, 59, 110, 1)';
        else
            return 'rgba(138, 157, 152, 1)';
    }
}
PasswordStrengthMeterComponent.ɵfac = function PasswordStrengthMeterComponent_Factory(t) { return new (t || PasswordStrengthMeterComponent)(i0.ɵɵdirectiveInject(i1.FormBuilder)); };
PasswordStrengthMeterComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: PasswordStrengthMeterComponent, selectors: [["password-strength-meter-component"]], inputs: { password: "password" }, features: [i0.ɵɵNgOnChangesFeature], decls: 22, vars: 11, consts: [[1, "olha-theme"], [1, "bg-white", "feedback"], [1, "d-flex"], ["jhiTranslate", "password.passwordStrength.passwordStrength", 1, "mr-2"], ["name", "form", "role", "form", 3, "formGroup"], ["formControlName", "eight", 1, "check-box", 3, "checked"], ["jhiTranslate", "password.passwordStrength.eight", 1, "check-box-text"], ["formControlName", "uppercase", 1, "check-box", 3, "checked"], ["jhiTranslate", "password.passwordStrength.uppercase", 1, "check-box-text"], ["formControlName", "lowercase", 1, "check-box", 3, "checked"], ["jhiTranslate", "password.passwordStrength.lowercase", 1, "check-box-text"], ["formControlName", "special", 1, "check-box", 3, "checked"], ["jhiTranslate", "password.passwordStrength.special", 1, "check-box-text"], ["formControlName", "numberLetter", 1, "check-box", 3, "checked"], ["jhiTranslate", "password.passwordStrength.numberLetter", 1, "check-box-text"]], template: function PasswordStrengthMeterComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelement(3, "h5", 3);
        i0.ɵɵelementStart(4, "h5");
        i0.ɵɵtext(5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "form", 4);
        i0.ɵɵelementStart(7, "div", 2);
        i0.ɵɵelement(8, "mat-checkbox", 5);
        i0.ɵɵelement(9, "span", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(10, "div", 2);
        i0.ɵɵelement(11, "mat-checkbox", 7);
        i0.ɵɵelement(12, "span", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 2);
        i0.ɵɵelement(14, "mat-checkbox", 9);
        i0.ɵɵelement(15, "span", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(16, "div", 2);
        i0.ɵɵelement(17, "mat-checkbox", 11);
        i0.ɵɵelement(18, "span", 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "div", 2);
        i0.ɵɵelement(20, "mat-checkbox", 13);
        i0.ɵɵelement(21, "span", 14);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵstyleProp("color", ctx.getColor());
        i0.ɵɵadvance(1);
        i0.ɵɵstyleProp("color", ctx.getColor());
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate(ctx.passwordStrengthFactor + "%");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("formGroup", ctx.checkboxForm);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("checked", ctx.isAtLeast8());
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("checked", ctx.containsUppercase());
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("checked", ctx.containsLowercase());
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("checked", ctx.containsSpecial());
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("checked", ctx.containsNumberLetter());
    } }, directives: [i2.JhiTranslateDirective, i1.ɵNgNoValidate, i1.NgControlStatusGroup, i1.FormGroupDirective, i3.MatCheckbox, i1.NgControlStatus, i1.FormControlName], styles: [".feedback[_ngcontent-%COMP%]{border-radius:5px;padding:16px}.check-box[_ngcontent-%COMP%]{margin-right:8px}.mat-form-field-wrapper[_ngcontent-%COMP%]{padding-bottom:0}.check-box-text[_ngcontent-%COMP%]{font-family:\"Open Sans\",serif;font-style:normal;font-weight:400;font-size:12px;line-height:140%;color:#0a4a3a}[_nghost-%COMP%]  .mat-checkbox-frame{border:1px solid rgba(10,74,58,.2) !important;border-radius:2px !important}"] });
