import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
function JhiMetricsSystemComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵtext(2, "Uptime");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r0.convertMillisecondsToDuration(ctx_r0.systemMetrics["process.uptime"]));
} }
function JhiMetricsSystemComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵtext(2, "Start time");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "date");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(5, 1, ctx_r1.systemMetrics["process.start.time"], "full"));
} }
function JhiMetricsSystemComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2, "Process CPU usage");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(5, 1, 100 * ctx_r2.systemMetrics["process.cpu.usage"], "1.0-2"), " %");
} }
function JhiMetricsSystemComponent_ngb_progressbar_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ngb-progressbar", 7);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", 100 * ctx_r3.systemMetrics["process.cpu.usage"])("striped", true)("animated", false);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(3, 4, 100 * ctx_r3.systemMetrics["process.cpu.usage"], "1.0-2"), " %");
} }
function JhiMetricsSystemComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2, "System CPU usage");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(5, 1, 100 * ctx_r4.systemMetrics["system.cpu.usage"], "1.0-2"), " %");
} }
function JhiMetricsSystemComponent_ngb_progressbar_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ngb-progressbar", 7);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", 100 * ctx_r5.systemMetrics["system.cpu.usage"])("striped", true)("animated", false);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(3, 4, 100 * ctx_r5.systemMetrics["system.cpu.usage"], "1.0-2"), " %");
} }
function JhiMetricsSystemComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2, "System CPU count");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(ctx_r6.systemMetrics["system.cpu.count"]);
} }
function JhiMetricsSystemComponent_div_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2, "System 1m Load average");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(5, 1, ctx_r7.systemMetrics["system.load.average.1m"], "1.0-2"));
} }
function JhiMetricsSystemComponent_div_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2, "Process files max");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(5, 1, ctx_r8.systemMetrics["process.files.max"], "1.0-0"));
} }
function JhiMetricsSystemComponent_div_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2, "Process files open");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵtext(4);
    i0.ɵɵpipe(5, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(5, 1, ctx_r9.systemMetrics["process.files.open"], "1.0-0"));
} }
export class JhiMetricsSystemComponent {
    convertMillisecondsToDuration(ms) {
        const times = {
            year: 31557600000,
            month: 2629746000,
            day: 86400000,
            hour: 3600000,
            minute: 60000,
            second: 1000
        };
        let timeString = '';
        for (const key in times) {
            if (Math.floor(ms / times[key]) > 0) {
                let plural = '';
                if (Math.floor(ms / times[key]) > 1) {
                    plural = 's';
                }
                timeString += Math.floor(ms / times[key]).toString() + ' ' + key.toString() + plural + ' ';
                ms = ms - times[key] * Math.floor(ms / times[key]);
            }
        }
        return timeString;
    }
}
JhiMetricsSystemComponent.ɵfac = function JhiMetricsSystemComponent_Factory(t) { return new (t || JhiMetricsSystemComponent)(); };
JhiMetricsSystemComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiMetricsSystemComponent, selectors: [["jhi-metrics-system"]], inputs: { systemMetrics: "systemMetrics", updating: "updating" }, decls: 12, vars: 10, consts: [["class", "row", 4, "ngIf"], ["type", "success", 3, "value", "striped", "animated", 4, "ngIf"], [1, "row"], [1, "col-md-4"], [1, "col-md-8", "text-right"], [1, "col-md-9"], [1, "col-md-3", "text-right"], ["type", "success", 3, "value", "striped", "animated"]], template: function JhiMetricsSystemComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "h4");
        i0.ɵɵtext(1, "System");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, JhiMetricsSystemComponent_div_2_Template, 5, 1, "div", 0);
        i0.ɵɵtemplate(3, JhiMetricsSystemComponent_div_3_Template, 6, 4, "div", 0);
        i0.ɵɵtemplate(4, JhiMetricsSystemComponent_div_4_Template, 6, 4, "div", 0);
        i0.ɵɵtemplate(5, JhiMetricsSystemComponent_ngb_progressbar_5_Template, 4, 7, "ngb-progressbar", 1);
        i0.ɵɵtemplate(6, JhiMetricsSystemComponent_div_6_Template, 6, 4, "div", 0);
        i0.ɵɵtemplate(7, JhiMetricsSystemComponent_ngb_progressbar_7_Template, 4, 7, "ngb-progressbar", 1);
        i0.ɵɵtemplate(8, JhiMetricsSystemComponent_div_8_Template, 5, 1, "div", 0);
        i0.ɵɵtemplate(9, JhiMetricsSystemComponent_div_9_Template, 6, 4, "div", 0);
        i0.ɵɵtemplate(10, JhiMetricsSystemComponent_div_10_Template, 6, 4, "div", 0);
        i0.ɵɵtemplate(11, JhiMetricsSystemComponent_div_11_Template, 6, 4, "div", 0);
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.updating);
    } }, directives: [i1.NgIf, i2.NgbProgressbar], pipes: [i1.DatePipe, i1.DecimalPipe], encapsulation: 2 });
