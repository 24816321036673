import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import { BaseComponent } from '../../../../shared/base.component';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "ngx-webstorage";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "../../../../ng-jhipster/language/jhi-translate.directive";
const _c0 = function (a0, a1) { return { percent: a0, total: a1 }; };
function OrganizationStatsComponent_ng_container_0_p_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "p", 10);
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction2(1, _c0, ctx_r1.registeredUserPercentage || 0, ctx_r1.report.usersCount || 0));
} }
function OrganizationStatsComponent_ng_container_0_p_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "p", 10);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction2(1, _c0, ctx_r2.bookedSessionsUserPercentage || 0, ctx_r2.report.usersCount || 0));
} }
function OrganizationStatsComponent_ng_container_0_p_19_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "p", 10);
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction2(1, _c0, ctx_r3.srqCompletedUserPercentage || 0, ctx_r3.report.usersCount || 0));
} }
function OrganizationStatsComponent_ng_container_0_p_25_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "p", 10);
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction2(1, _c0, ctx_r4.endedSessionsUserPercentage || 0, ctx_r4.report.usersCount || 0));
} }
function OrganizationStatsComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵelement(3, "div", 3);
    i0.ɵɵelementStart(4, "div", 4);
    i0.ɵɵelementStart(5, "h1", 5);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, OrganizationStatsComponent_ng_container_0_p_7_Template, 1, 4, "p", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 2);
    i0.ɵɵelement(9, "div", 7);
    i0.ɵɵelementStart(10, "div", 4);
    i0.ɵɵelementStart(11, "h1", 5);
    i0.ɵɵtext(12);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(13, OrganizationStatsComponent_ng_container_0_p_13_Template, 1, 4, "p", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "div", 2);
    i0.ɵɵelement(15, "div", 8);
    i0.ɵɵelementStart(16, "div", 4);
    i0.ɵɵelementStart(17, "h1", 5);
    i0.ɵɵtext(18);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(19, OrganizationStatsComponent_ng_container_0_p_19_Template, 1, 4, "p", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "div", 2);
    i0.ɵɵelement(21, "div", 9);
    i0.ɵɵelementStart(22, "div", 4);
    i0.ɵɵelementStart(23, "h1", 5);
    i0.ɵɵtext(24);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(25, OrganizationStatsComponent_ng_container_0_p_25_Template, 1, 4, "p", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵstyleProp("grid-template-columns", ctx_r0.getGridColumnNumber());
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(ctx_r0.report.registeredUsersCount || 0);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", (ctx_r0.parameters.departments === null || ctx_r0.parameters.departments === undefined) && ctx_r0.report.usersCount != 0);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(ctx_r0.report.bookedSessionsUsersCount || 0);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", (ctx_r0.parameters.departments === null || ctx_r0.parameters.departments === undefined) && ctx_r0.report.usersCount != 0);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(ctx_r0.report.srqUsersCount || 0);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", (ctx_r0.parameters.departments === null || ctx_r0.parameters.departments === undefined) && ctx_r0.report.usersCount != 0);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(ctx_r0.report.endedSessionsUsersCount || 0);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", (ctx_r0.parameters.departments === null || ctx_r0.parameters.departments === undefined) && ctx_r0.report.usersCount != 0);
} }
export class OrganizationStatsComponent extends BaseComponent {
    constructor(organizationDashboardService, sessionStorage, modalService) {
        super(sessionStorage, modalService);
        this.organizationDashboardService = organizationDashboardService;
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.isLoadingData = false;
        this.registeredUserPercentage = 0;
        this.bookedSessionsUserPercentage = 0;
        this.endedSessionsUserPercentage = 0;
        this.srqCompletedUserPercentage = 0;
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationStatsReport(this.parameters).subscribe(response => {
            this.report = response.body;
            console.log(this.report);
            let registeredUserPercentage = Math.round((this.report.registeredUsersCount * 100 / this.report.usersCount) * 10) / 10;
            let bookedSessionsUserPercentage = Math.round((this.report.bookedSessionsUsersCount * 100 / this.report.usersCount) * 10) / 10;
            let srqCompletedUserPercentage = Math.round((this.report.srqUsersCount * 100 / this.report.usersCount) * 10) / 10;
            let endedSessionsUserPercentage = Math.round((this.report.endedSessionsUsersCount * 100 / this.report.usersCount) * 10) / 10;
            if (registeredUserPercentage != null)
                this.registeredUserPercentage = registeredUserPercentage;
            if (bookedSessionsUserPercentage != null)
                this.bookedSessionsUserPercentage = bookedSessionsUserPercentage;
            if (srqCompletedUserPercentage != null)
                this.srqCompletedUserPercentage = srqCompletedUserPercentage;
            if (endedSessionsUserPercentage != null)
                this.endedSessionsUserPercentage = endedSessionsUserPercentage;
            this.isLoadingData = false;
        }, error => {
            console.log(error);
            this.isLoadingData = false;
        });
    }
    getGridColumnNumber() {
        return this.windowWidth > 600 ? "auto auto" : "auto";
    }
}
OrganizationStatsComponent.ɵfac = function OrganizationStatsComponent_Factory(t) { return new (t || OrganizationStatsComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService), i0.ɵɵdirectiveInject(i2.SessionStorageService), i0.ɵɵdirectiveInject(i3.NgbModal)); };
OrganizationStatsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationStatsComponent, selectors: [["organization-stats"]], inputs: { parameters: "parameters" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 1, vars: 1, consts: [[4, "ngIf"], [1, "grid-container"], [1, "card", "dashboard-card", "p-3", "two-line-stat"], ["jhiTranslate", "organizationDashboard.overview.stats.registeredUserCount"], [1, "d-flex", "justify-content-start", "align-items-end", 2, "width", "100%"], [1, "description-header"], ["class", "description", "jhiTranslate", "organizationDashboard.overview.stats.invitedEmployees", 3, "translateValues", 4, "ngIf"], ["jhiTranslate", "organizationDashboard.overview.stats.bookedSessionsUsersCount"], ["jhiTranslate", "organizationDashboard.overview.stats.srqUsersCount"], ["jhiTranslate", "organizationDashboard.overview.stats.endedSessionsUsersCount"], ["jhiTranslate", "organizationDashboard.overview.stats.invitedEmployees", 1, "description", 3, "translateValues"]], template: function OrganizationStatsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, OrganizationStatsComponent_ng_container_0_Template, 26, 10, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.report);
    } }, directives: [i4.NgIf, i5.JhiTranslateDirective], styles: [".grid-container[_ngcontent-%COMP%]{display:grid;grid-gap:15px !important;margin:auto}.dashboard-card[_ngcontent-%COMP%]{height:165px !important;width:auto !important}.description-header[_ngcontent-%COMP%]{margin-bottom:0 !important;line-height:60px}.description[_ngcontent-%COMP%]{margin-bottom:5px;margin-left:10px;color:#9db7b0;font-family:\"Open Sans\",serif;font-size:14px;font-style:normal;font-weight:400;line-height:120%}h1[_ngcontent-%COMP%]{font-size:60px}h2[_ngcontent-%COMP%]{font-size:34px}.row[_ngcontent-%COMP%]{padding-right:0;padding-left:0}.row[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{padding-left:10px;padding-right:10px}.single-ling-stat[_ngcontent-%COMP%]{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;gap:10px;height:auto !important}.single-ling-stat[_ngcontent-%COMP%] > h1[_ngcontent-%COMP%], .single-ling-stat[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{margin:0 !important;line-height:100% !important}.two-line-stat[_ngcontent-%COMP%]{display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start;gap:10px;height:100%}.two-line-stat[_ngcontent-%COMP%] > h1[_ngcontent-%COMP%], .two-line-stat[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{margin:0 !important;line-height:100% !important}.flex-two-col[_ngcontent-%COMP%]{flex:0 0 49%}.mobile-view-stats[_ngcontent-%COMP%]   .two-line-stat[_ngcontent-%COMP%]{height:95px;gap:unset}"] });
