import { ElementRef } from '@angular/core';
import { OrganizationDashboardService } from '../organization-dashboard.service';
import { AccountService } from '../../../core/auth/account.service';
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "../organization-dashboard.service";
import * as i2 from "../../../core/auth/account.service";
import * as i3 from "@angular/router";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "@angular/common";
function OrganizationSelectionComponent_ng_container_7_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 7);
    i0.ɵɵlistener("click", function OrganizationSelectionComponent_ng_container_7_Template_div_click_1_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.redirectToHomePage(); });
    i0.ɵɵelementStart(2, "div", 8);
    i0.ɵɵelement(3, "img", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 10);
    i0.ɵɵelementStart(5, "h5");
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 11);
    i0.ɵɵtext(8, "Inuka client");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.currentUser.login, " ");
} }
function OrganizationSelectionComponent_ng_container_8_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵlistener("click", function OrganizationSelectionComponent_ng_container_8_div_1_Template_div_click_0_listener() { const restoredCtx = i0.ɵɵrestoreView(_r7); const organization_r5 = restoredCtx.$implicit; const ctx_r6 = i0.ɵɵnextContext(2); return ctx_r6.redirectToOrganizationDashboard(organization_r5); });
    i0.ɵɵelementStart(1, "div", 8);
    i0.ɵɵelement(2, "img", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 10);
    i0.ɵɵelementStart(4, "h5");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 14);
    i0.ɵɵtext(7, "Organization manager");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const organization_r5 = ctx.$implicit;
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" ", organization_r5.name, " ");
} }
function OrganizationSelectionComponent_ng_container_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, OrganizationSelectionComponent_ng_container_8_div_1_Template, 8, 1, "div", 12);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r1.organizations);
} }
export class OrganizationSelectionComponent {
    constructor(organizationDashboardService, accountService, router, elementRef) {
        this.organizationDashboardService = organizationDashboardService;
        this.accountService = accountService;
        this.router = router;
        this.elementRef = elementRef;
    }
    ngOnInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#FFFFFF';
        this.loadOrganizations();
        this.currentUser = this.accountService.getCurrentLoggedInUser();
    }
    loadOrganizations() {
        this.organizationDashboardService.getUserAuthorizedOrganizations().subscribe(value => {
            this.organizations = value.body;
        });
    }
    redirectToHomePage() {
        if (this.accountService.hasAnyAuthority(['ROLE_COACH'])) {
            this.router.navigate(['/index-coach']);
            return;
        }
        else if (this.accountService.hasAnyAuthority(['ROLE_CUSTOMER_CARE'])) {
            this.router.navigate(['/session/inbox/all']);
            return;
        }
        else if (this.accountService.hasAnyAuthority(['ROLE_SUPERVISOR', 'ROLE_ADMIN', 'ROLE_MASTERMIND'])) {
            this.router.navigate(['/admin/session-management']);
            return;
        }
        else if (this.accountService.hasAnyAuthority(['ROLE_USER'])) {
            this.router.navigate(['/index-user']);
            return;
        }
        else {
            console.log('Invalid authority');
        }
    }
    redirectToOrganizationDashboard(organizationInfo) {
        this.router.navigate([`/organization-dashboard-index/${organizationInfo.id}`]);
    }
}
OrganizationSelectionComponent.ɵfac = function OrganizationSelectionComponent_Factory(t) { return new (t || OrganizationSelectionComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService), i0.ɵɵdirectiveInject(i2.AccountService), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i0.ElementRef)); };
OrganizationSelectionComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationSelectionComponent, selectors: [["organization-selection"]], decls: 9, vars: 2, consts: [[1, "olha-theme"], [1, "d-flex", "justify-content-center", "mt-5"], [1, "d-flex", "bg-2", "mt-5", "mb-5", "flex-column", "py-4", "text-center", "align-items-center", 2, "padding-left", "10%", "padding-right", "10%"], [1, "text-center"], ["src", "../../../../../content/images/inuka-logo-top.svg", "alt", "inuka-logo-top", "width", "70"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSelection.header", 1, "my-4"], [4, "ngIf"], [1, "account-card", "p-3", "d-flex", "flex-row", "justify-content-start", "pl-4", 3, "click"], [1, "icon-circle"], ["src", "../../../../../content/images/user-account.svg", "width", "44"], [1, "d-flex", "flex-column", "pl-3", "text-left"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSelection.userProfile", 1, "text-lighter"], ["class", "account-card p-3 d-flex flex-row justify-content-start pl-4", 3, "click", 4, "ngFor", "ngForOf"], ["src", "../../../../../content/images/organization-admin-account.svg", "width", "44"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationSelection.organizationDashboard", 1, "text-lighter"]], template: function OrganizationSelectionComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelement(4, "img", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "h3", 5);
        i0.ɵɵtext(6, "Select the account to proceed ");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(7, OrganizationSelectionComponent_ng_container_7_Template, 9, 1, "ng-container", 6);
        i0.ɵɵtemplate(8, OrganizationSelectionComponent_ng_container_8_Template, 2, 1, "ng-container", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(7);
        i0.ɵɵproperty("ngIf", ctx.currentUser);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.organizations);
    } }, directives: [i4.JhiTranslateDirective, i5.NgIf, i5.NgForOf], styles: [".container-index-user[_ngcontent-%COMP%]{padding-top:80px !important}.account-card[_ngcontent-%COMP%]{background-color:#fff;border-radius:10px;margin-bottom:10px;width:100%}.account-card[_ngcontent-%COMP%]:hover{background-color:var(--main);cursor:pointer;box-shadow:0px 10px 20px 0px rgba(0,0,0,.13)}.account-card[_ngcontent-%COMP%]:hover   h5[_ngcontent-%COMP%]{color:#fff !important}.account-card[_ngcontent-%COMP%]:hover   div[_ngcontent-%COMP%]{color:#cedbd7 !important}.icon-circle[_ngcontent-%COMP%]{width:44px;height:44px}"] });
