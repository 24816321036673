import { RouterModule } from '@angular/router';
import { InukaNgSharedModule } from '../../shared/shared.module';
import { sessionRoute } from './session.route';
import { InukaNgSRQCompletedModule } from '../srq-completed/srq-completed.module';
import { PaymentModule } from '../payment/payment.module';
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { InukaNgActionPlanModule } from '../action-plan/action-plan.module';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { InukaNgVideoCallModule } from '../video-call/video-call.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class InukaNgSessionModule {
}
InukaNgSessionModule.ɵfac = function InukaNgSessionModule_Factory(t) { return new (t || InukaNgSessionModule)(); };
InukaNgSessionModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgSessionModule });
InukaNgSessionModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [], imports: [[InukaNgSharedModule, RouterModule.forChild(sessionRoute), InukaNgSRQCompletedModule, PaymentModule, PickerModule, InukaNgActionPlanModule, MatTooltipModule, MatCheckboxModule, InukaNgVideoCallModule]] });
