import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PricingService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/pricings';
    }
    create(pricing) {
        return this.http.post(this.resourceUrl, pricing, { observe: 'response' });
    }
    update(pricing) {
        return this.http.put(`${this.resourceUrl}/${pricing.id}`, pricing, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    productsAndPrices() {
        return this.http.get(SERVER_API_URL + 'api/get');
    }
    productsAndPricesById(selectedUserId) {
        return this.http.get(`${this.resourceUrl}/by-user/${selectedUserId}`);
    }
}
PricingService.ɵfac = function PricingService_Factory(t) { return new (t || PricingService)(i0.ɵɵinject(i1.HttpClient)); };
PricingService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PricingService, factory: PricingService.ɵfac, providedIn: 'root' });
