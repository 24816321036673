import * as i0 from "@angular/core";
import * as i1 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
function WellbeingResultPurposeChartComponent_ng_template_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelement(1, "p", 11);
    i0.ɵɵelementEnd();
} }
function WellbeingResultPurposeChartComponent_div_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "p", 12);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("jhiTranslate", "wellBeingResult.moodIndicator.purpose." + ctx_r2.purposeStage);
} }
export class WellbeingResultPurposeChartComponent {
    constructor() { }
    set testResult(value) {
        this._testResult = value;
        this.determinePurposeStage(value);
    }
    get testResult() {
        return this._testResult;
    }
    ngOnInit() {
    }
    determinePurposeStage(testResult) {
        this.percentOutlookScore = this.testResult.outlookScore * (100 / 4);
        if (testResult.outlookScore <= 1) {
            this.purposeStage = 'danger';
        }
        else if (testResult.outlookScore === 2) {
            this.purposeStage = 'warning';
        }
        else if (testResult.outlookScore >= 3) {
            this.purposeStage = 'success';
        }
        else {
            this.purposeStage = '';
        }
    }
}
WellbeingResultPurposeChartComponent.ɵfac = function WellbeingResultPurposeChartComponent_Factory(t) { return new (t || WellbeingResultPurposeChartComponent)(); };
WellbeingResultPurposeChartComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WellbeingResultPurposeChartComponent, selectors: [["wellbeing-result-purpose-chart"]], inputs: { testResult: "testResult" }, decls: 15, vars: 9, consts: [[1, "row", "m-0", "justify-content-between", "align-items-center"], [1, "mb-4"], ["jhiTranslate", "wellBeingResult.moodIndicator.purpose.title", 2, "font-weight", "bold", "text-transform", "uppercase"], ["icon", "info-circle", 1, "ml-2", 2, "cursor", "pointer", 3, "ngbPopover", "closeDelay"], [2, "font-weight", "bold", "font-size", "20px", 3, "ngClass"], ["purposeStagePopover", ""], [1, "row", "m-0", 2, "position", "relative", "display", "flex", "justify-content", "left"], [1, "progress", "progress-bar-horizontal-dela", 2, "height", "10px"], ["role", "progressbar", "aria-valuemin", "0", "aria-valuemax", "100", 1, "progress-bar", "s-resilient-details", 3, "ngClass"], [4, "ngIf"], [1, "mt-2"], ["jhiTranslate", "wellBeingResult.moodIndicator.purpose.description"], [1, "text-justify", "mt-3", 3, "jhiTranslate"]], template: function WellbeingResultPurposeChartComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "span", 1);
        i0.ɵɵelement(2, "span", 2);
        i0.ɵɵelement(3, "fa-icon", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "span");
        i0.ɵɵelementStart(5, "span", 4);
        i0.ɵɵtext(6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "span");
        i0.ɵɵtext(8, "/4");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(9, WellbeingResultPurposeChartComponent_ng_template_9_Template, 2, 0, "ng-template", null, 5, i0.ɵɵtemplateRefExtractor);
        i0.ɵɵelementStart(11, "div", 6);
        i0.ɵɵelementStart(12, "div", 7);
        i0.ɵɵelement(13, "div", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(14, WellbeingResultPurposeChartComponent_div_14_Template, 2, 1, "div", 9);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        const _r0 = i0.ɵɵreference(10);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngbPopover", _r0)("closeDelay", 1000);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", "text-" + ctx.purposeStage);
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate(ctx.testResult == null ? null : ctx.testResult.outlookScore);
        i0.ɵɵadvance(7);
        i0.ɵɵstyleProp("width", ctx.percentOutlookScore + "%");
        i0.ɵɵproperty("ngClass", "bg-" + ctx.purposeStage);
        i0.ɵɵattribute("aria-valuenow", ctx.percentOutlookScore);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.purposeStage != null);
    } }, directives: [i1.JhiTranslateDirective, i2.FaIconComponent, i3.NgbPopover, i4.NgClass, i4.NgIf], styles: [".progress[_ngcontent-%COMP%]{background-color:#e1e1e1}.progress-bar-horizontal-dela[_ngcontent-%COMP%]{width:100%;text-align:center;z-index:130;border-radius:8px}.progress-bar[_ngcontent-%COMP%]{z-index:120}.s-resilient-details[_ngcontent-%COMP%]{background-color:#a9d3c7;color:#000}"] });
