export class NotificationPreference {
    constructor(id, group, channel, enabled, createdDate, lastModifiedDate, reasonOfDisabling, user) {
        this.id = id;
        this.group = group;
        this.channel = channel;
        this.enabled = enabled;
        this.createdDate = createdDate;
        this.lastModifiedDate = lastModifiedDate;
        this.reasonOfDisabling = reasonOfDisabling;
        this.user = user;
        this.enabled = this.enabled || false;
    }
}
