import { JhiEventManager } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../ng-jhipster/service";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../util/long-press.directive";
import * as i4 from "@angular/router";
import * as i5 from "../support/support-dropdown.component";
import * as i6 from "../language/language-selector/language-selector.component";
export class UnauthHeaderComponent {
    constructor(eventManager) {
        this.eventManager = eventManager;
    }
    toggleConsoleLogViewer() {
        this.eventManager.broadcast('toggleConsoleLogViewer');
    }
}
UnauthHeaderComponent.ɵfac = function UnauthHeaderComponent_Factory(t) { return new (t || UnauthHeaderComponent)(i0.ɵɵdirectiveInject(i1.JhiEventManager)); };
UnauthHeaderComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: UnauthHeaderComponent, selectors: [["unauth-header"]], decls: 10, vars: 0, consts: [[1, "olha-theme"], [1, "navbar", "navbar-dark", 2, "height", "60px"], ["longPress", "", 1, "d-flex", "align-items-center", "left-side-margin", "d-md-none", 3, "mouseLongPress"], ["routerLink", "/login"], [2, "width", "4.7rem!important", "height", "30px"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#inuka-logo-left"], [1, "d-flex", "align-items-center", "left-side-margin", "d-none", "d-md-block", "d-lg-block", "d-xl-block"], [1, "d-flex", "align-items-center", "right-side-margin"], [1, "ml-3", "mr-3"]], template: function UnauthHeaderComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "nav", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵlistener("mouseLongPress", function UnauthHeaderComponent_Template_div_mouseLongPress_2_listener() { return ctx.toggleConsoleLogViewer(); });
        i0.ɵɵelementStart(3, "a", 3);
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(4, "svg", 4);
        i0.ɵɵelement(5, "use", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵnamespaceHTML();
        i0.ɵɵelement(6, "div", 6);
        i0.ɵɵelementStart(7, "div", 7);
        i0.ɵɵelement(8, "support-dropdown", 8);
        i0.ɵɵelement(9, "language-selector", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, directives: [i2.NgbNavbar, i3.LongPressDirective, i4.RouterLinkWithHref, i5.SupportDropdownComponent, i6.LanguageSelectorComponent], styles: [".right-side-margin[_ngcontent-%COMP%]{margin-right:20px !important}.left-side-margin[_ngcontent-%COMP%]{margin-left:20px !important}@media(min-width: 768px){.left-side-margin[_ngcontent-%COMP%]{margin-left:84px !important}.right-side-margin[_ngcontent-%COMP%]{margin-right:84px !important}}"] });
