import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationPreferenceService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/notification-preferences';
        this.nonAuthenticatedResourceUrl = SERVER_API_URL + 'api/na/notification-preferences';
    }
    create(notificationPreference) {
        const copy = this.convertDateFromClient(notificationPreference);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(notificationPreference) {
        const copy = this.convertDateFromClient(notificationPreference);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' });
    }
    queryForCurrentUser() {
        return this.http
            .get(`${this.resourceUrl}/current`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(notificationPreference) {
        const copy = Object.assign({}, notificationPreference, {
            createdDate: notificationPreference.createdDate && notificationPreference.createdDate.isValid()
                ? notificationPreference.createdDate.toJSON()
                : undefined,
            lastModifiedDate: notificationPreference.lastModifiedDate && notificationPreference.lastModifiedDate.isValid()
                ? notificationPreference.lastModifiedDate.toJSON()
                : undefined,
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
            res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((notificationPreference) => {
                notificationPreference.createdDate = notificationPreference.createdDate ? moment(notificationPreference.createdDate) : undefined;
                notificationPreference.lastModifiedDate = notificationPreference.lastModifiedDate
                    ? moment(notificationPreference.lastModifiedDate)
                    : undefined;
            });
        }
        return res;
    }
    updateEmailNotificationPreference(publicId, updateNotificationPreferenceDto) {
        return this.http
            .put(`${this.nonAuthenticatedResourceUrl}/email/${publicId}`, updateNotificationPreferenceDto, { observe: 'response' });
    }
}
NotificationPreferenceService.ɵfac = function NotificationPreferenceService_Factory(t) { return new (t || NotificationPreferenceService)(i0.ɵɵinject(i1.HttpClient)); };
NotificationPreferenceService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NotificationPreferenceService, factory: NotificationPreferenceService.ɵfac, providedIn: 'root' });
