import { icon } from "@fortawesome/fontawesome-svg-core";
import { CoachDetails } from '../model/coach-details.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "../../ng-jhipster/language/jhi-translate.directive";
function BadgeComponent_div_1_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵelement(1, "fa-icon", 6);
    i0.ɵɵelement(2, "span", 7);
    i0.ɵɵelementEnd();
} }
function BadgeComponent_div_1_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelement(1, "fa-icon", 9);
    i0.ɵɵelement(2, "span", 10);
    i0.ɵɵelementEnd();
} }
function BadgeComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, BadgeComponent_div_1_div_1_Template, 3, 0, "div", 3);
    i0.ɵɵtemplate(2, BadgeComponent_div_1_div_2_Template, 3, 0, "div", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("flex-direction", ctx_r0.isColumn ? "column" : "");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.coachDetails == null ? null : ctx_r0.coachDetails.psychologist);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.coachDetails == null ? null : ctx_r0.coachDetails.colleague);
} }
export class BadgeComponent {
    constructor() {
        this.icon = icon;
    }
    ngOnInit() {
    }
}
BadgeComponent.ɵfac = function BadgeComponent_Factory(t) { return new (t || BadgeComponent)(); };
BadgeComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: BadgeComponent, selectors: [["badge-component"]], inputs: { coachDetails: "coachDetails", isColumn: "isColumn" }, decls: 2, vars: 1, consts: [[1, "olha-theme"], ["class", "d-flex align-items-center", 3, "flex-direction", 4, "ngIf"], [1, "d-flex", "align-items-center"], ["class", "d-flex justify-content-center align-items-center badge badge-psychologist", 4, "ngIf"], ["class", "d-flex justify-content-center align-items-center badge badge-colleague", 4, "ngIf"], [1, "d-flex", "justify-content-center", "align-items-center", "badge", "badge-psychologist"], ["icon", "user-graduate"], ["id", "hide-psychologist", "jhiTranslate", "settings.headers.psychologist"], [1, "d-flex", "justify-content-center", "align-items-center", "badge", "badge-colleague"], ["icon", "user-tie"], ["id", "hide-colleague", "jhiTranslate", "settings.headers.yourColleague"]], template: function BadgeComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, BadgeComponent_div_1_Template, 3, 4, "div", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.coachDetails != null);
    } }, directives: [i1.NgIf, i2.FaIconComponent, i3.JhiTranslateDirective], styles: [".badge[_ngcontent-%COMP%]{background:rgba(68,163,141,.1);color:#44a38b;border-radius:16px;margin:5px 5px 5px 0;transition:width 1s;line-height:19px}.badge-psychologist[_ngcontent-%COMP%]{padding:5px 3px 5px 8px}.badge-colleague[_ngcontent-%COMP%]{padding:5px 5px 5px 10px}span[_ngcontent-%COMP%]{border-radius:8px;cursor:pointer;margin-left:5px}#hide-psychologist[_ngcontent-%COMP%]{max-width:0;transition:max-width 1s;overflow:hidden}#hide-colleague[_ngcontent-%COMP%]{max-width:0;transition:max-width 1s;overflow:hidden}.badge[_ngcontent-%COMP%]:hover   #hide-psychologist[_ngcontent-%COMP%]{max-width:300px;padding-right:6px}.badge[_ngcontent-%COMP%]:hover   #hide-colleague[_ngcontent-%COMP%]{max-width:300px;padding-right:6px}"] });
