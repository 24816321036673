import * as i0 from "@angular/core";
import * as i1 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i2 from "@angular/router";
export class HowDoYouFeelComponent {
    constructor() { }
    ngOnInit() {
    }
}
HowDoYouFeelComponent.ɵfac = function HowDoYouFeelComponent_Factory(t) { return new (t || HowDoYouFeelComponent)(); };
HowDoYouFeelComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: HowDoYouFeelComponent, selectors: [["how-do-you-feel"]], decls: 8, vars: 0, consts: [[1, "card"], [1, "card-body"], ["jhiTranslate", "inukaNgApp.indexUser.howDoYouFeel.question", 1, "d-lg-inline-block", "mt-2", "text-center", "text-lg-left"], [1, "d-lg-inline", "float-lg-right"], [1, "d-block", "d-lg-inline", "mr-lg-3", "text-center", "pt-2", "pt-lg-0"], ["routerLink", "/wellbeing-start", "jhiTranslate", "inukaNgApp.indexUser.howDoYouFeel.selfScan", 1, "btn", "btn-secondary"], [1, "d-block", "d-lg-inline", "text-center", "pt-2", "pt-lg-0"], ["routerLink", "/wellbeing-result", "jhiTranslate", "inukaNgApp.indexUser.howDoYouFeel.viewPreviousResult", 1, "btn-link"]], template: function HowDoYouFeelComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelement(2, "h3", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelement(5, "button", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 6);
        i0.ɵɵelement(7, "a", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, directives: [i1.JhiTranslateDirective, i2.RouterLink, i2.RouterLinkWithHref], styles: [""] });
