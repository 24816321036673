import { SimpleChanges, TemplateRef } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { SessionStorageService } from 'ngx-webstorage';
import { TIME_FORMAT } from './constants/input.constants';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
export class BaseComponent {
    constructor(sessionStorage, modalService) {
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.windowWidth = window.innerWidth;
        this.loggedInUserId = 0;
        this.switchTo = 'chat';
        this.debug = false;
        this.loggedInUserId = this.sessionStorage.retrieve('id');
    }
    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(result => {
            this.closeResult = `Closed with: ${result}`;
        }, reason => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    dismissAll() {
        this.modalService.dismissAll();
    }
    getDismissReason(reason) {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return `with: ${reason}`;
        }
    }
    static determineColor(score) {
        if (score > 13) {
            return '#A9D3C7';
        }
        else if (score > 3) {
            return '#EAD88C';
        }
        else {
            return '#B894AA';
        }
    }
    dateFormatted(date) {
        return moment(date).format('D MMM, YYYY');
    }
    dateFormattedNumeric(date) {
        return moment(date).format('DD-MM-YYYY');
    }
    timeDateFormatted(sessionStartTime) {
        return moment(sessionStartTime)
            .locale('en')
            .format('HH:mm on DD MMM, YYYY');
    }
    timeFormatted(createDate) {
        return moment(createDate).format(TIME_FORMAT);
    }
    isLoggedInUser(userId) {
        return userId === this.loggedInUserId;
    }
    getPeer(senderId, receiverId) {
        return this.isLoggedInUser(senderId) ? receiverId : senderId;
    }
    getOrganization() {
        return this.sessionStorage.retrieve("organization");
    }
    ngAfterContentInit() {
        if (this.debug)
            console.log(this.constructor.name + ': ngAfterContentInit');
    }
    ngAfterViewInit() {
        if (this.debug)
            console.log(this.constructor.name + ': ngAfterViewInit');
        this.windowWidth = window.innerWidth;
        this.detectScreenSize();
    }
    ngOnDestroy() {
        if (this.debug)
            console.log(this.constructor.name + ': ngOnDestroy');
    }
    ngOnInit() {
        if (this.debug)
            console.log(this.constructor.name + ': ngOnInit');
    }
    ngDoCheck() {
        // console.log(this.constructor.name + ': ngDoCheck: ' + Zone.currentTask.source);
    }
    ngOnChanges(changes) {
        if (this.debug)
            console.log(this.constructor.name + ': ngOnChanges');
    }
    toggleDropdown(event) { }
    collapseDropdown(event) { event.close(); }
    onResize() {
        if (window.innerWidth % 2 === 0) {
            this.windowWidth = window.innerWidth;
            this.detectScreenSize();
        }
        if (this.windowWidth >= 992)
            this.switchTo = 'chat';
    }
    detectScreenSize() {
        // it works the same as bootstrap responsive break points
        if (this.windowWidth < 768) {
            this.isScreenSm = true;
            this.isScreenMd = false;
            this.isScreenLg = false;
            this.isScreenXLg = false;
        }
        else if (this.windowWidth < 992) {
            this.isScreenSm = true;
            this.isScreenMd = true;
            this.isScreenLg = false;
            this.isScreenXLg = false;
        }
        else if (this.windowWidth < 1200) {
            this.isScreenMd = true;
            this.isScreenSm = true;
            this.isScreenLg = true;
            this.isScreenXLg = false;
        }
        else { // more than 1200
            this.isScreenSm = true;
            this.isScreenMd = true;
            this.isScreenLg = true;
            this.isScreenXLg = true;
        }
    }
}
BaseComponent.ɵfac = function BaseComponent_Factory(t) { return new (t || BaseComponent)(i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.NgbModal)); };
BaseComponent.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: BaseComponent, hostBindings: function BaseComponent_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("resize", function BaseComponent_resize_HostBindingHandler() { return ctx.onResize(); }, false, i0.ɵɵresolveWindow);
    } }, features: [i0.ɵɵNgOnChangesFeature] });
