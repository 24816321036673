export const STATUS_SENT_BY_SENDER = 'SENDER';
export const STATUS_RECEIVED_BY_SERVER = 'RECEIVED';
export const STATUS_DISPLAYED_ON_RECEIVER = 'DISPLAYED';
export class Message {
    constructor(id, cancelledCoachId, createDate, deleted, message, senderId, senderName, receiverId, conversation, convId, replyId, status, chosenToBeEmailed, sessionId, type, receiverReaction, replyMessage, isFirstSessionMessage, isLastSessionMessage) {
        this.id = id;
        this.cancelledCoachId = cancelledCoachId;
        this.createDate = createDate;
        this.deleted = deleted;
        this.message = message;
        this.senderId = senderId;
        this.senderName = senderName;
        this.receiverId = receiverId;
        this.conversation = conversation;
        this.convId = convId;
        this.replyId = replyId;
        this.status = status;
        this.chosenToBeEmailed = chosenToBeEmailed;
        this.sessionId = sessionId;
        this.type = type;
        this.receiverReaction = receiverReaction;
        this.replyMessage = replyMessage;
        this.isFirstSessionMessage = isFirstSessionMessage;
        this.isLastSessionMessage = isLastSessionMessage;
        this.deleted = this.deleted || false;
        this.chosenToBeEmailed = this.chosenToBeEmailed || false;
    }
}
