import { JhiLanguageService } from '../../../ng-jhipster/language';
import { JhiEventManager } from '../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../../ng-jhipster/service";
import * as i2 from "../../../ng-jhipster/language";
import * as i3 from "../../../ng-jhipster/language/jhi-translate.directive";
export class HaveQuestionComponent {
    constructor(eventManager, languageService) {
        this.eventManager = eventManager;
        this.languageService = languageService;
    }
    ngOnInit() {
    }
    openMessageUsPopup() {
        this.eventManager.broadcast('openMessageUsPopup');
    }
    openFaqPage() {
        const faqPageAddress = this.languageService.getCurrentLanguage() === 'nl' ? 'https://inukacoaching.com/nl/faq/' : 'https://inukacoaching.com/faq/';
        window.open(faqPageAddress, '_blank');
    }
}
HaveQuestionComponent.ɵfac = function HaveQuestionComponent_Factory(t) { return new (t || HaveQuestionComponent)(i0.ɵɵdirectiveInject(i1.JhiEventManager), i0.ɵɵdirectiveInject(i2.JhiLanguageService)); };
HaveQuestionComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: HaveQuestionComponent, selectors: [["have-question"]], decls: 15, vars: 0, consts: [[1, "card"], [1, "card-body"], [1, "float-left"], [1, "mr-2", 2, "width", "2rem!important", "height", "2rem!important"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#question-mark"], [1, "mt-lg-2"], [1, "d-lg-inline-block"], ["jhiTranslate", "inukaNgApp.indexUser.haveQuestion.question", 1, "text-success"], [1, "ml-5", "ml-lg-0", "mt-2", "mt-lg-0"], ["jhiTranslate", "inukaNgApp.indexUser.haveQuestion.faq", 1, "btn-link", 3, "click"], ["jhiTranslate", "inukaNgApp.indexUser.haveQuestion.or", 1, "text-success"], ["jhiTranslate", "inukaNgApp.indexUser.haveQuestion.messageUs", 1, "btn-link", 3, "click"]], template: function HaveQuestionComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelementStart(3, "svg", 3);
        i0.ɵɵelement(4, "use", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵnamespaceHTML();
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelementStart(6, "div", 6);
        i0.ɵɵelement(7, "span", 7);
        i0.ɵɵtext(8, "\u00A0 ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "span", 8);
        i0.ɵɵelementStart(10, "a", 9);
        i0.ɵɵlistener("click", function HaveQuestionComponent_Template_a_click_10_listener() { return ctx.openFaqPage(); });
        i0.ɵɵelementEnd();
        i0.ɵɵtext(11, "\u00A0 ");
        i0.ɵɵelement(12, "span", 10);
        i0.ɵɵtext(13, "\u00A0 ");
        i0.ɵɵelementStart(14, "a", 11);
        i0.ɵɵlistener("click", function HaveQuestionComponent_Template_a_click_14_listener() { return ctx.openMessageUsPopup(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, directives: [i3.JhiTranslateDirective], styles: [""] });
