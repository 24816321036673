export class User {
    constructor(id, idKey, login, firstName, lastName, email, activated, langKey, authorities, createdBy, createdDate, lastModifiedBy, lastModifiedDate, password, srqAnswered, srqScore, timezone, coachReasoning, coachScore, coachScorec, endedSessionCount, credits, region, country, voucherCode, organizationCode, organization, departments, payerTransactionID, flagged, tester, sendEmails, phoneNumber, whatsappConversationId, isWhatsappUser, isDemoUser, lastMessage, testTime, coachDisplayOrder, lastLoginDate, textChatEnabled, videoChatEnabled, introCallEnabled, psychologist, colleague) {
        this.id = id;
        this.idKey = idKey;
        this.login = login;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.activated = activated;
        this.langKey = langKey;
        this.authorities = authorities;
        this.createdBy = createdBy;
        this.createdDate = createdDate;
        this.lastModifiedBy = lastModifiedBy;
        this.lastModifiedDate = lastModifiedDate;
        this.password = password;
        this.srqAnswered = srqAnswered;
        this.srqScore = srqScore;
        this.timezone = timezone;
        this.coachReasoning = coachReasoning;
        this.coachScore = coachScore;
        this.coachScorec = coachScorec;
        this.endedSessionCount = endedSessionCount;
        this.credits = credits;
        this.region = region;
        this.country = country;
        this.voucherCode = voucherCode;
        this.organizationCode = organizationCode;
        this.organization = organization;
        this.departments = departments;
        this.payerTransactionID = payerTransactionID;
        this.flagged = flagged;
        this.tester = tester;
        this.sendEmails = sendEmails;
        this.phoneNumber = phoneNumber;
        this.whatsappConversationId = whatsappConversationId;
        this.isWhatsappUser = isWhatsappUser;
        this.isDemoUser = isDemoUser;
        this.lastMessage = lastMessage;
        this.testTime = testTime;
        this.coachDisplayOrder = coachDisplayOrder;
        this.lastLoginDate = lastLoginDate;
        this.textChatEnabled = textChatEnabled;
        this.videoChatEnabled = videoChatEnabled;
        this.introCallEnabled = introCallEnabled;
        this.psychologist = psychologist;
        this.colleague = colleague;
    }
}
