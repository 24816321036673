import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ActionPlanService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/action-plans';
    }
    create(actionPlan) {
        const copy = this.convertDateFromClient(actionPlan);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(actionPlan) {
        const copy = this.convertDateFromClient(actionPlan);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(actionPlan) {
        const copy = Object.assign({}, actionPlan, {
            creationDate: actionPlan.creationDate && actionPlan.creationDate.isValid() ? actionPlan.creationDate.toJSON() : undefined
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.creationDate = res.body.creationDate ? moment(res.body.creationDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((actionPlan) => {
                actionPlan.creationDate = actionPlan.creationDate ? moment(actionPlan.creationDate) : undefined;
            });
        }
        return res;
    }
    findActiveActionPlan() {
        return this.http
            .get(`${this.resourceUrl}/active/current-user`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    findBySessionId(sessionId) {
        return this.http
            .get(`${this.resourceUrl}/action-plan-by-session-id?sessionId=${sessionId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    findActionPlansByUserIdExcludeInvalidStatus(userId) {
        return this.http.get(`${this.resourceUrl}/action-plans-by-user-id-and-coach-id?userId=${userId}`, { observe: 'response' });
    }
}
ActionPlanService.ɵfac = function ActionPlanService_Factory(t) { return new (t || ActionPlanService)(i0.ɵɵinject(i1.HttpClient)); };
ActionPlanService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ActionPlanService, factory: ActionPlanService.ɵfac, providedIn: 'root' });
