import { ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class ActiveMenuDirective {
    constructor(el, renderer, translateService) {
        this.el = el;
        this.renderer = renderer;
        this.translateService = translateService;
    }
    ngOnInit() {
        this.translateService.onLangChange.subscribe((event) => {
            this.updateActiveFlag(event.lang);
        });
        this.updateActiveFlag(this.translateService.currentLang);
    }
    updateActiveFlag(selectedLanguage) {
        if (this.jhiActiveMenu === selectedLanguage) {
            this.renderer.addClass(this.el.nativeElement, 'active');
        }
        else {
            this.renderer.removeClass(this.el.nativeElement, 'active');
        }
    }
}
ActiveMenuDirective.ɵfac = function ActiveMenuDirective_Factory(t) { return new (t || ActiveMenuDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.TranslateService)); };
ActiveMenuDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: ActiveMenuDirective, selectors: [["", "jhiActiveMenu", ""]], inputs: { jhiActiveMenu: "jhiActiveMenu" } });
