import { JhiConfigService } from '../config.service';
import * as i0 from "@angular/core";
import * as i1 from "../config.service";
import * as i2 from "@angular/common";
/**
 * This component can be used to display a boolean value by defining the @Input attributes
 * If an attribute is not provided, default values will be applied (see JhiModuleConfig class)
 * Have a look at the following examples
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * <jhi-boolean [value]="inputBooleanVariable"></jhi-boolean>
 *
 * - Display a green check when inputBooleanVariable is true
 * - Display a red cross when inputBooleanVariable is false
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * <jhi-boolean
 *     [value]="inputBooleanVariable">
 *     classTrue="fa fa-lg fa-check text-primary"
 *     classFalse="fa fa-lg fa-times text-warning"
 * </jhi-boolean>
 *
 * - Display a blue check when inputBooleanVariable is true
 * - Display an orange cross when inputBooleanVariable is false
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * <jhi-boolean
 *     [value]="inputBooleanVariable">
 *     classTrue="fa fa-lg fa-check"
 *     classFalse=""
 * </jhi-boolean>
 *
 * - Display a black check when inputBooleanVariable is true
 * - Do not display anything when inputBooleanVariable is false
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * <jhi-boolean
 *     [value]="inputBooleanVariable"
 *     [textTrue]="'userManagement.activated' | translate"
 *     textFalse="deactivated">
 * </jhi-boolean>
 *
 * - Display a green badge when inputBooleanVariable is true
 * - Display a red badge when inputBooleanVariable is false
 *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 * <jhi-boolean
 *     [value]="user.activated"
 *     classTrue="badge badge-warning"
 *     classFalse="badge badge-info"
 *     [textTrue]="'userManagement.activated' | translate"
 *     textFalse="deactivated">
 * </jhi-boolean>
 *
 * - Display an orange badge and write 'activated' when inputBooleanVariable is true
 * - Display a blue badge and write 'deactivated' when inputBooleanVariable is false
 */
export class JhiBooleanComponent {
    constructor(configService) {
        this.config = configService.getConfig();
    }
    ngOnInit() {
        if (this.textTrue === undefined) {
            if (this.classTrue === undefined) {
                this.classTrue = this.config.classTrue;
            }
        }
        else {
            if (this.classTrue === undefined) {
                this.classTrue = this.config.classBadgeTrue;
            }
        }
        if (this.textFalse === undefined) {
            if (this.classFalse === undefined) {
                this.classFalse = this.config.classFalse;
            }
        }
        else {
            if (this.classFalse === undefined) {
                this.classFalse = this.config.classBadgeFalse;
            }
        }
    }
}
JhiBooleanComponent.ɵfac = function JhiBooleanComponent_Factory(t) { return new (t || JhiBooleanComponent)(i0.ɵɵdirectiveInject(i1.JhiConfigService)); };
JhiBooleanComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiBooleanComponent, selectors: [["jhi-boolean"]], inputs: { value: "value", classTrue: "classTrue", classFalse: "classFalse", textTrue: "textTrue", textFalse: "textFalse" }, decls: 1, vars: 2, consts: [[3, "ngClass", "innerHtml"]], template: function JhiBooleanComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "span", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngClass", ctx.value ? ctx.classTrue : ctx.classFalse)("innerHtml", ctx.value ? ctx.textTrue : ctx.textFalse, i0.ɵɵsanitizeHtml);
    } }, directives: [i2.NgClass], encapsulation: 2 });
