import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "../../../ng-jhipster/language/jhi-translate.directive";
export class CameraIsInUsePopupComponent {
    constructor(modalService) {
        this.modalService = modalService;
    }
    ngOnInit() {
    }
    close() {
        this.modalService.dismissAll();
    }
}
CameraIsInUsePopupComponent.ɵfac = function CameraIsInUsePopupComponent_Factory(t) { return new (t || CameraIsInUsePopupComponent)(i0.ɵɵdirectiveInject(i1.NgbModal)); };
CameraIsInUsePopupComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: CameraIsInUsePopupComponent, selectors: [["camera-is-in-use-popup"]], decls: 6, vars: 0, consts: [[1, "olha-theme", "px-4", "pt-1", "pb-3"], [1, "text-center"], [1, "text-right"], ["icon", "times-circle", 1, "close-icon", 3, "click"], ["jhiTranslate", "sessionChat.paragraph.cameraIsInUse", 1, "mt-4"], ["jhiTranslate", "sessionChat.paragraph.cameraIsInUseDetail"]], template: function CameraIsInUsePopupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function CameraIsInUsePopupComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelement(4, "h4", 4);
        i0.ɵɵelement(5, "p", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, directives: [i2.FaIconComponent, i3.JhiTranslateDirective], styles: [""] });
