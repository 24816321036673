import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '../../core/login/login.service';
import { LoginModalService } from '../../core/login/login-modal.service';
import { StateStorageService } from '../../core/auth/state-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/login/login.service";
import * as i2 from "../../core/login/login-modal.service";
import * as i3 from "../../core/auth/state-storage.service";
import * as i4 from "@angular/router";
export class AuthExpiredInterceptor {
    constructor(loginService, loginModalService, stateStorageService, router) {
        this.loginService = loginService;
        this.loginModalService = loginModalService;
        this.stateStorageService = stateStorageService;
        this.router = router;
    }
    intercept(request, next) {
        return next.handle(request).pipe(map(event => {
            return event;
        }), catchError(err => {
            if (err.status === 401 && err.url && !err.url.includes('api/account') && !err.url.includes('api/authentication')) {
                this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
                this.loginService.logout();
                this.router.navigate(['/login']);
                return of(undefined);
            }
            return throwError(err);
        }));
    }
}
AuthExpiredInterceptor.ɵfac = function AuthExpiredInterceptor_Factory(t) { return new (t || AuthExpiredInterceptor)(i0.ɵɵinject(i1.LoginService), i0.ɵɵinject(i2.LoginModalService), i0.ɵɵinject(i3.StateStorageService), i0.ɵɵinject(i4.Router)); };
AuthExpiredInterceptor.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthExpiredInterceptor, factory: AuthExpiredInterceptor.ɵfac });
