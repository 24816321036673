import { ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { OrganizationDashboardService } from '../organization-dashboard.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../organization-dashboard.service";
import * as i3 from "../organization-dashboard-navbar/organization-dashboard-navbar.component";
import * as i4 from "@angular/common";
import * as i5 from "../organization-dashboard-department-and-time-selection/organization-dashboard-department-and-time-selection.component";
import * as i6 from "../organization-dashboard-index-header/organization-dashboard-index-header.component";
import * as i7 from "../overview/organization-overview-dashboard/organization-overview-dashboard.component";
import * as i8 from "../wellbeing/organization-wellbeing-dashboard/organization-wellbeing-dashboard.component";
import * as i9 from "../insights/organization-insights-dashboard/organization-insights-dashboard.component";
function OrganizationDashboardIndexComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "organization-dashboard-index-header", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("selectedTab", ctx_r0.selectedTabText)("parameters", ctx_r0.organizationReportParameters);
} }
function OrganizationDashboardIndexComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "organization-overview-dashboard", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("parameters", ctx_r1.organizationReportParameters);
} }
function OrganizationDashboardIndexComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "organization-wellbeing-dashboard", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("parameters", ctx_r2.organizationReportParameters);
} }
function OrganizationDashboardIndexComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "organization-insights-dashboard", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("parameters", ctx_r3.organizationReportParameters);
} }
export class OrganizationDashboardIndexComponent {
    constructor(elementRef, route, organizationDashboardService) {
        this.elementRef = elementRef;
        this.route = route;
        this.organizationDashboardService = organizationDashboardService;
        this.selectedTab = 'overview';
        this.selectedTabText = "inukaNgApp.organizationAdmin.organizationAdminNavbar.overview";
    }
    ngOnInit() {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F8F7F2';
        this.route.params.subscribe(params => {
            let organizationId = parseInt(params['organizationId'], 10);
            if (organizationId) {
                this.organizationDashboardService.getUserAuthorizedOrganizations().subscribe(value => {
                    this.organization = value.body.find(o => o.id === organizationId);
                });
                this.organizationReportParameters = {
                    organizationId,
                    // endDate: moment(),
                    // startDate: moment().subtract(360, 'days'),
                };
            }
            else {
                console.log("The organization id can not be retrieved.");
            }
        });
    }
    getTab(tab) {
        console.log(tab);
        this.selectedTab = tab;
        this.selectedTabText = "inukaNgApp.organizationAdmin.organizationAdminNavbar." + tab;
    }
    getDepartments(departments) {
        console.log(departments);
        const newReportParameters = this.cloneReportParameters(this.organizationReportParameters);
        newReportParameters.departments = departments;
        this.organizationReportParameters = newReportParameters;
    }
    getTimeInterval(timeInterval) {
        console.log(timeInterval);
        const newReportParameters = this.cloneReportParameters(this.organizationReportParameters);
        newReportParameters.startDate = timeInterval[0];
        newReportParameters.endDate = timeInterval[1];
        this.organizationReportParameters = newReportParameters;
    }
    cloneReportParameters(reportParameters) {
        const newReportParameters = JSON.parse(JSON.stringify(reportParameters));
        return newReportParameters;
    }
}
OrganizationDashboardIndexComponent.ɵfac = function OrganizationDashboardIndexComponent_Factory(t) { return new (t || OrganizationDashboardIndexComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.OrganizationDashboardService)); };
OrganizationDashboardIndexComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationDashboardIndexComponent, selectors: [["organization-dashboard-index"]], decls: 9, vars: 5, consts: [[1, "olha-theme"], [3, "onTabChange"], [1, "container"], ["class", "organization-header-component", 4, "ngIf"], [1, "organization-selection-component", "mb-3"], [3, "departments", "onDepartmentChange", "onTimeIntervalChange"], [4, "ngIf"], [1, "organization-header-component"], [3, "selectedTab", "parameters"], [3, "parameters"]], template: function OrganizationDashboardIndexComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "organization-dashboard-navbar", 1);
        i0.ɵɵlistener("onTabChange", function OrganizationDashboardIndexComponent_Template_organization_dashboard_navbar_onTabChange_1_listener($event) { return ctx.getTab($event); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵtemplate(3, OrganizationDashboardIndexComponent_div_3_Template, 2, 2, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "organization-dashboard-department-and-time-selection", 5);
        i0.ɵɵlistener("onDepartmentChange", function OrganizationDashboardIndexComponent_Template_organization_dashboard_department_and_time_selection_onDepartmentChange_5_listener($event) { return ctx.getDepartments($event); })("onTimeIntervalChange", function OrganizationDashboardIndexComponent_Template_organization_dashboard_department_and_time_selection_onTimeIntervalChange_5_listener($event) { return ctx.getTimeInterval($event); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, OrganizationDashboardIndexComponent_div_6_Template, 2, 1, "div", 6);
        i0.ɵɵtemplate(7, OrganizationDashboardIndexComponent_div_7_Template, 2, 1, "div", 6);
        i0.ɵɵtemplate(8, OrganizationDashboardIndexComponent_div_8_Template, 2, 1, "div", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.organizationReportParameters);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("departments", ctx.organization == null ? null : ctx.organization.departments);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.selectedTab === "overview");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.selectedTab === "wellbeing");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.selectedTab === "insights");
    } }, directives: [i3.OrganizationDashboardNavbarComponent, i4.NgIf, i5.OrganizationDashboardDepartmentAndTimeSelectionComponent, i6.OrganizationDashboardIndexHeaderComponent, i7.OrganizationOverviewDashboardComponent, i8.OrganizationWellbeingDashboardComponent, i9.OrganizationInsightsDashboardComponent], styles: [".container[_ngcontent-%COMP%]{max-width:85% !important;padding-top:0;padding-left:0;padding-right:0}@media(min-width: 768px){.container[_ngcontent-%COMP%]{padding-top:50px}}"] });
