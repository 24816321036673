import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { AuthServerProvider } from '../../core/auth/auth-jwt.service';
import { AccountService } from '../../core/auth/account.service';
import { FlowManagerService } from '../../core/util/flow-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/auth/auth-jwt.service";
import * as i3 from "../../core/auth/account.service";
import * as i4 from "../../core/util/flow-manager.service";
export class RegistrationService {
    constructor(http, authServerProvider, accountService, flowManagerService) {
        this.http = http;
        this.authServerProvider = authServerProvider;
        this.accountService = accountService;
        this.flowManagerService = flowManagerService;
        this.resourceUrl = SERVER_API_URL + 'api/registration';
    }
    register(registrationInput) {
        return this.http
            .post(this.resourceUrl, registrationInput, { observe: 'response' });
    }
    emailVerification(emailVerificationInput) {
        return this.http
            .post(`${this.resourceUrl}/email-verification`, emailVerificationInput, { observe: 'response' });
    }
    resendEmailVerification(resendVerificationInput) {
        return this.http
            .post(`${this.resourceUrl}/email-verification/resend`, resendVerificationInput, { observe: 'response' });
    }
    checkRegistrationInput(registrationInput) {
        return this.http
            .post(`${this.resourceUrl}/status`, registrationInput, { observe: 'response' });
    }
    onRegistrationCompleted(registrationResult, navigateAutomatically) {
        this.authServerProvider.loginByJwtToken(registrationResult.jwtToken);
        this.accountService.identity(true).subscribe(account => {
            this.flowManagerService.continueFlowByName(registrationResult.flow);
            if (navigateAutomatically) {
                this.flowManagerService.navigateToNextPage('/index-user');
            }
        });
    }
    update(registration) {
        const copy = this.convertDateFromClient(registration);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(registration) {
        const copy = Object.assign({}, registration, {
            createDate: registration.createDate && registration.createDate.isValid() ? registration.createDate.toJSON() : undefined,
            lastVerificationTryDate: registration.lastVerificationTryDate && registration.lastVerificationTryDate.isValid()
                ? registration.lastVerificationTryDate.toJSON()
                : undefined,
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.createDate = res.body.createDate ? moment(res.body.createDate) : undefined;
            res.body.lastVerificationTryDate = res.body.lastVerificationTryDate ? moment(res.body.lastVerificationTryDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((registration) => {
                registration.createDate = registration.createDate ? moment(registration.createDate) : undefined;
                registration.lastVerificationTryDate = registration.lastVerificationTryDate
                    ? moment(registration.lastVerificationTryDate)
                    : undefined;
            });
        }
        return res;
    }
}
RegistrationService.ɵfac = function RegistrationService_Factory(t) { return new (t || RegistrationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthServerProvider), i0.ɵɵinject(i3.AccountService), i0.ɵɵinject(i4.FlowManagerService)); };
RegistrationService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RegistrationService, factory: RegistrationService.ɵfac, providedIn: 'root' });
