import { EventEmitter } from '@angular/core';
import { COACH_LABELS } from '../../../app.constants';
import { JhiLanguageService } from '../../../ng-jhipster/language';
import { JhiEventManager } from '../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../../ng-jhipster/language";
import * as i2 from "../../../ng-jhipster/service";
import * as i3 from "@angular/material/form-field";
import * as i4 from "@angular/material/select";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/core";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@ngx-translate/core";
function LabelSelectorComponent_mat_option_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 3);
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵelement(3, "fa-icon", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "span", 7);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const cl_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", cl_r1);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("icon", cl_r1.icon);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(cl_r1[ctx_r0.currentLanguage]);
} }
export class LabelSelectorComponent {
    constructor(languageService, eventManager) {
        this.languageService = languageService;
        this.eventManager = eventManager;
        this.coachLabels = COACH_LABELS;
        this.onLabelsSelected = new EventEmitter();
        this.settings = {
            badgeShowLimit: 10, maxHeight: 320,
            enableFilterSelectAll: true,
            filterSelectAllText: "",
            filterUnSelectAllText: "",
            noDataLabel: "",
            position: "",
            searchBy: [],
            searchPlaceholderText: "",
            singleSelection: false,
            primaryKey: "id",
            labelKey: "id",
            lazyLoading: true,
            enableSearchFilter: false,
            enableCheckAll: false,
            showCheckbox: true,
            text: "Coach label",
            selectAllText: 'Select all',
            unSelectAllText: 'UnSelect all',
            classes: "multi-select-dropdown",
            addNewItemOnFilter: false,
            addNewButtonText: "Add New Chapter"
        };
    }
    ngOnInit() {
        this.currentLanguage = this.languageService.getCurrentLanguage();
        this.eventManager.subscribe('langKeyChanged', () => {
            this.currentLanguage = this.languageService.getCurrentLanguage();
        });
    }
    emitSelectedLabels() {
        const labelNames = this.dropDownSelectedLabels.map(l => l.name);
        this.onLabelsSelected.emit(labelNames);
    }
}
LabelSelectorComponent.ɵfac = function LabelSelectorComponent_Factory(t) { return new (t || LabelSelectorComponent)(i0.ɵɵdirectiveInject(i1.JhiLanguageService), i0.ɵɵdirectiveInject(i2.JhiEventManager)); };
LabelSelectorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: LabelSelectorComponent, selectors: [["label-selector"]], inputs: { selectedLabels: "selectedLabels" }, outputs: { onLabelsSelected: "onLabelsSelected" }, decls: 5, vars: 5, consts: [["appearance", "outline", "floatLabel", "never", 1, "label-selector"], ["tabindex", "-1", "multiple", "", "disableOptionCentering", "true", "panelClass", "schedule-session-selectors-dropdown-panel", 3, "value", "placeholder", "valueChange", "selectionChange"], [3, "value", 4, "ngFor", "ngForOf"], [3, "value"], [1, "d-flex", "justify-content-start", "align-items-center", "mt-1"], [1, "d-flex", "justify-content-center", "align-items-center", "fa-icon-div"], [3, "icon"], [1, "ml-2"]], template: function LabelSelectorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementContainerStart(0);
        i0.ɵɵelementStart(1, "mat-form-field", 0);
        i0.ɵɵelementStart(2, "mat-select", 1);
        i0.ɵɵlistener("valueChange", function LabelSelectorComponent_Template_mat_select_valueChange_2_listener($event) { return ctx.dropDownSelectedLabels = $event; })("selectionChange", function LabelSelectorComponent_Template_mat_select_selectionChange_2_listener() { return ctx.emitSelectedLabels(); });
        i0.ɵɵpipe(3, "translate");
        i0.ɵɵtemplate(4, LabelSelectorComponent_mat_option_4_Template, 6, 3, "mat-option", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementContainerEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("value", ctx.dropDownSelectedLabels)("placeholder", i0.ɵɵpipeBind1(3, 3, "sessionSchedule.header.labelSelector"));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.coachLabels);
    } }, directives: [i3.MatFormField, i4.MatSelect, i5.NgForOf, i6.MatOption, i7.FaIconComponent], pipes: [i8.TranslatePipe], styles: [".header[_ngcontent-%COMP%]{color:var(--Text-Dark-Green, #0A4A3A);font-family:Poppins,serif;font-size:18px;font-style:normal;font-weight:600;line-height:130%}.text[_ngcontent-%COMP%]{color:var(--Text-Dark-Green, #0A4A3A);text-align:center;font-feature-settings:\"clig\" off,\"liga\" off;font-family:Open Sans,serif;font-size:12px;font-style:normal;font-weight:400;line-height:140%}.skip-link[_ngcontent-%COMP%]{text-decoration:underline;border:none;color:#9db7b0}.fa-icon-div[_ngcontent-%COMP%]{background-color:#ecf6f4;border-radius:50%;height:24px !important;width:24px !important}@media(min-width: 576px){.header[_ngcontent-%COMP%]{color:var(--Text-Dark-Green, #0A4A3A);font-family:Poppins,serif;font-size:26px;font-style:normal;font-weight:700;line-height:140%}.text[_ngcontent-%COMP%]{color:var(--Dark-Text, #0A4A3A);text-align:center;font-family:Open Sans,serif;font-size:16px;font-style:normal;font-weight:400;line-height:140%}}"] });
