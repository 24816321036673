import { RouterModule } from '@angular/router';
import { errorRoute } from './layouts/error/error.route';
import { navbarRoute } from './layouts/navbar/navbar.route';
import { DEBUG_INFO_ENABLED } from './app.constants';
import { Authority } from './shared/constants/authority.constants';
import { UserRouteAccessService } from './core/auth/user-route-access-service';
import { NavbarInukaComponent } from './entities/navbar-inuka/navbar-inuka.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const LAYOUT_ROUTES = [navbarRoute, ...errorRoute];
export class InukaNgAppRoutingModule {
}
InukaNgAppRoutingModule.ɵfac = function InukaNgAppRoutingModule_Factory(t) { return new (t || InukaNgAppRoutingModule)(); };
InukaNgAppRoutingModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgAppRoutingModule });
InukaNgAppRoutingModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[
            RouterModule.forRoot([
                {
                    path: '',
                    component: NavbarInukaComponent,
                    outlet: 'navbar'
                },
                {
                    path: 'admin',
                    data: {
                        authorities: [Authority.MASTERMIND, Authority.SUPERVISOR, Authority.ADMIN]
                    },
                    canActivate: [UserRouteAccessService],
                    loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule)
                },
                {
                    path: '',
                    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
                },
                ...LAYOUT_ROUTES
            ], { enableTracing: DEBUG_INFO_ENABLED, useHash: true, anchorScrolling: 'enabled', scrollOffset: [0, 85], scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })
        ], RouterModule] });
