export class Feedback {
    constructor(userId, creationDate, login, email, phone, message, rating, location) {
        this.userId = userId;
        this.creationDate = creationDate;
        this.login = login;
        this.email = email;
        this.phone = phone;
        this.message = message;
        this.rating = rating;
        this.location = location;
    }
}
