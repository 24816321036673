import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
function AutoDirectionSwitchComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelement(1, "span", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵclassMap(ctx_r0.showEllipsis ? "show-ellipsis w-100" : "w-100");
    i0.ɵɵstyleProp("direction", ctx_r0.direction)("text-align", ctx_r0.align);
    i0.ɵɵproperty("innerHTML", ctx_r0.innerHtml, i0.ɵɵsanitizeHtml);
} }
export class AutoDirectionSwitchComponent {
    constructor() {
        this.applyAlignment = true;
    }
    set isAutomatedMessage(value) {
        this._isAutomatedMessage = value;
    }
    get isAutomatedMessage() {
        return this._isAutomatedMessage;
    }
    set innerHtml(value) {
        this._innerHtml = value;
        this.changeDirectionIfNeeded(value);
    }
    get innerHtml() {
        return this._innerHtml;
    }
    changeDirectionIfNeeded(text) {
        if (text) {
            if (this.isContainRightToLeftChar(text)) {
                this.setDirectionToRtl();
            }
            else {
                this.setDirectionToLtr();
            }
        }
    }
    isContainRightToLeftChar(text) {
        return AutoDirectionSwitchComponent.rtlLanguagesRegexp.test(text);
    }
    setDirectionToRtl() {
        this.direction = 'rtl';
        if (this.applyAlignment) {
            this.align = 'right';
        }
        else {
            this.align = 'unset';
        }
    }
    setDirectionToLtr() {
        this.direction = 'ltr';
        if (this.applyAlignment) {
            this.align = 'left';
        }
        else {
            this.align = 'unset';
        }
    }
    ngOnInit() {
        this.innerHtml = this.isAutomatedMessage ? this.innerHtml.replace(/(?:\r\n|\r|\n)/g, '<br>') : this.innerHtml;
    }
}
AutoDirectionSwitchComponent.rtlLanguagesRegexp = /[\u0600-\u06FF]+/;
AutoDirectionSwitchComponent.ɵfac = function AutoDirectionSwitchComponent_Factory(t) { return new (t || AutoDirectionSwitchComponent)(); };
AutoDirectionSwitchComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AutoDirectionSwitchComponent, selectors: [["auto-direction-switch"]], inputs: { applyAlignment: "applyAlignment", isAutomatedMessage: "isAutomatedMessage", innerHtml: "innerHtml", showEllipsis: "showEllipsis" }, decls: 1, vars: 1, consts: [["class", "align-items-end", 4, "ngIf"], [1, "align-items-end"], [3, "innerHTML"]], template: function AutoDirectionSwitchComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, AutoDirectionSwitchComponent_div_0_Template, 2, 7, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.innerHtml);
    } }, directives: [i1.NgIf], styles: ["div[_ngcontent-%COMP%]{display:flex;display:-webkit-flex;flex-shrink:0}span[_ngcontent-%COMP%]{display:inline-block;text-decoration:none !important}.show-ellipsis[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:90% !important}"] });
