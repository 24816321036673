import { RouterModule } from '@angular/router';
import { InukaNgSharedModule } from '../../shared/shared.module';
import { sRQCompletedRoute } from './srq-completed.route';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class InukaNgSRQCompletedModule {
}
InukaNgSRQCompletedModule.ɵfac = function InukaNgSRQCompletedModule_Factory(t) { return new (t || InukaNgSRQCompletedModule)(); };
InukaNgSRQCompletedModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgSRQCompletedModule });
InukaNgSRQCompletedModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[InukaNgSharedModule, RouterModule.forChild(sRQCompletedRoute)]] });
