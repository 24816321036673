import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import { JhiEventManager } from '../../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "../../../../ng-jhipster/service";
import * as i3 from "@angular/common";
import * as i4 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "@fortawesome/angular-fontawesome";
function OrganizationSrqStatsComponent_ng_container_0_div_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "h1");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.report.resilientUsersCount, " ");
} }
function OrganizationSrqStatsComponent_ng_container_0_div_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "fa-icon", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "p", 15);
    i0.ɵɵelementEnd();
} }
function OrganizationSrqStatsComponent_ng_container_0_div_20_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "h1");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r3.report.atRiskUsersCount, " ");
} }
function OrganizationSrqStatsComponent_ng_container_0_div_21_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "fa-icon", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "p", 15);
    i0.ɵɵelementEnd();
} }
function OrganizationSrqStatsComponent_ng_container_0_div_27_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "h1");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r5.report.inAToughPlaceUsersCount, " ");
} }
function OrganizationSrqStatsComponent_ng_container_0_div_28_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "fa-icon", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "p", 15);
    i0.ɵɵelementEnd();
} }
function OrganizationSrqStatsComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵelementStart(3, "div", 3);
    i0.ɵɵelement(4, "div", 4);
    i0.ɵɵelementStart(5, "div");
    i0.ɵɵelementStart(6, "h1");
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 2);
    i0.ɵɵelementStart(9, "div", 3);
    i0.ɵɵelementStart(10, "div", 5);
    i0.ɵɵelement(11, "div", 6);
    i0.ɵɵelement(12, "div", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(13, OrganizationSrqStatsComponent_ng_container_0_div_13_Template, 3, 1, "div", 0);
    i0.ɵɵtemplate(14, OrganizationSrqStatsComponent_ng_container_0_div_14_Template, 4, 0, "div", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "div", 2);
    i0.ɵɵelementStart(16, "div", 3);
    i0.ɵɵelementStart(17, "div", 9);
    i0.ɵɵelement(18, "div", 6);
    i0.ɵɵelement(19, "div", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(20, OrganizationSrqStatsComponent_ng_container_0_div_20_Template, 3, 1, "div", 0);
    i0.ɵɵtemplate(21, OrganizationSrqStatsComponent_ng_container_0_div_21_Template, 4, 0, "div", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(22, "div", 2);
    i0.ɵɵelementStart(23, "div", 3);
    i0.ɵɵelementStart(24, "div", 11);
    i0.ɵɵelement(25, "div", 6);
    i0.ɵɵelement(26, "div", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(27, OrganizationSrqStatsComponent_ng_container_0_div_27_Template, 3, 1, "div", 0);
    i0.ɵɵtemplate(28, OrganizationSrqStatsComponent_ng_container_0_div_28_Template, 4, 0, "div", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(7);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.report.srqUsersCount, " ");
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("ngIf", !ctx_r0.notEnoughData);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.notEnoughData);
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("ngIf", !ctx_r0.notEnoughData);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.notEnoughData);
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("ngIf", !ctx_r0.notEnoughData);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.notEnoughData);
} }
export class OrganizationSrqStatsComponent {
    constructor(organizationDashboardService, eventManager) {
        this.organizationDashboardService = organizationDashboardService;
        this.eventManager = eventManager;
        this.isLoadingData = false;
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
        this.httpErrorListener = this.eventManager.subscribe('inukaNgApp.httpError', (response) => {
            if (response.content.error.detail === 'NotEnoughDataForOrganizationReport') {
                this.notEnoughData = true;
            }
        });
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationSrqStatsReport(this.parameters).subscribe(response => {
            this.report = response.body;
            this.isLoadingData = false;
        }, error => {
            this.isLoadingData = false;
        });
    }
}
OrganizationSrqStatsComponent.ɵfac = function OrganizationSrqStatsComponent_Factory(t) { return new (t || OrganizationSrqStatsComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService), i0.ɵɵdirectiveInject(i2.JhiEventManager)); };
OrganizationSrqStatsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationSrqStatsComponent, selectors: [["organization-srq-stats"]], inputs: { parameters: "parameters" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [1, "d-flex", "justify-content-between", "flex-wrap", 2, "gap", "8px"], [1, "stat-container"], [1, "card", "dashboard-card", "p-3", "px-4", "two-line-stat"], ["jhiTranslate", "organizationDashboard.wellbeing.stats.srqUsersCount"], [1, "d-flex", "flex-row", "justify-content-start", "align-items-center", "resilient-label"], [1, "circle", "mr-2"], ["jhiTranslate", "organizationDashboard.wellbeing.stats.resilientUsersCount"], ["class", "not-enough-data-warning d-flex flex-column justify-content-center align-items-center", 4, "ngIf"], [1, "d-flex", "flex-row", "justify-content-start", "align-items-center", "at-risk-label"], ["jhiTranslate", "organizationDashboard.wellbeing.stats.atRiskUsersCount"], [1, "d-flex", "flex-row", "justify-content-start", "align-items-center", "in-a-tough-place-label"], ["jhiTranslate", "organizationDashboard.wellbeing.stats.inAToughPlaceUsersCount"], [1, "not-enough-data-warning", "d-flex", "flex-column", "justify-content-center", "align-items-center"], ["icon", "database", "size", "2x"], ["jhiTranslate", "inukaNgApp.organizationAdmin.notEnoughData"]], template: function OrganizationSrqStatsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, OrganizationSrqStatsComponent_ng_container_0_Template, 29, 7, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.report);
    } }, directives: [i3.NgIf, i4.JhiTranslateDirective, i5.FaIconComponent], styles: ["h1[_ngcontent-%COMP%]{font-size:60px}h2[_ngcontent-%COMP%]{font-size:34px}.stat-container[_ngcontent-%COMP%]{flex:0 0 99%}.two-line-stat[_ngcontent-%COMP%]{display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start;gap:20px;height:100%}.two-line-stat[_ngcontent-%COMP%] > h1[_ngcontent-%COMP%], .two-line-stat[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{margin:0 !important;line-height:100% !important}.circle[_ngcontent-%COMP%]{width:14px;height:14px;border-radius:50%}.resilient-label[_ngcontent-%COMP%]{color:#44a38b}.resilient-label[_ngcontent-%COMP%]   .circle[_ngcontent-%COMP%]{background-color:#44a38b}.at-risk-label[_ngcontent-%COMP%]{color:#e0ca58}.at-risk-label[_ngcontent-%COMP%]   .circle[_ngcontent-%COMP%]{background-color:#e0ca58}.in-a-tough-place-label[_ngcontent-%COMP%]{color:#af3b6e}.in-a-tough-place-label[_ngcontent-%COMP%]   .circle[_ngcontent-%COMP%]{background-color:#af3b6e}.mobile-view-stats[_ngcontent-%COMP%]   .two-line-stat[_ngcontent-%COMP%]{height:95px;gap:unset}@media(min-width: 768px){.stat-container[_ngcontent-%COMP%]{flex:0 0 49%}}@media(min-width: 1200px){.stat-container[_ngcontent-%COMP%]{flex:0 0 24%}}"] });
