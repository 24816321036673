import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { SERVER_API_URL } from '../../../app.constants';
import { map } from "rxjs/operators";
import moment from "moment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OfflineMessageService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/offline-messages';
        this.pageSubject = new Subject();
        this.setPage = this.pageSubject.asObservable();
    }
    getConversations() {
        return this.http.get(SERVER_API_URL + 'api/conversations/contacts/');
    }
    getMessages(id) {
        return this.http
            .get(SERVER_API_URL + 'api/conversations/conversation/' + id)
            .pipe(map((res) => this.convertDateArrayFromServer2(res)));
    }
    convertDateArrayFromServer2(res) {
        if (res) {
            res.forEach((m) => {
                m.createDate = m.createDate ? moment(m.createDate) : undefined;
            });
        }
        return res;
    }
    getConversation(id) {
        return this.http.get(SERVER_API_URL + 'api/conversations/getConversation/' + id);
    }
    // saveMessage(message: Message): Observable<Message>{
    //   return this.http.post<Message>(SERVER_API_URL + 'api/offline-messages', message);
    // }
    deleteAllMessages(id) {
        return this.http.get(SERVER_API_URL + 'api/conversations/deleteAll/' + id);
    }
    getConversationsOfCurrentUser() {
        return this.http
            .get(SERVER_API_URL + `api/conversations/current-user-conversations`)
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    getCustomerCareConversationsOfCurrentUser() {
        return this.http
            .get(SERVER_API_URL + `api/conversations/customer-care-conversations`)
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    getConversationOfUserIdAndCurrentCoach(userId) {
        return this.http
            .get(SERVER_API_URL + `api/conversations/userId/` + userId)
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    convertDateArrayFromServer(res) {
        if (res) {
            res.forEach((c) => {
                let m = c.lastMessage;
                if (m === null)
                    return;
                m.createDate = m.createDate ? moment(m.createDate) : undefined;
            });
        }
        return res;
    }
    convertDateFromServer(res) {
        if (res) {
            let m = res.lastMessage;
            if (m)
                m.createDate = m.createDate ? moment(m.createDate) : undefined;
        }
        return res;
    }
    getConversationInfo(c) {
        return this.http.post(SERVER_API_URL + `api/conversations/current-conversation-info/`, c);
    }
    updateMessageInfo(m) {
        return this.http.post(SERVER_API_URL + `api/conversations/update-message-info/${m.id}`, m.status);
    }
    sendMessage(message) {
        if (message['notifyWellbeingPlanUpdated'] === undefined) {
            message['notifyWellbeingPlanUpdated'] = false;
        }
        return this.http.post(`${this.resourceUrl}/send`, message, { observe: 'response' });
    }
    offlineMessageSeen(id) {
        return this.http.get(`${this.resourceUrl}/message-seen?messageId=${id}`, { observe: 'response' });
    }
    goFirstPage() {
        this.pageSubject.next();
    }
}
OfflineMessageService.ɵfac = function OfflineMessageService_Factory(t) { return new (t || OfflineMessageService)(i0.ɵɵinject(i1.HttpClient)); };
OfflineMessageService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OfflineMessageService, factory: OfflineMessageService.ɵfac, providedIn: 'root' });
