import { EventEmitter } from '@angular/core';
import { VideoCallService } from '../video-call.service';
import { Session } from '../../../shared/model/session.model';
import { Router } from "@angular/router";
import { AccountService } from '../../../core/auth/account.service';
import { ZoomService } from '../zoom.service';
import * as i0 from "@angular/core";
import * as i1 from "../video-call.service";
import * as i2 from "@angular/router";
import * as i3 from "../../../core/auth/account.service";
import * as i4 from "../zoom.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../ng-jhipster/language/jhi-translate.directive";
function InProgressVideoCallIndicatorComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "img", 2);
    i0.ɵɵelementStart(3, "span", 3);
    i0.ɵɵtext(4, "Video Call is in progress");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "button", 4);
    i0.ɵɵlistener("click", function InProgressVideoCallIndicatorComponent_div_0_Template_button_click_5_listener() { i0.ɵɵrestoreView(_r2); const ctx_r1 = i0.ɵɵnextContext(); return ctx_r1.navigateToVideoSession(); });
    i0.ɵɵtext(6, "Join Call ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
export class InProgressVideoCallIndicatorComponent {
    constructor(videoCallService, router, accountService, zoomService) {
        this.videoCallService = videoCallService;
        this.router = router;
        this.accountService = accountService;
        this.zoomService = zoomService;
        this.videoCallIsInProgress = new EventEmitter();
    }
    set session(value) {
        var _a;
        if (((_a = this._session) === null || _a === void 0 ? void 0 : _a.id) !== (value === null || value === void 0 ? void 0 : value.id)) {
            this.isVideoCallInProgress = false;
            this._session = value;
            this.checkVideoCallStatus(value);
        }
    }
    get session() {
        return this._session;
    }
    ngOnDestroy() {
        var _a;
        (_a = this.onVideoCallEndedSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    ngOnInit() {
        // if (!this.onVideoCallEndedSubscription) {
        //   this.onVideoCallEndedSubscription = this.zoomService.onVideoCallEnded$().subscribe(() => {
        //     this.isVideoCallInProgress = false;
        //     if (!this.isVideoCallInProgressHttpCallSubscription?.closed) {
        //       this.isVideoCallInProgressHttpCallSubscription.unsubscribe();
        //     }
        //   });
        // }
    }
    checkVideoCallStatus(session) {
        this.isVideoCallInProgressHttpCallSubscription = this.videoCallService.isVideoCallInProgress(session).subscribe(result => {
            this.isVideoCallInProgress = result.body;
            this.videoCallIsInProgress.emit(this.isVideoCallInProgress);
        });
    }
    navigateToVideoSession() {
        if (this.accountService.isCoach()) {
            this.router.navigate(['/session/video-coach', this.session.id]).then();
        }
        else {
            // user
            this.router.navigate(['/session/video', this.session.id]).then();
        }
    }
}
InProgressVideoCallIndicatorComponent.ɵfac = function InProgressVideoCallIndicatorComponent_Factory(t) { return new (t || InProgressVideoCallIndicatorComponent)(i0.ɵɵdirectiveInject(i1.VideoCallService), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.AccountService), i0.ɵɵdirectiveInject(i4.ZoomService)); };
InProgressVideoCallIndicatorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: InProgressVideoCallIndicatorComponent, selectors: [["in-progress-video-call-indicator"]], inputs: { session: "session" }, outputs: { videoCallIsInProgress: "videoCallIsInProgress" }, decls: 1, vars: 1, consts: [["class", "d-flex align-items-center justify-content-between", "style", "gap: 0.5rem", 4, "ngIf"], [1, "d-flex", "align-items-center", "justify-content-between", 2, "gap", "0.5rem"], ["src", "../../../../../content/images/internal-video-call-icon.svg", "alt", "talking.png", 1, "mr-2"], ["jhiTranslate", "inukaNgApp.videoCall.label.inProgressVideoCall", 1, "text-main", "font-weight-bold"], ["jhiTranslate", "inukaNgApp.videoCall.button.joinCall", 1, "btn", "btn-primary", 2, "min-width", "7rem", "padding-top", "0.3rem !important", "padding-bottom", "0.3rem !important", 3, "click"]], template: function InProgressVideoCallIndicatorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, InProgressVideoCallIndicatorComponent_div_0_Template, 7, 0, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.isVideoCallInProgress);
    } }, directives: [i5.NgIf, i6.JhiTranslateDirective], styles: [""] });
