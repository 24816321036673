import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i4 from "../../shared/alert/alert-error.component";
import * as i5 from "@fortawesome/angular-fontawesome";
const _c0 = function (a1) { return ["/srq-completed", a1, "edit"]; };
function SRQCompletedDetailComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "h2");
    i0.ɵɵelementStart(2, "span", 3);
    i0.ɵɵtext(3, "SRQ Completed");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "hr");
    i0.ɵɵelement(6, "jhi-alert-error");
    i0.ɵɵelementStart(7, "dl", 4);
    i0.ɵɵelementStart(8, "dt");
    i0.ɵɵelementStart(9, "span", 5);
    i0.ɵɵtext(10, "Date Taken");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "dd");
    i0.ɵɵelementStart(12, "span");
    i0.ɵɵtext(13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "dt");
    i0.ɵɵelementStart(15, "span", 6);
    i0.ɵɵtext(16, "Score");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(17, "dd");
    i0.ɵɵelementStart(18, "span");
    i0.ɵɵtext(19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(20, "dt");
    i0.ɵɵelementStart(21, "span", 7);
    i0.ɵɵtext(22, "Body Score");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(23, "dd");
    i0.ɵɵelementStart(24, "span");
    i0.ɵɵtext(25);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(26, "dt");
    i0.ɵɵelementStart(27, "span", 8);
    i0.ɵɵtext(28, "Mood Score");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(29, "dd");
    i0.ɵɵelementStart(30, "span");
    i0.ɵɵtext(31);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(32, "dt");
    i0.ɵɵelementStart(33, "span", 9);
    i0.ɵɵtext(34, "Energy Score");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(35, "dd");
    i0.ɵɵelementStart(36, "span");
    i0.ɵɵtext(37);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(38, "dt");
    i0.ɵɵelementStart(39, "span", 10);
    i0.ɵɵtext(40, "Outlook Score");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(41, "dd");
    i0.ɵɵelementStart(42, "span");
    i0.ɵɵtext(43);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(44, "dt");
    i0.ɵɵelementStart(45, "span", 11);
    i0.ɵɵtext(46, "User");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(47, "dd");
    i0.ɵɵtext(48);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(49, "button", 12);
    i0.ɵɵlistener("click", function SRQCompletedDetailComponent_div_2_Template_button_click_49_listener() { i0.ɵɵrestoreView(_r2); const ctx_r1 = i0.ɵɵnextContext(); return ctx_r1.previousState(); });
    i0.ɵɵelement(50, "fa-icon", 13);
    i0.ɵɵtext(51, "\u00A0");
    i0.ɵɵelementStart(52, "span", 14);
    i0.ɵɵtext(53, "Back");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(54, "button", 15);
    i0.ɵɵelement(55, "fa-icon", 16);
    i0.ɵɵtext(56, "\u00A0");
    i0.ɵɵelementStart(57, "span", 17);
    i0.ɵɵtext(58, "Edit");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.sRQCompleted.id, "");
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(ctx_r0.sRQCompleted.dateTaken);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.sRQCompleted.score);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.sRQCompleted.bodyScore);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.sRQCompleted.moodScore);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.sRQCompleted.energyScore);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.sRQCompleted.outlookScore);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.sRQCompleted.user == null ? null : ctx_r0.sRQCompleted.user.login, " ");
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(9, _c0, ctx_r0.sRQCompleted.id));
} }
export class SRQCompletedDetailComponent {
    constructor(activatedRoute) {
        this.activatedRoute = activatedRoute;
        this.sRQCompleted = null;
    }
    ngOnInit() {
        this.activatedRoute.data.subscribe(({ sRQCompleted }) => (this.sRQCompleted = sRQCompleted));
    }
    previousState() {
        window.history.back();
    }
}
SRQCompletedDetailComponent.ɵfac = function SRQCompletedDetailComponent_Factory(t) { return new (t || SRQCompletedDetailComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute)); };
SRQCompletedDetailComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SRQCompletedDetailComponent, selectors: [["jhi-srq-completed-detail"]], decls: 3, vars: 1, consts: [[1, "row", "justify-content-center"], [1, "col-8"], [4, "ngIf"], ["jhiTranslate", "inukaNgApp.sRQCompleted.detail.title"], [1, "row-md", "jh-entity-details"], ["jhiTranslate", "inukaNgApp.sRQCompleted.dateTaken"], ["jhiTranslate", "inukaNgApp.sRQCompleted.score"], ["jhiTranslate", "inukaNgApp.sRQCompleted.bodyScore"], ["jhiTranslate", "inukaNgApp.sRQCompleted.moodScore"], ["jhiTranslate", "inukaNgApp.sRQCompleted.energyScore"], ["jhiTranslate", "inukaNgApp.sRQCompleted.outlookScore"], ["jhiTranslate", "inukaNgApp.sRQCompleted.user"], ["type", "submit", 1, "btn", "btn-info", 3, "click"], ["icon", "arrow-left"], ["jhiTranslate", "entity.action.back"], ["type", "button", 1, "btn", "btn-primary", 3, "routerLink"], ["icon", "pencil-alt"], ["jhiTranslate", "entity.action.edit"]], template: function SRQCompletedDetailComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵtemplate(2, SRQCompletedDetailComponent_div_2_Template, 59, 11, "div", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.sRQCompleted);
    } }, directives: [i2.NgIf, i3.JhiTranslateDirective, i4.AlertErrorComponent, i5.FaIconComponent, i1.RouterLink], encapsulation: 2 });
