import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from "@angular/forms";
import { Credentials } from '../../core/login/credentials.model';
import { UserService } from '../../core/user/user.service';
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/forms";
import * as i3 from "../../core/user/user.service";
import * as i4 from "@angular/router";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/common";
import * as i7 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i8 from "@angular/material/form-field";
import * as i9 from "@angular/material/input";
import * as i10 from "../../shared/track-user-activity.directive";
import * as i11 from "@ngx-translate/core";
function LeaveNumberModalComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelement(1, "h3", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("jhiTranslate", ctx_r0.errorMessage);
} }
function LeaveNumberModalComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelementStart(1, "a", 11);
    i0.ɵɵlistener("click", function LeaveNumberModalComponent_div_6_Template_a_click_1_listener() { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.displayNLHelp = !ctx_r3.displayNLHelp; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
const _c0 = function () { return { "track": "click", name: "leave_number_btn_clicked" }; };
function LeaveNumberModalComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵelement(1, "h4", 13);
    i0.ɵɵelementStart(2, "div", 14);
    i0.ɵɵelementStart(3, "div");
    i0.ɵɵelement(4, "fa-icon", 15);
    i0.ɵɵelementStart(5, "span");
    i0.ɵɵtext(6, "+31613433457");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 16);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(8, "svg", 17);
    i0.ɵɵelement(9, "use", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(10, "a", 19);
    i0.ɵɵtext(11, "Inuka Customer Service");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelement(12, "hr");
    i0.ɵɵelement(13, "p", 20);
    i0.ɵɵelementStart(14, "form", 21);
    i0.ɵɵlistener("ngSubmit", function LeaveNumberModalComponent_div_7_Template_form_ngSubmit_14_listener() { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.leaveNumber(); });
    i0.ɵɵelementStart(15, "div", 22);
    i0.ɵɵelementStart(16, "mat-form-field", 23);
    i0.ɵɵelementStart(17, "mat-label");
    i0.ɵɵtext(18);
    i0.ɵɵpipe(19, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(20, "input", 24);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(21, "mat-form-field", 23);
    i0.ɵɵelementStart(22, "mat-label");
    i0.ɵɵtext(23);
    i0.ɵɵpipe(24, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(25, "input", 25);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(26, "div", 26);
    i0.ɵɵelement(27, "button", 27);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(14);
    i0.ɵɵproperty("formGroup", ctx_r2.leaveNumberForm);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(19, 4, "global.form.name.placeholder"));
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(24, 6, "global.form.phone.placeholder"));
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("trackUserActivity", i0.ɵɵpureFunction0(8, _c0));
} }
export class LeaveNumberModalComponent {
    constructor(activeModal, formBuilder, userService, router) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.router = router;
        this.leaveNumberForm = this.formBuilder.group({ name: [''], phone: [''] });
        this.displayNLHelp = false;
    }
    leaveNumber() {
        let name = this.leaveNumberForm.get('name').value;
        let phone = this.leaveNumberForm.get('phone').value;
        if (name.length === 0) {
            this.errorMessageName = 'register.messages.error.nameRequired';
            return;
        }
        if (phone.length === 0) {
            this.errorMessagePhone = 'register.messages.error.phoneRequired';
            return;
        }
        let credentials = new Credentials(name, phone, false);
        this.leaveNumberSubscription = this.userService.emailCredentials(credentials).subscribe((response) => {
            console.log(response);
            this.leaveNumberModalRef.close();
        }, (error) => {
            console.log(error);
        });
    }
    modalNavigate(url) {
        this.router.navigate([url]).then(response => console.log(response));
    }
    close() {
        this.activeModal.dismiss();
    }
    ngOnDestroy() {
        if (this.leaveNumberSubscription) {
            this.leaveNumberSubscription.unsubscribe();
        }
    }
}
LeaveNumberModalComponent.ɵfac = function LeaveNumberModalComponent_Factory(t) { return new (t || LeaveNumberModalComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal), i0.ɵɵdirectiveInject(i2.FormBuilder), i0.ɵɵdirectiveInject(i3.UserService), i0.ɵɵdirectiveInject(i4.Router)); };
LeaveNumberModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: LeaveNumberModalComponent, selectors: [["leave-number-modal"]], decls: 8, vars: 3, consts: [[1, "olha-theme"], [1, "modal-body"], [1, "d-flex", "justify-content-end"], ["icon", "times-circle", 1, "close-icon", 3, "click"], [1, "modal-div"], ["class", "modal-title", 4, "ngIf"], ["class", "modal-title btn-link mt-4", 4, "ngIf"], ["class", "call-div", 4, "ngIf"], [1, "modal-title"], ["id", "modal-error-message", 3, "jhiTranslate"], [1, "modal-title", "btn-link", "mt-4"], ["jhiTranslate", "login.messages.error.helpNL", 3, "click"], [1, "call-div"], ["jhiTranslate", "login.messages.error.tania"], [1, "text-left", "mt-3"], ["icon", "phone", 2, "color", "#44a38d", "padding", "5px"], [1, "mt-2"], [2, "height", "1.2rem", "width", "1.6rem", "filter", "invert(50%) sepia(54%) saturate(385%) hue-rotate(116deg) brightness(101%) contrast(89%)"], [0, "xlink", "href", "../../../../content/images/sprite.svg?v=3#whatsapp"], ["href", "https://wa.me/31613433457", "target", "_blank", 2, "color", "#44A38D"], ["jhiTranslate", "login.messages.error.leaveNumber", 2, "font-weight", "bold", "text-align", "center"], ["role", "form", 1, "leave-number-form", 3, "formGroup", "ngSubmit"], [1, "d-flex", "flex-column", "form-inside"], ["appearance", "outline", 1, ""], ["matInput", "", "type", "text", "name", "name", "id", "name", "formControlName", "name"], ["matInput", "", "type", "tel", "name", "phone", "id", "phone", "formControlName", "phone"], [1, "text-center"], ["type", "submit", "jhiTranslate", "login.sendCredentials", 1, "btn", "btn-primary", "leave-number-button", "mt-4", 3, "trackUserActivity"]], template: function LeaveNumberModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function LeaveNumberModalComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵtemplate(5, LeaveNumberModalComponent_div_5_Template, 2, 1, "div", 5);
        i0.ɵɵtemplate(6, LeaveNumberModalComponent_div_6_Template, 2, 0, "div", 6);
        i0.ɵɵtemplate(7, LeaveNumberModalComponent_div_7_Template, 28, 9, "div", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngIf", ctx.errorMessage);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.displayNLHelpHeader);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.displayNLHelp);
    } }, directives: [i5.FaIconComponent, i6.NgIf, i7.JhiTranslateDirective, i2.ɵNgNoValidate, i2.NgControlStatusGroup, i2.FormGroupDirective, i8.MatFormField, i8.MatLabel, i9.MatInput, i2.DefaultValueAccessor, i2.NgControlStatus, i2.FormControlName, i10.TrackUserActivityDirective], pipes: [i11.TranslatePipe], styles: [".modal-body{text-align:center;padding:0 !important}.modal-div{padding:10px 20px 30px 20px !important}.close-icon{background:#fff;color:rgba(10,74,58,.2);margin:10px;cursor:pointer}.modal-title{font-weight:bold}.call-div{margin-top:30px;text-align:center}.leave-number-form .mat-form-field-appearance-outline .mat-form-field-outline{color:var(--main) !important}.leave-number-button{width:150px;height:40px;margin-top:30px}"], encapsulation: 2 });
