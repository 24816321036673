import { InukaNgSharedLibsModule } from './libs/shared-libs.module';
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ChartsModule } from "ng2-charts";
import { UiScrollModule } from "ngx-ui-scroll";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import * as i0 from "@angular/core";
export class InukaNgSharedModule {
}
InukaNgSharedModule.ɵfac = function InukaNgSharedModule_Factory(t) { return new (t || InukaNgSharedModule)(); };
InukaNgSharedModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgSharedModule });
InukaNgSharedModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[InukaNgSharedLibsModule, RouterModule, MatTooltipModule, MatCheckboxModule, ChartsModule, UiScrollModule, PickerModule], InukaNgSharedLibsModule] });
