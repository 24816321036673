import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccountService } from '../../core/auth/account.service';
import { UserService } from '../../core/user/user.service';
import { TipService } from '../../entities/tip/tip.service';
import { BaseComponent } from '../base.component';
import { SessionStorageService } from "ngx-webstorage";
import { CoachDetailsService } from '../../entities/slot/coach-details.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../core/auth/account.service";
import * as i4 from "../../core/user/user.service";
import * as i5 from "../../entities/tip/tip.service";
import * as i6 from "../../entities/slot/coach-details.service";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@angular/common";
import * as i9 from "../tip/tip.component";
import * as i10 from "../upload-image-component/profile-image.component";
import * as i11 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i12 from "@angular/router";
import * as i13 from "../badge/badge.component";
function TipFromCoachComponent_div_3_div_2_div_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "badge-component", 16);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("coachDetails", ctx_r3.coachDetails)("isColumn", true);
} }
const _c0 = function (a0) { return { coach: a0 }; };
function TipFromCoachComponent_div_3_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "hr");
    i0.ɵɵelementStart(2, "div", 7);
    i0.ɵɵelementStart(3, "div", 8);
    i0.ɵɵelementStart(4, "div", 9);
    i0.ɵɵelement(5, "profile-image", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 11);
    i0.ɵɵelementStart(7, "h4", 12);
    i0.ɵɵtext(8);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(9, "p", 13);
    i0.ɵɵtemplate(10, TipFromCoachComponent_div_3_div_2_div_10_Template, 2, 2, "div", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "div", 14);
    i0.ɵɵelementStart(12, "button", 15);
    i0.ɵɵlistener("click", function TipFromCoachComponent_div_3_div_2_Template_button_click_12_listener() { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(2); return ctx_r4.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵstyleProp("flex-direction", ctx_r1.isScreenMd ? "row" : "column");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("coachId", ctx_r1.tip.coach.id)("coachLogin", ctx_r1.tip.coach.login)("videoEnabled", ctx_r1.coachDetails == null ? null : ctx_r1.coachDetails.videoChatEnabled)("size", 90);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.tip.coach.login);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.coachDetails);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("routerLink", "session-type-selection/" + ctx_r1.tip.coach.id)("translateValues", i0.ɵɵpureFunction1(10, _c0, ctx_r1.tip.coach.login));
} }
function TipFromCoachComponent_div_3_div_3_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 14);
    i0.ɵɵelementStart(2, "button", 17);
    i0.ɵɵlistener("click", function TipFromCoachComponent_div_3_div_3_Template_button_click_2_listener() { i0.ɵɵrestoreView(_r7); const ctx_r6 = i0.ɵɵnextContext(2); return ctx_r6.close(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function TipFromCoachComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelement(1, "tip-component", 5);
    i0.ɵɵtemplate(2, TipFromCoachComponent_div_3_div_2_Template, 13, 12, "div", 6);
    i0.ɵɵtemplate(3, TipFromCoachComponent_div_3_div_3_Template, 3, 0, "div", 6);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("tip", ctx_r0.tip);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.tip.coach != null);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", (ctx_r0.tip.coach === null || ctx_r0.tip.coach === undefined) && ctx_r0.isNotManagement);
} }
export class TipFromCoachComponent extends BaseComponent {
    constructor(sessionStorage, modalService, accountService, userService, tipService, coachDetailsService) {
        super(sessionStorage, modalService);
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.accountService = accountService;
        this.userService = userService;
        this.tipService = tipService;
        this.coachDetailsService = coachDetailsService;
    }
    ngOnInit() {
        this.isNotManagement = !this.accountService.hasAnyAuthority(['ROLE_MASTERMIND', 'ROLE_ADMIN', 'ROLE_SUPERVISOR']);
        this.userService.getEligibleCoaches().subscribe(response1 => {
            this.coaches = response1.coaches;
            console.log(this.coaches);
            if (this.tip === null || this.tip === undefined) {
                this.tipService.selectTipByCategory(this.tipCategory).subscribe(response2 => {
                    this.tip = response2.body;
                    if (this.tip != null) {
                        if (this.coaches.length !== 0) {
                            this.tip.coach = this.coaches[0];
                            this.coachDetailsService.findCoachDetails(this.tip.coach.id).subscribe(response3 => {
                                this.coachDetails = response3.body;
                            });
                        }
                    }
                    else {
                        this.close();
                    }
                });
            }
            else {
                this.coachDetailsService.findCoachDetails(this.tip.coach.id).subscribe(response3 => {
                    this.coachDetails = response3.body;
                });
            }
        });
    }
    close() {
        this.modalService.dismissAll();
    }
}
TipFromCoachComponent.ɵfac = function TipFromCoachComponent_Factory(t) { return new (t || TipFromCoachComponent)(i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.NgbModal), i0.ɵɵdirectiveInject(i3.AccountService), i0.ɵɵdirectiveInject(i4.UserService), i0.ɵɵdirectiveInject(i5.TipService), i0.ɵɵdirectiveInject(i6.CoachDetailsService)); };
TipFromCoachComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TipFromCoachComponent, selectors: [["tip-from-coach"]], features: [i0.ɵɵInheritDefinitionFeature], decls: 4, vars: 1, consts: [[1, "olha-theme"], [2, "height", "100%!important"], ["icon", "times-circle", 1, "close", 3, "click"], ["class", "container bg-white", 4, "ngIf"], [1, "container", "bg-white"], [3, "tip"], [4, "ngIf"], [1, "d-flex", "justify-content-between", "align-items-center", "mt-4"], [1, "d-flex", "justify-content-start", "align-items-center"], [1, "coach-image-div"], [3, "coachId", "coachLogin", "videoEnabled", "size"], [1, "coach-name-div"], [2, "margin", "0!important"], ["jhiTranslate", "inukaNgApp.tip.inukaCoach"], [1, "d-flex", "justify-content-end", "align-items-center"], ["jhiTranslate", "inukaNgApp.tip.talkTo", 1, "btn", "btn-secondary", "mr-3", 3, "routerLink", "translateValues", "click"], [3, "coachDetails", "isColumn"], ["routerLink", "/session/schedule-session", "jhiTranslate", "inukaNgApp.tip.talkToACoach", 1, "btn", "btn-secondary", "mr-3", 3, "click"]], template: function TipFromCoachComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "fa-icon", 2);
        i0.ɵɵlistener("click", function TipFromCoachComponent_Template_fa_icon_click_2_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(3, TipFromCoachComponent_div_3_Template, 4, 3, "div", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.tip != null);
    } }, directives: [i7.FaIconComponent, i8.NgIf, i9.TipComponent, i10.ProfileImageComponent, i11.JhiTranslateDirective, i12.RouterLink, i13.BadgeComponent], styles: [".container[_ngcontent-%COMP%]{border-radius:20px !important}.coach-name-div[_ngcontent-%COMP%]{color:var(--text-dark-green, #0A4A3A);font-family:Poppins,serif;font-size:14px;font-style:normal;font-weight:600;line-height:130%}.coach-image-div[_ngcontent-%COMP%], .coach-name-div[_ngcontent-%COMP%]{padding:10px}hr[_ngcontent-%COMP%]{position:relative;top:20px;border:none;border-radius:1px;height:2px;background:#8a9d98}.close[_ngcontent-%COMP%]{margin-top:10px;margin-right:10px;cursor:pointer}.badge[_ngcontent-%COMP%]{background:rgba(68,163,141,.1);color:#44a38b;border-radius:16px;padding:1px 20px;width:120px;height:30px}"] });
