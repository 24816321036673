import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { map } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { JhiDateUtils } from '../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../ng-jhipster/service";
import * as i3 from "@angular/common";
export class UserService {
    constructor(http, dateUtils, datePipe) {
        this.http = http;
        this.dateUtils = dateUtils;
        this.datePipe = datePipe;
        this.resourceUrl = SERVER_API_URL + 'api/users';
        this.resourceUrl2 = SERVER_API_URL + 'api/supervisors';
    }
    create(user) {
        return this.http.post(this.resourceUrl, user);
    }
    update(user) {
        return this.http.put(this.resourceUrl, user);
    }
    updateUserLanguage(user) {
        console.log(user);
        return this.http.post(`${this.resourceUrl}/update-language/`, user);
    }
    createCoach(user) {
        return this.http.post(SERVER_API_URL + 'api/coach/create', user);
    }
    updateCoach(user) {
        return this.http.put(SERVER_API_URL + 'api/coach/update', user);
    }
    createSupervisor(user) {
        return this.http.post(SERVER_API_URL + 'api/supervisor/create', user);
    }
    updateSupervisor(user) {
        return this.http.put(SERVER_API_URL + 'api/supervisor/update', user);
    }
    find(login) {
        return this.http.get(`${this.resourceUrl}/${login}`);
    }
    userExits(login) {
        return this.http.get(`${this.resourceUrl}/user-exists`, { params: new HttpParams().set('userName', login) });
    }
    unsubscribe(code) {
        return this.http.get(`${SERVER_API_URL}api/na/unsubscribe/${code}`, { observe: 'response' });
    }
    findAllUsersByRegions(selectedRegions) {
        console.log(selectedRegions);
        return this.http
            .post(`${this.resourceUrl}/all`, selectedRegions, { observe: 'response' })
            .pipe(map((res) => this.convertDateToString(res)));
    }
    convertDateToString(res) {
        if (res.body) {
            res.body.forEach((u) => {
                u.createdDate = this.datePipe.transform(u.createdDate, "yyyy-MM-ddThh:mm:ss");
            });
        }
        return res;
    }
    findAllTypeTwo(regions) {
        return this.http.post(`${this.resourceUrl}/all-type-2`, regions, { observe: 'response' })
            .pipe(map((res) => this.convertDateToString(res)));
    }
    findAllCoaches() {
        return this.http.get(`${this.resourceUrl}/all-coaches`, { observe: 'response' });
    }
    findAllUserCountries() {
        return this.http.get(`${this.resourceUrl}/all-user-countries`, { observe: 'response' });
    }
    convertArrayResponse(res) {
        const jsonResponse = res.body;
        const body = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }
    convertItemFromServer(json) {
        const entity = Object.assign({}, json);
        entity.createdDate = this.dateUtils.convertDateTimeFromServer(json.createdDate);
        return entity;
    }
    findById(id) {
        return this.http.get(`${this.resourceUrl}/user-by-id?id=${id}`);
    }
    findByIdForUpdate(id) {
        return this.http.get(`${this.resourceUrl}/user-by-id-update?id=${id}`);
    }
    findCustomerCareIdByRegion(region) {
        return this.http.get(`${SERVER_API_URL}api/users/customer-care/find/${region}`);
    }
    findAuthoritiesById(id) {
        return this.http.get(`${this.resourceUrl}/authorities-by-id?id=${id}`);
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    queryClients(req, selectedRegions) {
        console.log(selectedRegions);
        const options = createRequestOption(req);
        return this.http.post(SERVER_API_URL + 'api/clients', selectedRegions, { params: options, observe: 'response' });
    }
    queryClientsForManagement(req, searchParam, selectedRegions) {
        console.log(searchParam);
        console.log(selectedRegions);
        const options = createRequestOption(req);
        return this.http.post(SERVER_API_URL + 'api/clients/searchParam', { searchParam, selectedRegions }, { params: options, observe: 'response' });
    }
    queryCoachesForManagement(req, searchParam, selectedRegions) {
        console.log(searchParam);
        console.log(selectedRegions);
        const options = createRequestOption(req);
        return this.http.post(SERVER_API_URL + 'api/coaches/searchParam', { searchParam, selectedRegions }, { params: options, observe: 'response' });
    }
    querySupervisors(req) {
        const options = createRequestOption(req);
        return this.http.get(SERVER_API_URL + 'api/supervisors', { params: options, observe: 'response' });
    }
    delete(login) {
        return this.http.delete(`${this.resourceUrl}/${login}`);
    }
    deleteSupervisor(login) {
        return this.http.delete(`${this.resourceUrl2}/${login}`);
    }
    authorities() {
        return this.http.get(SERVER_API_URL + 'api/users/authorities');
    }
    coachAuthorities() {
        return this.http.get(SERVER_API_URL + 'api/coaches/authorities');
    }
    getAllCoachReasoningsByCoachId(coachIds) {
        return this.http.post(SERVER_API_URL + 'api/coaches/lang', coachIds, { observe: 'response' });
    }
    supervisorAuthorities() {
        return this.http.get(SERVER_API_URL + 'api/supervisors/authorities');
    }
    getAllRegions() {
        return this.http.get(SERVER_API_URL + 'api/regions');
    }
    getAllRegionObjects() {
        return this.http.get(SERVER_API_URL + 'api/regions/objects');
    }
    getRegionObjectByName(region) {
        return this.http.get(SERVER_API_URL + 'api/regions/' + region);
    }
    current() {
        return this.http.get(`${this.resourceUrl}/current`);
    }
    customerCare() {
        return this.http.get(`${this.resourceUrl}/customer-care`, { responseType: 'text' });
    }
    customerCareEmail() {
        return this.http.get(`${this.resourceUrl}/customer-care-email`, { responseType: 'text' });
    }
    reportClient(user, sessionId) {
        return this.http.put(`${this.resourceUrl}/flag-user?sessionId=${sessionId}`, user);
    }
    reportClient2(user, receiverId) {
        return this.http.put(`${this.resourceUrl}/flag-user2?receiverId=${receiverId}`, user);
    }
    getAllAvailableCoachReasonings() {
        return this.http.get(SERVER_API_URL + "api/available/coaches");
    }
    getCoachReasoning(coachId, langKey) {
        return this.http.post(`${this.resourceUrl}/reasoning/${coachId}`, langKey, { responseType: 'text' });
    }
    updateCoachReasonings(coachReasoningList) {
        return this.http.put(`${SERVER_API_URL}api/reasoning/`, coachReasoningList);
    }
    getAllAvailableLanguagesByUserRegion(coachId) {
        return this.http.get(`${SERVER_API_URL}api/reasoning/available-languages-by-user-region?coachId=${coachId}`);
    }
    getAllAvailableLanguages() {
        return this.http.get(`${SERVER_API_URL}api/reasoning/available-languages`);
    }
    getCoachReasoningLanguages(coachId) {
        return this.http.get(SERVER_API_URL + 'api/coach/languages/' + coachId);
    }
    getCurrentCoach() {
        return this.http.get(SERVER_API_URL + 'api/coach/current');
    }
    getCoachStatistics(coachStatistics) {
        return this.http.post(SERVER_API_URL + 'api/coach/statistics', coachStatistics);
    }
    getLoginCountry() {
        return this.http.get(SERVER_API_URL + 'api/na/login-country', { responseType: 'text' });
    }
    emailCredentials(credentials) {
        return this.http.post(SERVER_API_URL + 'api/email-credentials', credentials, { responseType: 'text' });
    }
    contactUser(phoneNumber) {
        return this.http.post(`${this.resourceUrl}/contact`, phoneNumber, { responseType: 'text' });
    }
    regenerateOtpSecretKey() {
        return this.http.put(`${this.resourceUrl}/regenerate-otp-secret-key`, {}, { responseType: 'text' });
    }
    getEligibleCoaches() {
        return this.http.get(SERVER_API_URL + 'api/planner/coaches');
    }
}
UserService.ɵfac = function UserService_Factory(t) { return new (t || UserService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JhiDateUtils), i0.ɵɵinject(i3.DatePipe)); };
UserService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserService, factory: UserService.ɵfac, providedIn: 'root' });
