import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AutomatedMessageService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/automated-messages';
    }
    create(automatedMessage) {
        return this.http.post(this.resourceUrl, automatedMessage, { observe: 'response' });
    }
    update(automatedMessage) {
        return this.http.put(this.resourceUrl, automatedMessage, { observe: 'response' });
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    findAutomatedMessagesOfCoachByTrigger(trigger) {
        return this.http.get(`${this.resourceUrl}/trigger/${trigger}`, { observe: 'response' });
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options, observe: 'response' });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
AutomatedMessageService.ɵfac = function AutomatedMessageService_Factory(t) { return new (t || AutomatedMessageService)(i0.ɵɵinject(i1.HttpClient)); };
AutomatedMessageService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AutomatedMessageService, factory: AutomatedMessageService.ɵfac, providedIn: 'root' });
