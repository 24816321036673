import { ElementRef, ViewContainerRef } from '@angular/core';
import { SessionService } from '../session/session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPlanService } from '../action-plan/action-plan.service';
import { BaseComponent } from '../../shared/base.component';
import { SRQCompletedService } from '../srq-completed/srq-completed.service';
import { SessionStorageService } from 'ngx-webstorage';
import { NavbarInukaService } from '../navbar-inuka/navbar-inuka.service';
import { TrackerService } from '../../core/tracker/tracker.service';
import { Router } from "@angular/router";
import { FlowManagerService } from '../../core/util/flow-manager.service';
import { JhiEventManager } from '../../ng-jhipster/service';
import { NavbarNotificationComponent } from '../index-user/navbar-notification/navbar-notification.component';
import { UpdateTimezoneNavbarNotificationComponent } from '../update-timezone-navbar-notification/update-timezone-navbar-notification.component';
import { UserService } from '../../core/user/user.service';
import { TimeZoneService } from '../../core/util/timzone.service';
import { PwaInstallationService } from '../../shared/pwa-installation/pwa-installation.service';
import { OfflineMessageService } from '../offline-message/offline-message.service';
import * as i0 from "@angular/core";
import * as i1 from "../action-plan/action-plan.service";
import * as i2 from "../session/session.service";
import * as i3 from "../srq-completed/srq-completed.service";
import * as i4 from "../navbar-inuka/navbar-inuka.service";
import * as i5 from "ngx-webstorage";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../core/tracker/tracker.service";
import * as i8 from "../../core/util/flow-manager.service";
import * as i9 from "@angular/router";
import * as i10 from "../../ng-jhipster/service";
import * as i11 from "../../core/user/user.service";
import * as i12 from "../../core/util/timzone.service";
import * as i13 from "../../shared/pwa-installation/pwa-installation.service";
import * as i14 from "../offline-message/offline-message.service";
import * as i15 from "@angular/common";
import * as i16 from "./session-info-card/session-info-card.component";
import * as i17 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i18 from "../action-plan/action-plan/action-plan.component";
import * as i19 from "./how-do-you-feel/how-do-you-feel.component";
import * as i20 from "./have-question/have-question.component";
import * as i21 from "./wellbeing-result-card/wellbeing-result-card.component";
import * as i22 from "./do-scan/do-scan.component";
import * as i23 from "./inuka-journey/inuka-journey.component";
import * as i24 from "../session/session-chat-user/session-chat-user.component";
import * as i25 from "../../shared/pwa-installation/pwa-installation-ios/pwa-installation-ios.component";
const _c0 = ["myWellbeingPlanModal"];
const _c1 = ["mobileViewEmbeddedComponentContainer"];
function IndexUserComponent_div_5_button_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 15);
    i0.ɵɵtext(1, "Chat with you coach ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵpropertyInterpolate1("routerLink", "/session/chat/", ctx_r4.session.id, "");
} }
function IndexUserComponent_div_5_ng_template_5_button_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 15);
    i0.ɵɵtext(1, "Chat with you coach ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(3);
    i0.ɵɵpropertyInterpolate1("routerLink", "/session/chat/", ctx_r10.endedSessions[ctx_r10.endedSessions.length - 1].id, "");
} }
function IndexUserComponent_div_5_ng_template_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtemplate(0, IndexUserComponent_div_5_ng_template_5_button_0_Template, 2, 1, "button", 16);
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r6.endedSessions == null ? null : ctx_r6.endedSessions.length);
} }
function IndexUserComponent_div_5_ng_container_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 17);
    i0.ɵɵelementStart(2, "div", 18);
    i0.ɵɵelement(3, "action-plan", 19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 20);
    i0.ɵɵelement(5, "how-do-you-feel");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("actionPlan", ctx_r7.actionPlan)("showEllipsis", true);
} }
function IndexUserComponent_div_5_ng_template_8_wellbeing_result_card_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "wellbeing-result-card", 22);
} if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("srqHistory", ctx_r11.srqHistory);
} }
function IndexUserComponent_div_5_ng_template_8_do_scan_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "do-scan");
} }
function IndexUserComponent_div_5_ng_template_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtemplate(0, IndexUserComponent_div_5_ng_template_8_wellbeing_result_card_0_Template, 1, 1, "wellbeing-result-card", 21);
    i0.ɵɵtemplate(1, IndexUserComponent_div_5_ng_template_8_do_scan_1_Template, 1, 0, "do-scan", 6);
    i0.ɵɵelementStart(2, "div", 20);
    i0.ɵɵelement(3, "have-question");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", (ctx_r9.srqHistory == null ? null : ctx_r9.srqHistory.length) > 0 && (!ctx_r9.actionPlan || !(ctx_r9.actionPlan == null ? null : ctx_r9.actionPlan.actionPlanItems.length)));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", (ctx_r9.srqHistory == null ? null : ctx_r9.srqHistory.length) === 0);
} }
const _c2 = function (a0) { return { "d-none d-lg-block": a0 }; };
function IndexUserComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelementStart(1, "div", 8);
    i0.ɵɵelement(2, "session-info-card", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 10);
    i0.ɵɵtemplate(4, IndexUserComponent_div_5_button_4_Template, 2, 1, "button", 11);
    i0.ɵɵtemplate(5, IndexUserComponent_div_5_ng_template_5_Template, 1, 1, "ng-template", null, 12, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵtemplate(7, IndexUserComponent_div_5_ng_container_7_Template, 6, 2, "ng-container", 13);
    i0.ɵɵtemplate(8, IndexUserComponent_div_5_ng_template_8_Template, 4, 2, "ng-template", null, 14, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const _r5 = i0.ɵɵreference(6);
    const _r8 = i0.ɵɵreference(9);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(6, _c2, (ctx_r1.session == null ? null : ctx_r1.session.active) || (ctx_r1.session == null ? null : ctx_r1.session.started)));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("session", ctx_r1.session);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.session)("ngIfElse", _r5);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r1.actionPlan && (ctx_r1.actionPlan == null ? null : ctx_r1.actionPlan.actionPlanItems.length))("ngIfElse", _r8);
} }
function IndexUserComponent_div_6_session_chat_user_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "session-chat-user", 29);
} if (rf & 2) {
    const ctx_r13 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("sessionId", ctx_r13.session.id)("displaySessionInfoHeader", false)("viewportHeight", "100%");
} }
function IndexUserComponent_div_6_Template(rf, ctx) { if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 23);
    i0.ɵɵelementStart(1, "div", 24);
    i0.ɵɵelementStart(2, "inuka-journey", 25);
    i0.ɵɵlistener("sessionSelected", function IndexUserComponent_div_6_Template_inuka_journey_sessionSelected_2_listener($event) { i0.ɵɵrestoreView(_r15); const ctx_r14 = i0.ɵɵnextContext(); return ctx_r14.showPreviousSessionTranscriptInNewTab($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 26);
    i0.ɵɵelementStart(4, "div", 27);
    i0.ɵɵtemplate(5, IndexUserComponent_div_6_session_chat_user_5_Template, 1, 3, "session-chat-user", 28);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("sessions", ctx_r2.sessions)("srqHistory", ctx_r2.srqHistory);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r2.session);
} }
function IndexUserComponent_pwa_installation_ios_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "pwa-installation-ios");
} }
export class IndexUserComponent extends BaseComponent {
    constructor(elementRef, actionPlanService, sessionService, srqService, navbarInukaService, sessionStorage, modalService, trackerService, flowManagerService, router, eventManager, userService, timeZoneService, pwaInstallationService, offlineMessageService) {
        super(sessionStorage, modalService);
        this.elementRef = elementRef;
        this.actionPlanService = actionPlanService;
        this.sessionService = sessionService;
        this.srqService = srqService;
        this.navbarInukaService = navbarInukaService;
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
        this.trackerService = trackerService;
        this.flowManagerService = flowManagerService;
        this.router = router;
        this.eventManager = eventManager;
        this.userService = userService;
        this.timeZoneService = timeZoneService;
        this.pwaInstallationService = pwaInstallationService;
        this.offlineMessageService = offlineMessageService;
        this.srqHistory = [];
        this.isLoaded = false;
    }
    get isSessionFinished() {
        return this.session && this.session.ended;
    }
    ngAfterViewInit() {
        this.navbarInukaService.registerAsEmbeddedComponentHost(this.mobileViewEmbeddedComponentContainer);
    }
    ngOnInit() {
        this.showPwaInstallation = this.pwaInstallationService.shouldShowPwaInstallation();
        this.eventSubscriber = this.eventManager.subscribe('sessionListModification', () => this.loadData());
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F7F4EB';
        this.userService.current().subscribe(user => {
            this.user = user;
            if (!this.timeZoneService.isUserAndBrowserTimezonesTheSame(user)) {
                this.navbarInukaService.showEmbeddedComponent(UpdateTimezoneNavbarNotificationComponent, {
                    user,
                    isCoach: false
                });
            }
        });
        this.loadData();
        this.offlineMessageService.unreadMessageCount().subscribe(res => {
            this.unreadMessageCount = res.body;
        });
        this.navbarInukaService.navbarTitle = 'inuka';
        this.trackerService.sendActivity("welcome_home_screen_open");
    }
    loadData() {
        this.sessionService.recentSessions().subscribe((res) => {
            this.sessions = res.body || [];
            this.endedSessions = this.sessions
                .filter(s => s.ended)
                .sort((a, b) => a.sessionStartTime.minutes() - b.sessionStartTime.minutes());
            this.session = this.sessionService.decideWhichSessionToDisplay(this.sessions, this.endedSessions, true);
            if (this.redirectToStartedSession()) {
                return;
            }
            if (this.session) {
                if (this.session.future) {
                    this.checkAboutToStartSession();
                }
                else if (this.session.active || this.session.started) {
                    this.navbarInukaService.showEmbeddedComponent(NavbarNotificationComponent, { session: this.session });
                }
            }
            // find the last successfully held session
            const lastSession = this.sessions.find(s => s.completed);
            if (lastSession) {
                this.actionPlanService.findBySessionId(lastSession.id).subscribe((r) => {
                    this.actionPlan = r.body;
                });
            }
            this.srqService.srqHistory().subscribe((srqs) => {
                this.srqHistory = srqs.body || [];
                // ignore cancelled/rescheduled/no-show sessions
                const successfullyEndedOrFutureSessions = this.sessions.filter(s => !s.rescheduled && !s.noShowUser && !s.noShowCoach && !s.noOneShowed && !s.incomplete);
                if (successfullyEndedOrFutureSessions.length === 0 && this.srqHistory.length === 0) // no session booked and no self scan is done
                 {
                    // go to self scan start page
                    this.router.navigate(['/wellbeing-start']).then();
                }
                else if (successfullyEndedOrFutureSessions.length === 0 && this.srqHistory.length > 0) // self scan is done but no session booked
                 {
                    // go to self scan result page 2
                    this.router.navigate(['/wellbeing-result2', this.srqHistory[this.srqHistory.length - 1].score]).then();
                }
                this.isLoaded = true;
            });
        }, () => this.onError());
    }
    checkAboutToStartSession() {
        var _a;
        clearTimeout(this.timeOutVar);
        if ((_a = this.session) === null || _a === void 0 ? void 0 : _a.future) {
            const minutesLeftToSessionStart = this.sessionService.calculateMinutesLeftToSessionStart(this.session);
            if (minutesLeftToSessionStart > 0) {
                if (minutesLeftToSessionStart <= 10) {
                    this.loadData();
                    return;
                }
                else if (minutesLeftToSessionStart < (24 * 60)) { // less than one day is left until session start
                    this.timeOutVar = window.setTimeout(() => {
                        this.checkAboutToStartSession();
                    }, 1000 * 60); // check every one minute
                }
            }
        }
    }
    // private subscribeToWebsocket(): void {
    //   this.trackerService.listenerSubject.subscribe(value => {
    //     const message = value['body'];
    //     const type = value['type'];
    //     if (type === 'SESSION_STATUS_CHANGED') {
    //       this.loadData();
    //     } else if (type === 'WELLBEING_PLAN_UPDATED') {
    //     } else if (type === 'SESSION_EXTENDED') {
    //     } else {
    //     }
    //   });
    // }
    redirectToStartedSession() {
        if (this.session && this.session.started) { // the Session is started
            if (this.flowManagerService.getPreviousUrl() === '/login') { // it's the first visit of index-user after login,
                // check it to make sure user can get back to index-user from session chat page
                this.router.navigate(['/session/chat', this.session.id]).then();
                return true;
            }
        }
        return false;
    }
    messageText() {
        if (this.unreadMessageCount === 0) {
            return 'inukaNgApp.indexUser.message1';
        }
        else if (this.unreadMessageCount === 1) {
            return 'inukaNgApp.indexUser.message2';
        }
        else {
            return 'inukaNgApp.indexUser.message3';
        }
    }
    showPreviousSessionTranscriptInNewTab(session) {
        this.router.navigate([]).then(result => { window.open('/#/past/' + session.id, '_blank'); });
    }
    onError() { }
    ngOnDestroy() {
        if (this.eventSubscriber) {
            this.eventManager.destroy(this.eventSubscriber);
        }
        this.navbarInukaService.hideEmbeddedComponent();
        if (this.timeOutVar) {
            clearTimeout(this.timeOutVar);
        }
    }
}
IndexUserComponent.ɵfac = function IndexUserComponent_Factory(t) { return new (t || IndexUserComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.ActionPlanService), i0.ɵɵdirectiveInject(i2.SessionService), i0.ɵɵdirectiveInject(i3.SRQCompletedService), i0.ɵɵdirectiveInject(i4.NavbarInukaService), i0.ɵɵdirectiveInject(i5.SessionStorageService), i0.ɵɵdirectiveInject(i6.NgbModal), i0.ɵɵdirectiveInject(i7.TrackerService), i0.ɵɵdirectiveInject(i8.FlowManagerService), i0.ɵɵdirectiveInject(i9.Router), i0.ɵɵdirectiveInject(i10.JhiEventManager), i0.ɵɵdirectiveInject(i11.UserService), i0.ɵɵdirectiveInject(i12.TimeZoneService), i0.ɵɵdirectiveInject(i13.PwaInstallationService), i0.ɵɵdirectiveInject(i14.OfflineMessageService)); };
IndexUserComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: IndexUserComponent, selectors: [["jhi-index-user"]], viewQuery: function IndexUserComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c1, 5, ViewContainerRef);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.myWellbeingPlanModal = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.mobileViewEmbeddedComponentContainer = _t.first);
    } }, features: [i0.ɵɵInheritDefinitionFeature], decls: 8, vars: 3, consts: [[1, "olha-theme"], [1, "container", "container-index-user"], [1, "row", "d-lg-none", "bg-1", "justify-content-center", 2, "border-radius", "0", "margin-top", "-20px"], ["mobileViewEmbeddedComponentContainer", ""], ["class", "row mt-2", 4, "ngIf"], ["class", "row mt-3", 4, "ngIf"], [4, "ngIf"], [1, "row", "mt-2"], [1, "col-lg-5", "mb-3", "mb-lg-0", 3, "ngClass"], [3, "session"], [1, "col-lg-7"], ["class", "btn btn-success mb-3 btn-block d-block d-lg-none", "jhiTranslate", "inukaNgApp.indexUser.chatWithCoach", 3, "routerLink", 4, "ngIf", "ngIfElse"], ["showLinkToOldSessions", ""], [4, "ngIf", "ngIfElse"], ["showWellbeingResult", ""], ["jhiTranslate", "inukaNgApp.indexUser.chatWithCoach", 1, "btn", "btn-success", "mb-3", "btn-block", "d-block", "d-lg-none", 3, "routerLink"], ["class", "btn btn-success mb-3 btn-block d-block d-lg-none", "jhiTranslate", "inukaNgApp.indexUser.chatWithCoach", 3, "routerLink", 4, "ngIf"], [1, "card"], [1, "card-body"], [3, "actionPlan", "showEllipsis"], [1, "mt-3"], [3, "srqHistory", 4, "ngIf"], [3, "srqHistory"], [1, "row", "mt-3"], [1, "col-lg-5"], [3, "sessions", "srqHistory", "sessionSelected"], [1, "col-lg-7", "d-none", "d-lg-block"], [2, "position", "relative", "height", "100%"], ["isEmbed", "true", 3, "sessionId", "displaySessionInfoHeader", "viewportHeight", 4, "ngIf"], ["isEmbed", "true", 3, "sessionId", "displaySessionInfoHeader", "viewportHeight"]], template: function IndexUserComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementContainer(3, null, 3);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, IndexUserComponent_div_5_Template, 10, 8, "div", 4);
        i0.ɵɵtemplate(6, IndexUserComponent_div_6_Template, 6, 3, "div", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(7, IndexUserComponent_pwa_installation_ios_7_Template, 1, 0, "pwa-installation-ios", 6);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngIf", ctx.isLoaded);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isLoaded);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.showPwaInstallation);
    } }, directives: [i15.NgIf, i15.NgClass, i16.SessionInfoCardComponent, i17.JhiTranslateDirective, i9.RouterLink, i18.ActionPlanComponent, i19.HowDoYouFeelComponent, i20.HaveQuestionComponent, i21.WellbeingResultCardComponent, i22.DoScanComponent, i23.InukaJourneyComponent, i24.SessionChatUserComponent, i25.PwaInstallationIosComponent], styles: [".container-index-user{padding-top:80px !important}html{scroll-behavior:smooth}.score-board{background-color:#f1eee2;border-radius:50px;height:80px;border-spacing:8px}.score{width:80px;height:80px;background-color:#fff;font-size:11px;color:#c1c1c1}.s-resilient{background-color:#a9d3c7;color:#fff}.s-at-risk{background-color:#ead88c;color:#fff}.s-in-a-bad-place{background-color:#b894aa;color:#fff}.toast-message-book-session-pc{top:125px !important;left:42% !important;width:auto;height:auto}.toast-message-book-session-mobile{top:375px !important;left:50% !important;transform:translateX(-50%);width:auto;height:auto}hr{width:100% !important;margin-top:5px !important;margin-bottom:5px !important}button.close{font-size:30px;margin-right:20px}.number-hr{top:18px}"], encapsulation: 2 });
