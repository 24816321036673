import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../ng-jhipster/language/jhi-translate.directive";
const _c0 = function (a0) { return { language: a0 }; };
function AlertModalComponent_h4_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h4", 5);
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("jhiTranslate", ctx_r0.userAlertMessage)("translateValues", i0.ɵɵpureFunction1(2, _c0, ctx_r0.language));
} }
export class AlertModalComponent {
    onUserAlertModalClose() {
        this.alertModalRef.close();
    }
}
AlertModalComponent.ɵfac = function AlertModalComponent_Factory(t) { return new (t || AlertModalComponent)(); };
AlertModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AlertModalComponent, selectors: [["alert-modal"]], decls: 6, vars: 1, consts: [[1, "olha-theme"], [1, "modal-body", "text-center", "mt-4", "mb-4"], ["class", "modal-title", 3, "jhiTranslate", "translateValues", 4, "ngIf"], [1, "alert-box-actions", 2, "text-align", "center"], ["type", "button", "jhiTranslate", "login.close", 1, "btn", "btn-dark", "rounded-button", 3, "click"], [1, "modal-title", 3, "jhiTranslate", "translateValues"]], template: function AlertModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵtemplate(2, AlertModalComponent_h4_2_Template, 1, 4, "h4", 2);
        i0.ɵɵelement(3, "br");
        i0.ɵɵelementStart(4, "div", 3);
        i0.ɵɵelementStart(5, "button", 4);
        i0.ɵɵlistener("click", function AlertModalComponent_Template_button_click_5_listener() { return ctx.onUserAlertModalClose(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.userAlertMessage);
    } }, directives: [i1.NgIf, i2.JhiTranslateDirective], styles: [""], encapsulation: 2 });
