import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { BaseComponent } from '../../../../shared/base.component';
import { SessionStorageService } from "ngx-webstorage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../organization-srq-stats/organization-srq-stats.component";
import * as i5 from "../organization-srq-impact-percentage/organization-srq-impact-percentage.component";
import * as i6 from "../organization-srq-status/organization-srq-status.component";
function OrganizationWellbeingDashboardComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵelement(2, "organization-srq-stats", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 3);
    i0.ɵɵelementStart(4, "div", 4);
    i0.ɵɵelement(5, "organization-srq-impact-percentage", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 5);
    i0.ɵɵelement(7, "organization-srq-status", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngClass", ctx_r0.windowWidth < 1200 ? "mb-4" : "");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
} }
export class OrganizationWellbeingDashboardComponent extends BaseComponent {
    constructor(sessionStorage, modalService) {
        super(sessionStorage, modalService);
        this.sessionStorage = sessionStorage;
        this.modalService = modalService;
    }
    ngOnInit() {
    }
}
OrganizationWellbeingDashboardComponent.ɵfac = function OrganizationWellbeingDashboardComponent_Factory(t) { return new (t || OrganizationWellbeingDashboardComponent)(i0.ɵɵdirectiveInject(i1.SessionStorageService), i0.ɵɵdirectiveInject(i2.NgbModal)); };
OrganizationWellbeingDashboardComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationWellbeingDashboardComponent, selectors: [["organization-wellbeing-dashboard"]], inputs: { parameters: "parameters" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 1, vars: 1, consts: [[4, "ngIf"], [1, "mb-4"], [3, "parameters"], [1, "d-flex", "justify-content-between", "flex-class"], [1, "report-card", 3, "ngClass"], [1, "report-card"]], template: function OrganizationWellbeingDashboardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, OrganizationWellbeingDashboardComponent_ng_container_0_Template, 8, 4, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.parameters);
    } }, directives: [i3.NgIf, i4.OrganizationSrqStatsComponent, i3.NgClass, i5.OrganizationSrqImpactPercentageComponent, i6.OrganizationSrqStatusComponent], styles: [".report-card[_ngcontent-%COMP%]{width:100%}.flex-class[_ngcontent-%COMP%]{flex-direction:column}@media(min-width: 1200px){.report-card[_ngcontent-%COMP%]{width:49.3%}.flex-class[_ngcontent-%COMP%]{flex-direction:row}}"] });
