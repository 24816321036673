import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JhiEventManager } from '../../ng-jhipster/service';
import { VideoCallService } from './video-call.service';
import * as i0 from "@angular/core";
import * as i1 from "./video-call.service";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../ng-jhipster/service";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i7 from "../../shared/alert/alert-error.component";
import * as i8 from "@fortawesome/angular-fontawesome";
const _c0 = function (a0) { return { id: a0 }; };
function VideoCallDeleteDialogComponent_form_0_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "form", 1);
    i0.ɵɵlistener("ngSubmit", function VideoCallDeleteDialogComponent_form_0_Template_form_ngSubmit_0_listener() { i0.ɵɵrestoreView(_r2); const ctx_r1 = i0.ɵɵnextContext(); return ctx_r1.confirmDelete(ctx_r1.videoCall == null ? null : ctx_r1.videoCall.id); });
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "h4", 3);
    i0.ɵɵtext(3, "Confirm delete operation");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "button", 4);
    i0.ɵɵlistener("click", function VideoCallDeleteDialogComponent_form_0_Template_button_click_4_listener() { i0.ɵɵrestoreView(_r2); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.cancel(); });
    i0.ɵɵtext(5, "\u00D7");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 5);
    i0.ɵɵelement(7, "jhi-alert-error");
    i0.ɵɵelementStart(8, "p", 6);
    i0.ɵɵtext(9, "Are you sure you want to delete this Video Call?");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "div", 7);
    i0.ɵɵelementStart(11, "button", 8);
    i0.ɵɵlistener("click", function VideoCallDeleteDialogComponent_form_0_Template_button_click_11_listener() { i0.ɵɵrestoreView(_r2); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.cancel(); });
    i0.ɵɵelement(12, "fa-icon", 9);
    i0.ɵɵtext(13, "\u00A0");
    i0.ɵɵelementStart(14, "span", 10);
    i0.ɵɵtext(15, "Cancel");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "button", 11);
    i0.ɵɵelement(17, "fa-icon", 12);
    i0.ɵɵtext(18, "\u00A0");
    i0.ɵɵelementStart(19, "span", 13);
    i0.ɵɵtext(20, "Delete");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(8);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(1, _c0, ctx_r0.videoCall.id));
} }
export class VideoCallDeleteDialogComponent {
    constructor(videoCallService, activeModal, eventManager) {
        this.videoCallService = videoCallService;
        this.activeModal = activeModal;
        this.eventManager = eventManager;
    }
    cancel() {
        this.activeModal.dismiss();
    }
    confirmDelete(id) {
        this.videoCallService.delete(id).subscribe(() => {
            this.eventManager.broadcast('videoCallListModification');
            this.activeModal.close();
        });
    }
}
VideoCallDeleteDialogComponent.ɵfac = function VideoCallDeleteDialogComponent_Factory(t) { return new (t || VideoCallDeleteDialogComponent)(i0.ɵɵdirectiveInject(i1.VideoCallService), i0.ɵɵdirectiveInject(i2.NgbActiveModal), i0.ɵɵdirectiveInject(i3.JhiEventManager)); };
VideoCallDeleteDialogComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: VideoCallDeleteDialogComponent, selectors: [["ng-component"]], decls: 1, vars: 1, consts: [["name", "deleteForm", 3, "ngSubmit", 4, "ngIf"], ["name", "deleteForm", 3, "ngSubmit"], [1, "modal-header"], ["jhiTranslate", "entity.delete.title", 1, "modal-title"], ["type", "button", "data-dismiss", "modal", "aria-hidden", "true", 1, "close", 3, "click"], [1, "modal-body"], ["id", "jhi-delete-videoCall-heading", "jhiTranslate", "inukaNgApp.videoCall.delete.question", 3, "translateValues"], [1, "modal-footer"], ["type", "button", "data-dismiss", "modal", 1, "btn", "btn-secondary", 3, "click"], ["icon", "ban"], ["jhiTranslate", "entity.action.cancel"], ["id", "jhi-confirm-delete-videoCall", "type", "submit", 1, "btn", "btn-danger"], ["icon", "times"], ["jhiTranslate", "entity.action.delete"]], template: function VideoCallDeleteDialogComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, VideoCallDeleteDialogComponent_form_0_Template, 21, 3, "form", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.videoCall);
    } }, directives: [i4.NgIf, i5.ɵNgNoValidate, i5.NgControlStatusGroup, i5.NgForm, i6.JhiTranslateDirective, i7.AlertErrorComponent, i8.FaIconComponent], encapsulation: 2 });
