import { RouterModule } from '@angular/router';
import { InukaNgSharedModule } from '../../shared/shared.module';
import { actionPlanRoute } from './action-plan.route';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class InukaNgActionPlanModule {
}
InukaNgActionPlanModule.ɵfac = function InukaNgActionPlanModule_Factory(t) { return new (t || InukaNgActionPlanModule)(); };
InukaNgActionPlanModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InukaNgActionPlanModule });
InukaNgActionPlanModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[InukaNgSharedModule, RouterModule.forChild(actionPlanRoute)]] });
