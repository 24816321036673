import { CoachDetails } from '../../../../shared/model/coach-details.model';
import { CoachDetailsService } from '../../../slot/coach-details.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../slot/coach-details.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@ngx-translate/core";
function CoachProfileImageComponent_ng_container_0_div_1_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(3, "svg", 7);
    i0.ɵɵelement(4, "use", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("matTooltip", i0.ɵɵpipeBind1(2, 3, "sessionChat.paragraph.videoCallTooltip"))("matTooltipPosition", "below")("matTooltipClass", "expand-tool-tip");
} }
function CoachProfileImageComponent_ng_container_0_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵelementStart(2, "div");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, CoachProfileImageComponent_ng_container_0_div_1_div_4_Template, 5, 5, "div", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.coachLogin[0], " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.videoEnabled);
} }
function CoachProfileImageComponent_ng_container_0_div_2_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(3, "svg", 7);
    i0.ɵɵelement(4, "use", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("matTooltip", i0.ɵɵpipeBind1(2, 3, "sessionChat.paragraph.videoCallTooltip"))("matTooltipPosition", "below")("matTooltipClass", "expand-tool-tip");
} }
const _c0 = function (a0) { return { "background-image": a0 }; };
function CoachProfileImageComponent_ng_container_0_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "div", 9);
    i0.ɵɵtemplate(2, CoachProfileImageComponent_ng_container_0_div_2_div_2_Template, 5, 5, "div", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(2, _c0, "url(" + ctx_r2.coachDetails.imageUrl + ")"));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.videoEnabled);
} }
function CoachProfileImageComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CoachProfileImageComponent_ng_container_0_div_1_Template, 5, 2, "div", 1);
    i0.ɵɵtemplate(2, CoachProfileImageComponent_ng_container_0_div_2_Template, 3, 4, "div", 1);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.coachDetails.imageUrl);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.coachDetails.imageUrl);
} }
export class CoachProfileImageComponent {
    constructor(coachDetailsService) {
        this.coachDetailsService = coachDetailsService;
        this.timeStamp = (new Date()).getTime();
    }
    set coachId(value) {
        if (this._coachId !== value) {
            this._coachId = value;
            this.loadCoachDetails();
        }
    }
    get coachId() {
        return this._coachId;
    }
    ngOnInit() {
        var _a;
        this.videoEnabled = (_a = this.coachDetails) === null || _a === void 0 ? void 0 : _a.videoChatEnabled;
    }
    loadCoachDetails() {
        if (this.coachId) {
            this.coachDetailsService.findCoachDetails(this.coachId).subscribe(c => this.coachDetails = c.body);
        }
    }
}
CoachProfileImageComponent.ɵfac = function CoachProfileImageComponent_Factory(t) { return new (t || CoachProfileImageComponent)(i0.ɵɵdirectiveInject(i1.CoachDetailsService)); };
CoachProfileImageComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: CoachProfileImageComponent, selectors: [["coach-profile-image"]], inputs: { coachLogin: "coachLogin", coachDetails: "coachDetails", coachId: "coachId" }, decls: 1, vars: 1, consts: [[4, "ngIf"], ["style", "position: relative;width: 100%;padding-bottom: 100%", 4, "ngIf"], [2, "position", "relative", "width", "100%", "padding-bottom", "100%"], [1, "text-center", "d-flex", "justify-content-center", "align-items-center", "text-uppercase", 2, "position", "absolute", "width", "100%", "height", "100%", "background-color", "#DFF1EC", "border-radius", "50%", "color", "#44A38B", "font-size", "20px", "font-weight", "700"], ["class", "d-flex justify-content-center align-items-center video-white-icon-div", 4, "ngIf"], [1, "d-flex", "justify-content-center", "align-items-center", "video-white-icon-div"], [1, "expand-div", 3, "matTooltip", "matTooltipPosition", "matTooltipClass"], [1, "video-white-icon"], [0, "xlink", "href", "../../../../../../../../content/images/sprite.svg?v=3#video-white-small"], [1, "text-center", "p-2", "text-uppercase", 2, "position", "absolute", "width", "100%", "height", "100%", "background-color", "#DFF1EC", "border-radius", "50%", "background-repeat", "no-repeat", "background-position", "center center", "background-size", "cover", 3, "ngStyle"]], template: function CoachProfileImageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, CoachProfileImageComponent_ng_container_0_Template, 3, 2, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.coachDetails && ctx.coachLogin);
    } }, directives: [i2.NgIf, i3.MatTooltip, i2.NgStyle], pipes: [i4.TranslatePipe], styles: [".video-white-icon-div[_ngcontent-%COMP%]{height:25px;width:25px;position:absolute;bottom:-8px;right:-6%;border:1px solid #fff;border-radius:50%;background-color:#44a38b}.video-white-icon[_ngcontent-%COMP%]{height:12px;width:12px;margin-top:1px;margin-left:1px} .expand-tool-tip{color:#fff !important;background:#44a38b !important;border:1px solid #44a38b !important;overflow:visible !important}  .expand-tool-tip:before{content:\"\";position:absolute;bottom:100%;left:47%;border-bottom:5px solid #44a38b;border-left:5px solid transparent;border-right:5px solid transparent;z-index:10000}"] });
