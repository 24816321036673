import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../../organization-dashboard.service';
import { JhiLanguageService } from '../../../../ng-jhipster/language';
import { JhiEventManager } from '../../../../ng-jhipster/service';
import * as i0 from "@angular/core";
import * as i1 from "../../organization-dashboard.service";
import * as i2 from "../../../../ng-jhipster/language";
import * as i3 from "../../../../ng-jhipster/service";
import * as i4 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "@angular/common";
import * as i6 from "@fortawesome/angular-fontawesome";
function WellbeingImprovementComponent_ng_container_3_div_1_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵelementStart(1, "h1", 13);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 14);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r3.resilientChangeCount);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate("(" + ctx_r3.resilientChangePercent + "%)");
} }
function WellbeingImprovementComponent_ng_container_3_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵelementStart(1, "div", 7);
    i0.ɵɵelementStart(2, "div");
    i0.ɵɵelement(3, "img", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 9);
    i0.ɵɵtemplate(5, WellbeingImprovementComponent_ng_container_3_div_1_div_5_Template, 5, 2, "div", 10);
    i0.ɵɵelement(6, "span", 11);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngIf", ctx_r1.report);
} }
function WellbeingImprovementComponent_ng_container_3_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "fa-icon", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "p", 17);
    i0.ɵɵelementEnd();
} }
function WellbeingImprovementComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, WellbeingImprovementComponent_ng_container_3_div_1_Template, 7, 1, "div", 4);
    i0.ɵɵtemplate(2, WellbeingImprovementComponent_ng_container_3_div_2_Template, 4, 0, "div", 5);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.notEnoughData);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.notEnoughData);
} }
export class WellbeingImprovementComponent {
    constructor(organizationDashboardService, languageService, eventManager) {
        this.organizationDashboardService = organizationDashboardService;
        this.languageService = languageService;
        this.eventManager = eventManager;
        this.windowWidth = window.innerWidth;
        this.isLoadingData = false;
        this.currentLanguage = 'en';
        this.console = console;
    }
    set parameters(value) {
        this._parameters = value;
        this.loadReport();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
        this.currentLanguage = this.languageService.getCurrentLanguage();
        this.eventManager.subscribe('langKeyChanged', () => {
            this.currentLanguage = this.languageService.getCurrentLanguage();
            this.loadReport();
        });
    }
    loadReport() {
        this.isLoadingData = true;
        this.organizationDashboardService.getOrganizationWellbeingImprovementReport(this.parameters).subscribe(response => {
            this.report = response.body;
            console.log(this.report);
            this.refreshCardData();
            this.isLoadingData = false;
            this.notEnoughData = false;
        }, error => {
            console.log(error);
            if (error.error.title === 'NotEnoughDataForOrganizationReport') {
                this.notEnoughData = true;
            }
            this.isLoadingData = false;
        });
    }
    refreshCardData() {
        var _a;
        if ((_a = this.report) === null || _a === void 0 ? void 0 : _a.changes) {
            for (const reportItem of this.report.changes) {
                if (reportItem.department === 'inukaNgApp.organizationAdmin.organizationSrqImpactPercentage.Company total') {
                    this.resilientChangePercent = reportItem.resilientUsersPercentage > 0 ? Math.round(reportItem.resilientUsersPercentage) : 0;
                    this.resilientChangeCount = reportItem.resilientCount > 0 ? reportItem.resilientCount : 0;
                }
            }
        }
    }
}
WellbeingImprovementComponent.ɵfac = function WellbeingImprovementComponent_Factory(t) { return new (t || WellbeingImprovementComponent)(i0.ɵɵdirectiveInject(i1.OrganizationDashboardService), i0.ɵɵdirectiveInject(i2.JhiLanguageService), i0.ɵɵdirectiveInject(i3.JhiEventManager)); };
WellbeingImprovementComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WellbeingImprovementComponent, selectors: [["wellbeing-improvement"]], inputs: { parameters: "parameters" }, decls: 4, vars: 1, consts: [[1, "card", "dashboard-card", "p-2"], [1, "d-flex", "justify-content-start", "align-items-center"], ["jhiTranslate", "inukaNgApp.organizationAdmin.wellbeingImprovement.header", 1, "ml-2", "mt-3", "mr-2", "light-weight"], [4, "ngIf"], ["style", "height: auto!important;", 4, "ngIf"], ["class", "not-enough-data-warning d-flex flex-column justify-content-center align-items-center", 4, "ngIf"], [2, "height", "auto!important"], [1, "d-flex", "justify-content-between", "align-items-center", "e-charts-div"], ["src", "../../../../../../../webapp/content/images/resilient.png", "alt", "resilient.png", 2, "max-width", "280px", "max-height", "280px"], [1, "descriptive-text"], ["style", "color: #44A38B", "class", "d-flex justify-content-start align-items-center", 4, "ngIf"], ["jhiTranslate", "inukaNgApp.organizationAdmin.wellbeingImprovement.text", 2, "font-size", "16px"], [1, "d-flex", "justify-content-start", "align-items-center", 2, "color", "#44A38B"], [1, "mb-0", 2, "color", "#44A38B"], [2, "font-size", "20px", "margin-left", "10px", "font-weight", "lighter"], [1, "not-enough-data-warning", "d-flex", "flex-column", "justify-content-center", "align-items-center"], ["icon", "database", "size", "2x"], ["jhiTranslate", "inukaNgApp.organizationAdmin.notEnoughData"]], template: function WellbeingImprovementComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelement(2, "h4", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(3, WellbeingImprovementComponent_ng_container_3_Template, 3, 2, "ng-container", 3);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.isLoadingData);
    } }, directives: [i4.JhiTranslateDirective, i5.NgIf, i6.FaIconComponent], styles: [".card[_ngcontent-%COMP%]{max-height:530px;min-height:345px}.not-enough-data-warning[_ngcontent-%COMP%]{width:100%;height:200px}.not-enough-data-warning[_ngcontent-%COMP%]   svg[_ngcontent-%COMP%]   path[_ngcontent-%COMP%]{fill:#9db7b0}.not-enough-data-warning[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin-top:10px;color:var(--green-lighter)}.e-charts-div[_ngcontent-%COMP%]{flex-direction:column}.descriptive-text[_ngcontent-%COMP%]{min-width:150px;flex-grow:1;margin-left:20px}@media(min-width: 900px){.e-charts-div[_ngcontent-%COMP%]{flex-direction:row}}@media(min-width: 1200px){.not-enough-data-warning[_ngcontent-%COMP%]{height:400px}}"] });
