import { __awaiter } from "tslib";
import { ChangeDetectorRef, ElementRef, NgZone } from "@angular/core";
import { MessageService } from '../../../message/message.service';
import { AccountService } from '../../../../core/auth/account.service';
import { TrackerService } from '../../../../core/tracker/tracker.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CUSTOMER_CARE_CANNED_RESPONSES } from '../../../../app.constants';
import { JhiLanguageService } from '../../../../ng-jhipster/language';
import { UserService } from '../../../../core/user/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../message/message.service";
import * as i2 from "../../../../core/auth/account.service";
import * as i3 from "../../../../core/tracker/tracker.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../../../ng-jhipster/language";
import * as i6 from "../../../../core/user/user.service";
const _c0 = ["messageSendContainer"];
const _c1 = ["fileInput"];
const _c2 = ["cannedResponseModal"];
// noinspection JSAnnotator
export class MessageSendComponent {
    constructor(messageService, accountService, trackerService, cdr, ngZone, modalService, languageService, userService) {
        this.messageService = messageService;
        this.accountService = accountService;
        this.trackerService = trackerService;
        this.cdr = cdr;
        this.ngZone = ngZone;
        this.modalService = modalService;
        this.languageService = languageService;
        this.userService = userService;
        this.message = '';
        this.selectedFile = null;
        this.isCoachAccount = false;
        this.isCustomerCareAccount = false;
        this.recording = false;
        this.sending = false;
        this.typing = false;
        this.typingMessage = ''; // do not delete space character
        this.messageLength = 0;
        this.currentMessageValue = '';
        this.lastNotification = 0;
        this.displayScriptsIcon = false;
        this.typingNotifyTime = 0;
        this.myReader = new FileReader();
        this.langKey = this.languageService.getCurrentLanguage() ? this.languageService.getCurrentLanguage() : "en";
        this.currentCannedResponseList = CUSTOMER_CARE_CANNED_RESPONSES[this.langKey] ? CUSTOMER_CARE_CANNED_RESPONSES[this.langKey] : CUSTOMER_CARE_CANNED_RESPONSES["en"];
        this.modalOptions = { windowClass: 'modal-window', size: 'lg' };
        this.emptyLine = '<br>'; // ENTER
        this.recordAudio = () => new Promise(resolve => {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);
                let audioChunks = [];
                mediaRecorder.addEventListener('dataavailable', event => {
                    audioChunks.push(event.data);
                });
                const start = () => {
                    audioChunks = [];
                    mediaRecorder.start();
                };
                const stop = () => new Promise(resolveStop => {
                    mediaRecorder.addEventListener('stop', () => {
                        const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
                        const audioUrl = URL.createObjectURL(audioBlob);
                        const audio = new Audio(audioUrl);
                        const play = () => audio.play();
                        resolveStop({ audioChunks, audioBlob, audioUrl, play });
                    });
                    mediaRecorder.stop();
                });
                resolve({ start, stop });
            });
        });
        this.ngZone.runOutsideAngular(() => {
            // window.document.addEventListener('keydown', this.onInput.bind(this));
        });
    }
    ngOnInit() {
        this.subscribeToWebsocket();
        this.isCoachAccount = this.accountService.isCoach();
        this.isCustomerCareAccount = this.accountService.isCustomerCare();
        this.displayScriptsIcon = this.isCoachAccount;
    }
    ngOnChanges() {
        if (this.messageService.getCurrentConversation()) {
            setTimeout(() => { this.setMessageSendInner(); }, 100);
        }
    }
    setMessageSendInner() {
        let convId = this.messageService.getCurrentConversation().id;
        if (this.messageService.messageCache[convId]) {
            this.message = this.messageService.messageCache[convId];
        }
        else {
            // this.messageSendContainer.nativeElement.innerHTML = "";
        }
    }
    subscribeToWebsocket() {
        this.subscription = this.trackerService.listenerSubject.subscribe(value => {
            const message = value['body'];
            const type = value['type'];
            if (type === 'TYPING') {
                console.log('peer is typing');
                this.typingMessage = message.senderName + ' is typing ';
                setTimeout(() => {
                    this.typingMessage = '';
                }, 3000);
            }
            else {
                console.log('MessageSendComponent ignoring ' + type + ' message');
            }
        });
    }
    changeMessageCache(messageSendContainer) {
        let lengthTrimmedMessage = messageSendContainer.innerText.replace(/\s+/g, ' ').trim().length;
        if (this.messageService.getCurrentConversation()) {
            let convId = this.messageService.getCurrentConversation().id;
            if (lengthTrimmedMessage > 0)
                this.messageService.messageCache[convId] = messageSendContainer.innerText;
            else
                delete this.messageService.messageCache[convId];
        }
    }
    onInput($event, messageSendContainer) {
        this.messageLength = messageSendContainer.innerHTML.length;
        this.changeMessageCache(messageSendContainer);
        const now = new Date().getTime();
        if (now - this.typingNotifyTime > 3000) {
            this.typingNotifyTime = now;
            this.typingNotify();
        }
        else {
            // console.log("no notify");
        }
        if (messageSendContainer.innerHTML === '/') {
            this.userService.current().subscribe(user => {
                if (user.authorities.includes('ROLE_CUSTOMER_CARE')) {
                    this.modalService.open(this.cannedResponseModal, this.modalOptions).result.then();
                    messageSendContainer.innerHTML = '';
                }
            });
        }
    }
    onEnterPressed(messageSendContainer, fileInput) {
        this.sendMessage(messageSendContainer, fileInput);
        this.message = '';
        return false;
    }
    // emptyLine2 = '<div><br></div>'; // SHIFT + ENTER
    trim(innerHTML) {
        innerHTML = innerHTML.trim();
        innerHTML = innerHTML.replace(/<\/div>/g, this.emptyLine);
        innerHTML = innerHTML.replace(/<\/p>/g, this.emptyLine);
        innerHTML = innerHTML.replace(/<(?!br\s*\/?)[^>]+>/g, '');
        while (innerHTML.endsWith(this.emptyLine)) {
            //
            innerHTML = innerHTML.replace(/<br>$/, '');
        }
        return innerHTML;
    }
    onFileSelected(event, messageSendContainer) {
        this.selectedFile = event.target.files[0];
        messageSendContainer.innerText = this.selectedFile.name;
        this.fileToB64(this.selectedFile);
        this.fileName = this.selectedFile.name;
    }
    fileToB64(file) {
        this.myReader.onloadend = (e) => {
            let strings = this.myReader.result.toString().split(';');
            this.fileType = strings[0].split(':')[1].split('/')[0];
            this.fileFormat = strings[0].split(':')[1].split('/')[1];
            if ((this.fileType === 'APPLICATION' || this.fileType === 'application') && (this.fileFormat === 'octet-stream' || this.fileFormat === 'OCTET-STREAM')) {
                this.fileType = 'audio';
                this.fileFormat = 'mp3';
            }
            this.fileB64 = strings[1].split(',')[1];
            this.fileName = Date.now().toString();
        };
        this.myReader.readAsDataURL(file);
    }
    voiceRecord() {
        return __awaiter(this, void 0, void 0, function* () {
            this.recording = !this.recording;
            if (!this.recorder) {
                this.recorder = yield this.recordAudio();
            }
            this.recorder.start();
        });
    }
    sendRecord(messageSendContainer, fileInput) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recording = !this.recording;
            this.audio = yield this.recorder.stop();
            this.fileToB64(this.blobToFile(this.audio.audioBlob, ""));
            messageSendContainer.innerHTML = 'Voice note saved';
        });
    }
    cancelRecord() {
        return __awaiter(this, void 0, void 0, function* () {
            this.recording = !this.recording;
            yield this.recorder.stop();
        });
    }
    blobToFile(myBlob, fileName) {
        let tempBlob = myBlob;
        tempBlob.lastModifiedDate = new Date();
        tempBlob.name = fileName;
        return tempBlob;
    }
    clickFirstCannedResponse() {
        document.getElementById('selectedCannedResponse').click();
    }
}
MessageSendComponent.ɵfac = function MessageSendComponent_Factory(t) { return new (t || MessageSendComponent)(i0.ɵɵdirectiveInject(i1.MessageService), i0.ɵɵdirectiveInject(i2.AccountService), i0.ɵɵdirectiveInject(i3.TrackerService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i4.NgbModal), i0.ɵɵdirectiveInject(i5.JhiLanguageService), i0.ɵɵdirectiveInject(i6.UserService)); };
MessageSendComponent.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: MessageSendComponent, viewQuery: function MessageSendComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c1, 5);
        i0.ɵɵviewQuery(_c2, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.messageSendContainer = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileInput = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.cannedResponseModal = _t.first);
    } }, inputs: { maxCharacterSize: "maxCharacterSize", message: "message" }, features: [i0.ɵɵNgOnChangesFeature] });
