import { OrganizationReportParameters } from '../../../../shared/model/organization-report-parameters.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../organization-stats/organization-stats.component";
import * as i3 from "../organization-number-of-sessions/organization-number-of-sessions.component";
import * as i4 from "../wellbeing-improvement/wellbeing-improvement.component";
import * as i5 from "../wellbeing-baseline-and-impact/wellbeing-baseline-and-impact.component";
import * as i6 from "../organization-engagement-stats/organization-engagement-stats.component";
import * as i7 from "../organization-departments-engagement/organization-departments-engagement.component";
import * as i8 from "../organization-usage-over-time/organization-usage-over-time.component";
function OrganizationOverviewDashboardComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵelement(3, "organization-stats", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(4, "organization-number-of-sessions", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div", 5);
    i0.ɵɵelement(6, "wellbeing-improvement", 6);
    i0.ɵɵelement(7, "wellbeing-baseline-and-impact", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 5);
    i0.ɵɵelement(9, "organization-engagement-stats", 8);
    i0.ɵɵelement(10, "organization-departments-engagement", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "div", 10);
    i0.ɵɵelement(12, "organization-usage-over-time", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("parameters", ctx_r0.parameters);
} }
export class OrganizationOverviewDashboardComponent {
    constructor() { }
    ngOnInit() {
    }
}
OrganizationOverviewDashboardComponent.ɵfac = function OrganizationOverviewDashboardComponent_Factory(t) { return new (t || OrganizationOverviewDashboardComponent)(); };
OrganizationOverviewDashboardComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationOverviewDashboardComponent, selectors: [["organization-overview-dashboard"]], inputs: { parameters: "parameters" }, decls: 1, vars: 1, consts: [[4, "ngIf"], [1, "mb-4", "d-flex", "justify-content-between", "reports-container", "stats-div", "align-items-top"], [2, "flex-grow", "1"], [3, "parameters"], [1, "number-of-sessions-card", 3, "parameters"], [1, "d-flex", "justify-content-between", "reports-container", "engagement-div", "mt-4", "mb-4"], [1, "number-of-sessions-card", 2, "min-width", "0!important", 3, "parameters"], [2, "flex-grow", "1", "min-width", "0!important", 3, "parameters"], [2, "min-width", "0!important", 3, "parameters"], [1, "department-engagement-card", 2, "min-width", "0!important", 3, "parameters"], [2, "width", "100%"]], template: function OrganizationOverviewDashboardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, OrganizationOverviewDashboardComponent_ng_container_0_Template, 13, 7, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.parameters);
    } }, directives: [i1.NgIf, i2.OrganizationStatsComponent, i3.OrganizationNumberOfSessionsComponent, i4.WellbeingImprovementComponent, i5.WellbeingBaselineAndImpactComponent, i6.OrganizationEngagementStatsComponent, i7.OrganizationDepartmentsEngagementComponent, i8.OrganizationUsageOverTimeComponent], styles: [".reports-container[_ngcontent-%COMP%]{gap:20px}.report-card[_ngcontent-%COMP%]{width:100%}.number-of-sessions-card[_ngcontent-%COMP%]{width:100%}.department-engagement-card[_ngcontent-%COMP%]{width:100%}.engagement-div[_ngcontent-%COMP%], .stats-div[_ngcontent-%COMP%], .impact-div[_ngcontent-%COMP%]{flex-direction:column}@media(min-width: 1400px){.department-engagement-card[_ngcontent-%COMP%]{flex:0 0 40%}.engagement-div[_ngcontent-%COMP%], .stats-div[_ngcontent-%COMP%], .impact-div[_ngcontent-%COMP%]{flex-direction:row}.number-of-sessions-card[_ngcontent-%COMP%]{flex:0 0 40%}}"] });
