import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MessageService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/messages';
        this.resourceUrlTemp = SERVER_API_URL + 'api/offline-messages';
        this.messagesList = [];
        this.inboxMessagesList = [];
        this.messageCache = {};
    }
    create(message) {
        const copy = this.convertDateFromClient(message);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(message) {
        const copy = this.convertDateFromClient(message);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    convertDateFromClient(message) {
        const copy = Object.assign({}, message, {
            createDate: message.createDate && message.createDate.isValid() ? message.createDate.toJSON() : undefined,
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.createDate = res.body.createDate ? moment(res.body.createDate) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((message) => {
                message.createDate = message.createDate ? moment(message.createDate) : undefined;
            });
        }
        return res;
    }
    convertDateMessageListFromServer(res) {
        if (res.body && res.body.messages) {
            res.body.messages.forEach((message) => {
                message.createDate = message.createDate ? moment(message.createDate) : undefined;
            });
        }
        return res;
    }
    convertDateArrayFromServer2(res) {
        if (res) {
            res.forEach((m) => {
                m.createDate = m.createDate ? moment(m.createDate) : undefined;
            });
        }
        return res;
    }
    getMessagesForCurrentSession() {
        return this.http.get(`${this.resourceUrl}/current-session-and-messages`, { observe: 'response' });
    }
    getCurrentSession() {
        return this.getSession(0);
    }
    getSession(sessionId) {
        sessionId = sessionId ? sessionId : 0; // send '0' instead of null to prevent Bad Request Exception
        return this.http.get(`${SERVER_API_URL}api/sessions/current-session?sessionId=${sessionId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
        ;
    }
    getMessages(id) {
        return this.http
            .get(SERVER_API_URL + 'api/conversations/conversation/' + id)
            .pipe(map((res) => this.convertDateArrayFromServer2(res)));
    }
    getMessagesByUserId(id) {
        return this.http
            .get(SERVER_API_URL + 'api/conversations/' + id)
            .pipe(map((res) => this.convertDateArrayFromServer2(res)));
    }
    getMessagesByUserAndCoach(id) {
        return this.http
            .get(SERVER_API_URL + 'api/messages/userId/' + id)
            .pipe(map((res) => this.convertDateArrayFromServer2(res)));
    }
    getMessagesForSession(s) {
        return this.http
            .get(`${this.resourceUrl}/messages-for-session?sessionId=${s.id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    getMessagesForSessionn(s) {
        return this.http
            .get(`${this.resourceUrl}/messages-for-session?sessionId=${s.id}`, { observe: 'response', responseType: 'text' });
    }
    getMessagesByUserIdAndCoachIdOfConversation(id) {
        return this.http
            .get(`${this.resourceUrl}/conversationId/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    getMessagesByUserIdAndCoachId(userId, coachId) {
        return this.http
            .get(`${this.resourceUrl}/userId/${userId}/coachId/${coachId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    getMessagesByConversation(userId, coachId, count, index) {
        let params = new HttpParams();
        if (index !== undefined && index !== null) {
            params = params.set('index', index.toString());
        }
        params = params.set('count', count.toString());
        return this.http
            .get(`${this.resourceUrl}/paginated/user/${userId}/coach/${coachId}`, { params, observe: 'response' })
            .pipe(map((res) => this.convertDateMessageListFromServer(res)));
    }
    getMessagesBySession(sessionId, count, index, displayTranscriptions) {
        let params = new HttpParams();
        if (index !== undefined && index !== null) {
            params = params.set('index', index.toString());
        }
        params = params.set('count', count.toString());
        params = params.set('displayTranscriptions', String(displayTranscriptions));
        return this.http
            .get(`${this.resourceUrl}/paginated/session/${sessionId}`, { params, observe: 'response' })
            .pipe(map((res) => this.convertDateMessageListFromServer(res)));
    }
    setMessagesByUserIdAndCoachIdAsDisplayed(userId, coachId) {
        return this.http.put(`${this.resourceUrl}/userId/${userId}/coachId/${coachId}`, { observe: 'response' });
    }
    shareWellbeingPlan(message) {
        if (message['notifyWellbeingPlanUpdated'] === undefined) {
            message['notifyWellbeingPlanUpdated'] = false;
        }
        return this.http.post(`${this.resourceUrl}/share-wellbeing-plan`, message, { observe: 'response' });
    }
    getMessagesBySessionId(sessionId, userId, coachId) {
        return this.http
            .get(`${this.resourceUrl}/sessionId/${sessionId}/userId/${userId}/coachId/${coachId}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    sendMessage(message) {
        if (message['notifyWellbeingPlanUpdated'] === undefined) {
            message['notifyWellbeingPlanUpdated'] = false;
        }
        return this.http.post(`${this.resourceUrl}/send`, message, { observe: 'response' });
    }
    sendMessageTemp(message) {
        if (message['notifyWellbeingPlanUpdated'] === undefined) {
            message['notifyWellbeingPlanUpdated'] = false;
        }
        return this.http.post(`${this.resourceUrlTemp}/send`, message, { observe: 'response' });
    }
    sendMessageWithParams(messageText, sessionId) {
        let request = {};
        request['message'] = messageText;
        request['sessionId'] = sessionId;
        request['type'] = 'text';
        return this.sendMessage(request);
    }
    typing(sessionId) {
        return this.http.get(`${this.resourceUrl}/typing?sessionId=${sessionId}`, { observe: 'response' });
    }
    typing2(receiverId) {
        return this.http.get(`${this.resourceUrl}/typing2?receiverId=${receiverId}`, { observe: 'response' });
    }
    messageSeen(id) {
        return this.http.get(`${this.resourceUrl}/message-seen?messageId=${id}`, { observe: 'response' });
    }
    emailSessionMessages(id, messageList) {
        return this.http.post(`${this.resourceUrl}/email/${id}`, messageList, { observe: 'response' });
    }
    emailInboxMessages(inboxMessagesList) {
        return this.http.post(`${this.resourceUrl}/email2`, inboxMessagesList, { observe: 'response' });
    }
    startZoomVideoChat(id) {
        return this.http.get(`${this.resourceUrl}/start-zoom-video-chat/${id}`, { observe: 'response' });
    }
    startJitsiVideoChat(id) {
        return this.http.get(`${this.resourceUrl}/start-jitsi-video-chat/${id}`, { observe: 'response' });
    }
    isCoachAllowedSendMessageToUser(userId) {
        return this.http.get(`${this.resourceUrl}/userId/${userId}/is-send-message-allowed`, { observe: 'response' });
    }
    startWellbeingScan(userId, sessionId) {
        let url = `${SERVER_API_URL}api/wa/start-wellbeing-scan?userId=${userId}&sessionId=${sessionId}`;
        console.log("url: " + url);
        return this.http.get(url, { observe: 'response' });
    }
    getCurrentConversation() {
        return this.currentConversation;
    }
    setCurrentConversation(c) {
        this.currentConversation = c;
    }
    updateMessageReaction(messageReaction) {
        return this.http
            .put(`${this.resourceUrl}/reaction`, messageReaction, { observe: 'response' });
    }
}
MessageService.ɵfac = function MessageService_Factory(t) { return new (t || MessageService)(i0.ɵɵinject(i1.HttpClient)); };
MessageService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MessageService, factory: MessageService.ɵfac, providedIn: 'root' });
