import * as i0 from "@angular/core";
export class JhiBase64Service {
    constructor() {
        this.keyStr = 'ABCDEFGHIJKLMNOP' + 'QRSTUVWXYZabcdef' + 'ghijklmnopqrstuv' + 'wxyz0123456789+/' + '=';
    }
    encode(input) {
        let output = '';
        let enc1 = '';
        let enc2 = '';
        let enc3 = '';
        let enc4 = '';
        let chr1 = '';
        let chr2 = '';
        let chr3 = '';
        let i = 0;
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            }
            else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output + this.keyStr.charAt(enc1) + this.keyStr.charAt(enc2) + this.keyStr.charAt(enc3) + this.keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = '';
            enc1 = enc2 = enc3 = enc4 = '';
        }
        return output;
    }
    decode(input) {
        let output = '';
        let enc1 = '';
        let enc2 = '';
        let enc3 = '';
        let enc4 = '';
        let chr1 = '';
        let chr2 = '';
        let chr3 = '';
        let i = 0;
        // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
        input = input.replace(/[^A-Za-z0-9+/=]/g, '');
        while (i < input.length) {
            enc1 = this.keyStr.indexOf(input.charAt(i++));
            enc2 = this.keyStr.indexOf(input.charAt(i++));
            enc3 = this.keyStr.indexOf(input.charAt(i++));
            enc4 = this.keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 !== 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 !== 64) {
                output = output + String.fromCharCode(chr3);
            }
            chr1 = chr2 = chr3 = '';
            enc1 = enc2 = enc3 = enc4 = '';
        }
        return output;
    }
}
JhiBase64Service.ɵfac = function JhiBase64Service_Factory(t) { return new (t || JhiBase64Service)(); };
JhiBase64Service.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: JhiBase64Service, factory: JhiBase64Service.ɵfac, providedIn: 'root' });
