import { ChangeDetectorRef } from '@angular/core';
import { ZoomService } from '../zoom.service';
import * as i0 from "@angular/core";
import * as i1 from "../zoom.service";
import * as i2 from "@angular/common";
import * as i3 from "../turned-off-video/turned-off-video.component";
import * as i4 from "../../../shared/auth/has-any-authority.directive";
import * as i5 from "../../../ng-jhipster/language/jhi-translate.directive";
function ParticipantVideoComponent_div_1_div_3_h1_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h1", 8);
} }
function ParticipantVideoComponent_div_1_div_3_h1_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "h1", 9);
} }
const _c0 = function () { return ["ROLE_COACH"]; };
const _c1 = function () { return ["ROLE_USER"]; };
function ParticipantVideoComponent_div_1_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, ParticipantVideoComponent_div_1_div_3_h1_1_Template, 1, 0, "h1", 6);
    i0.ɵɵtemplate(2, ParticipantVideoComponent_div_1_div_3_h1_2_Template, 1, 0, "h1", 7);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("jhiHasAnyAuthority", i0.ɵɵpureFunction0(2, _c0));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("jhiHasAnyAuthority", i0.ɵɵpureFunction0(3, _c1));
} }
const _c2 = function (a0, a1) { return { width: a0, height: a1 }; };
function ParticipantVideoComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "turned-off-video", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, ParticipantVideoComponent_div_1_div_3_Template, 3, 4, "div", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(3, _c2, ctx_r0.width + "px", ctx_r0.height + "px"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("user", ctx_r0.participantUser);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.isParticipantJoined);
} }
export class ParticipantVideoComponent {
    constructor(zoomService, changeDetector) {
        this.zoomService = zoomService;
        this.changeDetector = changeDetector;
    }
    set width(value) {
        if (this._width !== value) {
            this._width = value;
            this.resizeVideoCanvas();
        }
    }
    get width() {
        return this._width;
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        var _a, _b, _c, _d;
        (_a = this.participantVideoStartedSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this.participantVideoStoppedSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        (_c = this.participantJoinedTheCallSubscription) === null || _c === void 0 ? void 0 : _c.unsubscribe();
        (_d = this.participantLeftTheCallSubscription) === null || _d === void 0 ? void 0 : _d.unsubscribe();
        if (this.resizeVideoTimeout) {
            clearTimeout(this.resizeVideoTimeout);
        }
    }
    resizeVideoCanvas() {
        if (this.isParticipantVideoActive && !this.resizeVideoTimeout) {
            this.resizeVideoTimeout = setTimeout(() => {
                const participantVideoCanvasElement = document.getElementById('participant-videos-canvas');
                this.zoomService.resizeParticipantVideo(participantVideoCanvasElement, this.width, this.height);
                this.resizeVideoTimeout = null;
            }, 2000);
        }
    }
    ngAfterViewInit() {
        this.participantVideoStartedSubscription = this.zoomService.participantVideoStarted$().subscribe(userId => {
            this.startParticipantVideo(userId);
        });
        this.participantVideoStoppedSubscription = this.zoomService.participantVideoStopped$().subscribe(userId => {
            if (this.isParticipantVideoActive) {
                const participantVideoCanvasElement = document.getElementById('participant-videos-canvas');
                this.zoomService.stopParticipantVideo(userId, participantVideoCanvasElement);
                this.isParticipantVideoActive = false;
            }
        });
        this.participantJoinedTheCallSubscription = this.zoomService.participantJoinedTheCall$().subscribe(userId => {
            this.isParticipantJoined = true;
        });
        this.participantLeftTheCallSubscription = this.zoomService.participantLeftTheCall$().subscribe(userId => {
            this.isParticipantJoined = false;
        });
    }
    startParticipantVideo(userId) {
        if (this.width && this.height) {
            if (this.isParticipantJoined && !this.isParticipantVideoActive) {
                const participantVideoCanvasElement = document.getElementById('participant-videos-canvas');
                if (!participantVideoCanvasElement.getAttribute('width')) {
                    // this.participantVideoCanvas.nativeElement.width = this.width;
                    // this.participantVideoCanvas.nativeElement.height = this.height;
                    participantVideoCanvasElement.setAttribute('width', this.width.toString());
                    participantVideoCanvasElement.setAttribute('height', this.height.toString());
                }
                this.zoomService.startParticipantVideo(userId, participantVideoCanvasElement, this.width, this.height, 0, 0).subscribe(value => {
                    this.isParticipantVideoActive = true;
                    this.resizeVideoCanvas();
                    this.changeDetector.detectChanges();
                });
            }
        }
        else {
            setTimeout(() => {
                this.startParticipantVideo(userId);
            }, 1000);
        }
    }
}
ParticipantVideoComponent.ɵfac = function ParticipantVideoComponent_Factory(t) { return new (t || ParticipantVideoComponent)(i0.ɵɵdirectiveInject(i1.ZoomService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
ParticipantVideoComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ParticipantVideoComponent, selectors: [["participant-video"]], inputs: { width: "width", height: "height", participantUser: "participantUser" }, decls: 2, vars: 3, consts: [["id", "participant-videos-canvas", 2, "width", "100%", "height", "auto"], ["class", "d-flex flex-column justify-content-center", 3, "ngStyle", 4, "ngIf"], [1, "d-flex", "flex-column", "justify-content-center", 3, "ngStyle"], ["showLarge", "true", 3, "user"], ["class", "mt-3", 4, "ngIf"], [1, "mt-3"], ["class", "text-center", "jhiTranslate", "sessionChat.coachSessionControl.waitingForUser", 4, "jhiHasAnyAuthority"], ["class", "text-center", "jhiTranslate", "sessionChat.coachSessionControl.waitingForCoach", 4, "jhiHasAnyAuthority"], ["jhiTranslate", "sessionChat.coachSessionControl.waitingForUser", 1, "text-center"], ["jhiTranslate", "sessionChat.coachSessionControl.waitingForCoach", 1, "text-center"]], template: function ParticipantVideoComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "canvas", 0);
        i0.ɵɵtemplate(1, ParticipantVideoComponent_div_1_Template, 4, 6, "div", 1);
    } if (rf & 2) {
        i0.ɵɵclassProp("d-none", !ctx.isParticipantVideoActive);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isParticipantVideoActive);
    } }, directives: [i2.NgIf, i2.NgStyle, i3.TurnedOffVideoComponent, i4.HasAnyAuthorityDirective, i5.JhiTranslateDirective], styles: [""] });
