import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../../app.constants';
import { createRequestOption } from '../../../shared/util/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CoachAvailabilityPreferenceService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/coach-availability-preferences';
    }
    create(coachAvailabilityPreference) {
        const copy = this.convertDateFromClient(coachAvailabilityPreference);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(coachAvailabilityPreference) {
        const copy = this.convertDateFromClient(coachAvailabilityPreference);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    findByCurrentCoach() {
        return this.http
            .get(`${this.resourceUrl}/coachId`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    convertDateFromClient(coachAvailabilityPreference) {
        const copy = Object.assign({}, coachAvailabilityPreference, {
            startDate: coachAvailabilityPreference.startDate
                ? coachAvailabilityPreference.startDate.toJSON()
                : undefined,
            endDate: coachAvailabilityPreference.endDate
                ? coachAvailabilityPreference.endDate.toJSON()
                : undefined,
            frequencyLastUpdated: coachAvailabilityPreference.frequencyLastUpdated
                ? coachAvailabilityPreference.frequencyLastUpdated.toJSON()
                : undefined,
            capacityLastUpdated: coachAvailabilityPreference.capacityLastUpdated
                ? coachAvailabilityPreference.capacityLastUpdated.toJSON()
                : undefined,
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.startDate = res.body.startDate ? moment(res.body.startDate) : undefined;
            res.body.endDate = res.body.endDate ? moment(res.body.endDate) : undefined;
            res.body.frequencyLastUpdated = res.body.frequencyLastUpdated ? moment(res.body.frequencyLastUpdated) : undefined;
            res.body.capacityLastUpdated = res.body.capacityLastUpdated ? moment(res.body.capacityLastUpdated) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((coachAvailabilityPreference) => {
                coachAvailabilityPreference.startDate = coachAvailabilityPreference.startDate
                    ? moment(coachAvailabilityPreference.startDate)
                    : undefined;
                coachAvailabilityPreference.endDate = coachAvailabilityPreference.endDate ? moment(coachAvailabilityPreference.endDate) : undefined;
                coachAvailabilityPreference.frequencyLastUpdated = coachAvailabilityPreference.frequencyLastUpdated
                    ? moment(coachAvailabilityPreference.frequencyLastUpdated)
                    : undefined;
                coachAvailabilityPreference.capacityLastUpdated = coachAvailabilityPreference.capacityLastUpdated
                    ? moment(coachAvailabilityPreference.capacityLastUpdated)
                    : undefined;
            });
        }
        return res;
    }
}
CoachAvailabilityPreferenceService.ɵfac = function CoachAvailabilityPreferenceService_Factory(t) { return new (t || CoachAvailabilityPreferenceService)(i0.ɵɵinject(i1.HttpClient)); };
CoachAvailabilityPreferenceService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CoachAvailabilityPreferenceService, factory: CoachAvailabilityPreferenceService.ɵfac, providedIn: 'root' });
