export class ConfirmAttributes {
    constructor(currentLanguage, selectedDate, hour, coach, momentDate, duration) {
        this.selectedDate = selectedDate;
        this.selectedDateTranslated = this.selectedDate.locale(currentLanguage).format('dddd, DD MMM YYYY');
        this.hour = hour;
        this.coach = coach;
        this.selectedDateString = momentDate === null || momentDate === void 0 ? void 0 : momentDate.toString();
        this.duration = duration;
    }
}
