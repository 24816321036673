import { HttpClient } from "@angular/common/http";
import { SERVER_API_URL } from '../../../../app.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CoachUserNoteService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/notes';
    }
    create(note) {
        return this.http.post(`${this.resourceUrl}/create`, note, { observe: 'response' });
    }
    update(note) {
        return this.http.put(`${this.resourceUrl}/update`, note, { observe: 'response' });
    }
    find(coachId, userId) {
        return this.http.get(`${this.resourceUrl}/get?coachId=${coachId}&userId=${userId}`, { observe: 'response' });
    }
    findForSupervisor(userId) {
        return this.http.get(`${this.resourceUrl}/get-for-supervisor?userId=${userId}`, { observe: 'response' });
    }
}
CoachUserNoteService.ɵfac = function CoachUserNoteService_Factory(t) { return new (t || CoachUserNoteService)(i0.ɵɵinject(i1.HttpClient)); };
CoachUserNoteService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CoachUserNoteService, factory: CoachUserNoteService.ɵfac, providedIn: 'root' });
