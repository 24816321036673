import { OfflineMessageService } from "./offline-message.service";
import * as i0 from "@angular/core";
import * as i1 from "./offline-message.service";
export class FilterKeywordPipe {
    constructor(offlineMessageService) {
        this.offlineMessageService = offlineMessageService;
    }
    transform(Conversation, searchKeyword) {
        if (!searchKeyword || !Conversation)
            return Conversation;
        this.offlineMessageService.goFirstPage();
        return Conversation
            .filter(c => { var _a; return (_a = c.user) === null || _a === void 0 ? void 0 : _a.login.toLocaleLowerCase().includes(searchKeyword.toLocaleLowerCase()); });
    }
}
FilterKeywordPipe.ɵfac = function FilterKeywordPipe_Factory(t) { return new (t || FilterKeywordPipe)(i0.ɵɵdirectiveInject(i1.OfflineMessageService, 16)); };
FilterKeywordPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "searchFilter", type: FilterKeywordPipe, pure: false });
