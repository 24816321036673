import { IndexUserComponent } from './index-user.component';
import { UserRouteAccessService } from '../../core/auth/user-route-access-service';
import { Authority } from '../../shared/constants/authority.constants';
export const indexUserRoute = [
    {
        path: 'index-user',
        component: IndexUserComponent,
        canActivate: [UserRouteAccessService],
        data: {
            authorities: [Authority.USER],
            pageTitle: 'home.title'
        }
    }
];
