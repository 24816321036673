import { SessionStorageService } from 'ngx-webstorage';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
export class StateStorageService {
    constructor($sessionStorage) {
        this.$sessionStorage = $sessionStorage;
        this.previousUrlKey = 'previousUrl';
    }
    storeUrl(url) {
        this.$sessionStorage.store(this.previousUrlKey, url);
    }
    getUrl() {
        return this.$sessionStorage.retrieve(this.previousUrlKey);
    }
    clearUrl() {
        this.$sessionStorage.clear(this.previousUrlKey);
    }
}
StateStorageService.ɵfac = function StateStorageService_Factory(t) { return new (t || StateStorageService)(i0.ɵɵinject(i1.SessionStorageService)); };
StateStorageService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: StateStorageService, factory: StateStorageService.ɵfac, providedIn: 'root' });
