import { EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalComponent } from '../modal/modal.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "../alert/alert-error.component";
export class ModalSuccessComponent extends ModalComponent {
    constructor(router, activeModal, modalService) {
        super(router, activeModal, modalService);
        this.router = router;
        this.activeModal = activeModal;
        this.modalService = modalService;
        this.dismissed = new EventEmitter();
    }
    ngOnInit() { }
    dismissHandler() {
        console.log('dismissHandler');
        if (this.router.url.includes('schedule') && this.navigateTo === '../../')
            this.navigateTo = '/index-user';
        if (this.navigateTo) {
            console.log('navigating to ' + this.navigateTo);
            if (this.navigateToQueryParams) {
                this.router.navigate([this.navigateTo], {
                    queryParams: this.navigateToQueryParams
                }).then();
            }
            else {
                this.router.navigate([this.navigateTo]).then();
            }
        }
        // this.cancel();
        this.modalService.dismissAll();
        this.dismissed.emit({ x: 'y' });
    }
    close() {
        this.modalService.dismissAll();
    }
}
ModalSuccessComponent.ɵfac = function ModalSuccessComponent_Factory(t) { return new (t || ModalSuccessComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.NgbActiveModal), i0.ɵɵdirectiveInject(i2.NgbModal)); };
ModalSuccessComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ModalSuccessComponent, selectors: [["modal-success"]], inputs: { titleText: "titleText", message: "message", navigateTo: "navigateTo", navigateToQueryParams: "navigateToQueryParams" }, outputs: { dismissed: "dismissed" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 16, vars: 3, consts: [[1, "olha-theme"], [1, "d-flex", "justify-content-end"], ["icon", "times-circle", 1, "close", 3, "click"], [1, "modal-body", "text-center"], [1, "header"], [1, "d-sm-none"], [3, "jhiTranslate"], [1, "d-none", "d-sm-block", "d-md-block", "d-lg-block", "d-xl-block"], [1, "font-weight-bold"], ["id", "modal-success-message", 1, "text-center", 3, "innerHTML"], [1, "text-center"], ["type", "button", "data-dismiss", "modal", 1, "btn", "btn-primary", "justify-content-center", 3, "click"]], template: function ModalSuccessComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "fa-icon", 2);
        i0.ɵɵlistener("click", function ModalSuccessComponent_Template_fa_icon_click_2_listener() { return ctx.dismissHandler(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelement(6, "h3", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 7);
        i0.ɵɵelement(8, "h2", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelement(9, "jhi-alert-error");
        i0.ɵɵelementStart(10, "div", 8);
        i0.ɵɵelement(11, "p", 9);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(12, "div", 10);
        i0.ɵɵelementStart(13, "button", 11);
        i0.ɵɵlistener("click", function ModalSuccessComponent_Template_button_click_13_listener() { return ctx.dismissHandler(); });
        i0.ɵɵelementStart(14, "span");
        i0.ɵɵtext(15, "OK");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("jhiTranslate", ctx.titleText);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("jhiTranslate", ctx.titleText);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("innerHTML", ctx.message, i0.ɵɵsanitizeHtml);
    } }, directives: [i3.FaIconComponent, i4.JhiTranslateDirective, i5.AlertErrorComponent], styles: [".close[_ngcontent-%COMP%]{color:gray;font-size:20px}button[_ngcontent-%COMP%]{width:114px;height:36px}#modal-success-message[_ngcontent-%COMP%]{font-size:16px}.modal-body[_ngcontent-%COMP%]{padding-top:0 !important}"] });
