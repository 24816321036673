import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JhiThreadModalComponent } from './jhi-metrics-modal-threads.component';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "../../language/jhi-translate.directive";
import * as i3 from "@angular/common";
export class JhiJvmThreadsComponent {
    constructor(modalService) {
        this.modalService = modalService;
    }
    ngOnInit() {
        this.threadStats = {
            threadDumpRunnable: 0,
            threadDumpWaiting: 0,
            threadDumpTimedWaiting: 0,
            threadDumpBlocked: 0,
            threadDumpAll: 0
        };
        this.threadData.forEach((value) => {
            if (value.threadState === 'RUNNABLE') {
                this.threadStats.threadDumpRunnable += 1;
            }
            else if (value.threadState === 'WAITING') {
                this.threadStats.threadDumpWaiting += 1;
            }
            else if (value.threadState === 'TIMED_WAITING') {
                this.threadStats.threadDumpTimedWaiting += 1;
            }
            else if (value.threadState === 'BLOCKED') {
                this.threadStats.threadDumpBlocked += 1;
            }
        });
        this.threadStats.threadDumpAll =
            this.threadStats.threadDumpRunnable +
                this.threadStats.threadDumpWaiting +
                this.threadStats.threadDumpTimedWaiting +
                this.threadStats.threadDumpBlocked;
    }
    open() {
        const modalRef = this.modalService.open(JhiThreadModalComponent);
        modalRef.componentInstance.threadDump = this.threadData;
    }
}
JhiJvmThreadsComponent.ɵfac = function JhiJvmThreadsComponent_Factory(t) { return new (t || JhiJvmThreadsComponent)(i0.ɵɵdirectiveInject(i1.NgbModal)); };
JhiJvmThreadsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: JhiJvmThreadsComponent, selectors: [["jhi-jvm-threads"]], inputs: { threadData: "threadData" }, decls: 39, vars: 37, consts: [["jhiTranslate", "metrics.jvm.threads.title"], ["jhiTranslate", "metrics.jvm.threads.runnable"], ["type", "success", 3, "value", "max", "striped", "animated"], ["jhiTranslate", "metrics.jvm.threads.timedwaiting"], ["type", "warning", 3, "value", "max", "striped", "animated"], ["jhiTranslate", "metrics.jvm.threads.waiting"], ["jhiTranslate", "metrics.jvm.threads.blocked"], ["data-toggle", "modal", "data-target", "#threadDump", 1, "hand", "btn", "btn-primary", "btn-sm", 3, "click"]], template: function JhiJvmThreadsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "h4", 0);
        i0.ɵɵtext(1, "Threads");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "span");
        i0.ɵɵelementStart(3, "span", 1);
        i0.ɵɵtext(4, "Runnable");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "ngb-progressbar", 2);
        i0.ɵɵelementStart(7, "span");
        i0.ɵɵtext(8);
        i0.ɵɵpipe(9, "number");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(10, "span");
        i0.ɵɵelementStart(11, "span", 3);
        i0.ɵɵtext(12, "Timed Waiting");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(13);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(14, "ngb-progressbar", 4);
        i0.ɵɵelementStart(15, "span");
        i0.ɵɵtext(16);
        i0.ɵɵpipe(17, "number");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(18, "span");
        i0.ɵɵelementStart(19, "span", 5);
        i0.ɵɵtext(20, "Waiting");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(21);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(22, "ngb-progressbar", 4);
        i0.ɵɵelementStart(23, "span");
        i0.ɵɵtext(24);
        i0.ɵɵpipe(25, "number");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(26, "span");
        i0.ɵɵelementStart(27, "span", 6);
        i0.ɵɵtext(28, "Blocked");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(29);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(30, "ngb-progressbar", 2);
        i0.ɵɵelementStart(31, "span");
        i0.ɵɵtext(32);
        i0.ɵɵpipe(33, "number");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(34, "div");
        i0.ɵɵtext(35);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(36, "button", 7);
        i0.ɵɵlistener("click", function JhiJvmThreadsComponent_Template_button_click_36_listener() { return ctx.open(); });
        i0.ɵɵelementStart(37, "span");
        i0.ɵɵtext(38, "Expand");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate1(" ", ctx.threadStats.threadDumpRunnable, "");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("value", ctx.threadStats.threadDumpRunnable)("max", ctx.threadStats.threadDumpAll)("striped", true)("animated", false);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(9, 25, ctx.threadStats.threadDumpRunnable * 100 / ctx.threadStats.threadDumpAll, "1.0-0"), "%");
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate1(" (", ctx.threadStats.threadDumpTimedWaiting, ")");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("value", ctx.threadStats.threadDumpTimedWaiting)("max", ctx.threadStats.threadDumpAll)("striped", true)("animated", false);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(17, 28, ctx.threadStats.threadDumpTimedWaiting * 100 / ctx.threadStats.threadDumpAll, "1.0-0"), "%");
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate1(" (", ctx.threadStats.threadDumpWaiting, ")");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("value", ctx.threadStats.threadDumpWaiting)("max", ctx.threadStats.threadDumpAll)("striped", true)("animated", false);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(25, 31, ctx.threadStats.threadDumpWaiting * 100 / ctx.threadStats.threadDumpAll, "1.0-0"), "%");
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate1(" (", ctx.threadStats.threadDumpBlocked, ")");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("value", ctx.threadStats.threadDumpBlocked)("max", ctx.threadStats.threadDumpAll)("striped", true)("animated", false);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(33, 34, ctx.threadStats.threadDumpBlocked * 100 / ctx.threadStats.threadDumpAll, "1.0-0"), "%");
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate1("Total: ", ctx.threadStats.threadDumpAll, "");
    } }, directives: [i2.JhiTranslateDirective, i1.NgbProgressbar], pipes: [i3.DecimalPipe], encapsulation: 2 });
