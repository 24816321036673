import moment from "moment";
export class WeekDay {
    constructor(dayNumber) {
        this.dayNumber = dayNumber;
        this.dateMoment = moment().isoWeekday(dayNumber);
        this.dayInThree = this.dateMoment.format('ddd');
        this.dayInTwo = this.dateMoment.format('dd');
        this.disabled = false;
        this.hasSlots = false;
    }
}
