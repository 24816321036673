import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
function StepperComponent_div_2_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 9);
} }
function StepperComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 5);
    i0.ɵɵelementStart(4, "div", 6);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(6, "div", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, StepperComponent_div_2_div_7_Template, 1, 0, "div", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i_r2 + 1);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(item_r1);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", i_r2 == ctx_r0.items.length - 1);
} }
export class StepperComponent {
    constructor() { }
    ngOnInit() {
    }
}
StepperComponent.ɵfac = function StepperComponent_Factory(t) { return new (t || StepperComponent)(); };
StepperComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: StepperComponent, selectors: [["stepper-component"]], inputs: { items: "items" }, decls: 3, vars: 1, consts: [[1, "list-wrapper"], [1, "red-line"], ["class", "list-item-wrapper", 4, "ngFor", "ngForOf"], [1, "list-item-wrapper"], [1, "list-bullet"], [1, "list-item"], [1, "list-title"], [1, "list-text"], ["class", "white-line", 4, "ngIf"], [1, "white-line"]], template: function StepperComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "div", 1);
        i0.ɵɵtemplate(2, StepperComponent_div_2_Template, 8, 3, "div", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.items);
    } }, directives: [i1.NgForOf, i1.NgIf], styles: [".list-wrapper[_ngcontent-%COMP%]{position:relative}.list-item-wrapper[_ngcontent-%COMP%]{margin-top:10px;position:relative;min-height:3rem;z-index:1}.list-bullet[_ngcontent-%COMP%]{float:left;margin-right:20px;background:var(--primary-color);height:30px;width:30px;line-height:30px;border-radius:100px;font-weight:700;color:#fff;text-align:center}.list-item[_ngcontent-%COMP%]{display:table-row;vertical-align:middle}.list-text[_ngcontent-%COMP%]{font-weight:400}.red-line[_ngcontent-%COMP%]{background:var(--primary-color);z-index:0;width:1px;height:100%;position:absolute;left:15px}.white-line[_ngcontent-%COMP%]{background:#fff;z-index:-1;top:0;width:1px;height:100%;position:absolute;left:15px}"] });
