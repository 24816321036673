import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/router";
import * as i3 from "../../ng-jhipster/language/jhi-translate.directive";
import * as i4 from "@angular/common";
function ErrorComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.errorMessage);
} }
export class ErrorComponent {
    constructor(translateService, route) {
        this.translateService = translateService;
        this.route = route;
    }
    ngOnInit() {
        this.route.data.subscribe(routeData => {
            if (routeData.errorMessage) {
                this.errorKey = routeData.errorMessage;
                this.getErrorMessageTranslation();
                this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => this.getErrorMessageTranslation());
            }
        });
    }
    ngOnDestroy() {
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }
    getErrorMessageTranslation() {
        this.errorMessage = '';
        if (this.errorKey) {
            this.translateService.get(this.errorKey).subscribe(translatedErrorMessage => (this.errorMessage = translatedErrorMessage));
        }
    }
}
ErrorComponent.ɵfac = function ErrorComponent_Factory(t) { return new (t || ErrorComponent)(i0.ɵɵdirectiveInject(i1.TranslateService), i0.ɵɵdirectiveInject(i2.ActivatedRoute)); };
ErrorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ErrorComponent, selectors: [["jhi-error"]], decls: 8, vars: 1, consts: [[1, "row"], [1, "col-md-4"], [1, "hipster", "img-fluid", "rounded"], [1, "col-md-8"], ["jhiTranslate", "error.title"], [4, "ngIf"], [1, "alert", "alert-danger"]], template: function ErrorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelement(3, "span", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 3);
        i0.ɵɵelementStart(5, "h1", 4);
        i0.ɵɵtext(6, "Error Page!");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(7, ErrorComponent_div_7_Template, 3, 1, "div", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(7);
        i0.ɵɵproperty("ngIf", ctx.errorMessage);
    } }, directives: [i3.JhiTranslateDirective, i4.NgIf], encapsulation: 2 });
