import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import { of, ReplaySubject } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { StateStorageService } from './state-storage.service';
import { SERVER_API_URL } from '../../app.constants';
import { Authority } from '../../shared/constants/authority.constants';
import { AuthServerProvider } from './auth-jwt.service';
import { JhiLanguageService } from '../../ng-jhipster/language';
import * as i0 from "@angular/core";
import * as i1 from "./auth-jwt.service";
import * as i2 from "../../ng-jhipster/language";
import * as i3 from "ngx-webstorage";
import * as i4 from "@angular/common/http";
import * as i5 from "./state-storage.service";
import * as i6 from "@angular/router";
// window.chatUserName = window.chatUserName || {};
export class AccountService {
    constructor(authServerProvider, languageService, sessionStorage, http, stateStorageService, router) {
        this.authServerProvider = authServerProvider;
        this.languageService = languageService;
        this.sessionStorage = sessionStorage;
        this.http = http;
        this.stateStorageService = stateStorageService;
        this.router = router;
        this.userIdentity = null;
        this.authenticationState = new ReplaySubject(1);
        this.expires = new Date();
    }
    save(account) {
        return this.http.post(SERVER_API_URL + 'api/account', account, { responseType: 'text' });
    }
    saveCoach(account) {
        return this.http.post(SERVER_API_URL + 'api/account-coach', account, { responseType: 'text' });
    }
    saveLanguage(account) {
        return this.http.post(SERVER_API_URL + 'api/account/language', account, { responseType: 'text' });
    }
    saveTimezone(timezone) {
        return this.http.post(SERVER_API_URL + 'api/account/timezone', timezone);
    }
    authenticate(identity) {
        console.log('Authenticated: ', identity === null || identity === void 0 ? void 0 : identity.login);
        this.userIdentity = identity;
        this.authenticationState.next(this.userIdentity);
        if (identity) {
            // this.setCookies(identity);
            this.sessionStorage.store('id', identity.id);
        }
        if (identity) {
            // disconnect in case user is connected with anonymous access
            // this.trackerService.disconnect();
            //
            // this.trackerService.connectWithId(identity.id);
        }
        else {
            // this.trackerService.disconnect();
            if ((this.router.url === '/user-settings' || this.router.url === '/coach-settings') && identity === null) {
                this.router.navigate(['/login']);
            }
        }
    }
    hasAnyAuthority(authorities) {
        if (!this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }
        if (!Array.isArray(authorities)) {
            authorities = [authorities];
        }
        return this.userIdentity.authorities.some((authority) => authorities.includes(authority));
    }
    identity(force) {
        if (!this.accountCache$ || force || !this.isAuthenticated()) {
            const token = this.authServerProvider.getToken();
            // we don't need to call /account api if there is no jwt token stored
            if (!(token === null || token === void 0 ? void 0 : token.length)) {
                return of(null);
            }
            this.accountCache$ = this.fetch().pipe(catchError(() => {
                return of(null);
            }), tap((account) => {
                this.authenticate(account);
                // After retrieve the account info, the language will be changed to
                // the user's preferred language configured in the account setting
                if (account && account.langKey) {
                    const langKey = this.sessionStorage.retrieve('locale') || account.langKey;
                    this.languageService.changeLanguage(langKey);
                }
                if (account) {
                    this.navigateToStoredUrl();
                }
            }), shareReplay());
        }
        return this.accountCache$;
    }
    // TODO consider removing this service since it is misleading.
    // When you open a new tab and load the application in the new tab, userIdentity field is not initialized yet.
    // userIdentity field is initialized only after calling identity() method.
    isAuthenticated() {
        return this.userIdentity !== null;
    }
    getAuthenticationState() {
        return this.authenticationState.asObservable();
    }
    getImageUrl() {
        return this.userIdentity ? this.userIdentity.imageUrl : '';
    }
    getCurrentLoggedInUser() {
        return this.userIdentity;
    }
    fetch() {
        return this.http.get(SERVER_API_URL + 'api/account');
    }
    navigateToStoredUrl() {
        // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
        // if login is successful, go to stored previousState and clear previousState
        const previousUrl = this.stateStorageService.getUrl();
        if (previousUrl) {
            console.log('navigating to previous url: ' + previousUrl);
            this.stateStorageService.clearUrl();
            this.router.navigateByUrl(previousUrl);
        }
    }
    // TODO consider removing after migration
    clearCookies() {
        let expiresNow = new Date();
        this.setCookieToken(expiresNow);
        this.setCookieUserId(0, expiresNow);
        this.setCookieEmail('', expiresNow);
        this.setCookieRoles([], expiresNow);
        this.setCookieType([], expiresNow);
    }
    setCookies(identity) {
        window.chatUserName = identity.login;
        window.userId = identity.id;
        window.userCountry = identity.country;
        window.isCorporate = identity.corporate;
        let expires = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
        this.setCookieToken(expires);
        this.setCookieUserId(identity.id, expires);
        this.setCookieEmail(identity.email, expires);
        this.setCookieRoles(identity.authorities, expires);
        this.setCookieType(identity.authorities, expires);
        localStorage.setItem('bookingAllowed', 'true');
    }
    setCookieType(authorities, expires) {
        document.cookie = this.setCookie('Type', this.decideUserType(authorities), expires);
    }
    setCookieRoles(authorities, expires) {
        document.cookie = this.setCookie('Roles', authorities, expires);
    }
    setCookieEmail(email, expires) {
        document.cookie = this.setCookie('Email', email, expires);
    }
    setCookieUserId(id, expires) {
        document.cookie = this.setCookie('UserId', id, expires);
    }
    setCookieToken(expires) {
        document.cookie = this.setCookie('Token', this.authServerProvider.getToken(), expires);
    }
    setCookie(cookieName, cookieValue, expires) {
        return `${cookieName}=${cookieValue};Expires=` + expires.toUTCString() + '; Path=/';
    }
    decideUserType(authorities) {
        let supervisorAuthorities = [Authority.MASTERMIND, Authority.SUPERVISOR, Authority.ADMIN];
        for (const authority of authorities) {
            if (authority === Authority.USER) {
                return '1';
            }
            else if (authority === Authority.COACH) {
                return '2';
            }
            else if (supervisorAuthorities.includes(authority)) {
                return '3';
            }
        }
        return '-1';
    }
    isCoach() {
        if (!this.userIdentity) {
            // when the user logs out the userIdentity would be null
            return false;
        }
        let strings = this.userIdentity.authorities;
        return strings.includes(Authority.COACH);
    }
    isCoachUser(user) {
        var _a;
        return (_a = user === null || user === void 0 ? void 0 : user.authorities) === null || _a === void 0 ? void 0 : _a.includes(Authority.COACH);
    }
    isUser() {
        if (!this.userIdentity) {
            // when the user logs out the userIdentity would be null
            return false;
        }
        let strings = this.userIdentity.authorities;
        return strings.includes(Authority.USER);
    }
    isCustomerCare() {
        if (!this.userIdentity) {
            // when the user logs out the userIdentity would be null
            return false;
        }
        let strings = this.userIdentity.authorities;
        return strings.includes(Authority.CUSTOMER_CARE);
    }
    isSupervisor() {
        if (!this.userIdentity) {
            // when the user logs out the userIdentity would be null
            return false;
        }
        let strings = this.userIdentity.authorities;
        return strings.includes(Authority.SUPERVISOR);
    }
    isMastermind() {
        if (!this.userIdentity) {
            // when the user logs out the userIdentity would be null
            return false;
        }
        let strings = this.userIdentity.authorities;
        return strings.includes(Authority.MASTERMIND);
    }
    redirectToHomePage() {
        if (this.hasAnyAuthority(['ROLE_COACH'])) {
            this.router.navigate(['/index-coach']);
            return;
        }
        else if (this.hasAnyAuthority(['ROLE_CUSTOMER_CARE'])) {
            this.router.navigate(['/session/inbox/all']);
            return;
        }
        else if (this.hasAnyAuthority(['ROLE_SUPERVISOR', 'ROLE_ADMIN', 'ROLE_MASTERMIND'])) {
            this.router.navigate(['/admin/session-management']);
            return;
        }
        else if (this.hasAnyAuthority(['ROLE_ORGANIZATION_ADMIN'])) {
            this.router.navigate(['/organization-selection']);
            return;
        }
        else if (this.hasAnyAuthority(['ROLE_USER'])) {
            this.router.navigate(['/index-user']);
            return;
        }
        else {
            console.log('Invalid authority');
        }
    }
}
AccountService.ɵfac = function AccountService_Factory(t) { return new (t || AccountService)(i0.ɵɵinject(i1.AuthServerProvider), i0.ɵɵinject(i2.JhiLanguageService), i0.ɵɵinject(i3.SessionStorageService), i0.ɵɵinject(i4.HttpClient), i0.ɵɵinject(i5.StateStorageService), i0.ɵɵinject(i6.Router)); };
AccountService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AccountService, factory: AccountService.ɵfac, providedIn: 'root' });
