import { CommonModule } from '@angular/common';
import { OrganizationDashboardRoutingModule } from './organization-dashboard-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { InukaNgSharedModule } from '../../shared/shared.module';
import { MatExpansionModule } from "@angular/material/expansion";
import { NgJhipsterModule } from '../../ng-jhipster/ng-jhipster.module';
import * as i0 from "@angular/core";
import * as i1 from "ngx-echarts";
export class OrganizationDashboardModule {
}
OrganizationDashboardModule.ɵfac = function OrganizationDashboardModule_Factory(t) { return new (t || OrganizationDashboardModule)(); };
OrganizationDashboardModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: OrganizationDashboardModule });
OrganizationDashboardModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [[
            CommonModule,
            OrganizationDashboardRoutingModule,
            NgxEchartsModule.forRoot({
                /**
                 * This will import all modules from echarts.
                 * If you only need custom modules,
                 * please refer to [Custom Build] section.
                 */
                // echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
                echarts: () => import(
                /* webpackChunkName: "charts" */
                /* webpackMode: "lazy" */
                './charts').then(m => m.default)
            }),
            InukaNgSharedModule,
            NgJhipsterModule,
            MatExpansionModule
        ]] });
