import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BrowserDetector } from '../../../core/util/browser-detector';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@angular/common";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
function DeviceAccessDeniedNotificationComponent_ng_container_6_img_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 11);
} }
function DeviceAccessDeniedNotificationComponent_ng_container_6_img_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 12);
} }
function DeviceAccessDeniedNotificationComponent_ng_container_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DeviceAccessDeniedNotificationComponent_ng_container_6_img_1_Template, 1, 0, "img", 9);
    i0.ɵɵtemplate(2, DeviceAccessDeniedNotificationComponent_ng_container_6_img_2_Template, 1, 0, "img", 10);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.isDesktop);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.isDesktop);
} }
function DeviceAccessDeniedNotificationComponent_ng_container_7_img_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 15);
} }
function DeviceAccessDeniedNotificationComponent_ng_container_7_img_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 16);
} }
function DeviceAccessDeniedNotificationComponent_ng_container_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DeviceAccessDeniedNotificationComponent_ng_container_7_img_1_Template, 1, 0, "img", 13);
    i0.ɵɵtemplate(2, DeviceAccessDeniedNotificationComponent_ng_container_7_img_2_Template, 1, 0, "img", 14);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.isDesktop);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r1.isDesktop);
} }
function DeviceAccessDeniedNotificationComponent_span_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 17);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("jhiTranslate", ctx_r2.isSafari ? "sessionChat.coachSessionControl.grantPermissionSafari" : "sessionChat.coachSessionControl.grantPermission");
} }
function DeviceAccessDeniedNotificationComponent_span_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 17);
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("jhiTranslate", ctx_r3.isSafari ? "sessionChat.coachSessionControl.grantPermissionSafariMobile" : "sessionChat.coachSessionControl.grantPermissionMobile");
} }
export class DeviceAccessDeniedNotificationComponent {
    constructor(activeModal) {
        this.activeModal = activeModal;
    }
    ngOnInit() {
        this.isSafari = BrowserDetector.isSafari();
        this.isDesktop = BrowserDetector.isDesktop();
    }
    close() {
        this.activeModal.dismiss();
    }
}
DeviceAccessDeniedNotificationComponent.ɵfac = function DeviceAccessDeniedNotificationComponent_Factory(t) { return new (t || DeviceAccessDeniedNotificationComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal)); };
DeviceAccessDeniedNotificationComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: DeviceAccessDeniedNotificationComponent, selectors: [["device-access-denied-notification"]], decls: 12, vars: 4, consts: [[1, "olha-theme"], [1, "modal-body"], [1, "d-flex", "justify-content-end"], ["icon", "times-circle", 1, "close-icon", 3, "click"], [1, "modal-div", "text-center", "p-4"], [1, "mb-4"], [4, "ngIf"], ["jhiTranslate", "sessionChat.coachSessionControl.allowCameraOrMicrophone"], [3, "jhiTranslate", 4, "ngIf"], ["src", "../../../../../content/images/give-access-safari.png", "alt", "give-access-safari.png", 4, "ngIf"], ["src", "../../../../../content/images/give-access-safari-mobile.png", "alt", "give-access-safari.png", 4, "ngIf"], ["src", "../../../../../content/images/give-access-safari.png", "alt", "give-access-safari.png"], ["src", "../../../../../content/images/give-access-safari-mobile.png", "alt", "give-access-safari.png"], ["src", "../../../../../content/images/give-access-general.png", "alt", "give-access-general.png", 4, "ngIf"], ["src", "../../../../../content/images/give-access-general-mobile.png", "alt", "give-access-general.png", 4, "ngIf"], ["src", "../../../../../content/images/give-access-general.png", "alt", "give-access-general.png"], ["src", "../../../../../content/images/give-access-general-mobile.png", "alt", "give-access-general.png"], [3, "jhiTranslate"]], template: function DeviceAccessDeniedNotificationComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function DeviceAccessDeniedNotificationComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵtemplate(6, DeviceAccessDeniedNotificationComponent_ng_container_6_Template, 3, 2, "ng-container", 6);
        i0.ɵɵtemplate(7, DeviceAccessDeniedNotificationComponent_ng_container_7_Template, 3, 2, "ng-container", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "div");
        i0.ɵɵelement(9, "h3", 7);
        i0.ɵɵtemplate(10, DeviceAccessDeniedNotificationComponent_span_10_Template, 1, 1, "span", 8);
        i0.ɵɵtemplate(11, DeviceAccessDeniedNotificationComponent_span_11_Template, 1, 1, "span", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("ngIf", ctx.isSafari);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isSafari);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.isDesktop);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isDesktop);
    } }, directives: [i2.FaIconComponent, i3.NgIf, i4.JhiTranslateDirective], styles: [".modal-body[_ngcontent-%COMP%]{padding:10px}"] });
