import { TemplateRef, ViewContainerRef } from '@angular/core';
import { AccountService } from '../../core/auth/account.service';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth/account.service";
/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhiHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *jhiHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
export class HasAnyAuthorityDirective {
    constructor(accountService, templateRef, viewContainerRef) {
        this.accountService = accountService;
        this.templateRef = templateRef;
        this.viewContainerRef = viewContainerRef;
        this.authorities = [];
    }
    set jhiHasAnyAuthority(value) {
        this.authorities = typeof value === 'string' ? [value] : value;
        this.updateView();
        // Get notified each time authentication state changes.
        this.authenticationSubscription = this.accountService.getAuthenticationState().subscribe(() => this.updateView());
    }
    ngOnDestroy() {
        if (this.authenticationSubscription) {
            this.authenticationSubscription.unsubscribe();
        }
    }
    updateView() {
        const hasAnyAuthority = this.accountService.hasAnyAuthority(this.authorities);
        this.viewContainerRef.clear();
        if (hasAnyAuthority) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
HasAnyAuthorityDirective.ɵfac = function HasAnyAuthorityDirective_Factory(t) { return new (t || HasAnyAuthorityDirective)(i0.ɵɵdirectiveInject(i1.AccountService), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef)); };
HasAnyAuthorityDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: HasAnyAuthorityDirective, selectors: [["", "jhiHasAnyAuthority", ""]], inputs: { jhiHasAnyAuthority: "jhiHasAnyAuthority" } });
