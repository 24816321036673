import moment from "moment";
import { RatingService } from '../../rating/rating.service';
import { OrganizationReportParameters } from '../../../shared/model/organization-report-parameters.model';
import { OrganizationDashboardService } from '../organization-dashboard.service';
import * as i0 from "@angular/core";
import * as i1 from "../../rating/rating.service";
import * as i2 from "../organization-dashboard.service";
import * as i3 from "@angular/common";
import * as i4 from "../../../ng-jhipster/language/jhi-translate.directive";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@fortawesome/angular-fontawesome";
const _c0 = function (a0) { return { coachRating: a0 }; };
const _c1 = function (a0) { return { appRating: a0 }; };
function OrganizationDashboardIndexHeaderComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵelementStart(2, "div", 4);
    i0.ɵɵelementStart(3, "div", 5);
    i0.ɵɵelementStart(4, "div", 6);
    i0.ɵɵelementStart(5, "span", 7);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelement(7, "div", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 9);
    i0.ɵɵelementStart(9, "div", 10);
    i0.ɵɵelementStart(10, "div", 11);
    i0.ɵɵtext(11);
    i0.ɵɵpipe(12, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "div", 12);
    i0.ɵɵelementStart(14, "div", 13);
    i0.ɵɵtext(15, "Coach rating");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "div", 14);
    i0.ɵɵelementStart(17, "div", 15);
    i0.ɵɵelementStart(18, "ngb-rating", 16);
    i0.ɵɵlistener("rateChange", function OrganizationDashboardIndexHeaderComponent_div_0_Template_ngb_rating_rateChange_18_listener($event) { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.ratingInfo.coachAverage = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(19, "div", 17);
    i0.ɵɵtext(20, "|");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(21, "div", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(22, "div", 19);
    i0.ɵɵelementStart(23, "div", 11);
    i0.ɵɵtext(24);
    i0.ɵɵpipe(25, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(26, "div", 12);
    i0.ɵɵelementStart(27, "div", 20);
    i0.ɵɵtext(28, "App rating");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(29, "div", 14);
    i0.ɵɵelementStart(30, "div", 15);
    i0.ɵɵelementStart(31, "ngb-rating", 16);
    i0.ɵɵlistener("rateChange", function OrganizationDashboardIndexHeaderComponent_div_0_Template_ngb_rating_rateChange_31_listener($event) { i0.ɵɵrestoreView(_r4); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.ratingInfo.appAverage = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(32, "div", 17);
    i0.ɵɵtext(33, "|");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(34, "div", 21);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(35, "div", 22);
    i0.ɵɵelementStart(36, "div", 4);
    i0.ɵɵelementStart(37, "div", 5);
    i0.ɵɵelementStart(38, "div", 6);
    i0.ɵɵelementStart(39, "span", 7);
    i0.ɵɵtext(40);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelement(41, "div", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(42, "div", 9);
    i0.ɵɵelementStart(43, "div", 23);
    i0.ɵɵelementStart(44, "div", 20);
    i0.ɵɵtext(45, "App rating");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(46, "div", 11);
    i0.ɵɵtext(47);
    i0.ɵɵpipe(48, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(49, "div", 12);
    i0.ɵɵelementStart(50, "div", 12);
    i0.ɵɵelementStart(51, "div", 15);
    i0.ɵɵelementStart(52, "ngb-rating", 16);
    i0.ɵɵlistener("rateChange", function OrganizationDashboardIndexHeaderComponent_div_0_Template_ngb_rating_rateChange_52_listener($event) { i0.ɵɵrestoreView(_r4); const ctx_r6 = i0.ɵɵnextContext(); return ctx_r6.ratingInfo.appAverage = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelement(53, "div", 21);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(54, "div", 24);
    i0.ɵɵelementStart(55, "div", 13);
    i0.ɵɵtext(56, "Coach rating");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(57, "div", 11);
    i0.ɵɵtext(58);
    i0.ɵɵpipe(59, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(60, "div", 12);
    i0.ɵɵelementStart(61, "div", 12);
    i0.ɵɵelementStart(62, "div", 15);
    i0.ɵɵelementStart(63, "ngb-rating", 16);
    i0.ɵɵlistener("rateChange", function OrganizationDashboardIndexHeaderComponent_div_0_Template_ngb_rating_rateChange_63_listener($event) { i0.ɵɵrestoreView(_r4); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.ratingInfo.coachAverage = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelement(64, "div", 18);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const _r1 = i0.ɵɵreference(2);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.now);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("jhiTranslate", ctx_r0.selectedTab);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(12, 24, ctx_r0.ratingInfo.coachAverage, "1.0-1"));
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("rate", ctx_r0.ratingInfo.coachAverage)("starTemplate", _r1)("readonly", true);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(36, _c0, ctx_r0.ratingInfo.coachRatingCount));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(25, 27, ctx_r0.ratingInfo.appAverage, "1.0-1"));
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("rate", ctx_r0.ratingInfo.appAverage)("starTemplate", _r1)("readonly", true);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(38, _c1, ctx_r0.ratingInfo.appRatingCount));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r0.now);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("jhiTranslate", ctx_r0.selectedTab);
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(48, 30, ctx_r0.ratingInfo.appAverage, "1.0-1"));
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("rate", ctx_r0.ratingInfo.appAverage)("starTemplate", _r1)("readonly", true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(40, _c1, ctx_r0.ratingInfo.appRatingCount));
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(59, 33, ctx_r0.ratingInfo.coachAverage, "1.0-1"));
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("rate", ctx_r0.ratingInfo.coachAverage)("starTemplate", _r1)("readonly", true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("translateValues", i0.ɵɵpureFunction1(42, _c0, ctx_r0.ratingInfo.coachRatingCount));
} }
const _c2 = function () { return ["fas", "star"]; };
const _c3 = function () { return ["far", "star"]; };
function OrganizationDashboardIndexHeaderComponent_ng_template_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 25);
    i0.ɵɵelementStart(1, "span", 26);
    i0.ɵɵelementStart(2, "em", 27);
    i0.ɵɵelement(3, "fa-icon", 28);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "em", 29);
    i0.ɵɵelement(5, "fa-icon", 28);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const fill_r8 = ctx.fill;
    i0.ɵɵclassProp("full", fill_r8 === 100);
    i0.ɵɵadvance(1);
    i0.ɵɵstyleProp("width", fill_r8, "%");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(6, _c2));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(7, _c3));
} }
export class OrganizationDashboardIndexHeaderComponent {
    constructor(ratingService, organizationDashboardService) {
        this.ratingService = ratingService;
        this.organizationDashboardService = organizationDashboardService;
    }
    set selectedTab(value) {
        this._selectedTab = value;
    }
    get selectedTab() {
        return this._selectedTab;
    }
    set parameters(value) {
        this._parameters = value;
        this.getRatingParameters();
    }
    get parameters() {
        return this._parameters;
    }
    ngOnInit() {
        this.now = moment().format('D MMMM YYYY');
    }
    getRatingParameters() {
        console.log(this.parameters);
        this.organizationDashboardService.getAuthorizedRatingsInfo(this.parameters).subscribe(res => {
            console.log(res);
            this.ratingInfo = res.body;
        });
    }
}
OrganizationDashboardIndexHeaderComponent.ɵfac = function OrganizationDashboardIndexHeaderComponent_Factory(t) { return new (t || OrganizationDashboardIndexHeaderComponent)(i0.ɵɵdirectiveInject(i1.RatingService), i0.ɵɵdirectiveInject(i2.OrganizationDashboardService)); };
OrganizationDashboardIndexHeaderComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OrganizationDashboardIndexHeaderComponent, selectors: [["organization-dashboard-index-header"]], inputs: { selectedTab: "selectedTab", parameters: "parameters" }, decls: 3, vars: 1, consts: [["class", "olha-theme mt-3", 4, "ngIf"], ["t", ""], [1, "olha-theme", "mt-3"], [1, "d-none", "d-md-block"], [1, "organization-header-component", "d-flex", "justify-content-between", "align-items-center"], [1, "left-side-header"], [1, "index-date"], [1, "writer"], [1, "index-header", 3, "jhiTranslate"], [1, "right-side-header", "d-flex", "justify-content-end", "align-items-center"], [1, "d-flex", "justify-content-start", "align-items-center", "mr-3"], [1, "rating-number"], [1, "rating-stars"], ["jhiTranslate", "sessionManagement.coachRating", 1, "rating-header"], [1, "rating-stars", "d-flex", "justify-content-start", "align-items-center"], [1, "ngb-rating"], ["max", "5", 3, "rate", "starTemplate", "readonly", "rateChange"], [1, "vertical-line"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationDashboardIndexHeader.coachRating", 1, "rating-count", 3, "translateValues"], [1, "d-flex", "justify-content-start", "align-items-center", "ml-3"], ["jhiTranslate", "inukaNgApp.rating.appRating", 1, "rating-header"], ["jhiTranslate", "inukaNgApp.organizationAdmin.organizationDashboardIndexHeader.appRating", 1, "rating-count", 3, "translateValues"], [1, "d-md-none"], [1, "mr-3"], [1, "ml-3"], [1, "star"], [1, "half"], [2, "color", "#44A38D"], [3, "icon"], [1, "cursor", 2, "color", "#44A38D"]], template: function OrganizationDashboardIndexHeaderComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, OrganizationDashboardIndexHeaderComponent_div_0_Template, 65, 44, "div", 0);
        i0.ɵɵtemplate(1, OrganizationDashboardIndexHeaderComponent_ng_template_1_Template, 6, 8, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.ratingInfo);
    } }, directives: [i3.NgIf, i4.JhiTranslateDirective, i5.NgbRating, i6.FaIconComponent], pipes: [i3.DecimalPipe], styles: [".organization-header-component[_ngcontent-%COMP%]{margin-bottom:30px}.index-date[_ngcontent-%COMP%]{margin-bottom:0}.writer[_ngcontent-%COMP%]{font-family:Open Sans,serif;font-size:10px;font-weight:400;line-height:12px;letter-spacing:0;text-align:left;color:#9db7b0}.index-header[_ngcontent-%COMP%]{font-family:Poppins,serif;font-size:20px;font-weight:700;line-height:25px;letter-spacing:0;text-align:left;color:#0a4a3a}.rating-number[_ngcontent-%COMP%]{font-family:Poppins,serif;font-size:18px;font-weight:600;line-height:16px;letter-spacing:0;text-align:center;color:#44a38b}.rating-header[_ngcontent-%COMP%]{font-family:Open Sans,serif;font-size:12px;font-weight:400;line-height:17px;letter-spacing:0;text-align:center;color:#0a4a3a}.rating-count[_ngcontent-%COMP%]{font-size:12px}.vertical-line[_ngcontent-%COMP%]{color:#9db7b0;margin:0 3px 0 3px}.star[_ngcontent-%COMP%]{position:relative;display:inline-block;font-size:1rem}.half[_ngcontent-%COMP%]{position:absolute;display:inline-block;overflow:hidden}@media(min-width: 768px){.index-date[_ngcontent-%COMP%]{margin-bottom:10px}.writer[_ngcontent-%COMP%]{font-family:Open Sans,serif;font-size:12px;font-weight:400;line-height:17px;letter-spacing:0;text-align:left;color:#9db7b0}.index-header[_ngcontent-%COMP%]{font-family:Poppins,serif;font-size:26px;font-weight:700;line-height:36px;letter-spacing:0;text-align:left;color:#0a4a3a}.rating-header[_ngcontent-%COMP%]{text-align:left}.rating-number[_ngcontent-%COMP%]{font-family:Poppins,serif;font-size:26px;font-weight:700;line-height:36px;margin:10px}.rating-count[_ngcontent-%COMP%]{font-size:14px}}"] });
