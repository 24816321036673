import { ProblemDetail } from '../problem-detail.model';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@angular/common";
function ProblemDetailPopupComponent_h4_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "h4", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.problemDetail.title);
} }
function ProblemDetailPopupComponent_p_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r1.problemDetail.detail);
} }
export class ProblemDetailPopupComponent {
    constructor(modalService) {
        this.modalService = modalService;
    }
    ngOnInit() {
    }
    close() {
        this.modalService.dismissAll();
    }
}
ProblemDetailPopupComponent.ɵfac = function ProblemDetailPopupComponent_Factory(t) { return new (t || ProblemDetailPopupComponent)(i0.ɵɵdirectiveInject(i1.NgbModal)); };
ProblemDetailPopupComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ProblemDetailPopupComponent, selectors: [["problem-detail-popup"]], inputs: { problemDetail: "problemDetail" }, decls: 6, vars: 2, consts: [[1, "olha-theme", "px-4", "pt-1", "pb-3"], [1, "text-center"], [1, "text-right"], ["icon", "times-circle", 1, "close-icon", 3, "click"], ["class", "mt-4", 4, "ngIf"], [4, "ngIf"], [1, "mt-4"]], template: function ProblemDetailPopupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "fa-icon", 3);
        i0.ɵɵlistener("click", function ProblemDetailPopupComponent_Template_fa_icon_click_3_listener() { return ctx.close(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(4, ProblemDetailPopupComponent_h4_4_Template, 2, 1, "h4", 4);
        i0.ɵɵtemplate(5, ProblemDetailPopupComponent_p_5_Template, 2, 1, "p", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.problemDetail == null ? null : ctx.problemDetail.title);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.problemDetail == null ? null : ctx.problemDetail.detail);
    } }, directives: [i2.FaIconComponent, i3.NgIf], styles: [".close-icon[_ngcontent-%COMP%]{position:absolute !important;top:12px !important;right:12px !important;cursor:pointer !important;color:#0a4a3a !important}"] });
