import { EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../ng-jhipster/language/jhi-translate.directive";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/radio";
import * as i5 from "@ctrl/ngx-emoji-mart/ngx-emoji";
import * as i6 from "../../../../shared/language/find-language-from-key.pipe";
import * as i7 from "../../../../shared/language/find-flag-code-from-key.pipe";
function SessionLanguageRadioSelectorComponent_span_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 5);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "findLanguageFromKey");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx_r0.sessionLanguageList[0]));
} }
function SessionLanguageRadioSelectorComponent_mat_radio_button_5_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-radio-button", 6);
    i0.ɵɵlistener("change", function SessionLanguageRadioSelectorComponent_mat_radio_button_5_Template_mat_radio_button_change_0_listener($event) { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.onSessionLanguageChange($event); });
    i0.ɵɵelementStart(1, "div", 7);
    i0.ɵɵelementStart(2, "div", 8);
    i0.ɵɵelement(3, "ngx-emoji", 9);
    i0.ɵɵpipe(4, "findFlagCodeFromKey");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div");
    i0.ɵɵtext(6);
    i0.ɵɵpipe(7, "findLanguageFromKey");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const sl_r2 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("checked", sl_r2 === ctx_r1.sessionLanguage)("value", sl_r2);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate1("emoji", "flag-", i0.ɵɵpipeBind1(4, 4, sl_r2), "");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(7, 6, sl_r2));
} }
export class SessionLanguageRadioSelectorComponent {
    constructor(formBuilder) {
        this.formBuilder = formBuilder;
        this.sessionForm = this.formBuilder.group({ sLanguage: ['', [Validators.required]] });
        this.sessionLanguageChange = new EventEmitter();
    }
    set sessionLanguage(value) {
        if (this._sessionLanguage !== value) {
            this._sessionLanguage = value;
            if (!this.sessionForm) {
                this.sessionForm = this.formBuilder.group({ sLanguage: [this.sessionLanguage] });
            }
            else {
                this.sessionForm.patchValue({ sLanguage: value });
            }
        }
    }
    get sessionLanguage() {
        return this._sessionLanguage;
    }
    ngOnInit() { }
    onSessionLanguageChange(event) {
        this.sessionLanguage = event.value;
        this.sessionLanguageChange.emit(this.sessionLanguage);
    }
}
SessionLanguageRadioSelectorComponent.ɵfac = function SessionLanguageRadioSelectorComponent_Factory(t) { return new (t || SessionLanguageRadioSelectorComponent)(i0.ɵɵdirectiveInject(i1.FormBuilder)); };
SessionLanguageRadioSelectorComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SessionLanguageRadioSelectorComponent, selectors: [["session-language-radio-selector"]], inputs: { sessionLanguageList: "sessionLanguageList", sessionLanguage: "sessionLanguage" }, outputs: { sessionLanguageChange: "sessionLanguageChange" }, decls: 6, vars: 3, consts: [[3, "formGroup"], ["jhiTranslate", "sessionSchedule.paragraph.sessionLanguage", 1, "header"], ["class", "description", 4, "ngIf"], ["formControlName", "sLanguage", 1, "d-flex", "justify-content-between", 2, "flex-flow", "column"], [3, "checked", "value", "change", 4, "ngFor", "ngForOf"], [1, "description"], [3, "checked", "value", "change"], [1, "d-flex", "justify-content-between", "align-items-center", "description"], [1, "d-flex", "justify-content-between", "align-items-end", "mt-1"], [3, "emoji"]], template: function SessionLanguageRadioSelectorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "form", 0);
        i0.ɵɵelementStart(1, "p");
        i0.ɵɵelement(2, "span", 1);
        i0.ɵɵtemplate(3, SessionLanguageRadioSelectorComponent_span_3_Template, 3, 3, "span", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "mat-radio-group", 3);
        i0.ɵɵtemplate(5, SessionLanguageRadioSelectorComponent_mat_radio_button_5_Template, 8, 8, "mat-radio-button", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("formGroup", ctx.sessionForm);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", (ctx.sessionLanguageList == null ? null : ctx.sessionLanguageList.length) == 1);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.sessionLanguageList);
    } }, directives: [i1.ɵNgNoValidate, i1.NgControlStatusGroup, i1.FormGroupDirective, i2.JhiTranslateDirective, i3.NgIf, i4.MatRadioGroup, i1.NgControlStatus, i1.FormControlName, i3.NgForOf, i4.MatRadioButton, i5.EmojiComponent], pipes: [i6.FindLanguageFromKeyPipe, i7.FindFlagCodeFromKeyPipe], styles: [".header[_ngcontent-%COMP%]{font-weight:bold;font-family:Poppins,sans-serif;font-size:16px !important}.description[_ngcontent-%COMP%]{font-family:\"Open Sans\",sans-serif}mat-radio-button[_ngcontent-%COMP%]{font-size:16px !important}ngx-emoji[_ngcontent-%COMP%]{font-size:10px !important;padding-right:3px}"] });
