import { ActivatedRouteSnapshot, Routes, Router } from '@angular/router';
import { of, EMPTY } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Authority } from '../../shared/constants/authority.constants';
import { UserRouteAccessService } from '../../core/auth/user-route-access-service';
import { VideoCall } from '../../shared/model/video-call.model';
import { VideoCallService } from './video-call.service';
import { VideoCallComponent } from './video-call.component';
import { VideoCallDetailComponent } from './video-call-detail.component';
import { VideoCallUpdateComponent } from './video-call-update.component';
import * as i0 from "@angular/core";
import * as i1 from "./video-call.service";
import * as i2 from "@angular/router";
export class VideoCallResolve {
    constructor(service, router) {
        this.service = service;
        this.router = router;
    }
    resolve(route) {
        const id = route.params['id'];
        if (id) {
            return this.service.find(id).pipe(flatMap((videoCall) => {
                if (videoCall.body) {
                    return of(videoCall.body);
                }
                else {
                    this.router.navigate(['404']);
                    return EMPTY;
                }
            }));
        }
        return of(new VideoCall());
    }
}
VideoCallResolve.ɵfac = function VideoCallResolve_Factory(t) { return new (t || VideoCallResolve)(i0.ɵɵinject(i1.VideoCallService), i0.ɵɵinject(i2.Router)); };
VideoCallResolve.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: VideoCallResolve, factory: VideoCallResolve.ɵfac, providedIn: 'root' });
export const videoCallRoute = [
    {
        path: 'video-call/',
        component: VideoCallComponent,
        data: {
            authorities: [Authority.USER],
            pageTitle: 'inukaNgApp.videoCall.home.title',
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'video-call/:id/view',
        component: VideoCallDetailComponent,
        resolve: {
            videoCall: VideoCallResolve,
        },
        data: {
            authorities: [Authority.USER],
            pageTitle: 'inukaNgApp.videoCall.home.title',
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'video-call/new',
        component: VideoCallUpdateComponent,
        resolve: {
            videoCall: VideoCallResolve,
        },
        data: {
            authorities: [Authority.USER],
            pageTitle: 'inukaNgApp.videoCall.home.title',
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'video-call/:id/edit',
        component: VideoCallUpdateComponent,
        resolve: {
            videoCall: VideoCallResolve,
        },
        data: {
            authorities: [Authority.USER],
            pageTitle: 'inukaNgApp.videoCall.home.title',
        },
        canActivate: [UserRouteAccessService],
    },
];
