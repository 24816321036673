import { HttpClient } from "@angular/common/http";
import { SERVER_API_URL } from '../../app.constants';
import { SessionStorageService } from "ngx-webstorage";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-webstorage";
export class ApplicationConfigService {
    constructor(http, sessionStorageService) {
        this.http = http;
        this.sessionStorageService = sessionStorageService;
        this.endpointPrefix = '';
    }
    setEndpointPrefix(endpointPrefix) {
        this.endpointPrefix = endpointPrefix;
    }
    getEndpointFor(api, microservice) {
        if (microservice) {
            return `${this.endpointPrefix}services/${microservice}/${api}`;
        }
        return `${this.endpointPrefix}${api}`;
    }
    fetchApplicationLookAndFeelProperties() {
        console.log("fetchApplicationLookAndFeelProperties");
        return this.http.get(SERVER_API_URL + 'api/util/application-laf');
    }
    fetchApplicationLookAndFeelPropertiesSync() {
        console.log("fetchApplicationLookAndFeelPropertiesSync");
        return {
            "global": {
                "dela": {
                    "font-face": '"ScalaSans", sans-serif'
                },
                "inuka": {
                    "font-face": '"Raleway", sans-serif'
                }
            },
            "RegisterCorporateComponent": {
                "default": {
                    "block_heading": "heading-default"
                },
                "dela": {
                    "block_heading": "heading-dela"
                }
            }
        };
    }
}
ApplicationConfigService.ɵfac = function ApplicationConfigService_Factory(t) { return new (t || ApplicationConfigService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionStorageService)); };
ApplicationConfigService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ApplicationConfigService, factory: ApplicationConfigService.ɵfac, providedIn: 'root' });
