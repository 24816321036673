import { JhiModuleConfig } from './config';
import * as i0 from "@angular/core";
import * as i1 from "./config";
export class JhiConfigService {
    constructor(moduleConfig) {
        this.CONFIG_OPTIONS = Object.assign(Object.assign({}, new JhiModuleConfig()), moduleConfig);
    }
    getConfig() {
        return this.CONFIG_OPTIONS;
    }
}
JhiConfigService.ɵfac = function JhiConfigService_Factory(t) { return new (t || JhiConfigService)(i0.ɵɵinject(i1.JhiModuleConfig)); };
JhiConfigService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: JhiConfigService, factory: JhiConfigService.ɵfac, providedIn: 'root' });
