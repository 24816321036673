import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';
import { ValueWrapper } from '../../shared/model/value-wrapper.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VideoCallService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = SERVER_API_URL + 'api/video-calls';
    }
    create(videoCall) {
        const copy = this.convertDateFromClient(videoCall);
        return this.http
            .post(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    update(videoCall) {
        const copy = this.convertDateFromClient(videoCall);
        return this.http
            .put(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    find(id) {
        return this.http
            .get(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res) => this.convertDateFromServer(res)));
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http
            .get(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res) => this.convertDateArrayFromServer(res)));
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    startVideoCall(session) {
        return this.http
            .post(`${this.resourceUrl}/start`, session.id, { observe: 'response' });
    }
    endVideoCall(session) {
        return this.http
            .post(`${this.resourceUrl}/end`, session.id, { observe: 'response' });
    }
    joinVideoCall(session) {
        return this.http
            .post(`${this.resourceUrl}/join`, session.id, { observe: 'response' });
    }
    isVideoCallInProgress(session) {
        return this.http
            .get(`${this.resourceUrl}/started/sessions/${session.id}`, { observe: 'response' });
    }
    submitVideoCallJoinResult(sessionId, videoCallJoinResult) {
        return this.http
            .post(`${this.resourceUrl}/started/sessions/${sessionId}`, videoCallJoinResult, { observe: 'response' });
    }
    saveTranscriptionMessage(sessionId, videoCallTranscriptionMessage) {
        return this.http
            .post(`${this.resourceUrl}/started/sessions/${sessionId}/messages`, videoCallTranscriptionMessage, { observe: 'response' });
    }
    updateTranscriptionStatus(sessionId, transcriptionEnabled) {
        return this.http
            .put(`${this.resourceUrl}/started/sessions/${sessionId}/transcription`, new ValueWrapper(transcriptionEnabled), { observe: 'response' });
    }
    convertDateFromClient(videoCall) {
        const copy = Object.assign({}, videoCall, {
            startTime: videoCall.startTime && videoCall.startTime.isValid() ? videoCall.startTime.toJSON() : undefined,
            endTime: videoCall.endTime && videoCall.endTime.isValid() ? videoCall.endTime.toJSON() : undefined,
        });
        return copy;
    }
    convertDateFromServer(res) {
        if (res.body) {
            res.body.startTime = res.body.startTime ? moment(res.body.startTime) : undefined;
            res.body.endTime = res.body.endTime ? moment(res.body.endTime) : undefined;
        }
        return res;
    }
    convertDateArrayFromServer(res) {
        if (res.body) {
            res.body.forEach((videoCall) => {
                videoCall.startTime = videoCall.startTime ? moment(videoCall.startTime) : undefined;
                videoCall.endTime = videoCall.endTime ? moment(videoCall.endTime) : undefined;
            });
        }
        return res;
    }
}
VideoCallService.ɵfac = function VideoCallService_Factory(t) { return new (t || VideoCallService)(i0.ɵɵinject(i1.HttpClient)); };
VideoCallService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: VideoCallService, factory: VideoCallService.ɵfac, providedIn: 'root' });
